import React from "react";
import { Auth, API, Storage } from 'aws-amplify';
import Tooltip from '@material-ui/core/Tooltip';
import { CircularProgress, Button } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import ReactDOM from 'react-dom';
import App from "../App";

import imgSearchclaim from '../imgs/search-claims.svg';
import Snackbar from '@material-ui/core/Snackbar';
import $ from 'jquery';

class IrvineClaimDetails extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            isLoading: false,
            permissions: [],
            mode: "Admin",
            authState: "loading",

            ///////////

            firstName: null,
            middleName: null,
            lastName: null,
            suffix: null,
            addressLine1: null,
            addressLine2: null,
            city: null,
            state: null,
            zipcode: null,
            phone: null,
            phoneType: null,
            email: null,
            gender: null,
            race: null,
            ethnicity: null,
            dateOfBirth: '0000-00-00',
            interpretationServicesRequiredForLanguage: null,
            secondaryFirstName: null,
            secondaryLastName: null,
            secondaryEmail: null,
            secondaryPhone: null,
            householdNumber: "0",
            countAdult: "0",
            countChildren: "0",
            headOfHousehold: null,
            veteran: null,
            currentMonthlyIncome: null,
            totalHouseholdAnnualIncome: null,
            qualifiedForUnemploymentBenefits: false,
            experiencedReductionInHouseholdIncome: false,
            incurredSignificantCosts: false,
            reductionOfChildSupport: false,
            otherFinancialHardship: false,
            isSomeoneOnRentalOrLeaseAgreement: null,

            // landlordFullName: null,
            // landlordEmail: null,
            // landlordPhone: null,
            // monthlyRentPayment: null,
            // monthsBehindOnRent: null,
            // amountBehindOnRent: null,

            receivedHousingSubsidy: null,
            subsidyExplanation: null,
            filedCovid19FinancialStressDeclaration: null,
            receivedEvictionNotice: null,
            dateEvictionNoticeReceived: '0000-00-00',

            // createdDate: null,
            // createdTime: null,

            doYouLiveInIrvine: null,
            doYouRent: null,
            areYouBehindOnRent: null,
            doYouHoldSection8HousingVoucher: null,
            experiencedFinancialHardshipInCovid: null,
            isAnnualIncomeLessThanMaxLimit: null,
            belowThirtyPercAMI: "",
            thirtyToFiftyPercAMI: "",
            fiftyToEightyPercAMI: "",

            individualID: "IRV000000000000",
            // agent: null,
            individualCase: "Open",
            individualCase_Original: "Open",
            // rentPaymentStatus: null,
            dontKnowDateOfBirth: null,
            // ipAddress: null,

            age: null,

            // Top Section
            // --individualCase
            applicationAccepted: "No", // (hmisApproval)
            applicationAccepted_Original: "No",
            householdApprovedForUnitedWay: "No",

            // Applicant Details Section
            // --individualID
            programTitle: "ERA-IRVINE",
            // -- createdDate // dateApplied: null, // (createdDate)


            // Other Details Section	
            uniqueID: null,
            agent: null,
            SSN: null,
            socialSecurityFirstName: null,
            socialSecurityLastName: null,
            lengthOfTimeForAward: null,
            householdID: "0",
            transactionID: "0",
            birthdayCode: null,
            otherPhone: null,
            spaAssignmentNo: null,
            spaServiceProviderEmail: null,
            partnerFirstName: null,
            partnerLastName: null,
            openToFollowup: null,
            noOfTimesParticipated: null,
            boardDistrict: "",
            legallyAbleToWork: "",
            workIndustry: "",
            industry_other: "",
            eligibleForStimulusFunds: "",
            employmentStatus: null,

            effectOfCovid19Financially: null,
            covid_other: null,


            // Landlord Details Section	
            landlordFullName: null,
            landlordEmail: null,
            landlordEmail_Original: null,
            landlordPhone: null,
            monthlyRentPayment: null,
            monthsBehindOnRent: null,
            amountBehindOnRent: null,
            rentAmountCovered: null,
            percentageOfMonthlyRent: null,
            landlordBankName: null,
            landlordAccountNumber: null,
            landlordRoutingNumber: null,
            landlordSSN: null,
            landlordTaxID: null,
            landlordDUNSNumber: null,
            memoPropertyAddress: null,
            rentTypeOfAssistance: null,
            outstandingRentalArrearsAmount: null,
            rentPaymentStatus: null,
            rentPaymentStatus_Original: null,
            landlordFirstAttempt: null,
            reasonPaymentNotProcessedIn21Days: null,
            landlordACHReceived: null,
            dateRentPaymentStatusUpdated: '0000-00-00',
            timeRentPaymentStatusUpdated: null,

            dateEmailTriggered: '0000-00-00',
            timeEmailTriggered: null,


            // Electric Details Section	
            electricProvider: null,
            electricUtilityAccountNo: null,
            custElectricBillingAccountNo: null,
            monthlyElectricBillAmount: null,
            noOfMonthsElectricUtilityPayments: null,
            electricTypeOfAssistance: null,
            amountDueforElectricity: null,
            electricAmount: null,
            percentageOfElectricBill: null,
            electricBankName: null,
            electricUtilityProviderAccountNo: null,
            electricRoutingNumber: null,
            electricMemoAccountNo: null,
            electricUtilityTaxID: null,
            electricUtilityDUNSNumber: null,
            electricPaymentStatus: "N/A",
            electricPaymentStatus_Original: null,
            dateElectricPaymentStatusUpdated: '0000-00-00',
            timeElectricPaymentStatusUpdated: null,


            // Gas Details Section	
            gasProvider: null,
            gasUtilityAccountNo: null,
            custGasBillingAccountNo: null,
            monthlyGasBillAmount: null,
            noOfMonthsGasUtilityPayments: null,
            gasTypeOfAssistance: null,
            amountDueforGas: null,
            gasAmount: null,
            percentageOfGasBill: null,
            gasBankName: null,
            gasUtilityProviderAccountNo: null,
            gasRoutingNumber: null,
            gasMemoAccountNo: null,
            gasUtilityTaxID: null,
            gasUtilityDUNSNumber: null,
            gasPaymentStatus: "N/A",
            gasPaymentStatus_Original: null,
            dateGasPaymentStatusUpdated: '0000-00-00',
            timeGasPaymentStatusUpdated: null,


            // Water/Sewer Details Section	
            waterProvider: null,
            waterUtilityAccountNo: null,
            custWaterBillingAccountNo: null,
            monthlyWaterBillAmount: null,
            noOfMonthsWaterUtilityPayments: null,
            waterTypeOfAssistance: null,
            amountDueforWater: null,
            waterAmount: null,
            percentageOfWaterBill: null,
            waterBankName: null,
            waterUtilityProviderAccountNo: null,
            waterRoutingNumber: null,
            waterMemoAccountNo: null,
            waterUtilityTaxID: null,
            waterUtilityDUNSNumber: null,
            waterPaymentStatus: "N/A",
            waterPaymentStatus_Original: null,
            dateWaterPaymentStatusUpdated: '0000-00-00',
            timeWaterPaymentStatusUpdated: null,


            // Trash Details Section	
            trashProvider: null,
            trashUtilityAccountNo: null,
            custTrashBillingAccountNo: null,
            monthlyTrashBillAmount: null,
            noOfMonthsTrashUtilityPayments: null,
            trashTypeOfAssistance: null,
            amountDueforTrash: null,
            trashAmount: null,
            percentageOfTrashBill: null,
            trashBankName: null,
            trashUtilityProviderAccountNo: null,
            trashRoutingNumber: null,
            trashMemoAccountNo: null,
            trashUtilityTaxID: null,
            trashUtilityDUNSNumber: null,
            trashPaymentStatus: "N/A",
            trashPaymentStatus_Original: null,
            dateTrashPaymentStatusUpdated: '0000-00-00',
            timeTrashPaymentStatusUpdated: null,


            // Energy Details Section	
            energyProvider: null,
            energyUtilityAccountNo: null,
            custEnergyBillingAccountNo: null,
            monthlyEnergyBillAmount: null,
            noOfMonthsEnergyUtilityPayments: null,
            energyTypeOfAssistance: null,
            amountDueforEnergy: null,
            energyAmount: null,
            percentageOfEnergyBill: null,
            energyBankName: null,
            energyUtilityProviderAccountNo: null,
            energyRoutingNumber: null,
            energyMemoAccountNo: null,
            energyUtilityTaxID: null,
            energyUtilityDUNSNumber: null,
            energyPaymentStatus: "N/A",
            energyPaymentStatus_Original: null,
            dateEnergyPaymentStatusUpdated: '0000-00-00',
            timeEnergyPaymentStatusUpdated: null,


            // Individual Payments Details Section	
            individualPaymentReason: null,
            individualPaymentAmount: null,
            individualMemoPropertyAddress: null,
            individualBankName: null,
            individualAccountNumber: null,
            individualRoutingNumber: null,
            individualPaymentStatus: "Pending",
            individualACHReceived: null,
            individualPaymentStatus_Original: null,
            dateIndividualPaymentStatusUpdated: '0000-00-00',
            timeIndividualPaymentStatusUpdated: null,

            // Other Payments Details Section	
            otherPayeeName: null,
            otherDesription: null,
            otherPaymentAmount: null,
            otherMemoPropertyAddress: null,
            otherBankName: null,
            otherAccountNumber: null,
            otherRoutingNumber: null,
            otherPaymentStatus: "N/A",
            otherPaymentStatus_Original: null,
            dateOtherPaymentStatusUpdated: '0000-00-00',
            timeOtherPaymentStatusUpdated: null,

            // Note Section	
            notes: null,


            dateClaimModified: '0000-00-00',
            timeClaimModified: null,
            modifiedBy: null, //(?) adminUsername?
            createdBy: null, //(?) originator?

            requestedLandlordInfo: false,
            dateLandlordInfoRequested: '0000-00-00',
            dateLandlordInfoRequested_Formatted: '0000-00-00',
            timeLandlordInfoRequested: null,
            countLandlordInfoRequested: 0,
            landlordInfoRequestedStatus: 'Pending',
            landlordInfoRequestedStatus_Original: null,
            dateLandlordInfoReminded: '0000-00-00',
            dateLandlordInfoReminded_Formatted: '0000-00-00',
            timeLandlordInfoReminded: null,

            landlordMobile: null,
            verifiedasEligible: null,

            applicantDocUploadStatus: 'Pending',
            applicantDocUploadStatus_Original: null,
            paymentToIrvineCompany: null,

            dateApplicantDocumentSubmitted: '0000-00-00',
            dateApplicantDocumentSubmitted_Formatted: '0000-00-00',
            timeApplicantDocumentSubmitted: null,
            dateLandlordDocumentSubmitted: '0000-00-00',
            dateLandlordDocumentSubmitted_Formatted: '0000-00-00',
            timeLandlordDocumentSubmitted: null,
            dateApplicationAcceptedChanged: '0000-00-00',
            dateApplicationAcceptedChanged_Formatted: '0000-00-00',
            timeApplicationAcceptedChanged: null,

            landlordTitlePulled: null,

            startDateCoveredByPayment: '0000-00-00',
            endDateCoveredByPayment: '0000-00-00',

            total1099Amount: 0,

            totalCovered: 0,
            totalCoveredClone: 0,

            ///////////

            lengthOfTimeForAward: null,
            timestampOfRentIssued: null,

            triggerEmail: "0",


            reconciliationEditId: 0,
            deleteReconciliationId: 0,
            reconciliationMode: '',
            isChildRecord: false,

            reconciliation: { "entityType": "", "initiallyPaidBy": "", "amountType": "", "amount": "", "memoPropertyAddress": "", "paymentMethod": "", "otherPaymentStatus": "", "amountCoveredAfterRec": "", "amountCoveredBeforeRec": "", "transactionNo": "", "notes": "" },
            reconciliationList: [],
            reconciliationListClone: [],
            reconciliationListByIndividualId: [],

            validationErrors: {},

            openSnackbar: false,
            snackbarSeverity: "",
            snackbarVertical: 'top',
            snackbarHorizontal: 'right',
            snackbarMessage: "",
            snackbarMessageClass: '',


            // Tenant Documents	
            photo_identification: "",
            secondary_identification: "",
            secondary_proof_of_residential_tenancy: "",
            proof_of_past_due_rent_amount: "",
            proof_of_Annual_2020_household_income: "",
            rental_agreement: "",
            supplemental_proofof_Annual_2020_household_income: "",
            participation_agreement: "",
            proof_of_unemployment: "",
            supplemental_proofof_unemployment: "",
            authorization_to_release_information_to_SSA: "",
            consent_to_exchange_information: "",
            authorization_to_release_information_to_HUD: "",
            ami_household_income_worksheet: "",
            tenant_payment_agreement: "",

            photo_identification_uploaded: false,
            secondary_identification_uploaded: false,
            secondary_proof_of_residential_tenancy_uploaded: false,
            proof_of_past_due_rent_amount_uploaded: false,
            proof_of_Annual_2020_household_income_uploaded: false,
            rental_agreement_uploaded: false,
            supplemental_proofof_Annual_2020_household_income_uploaded: false,
            participation_agreement_uploaded: false,
            proof_of_unemployment_uploaded: false,
            supplemental_proofof_unemployment_uploaded: false,
            authorization_to_release_information_to_SSA_uploaded: false,
            consent_to_exchange_information_uploaded: false,
            authorization_to_release_information_to_HUD_uploaded: false,
            ami_household_income_worksheet_uploaded: false,
            tenant_payment_agreement_uploaded: false,


            // Landlord Documents	
            third_party_payer_agreement: "",
            landlord_w9: "",
            landlord_participationagreement: "",
            verified_checkpoint: "",
            landlord_driver_license: "",
            management_agreement: "",
            landlord_payment_agreement: "",

            third_party_payer_agreement_uploaded: false,
            landlord_w9_uploaded: false,
            landlord_participationagreement_uploaded: false,
            verified_checkpoint_uploaded: false,
            landlord_driver_license_uploaded: false,
            management_agreement_uploaded: false,
            landlord_payment_agreement_uploaded: false,


            /////////// Error State Variables - Starts ///////////

            firstName_Error: "",
            middleName_Error: "",
            lastName_Error: "",
            suffix_Error: "",
            addressLine1_Error: "",
            addressLine2_Error: "",
            city_Error: "",
            state_Error: "",
            zipcode_Error: "",
            phone_Error: "",
            phoneType_Error: "",
            email_Error: "",
            gender_Error: "",
            race_Error: "",
            ethnicity_Error: "",
            dateOfBirth_Error: "",
            interpretationServicesRequiredForLanguage_Error: "",
            secondaryFirstName_Error: "",
            secondaryLastName_Error: "",
            secondaryEmail_Error: "",
            secondaryPhone_Error: "",
            householdNumber_Error: "",
            countAdult_Error: "",
            countChildren_Error: "",
            headOfHousehold_Error: "",
            veteran_Error: "",
            currentMonthlyIncome_Error: "",
            totalHouseholdAnnualIncome_Error: "",
            qualifiedForUnemploymentBenefits_Error: "",
            experiencedReductionInHouseholdIncome_Error: "",
            incurredSignificantCosts_Error: "",
            reductionOfChildSupport_Error: "",
            otherFinancialHardship_Error: "",
            isSomeoneOnRentalOrLeaseAgreement_Error: "",

            // landlordFullName_Error: "",
            // landlordEmail_Error: "",
            // landlordPhone_Error: "",
            // monthlyRentPayment_Error: "",
            // monthsBehindOnRent_Error: "",
            // amountBehindOnRent_Error: "",

            receivedHousingSubsidy_Error: "",
            subsidyExplanation_Error: "",
            filedCovid19FinancialStressDeclaration_Error: "",
            receivedEvictionNotice_Error: "",
            dateEvictionNoticeReceived_Error: "",

            doYouLiveInIrvine_Error: "",
            doYouRent_Error: "",
            areYouBehindOnRent_Error: "",
            doYouHoldSection8HousingVoucher_Error: "",
            experiencedFinancialHardshipInCovid_Error: "",
            isAnnualIncomeLessThanMaxLimit_Error: "",
            belowThirtyPercAMI_Error: "",
            thirtyToFiftyPercAMI_Error: "",
            fiftyToEightyPercAMI_Error: "",

            individualID_Error: "",
            // agent_Error: "",
            individualCase_Error: "",
            // rentPaymentStatus_Error: "",
            dontKnowDateOfBirth_Error: "",
            // ipAddress_Error: "",

            age_Error: "",

            // Top Section
            // --individualCase
            applicationAccepted_Error: "", // (hmisApproval)
            householdApprovedForUnitedWay_Error: "",

            // Applicant Details Section
            // --individualID
            programTitle_Error: "",
            // -- createdDate // dateApplied_Error: "", // (createdDate)


            // Other Details Section	
            uniqueID_Error: "",
            agent_Error: "",
            SSN_Error: "",
            socialSecurityFirstName_Error: "",
            socialSecurityLastName_Error: "",
            lengthOfTimeForAward_Error: "",
            householdID_Error: "",
            transactionID_Error: "",
            birthdayCode_Error: "",
            otherPhone_Error: "",
            spaAssignmentNo_Error: "",
            spaServiceProviderEmail_Error: "",
            partnerFirstName_Error: "",
            partnerLastName_Error: "",
            openToFollowup_Error: "",
            noOfTimesParticipated_Error: "",
            boardDistrict_Error: "",
            legallyAbleToWork_Error: "",
            workIndustry_Error: "",
            industry_other_Error: "",
            eligibleForStimulusFunds_Error: "",
            employmentStatus_Error: "",

            effectOfCovid19Financially_Error: "",
            covid_other_Error: "",


            // Landlord Details Section	
            landlordFullName_Error: "",
            landlordEmail_Error: "",
            landlordPhone_Error: "",
            monthlyRentPayment_Error: "",
            monthsBehindOnRent_Error: "",
            amountBehindOnRent_Error: "",
            rentAmountCovered_Error: "",
            percentageOfMonthlyRent_Error: "",
            landlordBankName_Error: "",
            landlordAccountNumber_Error: "",
            landlordRoutingNumber_Error: "",
            landlordSSN_Error: "",
            landlordTaxID_Error: "",
            landlordDUNSNumber_Error: "",
            memoPropertyAddress_Error: "",
            rentTypeOfAssistance_Error: "",
            outstandingRentalArrearsAmount_Error: "",
            rentPaymentStatus_Error: "",
            landlordFirstAttempt_Error: "",
            reasonPaymentNotProcessedIn21Days_Error: "",
            landlordACHReceived_Error: "",

            // Electric Details Section	
            electricProvider_Error: "",
            electricUtilityAccountNo_Error: "",
            custElectricBillingAccountNo_Error: "",
            monthlyElectricBillAmount_Error: "",
            noOfMonthsElectricUtilityPayments_Error: "",
            electricTypeOfAssistance_Error: "",
            amountDueforElectricity_Error: "",
            electricAmount_Error: "",
            percentageOfElectricBill_Error: "",
            electricBankName_Error: "",
            electricUtilityProviderAccountNo_Error: "",
            electricRoutingNumber_Error: "",
            electricMemoAccountNo_Error: "",
            electricUtilityTaxID_Error: "",
            electricUtilityDUNSNumber_Error: "",
            electricPaymentStatus_Error: "",


            // Gas Details Section	
            gasProvider_Error: "",
            gasUtilityAccountNo_Error: "",
            custGasBillingAccountNo_Error: "",
            monthlyGasBillAmount_Error: "",
            noOfMonthsGasUtilityPayments_Error: "",
            gasTypeOfAssistance_Error: "",
            amountDueforGas_Error: "",
            gasAmount_Error: "",
            percentageOfGasBill_Error: "",
            gasBankName_Error: "",
            gasUtilityProviderAccountNo_Error: "",
            gasRoutingNumber_Error: "",
            gasMemoAccountNo_Error: "",
            gasUtilityTaxID_Error: "",
            gasUtilityDUNSNumber_Error: "",
            gasPaymentStatus_Error: "",


            // Water/Sewer Details Section	
            waterProvider_Error: "",
            waterUtilityAccountNo_Error: "",
            custWaterBillingAccountNo_Error: "",
            monthlyWaterBillAmount_Error: "",
            noOfMonthsWaterUtilityPayments_Error: "",
            waterTypeOfAssistance_Error: "",
            amountDueforWater_Error: "",
            waterAmount_Error: "",
            percentageOfWaterBill_Error: "",
            waterBankName_Error: "",
            waterUtilityProviderAccountNo_Error: "",
            waterRoutingNumber_Error: "",
            waterMemoAccountNo_Error: "",
            waterUtilityTaxID_Error: "",
            waterUtilityDUNSNumber_Error: "",
            waterPaymentStatus_Error: "",


            // Trash Details Section	
            trashProvider_Error: "",
            trashUtilityAccountNo_Error: "",
            custTrashBillingAccountNo_Error: "",
            monthlyTrashBillAmount_Error: "",
            noOfMonthsTrashUtilityPayments_Error: "",
            trashTypeOfAssistance_Error: "",
            amountDueforTrash_Error: "",
            trashAmount_Error: "",
            percentageOfTrashBill_Error: "",
            trashBankName_Error: "",
            trashUtilityProviderAccountNo_Error: "",
            trashRoutingNumber_Error: "",
            trashMemoAccountNo_Error: "",
            trashUtilityTaxID_Error: "",
            trashUtilityDUNSNumber_Error: "",
            trashPaymentStatus_Error: "",


            // Energy Details Section	
            energyProvider_Error: "",
            energyUtilityAccountNo_Error: "",
            custEnergyBillingAccountNo_Error: "",
            monthlyEnergyBillAmount_Error: "",
            noOfMonthsEnergyUtilityPayments_Error: "",
            energyTypeOfAssistance_Error: "",
            amountDueforEnergy_Error: "",
            energyAmount_Error: "",
            percentageOfEnergyBill_Error: "",
            energyBankName_Error: "",
            energyUtilityProviderAccountNo_Error: "",
            energyRoutingNumber_Error: "",
            energyMemoAccountNo_Error: "",
            energyUtilityTaxID_Error: "",
            energyUtilityDUNSNumber_Error: "",
            energyPaymentStatus_Error: "",


            // Individual Payments Details Section	
            individualPaymentReason_Error: "",
            individualPaymentAmount_Error: "",
            individualMemoPropertyAddress_Error: "",
            individualBankName_Error: "",
            individualAccountNumber_Error: "",
            individualRoutingNumber_Error: "",
            individualPaymentStatus_Error: "",
            individualACHReceived_Error: "",

            // Other Payments Details Section	
            otherPayeeName_Error: "",
            otherDesription_Error: "",
            otherPaymentAmount_Error: "",
            otherMemoPropertyAddress_Error: "",
            otherBankName_Error: "",
            otherAccountNumber_Error: "",
            otherRoutingNumber_Error: "",
            otherPaymentStatus_Error: "",

            // Note Section	
            notes_Error: "",

            landlordMobile_Error: "",

            /////////// Error State Variables - Ends ///////////

        }

        // preserve the initial state in a new object
        this.initialState = { ...this.state };

        this.rentPaymentStatusRef = React.createRef();

        /////////// Validation Control References - Ends ///////////

        this.firstNameRef = React.createRef();
        this.middleNameRef = React.createRef();
        this.lastNameRef = React.createRef();
        this.suffixRef = React.createRef();
        this.addressLine1Ref = React.createRef();
        this.addressLine2Ref = React.createRef();
        this.cityRef = React.createRef();
        this.stateRef = React.createRef();
        this.zipcodeRef = React.createRef();
        this.phoneRef = React.createRef();
        this.phoneTypeRef = React.createRef();
        this.emailRef = React.createRef();
        this.genderRef = React.createRef();
        this.raceRef = React.createRef();
        this.ethnicityRef = React.createRef();
        this.dateOfBirthRef = React.createRef();
        this.interpretationServicesRequiredForLanguageRef = React.createRef();
        this.secondaryFirstNameRef = React.createRef();
        this.secondaryLastNameRef = React.createRef();
        this.secondaryEmailRef = React.createRef();
        this.secondaryPhoneRef = React.createRef();
        this.householdNumberRef = React.createRef();
        this.countAdultRef = React.createRef();
        this.countChildrenRef = React.createRef();
        this.headOfHouseholdRef = React.createRef();
        this.veteranRef = React.createRef();
        this.currentMonthlyIncomeRef = React.createRef();
        this.totalHouseholdAnnualIncomeRef = React.createRef();
        this.qualifiedForUnemploymentBenefitsRef = React.createRef();
        this.experiencedReductionInHouseholdIncomeRef = React.createRef();
        this.incurredSignificantCostsRef = React.createRef();
        this.reductionOfChildSupportRef = React.createRef();
        this.otherFinancialHardshipRef = React.createRef();
        this.isSomeoneOnRentalOrLeaseAgreementRef = React.createRef();

        // this.landlordFullNameRef = React.createRef();
        // this.landlordEmailRef = React.createRef();
        // this.landlordPhoneRef = React.createRef();
        // this.monthlyRentPaymentRef = React.createRef();
        // this.monthsBehindOnRentRef = React.createRef();
        // this.amountBehindOnRentRef = React.createRef();

        this.receivedHousingSubsidyRef = React.createRef();
        this.subsidyExplanationRef = React.createRef();
        this.filedCovid19FinancialStressDeclarationRef = React.createRef();
        this.receivedEvictionNoticeRef = React.createRef();
        this.dateEvictionNoticeReceivedRef = React.createRef();

        this.doYouLiveInIrvineRef = React.createRef();
        this.doYouRentRef = React.createRef();
        this.areYouBehindOnRentRef = React.createRef();
        this.doYouHoldSection8HousingVoucherRef = React.createRef();
        this.experiencedFinancialHardshipInCovidRef = React.createRef();
        this.isAnnualIncomeLessThanMaxLimitRef = React.createRef();
        this.belowThirtyPercAMIRef = React.createRef();
        this.thirtyToFiftyPercAMIRef = React.createRef();
        this.fiftyToEightyPercAMIRef = React.createRef();

        this.individualIDRef = React.createRef();
        // this.agentRef = React.createRef();
        this.individualCaseRef = React.createRef();
        // this.rentPaymentStatusRef = React.createRef();
        this.dontKnowDateOfBirthRef = React.createRef();
        // this.ipAddressRef = React.createRef();

        this.ageRef = React.createRef();

        // Top Section
        // --individualCase
        this.applicationAcceptedRef = React.createRef(); // (hmisApproval)
        this.householdApprovedForUnitedWayRef = React.createRef();

        // Applicant Details Section
        // --individualID
        this.programTitleRef = React.createRef();
        // -- createdDate // dateAppliedRef = React.createRef(); // (createdDate)


        // Other Details Section	
        this.uniqueIDRef = React.createRef();
        this.agentRef = React.createRef();
        this.SSNRef = React.createRef();
        this.socialSecurityFirstNameRef = React.createRef();
        this.socialSecurityLastNameRef = React.createRef();
        this.lengthOfTimeForAwardRef = React.createRef();
        this.householdIDRef = React.createRef();
        this.transactionIDRef = React.createRef();
        this.birthdayCodeRef = React.createRef();
        this.otherPhoneRef = React.createRef();
        this.spaAssignmentNoRef = React.createRef();
        this.spaServiceProviderEmailRef = React.createRef();
        this.partnerFirstNameRef = React.createRef();
        this.partnerLastNameRef = React.createRef();
        this.openToFollowupRef = React.createRef();
        this.noOfTimesParticipatedRef = React.createRef();
        this.boardDistrictRef = React.createRef();
        this.legallyAbleToWorkRef = React.createRef();
        this.workIndustryRef = React.createRef();
        this.industry_otherRef = React.createRef();
        this.eligibleForStimulusFundsRef = React.createRef();
        this.employmentStatusRef = React.createRef();

        this.effectOfCovid19FinanciallyRef = React.createRef();
        this.covid_otherRef = React.createRef();


        // Landlord Details Section	
        this.landlordFullNameRef = React.createRef();
        this.landlordEmailRef = React.createRef();
        this.landlordPhoneRef = React.createRef();
        this.monthlyRentPaymentRef = React.createRef();
        this.monthsBehindOnRentRef = React.createRef();
        this.amountBehindOnRentRef = React.createRef();
        this.rentAmountCoveredRef = React.createRef();
        this.percentageOfMonthlyRentRef = React.createRef();
        this.landlordBankNameRef = React.createRef();
        this.landlordAccountNumberRef = React.createRef();
        this.landlordRoutingNumberRef = React.createRef();
        this.landlordSSNRef = React.createRef();
        this.landlordTaxIDRef = React.createRef();
        this.landlordDUNSNumberRef = React.createRef();
        this.memoPropertyAddressRef = React.createRef();
        this.rentTypeOfAssistanceRef = React.createRef();
        this.outstandingRentalArrearsAmountRef = React.createRef();
        this.rentPaymentStatusRef = React.createRef();
        this.landlordFirstAttemptRef = React.createRef();
        this.reasonPaymentNotProcessedIn21DaysRef = React.createRef();
        this.landlordACHReceivedRef = React.createRef();


        // Electric Details Section	
        this.electricProviderRef = React.createRef();
        this.electricUtilityAccountNoRef = React.createRef();
        this.custElectricBillingAccountNoRef = React.createRef();
        this.monthlyElectricBillAmountRef = React.createRef();
        this.noOfMonthsElectricUtilityPaymentsRef = React.createRef();
        this.electricTypeOfAssistanceRef = React.createRef();
        this.amountDueforElectricityRef = React.createRef();
        this.electricAmountRef = React.createRef();
        this.percentageOfElectricBillRef = React.createRef();
        this.electricBankNameRef = React.createRef();
        this.electricUtilityProviderAccountNoRef = React.createRef();
        this.electricRoutingNumberRef = React.createRef();
        this.electricMemoAccountNoRef = React.createRef();
        this.electricUtilityTaxIDRef = React.createRef();
        this.electricUtilityDUNSNumberRef = React.createRef();
        this.electricPaymentStatusRef = React.createRef();

        // Gas Details Section	
        this.gasProviderRef = React.createRef();
        this.gasUtilityAccountNoRef = React.createRef();
        this.custGasBillingAccountNoRef = React.createRef();
        this.monthlyGasBillAmountRef = React.createRef();
        this.noOfMonthsGasUtilityPaymentsRef = React.createRef();
        this.gasTypeOfAssistanceRef = React.createRef();
        this.amountDueforGasRef = React.createRef();
        this.gasAmountRef = React.createRef();
        this.percentageOfGasBillRef = React.createRef();
        this.gasBankNameRef = React.createRef();
        this.gasUtilityProviderAccountNoRef = React.createRef();
        this.gasRoutingNumberRef = React.createRef();
        this.gasMemoAccountNoRef = React.createRef();
        this.gasUtilityTaxIDRef = React.createRef();
        this.gasUtilityDUNSNumberRef = React.createRef();
        this.gasPaymentStatusRef = React.createRef();

        // Water/Sewer Details Section	
        this.waterProviderRef = React.createRef();
        this.waterUtilityAccountNoRef = React.createRef();
        this.custWaterBillingAccountNoRef = React.createRef();
        this.monthlyWaterBillAmountRef = React.createRef();
        this.noOfMonthsWaterUtilityPaymentsRef = React.createRef();
        this.waterTypeOfAssistanceRef = React.createRef();
        this.amountDueforWaterRef = React.createRef();
        this.waterAmountRef = React.createRef();
        this.percentageOfWaterBillRef = React.createRef();
        this.waterBankNameRef = React.createRef();
        this.waterUtilityProviderAccountNoRef = React.createRef();
        this.waterRoutingNumberRef = React.createRef();
        this.waterMemoAccountNoRef = React.createRef();
        this.waterUtilityTaxIDRef = React.createRef();
        this.waterUtilityDUNSNumberRef = React.createRef();
        this.waterPaymentStatusRef = React.createRef();

        // Trash Details Section	
        this.trashProviderRef = React.createRef();
        this.trashUtilityAccountNoRef = React.createRef();
        this.custTrashBillingAccountNoRef = React.createRef();
        this.monthlyTrashBillAmountRef = React.createRef();
        this.noOfMonthsTrashUtilityPaymentsRef = React.createRef();
        this.trashTypeOfAssistanceRef = React.createRef();
        this.amountDueforTrashRef = React.createRef();
        this.trashAmountRef = React.createRef();
        this.percentageOfTrashBillRef = React.createRef();
        this.trashBankNameRef = React.createRef();
        this.trashUtilityProviderAccountNoRef = React.createRef();
        this.trashRoutingNumberRef = React.createRef();
        this.trashMemoAccountNoRef = React.createRef();
        this.trashUtilityTaxIDRef = React.createRef();
        this.trashUtilityDUNSNumberRef = React.createRef();
        this.trashPaymentStatusRef = React.createRef();


        // Energy Details Section	
        this.energyProviderRef = React.createRef();
        this.energyUtilityAccountNoRef = React.createRef();
        this.custEnergyBillingAccountNoRef = React.createRef();
        this.monthlyEnergyBillAmountRef = React.createRef();
        this.noOfMonthsEnergyUtilityPaymentsRef = React.createRef();
        this.energyTypeOfAssistanceRef = React.createRef();
        this.amountDueforEnergyRef = React.createRef();
        this.energyAmountRef = React.createRef();
        this.percentageOfEnergyBillRef = React.createRef();
        this.energyBankNameRef = React.createRef();
        this.energyUtilityProviderAccountNoRef = React.createRef();
        this.energyRoutingNumberRef = React.createRef();
        this.energyMemoAccountNoRef = React.createRef();
        this.energyUtilityTaxIDRef = React.createRef();
        this.energyUtilityDUNSNumberRef = React.createRef();
        this.energyPaymentStatusRef = React.createRef();


        // Individual Payments Details Section	
        this.individualPaymentReasonRef = React.createRef();
        this.individualPaymentAmountRef = React.createRef();
        this.individualMemoPropertyAddressRef = React.createRef();
        this.individualBankNameRef = React.createRef();
        this.individualAccountNumberRef = React.createRef();
        this.individualRoutingNumberRef = React.createRef();
        this.individualPaymentStatusRef = React.createRef();
        this.individualACHReceivedRef = React.createRef();

        // Other Payments Details Section	
        this.otherPayeeNameRef = React.createRef();
        this.otherDesriptionRef = React.createRef();
        this.otherPaymentAmountRef = React.createRef();
        this.otherMemoPropertyAddressRef = React.createRef();
        this.otherBankNameRef = React.createRef();
        this.otherAccountNumberRef = React.createRef();
        this.otherRoutingNumberRef = React.createRef();
        this.otherPaymentStatusRef = React.createRef();

        // Note Section	
        this.notesRef = React.createRef();

        this.landlordMobileRef = React.createRef();
        this.verifiedasEligibleRef = React.createRef();
        this.paymentToIrvineCompanyRef = React.createRef();

        /////////// Validation Control References - Ends ///////////

        this.photo_identification_upload = null;
        this.secondary_identification_upload = null;
        this.secondary_proof_of_residential_tenancy_upload = null;
        this.proof_of_past_due_rent_amount_upload = null;
        this.proof_of_Annual_2020_household_income_upload = null;
        this.rental_agreement_upload = null;
        this.supplemental_proofof_Annual_2020_household_income_upload = null;
        this.participation_agreement_upload = null;
        this.proof_of_unemployment_upload = null;
        this.supplemental_proofof_unemployment_upload = null;
        this.authorization_to_release_information_to_SSA_upload = null;
        this.consent_to_exchange_information_upload = null;
        this.authorization_to_release_information_to_HUD_upload = null;
        this.ami_household_income_worksheet_upload = null;
        this.tenant_payment_agreement_upload = null;

        this.third_party_payer_agreement_upload = null;
        this.landlord_w9_upload = null;
        this.landlord_participationagreement_upload = null;
        this.verified_checkpoint_upload = null;
        this.landlord_driver_license_upload = null;
        this.management_agreement_upload = null;
        this.landlord_payment_agreement_upload = null;
    }


    validateControls = () => {
        var validationError = false;
        this.setState({ isLoading: true });

        ///////////////////////////   VALIDATION STARTS   ///////////////////////////

        // if (this.state.firstName !== "" && this.state.firstName !== null && this.state.firstName !== undefined) {
        //     var namePattern = new RegExp(/^[A-Za-z\/\']+[A-Za-z]$/);
        //     if (!namePattern.test(this.state.firstName)) {
        //         this.setState({ firstName_Error: "Invalid First Name" });
        //         this.firstNameRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ firstName_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ firstName_Error: "" });
        // }

        // if (this.state.lastName !== "" && this.state.lastName !== null && this.state.lastName !== undefined) {
        //     var namePattern = new RegExp(/^[A-Za-z\/\']+[A-Za-z]$/);
        //     if (!namePattern.test(this.state.lastName)) {
        //         this.setState({ lastName_Error: "Invalid Last Name" });
        //         this.lastNameRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ lastName_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ lastName_Error: "" });
        // }

        // if (this.state.partnerFirstName !== "" && this.state.partnerFirstName !== null && this.state.partnerFirstName !== undefined) {
        //     var namePattern = new RegExp(/^[A-Za-z\/\']+[A-Za-z]$/);
        //     if (!namePattern.test(this.state.partnerFirstName)) {
        //         this.setState({ partnerFirstName_Error: "Invalid Partner First Name" });
        //         this.partnerFirstNameRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ partnerFirstName_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ partnerFirstName_Error: "" });
        // }

        // if (this.state.partnerLastName !== "" && this.state.partnerLastName !== null && this.state.partnerLastName !== undefined) {
        //     var namePattern = new RegExp(/^[A-Za-z\/\']+[A-Za-z]$/);
        //     if (!namePattern.test(this.state.partnerLastName)) {
        //         this.setState({ partnerLastName_Error: "Invalid Partner Last Name" });
        //         this.partnerLastNameRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ partnerLastName_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ partnerLastName_Error: "" });
        // }

        // addressLine1
        // addressLine2

        if (this.state.city !== "" && this.state.city !== null && this.state.city !== undefined) {
            var namePattern = new RegExp(/^[a-zA-Z- ]*$/);
            if (!namePattern.test(this.state.city)) {
                this.setState({ city_Error: "Invalid City" });
                this.cityRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ city_Error: "" });
            }
        }
        else {
            this.setState({ city_Error: "" });
        }

        // state
        // country

        if (this.state.zipcode !== "" && this.state.zipcode !== null && this.state.zipcode !== undefined) {
            if (this.state.zipcode.length < 5 || /^\d+$/.test(this.state.zipcode) !== true) {
                this.setState({ zipcode_Error: "Invalid Zip" });
                this.zipcodeRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ zipcode_Error: "" });
            }
        }
        else {
            this.setState({ zipcode_Error: "" });
        }

        // clientDOB

        if (this.state.phone !== "" && this.state.phone !== null && this.state.phone !== undefined) {
            if (this.state.phone.length < 10 || /^[0-9]*$/.test(this.state.phone) !== true) {
                this.setState({ phone_Error: "Invalid Phone" });
                this.phoneRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ phone_Error: "" });
            }
        }
        else {
            this.setState({ phone_Error: "" });
        }

        if (this.state.applicationAccepted_Original !== this.state.applicationAccepted &&
            this.state.applicationAccepted === "Yes") {

            if (this.state.firstName === "" || this.state.firstName === null || this.state.firstName === undefined) {
                this.setState({ firstName_Error: "First Name is required" });
                this.firstNameRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ firstName_Error: "" });
            }

            if (this.state.lastName === "" || this.state.lastName === null || this.state.lastName === undefined) {
                this.setState({ lastName_Error: "Last Name is required" });
                this.lastNameRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ lastName_Error: "" });
            }

            if (this.state.email === "" || this.state.email === null || this.state.email === undefined) {
                this.setState({ email_Error: "Tenant Email is required" });
                this.emailRef.current.focus();

                validationError = true;
            }
            else {
                var emailPattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
                if (!emailPattern.test(this.state.email)) {
                    this.setState({ email_Error: "Invalid Email" });
                    this.emailRef.current.focus();

                    validationError = true;
                }
                else {
                    this.setState({ email_Error: "" });
                }
            }

            if (this.state.phone === "" || this.state.phone === null || this.state.phone === undefined) {
                this.setState({ phone_Error: "Phone is required" });
                this.phoneRef.current.focus();

                validationError = true;
            }
            else {
                if (this.state.phone.length < 10 || /^[0-9]*$/.test(this.state.phone) !== true) {
                    this.setState({ phone_Error: "Invalid Phone Number" });
                    this.phoneRef.current.focus();

                    validationError = true;
                }
                else {
                    this.setState({ phone_Error: "" });
                }
            }
        }

        if (this.state.currentMonthlyIncome !== "" && this.state.currentMonthlyIncome !== null && this.state.currentMonthlyIncome !== undefined) {
            if (/^\d+(\.\d{1,2})?$/.test(this.state.currentMonthlyIncome) !== true) {
                this.setState({ currentMonthlyIncome_Error: "Invalid Amount" });
                this.currentMonthlyIncomeRef.current.focus();

                validationError = true;
            }
            else if (this.state.currentMonthlyIncome > 99999.99) {
                this.setState({ currentMonthlyIncome_Error: "Exceeds limit" });
                this.currentMonthlyIncomeRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ currentMonthlyIncome_Error: "" });
            }
        }
        else {
            this.setState({ currentMonthlyIncome_Error: "" });
        }

        if (this.state.householdNumber !== "" && this.state.householdNumber !== null && this.state.householdNumber !== undefined) {
            if (/^\d+$/.test(this.state.householdNumber) !== true || parseInt(this.state.householdNumber) > 100) {
                this.setState({ householdNumber_Error: "Invalid Number" });
                this.householdNumberRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ householdNumber_Error: "" });
            }
        }
        else {
            this.setState({ householdNumber_Error: "" });
        }

        // if (this.state.age0to5 !== "" && this.state.age0to5 !== null && this.state.age0to5 !== undefined) {
        //     if (/^\d+$/.test(this.state.age0to5) !== true || parseInt(this.state.age0to5) > 100) {
        //         this.setState({ age0to5_Error: "Invalid Age 0-5" });
        //         this.age0to5Ref.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ age0to5_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ age0to5_Error: "" });
        // }

        // if (this.state.age6to17 !== "" && this.state.age6to17 !== null && this.state.age6to17 !== undefined) {
        //     if (/^\d+$/.test(this.state.age6to17) !== true || parseInt(this.state.age6to17) > 100) {
        //         this.setState({ age6to17_Error: "Invalid Number" });
        //         this.age6to17Ref.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ age6to17_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ age6to17_Error: "" });
        // }

        // if (this.state.age18to59 !== "" && this.state.age18to59 !== null && this.state.age18to59 !== undefined) {
        //     if (/^\d+$/.test(this.state.age18to59) !== true || parseInt(this.state.age18to59) > 100) {
        //         this.setState({ age18to59_Error: "Invalid Number" });
        //         this.age18to59Ref.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ age18to59_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ age18to59_Error: "" });
        // }

        // if (this.state.age60andUp !== "" && this.state.age60andUp !== null && this.state.age60andUp !== undefined) {
        //     if (/^\d+$/.test(this.state.age60andUp) !== true || parseInt(this.state.age60andUp) > 100) {
        //         this.setState({ age60andUp_Error: "Invalid Age 60+" });
        //         this.age60andUpRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ age60andUp_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ age60andUp_Error: "" });
        // }



        if (this.state.countAdult !== "" && this.state.countAdult !== null && this.state.countAdult !== undefined) {
            if (/^\d+$/.test(this.state.countAdult) !== true || parseInt(this.state.countAdult) > 100) {
                this.setState({ countAdult_Error: "Invalid Number" });
                this.countAdultRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ countAdult_Error: "" });
            }
        }
        else {
            this.setState({ countAdult_Error: "" });
        }

        if (this.state.countChildren !== "" && this.state.countChildren !== null && this.state.countChildren !== undefined) {
            if (/^\d+$/.test(this.state.countChildren) !== true || parseInt(this.state.countChildren) > 100) {
                this.setState({ countChildren_Error: "Invalid Number" });
                this.countChildrenRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ countChildren_Error: "" });
            }
        }
        else {
            this.setState({ countChildren_Error: "" });
        }


        // if (this.state.cardValue !== "" && this.state.cardValue !== null && this.state.cardValue !== undefined) {
        //     if (/^\d+$/.test(this.state.cardValue) !== true) {
        //         this.setState({ cardValue_Error: "Invalid Card Value" });
        //         this.cardValueRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ cardValue_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ cardValue_Error: "" });
        // }

        // if (this.state.spaAssignmentNo !== "" && this.state.spaAssignmentNo !== null && this.state.spaAssignmentNo !== undefined) {
        //     if (/^\d+$/.test(this.state.spaAssignmentNo) !== true) {
        //         this.setState({ spaAssignmentNo_Error: "Invalid SPA Assignment Number" });
        //         this.spaAssignmentNoRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ spaAssignmentNo_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ spaAssignmentNo_Error: "" });
        // }

        if (this.state.transactionID !== "" && this.state.transactionID !== null && this.state.transactionID !== undefined) {
            if (/^\d+$/.test(this.state.transactionID) !== true) {
                this.setState({ transactionID_Error: "Invalid Transaction ID" });
                this.transactionIDRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ transactionID_Error: "" });
            }
        }
        else {
            this.setState({ transactionID_Error: "" });
        }

        if (this.state.suffix !== "" && this.state.suffix !== null && this.state.suffix !== undefined) {
            if (this.state.suffix.length > 10) {
                this.setState({ suffix_Error: "Suffix too long" });
                this.suffixRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ suffix_Error: "" });
            }
        }
        else {
            this.setState({ suffix_Error: "" });
        }

        if (this.state.middleName !== "" && this.state.middleName !== null && this.state.middleName !== undefined) {
            if (this.state.middleName.length > 100) {
                this.setState({ middleName_Error: "Name too long" });
                this.middleNameRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ middleName_Error: "" });
            }
        }
        else {
            this.setState({ middleName_Error: "" });
        }

        if (this.state.agent !== "" && this.state.agent !== null && this.state.agent !== undefined) {
            if (this.state.agent.length > 100) {
                this.setState({ agent_Error: "Name too long" });
                this.agentRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ agent_Error: "" });
            }
        }
        else {
            this.setState({ agent_Error: "" });
        }

        if (this.state.doYouRent !== "" && this.state.doYouRent !== null && this.state.doYouRent !== undefined) {
            if (this.state.doYouRent.length > 100) {
                this.setState({ doYouRent_Error: "String too long" });
                this.doYouRentRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ doYouRent_Error: "" });
            }
        }
        else {
            this.setState({ doYouRent_Error: "" });
        }

        if (this.state.receivedHousingSubsidy !== "" && this.state.receivedHousingSubsidy !== null && this.state.receivedHousingSubsidy !== undefined) {
            if (this.state.receivedHousingSubsidy.length > 100) {
                this.setState({ receivedHousingSubsidy_Error: "String too long" });
                this.receivedHousingSubsidyRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ receivedHousingSubsidy_Error: "" });
            }
        }
        else {
            this.setState({ receivedHousingSubsidy_Error: "" });
        }

        // if (this.state.isAnnualIncomeLess !== "" && this.state.isAnnualIncomeLess !== null && this.state.isAnnualIncomeLess !== undefined) {
        //     if (this.state.isAnnualIncomeLess.length > 100) {
        //         this.setState({ isAnnualIncomeLess_Error: "String too long" });
        //         this.isAnnualIncomeLessRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ isAnnualIncomeLess_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ isAnnualIncomeLess_Error: "" });
        // }

        if (this.state.isSomeoneOnRentalOrLeaseAgreement !== "" && this.state.isSomeoneOnRentalOrLeaseAgreement !== null && this.state.isSomeoneOnRentalOrLeaseAgreement !== undefined) {
            if (this.state.isSomeoneOnRentalOrLeaseAgreement.length > 100) {
                this.setState({ isSomeoneOnRentalOrLeaseAgreement_Error: "String too long" });
                this.isSomeoneOnRentalOrLeaseAgreementRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ isSomeoneOnRentalOrLeaseAgreement_Error: "" });
            }
        }
        else {
            this.setState({ isSomeoneOnRentalOrLeaseAgreement_Error: "" });
        }

        if (this.state.filedCovid19FinancialStressDeclaration !== "" && this.state.filedCovid19FinancialStressDeclaration !== null && this.state.filedCovid19FinancialStressDeclaration !== undefined) {
            if (this.state.filedCovid19FinancialStressDeclaration.length > 100) {
                this.setState({ filedCovid19FinancialStressDeclaration_Error: "String too long" });
                this.filedCovid19FinancialStressDeclarationRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ filedCovid19FinancialStressDeclaration_Error: "" });
            }
        }
        else {
            this.setState({ filedCovid19FinancialStressDeclaration_Error: "" });
        }

        if (this.state.receivedEvictionNotice !== "" && this.state.receivedEvictionNotice !== null && this.state.receivedEvictionNotice !== undefined) {
            if (this.state.receivedEvictionNotice.length > 100) {
                this.setState({ receivedEvictionNotice_Error: "String too long" });
                this.receivedEvictionNoticeRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ receivedEvictionNotice_Error: "" });
            }
        }
        else {
            this.setState({ receivedEvictionNotice_Error: "" });
        }

        if (this.state.dateEvictionNoticeReceived !== "" && this.state.dateEvictionNoticeReceived !== null && this.state.dateEvictionNoticeReceived !== undefined) {
            if (this.state.dateEvictionNoticeReceived.length > 100) {
                this.setState({ dateEvictionNoticeReceived_Error: "String too long" });
                this.dateEvictionNoticeReceivedRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ dateEvictionNoticeReceived_Error: "" });
            }
        }
        else {
            this.setState({ dateEvictionNoticeReceived_Error: "" });
        }

        if (this.state.SSN !== "" && this.state.SSN !== null && this.state.SSN !== undefined) {
            if (this.state.SSN.length < 9 || /^\d+$/.test(this.state.SSN) !== true) {
                this.setState({ SSN_Error: "Invalid SSN Number" });
                this.SSNRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ SSN_Error: "" });
            }
        }
        else {
            this.setState({ SSN_Error: "" });
        }

        if (this.state.socialSecurityFirstName !== "" && this.state.socialSecurityFirstName !== null && this.state.socialSecurityFirstName !== undefined) {
            if (this.state.socialSecurityFirstName.length > 100) {
                this.setState({ socialSecurityFirstName_Error: "String too long" });
                this.socialSecurityFirstNameRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ socialSecurityFirstName_Error: "" });
            }
        }
        else {
            this.setState({ socialSecurityFirstName_Error: "" });
        }

        if (this.state.socialSecurityLastName !== "" && this.state.socialSecurityLastName !== null && this.state.socialSecurityLastName !== undefined) {
            if (this.state.socialSecurityLastName.length > 100) {
                this.setState({ socialSecurityLastName_Error: "String too long" });
                this.socialSecurityLastNameRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ socialSecurityLastName_Error: "" });
            }
        }
        else {
            this.setState({ socialSecurityLastName_Error: "" });
        }

        if (this.state.birthdayCode !== "" && this.state.birthdayCode !== null && this.state.birthdayCode !== undefined) {
            if (this.state.birthdayCode.length > 255) {
                this.setState({ birthdayCode_Error: "String too long" });
                this.birthdayCodeRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ birthdayCode_Error: "" });
            }
        }
        else {
            this.setState({ birthdayCode_Error: "" });
        }

        if (this.state.age !== "" && this.state.age !== null && this.state.age !== undefined) {
            if (/^\d+$/.test(this.state.age) !== true) {
                this.setState({ age_Error: "Invalid Number" });
                this.ageRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ age_Error: "" });
            }
        }
        else {
            this.setState({ age_Error: "" });
        }

        if (this.state.otherPhone !== "" && this.state.otherPhone !== null && this.state.otherPhone !== undefined) {
            if (this.state.otherPhone.length < 10 || /^[0-9]*$/.test(this.state.otherPhone) !== true) {
                this.setState({ otherPhone_Error: "Invalid Phone Number" });
                this.otherPhoneRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ otherPhone_Error: "" });
            }
        }
        else {
            this.setState({ otherPhone_Error: "" });
        }

        if (this.state.secondaryFirstName !== "" && this.state.secondaryFirstName !== null && this.state.secondaryFirstName !== undefined) {
            if (this.state.secondaryFirstName.length > 100) {
                this.setState({ secondaryFirstName_Error: "String too long" });
                this.secondaryFirstNameRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ secondaryFirstName_Error: "" });
            }
        }
        else {
            this.setState({ secondaryFirstName_Error: "" });
        }

        if (this.state.secondaryLastName !== "" && this.state.secondaryLastName !== null && this.state.secondaryLastName !== undefined) {
            if (this.state.secondaryLastName.length > 100) {
                this.setState({ secondaryLastName_Error: "String too long" });
                this.secondaryLastNameRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ secondaryLastName_Error: "" });
            }
        }
        else {
            this.setState({ secondaryLastName_Error: "" });
        }

        if (this.state.secondaryEmail !== "" && this.state.secondaryEmail !== null && this.state.secondaryEmail !== undefined) {
            var secondaryEmailPattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!secondaryEmailPattern.test(this.state.secondaryEmail)) {
                this.setState({ secondaryEmail_Error: "Invalid Email" });
                this.secondaryEmailRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ secondaryEmail_Error: "" });
            }
        }
        else {
            this.setState({ secondaryEmail_Error: "" });
        }

        if (this.state.secondaryPhone !== "" && this.state.secondaryPhone !== null && this.state.secondaryPhone !== undefined) {
            if (this.state.secondaryPhone.length < 10 || /^[0-9]*$/.test(this.state.secondaryPhone) !== true) {
                this.setState({ secondaryPhone_Error: "Invalid Phone Number" });
                this.secondaryPhoneRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ secondaryPhone_Error: "" });
            }
        }
        else {
            this.setState({ secondaryPhone_Error: "" });
        }

        if (this.state.householdID !== "" && this.state.householdID !== null && this.state.householdID !== undefined) {
            if (/^\d+$/.test(this.state.householdID) !== true) {
                this.setState({ householdID_Error: "Invalid Household ID" });
                this.householdIDRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ householdID_Error: "" });
            }
        }
        else {
            this.setState({ householdID_Error: "" });
        }

        if (this.state.landlordPhone !== "" && this.state.landlordPhone !== null && this.state.landlordPhone !== undefined) {
            if (this.state.landlordPhone.length < 10 || /^[0-9]*$/.test(this.state.landlordPhone) !== true) {
                this.setState({ landlordPhone_Error: "Invalid Phone Number" });
                this.landlordPhoneRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ landlordPhone_Error: "" });
            }
        }
        else {
            this.setState({ landlordPhone_Error: "" });
        }
        if (this.state.landlordMobile !== "" && this.state.landlordMobile !== null && this.state.landlordMobile !== undefined) {
            if (this.state.landlordMobile.length < 10 || /^[0-9]*$/.test(this.state.landlordMobile) !== true) {
                this.setState({ landlordMobile_Error: "Invalid Mobile Number" });
                this.landlordMobileRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ landlordMobile_Error: "" });
            }
        }
        else {
            this.setState({ landlordMobile_Error: "" });
        }



        if (this.state.landlordEmail !== "" && this.state.landlordEmail !== null && this.state.landlordEmail !== undefined) {
            var landlordEmailPattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!landlordEmailPattern.test(this.state.landlordEmail)) {
                this.setState({ landlordEmail_Error: "Invalid Email" });
                this.landlordEmailRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ landlordEmail_Error: "" });
            }
        }
        else {
            this.setState({ landlordEmail_Error: "" });
        }


        if (this.state.amountBehindOnRent !== "" && this.state.amountBehindOnRent !== null && this.state.amountBehindOnRent !== undefined) {
            if (/^\d+(\.\d{1,2})?$/.test(this.state.amountBehindOnRent) !== true) {
                this.setState({ amountBehindOnRent_Error: "Invalid Amount" });
                this.amountBehindOnRentRef.current.focus();

                validationError = true;
            }
            else if (this.state.amountBehindOnRent > 99999.99) {
                this.setState({ amountBehindOnRent_Error: "Exceeds limit" });
                this.amountBehindOnRentRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ amountBehindOnRent_Error: "" });
            }
        }
        else {
            this.setState({ amountBehindOnRent_Error: "" });
        }

        if (this.state.rentAmountCovered !== "" && this.state.rentAmountCovered !== null && this.state.rentAmountCovered !== undefined) {
            if (/^\d+(\.\d{1,2})?$/.test(this.state.rentAmountCovered) !== true) {
                this.setState({ rentAmountCovered_Error: "Invalid Amount" });
                this.rentAmountCoveredRef.current.focus();

                validationError = true;
            }
            else if (this.state.rentAmountCovered > 99999.99) {
                this.setState({ rentAmountCovered_Error: "Exceeds limit" });
                this.rentAmountCoveredRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ rentAmountCovered_Error: "" });
            }
        }
        else {
            this.setState({ rentAmountCovered_Error: "" });
        }

        if (this.state.rentPaymentStatus === 'Not Approved for Payment') {
            if (this.state.reasonPaymentNotProcessedIn21Days === "" || this.state.reasonPaymentNotProcessedIn21Days === null || this.state.reasonPaymentNotProcessedIn21Days === undefined) {
                this.setState({ reasonPaymentNotProcessedIn21Days_Error: "Reason required for Rent Payment Status as Not Approved" });
                this.reasonPaymentNotProcessedIn21DaysRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ reasonPaymentNotProcessedIn21Days_Error: "" });
            }
        }
        else {
            this.setState({ reasonPaymentNotProcessedIn21Days_Error: "" });
        }

        // if (this.state.landlordAccountNumber !== "" && this.state.landlordAccountNumber !== null && this.state.landlordAccountNumber !== undefined) {
        //     if (/^\d+$/.test(this.state.landlordAccountNumber) !== true) {
        //         this.setState({ landlordAccountNumber_Error: "Invalid Account Number" });
        //         this.landlordAccountNumberRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ landlordAccountNumber_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ landlordAccountNumber_Error: "" });
        // }

        if (this.state.landlordRoutingNumber !== "" && this.state.landlordRoutingNumber !== null && this.state.landlordRoutingNumber !== undefined) {
            if (this.state.landlordRoutingNumber.length < 9 || /^\d+$/.test(this.state.landlordRoutingNumber) !== true) {
                this.setState({ landlordRoutingNumber_Error: "Invalid Routing Number" });
                this.landlordRoutingNumberRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ landlordRoutingNumber_Error: "" });
            }
        }
        else {
            this.setState({ landlordRoutingNumber_Error: "" });
        }

        // if (this.state.electricUtilityAccountNo !== "" && this.state.electricUtilityAccountNo !== null && this.state.electricUtilityAccountNo !== undefined) {
        //     if (/^\d+$/.test(this.state.electricUtilityAccountNo) !== true) {
        //         this.setState({ electricUtilityAccountNo_Error: "Invalid Account Number" });
        //         this.electricUtilityAccountNoRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ electricUtilityAccountNo_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ electricUtilityAccountNo_Error: "" });
        // }

        if (this.state.amountDueforElectricity !== "" && this.state.amountDueforElectricity !== null && this.state.amountDueforElectricity !== undefined) {
            if (/^\d+(\.\d{1,2})?$/.test(this.state.amountDueforElectricity) !== true) {
                this.setState({ amountDueforElectricity_Error: "Invalid Amount" });
                this.amountDueforElectricityRef.current.focus();

                validationError = true;
            }
            else if (this.state.amountDueforElectricity > 99999.99) {
                this.setState({ amountDueforElectricity_Error: "Exceeds limit" });
                this.amountDueforElectricityRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ amountDueforElectricity_Error: "" });
            }
        }
        else {
            this.setState({ amountDueforElectricity_Error: "" });
        }

        // if (this.state.custElectricBillingAccountNo !== "" && this.state.custElectricBillingAccountNo !== null && this.state.custElectricBillingAccountNo !== undefined) {
        //     if (/^\d+$/.test(this.state.custElectricBillingAccountNo) !== true) {
        //         this.setState({ custElectricBillingAccountNo_Error: "Invalid Account Number" });
        //         this.custElectricBillingAccountNoRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ custElectricBillingAccountNo_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ custElectricBillingAccountNo_Error: "" });
        // }

        if (this.state.electricAmount !== "" && this.state.electricAmount !== null && this.state.electricAmount !== undefined) {
            if (/^\d+(\.\d{1,2})?$/.test(this.state.electricAmount) !== true) {
                this.setState({ electricAmount_Error: "Invalid Amount" });
                this.electricAmountRef.current.focus();

                validationError = true;
            }
            else if (this.state.electricAmount > 99999.99) {
                this.setState({ electricAmount_Error: "Exceeds limit" });
                this.electricAmountRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ electricAmount_Error: "" });
            }
        }
        else {
            this.setState({ electricAmount_Error: "" });
        }

        // if (this.state.electricUtilityProviderAccountNo !== "" && this.state.electricUtilityProviderAccountNo !== null && this.state.electricUtilityProviderAccountNo !== undefined) {
        //     if (/^\d+$/.test(this.state.electricUtilityProviderAccountNo) !== true) {
        //         this.setState({ electricUtilityProviderAccountNo_Error: "Invalid Account Number" });
        //         this.electricUtilityProviderAccountNoRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ electricUtilityProviderAccountNo_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ electricUtilityProviderAccountNo_Error: "" });
        // }

        if (this.state.electricRoutingNumber !== "" && this.state.electricRoutingNumber !== null && this.state.electricRoutingNumber !== undefined) {
            if (this.state.electricRoutingNumber.length < 9 || /^\d+$/.test(this.state.electricRoutingNumber) !== true) {
                this.setState({ electricRoutingNumber_Error: "Invalid Routing Number" });
                this.electricRoutingNumberRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ electricRoutingNumber_Error: "" });
            }
        }
        else {
            this.setState({ electricRoutingNumber_Error: "" });
        }

        // if (this.state.electricMemoAccountNo !== "" && this.state.electricMemoAccountNo !== null && this.state.electricMemoAccountNo !== undefined) {
        //     if (/^\d+$/.test(this.state.electricMemoAccountNo) !== true) {
        //         this.setState({ electricMemoAccountNo_Error: "Invalid Account Number" });
        //         this.electricMemoAccountNoRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ electricMemoAccountNo_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ electricMemoAccountNo_Error: "" });
        // }

        // if (this.state.gasUtilityAccountNo !== "" && this.state.gasUtilityAccountNo !== null && this.state.gasUtilityAccountNo !== undefined) {
        //     if (/^\d+$/.test(this.state.gasUtilityAccountNo) !== true) {
        //         this.setState({ gasUtilityAccountNo_Error: "Invalid Account Number" });
        //         this.gasUtilityAccountNoRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ gasUtilityAccountNo_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ gasUtilityAccountNo_Error: "" });
        // }

        if (this.state.amountDueforGas !== "" && this.state.amountDueforGas !== null && this.state.amountDueforGas !== undefined) {
            if (/^\d+(\.\d{1,2})?$/.test(this.state.amountDueforGas) !== true) {
                this.setState({ amountDueforGas_Error: "Invalid Amount" });
                this.amountDueforGasRef.current.focus();

                validationError = true;
            }
            else if (this.state.amountDueforGas > 99999.99) {
                this.setState({ amountDueforGas_Error: "Exceeds limit" });
                this.amountDueforGasRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ amountDueforGas_Error: "" });
            }
        }
        else {
            this.setState({ amountDueforGas_Error: "" });
        }

        // if (this.state.custGasBillingAccountNo !== "" && this.state.custGasBillingAccountNo !== null && this.state.custGasBillingAccountNo !== undefined) {
        //     if (/^\d+$/.test(this.state.custGasBillingAccountNo) !== true) {
        //         this.setState({ custGasBillingAccountNo_Error: "Invalid Account Number" });
        //         this.custGasBillingAccountNoRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ custGasBillingAccountNo_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ custGasBillingAccountNo_Error: "" });
        // }

        if (this.state.gasAmount !== "" && this.state.gasAmount !== null && this.state.gasAmount !== undefined) {
            if (/^\d+(\.\d{1,2})?$/.test(this.state.gasAmount) !== true) {
                this.setState({ gasAmount_Error: "Invalid Amount" });
                this.gasAmountRef.current.focus();

                validationError = true;
            }
            else if (this.state.gasAmount > 99999.99) {
                this.setState({ gasAmount_Error: "Exceeds limit" });
                this.gasAmountRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ gasAmount_Error: "" });
            }
        }
        else {
            this.setState({ gasAmount_Error: "" });
        }

        // if (this.state.gasUtilityProviderAccountNo !== "" && this.state.gasUtilityProviderAccountNo !== null && this.state.gasUtilityProviderAccountNo !== undefined) {
        //     if (/^\d+$/.test(this.state.gasUtilityProviderAccountNo) !== true) {
        //         this.setState({ gasUtilityProviderAccountNo_Error: "Invalid Account Number" });
        //         this.gasUtilityProviderAccountNoRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ gasUtilityProviderAccountNo_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ gasUtilityProviderAccountNo_Error: "" });
        // }

        if (this.state.gasRoutingNumber !== "" && this.state.gasRoutingNumber !== null && this.state.gasRoutingNumber !== undefined) {
            if (this.state.gasRoutingNumber.length < 9 || /^\d+$/.test(this.state.gasRoutingNumber) !== true) {
                this.setState({ gasRoutingNumber_Error: "Invalid Routing Number" });
                this.gasRoutingNumberRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ gasRoutingNumber_Error: "" });
            }
        }
        else {
            this.setState({ gasRoutingNumber_Error: "" });
        }

        // if (this.state.gasMemoAccountNo !== "" && this.state.gasMemoAccountNo !== null && this.state.gasMemoAccountNo !== undefined) {
        //     if (/^\d+$/.test(this.state.gasMemoAccountNo) !== true) {
        //         this.setState({ gasMemoAccountNo_Error: "Invalid Account Number" });
        //         this.gasMemoAccountNoRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ gasMemoAccountNo_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ gasMemoAccountNo_Error: "" });
        // }

        if (this.state.gasUtilityTaxID !== "" && this.state.gasUtilityTaxID !== null && this.state.gasUtilityTaxID !== undefined) {
            if (this.state.gasUtilityTaxID.length < 9 || /^\d+$/.test(this.state.gasUtilityTaxID) !== true) {
                this.setState({ gasUtilityTaxID_Error: "Invalid Tax ID" });
                this.gasUtilityTaxIDRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ gasUtilityTaxID_Error: "" });
            }
        }
        else {
            this.setState({ gasUtilityTaxID_Error: "" });
        }

        if (this.state.gasUtilityDUNSNumber !== "" && this.state.gasUtilityDUNSNumber !== null && this.state.gasUtilityDUNSNumber !== undefined) {
            if (this.state.gasUtilityDUNSNumber.length < 9 || /^\d+$/.test(this.state.gasUtilityDUNSNumber) !== true) {
                this.setState({ gasUtilityDUNSNumber_Error: "Invalid DUNS Number" });
                this.gasUtilityDUNSNumberRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ gasUtilityDUNSNumber_Error: "" });
            }
        }
        else {
            this.setState({ gasUtilityDUNSNumber_Error: "" });
        }

        if (this.state.monthlyGasBillAmount !== "" && this.state.monthlyGasBillAmount !== null && this.state.monthlyGasBillAmount !== undefined) {
            if (/^\d+(\.\d{1,2})?$/.test(this.state.monthlyGasBillAmount) !== true) {
                this.setState({ monthlyGasBillAmount_Error: "Invalid Amount" });
                this.monthlyGasBillAmountRef.current.focus();

                validationError = true;
            }
            else if (this.state.monthlyGasBillAmount > 99999.99) {
                this.setState({ monthlyGasBillAmount_Error: "Exceeds limit" });
                this.monthlyGasBillAmountRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ monthlyGasBillAmount_Error: "" });
            }
        }
        else {
            this.setState({ monthlyGasBillAmount_Error: "" });
        }

        // if (this.state.percentageOfGasBill !== "" && this.state.percentageOfGasBill !== null && this.state.percentageOfGasBill !== undefined) {
        //     if (/^\d+$/.test(this.state.percentageOfGasBill) !== true) {
        //         this.setState({ percentageOfGasBill_Error: "Invalid Number" });
        //         this.percentageOfGasBillRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ percentageOfGasBill_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ percentageOfGasBill_Error: "" });
        // }

        if (this.state.noOfMonthsGasUtilityPayments !== "" && this.state.noOfMonthsGasUtilityPayments !== null && this.state.noOfMonthsGasUtilityPayments !== undefined) {
            if (/^\d+$/.test(this.state.noOfMonthsGasUtilityPayments) !== true) {
                this.setState({ noOfMonthsGasUtilityPayments_Error: "Invalid Number" });
                this.noOfMonthsGasUtilityPaymentsRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ noOfMonthsGasUtilityPayments_Error: "" });
            }
        }
        else {
            this.setState({ noOfMonthsGasUtilityPayments_Error: "" });
        }

        // if (this.state.waterUtilityAccountNo !== "" && this.state.waterUtilityAccountNo !== null && this.state.waterUtilityAccountNo !== undefined) {
        //     if (/^\d+$/.test(this.state.waterUtilityAccountNo) !== true) {
        //         this.setState({ waterUtilityAccountNo_Error: "Invalid Account Number" });
        //         this.waterUtilityAccountNoRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ waterUtilityAccountNo_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ waterUtilityAccountNo_Error: "" });
        // }

        if (this.state.amountDueforWater !== "" && this.state.amountDueforWater !== null && this.state.amountDueforWater !== undefined) {
            if (/^\d+(\.\d{1,2})?$/.test(this.state.amountDueforWater) !== true) {
                this.setState({ amountDueforWater_Error: "Invalid Amount" });
                this.amountDueforWaterRef.current.focus();

                validationError = true;
            }
            else if (this.state.amountDueforWater > 99999.99) {
                this.setState({ amountDueforWater_Error: "Exceeds limit" });
                this.amountDueforWaterRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ amountDueforWater_Error: "" });
            }
        }
        else {
            this.setState({ amountDueforWater_Error: "" });
        }

        // if (this.state.custWaterBillingAccountNo !== "" && this.state.custWaterBillingAccountNo !== null && this.state.custWaterBillingAccountNo !== undefined) {
        //     if (/^\d+$/.test(this.state.custWaterBillingAccountNo) !== true) {
        //         this.setState({ custWaterBillingAccountNo_Error: "Invalid Account Number" });
        //         this.custWaterBillingAccountNoRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ custWaterBillingAccountNo_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ custWaterBillingAccountNo_Error: "" });
        // }

        if (this.state.waterAmount !== "" && this.state.waterAmount !== null && this.state.waterAmount !== undefined) {
            if (/^\d+(\.\d{1,2})?$/.test(this.state.waterAmount) !== true) {
                this.setState({ waterAmount_Error: "Invalid Amount" });
                this.waterAmountRef.current.focus();

                validationError = true;
            }
            else if (this.state.waterAmount > 99999.99) {
                this.setState({ waterAmount_Error: "Exceeds limit" });
                this.waterAmountRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ waterAmount_Error: "" });
            }
        }
        else {
            this.setState({ waterAmount_Error: "" });
        }

        // if (this.state.waterUtilityProviderAccountNo !== "" && this.state.waterUtilityProviderAccountNo !== null && this.state.waterUtilityProviderAccountNo !== undefined) {
        //     if (/^\d+$/.test(this.state.waterUtilityProviderAccountNo) !== true) {
        //         this.setState({ waterUtilityProviderAccountNo_Error: "Invalid Account Number" });
        //         this.waterUtilityProviderAccountNoRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ waterUtilityProviderAccountNo_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ waterUtilityProviderAccountNo_Error: "" });
        // }

        if (this.state.waterRoutingNumber !== "" && this.state.waterRoutingNumber !== null && this.state.waterRoutingNumber !== undefined) {
            if (this.state.waterRoutingNumber.length < 9 || /^\d+$/.test(this.state.waterRoutingNumber) !== true) {
                this.setState({ waterRoutingNumber_Error: "Invalid Routing Number" });
                this.waterRoutingNumberRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ waterRoutingNumber_Error: "" });
            }
        }
        else {
            this.setState({ waterRoutingNumber_Error: "" });
        }

        // if (this.state.waterMemoAccountNo !== "" && this.state.waterMemoAccountNo !== null && this.state.waterMemoAccountNo !== undefined) {
        //     if (/^\d+$/.test(this.state.waterMemoAccountNo) !== true) {
        //         this.setState({ waterMemoAccountNo_Error: "Invalid Account Number" });
        //         this.waterMemoAccountNoRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ waterMemoAccountNo_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ waterMemoAccountNo_Error: "" });
        // }


        if (this.state.waterUtilityTaxID !== "" && this.state.waterUtilityTaxID !== null && this.state.waterUtilityTaxID !== undefined) {
            if (this.state.waterUtilityTaxID.length < 9 || /^\d+$/.test(this.state.waterUtilityTaxID) !== true) {
                this.setState({ waterUtilityTaxID_Error: "Invalid Tax ID" });
                this.waterUtilityTaxIDRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ waterUtilityTaxID_Error: "" });
            }
        }
        else {
            this.setState({ waterUtilityTaxID_Error: "" });
        }

        if (this.state.waterUtilityDUNSNumber !== "" && this.state.waterUtilityDUNSNumber !== null && this.state.waterUtilityDUNSNumber !== undefined) {
            if (this.state.waterUtilityDUNSNumber.length < 9 || /^\d+$/.test(this.state.waterUtilityDUNSNumber) !== true) {
                this.setState({ waterUtilityDUNSNumber_Error: "Invalid DUNS Number" });
                this.waterUtilityDUNSNumberRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ waterUtilityDUNSNumber_Error: "" });
            }
        }
        else {
            this.setState({ waterUtilityDUNSNumber_Error: "" });
        }

        if (this.state.monthlyWaterBillAmount !== "" && this.state.monthlyWaterBillAmount !== null && this.state.monthlyWaterBillAmount !== undefined) {
            if (/^\d+(\.\d{1,2})?$/.test(this.state.monthlyWaterBillAmount) !== true) {
                this.setState({ monthlyWaterBillAmount_Error: "Invalid Amount" });
                this.monthlyWaterBillAmountRef.current.focus();

                validationError = true;
            }
            else if (this.state.monthlyWaterBillAmount > 99999.99) {
                this.setState({ monthlyWaterBillAmount_Error: "Exceeds limit" });
                this.monthlyWaterBillAmountRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ monthlyWaterBillAmount_Error: "" });
            }
        }
        else {
            this.setState({ monthlyWaterBillAmount_Error: "" });
        }

        // if (this.state.percentageOfWaterBill !== "" && this.state.percentageOfWaterBill !== null && this.state.percentageOfWaterBill !== undefined) {
        //     if (/^\d+$/.test(this.state.percentageOfWaterBill) !== true) {
        //         this.setState({ percentageOfWaterBill_Error: "Invalid Number" });
        //         this.percentageOfWaterBillRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ percentageOfWaterBill_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ percentageOfWaterBill_Error: "" });
        // }

        if (this.state.noOfMonthsWaterUtilityPayments !== "" && this.state.noOfMonthsWaterUtilityPayments !== null && this.state.noOfMonthsWaterUtilityPayments !== undefined) {
            if (/^\d+$/.test(this.state.noOfMonthsWaterUtilityPayments) !== true) {
                this.setState({ noOfMonthsWaterUtilityPayments_Error: "Invalid Number" });
                this.noOfMonthsWaterUtilityPaymentsRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ noOfMonthsWaterUtilityPayments_Error: "" });
            }
        }
        else {
            this.setState({ noOfMonthsWaterUtilityPayments_Error: "" });
        }


        // if (this.state.trashUtilityAccountNo !== "" && this.state.trashUtilityAccountNo !== null && this.state.trashUtilityAccountNo !== undefined) {
        //     if (/^\d+$/.test(this.state.trashUtilityAccountNo) !== true) {
        //         this.setState({ trashUtilityAccountNo_Error: "Invalid Account Number" });
        //         this.trashUtilityAccountNoRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ trashUtilityAccountNo_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ trashUtilityAccountNo_Error: "" });
        // }

        if (this.state.amountDueforTrash !== "" && this.state.amountDueforTrash !== null && this.state.amountDueforTrash !== undefined) {
            if (/^\d+(\.\d{1,2})?$/.test(this.state.amountDueforTrash) !== true) {
                this.setState({ amountDueforTrash_Error: "Invalid Amount" });
                this.amountDueforTrashRef.current.focus();

                validationError = true;
            }
            else if (this.state.amountDueforTrash > 99999.99) {
                this.setState({ amountDueforTrash_Error: "Exceeds limit" });
                this.amountDueforTrashRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ amountDueforTrash_Error: "" });
            }
        }
        else {
            this.setState({ amountDueforTrash_Error: "" });
        }

        // if (this.state.custTrashBillingAccountNo !== "" && this.state.custTrashBillingAccountNo !== null && this.state.custTrashBillingAccountNo !== undefined) {
        //     if (/^\d+$/.test(this.state.custTrashBillingAccountNo) !== true) {
        //         this.setState({ custTrashBillingAccountNo_Error: "Invalid Account Number" });
        //         this.custTrashBillingAccountNoRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ custTrashBillingAccountNo_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ custTrashBillingAccountNo_Error: "" });
        // }

        if (this.state.trashAmount !== "" && this.state.trashAmount !== null && this.state.trashAmount !== undefined) {
            if (/^\d+(\.\d{1,2})?$/.test(this.state.trashAmount) !== true) {
                this.setState({ trashAmount_Error: "Invalid Amount" });
                this.trashAmountRef.current.focus();

                validationError = true;
            }
            else if (this.state.trashAmount > 99999.99) {
                this.setState({ trashAmount_Error: "Exceeds limit" });
                this.trashAmountRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ trashAmount_Error: "" });
            }
        }
        else {
            this.setState({ trashAmount_Error: "" });
        }

        // if (this.state.trashUtilityProviderAccountNo !== "" && this.state.trashUtilityProviderAccountNo !== null && this.state.trashUtilityProviderAccountNo !== undefined) {
        //     if (/^\d+$/.test(this.state.trashUtilityProviderAccountNo) !== true) {
        //         this.setState({ trashUtilityProviderAccountNo_Error: "Invalid Account Number" });
        //         this.trashUtilityProviderAccountNoRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ trashUtilityProviderAccountNo_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ trashUtilityProviderAccountNo_Error: "" });
        // }

        if (this.state.trashRoutingNumber !== "" && this.state.trashRoutingNumber !== null && this.state.trashRoutingNumber !== undefined) {
            if (this.state.trashRoutingNumber.length < 9 || /^\d+$/.test(this.state.trashRoutingNumber) !== true) {
                this.setState({ trashRoutingNumber_Error: "Invalid Routing Number" });
                this.trashRoutingNumberRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ trashRoutingNumber_Error: "" });
            }
        }
        else {
            this.setState({ trashRoutingNumber_Error: "" });
        }

        // if (this.state.trashMemoAccountNo !== "" && this.state.trashMemoAccountNo !== null && this.state.trashMemoAccountNo !== undefined) {
        //     if (/^\d+$/.test(this.state.trashMemoAccountNo) !== true) {
        //         this.setState({ trashMemoAccountNo_Error: "Invalid Account Number" });
        //         this.trashMemoAccountNoRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ trashMemoAccountNo_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ trashMemoAccountNo_Error: "" });
        // }


        if (this.state.trashUtilityTaxID !== "" && this.state.trashUtilityTaxID !== null && this.state.trashUtilityTaxID !== undefined) {
            if (this.state.trashUtilityTaxID.length < 9 || /^\d+$/.test(this.state.trashUtilityTaxID) !== true) {
                this.setState({ trashUtilityTaxID_Error: "Invalid Tax ID" });
                this.trashUtilityTaxIDRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ trashUtilityTaxID_Error: "" });
            }
        }
        else {
            this.setState({ trashUtilityTaxID_Error: "" });
        }

        if (this.state.trashUtilityDUNSNumber !== "" && this.state.trashUtilityDUNSNumber !== null && this.state.trashUtilityDUNSNumber !== undefined) {
            if (this.state.trashUtilityDUNSNumber.length < 9 || /^\d+$/.test(this.state.trashUtilityDUNSNumber) !== true) {
                this.setState({ trashUtilityDUNSNumber_Error: "Invalid DUNS Number" });
                this.trashUtilityDUNSNumberRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ trashUtilityDUNSNumber_Error: "" });
            }
        }
        else {
            this.setState({ trashUtilityDUNSNumber_Error: "" });
        }

        if (this.state.monthlyTrashBillAmount !== "" && this.state.monthlyTrashBillAmount !== null && this.state.monthlyTrashBillAmount !== undefined) {
            if (/^\d+(\.\d{1,2})?$/.test(this.state.monthlyTrashBillAmount) !== true) {
                this.setState({ monthlyTrashBillAmount_Error: "Invalid Amount" });
                this.monthlyTrashBillAmountRef.current.focus();

                validationError = true;
            }
            else if (this.state.monthlyTrashBillAmount > 99999.99) {
                this.setState({ monthlyTrashBillAmount_Error: "Exceeds limit" });
                this.monthlyTrashBillAmountRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ monthlyTrashBillAmount_Error: "" });
            }
        }
        else {
            this.setState({ monthlyTrashBillAmount_Error: "" });
        }

        // if (this.state.percentageOfTrashBill !== "" && this.state.percentageOfTrashBill !== null && this.state.percentageOfTrashBill !== undefined) {
        //     if (/^\d+$/.test(this.state.percentageOfTrashBill) !== true) {
        //         this.setState({ percentageOfTrashBill_Error: "Invalid Number" });
        //         this.percentageOfTrashBillRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ percentageOfTrashBill_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ percentageOfTrashBill_Error: "" });
        // }

        if (this.state.noOfMonthsTrashUtilityPayments !== "" && this.state.noOfMonthsTrashUtilityPayments !== null && this.state.noOfMonthsTrashUtilityPayments !== undefined) {
            if (/^\d+$/.test(this.state.noOfMonthsTrashUtilityPayments) !== true) {
                this.setState({ noOfMonthsTrashUtilityPayments_Error: "Invalid Number" });
                this.noOfMonthsTrashUtilityPaymentsRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ noOfMonthsTrashUtilityPayments_Error: "" });
            }
        }
        else {
            this.setState({ noOfMonthsTrashUtilityPayments_Error: "" });
        }


        // if (this.state.energyUtilityAccountNo !== "" && this.state.energyUtilityAccountNo !== null && this.state.energyUtilityAccountNo !== undefined) {
        //     if (/^\d+$/.test(this.state.energyUtilityAccountNo) !== true) {
        //         this.setState({ energyUtilityAccountNo_Error: "Invalid Account Number" });
        //         this.energyUtilityAccountNoRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ energyUtilityAccountNo_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ energyUtilityAccountNo_Error: "" });
        // }

        if (this.state.amountDueforEnergy !== "" && this.state.amountDueforEnergy !== null && this.state.amountDueforEnergy !== undefined) {
            if (/^\d+(\.\d{1,2})?$/.test(this.state.amountDueforEnergy) !== true) {
                this.setState({ amountDueforEnergy_Error: "Invalid Amount" });
                this.amountDueforEnergyRef.current.focus();

                validationError = true;
            }
            else if (this.state.amountDueforEnergy > 99999.99) {
                this.setState({ amountDueforEnergy_Error: "Exceeds limit" });
                this.amountDueforEnergyRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ amountDueforEnergy_Error: "" });
            }
        }
        else {
            this.setState({ amountDueforEnergy_Error: "" });
        }

        // if (this.state.custEnergyBillingAccountNo !== "" && this.state.custEnergyBillingAccountNo !== null && this.state.custEnergyBillingAccountNo !== undefined) {
        //     if (/^\d+$/.test(this.state.custEnergyBillingAccountNo) !== true) {
        //         this.setState({ custEnergyBillingAccountNo_Error: "Invalid Account Number" });
        //         this.custEnergyBillingAccountNoRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ custEnergyBillingAccountNo_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ custEnergyBillingAccountNo_Error: "" });
        // }

        if (this.state.energyAmount !== "" && this.state.energyAmount !== null && this.state.energyAmount !== undefined) {
            if (/^\d+(\.\d{1,2})?$/.test(this.state.energyAmount) !== true) {
                this.setState({ energyAmount_Error: "Invalid Amount" });
                this.energyAmountRef.current.focus();

                validationError = true;
            }
            else if (this.state.energyAmount > 99999.99) {
                this.setState({ energyAmount_Error: "Exceeds limit" });
                this.energyAmountRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ energyAmount_Error: "" });
            }
        }
        else {
            this.setState({ energyAmount_Error: "" });
        }

        // if (this.state.energyUtilityProviderAccountNo !== "" && this.state.energyUtilityProviderAccountNo !== null && this.state.energyUtilityProviderAccountNo !== undefined) {
        //     if (/^\d+$/.test(this.state.energyUtilityProviderAccountNo) !== true) {
        //         this.setState({ energyUtilityProviderAccountNo_Error: "Invalid Account Number" });
        //         this.energyUtilityProviderAccountNoRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ energyUtilityProviderAccountNo_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ energyUtilityProviderAccountNo_Error: "" });
        // }

        if (this.state.energyRoutingNumber !== "" && this.state.energyRoutingNumber !== null && this.state.energyRoutingNumber !== undefined) {
            if (this.state.energyRoutingNumber.length < 9 || /^\d+$/.test(this.state.energyRoutingNumber) !== true) {
                this.setState({ energyRoutingNumber_Error: "Invalid Routing Number" });
                this.energyRoutingNumberRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ energyRoutingNumber_Error: "" });
            }
        }
        else {
            this.setState({ energyRoutingNumber_Error: "" });
        }

        // if (this.state.energyMemoAccountNo !== "" && this.state.energyMemoAccountNo !== null && this.state.energyMemoAccountNo !== undefined) {
        //     if (/^\d+$/.test(this.state.energyMemoAccountNo) !== true) {
        //         this.setState({ energyMemoAccountNo_Error: "Invalid Account Number" });
        //         this.energyMemoAccountNoRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ energyMemoAccountNo_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ energyMemoAccountNo_Error: "" });
        // }


        if (this.state.energyUtilityTaxID !== "" && this.state.energyUtilityTaxID !== null && this.state.energyUtilityTaxID !== undefined) {
            if (this.state.energyUtilityTaxID.length < 9 || /^\d+$/.test(this.state.energyUtilityTaxID) !== true) {
                this.setState({ energyUtilityTaxID_Error: "Invalid Tax ID" });
                this.energyUtilityTaxIDRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ energyUtilityTaxID_Error: "" });
            }
        }
        else {
            this.setState({ energyUtilityTaxID_Error: "" });
        }

        if (this.state.energyUtilityDUNSNumber !== "" && this.state.energyUtilityDUNSNumber !== null && this.state.energyUtilityDUNSNumber !== undefined) {
            if (this.state.energyUtilityDUNSNumber.length < 9 || /^\d+$/.test(this.state.energyUtilityDUNSNumber) !== true) {
                this.setState({ energyUtilityDUNSNumber_Error: "Invalid DUNS Number" });
                this.energyUtilityDUNSNumberRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ energyUtilityDUNSNumber_Error: "" });
            }
        }
        else {
            this.setState({ energyUtilityDUNSNumber_Error: "" });
        }

        if (this.state.monthlyEnergyBillAmount !== "" && this.state.monthlyEnergyBillAmount !== null && this.state.monthlyEnergyBillAmount !== undefined) {
            if (/^\d+(\.\d{1,2})?$/.test(this.state.monthlyEnergyBillAmount) !== true) {
                this.setState({ monthlyEnergyBillAmount_Error: "Invalid Amount" });
                this.monthlyEnergyBillAmountRef.current.focus();

                validationError = true;
            }
            else if (this.state.monthlyEnergyBillAmount > 99999.99) {
                this.setState({ monthlyEnergyBillAmount_Error: "Exceeds limit" });
                this.monthlyEnergyBillAmountRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ monthlyEnergyBillAmount_Error: "" });
            }
        }
        else {
            this.setState({ monthlyEnergyBillAmount_Error: "" });
        }

        // if (this.state.percentageOfEnergyBill !== "" && this.state.percentageOfEnergyBill !== null && this.state.percentageOfEnergyBill !== undefined) {
        //     if (/^\d+$/.test(this.state.percentageOfEnergyBill) !== true) {
        //         this.setState({ percentageOfEnergyBill_Error: "Invalid Number" });
        //         this.percentageOfEnergyBillRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ percentageOfEnergyBill_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ percentageOfEnergyBill_Error: "" });
        // }

        if (this.state.noOfMonthsEnergyUtilityPayments !== "" && this.state.noOfMonthsEnergyUtilityPayments !== null && this.state.noOfMonthsEnergyUtilityPayments !== undefined) {
            if (/^\d+$/.test(this.state.noOfMonthsEnergyUtilityPayments) !== true) {
                this.setState({ noOfMonthsEnergyUtilityPayments_Error: "Invalid Number" });
                this.noOfMonthsEnergyUtilityPaymentsRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ noOfMonthsEnergyUtilityPayments_Error: "" });
            }
        }
        else {
            this.setState({ noOfMonthsEnergyUtilityPayments_Error: "" });
        }

        if (this.state.otherPaymentAmount !== "" && this.state.otherPaymentAmount !== null && this.state.otherPaymentAmount !== undefined) {
            if (/^\d+(\.\d{1,2})?$/.test(this.state.otherPaymentAmount) !== true) {
                this.setState({ otherPaymentAmount_Error: "Invalid Amount" });
                this.otherPaymentAmountRef.current.focus();

                validationError = true;
            }
            else if (this.state.otherPaymentAmount > 99999.99) {
                this.setState({ otherPaymentAmount_Error: "Exceeds limit" });
                this.otherPaymentAmountRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ otherPaymentAmount_Error: "" });
            }
        }
        else {
            this.setState({ otherPaymentAmount_Error: "" });
        }

        if (this.state.totalHouseholdAnnualIncome !== "" && this.state.totalHouseholdAnnualIncome !== null && this.state.totalHouseholdAnnualIncome !== undefined) {
            if (/^\d+(\.\d{1,2})?$/.test(this.state.totalHouseholdAnnualIncome) !== true) {
                this.setState({ totalHouseholdAnnualIncome_Error: "Invalid Amount" });
                this.totalHouseholdAnnualIncomeRef.current.focus();

                validationError = true;
            }
            else if (this.state.totalHouseholdAnnualIncome > 9999999.99) {
                this.setState({ totalHouseholdAnnualIncome_Error: "Exceeds limit" });
                this.totalHouseholdAnnualIncomeRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ totalHouseholdAnnualIncome_Error: "" });
            }
        }
        else {
            this.setState({ totalHouseholdAnnualIncome_Error: "" });
        }

        if (this.state.spaServiceProviderEmail !== "" && this.state.spaServiceProviderEmail !== null && this.state.spaServiceProviderEmail !== undefined) {
            var spaServiceProviderEmailPattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!spaServiceProviderEmailPattern.test(this.state.spaServiceProviderEmail)) {
                this.setState({ spaServiceProviderEmail_Error: "Invalid Email" });
                this.spaServiceProviderEmailRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ spaServiceProviderEmail_Error: "" });
            }
        }
        else {
            this.setState({ spaServiceProviderEmail_Error: "" });
        }

        if (this.state.rentPaymentStatus_Original !== this.state.rentPaymentStatus && this.state.rentPaymentStatus === 'Issued') {
            // if (this.state.landlordEmail === null || this.state.landlordEmail === "" || this.state.landlordEmail === undefined) {
            //     this.setState({ landlordEmail_Error: "Email required" });
            //     this.landlordEmailRef.current.focus();

            //     validationError = true;
            // }
            // else {
            //     this.setState({ landlordEmail_Error: "" });
            // }

            // if (this.state.spaServiceProviderEmail === null || this.state.spaServiceProviderEmail === "" || this.state.spaServiceProviderEmail === undefined) {
            //     this.setState({ spaServiceProviderEmail_Error: "Email required" });
            //     this.spaServiceProviderEmailRef.current.focus();

            //     validationError = true;
            // }
            // else {
            //     this.setState({ spaServiceProviderEmail_Error: "" });
            // }

            if (this.state.firstName === "" || this.state.firstName === null || this.state.firstName === undefined) {
                this.setState({ firstName_Error: "First Name required" });
                this.firstNameRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ firstName_Error: "" });
            }

            if (this.state.addressLine1 === "" || this.state.addressLine1 === null || this.state.addressLine1 === undefined) {
                this.setState({ addressLine1_Error: "Address required" });
                this.addressLine1Ref.current.focus();

                validationError = true;
            }
            else {
                this.setState({ addressLine1_Error: "" });
            }

            if (this.state.city === "" || this.state.city === null || this.state.city === undefined) {
                this.setState({ city_Error: "City required" });
                this.cityRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ city_Error: "" });
            }

            if (this.state.state === "" || this.state.state === null || this.state.state === undefined) {
                this.setState({ state_Error: "State required" });
                this.stateRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ state_Error: "" });
            }

            if (this.state.zipcode === "" || this.state.zipcode === null || this.state.zipcode === undefined) {
                this.setState({ zipcode_Error: "Zip required" });
                this.zipcodeRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ zipcode_Error: "" });
            }

            // if (this.state.uniqueID === "" || this.state.uniqueID === null || this.state.uniqueID === undefined) {
            //     this.setState({ uniqueID_Error: "Unique ID required" });
            //     this.uniqueIDRef.current.focus();

            //     validationError = true;
            // }
            // else {
            //     this.setState({ uniqueID_Error: "" });
            // }
        }

        if (this.state.noOfTimesParticipated !== "" && this.state.noOfTimesParticipated !== null && this.state.noOfTimesParticipated !== undefined) {
            if (/^\d+$/.test(this.state.noOfTimesParticipated) !== true) {
                this.setState({ noOfTimesParticipated_Error: "Invalid Number" });
                this.noOfTimesParticipatedRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ noOfTimesParticipated_Error: "" });
            }
        }
        else {
            this.setState({ noOfTimesParticipated_Error: "" });
        }

        if (this.state.landlordSSN !== "" && this.state.landlordSSN !== null && this.state.landlordSSN !== undefined) {
            if (this.state.landlordSSN.length < 9 || /^\d+$/.test(this.state.landlordSSN) !== true) {
                this.setState({ landlordSSN_Error: "Invalid SSN Number" });
                this.landlordSSNRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ landlordSSN_Error: "" });
            }
        }
        else {
            this.setState({ landlordSSN_Error: "" });
        }

        if (this.state.landlordTaxID !== "" && this.state.landlordTaxID !== null && this.state.landlordTaxID !== undefined) {
            if (this.state.landlordTaxID.length < 9 || /^\d+$/.test(this.state.landlordTaxID) !== true) {
                this.setState({ landlordTaxID_Error: "Invalid Tax ID" });
                this.landlordTaxIDRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ landlordTaxID_Error: "" });
            }
        }
        else {
            this.setState({ landlordTaxID_Error: "" });
        }

        if (this.state.landlordDUNSNumber !== "" && this.state.landlordDUNSNumber !== null && this.state.landlordDUNSNumber !== undefined) {
            if (this.state.landlordDUNSNumber.length < 9 || /^\d+$/.test(this.state.landlordDUNSNumber) !== true) {
                this.setState({ landlordDUNSNumber_Error: "Invalid DUNS Number" });
                this.landlordDUNSNumberRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ landlordDUNSNumber_Error: "" });
            }
        }
        else {
            this.setState({ landlordDUNSNumber_Error: "" });
        }

        if (this.state.monthlyRentPayment !== "" && this.state.monthlyRentPayment !== null && this.state.monthlyRentPayment !== undefined) {
            if (/^\d+(\.\d{1,2})?$/.test(this.state.monthlyRentPayment) !== true) {
                this.setState({ monthlyRentPayment_Error: "Invalid Amount" });
                this.monthlyRentPaymentRef.current.focus();

                validationError = true;
            }
            else if (this.state.monthlyRentPayment > 99999.99) {
                this.setState({ monthlyRentPayment_Error: "Exceeds limit" });
                this.monthlyRentPaymentRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ monthlyRentPayment_Error: "" });
            }
        }
        else {
            this.setState({ monthlyRentPayment_Error: "" });
        }

        // if (this.state.percentageOfMonthlyRent !== "" && this.state.percentageOfMonthlyRent !== null && this.state.percentageOfMonthlyRent !== undefined) {
        //     if (/^\d+$/.test(this.state.percentageOfMonthlyRent) !== true) {
        //         this.setState({ percentageOfMonthlyRent_Error: "Invalid Number" });
        //         this.percentageOfMonthlyRentRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ percentageOfMonthlyRent_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ percentageOfMonthlyRent_Error: "" });
        // }

        if (this.state.monthsBehindOnRent !== "" && this.state.monthsBehindOnRent !== null && this.state.monthsBehindOnRent !== undefined) {
            if (/^\d+$/.test(this.state.monthsBehindOnRent) !== true) {
                this.setState({ monthsBehindOnRent_Error: "Invalid Number" });
                this.monthsBehindOnRentRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ monthsBehindOnRent_Error: "" });
            }
        }
        else {
            this.setState({ monthsBehindOnRent_Error: "" });
        }

        if (this.state.outstandingRentalArrearsAmount !== "" && this.state.outstandingRentalArrearsAmount !== null && this.state.outstandingRentalArrearsAmount !== undefined) {
            if (/^\d+(\.\d{1,2})?$/.test(this.state.outstandingRentalArrearsAmount) !== true) {
                this.setState({ outstandingRentalArrearsAmount_Error: "Invalid Amount" });
                this.outstandingRentalArrearsAmountRef.current.focus();

                validationError = true;
            }
            else if (this.state.outstandingRentalArrearsAmount > 99999.99) {
                this.setState({ outstandingRentalArrearsAmount_Error: "Exceeds limit" });
                this.outstandingRentalArrearsAmountRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ outstandingRentalArrearsAmount_Error: "" });
            }
        }
        else {
            this.setState({ outstandingRentalArrearsAmount_Error: "" });
        }

        if (this.state.electricUtilityTaxID !== "" && this.state.electricUtilityTaxID !== null && this.state.electricUtilityTaxID !== undefined) {
            if (this.state.electricUtilityTaxID.length < 9 || /^\d+$/.test(this.state.electricUtilityTaxID) !== true) {
                this.setState({ electricUtilityTaxID_Error: "Invalid Tax ID" });
                this.electricUtilityTaxIDRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ electricUtilityTaxID_Error: "" });
            }
        }
        else {
            this.setState({ electricUtilityTaxID_Error: "" });
        }

        if (this.state.electricUtilityDUNSNumber !== "" && this.state.electricUtilityDUNSNumber !== null && this.state.electricUtilityDUNSNumber !== undefined) {
            if (this.state.electricUtilityDUNSNumber.length < 9 || /^\d+$/.test(this.state.electricUtilityDUNSNumber) !== true) {
                this.setState({ electricUtilityDUNSNumber_Error: "Invalid DUNS Number" });
                this.electricUtilityDUNSNumberRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ electricUtilityDUNSNumber_Error: "" });
            }
        }
        else {
            this.setState({ electricUtilityDUNSNumber_Error: "" });
        }

        if (this.state.monthlyElectricBillAmount !== "" && this.state.monthlyElectricBillAmount !== null && this.state.monthlyElectricBillAmount !== undefined) {
            if (/^\d+(\.\d{1,2})?$/.test(this.state.monthlyElectricBillAmount) !== true) {
                this.setState({ monthlyElectricBillAmount_Error: "Invalid Amount" });
                this.monthlyElectricBillAmountRef.current.focus();

                validationError = true;
            }
            else if (this.state.monthlyElectricBillAmount > 99999.99) {
                this.setState({ monthlyElectricBillAmount_Error: "Exceeds limit" });
                this.monthlyElectricBillAmountRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ monthlyElectricBillAmount_Error: "" });
            }
        }
        else {
            this.setState({ monthlyElectricBillAmount_Error: "" });
        }

        // if (this.state.percentageOfElectricBill !== "" && this.state.percentageOfElectricBill !== null && this.state.percentageOfElectricBill !== undefined) {
        //     if (/^\d+$/.test(this.state.percentageOfElectricBill) !== true) {
        //         this.setState({ percentageOfElectricBill_Error: "Invalid Number" });
        //         this.percentageOfElectricBillRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ percentageOfElectricBill_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ percentageOfElectricBill_Error: "" });
        // }

        if (this.state.noOfMonthsElectricUtilityPayments !== "" && this.state.noOfMonthsElectricUtilityPayments !== null && this.state.noOfMonthsElectricUtilityPayments !== undefined) {
            if (/^\d+$/.test(this.state.noOfMonthsElectricUtilityPayments) !== true) {
                this.setState({ noOfMonthsElectricUtilityPayments_Error: "Invalid Number" });
                this.noOfMonthsElectricUtilityPaymentsRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ noOfMonthsElectricUtilityPayments_Error: "" });
            }
        }
        else {
            this.setState({ noOfMonthsElectricUtilityPayments_Error: "" });
        }

        if (this.state.effectOfCovid19Financially === 'Other' &&
            this.state.covid_other !== "" && this.state.covid_other !== null && this.state.covid_other !== undefined) {
            if (this.state.covid_other.length > 100) {
                this.setState({ covid_other_Error: "Text too long" });
                this.covid_otherRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ covid_other_Error: "" });
            }
        }
        else {
            this.setState({ covid_other_Error: "" });
        }

        if (this.state.workIndustry === 'Other' &&
            this.state.industry_other !== "" && this.state.industry_other !== null && this.state.industry_other !== undefined) {
            if (this.state.industry_other.length > 100) {
                this.setState({ industry_other_Error: "Text too long" });
                this.industry_otherRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ industry_other_Error: "" });
            }
        }
        else {
            this.setState({ industry_other_Error: "" });
        }

        if (this.state.email !== "" && this.state.email !== null && this.state.email !== undefined) {
            var emailPattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!emailPattern.test(this.state.email)) {
                this.setState({ email_Error: "Invalid Email" });
                this.emailRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ email_Error: "" });
            }
        }
        else {
            this.setState({ email_Error: "" });
        }


        // if (this.state.individualAccountNumber !== "" && this.state.individualAccountNumber !== null && this.state.individualAccountNumber !== undefined) {
        //     if (/^\d+$/.test(this.state.individualAccountNumber) !== true) {
        //         this.setState({ individualAccountNumber_Error: "Invalid Account Number" });
        //         this.individualAccountNumberRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ individualAccountNumber_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ individualAccountNumber_Error: "" });
        // }


        if (this.state.individualRoutingNumber !== "" && this.state.individualRoutingNumber !== null && this.state.individualRoutingNumber !== undefined) {
            if (this.state.individualRoutingNumber.length < 9 || /^\d+$/.test(this.state.individualRoutingNumber) !== true) {
                this.setState({ individualRoutingNumber_Error: "Invalid Routing Number" });
                this.individualRoutingNumberRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ individualRoutingNumber_Error: "" });
            }
        }
        else {
            this.setState({ individualRoutingNumber_Error: "" });
        }


        if (this.state.individualPaymentAmount !== "" && this.state.individualPaymentAmount !== null && this.state.individualPaymentAmount !== undefined) {
            if (/^\d+(\.\d{1,2})?$/.test(this.state.individualPaymentAmount) !== true) {
                this.setState({ individualPaymentAmount_Error: "Invalid Amount" });
                this.individualPaymentAmountRef.current.focus();

                validationError = true;
            }
            else if (this.state.individualPaymentAmount > 99999.99) {
                this.setState({ individualPaymentAmount_Error: "Exceeds limit" });
                this.individualPaymentAmountRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ individualPaymentAmount_Error: "" });
            }
        }
        else {
            this.setState({ individualPaymentAmount_Error: "" });
        }

        if (this.state.individualMemoPropertyAddress !== "" && this.state.individualMemoPropertyAddress !== null && this.state.individualMemoPropertyAddress !== undefined) {
            if (this.state.individualMemoPropertyAddress.length > 100) {
                this.setState({ individualMemoPropertyAddress_Error: "Address too long" });
                this.individualMemoPropertyAddressRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ individualMemoPropertyAddress_Error: "" });
            }
        }
        else {
            this.setState({ individualMemoPropertyAddress_Error: "" });
        }

        // if (this.state.otherAccountNumber !== "" && this.state.otherAccountNumber !== null && this.state.otherAccountNumber !== undefined) {
        //     if (/^\d+$/.test(this.state.otherAccountNumber) !== true) {
        //         this.setState({ otherAccountNumber_Error: "Invalid Account Number" });
        //         this.otherAccountNumberRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ otherAccountNumber_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ otherAccountNumber_Error: "" });
        // }


        if (this.state.otherRoutingNumber !== "" && this.state.otherRoutingNumber !== null && this.state.otherRoutingNumber !== undefined) {
            if (this.state.otherRoutingNumber.length < 9 || /^\d+$/.test(this.state.otherRoutingNumber) !== true) {
                this.setState({ otherRoutingNumber_Error: "Invalid Routing Number" });
                this.otherRoutingNumberRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ otherRoutingNumber_Error: "" });
            }
        }
        else {
            this.setState({ otherRoutingNumber_Error: "" });
        }


        if (this.state.otherPaymentAmount !== "" && this.state.otherPaymentAmount !== null && this.state.otherPaymentAmount !== undefined) {
            if (/^\d+(\.\d{1,2})?$/.test(this.state.otherPaymentAmount) !== true) {
                this.setState({ otherPaymentAmount_Error: "Invalid Amount" });
                this.otherPaymentAmountRef.current.focus();

                validationError = true;
            }
            else if (this.state.otherPaymentAmount > 99999.99) {
                this.setState({ otherPaymentAmount_Error: "Exceeds limit" });
                this.otherPaymentAmountRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ otherPaymentAmount_Error: "" });
            }
        }
        else {
            this.setState({ otherPaymentAmount_Error: "" });
        }

        if (this.state.otherMemoPropertyAddress !== "" && this.state.otherMemoPropertyAddress !== null && this.state.otherMemoPropertyAddress !== undefined) {
            if (this.state.otherMemoPropertyAddress.length > 100) {
                this.setState({ otherMemoPropertyAddress_Error: "Address too long" });
                this.otherMemoPropertyAddressRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ otherMemoPropertyAddress_Error: "" });
            }
        }
        else {
            this.setState({ otherMemoPropertyAddress_Error: "" });
        }

        if (this.state.otherPayeeName !== "" && this.state.otherPayeeName !== null && this.state.otherPayeeName !== undefined) {
            if (this.state.otherPayeeName.length > 100) {
                this.setState({ otherPayeeName_Error: "Name too long" });
                this.otherPayeeNameRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ otherPayeeName_Error: "" });
            }
        }
        else {
            this.setState({ otherPayeeName_Error: "" });
        }

        if (this.state.otherDesription !== "" && this.state.otherDesription !== null && this.state.otherDesription !== undefined) {
            if (this.state.otherDesription.length > 100) {
                this.setState({ otherDesription_Error: "Description too long" });
                this.otherDesriptionRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ otherDesription_Error: "" });
            }
        }
        else {
            this.setState({ otherDesription_Error: "" });
        }

        if (this.state.notes !== "" && this.state.notes !== null && this.state.notes !== undefined) {
            if (this.state.notes.length > 15000) {
                this.setState({ notes_Error: "Notes too long" });
                this.notesRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ notes_Error: "" });
            }
        }
        else {
            this.setState({ notes_Error: "" });
        }

        ///////////////////////////   VALIDATION ENDS   ///////////////////////////

        if (validationError === true) {
            this.setState({ isLoading: false });
            return false;
        }

        return true;
    }

    loadIndividualDetails = async (individual) => {

        this.setState({ isLoading: true, individualID: individual });

        // check the current user when the App component is loaded
        var adminUser = '';
        Auth.currentAuthenticatedUser().then(user => {
            //console.log(user);
            adminUser = user.attributes.email;
            this.setState({ authState: 'signedIn' });
        }).catch(e => {
            console.log(e);
            if(e === "not authenticated") {
                this.signOut();
            }
            this.setState({ authState: 'signIn' });
        });

        await this.loadData();

        // await this.dummyRequest();

        // Set the logged-in admin user
        this.setState({ modifiedBy: adminUser });

        // Restore the Issued Payment Status if the Case is not open.
        if (this.state.individualCase !== 'Open' && this.state.individualCase !== 'Ineligible' &&
            this.state.individualCase !== '' && this.state.individualCase !== null && this.state.individualCase !== undefined) {

            var rentPaymentStatusSelect = document.getElementById("rentPaymentStatusId");
            if (!(rentPaymentStatusSelect.options.length > 5)) {
                var rentPaymentStatusIssuedOption = document.createElement("option");
                rentPaymentStatusIssuedOption.text = "Issued";
                rentPaymentStatusIssuedOption.value = "Issued";
                rentPaymentStatusSelect.appendChild(rentPaymentStatusIssuedOption);
            }

            var electricPaymentStatusSelect = document.getElementById("electricPaymentStatusId");
            if (!(electricPaymentStatusSelect.options.length > 5)) {
                var electricPaymentStatusIssuedOption = document.createElement("option");
                electricPaymentStatusIssuedOption.text = "Issued";
                electricPaymentStatusIssuedOption.value = "Issued";
                electricPaymentStatusSelect.appendChild(electricPaymentStatusIssuedOption);
            }

            var gasPaymentStatusSelect = document.getElementById("gasPaymentStatusId");
            if (!(gasPaymentStatusSelect.options.length > 5)) {
                var gasPaymentStatusIssuedOption = document.createElement("option");
                gasPaymentStatusIssuedOption.text = "Issued";
                gasPaymentStatusIssuedOption.value = "Issued";
                gasPaymentStatusSelect.appendChild(gasPaymentStatusIssuedOption);
            }

            var waterPaymentStatusSelect = document.getElementById("waterPaymentStatusId");
            if (!(waterPaymentStatusSelect.options.length > 5)) {
                var waterPaymentStatusIssuedOption = document.createElement("option");
                waterPaymentStatusIssuedOption.text = "Issued";
                waterPaymentStatusIssuedOption.value = "Issued";
                waterPaymentStatusSelect.appendChild(waterPaymentStatusIssuedOption);
            }

            var trashPaymentStatusSelect = document.getElementById("trashPaymentStatusId");
            if (!(trashPaymentStatusSelect.options.length > 5)) {
                var trashPaymentStatusIssuedOption = document.createElement("option");
                trashPaymentStatusIssuedOption.text = "Issued";
                trashPaymentStatusIssuedOption.value = "Issued";
                trashPaymentStatusSelect.appendChild(trashPaymentStatusIssuedOption);
            }

            var energyPaymentStatusSelect = document.getElementById("energyPaymentStatusId");
            if (!(energyPaymentStatusSelect.options.length > 5)) {
                var energyPaymentStatusIssuedOption = document.createElement("option");
                energyPaymentStatusIssuedOption.text = "Issued";
                energyPaymentStatusIssuedOption.value = "Issued";
                energyPaymentStatusSelect.appendChild(energyPaymentStatusIssuedOption);
            }

            var individualPaymentStatusSelect = document.getElementById("individualPaymentStatusId");
            if (!(individualPaymentStatusSelect.options.length > 5)) {
                var individualPaymentStatusIssuedOption = document.createElement("option");
                individualPaymentStatusIssuedOption.text = "Issued";
                individualPaymentStatusIssuedOption.value = "Issued";
                individualPaymentStatusSelect.appendChild(individualPaymentStatusIssuedOption);
            }

            var otherPaymentStatusSelect = document.getElementById("otherPaymentStatusId");
            if (!(otherPaymentStatusSelect.options.length > 5)) {
                var otherPaymentStatusIssuedOption = document.createElement("option");
                otherPaymentStatusIssuedOption.text = "Issued";
                otherPaymentStatusIssuedOption.value = "Issued";
                otherPaymentStatusSelect.appendChild(otherPaymentStatusIssuedOption);
            }
        }
        else if (this.state.individualCase === 'Open') {
            var rentPaymentStatusSelect = document.getElementById("rentPaymentStatusId");
            if (rentPaymentStatusSelect.options.length > 5) {
                rentPaymentStatusSelect.remove(5);
            }

            var electricPaymentStatusSelect = document.getElementById("electricPaymentStatusId");
            if (electricPaymentStatusSelect.options.length > 5) {
                electricPaymentStatusSelect.remove(5);
            }

            var gasPaymentStatusSelect = document.getElementById("gasPaymentStatusId");
            if (gasPaymentStatusSelect.options.length > 5) {
                gasPaymentStatusSelect.remove(5);
            }

            var waterPaymentStatusSelect = document.getElementById("waterPaymentStatusId");
            if (waterPaymentStatusSelect.options.length > 5) {
                waterPaymentStatusSelect.remove(5);
            }

            var trashPaymentStatusSelect = document.getElementById("trashPaymentStatusId");
            if (trashPaymentStatusSelect.options.length > 5) {
                trashPaymentStatusSelect.remove(5);
            }

            var energyPaymentStatusSelect = document.getElementById("energyPaymentStatusId");
            if (energyPaymentStatusSelect.options.length > 5) {
                energyPaymentStatusSelect.remove(5);
            }

            var individualPaymentStatusSelect = document.getElementById("individualPaymentStatusId");
            if (individualPaymentStatusSelect.options.length > 5) {
                individualPaymentStatusSelect.remove(5);
            }

            var otherPaymentStatusSelect = document.getElementById("otherPaymentStatusId");
            if (otherPaymentStatusSelect.options.length > 5) {
                otherPaymentStatusSelect.remove(5);
            }
        }
        else if (this.state.individualCase === 'Ineligible') {
            document.getElementById("individualCaseId").disabled = false;
        }

        this.setState({ isLoading: false });
    }

    componentDidMount = async () => {
        this.setState({isLoading: true});
        this.props.parentCallback("EDIT ERA-IRVINE APPLICATION", "", imgSearchclaim);
        try{
            this.getCurrentUserPermissions();
        }
        catch(error){
            console.log(error);      
            await this.signOut();      
        };
        this.loadIndividualDetails(this.props.match.params.id);

        // this.setState({ isLoading: true });
        // await this.dummyRequest();
        // this.setState({ isLoading: false });
    }

    getCurrentUserPermissions= async ()=>{
        const{ permissions } = this.props;
        if (permissions != null && permissions.length > 0) {
            if (permissions.includes('IrvineClaimUser') && (permissions.indexOf("IrvineClaim") == -1)) {
                document.getElementById("myset").disabled = true;
                this.setState({ mode: "User" });
            }
        }
    }

    dummyRequest = () => {
        return new Promise((resolve, reject) => {
            window.setTimeout(
                () => {
                    resolve();
                },
                2000
            );
        });
    }

    loadData = async () => {
        try {
            var res = await this.adminGetIndividual();
            if (res.length === 0) {
                console.log("ERROR: IndividualID is not available in System." + this.state.individualID);
                return;
            }
            //console.log(res[0]);

            var recList = await this.getReconciliationList();
            this.setState({ reconciliationList: recList, reconciliationListClone: JSON.parse(JSON.stringify(recList)) });

            this.setState({

                firstName: res[0][0],
                middleName: res[0][1],
                lastName: res[0][2],
                suffix: res[0][3],
                addressLine1: res[0][4],
                addressLine2: res[0][5],
                city: res[0][6],
                state: res[0][7],
                zipcode: res[0][8],
                phone: res[0][9],
                phoneType: res[0][10],
                email: res[0][11],
                gender: res[0][12],
                race: res[0][13],
                ethnicity: res[0][14],
                dateOfBirth: (res[0][15] === '1000-01-01') ? '0000-00-00' : res[0][15],
                interpretationServicesRequiredForLanguage: res[0][16],
                secondaryFirstName: res[0][17],
                secondaryLastName: res[0][18],
                secondaryEmail: res[0][19],
                secondaryPhone: res[0][20],
                householdNumber: res[0][21],
                countAdult: res[0][22],
                countChildren: res[0][23],
                headOfHousehold: res[0][24],
                veteran: res[0][25],
                currentMonthlyIncome: res[0][26],
                totalHouseholdAnnualIncome: res[0][27],
                qualifiedForUnemploymentBenefits: res[0][28],
                experiencedReductionInHouseholdIncome: res[0][29],
                incurredSignificantCosts: res[0][30],
                reductionOfChildSupport: res[0][31],
                otherFinancialHardship: res[0][32],
                isSomeoneOnRentalOrLeaseAgreement: res[0][33],

                landlordFullName: res[0][34],
                landlordEmail: res[0][35],
                landlordEmail_Original: res[0][35],
                landlordPhone: res[0][36],
                monthlyRentPayment: res[0][37],
                monthsBehindOnRent: res[0][38],
                amountBehindOnRent: res[0][39],

                receivedHousingSubsidy: res[0][40],
                subsidyExplanation: res[0][41],
                filedCovid19FinancialStressDeclaration: res[0][42],
                receivedEvictionNotice: res[0][43],
                dateEvictionNoticeReceived: (res[0][44] === '1000-01-01') ? '0000-00-00' : res[0][44],

                createdDate: res[0][45],
                createdTime: res[0][46],

                doYouLiveInIrvine: res[0][47],
                doYouRent: res[0][48],
                areYouBehindOnRent: res[0][49],
                doYouHoldSection8HousingVoucher: res[0][50],
                experiencedFinancialHardshipInCovid: res[0][51],
                isAnnualIncomeLessThanMaxLimit: res[0][52],
                belowThirtyPercAMI: res[0][53],
                thirtyToFiftyPercAMI: res[0][54],
                fiftyToEightyPercAMI: res[0][55],

                individualID: res[0][56],
                agent: res[0][57],
                individualCase: res[0][58],
                individualCase_Original: res[0][58],
                rentPaymentStatus: (res[0][59] === null) ? 'Not Approved for Payment' : res[0][59],
                rentPaymentStatus_Original: (res[0][59] === null) ? 'Not Approved for Payment' : res[0][59],
                dontKnowDateOfBirth: res[0][60],
                ipAddress: res[0][61],

                age: res[0][62],
                applicationAccepted: res[0][63],
                applicationAccepted_Original: res[0][63],
                householdApprovedForUnitedWay: res[0][64],
                programTitle: res[0][65],


                // Other Details Section	
                uniqueID: res[0][66],
                SSN: res[0][67],
                socialSecurityFirstName: res[0][68],
                socialSecurityLastName: res[0][69],

                lengthOfTimeForAward: null, // (Calculated based on Date Applied and Date on which the rent payment status updated to Issued)

                householdID: res[0][70],
                transactionID: res[0][71],
                birthdayCode: res[0][72],
                otherPhone: res[0][73],
                spaAssignmentNo: res[0][74],
                spaServiceProviderEmail: res[0][75],
                partnerFirstName: res[0][76],
                partnerLastName: res[0][77],
                openToFollowup: res[0][78],
                noOfTimesParticipated: res[0][79],
                boardDistrict: res[0][80],
                legallyAbleToWork: res[0][81],
                workIndustry: res[0][82],
                industry_other: res[0][83],
                eligibleForStimulusFunds: res[0][84],
                employmentStatus: res[0][85],

                effectOfCovid19Financially: res[0][86],
                covid_other: res[0][87],


                // Landlord Details Section	
                rentAmountCovered: res[0][88],
                percentageOfMonthlyRent: res[0][89],
                landlordBankName: res[0][90],
                landlordAccountNumber: res[0][91],
                landlordRoutingNumber: res[0][92],
                landlordSSN: res[0][93],
                landlordTaxID: res[0][94],
                landlordDUNSNumber: res[0][95],
                memoPropertyAddress: res[0][96],
                rentTypeOfAssistance: res[0][97],
                outstandingRentalArrearsAmount: res[0][98],
                //--rentPaymentStatus: null
                landlordFirstAttempt: res[0][99],
                reasonPaymentNotProcessedIn21Days: res[0][100],
                landlordACHReceived: res[0][101],
                dateRentPaymentStatusUpdated: (res[0][102] === '1000-01-01') ? '0000-00-00' : res[0][102],
                timeRentPaymentStatusUpdated: res[0][103],

                dateEmailTriggered: (res[0][104] === '1000-01-01') ? '0000-00-00' : res[0][104],
                timeEmailTriggered: res[0][105],


                // Electric Details Section	
                electricProvider: res[0][106],
                electricUtilityAccountNo: res[0][107],
                custElectricBillingAccountNo: res[0][108],
                monthlyElectricBillAmount: res[0][109],
                noOfMonthsElectricUtilityPayments: res[0][110],
                electricTypeOfAssistance: res[0][111],
                amountDueforElectricity: res[0][112],
                electricAmount: res[0][113],
                percentageOfElectricBill: res[0][114],
                electricBankName: res[0][115],
                electricUtilityProviderAccountNo: res[0][116],
                electricRoutingNumber: res[0][117],
                electricMemoAccountNo: res[0][118],
                electricUtilityTaxID: res[0][119],
                electricUtilityDUNSNumber: res[0][120],
                electricPaymentStatus: (res[0][121] === null) ? 'N/A' : res[0][121],
                electricPaymentStatus_Original: (res[0][121] === null) ? 'N/A' : res[0][121],
                dateElectricPaymentStatusUpdated: (res[0][122] === '1000-01-01') ? '0000-00-00' : res[0][122],
                timeElectricPaymentStatusUpdated: res[0][123],


                // Gas Details Section	
                gasProvider: res[0][124],
                gasUtilityAccountNo: res[0][125],
                custGasBillingAccountNo: res[0][126],
                monthlyGasBillAmount: res[0][127],
                noOfMonthsGasUtilityPayments: res[0][128],
                gasTypeOfAssistance: res[0][129],
                amountDueforGas: res[0][130],
                gasAmount: res[0][131],
                percentageOfGasBill: res[0][132],
                gasBankName: res[0][133],
                gasUtilityProviderAccountNo: res[0][134],
                gasRoutingNumber: res[0][135],
                gasMemoAccountNo: res[0][136],
                gasUtilityTaxID: res[0][137],
                gasUtilityDUNSNumber: res[0][138],
                gasPaymentStatus: (res[0][139] === null) ? 'N/A' : res[0][139],
                gasPaymentStatus_Original: (res[0][139] === null) ? 'N/A' : res[0][139],
                dateGasPaymentStatusUpdated: (res[0][140] === '1000-01-01') ? '0000-00-00' : res[0][140],
                timeGasPaymentStatusUpdated: res[0][141],


                // Water/Sewer Details Section	
                waterProvider: res[0][142],
                waterUtilityAccountNo: res[0][143],
                custWaterBillingAccountNo: res[0][144],
                monthlyWaterBillAmount: res[0][145],
                noOfMonthsWaterUtilityPayments: res[0][146],
                waterTypeOfAssistance: res[0][147],
                amountDueforWater: res[0][148],
                waterAmount: res[0][149],
                percentageOfWaterBill: res[0][150],
                waterBankName: res[0][151],
                waterUtilityProviderAccountNo: res[0][152],
                waterRoutingNumber: res[0][153],
                waterMemoAccountNo: res[0][154],
                waterUtilityTaxID: res[0][155],
                waterUtilityDUNSNumber: res[0][156],
                waterPaymentStatus: (res[0][157] === null) ? 'N/A' : res[0][157],
                waterPaymentStatus_Original: (res[0][157] === null) ? 'N/A' : res[0][157],
                dateWaterPaymentStatusUpdated: (res[0][158] === '1000-01-01') ? '0000-00-00' : res[0][158],
                timeWaterPaymentStatusUpdated: res[0][159],


                // Trash Details Section	
                trashProvider: res[0][160],
                trashUtilityAccountNo: res[0][161],
                custTrashBillingAccountNo: res[0][162],
                monthlyTrashBillAmount: res[0][163],
                noOfMonthsTrashUtilityPayments: res[0][164],
                trashTypeOfAssistance: res[0][165],
                amountDueforTrash: res[0][166],
                trashAmount: res[0][167],
                percentageOfTrashBill: res[0][168],
                trashBankName: res[0][169],
                trashUtilityProviderAccountNo: res[0][170],
                trashRoutingNumber: res[0][171],
                trashMemoAccountNo: res[0][172],
                trashUtilityTaxID: res[0][173],
                trashUtilityDUNSNumber: res[0][174],
                trashPaymentStatus: (res[0][175] === null) ? 'N/A' : res[0][175],
                trashPaymentStatus_Original: (res[0][175] === null) ? 'N/A' : res[0][175],
                dateTrashPaymentStatusUpdated: (res[0][176] === '1000-01-01') ? '0000-00-00' : res[0][176],
                timeTrashPaymentStatusUpdated: res[0][177],


                // Energy Details Section	
                energyProvider: res[0][178],
                energyUtilityAccountNo: res[0][179],
                custEnergyBillingAccountNo: res[0][180],
                monthlyEnergyBillAmount: res[0][181],
                noOfMonthsEnergyUtilityPayments: res[0][182],
                energyTypeOfAssistance: res[0][183],
                amountDueforEnergy: res[0][184],
                energyAmount: res[0][185],
                percentageOfEnergyBill: res[0][186],
                energyBankName: res[0][187],
                energyUtilityProviderAccountNo: res[0][188],
                energyRoutingNumber: res[0][189],
                energyMemoAccountNo: res[0][190],
                energyUtilityTaxID: res[0][191],
                energyUtilityDUNSNumber: res[0][192],
                energyPaymentStatus: (res[0][193] === null) ? 'N/A' : res[0][193],
                energyPaymentStatus_Original: (res[0][193] === null) ? 'N/A' : res[0][193],
                dateEnergyPaymentStatusUpdated: (res[0][194] === '1000-01-01') ? '0000-00-00' : res[0][194],
                timeEnergyPaymentStatusUpdated: res[0][195],


                // Individual Payments Details Section	
                individualPaymentReason: res[0][196],
                individualPaymentAmount: res[0][197],
                individualMemoPropertyAddress: res[0][198],
                individualBankName: res[0][199],
                individualAccountNumber: res[0][200],
                individualRoutingNumber: res[0][201],
                individualPaymentStatus: (res[0][202] === null) ? 'Pending' : res[0][202],
                individualPaymentStatus_Original: (res[0][202] === null) ? 'Pending' : res[0][202],
                individualACHReceived: res[0][203],
                dateIndividualPaymentStatusUpdated: (res[0][204] === '1000-01-01') ? '0000-00-00' : res[0][204],
                timeIndividualPaymentStatusUpdated: res[0][205],

                // Other Payments Details Section	
                otherPayeeName: res[0][206],
                otherDesription: res[0][207],
                otherPaymentAmount: res[0][208],
                otherMemoPropertyAddress: res[0][209],
                otherBankName: res[0][210],
                otherAccountNumber: res[0][211],
                otherRoutingNumber: res[0][212],
                otherPaymentStatus: (res[0][213] === null) ? 'N/A' : res[0][213],
                otherPaymentStatus_Original: (res[0][213] === null) ? 'N/A' : res[0][213],
                dateOtherPaymentStatusUpdated: (res[0][214] === '1000-01-01') ? '0000-00-00' : res[0][214],
                timeOtherPaymentStatusUpdated: res[0][215],

                // Note Section	
                notes: res[0][216],


                dateClaimModified: (res[0][217] === '1000-01-01') ? '0000-00-00' : res[0][217],
                timeClaimModified: res[0][218],
                modifiedBy: res[0][219],
                createdBy: res[0][220],

                requestedLandlordInfo: res[0][221],
                dateLandlordInfoRequested: (res[0][222] === '1000-01-01') ? '0000-00-00' : res[0][222],
                timeLandlordInfoRequested: res[0][223],

                countLandlordInfoRequested: res[0][224],
                landlordInfoRequestedStatus: res[0][225],
                landlordInfoRequestedStatus_Original: res[0][225],
                dateLandlordInfoReminded: (res[0][226] === '1000-01-01') ? '0000-00-00' : res[0][226],
                timeLandlordInfoReminded: res[0][227],

                landlordMobile: res[0][228],
                verifiedasEligible: res[0][229],
                applicantDocUploadStatus: res[0][230],
                applicantDocUploadStatus_Original: res[0][230],
                paymentToIrvineCompany: res[0][231],

                dateApplicantDocumentSubmitted: (res[0][232] === '1000-01-01') ? '0000-00-00' : res[0][232],
                timeApplicantDocumentSubmitted: res[0][233],
                dateLandlordDocumentSubmitted: (res[0][234] === '1000-01-01') ? '0000-00-00' : res[0][234],
                timeLandlordDocumentSubmitted: res[0][235],
                dateApplicationAcceptedChanged: (res[0][236] === '1000-01-01') ? '0000-00-00' : res[0][236],
                timeApplicationAcceptedChanged: res[0][237],

                landlordTitlePulled: res[0][238],

                startDateCoveredByPayment: res[0][239],
                endDateCoveredByPayment: res[0][240],
                total1099Amount: res[0][241],

            });

            this.calculateTotal1099();
            if (res[0][230] === 'Ready') {     // applicantDocUploadStatus
                if (res[0][232] !== '1000-01-01' && res[0][232] !== '0000-00-00') {     // dateApplicantDocumentSubmitted

                    var d = new Date(res[0][232]);
                    var applicantSubmitted = [
                        ('0' + (d.getMonth() + 1)).slice(-2),
                        ('0' + d.getDate()).slice(-2),
                        d.getFullYear()
                    ].join('/');
                    this.setState({ dateApplicantDocumentSubmitted_Formatted: applicantSubmitted });
                }
            }

            if (res[0][225] === 'Ready') {     // landlordInfoRequestedStatus
                if (res[0][234] !== '1000-01-01' && res[0][234] !== '0000-00-00') {     // dateLandlordDocumentSubmitted

                    var d = new Date(res[0][234]);
                    var landlordSubmitted = [
                        ('0' + (d.getMonth() + 1)).slice(-2),
                        ('0' + d.getDate()).slice(-2),
                        d.getFullYear()
                    ].join('/');
                    this.setState({ dateLandlordDocumentSubmitted_Formatted: landlordSubmitted });
                }
            }

            if (res[0][236] !== '1000-01-01' && res[0][236] !== '0000-00-00') {     // dateApplicationAcceptedChanged

                var d = new Date(res[0][236]);
                var applicationAccepted = [
                    ('0' + (d.getMonth() + 1)).slice(-2),
                    ('0' + d.getDate()).slice(-2),
                    d.getFullYear()
                ].join('/');
                this.setState({ dateApplicationAcceptedChanged_Formatted: applicationAccepted });
            }

            // Update the case dropdown after loading the data
            this.onPaymentStatusChange();

            // Update following fields based on the existing fields
            this.setState({
                timestampOfRentIssued: "",
                lengthOfTimeForAward: "0",
            });

            if (res[0][225] !== 'Ready' && (res[0][221] === true || res[0][221] === 1 || res[0][221] === "1")) {   // requestedLandlordInfo
                if (res[0][225] === 'Reminder Sent' &&                                  // landlordInfoRequestedStatus
                    res[0][226] !== '1000-01-01' && res[0][226] !== '0000-00-00') {     // dateLandlordInfoReminded

                    var d = new Date(res[0][226]);
                    var infoRemindedDate = [
                        ('0' + (d.getMonth() + 1)).slice(-2),
                        ('0' + d.getDate()).slice(-2),
                        d.getFullYear()
                    ].join('/');
                    this.setState({ dateLandlordInfoReminded_Formatted: infoRemindedDate });
                }

                if (res[0][222] !== '1000-01-01' && res[0][222] !== '0000-00-00') {    // dateLandlordInfoRequested
                    var d = new Date(res[0][222]);
                    var infoRequestedDate = [
                        ('0' + (d.getMonth() + 1)).slice(-2),
                        ('0' + d.getDate()).slice(-2),
                        d.getFullYear()
                    ].join('/');
                    this.setState({ dateLandlordInfoRequested_Formatted: infoRequestedDate });
                }
            }

            if (this.state.receivedHousingSubsidy === true || this.state.receivedHousingSubsidy === 1 || this.state.receivedHousingSubsidy === "1") {
                document.getElementById("subsidyExplanationId").disabled = false;
            }
            else {
                document.getElementById("subsidyExplanationId").disabled = true;
                this.setState({ subsidyExplanation: "" });
            }

            if (this.state.receivedEvictionNotice === true || this.state.receivedEvictionNotice === 1 || this.state.receivedEvictionNotice === "1") {
                document.getElementById("dateEvictionNoticeReceivedId").disabled = false;
            }
            else {
                document.getElementById("dateEvictionNoticeReceivedId").disabled = true;
                this.setState({ dateEvictionNoticeReceived: "0000-00-00" });
            }

            if (this.state.landlordInfoRequestedStatus !== "Ready") {
                if (this.state.requestedLandlordInfo === true || this.state.requestedLandlordInfo === 1 || this.state.requestedLandlordInfo === "1") {
                    document.getElementById("requestLandlordInfoId").style.display = 'none';
                    document.getElementById("requestDateId").style.display = 'block';
                    document.getElementById("sendReminderToLandlordId").style.display = 'block';
                }
                else {
                    document.getElementById("requestLandlordInfoId").style.display = 'block';
                    document.getElementById("requestDateId").style.display = 'none';
                    document.getElementById("sendReminderToLandlordId").style.display = 'none';
                }
            }

            if (this.state.rentPaymentStatus_Original === 'Issued') {

                if (this.state.dateRentPaymentStatusUpdated !== '' && this.state.dateRentPaymentStatusUpdated !== null && this.state.dateRentPaymentStatusUpdated !== undefined &&
                    this.state.timeRentPaymentStatusUpdated !== '' && this.state.timeRentPaymentStatusUpdated !== null && this.state.timeRentPaymentStatusUpdated !== undefined) {

                    var d = new Date(this.state.dateRentPaymentStatusUpdated);
                    var statusUpdatedDate = [
                        ('0' + (d.getMonth() + 1)).slice(-2),
                        ('0' + d.getDate()).slice(-2),
                        d.getFullYear()
                    ].join('/');

                    this.setState({
                        timestampOfRentIssued: statusUpdatedDate + ' ' + this.state.timeRentPaymentStatusUpdated,
                    });

                    if (this.state.dateApplied !== '' && this.state.dateApplied !== null && this.state.dateApplied !== undefined) {
                        this.setState({
                            lengthOfTimeForAward: this.date_diff_in_days(this.state.dateApplied, this.state.dateRentPaymentStatusUpdated),
                        });
                    }
                }
            }

            if (this.state.programTitle === '' || this.state.programTitle === null || this.state.programTitle === undefined) {
                this.setState({ programTitle: "ERA-IRVINE" });
            }

            // Load the ACH received checkboxes with their values
            if (this.state.landlordACHReceived === '1' || this.state.landlordACHReceived === 1) {
                document.getElementById("landlordACHReceivedId").checked = true;
            }
            else {
                document.getElementById("landlordACHReceivedId").checked = false;
            }

            if (this.state.verifiedasEligible === '1' || this.state.verifiedasEligible === 1) {
                document.getElementById("verifiedasEligibleId").checked = true;
            }
            else {
                document.getElementById("verifiedasEligibleId").checked = false;
            }

            if (this.state.paymentToIrvineCompany === '1' || this.state.paymentToIrvineCompany === 1) {
                document.getElementById("paymentToIrvineCompanyId").checked = true;
            }
            else {
                document.getElementById("paymentToIrvineCompanyId").checked = false;
            }

            if (this.state.individualACHReceived === '1' || this.state.individualACHReceived === 1) {
                document.getElementById("individualACHReceivedId").checked = true;
            }
            else {
                document.getElementById("individualACHReceivedId").checked = false;
            }

            if (this.state.landlordTitlePulled === '1' || this.state.landlordTitlePulled === 1) {
                document.getElementById("landlordTitlePulledId").checked = true;
            }
            else {
                document.getElementById("landlordTitlePulledId").checked = false;
            }

            // Load the checkboxes with their values
            if (res[0][28] === 1 || res[0][28] === "1" || res[0][28] === true) { // qualifiedForUnemploymentBenefits
                document.getElementById("qualifiedForUnemploymentBenefitsId").checked = true;
            }
            if (res[0][29] === 1 || res[0][29] === "1" || res[0][29] === true) { // experiencedReductionInHouseholdIncome
                document.getElementById("experiencedReductionInHouseholdIncomeId").checked = true;
            }
            if (res[0][30] === 1 || res[0][30] === "1" || res[0][30] === true) { // incurredSignificantCosts
                document.getElementById("incurredSignificantCostsId").checked = true;
            }
            if (res[0][31] === 1 || res[0][31] === "1" || res[0][31] === true) { // reductionOfChildSupport
                document.getElementById("reductionOfChildSupportId").checked = true;
            }
            if (res[0][32] === 1 || res[0][32] === "1" || res[0][32] === true) { // otherFinancialHardship
                document.getElementById("otherFinancialHardshipId").checked = true;
            }

            // Calculate Percentage values based on the existing values if any
            if (this.state.amountBehindOnRent !== 0 && this.state.amountBehindOnRent !== "0.00" &&
                this.state.amountBehindOnRent !== '' && this.state.amountBehindOnRent !== null && this.state.amountBehindOnRent !== undefined &&
                this.state.rentAmountCovered !== 0 && this.state.rentAmountCovered !== "0.00" &&
                this.state.rentAmountCovered !== '' && this.state.rentAmountCovered !== null && this.state.rentAmountCovered !== undefined) {
                this.setState({ percentageOfMonthlyRent: ((this.state.rentAmountCovered * 100) / this.state.amountBehindOnRent).toFixed(2) });
            }
            else {
                this.setState({ percentageOfMonthlyRent: '' });
            }

            if (this.state.amountDueforElectricity !== 0 && this.state.amountDueforElectricity !== "0.00" &&
                this.state.amountDueforElectricity !== '' && this.state.amountDueforElectricity !== null && this.state.amountDueforElectricity !== undefined &&
                this.state.electricAmount !== 0 && this.state.electricAmount !== "0.00" &&
                this.state.electricAmount !== '' && this.state.electricAmount !== null && this.state.electricAmount !== undefined) {
                this.setState({ percentageOfElectricBill: ((this.state.electricAmount * 100) / this.state.amountDueforElectricity).toFixed(2) });
            }
            else {
                this.setState({ percentageOfElectricBill: '' });
            }

            if (this.state.amountDueforGas !== 0 && this.state.amountDueforGas !== "0.00" &&
                this.state.amountDueforGas !== '' && this.state.amountDueforGas !== null && this.state.amountDueforGas !== undefined &&
                this.state.gasAmount !== 0 && this.state.gasAmount !== "0.00" &&
                this.state.gasAmount !== '' && this.state.gasAmount !== null && this.state.gasAmount !== undefined) {
                this.setState({ percentageOfGasBill: ((this.state.gasAmount * 100) / this.state.amountDueforGas).toFixed(2) });
            }
            else {
                this.setState({ percentageOfGasBill: '' });
            }

            if (this.state.amountDueforWater !== 0 && this.state.amountDueforWater !== "0.00" &&
                this.state.amountDueforWater !== '' && this.state.amountDueforWater !== null && this.state.amountDueforWater !== undefined &&
                this.state.waterAmount !== 0 && this.state.waterAmount !== "0.00" &&
                this.state.waterAmount !== '' && this.state.waterAmount !== null && this.state.waterAmount !== undefined) {
                this.setState({ percentageOfWaterBill: ((this.state.waterAmount * 100) / this.state.amountDueforWater).toFixed(2) });
            }
            else {
                this.setState({ percentageOfWaterBill: '' });
            }

            if (this.state.amountDueforTrash !== 0 && this.state.amountDueforTrash !== "0.00" &&
                this.state.amountDueforTrash !== '' && this.state.amountDueforTrash !== null && this.state.amountDueforTrash !== undefined &&
                this.state.trashAmount !== 0 && this.state.trashAmount !== "0.00" &&
                this.state.trashAmount !== '' && this.state.trashAmount !== null && this.state.trashAmount !== undefined) {
                this.setState({ percentageOfTrashBill: ((this.state.trashAmount * 100) / this.state.amountDueforTrash).toFixed(2) });
            }
            else {
                this.setState({ percentageOfTrashBill: '' });
            }

            if (this.state.amountDueforEnergy !== 0 && this.state.amountDueforEnergy !== "0.00" &&
                this.state.amountDueforEnergy !== '' && this.state.amountDueforEnergy !== null && this.state.amountDueforEnergy !== undefined &&
                this.state.energyAmount !== 0 && this.state.energyAmount !== "0.00" &&
                this.state.energyAmount !== '' && this.state.energyAmount !== null && this.state.energyAmount !== undefined) {
                this.setState({ percentageOfEnergyBill: ((this.state.energyAmount * 100) / this.state.amountDueforEnergy).toFixed(2) });
            }
            else {
                this.setState({ percentageOfEnergyBill: '' });
            }

            // if ((this.state.otherPaymentAmount !== "" && this.state.otherPaymentAmount !== null && this.state.otherPaymentAmount !== undefined) ||
            //     (this.state.rentAmountCovered !== '' && this.state.rentAmountCovered !== null && this.state.rentAmountCovered !== undefined)) {
            //     this.setState({ total1099Amount: +this.state.rentAmountCovered + +this.state.otherPaymentAmount });
            // }

            res = await this.loadUploadedDocuments();
            console.log(res);
        }
        catch (err) {
            console.log(err);
            console.log("ERROR: Failed to load the individual info.");
            return;
        }
    }

    adminGetIndividual = async () => {
        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayName;
        let path = '/getirvineindividualbyindividualid';
        let myInit = { // OPTIONAL
            body: {
                "info": {
                    "individualID": this.state.individualID,
                }
            }, // replace this with attributes you need
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
        }
        return await API.post(apiName, path, myInit);
    }

    getReconciliationList = async () => {
        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayName;
        let path = '/reconciliationbyindividualid';
        let myInit = { // OPTIONAL
            body: {
                "info": {
                    "individualID": this.state.individualID,
                    "program": "irvine",
                }
            }, // replace this with attributes you need
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
        }
        return await API.post(apiName, path, myInit);
    }
    
    textExtractW9Delete = async (key) => {
        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayName;
        let path = '/textextracterairvine';
        let myInit = { // OPTIONAL
            body: {
                "info": {
                    "individualID": this.state.individualID,
                    "modifiedBy": user.attributes.email,
                    "key": key,
                }
            }, // replace this with attributes you need
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
        }
        return await API.post(apiName, path, myInit);
    }

    updateIndividualDetails = async () => {
        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayName;
        let path = '/modifyirvineindividual';
        let myInit = { // OPTIONAL
            body: {
                "info": {
                    "individualID": this.state.individualID,

                    "firstName": this.state.firstName,
                    "middleName": this.state.middleName,
                    "lastName": this.state.lastName,
                    "suffix": this.state.suffix,
                    "addressLine1": this.state.addressLine1,
                    "addressLine2": this.state.addressLine2,
                    "city": this.state.city,
                    "state": this.state.state,
                    "zipcode": this.state.zipcode,
                    "phone": this.state.phone,
                    "phoneType": this.state.phoneType,
                    "email": this.state.email,
                    "gender": this.state.gender,
                    "race": this.state.race,
                    "ethnicity": this.state.ethnicity,
                    "dateOfBirth": (this.state.dateOfBirth === '0000-00-00') ? '1000-01-01' : this.state.dateOfBirth,
                    "interpretationServicesRequiredForLanguage": this.state.interpretationServicesRequiredForLanguage,
                    "secondaryFirstName": this.state.secondaryFirstName,
                    "secondaryLastName": this.state.secondaryLastName,
                    "secondaryEmail": this.state.secondaryEmail,
                    "secondaryPhone": this.state.secondaryPhone,
                    "householdNumber": this.state.householdNumber,
                    "countAdult": this.state.countAdult,
                    "countChildren": this.state.countChildren,
                    "headOfHousehold": this.state.headOfHousehold,
                    "veteran": this.state.veteran,
                    "currentMonthlyIncome": this.state.currentMonthlyIncome,
                    "totalHouseholdAnnualIncome": this.state.totalHouseholdAnnualIncome,
                    "qualifiedForUnemploymentBenefits": this.state.qualifiedForUnemploymentBenefits,
                    "experiencedReductionInHouseholdIncome": this.state.experiencedReductionInHouseholdIncome,
                    "incurredSignificantCosts": this.state.incurredSignificantCosts,
                    "reductionOfChildSupport": this.state.reductionOfChildSupport,
                    "otherFinancialHardship": this.state.otherFinancialHardship,
                    "isSomeoneOnRentalOrLeaseAgreement": this.state.isSomeoneOnRentalOrLeaseAgreement,

                    "landlordFullName": this.state.landlordFullName,
                    "landlordEmail": this.state.landlordEmail,
                    "landlordPhone": this.state.landlordPhone,
                    "monthlyRentPayment": this.state.monthlyRentPayment,
                    "monthsBehindOnRent": this.state.monthsBehindOnRent,
                    "amountBehindOnRent": this.state.amountBehindOnRent,

                    "receivedHousingSubsidy": this.state.receivedHousingSubsidy,
                    "subsidyExplanation": this.state.subsidyExplanation,
                    "filedCovid19FinancialStressDeclaration": this.state.filedCovid19FinancialStressDeclaration,
                    "receivedEvictionNotice": this.state.receivedEvictionNotice,
                    "dateEvictionNoticeReceived": (this.state.dateEvictionNoticeReceived === '0000-00-00') ? '1000-01-01' : this.state.dateEvictionNoticeReceived,

                    // "createdDate": this.state.createdDate,
                    // "createdTime": this.state.createdTime,

                    "doYouLiveInIrvine": this.state.doYouLiveInIrvine,
                    "doYouRent": this.state.doYouRent,
                    "areYouBehindOnRent": this.state.areYouBehindOnRent,
                    "doYouHoldSection8HousingVoucher": this.state.doYouHoldSection8HousingVoucher,
                    "experiencedFinancialHardshipInCovid": this.state.experiencedFinancialHardshipInCovid,
                    "isAnnualIncomeLessThanMaxLimit": this.state.isAnnualIncomeLessThanMaxLimit,
                    "belowThirtyPercAMI": this.state.belowThirtyPercAMI,
                    "thirtyToFiftyPercAMI": this.state.thirtyToFiftyPercAMI,
                    "fiftyToEightyPercAMI": this.state.fiftyToEightyPercAMI,

                    "agent": this.state.agent,
                    "individualCase": this.state.individualCase,
                    "rentPaymentStatus": this.state.rentPaymentStatus,
                    "dontKnowDateOfBirth": this.state.dontKnowDateOfBirth,
                    "ipAddress": this.state.ipAddress,


                    "age": this.state.age,
                    "applicationAccepted": this.state.applicationAccepted,
                    "householdApprovedForUnitedWay": this.state.householdApprovedForUnitedWay,
                    "programTitle": this.state.programTitle,

                    // Other Details Section	
                    "uniqueID": this.state.uniqueID,
                    "SSN": this.state.SSN,
                    "socialSecurityFirstName": this.state.socialSecurityFirstName,
                    "socialSecurityLastName": this.state.socialSecurityLastName,

                    //lengthOfTimeForAward: null, // (Calculated based on Date Applied and Date on which the rent payment status updated to Issued)

                    "householdID": this.state.householdID,
                    "transactionID": this.state.transactionID,
                    "birthdayCode": this.state.birthdayCode,
                    "otherPhone": this.state.otherPhone,
                    "spaAssignmentNo": this.state.spaAssignmentNo,
                    "spaServiceProviderEmail": this.state.spaServiceProviderEmail,
                    "partnerFirstName": this.state.partnerFirstName,
                    "partnerLastName": this.state.partnerLastName,
                    "openToFollowup": this.state.openToFollowup,
                    "noOfTimesParticipated": this.state.noOfTimesParticipated,
                    "boardDistrict": this.state.boardDistrict,
                    "legallyAbleToWork": this.state.legallyAbleToWork,
                    "workIndustry": this.state.workIndustry,
                    "industry_other": this.state.industry_other,
                    "eligibleForStimulusFunds": this.state.eligibleForStimulusFunds,
                    "employmentStatus": this.state.employmentStatus,

                    "effectOfCovid19Financially": this.state.effectOfCovid19Financially,
                    "covid_other": this.state.covid_other,


                    // Landlord Details Section	
                    "rentAmountCovered": this.state.rentAmountCovered,
                    "percentageOfMonthlyRent": this.state.percentageOfMonthlyRent,
                    "landlordBankName": this.state.landlordBankName,
                    "landlordAccountNumber": this.state.landlordAccountNumber,
                    "landlordRoutingNumber": this.state.landlordRoutingNumber,
                    "landlordSSN": this.state.landlordSSN,
                    "landlordTaxID": this.state.landlordTaxID,
                    "landlordDUNSNumber": this.state.landlordDUNSNumber,
                    "memoPropertyAddress": this.state.memoPropertyAddress,
                    "rentTypeOfAssistance": this.state.rentTypeOfAssistance,
                    "outstandingRentalArrearsAmount": this.state.outstandingRentalArrearsAmount,
                    // --rentPaymentStatus: null,
                    "landlordFirstAttempt": this.state.landlordFirstAttempt,
                    "reasonPaymentNotProcessedIn21Days": this.state.reasonPaymentNotProcessedIn21Days,
                    "landlordACHReceived": this.state.landlordACHReceived,
                    "dateRentPaymentStatusUpdated": (this.state.dateRentPaymentStatusUpdated === '0000-00-00') ? '1000-01-01' : this.state.dateRentPaymentStatusUpdated,
                    "timeRentPaymentStatusUpdated": this.state.timeRentPaymentStatusUpdated,

                    "dateEmailTriggered": (this.state.dateEmailTriggered === '0000-00-00') ? '1000-01-01' : this.state.dateEmailTriggered,
                    "timeEmailTriggered": this.state.timeEmailTriggered,


                    // Electric Details Section	
                    "electricProvider": this.state.electricProvider,
                    "electricUtilityAccountNo": this.state.electricUtilityAccountNo,
                    "custElectricBillingAccountNo": this.state.custElectricBillingAccountNo,
                    "monthlyElectricBillAmount": this.state.monthlyElectricBillAmount,
                    "noOfMonthsElectricUtilityPayments": this.state.noOfMonthsElectricUtilityPayments,
                    "electricTypeOfAssistance": this.state.electricTypeOfAssistance,
                    "amountDueforElectricity": this.state.amountDueforElectricity,
                    "electricAmount": this.state.electricAmount,
                    "percentageOfElectricBill": this.state.percentageOfElectricBill,
                    "electricBankName": this.state.electricBankName,
                    "electricUtilityProviderAccountNo": this.state.electricUtilityProviderAccountNo,
                    "electricRoutingNumber": this.state.electricRoutingNumber,
                    "electricMemoAccountNo": this.state.electricMemoAccountNo,
                    "electricUtilityTaxID": this.state.electricUtilityTaxID,
                    "electricUtilityDUNSNumber": this.state.electricUtilityDUNSNumber,
                    "electricPaymentStatus": this.state.electricPaymentStatus,
                    "dateElectricPaymentStatusUpdated": (this.state.dateElectricPaymentStatusUpdated === '0000-00-00') ? '1000-01-01' : this.state.dateElectricPaymentStatusUpdated,
                    "timeElectricPaymentStatusUpdated": this.state.timeElectricPaymentStatusUpdated,


                    // Gas Details Section	
                    "gasProvider": this.state.gasProvider,
                    "gasUtilityAccountNo": this.state.gasUtilityAccountNo,
                    "custGasBillingAccountNo": this.state.custGasBillingAccountNo,
                    "monthlyGasBillAmount": this.state.monthlyGasBillAmount,
                    "noOfMonthsGasUtilityPayments": this.state.noOfMonthsGasUtilityPayments,
                    "gasTypeOfAssistance": this.state.gasTypeOfAssistance,
                    "amountDueforGas": this.state.amountDueforGas,
                    "gasAmount": this.state.gasAmount,
                    "percentageOfGasBill": this.state.percentageOfGasBill,
                    "gasBankName": this.state.gasBankName,
                    "gasUtilityProviderAccountNo": this.state.gasUtilityProviderAccountNo,
                    "gasRoutingNumber": this.state.gasRoutingNumber,
                    "gasMemoAccountNo": this.state.gasMemoAccountNo,
                    "gasUtilityTaxID": this.state.gasUtilityTaxID,
                    "gasUtilityDUNSNumber": this.state.gasUtilityDUNSNumber,
                    "gasPaymentStatus": this.state.gasPaymentStatus,
                    "dateGasPaymentStatusUpdated": (this.state.dateGasPaymentStatusUpdated === '0000-00-00') ? '1000-01-01' : this.state.dateGasPaymentStatusUpdated,
                    "timeGasPaymentStatusUpdated": this.state.timeGasPaymentStatusUpdated,


                    // Water/Sewer Details Section	
                    "waterProvider": this.state.waterProvider,
                    "waterUtilityAccountNo": this.state.waterUtilityAccountNo,
                    "custWaterBillingAccountNo": this.state.custWaterBillingAccountNo,
                    "monthlyWaterBillAmount": this.state.monthlyWaterBillAmount,
                    "noOfMonthsWaterUtilityPayments": this.state.noOfMonthsWaterUtilityPayments,
                    "waterTypeOfAssistance": this.state.waterTypeOfAssistance,
                    "amountDueforWater": this.state.amountDueforWater,
                    "waterAmount": this.state.waterAmount,
                    "percentageOfWaterBill": this.state.percentageOfWaterBill,
                    "waterBankName": this.state.waterBankName,
                    "waterUtilityProviderAccountNo": this.state.waterUtilityProviderAccountNo,
                    "waterRoutingNumber": this.state.waterRoutingNumber,
                    "waterMemoAccountNo": this.state.waterMemoAccountNo,
                    "waterUtilityTaxID": this.state.waterUtilityTaxID,
                    "waterUtilityDUNSNumber": this.state.waterUtilityDUNSNumber,
                    "waterPaymentStatus": this.state.waterPaymentStatus,
                    "dateWaterPaymentStatusUpdated": (this.state.dateWaterPaymentStatusUpdated === '0000-00-00') ? '1000-01-01' : this.state.dateWaterPaymentStatusUpdated,
                    "timeWaterPaymentStatusUpdated": this.state.timeWaterPaymentStatusUpdated,


                    // Trash Details Section	
                    "trashProvider": this.state.trashProvider,
                    "trashUtilityAccountNo": this.state.trashUtilityAccountNo,
                    "custTrashBillingAccountNo": this.state.custTrashBillingAccountNo,
                    "monthlyTrashBillAmount": this.state.monthlyTrashBillAmount,
                    "noOfMonthsTrashUtilityPayments": this.state.noOfMonthsTrashUtilityPayments,
                    "trashTypeOfAssistance": this.state.trashTypeOfAssistance,
                    "amountDueforTrash": this.state.amountDueforTrash,
                    "trashAmount": this.state.trashAmount,
                    "percentageOfTrashBill": this.state.percentageOfTrashBill,
                    "trashBankName": this.state.trashBankName,
                    "trashUtilityProviderAccountNo": this.state.trashUtilityProviderAccountNo,
                    "trashRoutingNumber": this.state.trashRoutingNumber,
                    "trashMemoAccountNo": this.state.trashMemoAccountNo,
                    "trashUtilityTaxID": this.state.trashUtilityTaxID,
                    "trashUtilityDUNSNumber": this.state.trashUtilityDUNSNumber,
                    "trashPaymentStatus": this.state.trashPaymentStatus,
                    "dateTrashPaymentStatusUpdated": (this.state.dateTrashPaymentStatusUpdated === '0000-00-00') ? '1000-01-01' : this.state.dateTrashPaymentStatusUpdated,
                    "timeTrashPaymentStatusUpdated": this.state.timeTrashPaymentStatusUpdated,


                    // Energy Details Section	
                    "energyProvider": this.state.energyProvider,
                    "energyUtilityAccountNo": this.state.energyUtilityAccountNo,
                    "custEnergyBillingAccountNo": this.state.custEnergyBillingAccountNo,
                    "monthlyEnergyBillAmount": this.state.monthlyEnergyBillAmount,
                    "noOfMonthsEnergyUtilityPayments": this.state.noOfMonthsEnergyUtilityPayments,
                    "energyTypeOfAssistance": this.state.energyTypeOfAssistance,
                    "amountDueforEnergy": this.state.amountDueforEnergy,
                    "energyAmount": this.state.energyAmount,
                    "percentageOfEnergyBill": this.state.percentageOfEnergyBill,
                    "energyBankName": this.state.energyBankName,
                    "energyUtilityProviderAccountNo": this.state.energyUtilityProviderAccountNo,
                    "energyRoutingNumber": this.state.energyRoutingNumber,
                    "energyMemoAccountNo": this.state.energyMemoAccountNo,
                    "energyUtilityTaxID": this.state.energyUtilityTaxID,
                    "energyUtilityDUNSNumber": this.state.energyUtilityDUNSNumber,
                    "energyPaymentStatus": this.state.energyPaymentStatus,
                    "dateEnergyPaymentStatusUpdated": (this.state.dateEnergyPaymentStatusUpdated === '0000-00-00') ? '1000-01-01' : this.state.dateEnergyPaymentStatusUpdated,
                    "timeEnergyPaymentStatusUpdated": this.state.timeEnergyPaymentStatusUpdated,


                    // Individual Payments Details Section	
                    "individualPaymentReason": this.state.individualPaymentReason,
                    "individualPaymentAmount": this.state.individualPaymentAmount,
                    "individualMemoPropertyAddress": this.state.individualMemoPropertyAddress,
                    "individualBankName": this.state.individualBankName,
                    "individualAccountNumber": this.state.individualAccountNumber,
                    "individualRoutingNumber": this.state.individualRoutingNumber,
                    "individualPaymentStatus": this.state.individualPaymentStatus,
                    "individualACHReceived": this.state.individualACHReceived,
                    "dateIndividualPaymentStatusUpdated": (this.state.dateIndividualPaymentStatusUpdated === '0000-00-00') ? '1000-01-01' : this.state.dateIndividualPaymentStatusUpdated,
                    "timeIndividualPaymentStatusUpdated": this.state.timeIndividualPaymentStatusUpdated,

                    // Other Payments Details Section	
                    "otherPayeeName": this.state.otherPayeeName,
                    "otherDesription": this.state.otherDesription,
                    "otherPaymentAmount": this.state.otherPaymentAmount,
                    "otherMemoPropertyAddress": this.state.otherMemoPropertyAddress,
                    "otherBankName": this.state.otherBankName,
                    "otherAccountNumber": this.state.otherAccountNumber,
                    "otherRoutingNumber": this.state.otherRoutingNumber,
                    "otherPaymentStatus": this.state.otherPaymentStatus,
                    "dateOtherPaymentStatusUpdated": (this.state.dateOtherPaymentStatusUpdated === '0000-00-00') ? '1000-01-01' : this.state.dateOtherPaymentStatusUpdated,
                    "timeOtherPaymentStatusUpdated": this.state.timeOtherPaymentStatusUpdated,

                    // Note Section	
                    "notes": this.state.notes,


                    "dateClaimModified": (this.state.dateClaimModified === '0000-00-00') ? '1000-01-01' : this.state.dateClaimModified,
                    "timeClaimModified": this.state.timeClaimModified,
                    "modifiedBy": this.state.modifiedBy,
                    "createdBy": this.state.createdBy,

                    "requestedLandlordInfo": this.state.requestedLandlordInfo,
                    "dateLandlordInfoRequested": (this.state.dateLandlordInfoRequested === '0000-00-00') ? '1000-01-01' : this.state.dateLandlordInfoRequested,
                    "timeLandlordInfoRequested": this.state.timeLandlordInfoRequested,

                    "countLandlordInfoRequested": this.state.countLandlordInfoRequested,
                    "landlordInfoRequestedStatus": this.state.landlordInfoRequestedStatus,
                    "dateLandlordInfoReminded": (this.state.dateLandlordInfoReminded === '0000-00-00') ? '1000-01-01' : this.state.dateLandlordInfoReminded,
                    "timeLandlordInfoReminded": this.state.timeLandlordInfoReminded,

                    "lengthOfTimeForAward": this.state.lengthOfTimeForAward,
                    "timestampOfRentIssued": this.state.timestampOfRentIssued,

                    "landlordMobile": this.state.landlordMobile,
                    "verifiedasEligible": this.state.verifiedasEligible,
                    "applicantDocUploadStatus": this.state.applicantDocUploadStatus,
                    "paymentToIrvineCompany": this.state.paymentToIrvineCompany,

                    "dateApplicantDocumentSubmitted": (this.state.dateApplicantDocumentSubmitted === '0000-00-00') ? '1000-01-01' : this.state.dateApplicantDocumentSubmitted,
                    "timeApplicantDocumentSubmitted": this.state.timeApplicantDocumentSubmitted,
                    "dateLandlordDocumentSubmitted": (this.state.dateLandlordDocumentSubmitted === '0000-00-00') ? '1000-01-01' : this.state.dateLandlordDocumentSubmitted,
                    "timeLandlordDocumentSubmitted": this.state.timeLandlordDocumentSubmitted,
                    "dateApplicationAcceptedChanged": (this.state.dateApplicationAcceptedChanged === '0000-00-00') ? '1000-01-01' : this.state.dateApplicationAcceptedChanged,
                    "timeApplicationAcceptedChanged": this.state.timeApplicationAcceptedChanged,

                    "landlordTitlePulled": this.state.landlordTitlePulled,

                    "startDateCoveredByPayment": this.state.startDateCoveredByPayment,
                    "endDateCoveredByPayment": this.state.endDateCoveredByPayment,

                    "total1099Amount": this.state.total1099Amount,

                    //"triggerEmail": this.state.triggerEmail,
                }
            }, // replace this with attributes you need
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
        }
        return await API.post(apiName, path, myInit);
    };

    triggerRentPaymentIssuedEmail = async () => {
        this.setState({ isLoading: true });

        try {
            var user = await this.getCurrentUser();
            let apiName = process.env.REACT_APP_APIGatewayName;
            let path = '/erairvinesendrentpaymentemail';
            let myInit = { // OPTIONAL
                body: {
                    "info": {
                        "email": this.state.email,
                        "paymentToIrvineCompany": this.state.paymentToIrvineCompany,
                        "landlordEmail": this.state.landlordEmail,
                        "landlordFullName": this.state.landlordFullName,
                        "addressLine1": this.state.addressLine1,
                        "addressLine2": this.state.addressLine2,
                        "city": this.state.city,
                        "state": this.state.state,
                        "zipcode": this.state.zipcode,
                        "individualID": this.state.individualID,
                    }
                }, // replace this with attributes you need
                headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
            }

            await API.post(apiName, path, myInit);
            // if (res.message === 0)
        }
        catch (err) {
            console.log(err)
        }

        this.setState({ isLoading: false });
    }

    sendCredentialsToApplicant = async () => {
        this.setState({ isLoading: true });

        try {
            var user = await this.getCurrentUser();
            let apiName = process.env.REACT_APP_APIGatewayName;
            let path = '/adminaddirvineuserincognito';
            let myInit = { // OPTIONAL
                body: {
                    "info": {
                        "individualID": this.state.individualID,
                        "uniqueID": this.state.uniqueID,
                        "tenantFirstName": this.state.firstName,
                        "tenantLastName": this.state.lastName,
                        "tenantEmail": this.state.email,
                        "tenantPhone": this.state.phone,
                        "tenantAddressLine1": this.state.addressLine1,
                        "tenantAddressLine2": this.state.addressLine2,
                        "tenantCity": this.state.city,
                        "tenantState": this.state.state,
                        "tenantZipcode": this.state.zipcode,
                        "landlordFullName": this.state.landlordFullName,
                        "landlordEmail": this.state.landlordEmail,
                        "landlordPhone": this.state.landlordPhone,
                        "groupName": "IrvineApplicants", //"IrvineLandlords"
                        "resetPassword": "0",
                    }
                }, // replace this with attributes you need
                headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
            }

            await API.post(apiName, path, myInit);
            // if (res.message === 0)
        }
        catch (err) {
            console.log(err)
        }

        this.setState({ isLoading: false });
    }

    resendApplicantCredentials = async () => {
        await this.resendCredentials(true);
    }

    resendLandlordCredentials = async () => {
        await this.resendCredentials(false);
    }

    resendCredentials = async (toApplicant) => {
        this.setState({ isLoading: true });

        try {
            var user = await this.getCurrentUser();
            let apiName = process.env.REACT_APP_APIGatewayName;
            let path = '/adminaddirvineuserincognito';
            let myInit = { // OPTIONAL
                body: {
                    "info": {
                        "individualID": this.state.individualID,
                        "uniqueID": this.state.uniqueID,
                        "tenantFirstName": this.state.firstName,
                        "tenantLastName": this.state.lastName,
                        "tenantEmail": this.state.email,
                        "tenantPhone": this.state.phone,
                        "tenantAddressLine1": this.state.addressLine1,
                        "tenantAddressLine2": this.state.addressLine2,
                        "tenantCity": this.state.city,
                        "tenantState": this.state.state,
                        "tenantZipcode": this.state.zipcode,
                        "landlordFullName": this.state.landlordFullName,
                        "landlordEmail": this.state.landlordEmail,
                        "landlordPhone": this.state.landlordPhone,
                        "groupName": toApplicant === true ? "IrvineApplicants" : "IrvineLandlords",
                        "resetPassword": "1",
                    }
                }, // replace this with attributes you need
                headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
            }

            var res = await API.post(apiName, path, myInit);
            console.log(res);

            if (res.message === 0) {
                // Success
                alert("Password has been reset and welcome mail has been resent successfully to applicant.");
            }
            else if (res.message === "UnsupportedUserStateException") {
                // "Resend not possible. e9a24799-3b79-460f-8906-35640ce5d7dd status is not FORCE_CHANGE_PASSWORD"
                alert("Password can not be reset as applicant might have already updated the earlier sent temporary password.");
            }
            else if (res.message === "UserNotFoundException") {
                // "User does not exist."
                alert("User does not exist in user pool. Please contact portal administrator.");
            }
            else {
                // Unknown error
                alert("Unknown error occurred while resending the password. Please contact portal administrator.");
            }
        }
        catch (err) {
            console.log(err)
            // Unexpected error
            alert("Unexpected error occurred while resending the password. Please contact portal administrator.");
        }

        this.setState({ isLoading: false });
    }

    requestLandlordInfo = async () => {
        this.setState({ isLoading: true });

        var validationError = false;

        if (this.state.landlordFullName === "" || this.state.landlordFullName === null || this.state.landlordFullName === undefined) {
            this.setState({ landlordFullName_Error: "Name is required" });
            this.landlordFullNameRef.current.focus();

            validationError = true;
        }
        else {
            this.setState({ landlordFullName_Error: "" });
        }

        if (this.state.landlordEmail === "" || this.state.landlordEmail === null || this.state.landlordEmail === undefined) {
            this.setState({ landlordEmail_Error: "Email is required" });
            this.landlordEmailRef.current.focus();

            validationError = true;
        }
        else {
            var landlordEmailPattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!landlordEmailPattern.test(this.state.landlordEmail)) {
                this.setState({ landlordEmail_Error: "Invalid Email" });
                this.landlordEmailRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ landlordEmail_Error: "" });
            }
        }

        if (this.state.landlordPhone === "" || this.state.landlordPhone === null || this.state.landlordPhone === undefined) {
            this.setState({ landlordPhone_Error: "Phone is required" });
            this.landlordPhoneRef.current.focus();

            validationError = true;
        }
        else {
            if (this.state.landlordPhone.length < 10 || /^[0-9]*$/.test(this.state.landlordPhone) !== true) {
                this.setState({ landlordPhone_Error: "Invalid Phone Number" });
                this.landlordPhoneRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ landlordPhone_Error: "" });
            }
        }

        // if (this.state.landlordMobile === "" || this.state.landlordMobile === null || this.state.landlordMobile === undefined) {
        //     this.setState({ landlordMobile_Error: "Mobile is required" });
        //     this.landlordMobileRef.current.focus();

        //     validationError = true;
        // }
        // else {
        //     if (this.state.landlordMobile.length < 10 || /^[0-9]*$/.test(this.state.landlordMobile) !== true) {
        //         this.setState({ landlordMobile_Error: "Invalid Mobile Number" });
        //         this.landlordMobileRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ landlordMobile_Error: "" });
        //     }
        // }

        if (validationError === true) {
            this.setState({ isLoading: false });
            return;
        }

        try {
            var user = await Auth.currentAuthenticatedUser();
           // return user;
          }
          catch(e){
            this.setState({ isLoading: false });
            if(e === "not authenticated") {
              await this.signOut();
              return;
            }
        }
        var confirm = window.confirm(`This action will trigger an email to landlord on ${this.state.landlordEmail}. Please click Ok to confirm.`);
        if (confirm === false) {
            this.setState({ isLoading: false });
            return;
        }

        try {
            var user = await this.getCurrentUser();
            let apiName = process.env.REACT_APP_APIGatewayName;
            let path = '/adminaddirvineuserincognito';
            let myInit = { // OPTIONAL
                body: {
                    "info": {
                        "individualID": this.state.individualID,
                        "uniqueID": this.state.uniqueID,
                        "tenantFirstName": this.state.firstName,
                        "tenantLastName": this.state.lastName,
                        "tenantEmail": this.state.email,
                        "tenantPhone": this.state.phone,
                        "tenantAddressLine1": this.state.addressLine1,
                        "tenantAddressLine2": this.state.addressLine2,
                        "tenantCity": this.state.city,
                        "tenantState": this.state.state,
                        "tenantZipcode": this.state.zipcode,
                        "landlordFullName": this.state.landlordFullName,
                        "landlordEmail": this.state.landlordEmail,
                        "landlordPhone": this.state.landlordPhone,
                        "groupName": "IrvineLandlords", //"IrvineApplicants"
                        "resetPassword": "0",
                    }
                }, // replace this with attributes you need
                headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
            }

            var res = await API.post(apiName, path, myInit);
            console.log(res.message);
            if (res.message === 0 || res.message === "UsernameExistsException") {  //

                if (res.message === "UsernameExistsException") {
                    try {
                        var user = await this.getCurrentUser();
                        let apiName = process.env.REACT_APP_APIGatewayName;
                        let path = '/erairvinesendlandlordemailmultipleapplicant';
                        let myInit = { // OPTIONAL
                            body: {
                                "info": {
                                    "name": this.state.landlordFullName,
                                    "email": this.state.landlordEmail,
                                    "phone": this.state.landlordPhone,
                                    "tenantFirstName": this.state.firstName,
                                    "tenantLastName": this.state.lastName,
                                    "addressLine1": this.state.addressLine1,
                                    "addressLine2": this.state.addressLine2,
                                    "city": this.state.city,
                                    "state": this.state.state,
                                    "zipcode": this.state.zipcode,
                                    "individualID": this.state.individualID,
                                    "uniqueID": this.state.uniqueID,
                                }
                            }, // replace this with attributes you need
                            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
                        }

                        await API.post(apiName, path, myInit);
                        // if (res.message === 0)
                    }
                    catch (err) {
                        console.log(err)
                    }
                }

                var d = new Date(new Date().toLocaleString('en-US', { timeZone: 'US/Pacific' }));
                var todaysDate = [
                    d.getFullYear(),
                    ('0' + (d.getMonth() + 1)).slice(-2),
                    ('0' + d.getDate()).slice(-2)
                ].join('-');

                var todaysTime = [
                    ('0' + (d.getHours())).slice(-2),
                    ('0' + d.getMinutes()).slice(-2),
                    ('0' + d.getSeconds()).slice(-2),
                ].join(':');

                let requestedStatus = 'Info Requested';
                // if (this.state.requestedLandlordInfo === true || this.state.requestedLandlordInfo === 1 || this.state.requestedLandlordInfo === "1") {
                //     requestedStatus = "Reminder Sent";
                //     this.setState({
                //         dateLandlordInfoReminded: todaysDate,
                //         timeLandlordInfoReminded: todaysTime
                //     });
                // }
                // else {
                //     this.setState({
                //         dateLandlordInfoRequested: todaysDate,
                //         timeLandlordInfoRequested: todaysTime
                //     });
                // }

                this.setState({
                    requestedLandlordInfo: true,
                    countLandlordInfoRequested: this.state.countLandlordInfoRequested + 1,
                    landlordInfoRequestedStatus: requestedStatus,
                    dateLandlordInfoRequested: todaysDate,
                    timeLandlordInfoRequested: todaysTime
                },
                    () => {
                        this.updateLandlordInfo();

                        var d = new Date(this.state.dateLandlordInfoRequested);
                        var infoRequestedDate = [
                            ('0' + (d.getMonth() + 1)).slice(-2),
                            ('0' + d.getDate()).slice(-2),
                            d.getFullYear()
                        ].join('/');
                        this.setState({ dateLandlordInfoRequested_Formatted: infoRequestedDate, landlordInfoRequestedStatus_Original: requestedStatus, });
                    });
            }
        }
        catch (err) {
            console.log("catch called");
            console.log(err);
        }

        this.setState({ isLoading: false });
    }

    updateLandlordInfo = async () => {
        this.setState({ isLoading: true });
        var res = await this.updateIndividualDetails();
        console.log(res);
        console.log("Individual Updated Successful.");

        document.getElementById("requestLandlordInfoId").style.display = 'none';
        document.getElementById("requestDateId").style.display = 'block';
        document.getElementById("sendReminderToLandlordId").style.display = 'block';
        this.setState({ isLoading: false });
    }

    sendReminderToLandlord = async () => {
        this.setState({ isLoading: true });

        var validationError = false;

        if (this.state.landlordFullName === "" || this.state.landlordFullName === null || this.state.landlordFullName === undefined) {
            this.setState({ landlordFullName_Error: "Name is required" });
            this.landlordFullNameRef.current.focus();

            validationError = true;
        }
        else {
            this.setState({ landlordFullName_Error: "" });
        }

        if (this.state.landlordEmail === "" || this.state.landlordEmail === null || this.state.landlordEmail === undefined) {
            this.setState({ landlordEmail_Error: "Email is required" });
            this.landlordEmailRef.current.focus();

            validationError = true;
        }
        else {
            var landlordEmailPattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!landlordEmailPattern.test(this.state.landlordEmail)) {
                this.setState({ landlordEmail_Error: "Invalid Email" });
                this.landlordEmailRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ landlordEmail_Error: "" });
            }
        }

        if (this.state.landlordPhone === "" || this.state.landlordPhone === null || this.state.landlordPhone === undefined) {
            this.setState({ landlordPhone_Error: "Phone is required" });
            this.landlordPhoneRef.current.focus();

            validationError = true;
        }
        else {
            if (this.state.landlordPhone.length < 10 || /^[0-9]*$/.test(this.state.landlordPhone) !== true) {
                this.setState({ landlordPhone_Error: "Invalid Phone Number" });
                this.landlordPhoneRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ landlordPhone_Error: "" });
            }
        }

        if (validationError === true) {
            this.setState({ isLoading: false });
            return;
        }

        var confirm = window.confirm(`This action will trigger an email to landlord on ${this.state.landlordEmail}. Please click Ok to confirm.`);
        if (confirm === false) {
            this.setState({ isLoading: false });
            return;
        }

        var originalStatus = this.state.landlordInfoRequestedStatus;
        this.setState({ landlordInfoRequestedStatus: 'Reminder Sent' });

        try {
            var user = await this.getCurrentUser();
            let apiName = process.env.REACT_APP_APIGatewayName;
            let path = '/irvinesendreminderemailtolandlord';
            let myInit = { // OPTIONAL
                body: {
                    "info": {
                        "name": this.state.landlordFullName,
                        "email": this.state.landlordEmail,
                        "phone": this.state.landlordPhone,
                        "tenantFirstName": this.state.firstName,
                        "tenantLastName": this.state.lastName,
                        "addressLine1": this.state.addressLine1,
                        "addressLine2": this.state.addressLine2,
                        "city": this.state.city,
                        "state": this.state.state,
                        "zipcode": this.state.zipcode,
                        "individualID": this.state.individualID,
                        "uniqueID": this.state.uniqueID,
                    }
                }, // replace this with attributes you need
                headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
            }

            var res = await API.post(apiName, path, myInit);

            //await this.dummyRequest();
            console.log(res);

            var d = new Date(new Date().toLocaleString('en-US', { timeZone: 'US/Pacific' }));
            var todaysDate = [
                d.getFullYear(),
                ('0' + (d.getMonth() + 1)).slice(-2),
                ('0' + d.getDate()).slice(-2)
            ].join('-');

            var todaysTime = [
                ('0' + (d.getHours())).slice(-2),
                ('0' + d.getMinutes()).slice(-2),
                ('0' + d.getSeconds()).slice(-2),
            ].join(':');

            var infoRemindedDate = [
                ('0' + (d.getMonth() + 1)).slice(-2),
                ('0' + d.getDate()).slice(-2),
                d.getFullYear()
            ].join('/');

            this.setState({
                requestedLandlordInfo: true,
                countLandlordInfoRequested: this.state.countLandlordInfoRequested + 1,
                landlordInfoRequestedStatus: 'Reminder Sent',
                dateLandlordInfoReminded: todaysDate,
                timeLandlordInfoReminded: todaysTime,

                dateLandlordInfoReminded_Formatted: infoRemindedDate
            },
                () => {
                    this.updateLandlordInfo();

                    var d = new Date(this.state.dateLandlordInfoReminded);
                    var infoRemindedDate = [
                        ('0' + (d.getMonth() + 1)).slice(-2),
                        ('0' + d.getDate()).slice(-2),
                        d.getFullYear()
                    ].join('/');
                    this.setState({ dateLandlordInfoReminded_Formatted: infoRemindedDate, landlordInfoRequestedStatus_Original: 'Reminder Sent', });
                });
        }
        catch (err) {
            // Something went wrong, reset the status to its original.
            this.setState({ landlordInfoRequestedStatus: originalStatus, landlordInfoRequestedStatus_Original: originalStatus });
            console.log(err)
        }

        this.setState({ isLoading: false });
    }

    UploadIndividualDocuments = async () => {

        if (this.state.individualID === -1 || this.state.individualID === 0 || this.state.individualID === null || this.state.individualID === 'IRV000000000000') {
            // early return
            return;
        }

        Storage.configure({
            bucket: process.env.REACT_APP_S3FrontendBucket,
            region: process.env.REACT_APP_Region,
            level: 'public',
        });

        this.tenant_payment_agreement_upload = document.getElementById("tenant_payment_agreement_upload_id");
        if (this.tenant_payment_agreement_upload !== null && this.tenant_payment_agreement_upload.files != null && this.tenant_payment_agreement_upload.files.length > 0) {
            try {
                var result = await Storage.put(`ERA-IRVINE/${this.state.email}/tenant_payment_agreement/${this.tenant_payment_agreement_upload.files[0].name}`,
                    this.tenant_payment_agreement_upload.files[0],
                    { contentType: this.tenant_payment_agreement_upload.files[0].type });
                console.log(result);
                this.tenant_payment_agreement_upload = null;

                this.setState({ tenant_payment_agreement_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }

        this.landlord_payment_agreement_upload = document.getElementById("landlord_payment_agreement_upload_id");
        if (this.landlord_payment_agreement_upload !== null && this.landlord_payment_agreement_upload.files != null && this.landlord_payment_agreement_upload.files.length > 0) {
            try {
                var result = await Storage.put(`ERA-IRVINE/${this.state.email}/landlord_payment_agreement/${this.landlord_payment_agreement_upload.files[0].name}`,
                    this.landlord_payment_agreement_upload.files[0],
                    { contentType: this.landlord_payment_agreement_upload.files[0].type });
                console.log(result);
                this.landlord_payment_agreement_upload = null;

                this.setState({ landlord_payment_agreement_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }

        if (this.state.individualCase_Original !== 'Open') {
            // early return. don't allow rest of the documents to upload if individual case is set to other than open.
            return;
        }

        this.photo_identification_upload = document.getElementById("photo_identification_upload_id");
        this.secondary_identification_upload = document.getElementById("secondary_identification_upload_id");
        this.secondary_proof_of_residential_tenancy_upload = document.getElementById("secondary_proof_of_residential_tenancy_upload_id");
        this.proof_of_past_due_rent_amount_upload = document.getElementById("proof_of_past_due_rent_amount_upload_id");
        this.proof_of_Annual_2020_household_income_upload = document.getElementById("proof_of_Annual_2020_household_income_upload_id");
        this.rental_agreement_upload = document.getElementById("rental_agreement_upload_id");
        this.supplemental_proofof_Annual_2020_household_income_upload = document.getElementById("supplemental_proofof_Annual_2020_household_income_upload_id");
        this.participation_agreement_upload = document.getElementById("participation_agreement_upload_id");
        this.proof_of_unemployment_upload = document.getElementById("proof_of_unemployment_upload_id");
        this.supplemental_proofof_unemployment_upload = document.getElementById("supplemental_proofof_unemployment_upload_id");
        this.authorization_to_release_information_to_SSA_upload = document.getElementById("authorization_to_release_information_to_SSA_upload_id");
        this.consent_to_exchange_information_upload = document.getElementById("consent_to_exchange_information_upload_id");
        this.authorization_to_release_information_to_HUD_upload = document.getElementById("authorization_to_release_information_to_HUD_upload_id");
        this.ami_household_income_worksheet_upload = document.getElementById("ami_household_income_worksheet_upload_id");

        this.third_party_payer_agreement_upload = document.getElementById("third_party_payer_agreement_upload_id");
        this.landlord_w9_upload = document.getElementById("landlord_w9_upload_id");
        this.landlord_participationagreement_upload = document.getElementById("landlord_participationagreement_upload_id");
        this.verified_checkpoint_upload = document.getElementById("verified_checkpoint_upload_id");
        this.landlord_driver_license_upload = document.getElementById("landlord_driver_license_upload_id");
        this.management_agreement_upload = document.getElementById("management_agreement_upload_id");

        // Upload the new documents to be uploaded `ERA-IRVINE/${this.state.email}/`
        if (this.photo_identification_upload !== null && this.photo_identification_upload.files != null && this.photo_identification_upload.files.length > 0) {
            try {
                var result = await Storage.put(`ERA-IRVINE/${this.state.email}/photo_identification/${this.photo_identification_upload.files[0].name}`,
                    this.photo_identification_upload.files[0],
                    { contentType: this.photo_identification_upload.files[0].type });
                console.log(result);
                this.photo_identification_upload = null;

                this.setState({ photo_identification_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }

        if (this.secondary_identification_upload !== null && this.secondary_identification_upload.files != null && this.secondary_identification_upload.files.length > 0) {
            try {
                var result = await Storage.put(`ERA-IRVINE/${this.state.email}/secondary_identification/${this.secondary_identification_upload.files[0].name}`,
                    this.secondary_identification_upload.files[0],
                    { contentType: this.secondary_identification_upload.files[0].type });
                console.log(result);
                this.secondary_identification_upload = null;

                this.setState({ secondary_identification_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }

        if (this.secondary_proof_of_residential_tenancy_upload !== null && this.secondary_proof_of_residential_tenancy_upload.files != null && this.secondary_proof_of_residential_tenancy_upload.files.length > 0) {
            try {
                var result = await Storage.put(`ERA-IRVINE/${this.state.email}/secondary_proof_of_residential_tenancy/${this.secondary_proof_of_residential_tenancy_upload.files[0].name}`,
                    this.secondary_proof_of_residential_tenancy_upload.files[0],
                    { contentType: this.secondary_proof_of_residential_tenancy_upload.files[0].type });
                console.log(result);
                this.secondary_proof_of_residential_tenancy_upload = null;

                this.setState({ secondary_proof_of_residential_tenancy_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }

        if (this.proof_of_past_due_rent_amount_upload !== null && this.proof_of_past_due_rent_amount_upload.files != null && this.proof_of_past_due_rent_amount_upload.files.length > 0) {
            try {
                var result = await Storage.put(`ERA-IRVINE/${this.state.email}/proof_of_past_due_rent_amount/${this.proof_of_past_due_rent_amount_upload.files[0].name}`,
                    this.proof_of_past_due_rent_amount_upload.files[0],
                    { contentType: this.proof_of_past_due_rent_amount_upload.files[0].type });
                console.log(result);
                this.proof_of_past_due_rent_amount_upload = null;

                this.setState({ proof_of_past_due_rent_amount_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }

        if (this.proof_of_Annual_2020_household_income_upload !== null && this.proof_of_Annual_2020_household_income_upload.files != null && this.proof_of_Annual_2020_household_income_upload.files.length > 0) {
            try {
                var result = await Storage.put(`ERA-IRVINE/${this.state.email}/proof_of_Annual_2020_household_income/${this.proof_of_Annual_2020_household_income_upload.files[0].name}`,
                    this.proof_of_Annual_2020_household_income_upload.files[0],
                    { contentType: this.proof_of_Annual_2020_household_income_upload.files[0].type });
                console.log(result);
                this.proof_of_Annual_2020_household_income_upload = null;

                this.setState({ proof_of_Annual_2020_household_income_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }

        if (this.rental_agreement_upload !== null && this.rental_agreement_upload.files != null && this.rental_agreement_upload.files.length > 0) {
            try {
                var result = await Storage.put(`ERA-IRVINE/${this.state.email}/rental_agreement/${this.rental_agreement_upload.files[0].name}`,
                    this.rental_agreement_upload.files[0],
                    { contentType: this.rental_agreement_upload.files[0].type });
                console.log(result);
                this.rental_agreement_upload = null;

                this.setState({ rental_agreement_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }

        if (this.supplemental_proofof_Annual_2020_household_income_upload !== null && this.supplemental_proofof_Annual_2020_household_income_upload.files != null && this.supplemental_proofof_Annual_2020_household_income_upload.files.length > 0) {
            try {
                var result = await Storage.put(`ERA-IRVINE/${this.state.email}/supplemental_proofof_Annual_2020_household_income/${this.supplemental_proofof_Annual_2020_household_income_upload.files[0].name}`,
                    this.supplemental_proofof_Annual_2020_household_income_upload.files[0],
                    { contentType: this.supplemental_proofof_Annual_2020_household_income_upload.files[0].type });
                console.log(result);
                this.supplemental_proofof_Annual_2020_household_income_upload = null;

                this.setState({ supplemental_proofof_Annual_2020_household_income_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }

        if (this.participation_agreement_upload !== null && this.participation_agreement_upload.files != null && this.participation_agreement_upload.files.length > 0) {
            try {
                var result = await Storage.put(`ERA-IRVINE/${this.state.email}/participation_agreement/${this.participation_agreement_upload.files[0].name}`,
                    this.participation_agreement_upload.files[0],
                    { contentType: this.participation_agreement_upload.files[0].type });
                console.log(result);
                this.participation_agreement_upload = null;

                this.setState({ participation_agreement_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }

        if (this.proof_of_unemployment_upload !== null && this.proof_of_unemployment_upload.files != null && this.proof_of_unemployment_upload.files.length > 0) {
            try {
                var result = await Storage.put(`ERA-IRVINE/${this.state.email}/proof_of_unemployment/${this.proof_of_unemployment_upload.files[0].name}`,
                    this.proof_of_unemployment_upload.files[0],
                    { contentType: this.proof_of_unemployment_upload.files[0].type });
                console.log(result);
                this.proof_of_unemployment_upload = null;

                this.setState({ proof_of_unemployment_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }
        if (this.supplemental_proofof_unemployment_upload !== null && this.supplemental_proofof_unemployment_upload.files != null && this.supplemental_proofof_unemployment_upload.files.length > 0) {
            try {
                var result = await Storage.put(`ERA-IRVINE/${this.state.email}/supplemental_proofof_unemployment/${this.supplemental_proofof_unemployment_upload.files[0].name}`,
                    this.supplemental_proofof_unemployment_upload.files[0],
                    { contentType: this.supplemental_proofof_unemployment_upload.files[0].type });
                console.log(result);
                this.supplemental_proofof_unemployment_upload = null;

                this.setState({ supplemental_proofof_unemployment_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }
        if (this.authorization_to_release_information_to_SSA_upload !== null && this.authorization_to_release_information_to_SSA_upload.files != null && this.authorization_to_release_information_to_SSA_upload.files.length > 0) {
            try {
                var result = await Storage.put(`ERA-IRVINE/${this.state.email}/authorization_to_release_information_to_SSA/${this.authorization_to_release_information_to_SSA_upload.files[0].name}`,
                    this.authorization_to_release_information_to_SSA_upload.files[0],
                    { contentType: this.authorization_to_release_information_to_SSA_upload.files[0].type });
                console.log(result);
                this.authorization_to_release_information_to_SSA_upload = null;

                this.setState({ authorization_to_release_information_to_SSA_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }
        if (this.consent_to_exchange_information_upload !== null && this.consent_to_exchange_information_upload.files != null && this.consent_to_exchange_information_upload.files.length > 0) {
            try {
                var result = await Storage.put(`ERA-IRVINE/${this.state.email}/consent_to_exchange_information/${this.consent_to_exchange_information_upload.files[0].name}`,
                    this.consent_to_exchange_information_upload.files[0],
                    { contentType: this.consent_to_exchange_information_upload.files[0].type });
                console.log(result);
                this.consent_to_exchange_information_upload = null;

                this.setState({ consent_to_exchange_information_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }
        if (this.authorization_to_release_information_to_HUD_upload !== null && this.authorization_to_release_information_to_HUD_upload.files != null && this.authorization_to_release_information_to_HUD_upload.files.length > 0) {
            try {
                var result = await Storage.put(`ERA-IRVINE/${this.state.email}/authorization_to_release_information_to_HUD/${this.authorization_to_release_information_to_HUD_upload.files[0].name}`,
                    this.authorization_to_release_information_to_HUD_upload.files[0],
                    { contentType: this.authorization_to_release_information_to_HUD_upload.files[0].type });
                console.log(result);
                this.authorization_to_release_information_to_HUD_upload = null;

                this.setState({ authorization_to_release_information_to_HUD_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }
        if (this.ami_household_income_worksheet_upload !== null && this.ami_household_income_worksheet_upload.files != null && this.ami_household_income_worksheet_upload.files.length > 0) {
            try {
                var result = await Storage.put(`ERA-IRVINE/${this.state.email}/ami_household_income_worksheet/${this.ami_household_income_worksheet_upload.files[0].name}`,
                    this.ami_household_income_worksheet_upload.files[0],
                    { contentType: this.ami_household_income_worksheet_upload.files[0].type });
                console.log(result);
                this.ami_household_income_worksheet_upload = null;

                this.setState({ ami_household_income_worksheet_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }


        if (this.third_party_payer_agreement_upload !== null && this.third_party_payer_agreement_upload.files != null && this.third_party_payer_agreement_upload.files.length > 0) {
            try {
                var result = await Storage.put(`ERA-IRVINE/${this.state.email}/third_party_payer_agreement/${this.third_party_payer_agreement_upload.files[0].name}`,
                    this.third_party_payer_agreement_upload.files[0],
                    { contentType: this.third_party_payer_agreement_upload.files[0].type });
                console.log(result);
                this.third_party_payer_agreement_upload = null;

                this.setState({ third_party_payer_agreement_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }
        if (this.landlord_w9_upload !== null && this.landlord_w9_upload.files != null && this.landlord_w9_upload.files.length > 0) {
            try {
                var result = await Storage.put(`ERA-IRVINE/${this.state.email}/landlord_w9/${this.landlord_w9_upload.files[0].name}`,
                    this.landlord_w9_upload.files[0],
                    { contentType: this.landlord_w9_upload.files[0].type });

                var resultW9 = await Storage.put(`ERA-IRVINE/W9_TextExtract/${this.state.individualID}_W9.` + (this.landlord_w9_upload.files[0].name).split('.').pop(),
                    this.landlord_w9_upload.files[0],
                    { contentType: this.landlord_w9_upload.files[0].type });
                //console.log(result);
                this.landlord_w9_upload = null;

                this.setState({ landlord_w9_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }
        if (this.landlord_participationagreement_upload !== null && this.landlord_participationagreement_upload.files != null && this.landlord_participationagreement_upload.files.length > 0) {
            try {
                var result = await Storage.put(`ERA-IRVINE/${this.state.email}/landlord_participationagreement/${this.landlord_participationagreement_upload.files[0].name}`,
                    this.landlord_participationagreement_upload.files[0],
                    { contentType: this.landlord_participationagreement_upload.files[0].type });
                console.log(result);
                this.landlord_participationagreement_upload = null;

                this.setState({ landlord_participationagreement_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }
        if (this.verified_checkpoint_upload !== null && this.verified_checkpoint_upload.files != null && this.verified_checkpoint_upload.files.length > 0) {
            try {
                var result = await Storage.put(`ERA-IRVINE/${this.state.email}/verified_checkpoint/${this.verified_checkpoint_upload.files[0].name}`,
                    this.verified_checkpoint_upload.files[0],
                    { contentType: this.verified_checkpoint_upload.files[0].type });
                console.log(result);
                this.verified_checkpoint_upload = null;

                this.setState({ verified_checkpoint_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }

        if (this.landlord_driver_license_upload !== null && this.landlord_driver_license_upload.files != null && this.landlord_driver_license_upload.files.length > 0) {
            try {
                var result = await Storage.put(`ERA-IRVINE/${this.state.email}/landlord_driver_license/${this.landlord_driver_license_upload.files[0].name}`,
                    this.landlord_driver_license_upload.files[0],
                    { contentType: this.landlord_driver_license_upload.files[0].type });
                console.log(result);
                this.landlord_driver_license_upload = null;

                this.setState({ landlord_driver_license_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }

        if (this.management_agreement_upload !== null && this.management_agreement_upload.files != null && this.management_agreement_upload.files.length > 0) {
            try {
                var result = await Storage.put(`ERA-IRVINE/${this.state.email}/management_agreement/${this.management_agreement_upload.files[0].name}`,
                    this.management_agreement_upload.files[0],
                    { contentType: this.management_agreement_upload.files[0].type });
                console.log(result);
                this.management_agreement_upload = null;

                this.setState({ management_agreement_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }

    }

    lockDocuments = async () => {
        if (this.state.individualCase_Original !== 'Open') {
            this.photo_identification_upload = document.getElementById("photo_identification_upload_id");
            this.secondary_identification_upload = document.getElementById("secondary_identification_upload_id");
            this.secondary_proof_of_residential_tenancy_upload = document.getElementById("secondary_proof_of_residential_tenancy_upload_id");
            this.proof_of_past_due_rent_amount_upload = document.getElementById("proof_of_past_due_rent_amount_upload_id");
            this.proof_of_Annual_2020_household_income_upload = document.getElementById("proof_of_Annual_2020_household_income_upload_id");
            this.rental_agreement_upload = document.getElementById("rental_agreement_upload_id");
            this.supplemental_proofof_Annual_2020_household_income_upload = document.getElementById("supplemental_proofof_Annual_2020_household_income_upload_id");
            this.participation_agreement_upload = document.getElementById("participation_agreement_upload_id");
            this.proof_of_unemployment_upload = document.getElementById("proof_of_unemployment_upload_id");
            this.supplemental_proofof_unemployment_upload = document.getElementById("supplemental_proofof_unemployment_upload_id");
            this.authorization_to_release_information_to_SSA_upload = document.getElementById("authorization_to_release_information_to_SSA_upload_id");
            this.consent_to_exchange_information_upload = document.getElementById("consent_to_exchange_information_upload_id");
            this.authorization_to_release_information_to_HUD_upload = document.getElementById("authorization_to_release_information_to_HUD_upload_id");
            this.ami_household_income_worksheet_upload = document.getElementById("ami_household_income_worksheet_upload_id");
            // this.tenant_payment_agreement_upload = document.getElementById("tenant_payment_agreement_upload_id");

            this.third_party_payer_agreement_upload = document.getElementById("third_party_payer_agreement_upload_id");
            this.landlord_w9_upload = document.getElementById("landlord_w9_upload_id");
            this.landlord_participationagreement_upload = document.getElementById("landlord_participationagreement_upload_id");
            this.verified_checkpoint_upload = document.getElementById("verified_checkpoint_upload_id");
            this.landlord_driver_license_upload = document.getElementById("landlord_driver_license_upload_id");
            this.management_agreement_upload = document.getElementById("management_agreement_upload_id");
            // this.landlord_payment_agreement_upload = document.getElementById("landlord_payment_agreement_upload_id");

            if (this.photo_identification_upload !== null) {
                this.photo_identification_upload.disabled = true;
            }
            if (this.secondary_identification_upload !== null) {
                this.secondary_identification_upload.disabled = true;
            }
            if (this.secondary_proof_of_residential_tenancy_upload !== null) {
                this.secondary_proof_of_residential_tenancy_upload.disabled = true;
            }
            if (this.proof_of_past_due_rent_amount_upload !== null) {
                this.proof_of_past_due_rent_amount_upload.disabled = true;
            }
            if (this.proof_of_Annual_2020_household_income_upload !== null) {
                this.proof_of_Annual_2020_household_income_upload.disabled = true;
            }
            if (this.rental_agreement_upload !== null) {
                this.rental_agreement_upload.disabled = true;
            }
            if (this.supplemental_proofof_Annual_2020_household_income_upload !== null) {
                this.supplemental_proofof_Annual_2020_household_income_upload.disabled = true;
            }
            if (this.participation_agreement_upload !== null) {
                this.participation_agreement_upload.disabled = true;
            }
            if (this.proof_of_unemployment_upload !== null) {
                this.proof_of_unemployment_upload.disabled = true;
            }
            if (this.supplemental_proofof_unemployment_upload !== null) {
                this.supplemental_proofof_unemployment_upload.disabled = true;
            }
            if (this.authorization_to_release_information_to_SSA_upload !== null) {
                this.authorization_to_release_information_to_SSA_upload.disabled = true;
            }
            if (this.consent_to_exchange_information_upload !== null) {
                this.consent_to_exchange_information_upload.disabled = true;
            }
            if (this.authorization_to_release_information_to_HUD_upload !== null) {
                this.authorization_to_release_information_to_HUD_upload.disabled = true;
            }
            if (this.ami_household_income_worksheet_upload !== null) {
                this.ami_household_income_worksheet_upload.disabled = true;
            }
            // if (this.tenant_payment_agreement_upload !== null) {
            //     this.tenant_payment_agreement_upload.disabled = true;
            // }


            if (this.third_party_payer_agreement_upload !== null) {
                this.third_party_payer_agreement_upload.disabled = true;
            }
            if (this.landlord_w9_upload !== null) {
                this.landlord_w9_upload.disabled = true;
            }
            if (this.landlord_participationagreement_upload !== null) {
                this.landlord_participationagreement_upload.disabled = true;
            }
            if (this.verified_checkpoint_upload !== null) {
                this.verified_checkpoint_upload.disabled = true;
            }
            if (this.landlord_driver_license_upload !== null) {
                this.landlord_driver_license_upload.disabled = true;
            }
            if (this.management_agreement_upload !== null) {
                this.management_agreement_upload.disabled = true;
            }
            // if (this.landlord_payment_agreement_upload !== null) {
            //     this.landlord_payment_agreement_upload.disabled = true;
            // }
        }
    }

    loadUploadedDocuments = async () => {
        this.setState({ isLoading: true });

        document.getElementById('photo_identification_id').innerHTML = `<td>Photo Identification<div id="photo_identification_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="photo_identification_upload_id" /></td>`;
        document.getElementById('secondary_identification_id').innerHTML = `<td>Secondary Identification, if applicable<div id="secondary_identification_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="secondary_identification_upload_id" /></td>`;
        document.getElementById('secondary_proof_of_residential_tenancy_id').innerHTML = `<td>Secondary Proof of Residential Tenancy, if applicable<div id="secondary_proof_of_residential_tenancy_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="secondary_proof_of_residential_tenancy_upload_id" /></td>`;
        document.getElementById('proof_of_past_due_rent_amount_id').innerHTML = `<td>Proof of Past Due Rent Amount<div id="proof_of_past_due_rent_amount_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="proof_of_past_due_rent_amount_upload_id" /></td>`;
        document.getElementById('proof_of_Annual_2020_household_income_id').innerHTML = `<td>Proof of Annual 2020 Household Income for All Household Members<div id="proof_of_Annual_2020_household_income_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="proof_of_Annual_2020_household_income_upload_id" /></td>`;
        document.getElementById('rental_agreement_id').innerHTML = `<td>Rental Lease Agreement<div id="rental_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="rental_agreement_upload_id" /></td>`;
        document.getElementById('supplemental_proofof_Annual_2020_household_income_id').innerHTML = `<td>Supplemental Proof of Annual 2020 Household Income for All Household Members<div id="supplemental_proofof_Annual_2020_household_income_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="supplemental_proofof_Annual_2020_household_income_upload_id" /></td>`;
        document.getElementById('participation_agreement_id').innerHTML = `<td>City of Irvine, Tenant Applicant, Participation Agreement<div id="participation_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="participation_agreement_upload_id" /></td>`;
        document.getElementById('proof_of_unemployment_id').innerHTML = `<td>Proof of Unemployment and/or Financial Hardship Due to COVID-19<div id="proof_of_unemployment_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="proof_of_unemployment_upload_id" /></td>`;
        document.getElementById('supplemental_proofof_unemployment_id').innerHTML = `<td>Supplemental Proof of Unemployment and/or Financial Hardship Due to COVID-19<div id="supplemental_proofof_unemployment_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="supplemental_proofof_unemployment_upload_id" /></td>`;
        document.getElementById('authorization_to_release_information_to_SSA_id').innerHTML = `<td>Authorization to Release Information to Social Services Agency (for all other Housing Authorities)<div id="authorization_to_release_information_to_SSA_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="authorization_to_release_information_to_SSA_upload_id" /></td>`;
        document.getElementById('consent_to_exchange_information_id').innerHTML = `<td>Consent to Exchange Information with Homeless Management Information System and other parties such as Landlord<div id="consent_to_exchange_information_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="consent_to_exchange_information_upload_id" /></td>`;
        document.getElementById('authorization_to_release_information_to_HUD_id').innerHTML = `<td>Authorization to Release Information to Housing and Urban Development (for OC Housing Authority)<div id="authorization_to_release_information_to_HUD_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="authorization_to_release_information_to_HUD_upload_id" /></td>`;
        document.getElementById('ami_household_income_worksheet_id').innerHTML = `<td>AMI-Household Income Worksheet<div id="ami_household_income_worksheet_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="ami_household_income_worksheet_upload_id" /></td>`;
        document.getElementById('tenant_payment_agreement_id').innerHTML = `<td>Tenant Payment Agreement<div id="tenant_payment_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="tenant_payment_agreement_upload_id" /></td>`;

        document.getElementById('third_party_payer_agreement_id').innerHTML = `<td>Third Party Approval Document<div id="third_party_payer_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="third_party_payer_agreement_upload_id" /></td>`;
        document.getElementById('landlord_w9_id').innerHTML = `<td>Landlord W9<div id="landlord_w9_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="landlord_w9_upload_id" /></td>`;
        document.getElementById('landlord_participationagreement_id').innerHTML = `<td>Landlord Participation Agreement<div id="landlord_participationagreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="landlord_participationagreement_upload_id" /></td>`;
        document.getElementById('verified_checkpoint_id').innerHTML = `<td>CheckPoint ID Verification Page<div id="verified_checkpoint_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="verified_checkpoint_upload_id" /></td>`;
        document.getElementById('landlord_driver_license_id').innerHTML = `<td>Driver’s License or other government issued photo ID<div id="landlord_driver_license_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="landlord_driver_license_upload_id" /></td>`;
        document.getElementById('management_agreement_id').innerHTML = `<td>Management Agreement<div id="management_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="management_agreement_upload_id" /></td>`;
        document.getElementById('landlord_payment_agreement_id').innerHTML = `<td>Landlord Payment Agreement<div id="landlord_payment_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="landlord_payment_agreement_upload_id" /></td>`;

        // Lock documents if individual case is set to other than open
        this.lockDocuments();

        // Reset all the flags
        this.setState({
            photo_identification_uploaded: false,
            secondary_identification_uploaded: false,
            secondary_proof_of_residential_tenancy_uploaded: false,
            proof_of_past_due_rent_amount_uploaded: false,
            proof_of_Annual_2020_household_income_uploaded: false,
            rental_agreement_uploaded: false,
            supplemental_proofof_Annual_2020_household_income_uploaded: false,
            participation_agreement_uploaded: false,
            proof_of_unemployment_uploaded: false,
            supplemental_proofof_unemployment_uploaded: false,
            authorization_to_release_information_to_SSA_uploaded: false,
            consent_to_exchange_information_uploaded: false,
            authorization_to_release_information_to_HUD_uploaded: false,
            ami_household_income_worksheet_uploaded: false,
            tenant_payment_agreement_uploaded: false,

            third_party_payer_agreement_uploaded: false,
            landlord_w9_uploaded: false,
            landlord_participationagreement_uploaded: false,
            verified_checkpoint_uploaded: false,
            landlord_driver_license_uploaded: false,
            management_agreement_uploaded: false,
            landlord_payment_agreement_uploaded: false,
        });

        Storage.configure({
            bucket: process.env.REACT_APP_S3FrontendBucket,
            region: process.env.REACT_APP_Region,
            level: 'public',
        });

        //get file names for s3 keys
        try {
            var key = `ERA-IRVINE/${this.state.email}/`;
            var files = await Storage.list(key);
            console.log(files);
        }
        catch (err) {
            console.log("Error Files - " + err);
            this.setState({ isLoading: false });
            return 1;
        }

        for (var i = 0; i < files.length; i++) {
            if (files[i].key.includes("photo_identification/")) {
                try {
                    var file1 = await Storage.get(files[i].key);
                    this.setState({ photo_identification: file1 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    if (this.state.mode && this.state.mode == "User") {
                        document.getElementById('photo_identification_id').innerHTML = `<td>Photo Identification<div id="photo_identification_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.photo_identification}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File1"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
                    }
                    else {
                        document.getElementById('photo_identification_id').innerHTML = `<td>Photo Identification<div id="photo_identification_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.photo_identification}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File1"><i class="fas fa-trash-alt red"></i></a></td>`;
                        var aTag1 = document.getElementById('deleteS3File1');
                        if (aTag1 !== null && aTag1 !== undefined) {
                            if (this.state.individualCase_Original !== 'Open') {
                                aTag1.style.visibility = "hidden";
                            }
                            else {
                                if (aTag1.addEventListener) {
                                    aTag1.addEventListener("click", () => { this.deleteS3File(1) });
                                }
                                else if (aTag1.attachEvent) {
                                    aTag1.attachEvent("click", () => { this.deleteS3File(1) });
                                }
                            }
                        }
                    }
                    this.setState({ photo_identification_uploaded: true });
                }
                catch (err) {
                    console.log("Error photo_identification - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('photo_identification_id').innerHTML = `<td>Photo Identification<div id="photo_identification_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="photo_identification_upload_id" /></td>`;
                    return 1;
                }
            }
            else if (files[i].key.includes("secondary_identification/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ secondary_identification: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    if (this.state.mode && this.state.mode == "User") {
                        document.getElementById('secondary_identification_id').innerHTML = `<td>Secondary Identification, if applicable<div id="secondary_identification_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.secondary_identification}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File2"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
                    }
                    else {
                        document.getElementById('secondary_identification_id').innerHTML = `<td>Secondary Identification, if applicable<div id="secondary_identification_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.secondary_identification}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File2"><i class="fas fa-trash-alt red"></i></a></td>`;
                        var aTag2 = document.getElementById('deleteS3File2');
                        if (aTag2 !== null && aTag2 !== undefined) {
                            if (this.state.individualCase_Original !== 'Open') {
                                aTag2.style.visibility = "hidden";
                            }
                            else {
                                if (aTag2.addEventListener) {
                                    aTag2.addEventListener("click", () => { this.deleteS3File(2) });
                                }
                                else if (aTag2.attachEvent) {
                                    aTag2.attachEvent("click", () => { this.deleteS3File(2) });
                                }
                            }
                        }
                    }
                    this.setState({ secondary_identification_uploaded: true });
                }
                catch (err) {
                    console.log("Error secondary_identification - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('secondary_identification_id').innerHTML = `<td>Secondary Identification, if applicable<div id="secondary_identification_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="secondary_identification_upload_id" /></td>`;
                    return 2;
                }
            }
            else if (files[i].key.includes("secondary_proof_of_residential_tenancy/")) {
                try {
                    var file31 = await Storage.get(files[i].key);
                    this.setState({ secondary_proof_of_residential_tenancy: file31 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    if (this.state.mode && this.state.mode == "User") {
                        document.getElementById('secondary_proof_of_residential_tenancy_id').innerHTML = `<td>Secondary Proof of Residential Tenancy, if applicable<div id="secondary_proof_of_residential_tenancy_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.secondary_proof_of_residential_tenancy}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File3"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
                    }
                    else {
                        document.getElementById('secondary_proof_of_residential_tenancy_id').innerHTML = `<td>Secondary Proof of Residential Tenancy, if applicable<div id="secondary_proof_of_residential_tenancy_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.secondary_proof_of_residential_tenancy}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File3"><i class="fas fa-trash-alt red"></i></a></td>`;
                        var aTag31 = document.getElementById('deleteS3File3');
                        if (aTag31 !== null && aTag31 !== undefined) {
                            if (this.state.individualCase_Original !== 'Open') {
                                aTag31.style.visibility = "hidden";
                            }
                            else {
                                if (aTag31.addEventListener) {
                                    aTag31.addEventListener("click", () => { this.deleteS3File(3) });
                                }
                                else if (aTag31.attachEvent) {
                                    aTag31.attachEvent("click", () => { this.deleteS3File(3) });
                                }
                            }
                        }
                    }
                    this.setState({ secondary_proof_of_residential_tenancy_uploaded: true });
                }
                catch (err) {
                    console.log("Error secondary_proof_of_residential_tenancy - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('secondary_proof_of_residential_tenancy_id').innerHTML = `<td>Secondary Proof of Residential Tenancy, if applicable<div id="secondary_proof_of_residential_tenancy_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="secondary_proof_of_residential_tenancy_upload_id" /></td>`;
                    return 3;
                }
            }
            else if (files[i].key.includes("proof_of_past_due_rent_amount/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ proof_of_past_due_rent_amount: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    if (this.state.mode && this.state.mode == "User") {
                        document.getElementById('proof_of_past_due_rent_amount_id').innerHTML = `<td>Proof of Past Due Rent Amount<div id="proof_of_past_due_rent_amount_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.proof_of_past_due_rent_amount}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File4"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
                    }
                    else {
                        document.getElementById('proof_of_past_due_rent_amount_id').innerHTML = `<td>Proof of Past Due Rent Amount<div id="proof_of_past_due_rent_amount_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.proof_of_past_due_rent_amount}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File4"><i class="fas fa-trash-alt red"></i></a></td>`;
                        var aTag2 = document.getElementById('deleteS3File4');
                        if (aTag2 !== null && aTag2 !== undefined) {
                            if (this.state.individualCase_Original !== 'Open') {
                                aTag2.style.visibility = "hidden";
                            }
                            else {
                                if (aTag2.addEventListener) {
                                    aTag2.addEventListener("click", () => { this.deleteS3File(4) });
                                }
                                else if (aTag2.attachEvent) {
                                    aTag2.attachEvent("click", () => { this.deleteS3File(4) });
                                }
                            }
                        }
                    }
                    this.setState({ proof_of_past_due_rent_amount_uploaded: true });
                }
                catch (err) {
                    console.log("Error proof_of_past_due_rent_amount - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('proof_of_past_due_rent_amount_id').innerHTML = `<td>Proof of Past Due Rent Amount<div id="proof_of_past_due_rent_amount_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="proof_of_past_due_rent_amount_upload_id" /></td>`;
                    return 4;
                }
            }
            else if (files[i].key.includes("proof_of_Annual_2020_household_income/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ proof_of_Annual_2020_household_income: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    if (this.state.mode && this.state.mode == "User") {
                        document.getElementById('proof_of_Annual_2020_household_income_id').innerHTML = `<td>Proof of Annual 2020 Household Income for All Household Members<div id="proof_of_Annual_2020_household_income_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.proof_of_Annual_2020_household_income}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File5"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
                    }
                    else {
                        document.getElementById('proof_of_Annual_2020_household_income_id').innerHTML = `<td>Proof of Annual 2020 Household Income for All Household Members<div id="proof_of_Annual_2020_household_income_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.proof_of_Annual_2020_household_income}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File5"><i class="fas fa-trash-alt red"></i></a></td>`;
                        var aTag2 = document.getElementById('deleteS3File5');
                        if (aTag2 !== null && aTag2 !== undefined) {
                            if (this.state.individualCase_Original !== 'Open') {
                                aTag2.style.visibility = "hidden";
                            }
                            else {
                                if (aTag2.addEventListener) {
                                    aTag2.addEventListener("click", () => { this.deleteS3File(5) });
                                }
                                else if (aTag2.attachEvent) {
                                    aTag2.attachEvent("click", () => { this.deleteS3File(5) });
                                }
                            }
                        }
                    }
                    this.setState({ proof_of_Annual_2020_household_income_uploaded: true });
                }
                catch (err) {
                    console.log("Error proof_of_Annual_2020_household_income - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('proof_of_Annual_2020_household_income_id').innerHTML = `<td>Proof of Annual 2020 Household Income for All Household Members<div id="proof_of_Annual_2020_household_income_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="proof_of_Annual_2020_household_income_upload_id" /></td>`;
                    return 5;
                }
            }
            else if (files[i].key.includes("rental_agreement/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ rental_agreement: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    if (this.state.mode && this.state.mode == "User") {
                        document.getElementById('rental_agreement_id').innerHTML = `<td>Rental Lease Agreement<div id="rental_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.rental_agreement}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File6"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
                    }
                    else {
                        document.getElementById('rental_agreement_id').innerHTML = `<td>Rental Lease Agreement<div id="rental_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.rental_agreement}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File6"><i class="fas fa-trash-alt red"></i></a></td>`;
                        var aTag2 = document.getElementById('deleteS3File6');
                        if (aTag2 !== null && aTag2 !== undefined) {
                            if (this.state.individualCase_Original !== 'Open') {
                                aTag2.style.visibility = "hidden";
                            }
                            else {
                                if (aTag2.addEventListener) {
                                    aTag2.addEventListener("click", () => { this.deleteS3File(6) });
                                }
                                else if (aTag2.attachEvent) {
                                    aTag2.attachEvent("click", () => { this.deleteS3File(6) });
                                }
                            }
                        }
                    }
                    this.setState({ rental_agreement_uploaded: true });
                }
                catch (err) {
                    console.log("Error rental_agreement - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('rental_agreement_id').innerHTML = `<td>Rental Lease Agreement<div id="rental_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="rental_agreement_upload_id" /></td>`;
                    return 6;
                }
            }
            else if (files[i].key.includes("supplemental_proofof_Annual_2020_household_income/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ supplemental_proofof_Annual_2020_household_income: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    if (this.state.mode && this.state.mode == "User") {
                        document.getElementById('supplemental_proofof_Annual_2020_household_income_id').innerHTML = `<td>Supplemental Proof of Annual 2020 Household Income for All Household Members<div id="supplemental_proofof_Annual_2020_household_income_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.supplemental_proofof_Annual_2020_household_income}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File7"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
                    }
                    else {
                        document.getElementById('supplemental_proofof_Annual_2020_household_income_id').innerHTML = `<td>Supplemental Proof of Annual 2020 Household Income for All Household Members<div id="supplemental_proofof_Annual_2020_household_income_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.supplemental_proofof_Annual_2020_household_income}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File7"><i class="fas fa-trash-alt red"></i></a></td>`;
                        var aTag2 = document.getElementById('deleteS3File7');
                        if (aTag2 !== null && aTag2 !== undefined) {
                            if (this.state.individualCase_Original !== 'Open') {
                                aTag2.style.visibility = "hidden";
                            }
                            else {
                                if (aTag2.addEventListener) {
                                    aTag2.addEventListener("click", () => { this.deleteS3File(7) });
                                }
                                else if (aTag2.attachEvent) {
                                    aTag2.attachEvent("click", () => { this.deleteS3File(7) });
                                }
                            }
                        }
                    }
                    this.setState({ supplemental_proofof_Annual_2020_household_income_uploaded: true });
                }
                catch (err) {
                    console.log("Error supplemental_proofof_Annual_2020_household_income - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('supplemental_proofof_Annual_2020_household_income_id').innerHTML = `<td>Supplemental Proof of Annual 2020 Household Income for All Household Members<div id="supplemental_proofof_Annual_2020_household_income_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="supplemental_proofof_Annual_2020_household_income_upload_id" /></td>`;
                    return 7;
                }
            }
            else if (files[i].key.includes("participation_agreement/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ participation_agreement: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    if (this.state.mode && this.state.mode == "User") {
                        document.getElementById('participation_agreement_id').innerHTML = `<td>City of Irvine, Tenant Applicant, Participation Agreement<div id="participation_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.participation_agreement}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File8"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
                    }
                    else {
                        document.getElementById('participation_agreement_id').innerHTML = `<td>City of Irvine, Tenant Applicant, Participation Agreement<div id="participation_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.participation_agreement}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File8"><i class="fas fa-trash-alt red"></i></a></td>`;
                        var aTag2 = document.getElementById('deleteS3File8');
                        if (aTag2 !== null && aTag2 !== undefined) {
                            if (this.state.individualCase_Original !== 'Open') {
                                aTag2.style.visibility = "hidden";
                            }
                            else {
                                if (aTag2.addEventListener) {
                                    aTag2.addEventListener("click", () => { this.deleteS3File(8) });
                                }
                                else if (aTag2.attachEvent) {
                                    aTag2.attachEvent("click", () => { this.deleteS3File(8) });
                                }
                            }
                        }
                    }
                    this.setState({ participation_agreement_uploaded: true });
                }
                catch (err) {
                    console.log("Error participation_agreement - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('participation_agreement_id').innerHTML = `<td>City of Irvine, Tenant Applicant, Participation Agreement<div id="participation_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="participation_agreement_upload_id" /></td>`;
                    return 8;
                }
            }
            else if (files[i].key.includes("landlord_w9/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ landlord_w9: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    if (this.state.mode && this.state.mode == "User") {
                        document.getElementById('landlord_w9_id').innerHTML = `<td>Landlord W9<div id="landlord_w9_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.landlord_w9}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File9"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
                    }
                    else {
                        document.getElementById('landlord_w9_id').innerHTML = `<td>Landlord W9<div id="landlord_w9_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.landlord_w9}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File9"><i class="fas fa-trash-alt red"></i></a></td>`;
                        var aTag2 = document.getElementById('deleteS3File9');
                        if (aTag2 !== null && aTag2 !== undefined) {
                            if (this.state.individualCase_Original !== 'Open') {
                                aTag2.style.visibility = "hidden";
                            }
                            else {
                                if (aTag2.addEventListener) {
                                    aTag2.addEventListener("click", () => { this.deleteS3File(9) });
                                }
                                else if (aTag2.attachEvent) {
                                    aTag2.attachEvent("click", () => { this.deleteS3File(9) });
                                }
                            }
                        }
                    }
                    this.setState({ landlord_w9_uploaded: true });
                }
                catch (err) {
                    console.log("Error landlord_w9 - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('landlord_w9_id').innerHTML = `<td>Landlord W9<div id="landlord_w9_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="landlord_w9_upload_id" /></td>`;
                    return 9;
                }
            }
            else if (files[i].key.includes("landlord_driver_license/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ landlord_driver_license: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    if (this.state.mode && this.state.mode == "User") {
                        document.getElementById('landlord_driver_license_id').innerHTML = `<td>Driver’s License or other government issued photo ID<div id="landlord_driver_license_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.landlord_driver_license}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File10"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
                    }
                    else {
                        document.getElementById('landlord_driver_license_id').innerHTML = `<td>Driver’s License or other government issued photo ID<div id="landlord_driver_license_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.landlord_driver_license}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File10"><i class="fas fa-trash-alt red"></i></a></td>`;
                        var aTag2 = document.getElementById('deleteS3File10');
                        if (aTag2 !== null && aTag2 !== undefined) {
                            if (this.state.individualCase_Original !== 'Open') {
                                aTag2.style.visibility = "hidden";
                            }
                            else {
                                if (aTag2.addEventListener) {
                                    aTag2.addEventListener("click", () => { this.deleteS3File(10) });
                                }
                                else if (aTag2.attachEvent) {
                                    aTag2.attachEvent("click", () => { this.deleteS3File(10) });
                                }
                            }
                        }
                    }
                    this.setState({ landlord_driver_license_uploaded: true });
                }
                catch (err) {
                    console.log("Error landlord_driver_license - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('landlord_driver_license_id').innerHTML = `<td>Driver’s License or other government issued photo ID<div id="landlord_driver_license_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="landlord_driver_license_upload_id" /></td>`;
                    return 10;
                }
            }
            else if (files[i].key.includes("proof_of_unemployment/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ proof_of_unemployment: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    if (this.state.mode && this.state.mode == "User") {
                        document.getElementById('proof_of_unemployment_id').innerHTML = `<td>Proof of Unemployment and/or Financial Hardship Due to COVID-19<div id="proof_of_unemployment_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.proof_of_unemployment}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File11"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
                    }
                    else {
                        document.getElementById('proof_of_unemployment_id').innerHTML = `<td>Proof of Unemployment and/or Financial Hardship Due to COVID-19<div id="proof_of_unemployment_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.proof_of_unemployment}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File11"><i class="fas fa-trash-alt red"></i></a></td>`;
                        var aTag2 = document.getElementById('deleteS3File11');
                        if (aTag2 !== null && aTag2 !== undefined) {
                            if (this.state.individualCase_Original !== 'Open') {
                                aTag2.style.visibility = "hidden";
                            }
                            else {
                                if (aTag2.addEventListener) {
                                    aTag2.addEventListener("click", () => { this.deleteS3File(11) });
                                }
                                else if (aTag2.attachEvent) {
                                    aTag2.attachEvent("click", () => { this.deleteS3File(11) });
                                }
                            }
                        }
                    }
                    this.setState({ proof_of_unemployment_uploaded: true });
                }
                catch (err) {
                    console.log("Error proof_of_unemployment - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('proof_of_unemployment_id').innerHTML = `<td>Proof of Unemployment and/or Financial Hardship Due to COVID-19<div id="proof_of_unemployment_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="proof_of_unemployment_upload_id" /></td>`;
                    return 11;
                }
            }
            else if (files[i].key.includes("supplemental_proofof_unemployment/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ supplemental_proofof_unemployment: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    if (this.state.mode && this.state.mode == "User") {
                        document.getElementById('supplemental_proofof_unemployment_id').innerHTML = `<td>Supplemental Proof of Unemployment and/or Financial Hardship Due to COVID-19<div id="supplemental_proofof_unemployment_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.supplemental_proofof_unemployment}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File12"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
                    }
                    else {
                        document.getElementById('supplemental_proofof_unemployment_id').innerHTML = `<td>Supplemental Proof of Unemployment and/or Financial Hardship Due to COVID-19<div id="supplemental_proofof_unemployment_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.supplemental_proofof_unemployment}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File12"><i class="fas fa-trash-alt red"></i></a></td>`;
                        var aTag2 = document.getElementById('deleteS3File12');
                        if (aTag2 !== null && aTag2 !== undefined) {
                            if (this.state.individualCase_Original !== 'Open') {
                                aTag2.style.visibility = "hidden";
                            }
                            else {
                                if (aTag2.addEventListener) {
                                    aTag2.addEventListener("click", () => { this.deleteS3File(12) });
                                }
                                else if (aTag2.attachEvent) {
                                    aTag2.attachEvent("click", () => { this.deleteS3File(12) });
                                }
                            }
                        }
                    }
                    this.setState({ supplemental_proofof_unemployment_uploaded: true });
                }
                catch (err) {
                    console.log("Error supplemental_proofof_unemployment - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('supplemental_proofof_unemployment_id').innerHTML = `<td>Supplemental Proof of Unemployment and/or Financial Hardship Due to COVID-19<div id="supplemental_proofof_unemployment_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="supplemental_proofof_unemployment_upload_id" /></td>`;
                    return 12;
                }
            }
            else if (files[i].key.includes("authorization_to_release_information_to_SSA/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ authorization_to_release_information_to_SSA: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    if (this.state.mode && this.state.mode == "User") {
                        document.getElementById('authorization_to_release_information_to_SSA_id').innerHTML = `<td>Authorization to Release Information to Social Services Agency (for all other Housing Authorities)<div id="authorization_to_release_information_to_SSA_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.authorization_to_release_information_to_SSA}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File13"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
                    }
                    else {
                        document.getElementById('authorization_to_release_information_to_SSA_id').innerHTML = `<td>Authorization to Release Information to Social Services Agency (for all other Housing Authorities)<div id="authorization_to_release_information_to_SSA_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.authorization_to_release_information_to_SSA}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File13"><i class="fas fa-trash-alt red"></i></a></td>`;
                        var aTag2 = document.getElementById('deleteS3File13');
                        if (aTag2 !== null && aTag2 !== undefined) {
                            if (this.state.individualCase_Original !== 'Open') {
                                aTag2.style.visibility = "hidden";
                            }
                            else {
                                if (aTag2.addEventListener) {
                                    aTag2.addEventListener("click", () => { this.deleteS3File(13) });
                                }
                                else if (aTag2.attachEvent) {
                                    aTag2.attachEvent("click", () => { this.deleteS3File(13) });
                                }
                            }
                        }
                    }
                    this.setState({ authorization_to_release_information_to_SSA_uploaded: true });
                }
                catch (err) {
                    console.log("Error authorization_to_release_information_to_SSA - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('authorization_to_release_information_to_SSA_id').innerHTML = `<td>Authorization to Release Information to Social Services Agency (for all other Housing Authorities)<div id="authorization_to_release_information_to_SSA_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="authorization_to_release_information_to_SSA_upload_id" /></td>`;
                    return 13;
                }
            }

            else if (files[i].key.includes("third_party_payer_agreement/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ third_party_payer_agreement: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    if (this.state.mode && this.state.mode == "User") {
                        document.getElementById('third_party_payer_agreement_id').innerHTML = `<td>Third Party Approval Document<div id="third_party_payer_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.third_party_payer_agreement}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File14"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
                    }
                    else {
                        document.getElementById('third_party_payer_agreement_id').innerHTML = `<td>Third Party Approval Document<div id="third_party_payer_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.third_party_payer_agreement}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File14"><i class="fas fa-trash-alt red"></i></a></td>`;
                        var aTag2 = document.getElementById('deleteS3File14');
                        if (aTag2 !== null && aTag2 !== undefined) {
                            if (this.state.individualCase_Original !== 'Open') {
                                aTag2.style.visibility = "hidden";
                            }
                            else {
                                if (aTag2.addEventListener) {
                                    aTag2.addEventListener("click", () => { this.deleteS3File(14) });
                                }
                                else if (aTag2.attachEvent) {
                                    aTag2.attachEvent("click", () => { this.deleteS3File(14) });
                                }
                            }
                        }
                    }
                    this.setState({ third_party_payer_agreement_uploaded: true });
                }
                catch (err) {
                    console.log("Error third_party_payer_agreement - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('third_party_payer_agreement_id').innerHTML = `<td>Third Party Approval Document<div id="third_party_payer_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="third_party_payer_agreement_upload_id" /></td>`;
                    return 14;
                }
            }
            else if (files[i].key.includes("landlord_participationagreement/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ landlord_participationagreement: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    if (this.state.mode && this.state.mode == "User") {
                        document.getElementById('landlord_participationagreement_id').innerHTML = `<td>Landlord Participation Agreement<div id="landlord_participationagreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.landlord_participationagreement}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File15"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
                    }
                    else {
                        document.getElementById('landlord_participationagreement_id').innerHTML = `<td>Landlord Participation Agreement<div id="landlord_participationagreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.landlord_participationagreement}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File15"><i class="fas fa-trash-alt red"></i></a></td>`;
                        var aTag2 = document.getElementById('deleteS3File15');
                        if (aTag2 !== null && aTag2 !== undefined) {
                            if (this.state.individualCase_Original !== 'Open') {
                                aTag2.style.visibility = "hidden";
                            }
                            else {
                                if (aTag2.addEventListener) {
                                    aTag2.addEventListener("click", () => { this.deleteS3File(15) });
                                }
                                else if (aTag2.attachEvent) {
                                    aTag2.attachEvent("click", () => { this.deleteS3File(15) });
                                }
                            }
                        }
                    }
                    this.setState({ landlord_participationagreement_uploaded: true });
                }
                catch (err) {
                    console.log("Error landlord_participationagreement - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('landlord_participationagreement_id').innerHTML = `<td>Landlord Participation Agreement<div id="landlord_participationagreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="landlord_participationagreement_upload_id" /></td>`;
                    return 15;
                }
            }
            else if (files[i].key.includes("verified_checkpoint/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ verified_checkpoint: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    if (this.state.mode && this.state.mode == "User") {
                        document.getElementById('verified_checkpoint_id').innerHTML = `<td>CheckPoint ID Verification Page<div id="verified_checkpoint_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.verified_checkpoint}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File16"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
                    }
                    else {
                        document.getElementById('verified_checkpoint_id').innerHTML = `<td>CheckPoint ID Verification Page<div id="verified_checkpoint_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.verified_checkpoint}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File16"><i class="fas fa-trash-alt red"></i></a></td>`;
                        var aTag2 = document.getElementById('deleteS3File16');
                        if (aTag2 !== null && aTag2 !== undefined) {
                            if (this.state.individualCase_Original !== 'Open') {
                                aTag2.style.visibility = "hidden";
                            }
                            else {
                                if (aTag2.addEventListener) {
                                    aTag2.addEventListener("click", () => { this.deleteS3File(16) });
                                }
                                else if (aTag2.attachEvent) {
                                    aTag2.attachEvent("click", () => { this.deleteS3File(16) });
                                }
                            }
                        }
                    }
                    this.setState({ verified_checkpoint_uploaded: true });
                }
                catch (err) {
                    console.log("Error verified_checkpoint - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('verified_checkpoint_id').innerHTML = `<td>CheckPoint ID Verification Page<div id="verified_checkpoint_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="verified_checkpoint_upload_id" /></td>`;
                    return 16;
                }
            }


            else if (files[i].key.includes("consent_to_exchange_information/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ consent_to_exchange_information: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    if (this.state.mode && this.state.mode == "User") {
                        document.getElementById('consent_to_exchange_information_id').innerHTML = `<td>Consent to Exchange Information with Homeless Management Information System and other parties such as Landlord<div id="consent_to_exchange_information_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.consent_to_exchange_information}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File17"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
                    }
                    else {
                        document.getElementById('consent_to_exchange_information_id').innerHTML = `<td>Consent to Exchange Information with Homeless Management Information System and other parties such as Landlord<div id="consent_to_exchange_information_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.consent_to_exchange_information}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File17"><i class="fas fa-trash-alt red"></i></a></td>`;
                        var aTag2 = document.getElementById('deleteS3File17');
                        if (aTag2 !== null && aTag2 !== undefined) {
                            if (this.state.individualCase_Original !== 'Open') {
                                aTag2.style.visibility = "hidden";
                            }
                            else {
                                if (aTag2.addEventListener) {
                                    aTag2.addEventListener("click", () => { this.deleteS3File(17) });
                                }
                                else if (aTag2.attachEvent) {
                                    aTag2.attachEvent("click", () => { this.deleteS3File(17) });
                                }
                            }
                        }
                    }
                    this.setState({ consent_to_exchange_information_uploaded: true });
                }
                catch (err) {
                    console.log("Error consent_to_exchange_information - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('consent_to_exchange_information_id').innerHTML = `<td>Consent to Exchange Information with Homeless Management Information System and other parties such as Landlord<div id="consent_to_exchange_information_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="consent_to_exchange_information_upload_id" /></td>`;
                    return 17;
                }
            }
            else if (files[i].key.includes("authorization_to_release_information_to_HUD/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ authorization_to_release_information_to_HUD: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    if (this.state.mode && this.state.mode == "User") {
                        document.getElementById('authorization_to_release_information_to_HUD_id').innerHTML = `<td>Authorization to Release Information to Housing and Urban Development (for OC Housing Authority)<div id="authorization_to_release_information_to_HUD_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.authorization_to_release_information_to_HUD}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File18"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
                    }
                    else {
                        document.getElementById('authorization_to_release_information_to_HUD_id').innerHTML = `<td>Authorization to Release Information to Housing and Urban Development (for OC Housing Authority)<div id="authorization_to_release_information_to_HUD_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.authorization_to_release_information_to_HUD}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File18"><i class="fas fa-trash-alt red"></i></a></td>`;
                        var aTag2 = document.getElementById('deleteS3File18');
                        if (aTag2 !== null && aTag2 !== undefined) {
                            if (this.state.individualCase_Original !== 'Open') {
                                aTag2.style.visibility = "hidden";
                            }
                            else {
                                if (aTag2.addEventListener) {
                                    aTag2.addEventListener("click", () => { this.deleteS3File(18) });
                                }
                                else if (aTag2.attachEvent) {
                                    aTag2.attachEvent("click", () => { this.deleteS3File(18) });
                                }
                            }
                        }
                    }
                    this.setState({ authorization_to_release_information_to_HUD_uploaded: true });
                }
                catch (err) {
                    console.log("Error authorization_to_release_information_to_HUD - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('authorization_to_release_information_to_HUD_id').innerHTML = `<td>Authorization to Release Information to Housing and Urban Development (for OC Housing Authority)<div id="authorization_to_release_information_to_HUD_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="authorization_to_release_information_to_HUD_upload_id" /></td>`;
                    return 18;
                }
            }
            else if (files[i].key.includes("ami_household_income_worksheet/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ ami_household_income_worksheet: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    if (this.state.mode && this.state.mode == "User") {
                        document.getElementById('ami_household_income_worksheet_id').innerHTML = `<td>AMI-Household Income Worksheet<div id="ami_household_income_worksheet_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.ami_household_income_worksheet}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File19"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
                    }
                    else {
                        document.getElementById('ami_household_income_worksheet_id').innerHTML = `<td>AMI-Household Income Worksheet<div id="ami_household_income_worksheet_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.ami_household_income_worksheet}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File19"><i class="fas fa-trash-alt red"></i></a></td>`;
                        var aTag2 = document.getElementById('deleteS3File19');
                        if (aTag2 !== null && aTag2 !== undefined) {
                            if (this.state.individualCase_Original !== 'Open') {
                                aTag2.style.visibility = "hidden";
                            }
                            else {
                                if (aTag2.addEventListener) {
                                    aTag2.addEventListener("click", () => { this.deleteS3File(19) });
                                }
                                else if (aTag2.attachEvent) {
                                    aTag2.attachEvent("click", () => { this.deleteS3File(19) });
                                }
                            }
                        }
                    }
                    this.setState({ ami_household_income_worksheet_uploaded: true });
                }
                catch (err) {
                    console.log("Error ami_household_income_worksheet - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('ami_household_income_worksheet_id').innerHTML = `<td>AMI-Household Income Worksheet<div id="ami_household_income_worksheet_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="ami_household_income_worksheet_upload_id" /></td>`;
                    return 19;
                }
            }
            else if (files[i].key.includes("tenant_payment_agreement/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ tenant_payment_agreement: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    if (this.state.mode && this.state.mode == "User") {
                        document.getElementById('tenant_payment_agreement_id').innerHTML = `<td>Tenant Payment Agreement<div id="tenant_payment_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.tenant_payment_agreement}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File20"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
                    }
                    else {
                        document.getElementById('tenant_payment_agreement_id').innerHTML = `<td>Tenant Payment Agreement<div id="tenant_payment_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.tenant_payment_agreement}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File20"><i class="fas fa-trash-alt red"></i></a></td>`;
                        var aTag2 = document.getElementById('deleteS3File20');
                        if (aTag2 !== null && aTag2 !== undefined) {
                            if (this.state.individualCase_Original !== 'Open') {
                                aTag2.style.visibility = "hidden";
                            }
                            else {
                                if (aTag2.addEventListener) {
                                    aTag2.addEventListener("click", () => { this.deleteS3File(20) });
                                }
                                else if (aTag2.attachEvent) {
                                    aTag2.attachEvent("click", () => { this.deleteS3File(20) });
                                }
                            }
                        }
                    }
                    this.setState({ tenant_payment_agreement_uploaded: true });
                }
                catch (err) {
                    console.log("Error tenant_payment_agreement - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('tenant_payment_agreement_id').innerHTML = `<td>Tenant Payment Agreement<div id="tenant_payment_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="tenant_payment_agreement_upload_id" /></td>`;
                    return 20;
                }
            }

            else if (files[i].key.includes("management_agreement/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ management_agreement: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    if (this.state.mode && this.state.mode == "User") {
                        document.getElementById('management_agreement_id').innerHTML = `<td>Management Agreement<div id="management_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.management_agreement}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File21"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
                    }
                    else {
                        document.getElementById('management_agreement_id').innerHTML = `<td>Management Agreement<div id="management_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.management_agreement}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File21"><i class="fas fa-trash-alt red"></i></a></td>`;
                        var aTag2 = document.getElementById('deleteS3File21');
                        if (aTag2 !== null && aTag2 !== undefined) {
                            if (this.state.individualCase_Original !== 'Open') {
                                aTag2.style.visibility = "hidden";
                            }
                            else {
                                if (aTag2.addEventListener) {
                                    aTag2.addEventListener("click", () => { this.deleteS3File(21) });
                                }
                                else if (aTag2.attachEvent) {
                                    aTag2.attachEvent("click", () => { this.deleteS3File(21) });
                                }
                            }
                        }
                    }
                    this.setState({ management_agreement_uploaded: true });
                }
                catch (err) {
                    console.log("Error management_agreement - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('management_agreement_id').innerHTML = `<td>Management Agreement<div id="management_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="management_agreement_upload_id" /></td>`;
                    return 21;
                }
            }
            else if (files[i].key.includes("landlord_payment_agreement/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ landlord_payment_agreement: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    if (this.state.mode && this.state.mode == "User") {
                        document.getElementById('landlord_payment_agreement_id').innerHTML = `<td>Landlord Payment Agreement<div id="landlord_payment_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.landlord_payment_agreement}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File22"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
                    }
                    else {
                        document.getElementById('landlord_payment_agreement_id').innerHTML = `<td>Landlord Payment Agreement<div id="landlord_payment_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.landlord_payment_agreement}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File22"><i class="fas fa-trash-alt red"></i></a></td>`;
                        var aTag2 = document.getElementById('deleteS3File22');
                        if (aTag2 !== null && aTag2 !== undefined) {
                            if (this.state.individualCase_Original !== 'Open') {
                                aTag2.style.visibility = "hidden";
                            }
                            else {
                                if (aTag2.addEventListener) {
                                    aTag2.addEventListener("click", () => { this.deleteS3File(22) });
                                }
                                else if (aTag2.attachEvent) {
                                    aTag2.attachEvent("click", () => { this.deleteS3File(22) });
                                }
                            }
                        }
                    }
                    this.setState({ landlord_payment_agreement_uploaded: true });
                }
                catch (err) {
                    console.log("Error landlord_payment_agreement - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('landlord_payment_agreement_id').innerHTML = `<td>Landlord Payment Agreement<div id="landlord_payment_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="landlord_payment_agreement_upload_id" /></td>`;
                    return 22;
                }
            }

        }

        this.setState({ isLoading: false });

        return 0;
    }

    deleteS3File = async (fileToDelete) => {
        var result = window.confirm("Are you sure you want to delete selected document?");
        if (result === false) {
            return;
        }

        Storage.configure({
            bucket: process.env.REACT_APP_S3FrontendBucket,
            region: process.env.REACT_APP_Region,
            level: 'public',
        });

        this.setState({ isLoading: true });
        try {

            var key = `ERA-IRVINE/${this.state.email}/`;
            var files = await Storage.list(key);
            console.log(files);

            // Remove the earlier files for the section for which the new files to be uploaded

            switch (fileToDelete) {
                case 1:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes("photo_identification/")) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                console.log(res);
                            }
                        }
                        document.getElementById('photo_identification_id').innerHTML = `<td>Photo Identification<div id="photo_identification_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="photo_identification_upload_id" /></td>`;
                        this.setState({ photo_identification: "" });
                    }
                    break;
                case 2:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes("secondary_identification/")) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                console.log(res);
                            }
                        }
                        document.getElementById('secondary_identification_id').innerHTML = `<td>Secondary Identification, if applicable<div id="secondary_identification_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="secondary_identification_upload_id" /></td>`;
                        this.setState({ secondary_identification: "" });
                    }
                    break;
                case 3:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes("secondary_proof_of_residential_tenancy/")) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                console.log(res);
                            }
                        }
                        document.getElementById('secondary_proof_of_residential_tenancy_id').innerHTML = `<td>Secondary Proof of Residential Tenancy, if applicable<div id="secondary_proof_of_residential_tenancy_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="secondary_proof_of_residential_tenancy_upload_id" /></td>`;
                        this.setState({ secondary_proof_of_residential_tenancy: "" });
                    }
                    break;
                case 4:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes("proof_of_past_due_rent_amount/")) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                console.log(res);
                            }
                        }
                        document.getElementById('proof_of_past_due_rent_amount_id').innerHTML = `<td>Proof of Past Due Rent Amount<div id="proof_of_past_due_rent_amount_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="proof_of_past_due_rent_amount_upload_id" /></td>`;
                        this.setState({ proof_of_past_due_rent_amount: "" });
                    }
                    break;
                case 5:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes("proof_of_Annual_2020_household_income/")) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                console.log(res);
                            }
                        }
                        document.getElementById('proof_of_Annual_2020_household_income_id').innerHTML = `<td>Proof of Annual 2020 Household Income for All Household Members<div id="proof_of_Annual_2020_household_income_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="proof_of_Annual_2020_household_income_upload_id" /></td>`;
                        this.setState({ proof_of_Annual_2020_household_income: "" });
                    }
                    break;
                case 6:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes("rental_agreement/")) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                console.log(res);
                            }
                        }
                        document.getElementById('rental_agreement_id').innerHTML = `<td>Rental Lease Agreement<div id="rental_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="rental_agreement_upload_id" /></td>`;
                        this.setState({ rental_agreement: "" });
                    }
                    break;
                case 7:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes("supplemental_proofof_Annual_2020_household_income/")) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                console.log(res);
                            }
                        }
                        document.getElementById('supplemental_proofof_Annual_2020_household_income_id').innerHTML = `<td>Supplemental Proof of Annual 2020 Household Income for All Household Members<div id="supplemental_proofof_Annual_2020_household_income_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="supplemental_proofof_Annual_2020_household_income_upload_id" /></td>`;
                        this.setState({ supplemental_proofof_Annual_2020_household_income: "" });
                    }
                    break;
                case 8:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes("participation_agreement/")) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                console.log(res);
                            }
                        }
                        document.getElementById('participation_agreement_id').innerHTML = `<td>City of Irvine, Tenant Applicant, Participation Agreement<div id="participation_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="participation_agreement_upload_id" /></td>`;
                        this.setState({ participation_agreement: "" });
                    }
                    break;
                case 9:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes("landlord_w9/")) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                await this.textExtractW9Delete(files[i].key);
                                console.log(res);
                            }
                        }
                        document.getElementById('landlord_w9_id').innerHTML = `<td>Landlord W9<div id="landlord_w9_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="landlord_w9_upload_id" /></td>`;
                        this.setState({ landlord_w9: "" });
                    }
                    break;
                case 10:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes("landlord_driver_license/")) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                console.log(res);
                            }
                        }
                        document.getElementById('landlord_driver_license_id').innerHTML = `<td>Driver’s License or other government issued photo ID<div id="landlord_driver_license_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="landlord_driver_license_upload_id" /></td>`;
                        this.setState({ landlord_driver_license: "" });
                    }
                    break;


                case 11:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes("proof_of_unemployment/")) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                console.log(res);
                            }
                        }
                        document.getElementById('proof_of_unemployment_id').innerHTML = `<td>Proof of Unemployment and/or Financial Hardship Due to COVID-19<div id="proof_of_unemployment_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="proof_of_unemployment_upload_id" /></td>`;
                        this.setState({ proof_of_unemployment: "" });
                    }
                    break;
                case 12:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes("supplemental_proofof_unemployment/")) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                console.log(res);
                            }
                        }
                        document.getElementById('supplemental_proofof_unemployment_id').innerHTML = `<td>Supplemental Proof of Unemployment and/or Financial Hardship Due to COVID-19<div id="supplemental_proofof_unemployment_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="supplemental_proofof_unemployment_upload_id" /></td>`;
                        this.setState({ supplemental_proofof_unemployment: "" });
                    }
                    break;
                case 13:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes("authorization_to_release_information_to_SSA/")) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                console.log(res);
                            }
                        }
                        document.getElementById('authorization_to_release_information_to_SSA_id').innerHTML = `<td>Authorization to Release Information to Social Services Agency (for all other Housing Authorities)<div id="authorization_to_release_information_to_SSA_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="authorization_to_release_information_to_SSA_upload_id" /></td>`;
                        this.setState({ authorization_to_release_information_to_SSA: "" });
                    }
                    break;

                case 14:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes("third_party_payer_agreement/")) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                console.log(res);
                            }
                        }
                        document.getElementById('third_party_payer_agreement_id').innerHTML = `<td>Third Party Approval Document<div id="third_party_payer_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="third_party_payer_agreement_upload_id" /></td>`;
                        this.setState({ third_party_payer_agreement: "" });
                    }
                    break;
                case 15:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes("landlord_participationagreement/")) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                console.log(res);
                            }
                        }
                        document.getElementById('landlord_participationagreement_id').innerHTML = `<td>Landlord Participation Agreement<div id="landlord_participationagreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="landlord_participationagreement_upload_id" /></td>`;
                        this.setState({ landlord_participationagreement: "" });
                    }
                    break;
                case 16:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes("verified_checkpoint/")) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                console.log(res);
                            }
                        }
                        document.getElementById('verified_checkpoint_id').innerHTML = `<td>CheckPoint ID Verification Page<div id="verified_checkpoint_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="verified_checkpoint_upload_id" /></td>`;
                        this.setState({ verified_checkpoint: "" });
                    }
                    break;
                case 17:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes("consent_to_exchange_information/")) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                console.log(res);
                            }
                        }
                        document.getElementById('consent_to_exchange_information_id').innerHTML = `<td>Consent to Exchange Information with Homeless Management Information System and other parties such as Landlord<div id="consent_to_exchange_information_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="consent_to_exchange_information_upload_id" /></td>`;
                        this.setState({ consent_to_exchange_information: "" });
                    }
                    break;
                case 18:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes("authorization_to_release_information_to_HUD/")) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                console.log(res);
                            }
                        }
                        document.getElementById('authorization_to_release_information_to_HUD_id').innerHTML = `<td>Authorization to Release Information to Housing and Urban Development (for OC Housing Authority)<div id="authorization_to_release_information_to_HUD_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="authorization_to_release_information_to_HUD_upload_id" /></td>`;
                        this.setState({ authorization_to_release_information_to_HUD: "" });
                    }
                    break;
                case 19:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes("ami_household_income_worksheet/")) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                console.log(res);
                            }
                        }
                        document.getElementById('ami_household_income_worksheet_id').innerHTML = `<td>AMI-Household Income Worksheet<div id="ami_household_income_worksheet_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="ami_household_income_worksheet_upload_id" /></td>`;
                        this.setState({ ami_household_income_worksheet: "" });
                    }
                    break;
                case 20:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes("tenant_payment_agreement/")) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                console.log(res);
                            }
                        }
                        document.getElementById('tenant_payment_agreement_id').innerHTML = `<td>Tenant Payment Agreement<div id="tenant_payment_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="tenant_payment_agreement_upload_id" /></td>`;
                        this.setState({ tenant_payment_agreement: "" });
                    }
                    break;

                case 21:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes("management_agreement/")) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                console.log(res);
                            }
                        }
                        document.getElementById('management_agreement_id').innerHTML = `<td>Management Agreement<div id="management_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="management_agreement_upload_id" /></td>`;
                        this.setState({ management_agreement: "" });
                    }
                    break;
                case 22:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes("landlord_payment_agreement/")) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                console.log(res);
                            }
                        }
                        document.getElementById('landlord_payment_agreement_id').innerHTML = `<td>Landlord Payment Agreement<div id="landlord_payment_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="landlord_payment_agreement_upload_id" /></td>`;
                        this.setState({ landlord_payment_agreement: "" });
                    }
                    break;

                default:
                    break;
            }
        }
        catch (err) {
            console.log(err);
            this.setState({ msg: "Failed to delete file" });
            this.setState({ isLoading: false });
            return;
        }

        this.setState({ isLoading: false });

        var safeload = await this.loadUploadedDocuments();
    }

    getFileIconType(filename) {
        let extension = /(?:\.([^.]+))?$/.exec(filename)[1];

        switch (extension) {
            case 'pdf':
                return 'fas fa-file-pdf';
            case 'jpeg':
            case 'jpg':
            case 'png':
            case 'gif':
            case 'bmp':
                return 'fas fa-file-image';
            case 'txt':
                return 'fas fa-file-alt';
            case 'doc':
            case 'docx':
                return 'fas fa-file-word';
            case 'xls':
            case 'xlsx':
                return 'fas fa-file-excel';
            case 'ppt':
            case 'pptx':
                return 'fas fa-file-powerpoint';
            default: // any other file
                return 'fas fa-file';
        }
    }

    handleUpdate = async () => {
        this.setState({ isLoading: true });

        // Check input field validations if any
        if (this.validateControls() === false) {
            // Validation Failed - early return.
            this.setState({ isLoading: false });
            return;
        }

        try {
            var user = await Auth.currentAuthenticatedUser();
           // return user;
          }
          catch(e){
            this.setState({ isLoading: false });
            if(e === "not authenticated") {
              await this.signOut();
              return;
            }
        }

        var d = new Date(new Date().toLocaleString('en-US', { timeZone: 'US/Pacific' }));
        var todaysDate = [
            d.getFullYear(),
            ('0' + (d.getMonth() + 1)).slice(-2),
            ('0' + d.getDate()).slice(-2)
        ].join('-');

        var todaysTime = [
            ('0' + (d.getHours())).slice(-2),
            ('0' + d.getMinutes()).slice(-2),
            ('0' + d.getSeconds()).slice(-2),
        ].join(':');

        this.setState({
            dateClaimModified: todaysDate,
            timeClaimModified: todaysTime
        });

        if (this.state.applicationAccepted_Original !== this.state.applicationAccepted &&
            this.state.applicationAccepted === "Yes") {
            var confirm = window.confirm(`You have updated Application Accepted status to Yes. This will trigger an email to tenant on ${this.state.email}. Please click Ok to confirm.`);
            if (confirm === false) {
                this.applicationAcceptedRef.current.focus();

                this.setState({ isLoading: false });
                return;
            }
            this.setState({
                dateApplicationAcceptedChanged: todaysDate,
                timeApplicationAcceptedChanged: todaysTime
            });
        }

        if (this.state.rentPaymentStatus_Original !== this.state.rentPaymentStatus) {
            if (this.state.rentPaymentStatus === 'Issued') {

                let tempEmail = this.state.landlordEmail;
                if (this.state.landlordEmail === null || this.state.landlordEmail === "" || this.state.landlordEmail === undefined) {
                    tempEmail = 'JennO@UnitedWayOC.org';
                }

                var result = window.confirm(`You have updated rent status to Issued. This will trigger an email to landlord on ${tempEmail}. Please click Ok to confirm.`);
                if (result !== false) {

                    this.setState({
                        triggerEmail: "1",
                        dateEmailTriggered: todaysDate,
                        timeEmailTriggered: todaysTime,
                        dateRentPaymentStatusUpdated: todaysDate,
                        timeRentPaymentStatusUpdated: todaysTime
                    });
                }
                else {
                    this.rentPaymentStatusRef.current.focus();

                    this.setState({ isLoading: false, triggerEmail: "0" });
                    return;
                }
            }
            else {
                this.setState({
                    triggerEmail: "0",
                    dateRentPaymentStatusUpdated: todaysDate,
                    timeRentPaymentStatusUpdated: todaysTime
                });
            }
        }

        if (this.state.electricPaymentStatus_Original !== this.state.electricPaymentStatus) {
            this.setState({
                dateElectricPaymentStatusUpdated: todaysDate,
                timeElectricPaymentStatusUpdated: todaysTime
            });
        }
        if (this.state.gasPaymentStatus_Original !== this.state.gasPaymentStatus) {
            this.setState({
                dateGasPaymentStatusUpdated: todaysDate,
                timeGasPaymentStatusUpdated: todaysTime
            });
        }
        if (this.state.otherPaymentStatus_Original !== this.state.otherPaymentStatus) {
            this.setState({
                dateOtherPaymentStatusUpdated: todaysDate,
                timeOtherPaymentStatusUpdated: todaysTime
            });
        }
        if (this.state.individualPaymentStatus_Original !== this.state.individualPaymentStatus) {
            this.setState({
                dateIndividualPaymentStatusUpdated: todaysDate,
                timeIndividualPaymentStatusUpdated: todaysTime
            });
        }

        if (this.state.waterPaymentStatus_Original !== this.state.waterPaymentStatus) {
            this.setState({
                dateWaterPaymentStatusUpdated: todaysDate,
                timeWaterPaymentStatusUpdated: todaysTime
            });
        }
        if (this.state.trashPaymentStatus_Original !== this.state.trashPaymentStatus) {
            this.setState({
                dateTrashPaymentStatusUpdated: todaysDate,
                timeTrashPaymentStatusUpdated: todaysTime
            });
        }
        if (this.state.energyPaymentStatus_Original !== this.state.energyPaymentStatus) {
            this.setState({
                dateEnergyPaymentStatusUpdated: todaysDate,
                timeEnergyPaymentStatusUpdated: todaysTime
            });
        }
        if (this.state.landlordEmail_Original !== this.state.landlordEmail) {
            this.setState({
                requestedLandlordInfo: false,
                countLandlordInfoRequested: 0,
                // landlordInfoRequestedStatus: 'Pending',
                dateLandlordInfoRequested: '0000-00-00',
                timeLandlordInfoRequested: '00-00-00',
                dateLandlordInfoReminded: '0000-00-00',
                timeLandlordInfoReminded: '00-00-00',
            });
        }

        var redirectAfterSave = true;
        this.setState({ isLoading: true });
        try {
            // console.log("old landlord email " + this.state.landlordEmail_Original);
            // console.log("new landlord email " + this.state.landlordEmail);
            var res = await this.updateIndividualDetails();
            console.log(res);
            console.log("Individual Updated Successful.");

            if (this.state.landlordEmail_Original !== this.state.landlordEmail) {
                console.log("email changed");
                redirectAfterSave = false; // don't redirect after save in case landlord email is updated
                try {
                    var user = await this.getCurrentUser();
                    let apiName = process.env.REACT_APP_APIGatewayName;
                    let path = '/adminremoveirvinelandlordrincognito';
                    let myInit = { // OPTIONAL
                        body: {
                            "info": {
                                "oldemail": this.state.landlordEmail_Original,
                                "individualid": this.state.individualID,
                            }
                        }, // replace this with attributes you need
                        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
                    }

                    await API.post(apiName, path, myInit);
                    // if (res.message === 0)
                }
                catch (err) {
                    console.log(err)
                }
            }

            if (this.state.applicationAccepted_Original !== this.state.applicationAccepted &&
                this.state.applicationAccepted === "Yes") {
                // Send credentials to applicant once the application is marked as Accepted.
                await this.sendCredentialsToApplicant();
            }

            if (this.state.triggerEmail === "1") {
                // Trigger rent payment issued email
                await this.triggerRentPaymentIssuedEmail();
            }

            res = await this.UploadIndividualDocuments();
            console.log(res);

            alert('The changes have been saved successfully.');

            //await this.loadData(); // load the updated data again.

            if (redirectAfterSave === true) {
                await this.handleBack();
            }
        }
        catch (err) {
            console.log("ERROR: Failed to Update the individual.");
            console.log(err);
            alert('ERROR: Failed to Update the individual.');
        }

        this.setState({ isLoading: false });
    }

    handleBack = async () => {
        window.location.assign("/assistoc/irvineclaim");
    }

    signOut = async () => {
        try {
            await Auth.signOut()
            // signout success
            this.setState({ authState: 'signIn' });
            window.location.assign("/");
        }
        catch (e) {
            console.log(e)
        }
    }

    getCurrentUser = async () => {
        try {
          var user = await Auth.currentAuthenticatedUser();
          return user;
        }
        catch(e){
         // this.setState({ isLoading: false });
          if(e === "not authenticated") {
            await this.signOut();
          }
        }
    }

    renderPage = (page) => {
        // check the current user when the App component is loaded
        Auth.currentAuthenticatedUser().then(user => {
            this.setState({ authState: 'signedIn' });
        }).catch(e => {
            console.log(e);
            if(e === "not authenticated") {
                this.signOut();
            }
            this.setState({ authState: 'signIn' });
        });

        switch (page) {
            case 1:
                ReactDOM.render(<App showAddUser={'block'} authState={this.state.authState} />, document.getElementById("root"));
                break;
            case 3:
                ReactDOM.render(<App showSubmitClaim={'block'} authState={this.state.authState} />, document.getElementById("root"));
                break;
            case 5:
                ReactDOM.render(<App showCeppClaims={'block'} authState={this.state.authState} />, document.getElementById("root"));
                break;
            default:
                break;
        }
    }

    date_diff_in_days = (date1, date2) => {
        var dt1 = new Date(date1);
        var dt2 = new Date(date2);
        // Math.floor
        return Math.abs((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
    }

    onPaymentStatusChange = () => {
        if ((this.state.rentPaymentStatus === 'Approved for Payment' || this.state.rentPaymentStatus === 'Not Approved for Payment' || this.state.rentPaymentStatus === 'Issued') &&
            (this.state.electricPaymentStatus === 'Approved for Payment' || this.state.electricPaymentStatus === 'N/A' || this.state.electricPaymentStatus === 'Issued') &&
            (this.state.gasPaymentStatus === 'Approved for Payment' || this.state.gasPaymentStatus === 'N/A' || this.state.gasPaymentStatus === 'Issued') &&
            (this.state.waterPaymentStatus === 'Approved for Payment' || this.state.waterPaymentStatus === 'N/A' || this.state.waterPaymentStatus === 'Issued') &&
            (this.state.trashPaymentStatus === 'Approved for Payment' || this.state.trashPaymentStatus === 'N/A' || this.state.trashPaymentStatus === 'Issued') &&
            (this.state.energyPaymentStatus === 'Approved for Payment' || this.state.energyPaymentStatus === 'N/A' || this.state.energyPaymentStatus === 'Issued') &&
            (this.state.individualPaymentStatus === 'Approved for Payment' || this.state.individualPaymentStatus === 'N/A' || this.state.individualPaymentStatus === 'Issued') 
            // &&
            // (this.state.otherPaymentStatus === 'Approved for Payment' || this.state.otherPaymentStatus === 'N/A' || this.state.otherPaymentStatus === 'Issued')
            ) {
            document.getElementById("individualCaseId").disabled = false;
        }
        else {
            document.getElementById("individualCaseId").disabled = true;
        }
    }

    updateIndividualCase = (evt) => {
        var name = evt.target.value;
        this.setState({ individualCase: name });
    }

    updateHouseholdApprovedForUnitedWay = (evt) => {
        var name = evt.target.value;
        this.setState({ householdApprovedForUnitedWay: name });
    }

    updateFirstName = (evt) => {
        var name = evt.target.value;
        this.setState({ firstName: name, firstName_Error: "" });
    }
    updateLastName = (evt) => {
        var name = evt.target.value;
        this.setState({ lastName: name, lastName_Error: "" });
    }
    updateFirstNameSpouse = (evt) => {
        var name = evt.target.value;
        this.setState({ partnerFirstName: name, partnerFirstName_Error: "" });
    }
    updateLastNameSpouse = (evt) => {
        var name = evt.target.value;
        this.setState({ partnerLastName: name, partnerLastName_Error: "" });
    }

    updateAddress = (evt) => {
        var name = evt.target.value;
        this.setState({ addressLine1: name, addressLine1_Error: "" });
    }

    updateAddress2 = (evt) => {
        var name = evt.target.value;
        this.setState({ addressLine2: name, addressLine2_Error: "" });
    }
    updateZip = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ zipcode: name, zipcode_Error: "" });
    }
    updateCity = (evt) => {
        var name = evt.target.value;
        this.setState({ city: name, city_Error: "" });
    }
    updateState = (evt) => {
        var name = evt.target.value;
        this.setState({ state: name, state_Error: "" });
    }

    updatePhone = (evt) => {
        var name = evt.target.value;
        //name = name.replace(/\D/g, '');
        this.setState({ phone: name, phone_Error: "" });
    }
    updatePhoneType = (evt) => {
        var name = evt.target.value;
        this.setState({ phoneType: name, phoneType_Error: "" });
    }

    updateHouseHold = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ householdNumber: name, householdNumber_Error: "" });
    }
    // updateHouseHold1 = (evt) => {
    //     var name = evt.target.value;
    //     name = name.replace(/\D/g, '');
    //     this.setState({ age0to5: name, age0to5_Error: "" });
    // }
    // updateHouseHold2 = (evt) => {
    //     var name = evt.target.value;
    //     name = name.replace(/\D/g, '');
    //     this.setState({ age6to17: name, age6to17_Error: "" });
    // }
    // updateHouseHold3 = (evt) => {
    //     var name = evt.target.value;
    //     name = name.replace(/\D/g, '');
    //     this.setState({ age18to59: name, age18to59_Error: "" });
    // }
    // updateHouseHold4 = (evt) => {
    //     var name = evt.target.value;
    //     name = name.replace(/\D/g, '');
    //     this.setState({ age60andUp: name, age60andUp_Error: "" });
    // }
    updateCountAdult = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ countAdult: name, countAdult_Error: "" });
    }
    updateCountChildren = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ countChildren: name, countChildren_Error: "" });
    }
    updateHeadOfHousehold = (evt) => {
        var name = evt.target.value;
        this.setState({ headOfHousehold: name, headOfHousehold_Error: "" });
    }

    updateDOB = (evt) => {
        var name = evt.target.value;
        this.setState({ dateOfBirth: name });
    }
    updateInterpretationServicesRequiredForLanguage = (evt) => {
        var name = evt.target.value;
        this.setState({ interpretationServicesRequiredForLanguage: name, interpretationServicesRequiredForLanguage_Error: "" });
    }

    updateCovidOther = (evt) => {
        var name = evt.target.value;
        this.setState({ covid_other: name, covid_other_Error: "" });
    }

    updateLegallyAbleToWork = (evt) => {
        var name = evt.target.value;
        this.setState({ legallyAbleToWork: name });
    }

    updateWorkIndustry = (evt) => {
        var name = evt.target.value;
        this.setState({ workIndustry: name });
    }

    updateIndustryOther = (evt) => {
        var name = evt.target.value;
        this.setState({ industry_other: name, industry_other_Error: "" });
    }

    updateVeteran = (evt) => {
        var name = evt.target.value;
        this.setState({ veteran: name });
    }

    updateStimulus = (evt) => {
        var name = evt.target.value;
        this.setState({ eligibleForStimulusFunds: name });
    }

    updateEmploymentStatus = (evt) => {
        var name = evt.target.value;
        this.setState({ employmentStatus: name });
    }

    updateCurrentMonthlyIncome = (evt) => {
        var name = evt.target.value;
        this.setState({ currentMonthlyIncome: name, currentMonthlyIncome_Error: "" });
    }

    updateFinancialEffect = (evt) => {
        var name = evt.target.value;
        this.setState({ effectOfCovid19Financially: name });
    }


    updateProgram = (evt) => {
        var name = evt.target.value;
        this.setState({ programTitle: name });
    }
    updateSPAAssignmentNo = (evt) => {
        var name = evt.target.value;
        //name = name.replace(/\D/g, '');
        this.setState({ spaAssignmentNo: name, spaAssignmentNo_Error: "" });
    }
    updateApplicationAccepted = (evt) => {
        var name = evt.target.value;
        this.setState({ applicationAccepted: name });
    }
    updateHouseholdID = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ householdID: name, householdID_Error: "" });
    }
    updateTransactionID = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ transactionID: name, transactionID_Error: "" });
    }
    updateIndividualID = (evt) => {
        var name = evt.target.value;
        this.setState({ individualID: name });
    }
    updateUniqueID = (evt) => {
        var name = evt.target.value;
        this.setState({ uniqueID: name, uniqueID_Error: "" });
    }
    updateTotalHouseholdAnnualIncome = (evt) => {
        var name = evt.target.value;
        this.setState({ totalHouseholdAnnualIncome: name, totalHouseholdAnnualIncome_Error: "" });
    }
    updateOpenToFollowUp = (evt) => {
        var name = evt.target.value;
        this.setState({ openToFollowup: name });
    }
    updateGender = (evt) => {
        var name = evt.target.value;
        this.setState({ gender: name, gender_Error: "" });
    }
    updateRace = (evt) => {
        var name = evt.target.value;
        this.setState({ race: name, race_Error: "" });
    }
    updateEthnicity = (evt) => {
        var name = evt.target.value;
        this.setState({ ethnicity: name, ethnicity_Error: "" });
    }
    updateSPAServiceProviderEmail = (evt) => {
        var name = evt.target.value;
        this.setState({ spaServiceProviderEmail: name, spaServiceProviderEmail_Error: "" });
    }
    updateNoOfTimesParticipated = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ noOfTimesParticipated: name, noOfTimesParticipated_Error: "" });
    }



    updateSuffix = (evt) => {
        var name = evt.target.value;
        this.setState({ suffix: name, suffix_Error: "" });
    }
    updateMiddleName = (evt) => {
        var name = evt.target.value;
        this.setState({ middleName: name, middleName_Error: "" });
    }
    updateAgent = (evt) => {
        var name = evt.target.value;
        this.setState({ agent: name, agent_Error: "" });
    }
    updateIsSomeoneOnRentalOrLeaseAgreement = (evt) => {
        var name = evt.target.value;
        this.setState({ isSomeoneOnRentalOrLeaseAgreement: name, isSomeoneOnRentalOrLeaseAgreement_Error: "" });
    }
    updateQualifiedForUnemploymentBenefits = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ qualifiedForUnemploymentBenefits: true });
        }
        else {
            this.setState({ qualifiedForUnemploymentBenefits: false });
        }
    }
    updateExperiencedReductionInHouseholdIncome = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ experiencedReductionInHouseholdIncome: true });
        }
        else {
            this.setState({ experiencedReductionInHouseholdIncome: false });
        }
    }
    updateIncurredSignificantCosts = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ incurredSignificantCosts: true });
        }
        else {
            this.setState({ incurredSignificantCosts: false });
        }
    }
    updateReductionOfChildSupport = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ reductionOfChildSupport: true });
        }
        else {
            this.setState({ reductionOfChildSupport: false });
        }
    }
    updateOtherFinancialHardship = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ otherFinancialHardship: true });
        }
        else {
            this.setState({ otherFinancialHardship: false });
        }
    }

    updateReceivedAnyHousingSubsidy = (evt) => {
        var name = evt.target.value;
        if (evt.target.value === "1") {
            document.getElementById("subsidyExplanationId").disabled = false;
        }
        else {
            document.getElementById("subsidyExplanationId").disabled = true;
            this.setState({ subsidyExplanationId: "" });
        }
        this.setState({ receivedHousingSubsidy: name, receivedHousingSubsidy_Error: "" });
    }
    updateSubsidyExplanation = (evt) => {
        var name = evt.target.value;
        this.setState({ subsidyExplanation: name, subsidyExplanation_Error: "" });
    }
    updateFiledCovid19FinancialStressDeclaration = (evt) => {
        var name = evt.target.value;
        this.setState({ filedCovid19FinancialStressDeclaration: name, filedCovid19FinancialStressDeclaration_Error: "" });
    }
    updateReceivedEvictionNotice = (evt) => {
        var name = evt.target.value;
        if (evt.target.value === "1") {
            document.getElementById("dateEvictionNoticeReceivedId").disabled = false;
        }
        else {
            document.getElementById("dateEvictionNoticeReceivedId").disabled = true;
            this.setState({ dateEvictionNoticeReceived: "0000-00-00" });
        }
        this.setState({ receivedEvictionNotice: name, receivedEvictionNotice_Error: "" });
    }
    updateDateEvictionNoticeReceived = (evt) => {
        var name = evt.target.value;
        this.setState({ dateEvictionNoticeReceived: name, dateEvictionNoticeReceived_Error: "" });
    }

    updateDoYouLiveInIrvine = (evt) => {
        var name = evt.target.value;
        this.setState({ doYouLiveInIrvine: name, doYouLiveInIrvine_Error: "" });
    }
    updateDoYouRent = (evt) => {
        var name = evt.target.value;
        this.setState({ doYouRent: name, doYouRent_Error: "" });
    }
    updateAreYouBehindOnRent = (evt) => {
        var name = evt.target.value;
        this.setState({ areYouBehindOnRent: name, areYouBehindOnRent_Error: "" });
    }
    updateDoYouHoldSection8HousingVoucher = (evt) => {
        var name = evt.target.value;
        this.setState({ doYouHoldSection8HousingVoucher: name, doYouHoldSection8HousingVoucher_Error: "" });
    }
    updateExperiencedFinancialHardshipInCovid = (evt) => {
        var name = evt.target.value;
        this.setState({ experiencedFinancialHardshipInCovid: name, experiencedFinancialHardshipInCovid_Error: "" });
    }
    updateIsAnnualIncomeLessThanMaxLimit = (evt) => {
        var name = evt.target.value;
        this.setState({ isAnnualIncomeLessThanMaxLimit: name, isAnnualIncomeLessThanMaxLimit_Error: "" });
    }

    updateSSN = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ SSN: name, SSN_Error: "" });
    }
    updateSocialSecurityFirstName = (evt) => {
        var name = evt.target.value;
        this.setState({ socialSecurityFirstName: name, socialSecurityFirstName_Error: "" });
    }
    updateSocialSecurityLastName = (evt) => {
        var name = evt.target.value;
        this.setState({ socialSecurityLastName: name, socialSecurityLastName_Error: "" });
    }
    updateBirthdayCode = (evt) => {
        var name = evt.target.value;
        this.setState({ birthdayCode: name, birthdayCode_Error: "" });
    }
    updateAge = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ age: name, age_Error: "" });
    }
    updateOtherPhone = (evt) => {
        var name = evt.target.value;
        //name = name.replace(/\D/g, '');
        this.setState({ otherPhone: name, otherPhone_Error: "" });
    }
    updateSecondaryFirstName = (evt) => {
        var name = evt.target.value;
        this.setState({ secondaryFirstName: name, secondaryFirstName_Error: "" });
    }
    updateSecondaryLastName = (evt) => {
        var name = evt.target.value;
        this.setState({ secondaryLastName: name, secondaryLastName_Error: "" });
    }
    updateSecondaryEmail = (evt) => {
        var name = evt.target.value;
        this.setState({ secondaryEmail: name, secondaryEmail_Error: "" });
    }
    updateSecondaryPhone = (evt) => {
        var name = evt.target.value;
        //name = name.replace(/\D/g, '');
        this.setState({ secondaryPhone: name, secondaryPhone_Error: "" });
    }

    updateBelowThirtyPercAMI = (evt) => {
        var name = evt.target.value;
        this.setState({ belowThirtyPercAMI: name });
    }
    updateThirtyToFiftyPercAMI = (evt) => {
        var name = evt.target.value;
        this.setState({ thirtyToFiftyPercAMI: name });
    }
    updateFiftyToEightyPercAMI = (evt) => {
        var name = evt.target.value;
        this.setState({ fiftyToEightyPercAMI: name });
    }
    updateBoardDistrict = (evt) => {
        var name = evt.target.value;
        this.setState({ boardDistrict: name });
    }
    updateLengthOfTimeForAward = (evt) => {
        var name = evt.target.value;
        this.setState({ lengthOfTimeForAward: name });
    }


    updateLandlordPhone = (evt) => {
        var name = evt.target.value;
        //name = name.replace(/\D/g, '');
        this.setState({ landlordPhone: name, landlordPhone_Error: "" });
    }
    updatelandlordMobile = (evt) => {
        var name = evt.target.value;
        //name = name.replace(/\D/g, '');
        this.setState({ landlordMobile: name, landlordMobile_Error: "" });
    }

    updateLandlordEmail = (evt) => {
        var name = evt.target.value;
        this.setState({ landlordEmail: name, landlordEmail_Error: "" });
    }
    updateAmountBehindOnRent = (evt) => {
        var name = evt.target.value;
        this.setState({ amountBehindOnRent: name, amountBehindOnRent_Error: "" });

        if (name !== 0 && name !== "0.00" &&
            name !== '' && name !== null && name !== undefined &&
            this.state.rentAmountCovered !== 0 && this.state.rentAmountCovered !== "0.00" &&
            this.state.rentAmountCovered !== '' && this.state.rentAmountCovered !== null && this.state.rentAmountCovered !== undefined) {
            this.setState({ percentageOfMonthlyRent: ((this.state.rentAmountCovered * 100) / name).toFixed(2) });
        }
        else {
            this.setState({ percentageOfMonthlyRent: '' });
        }
    }
    onLandlordACHReceivedChange = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ landlordACHReceived: 1 });
        }
        else {
            this.setState({ landlordACHReceived: 0 });
        }
    }
    onVerifiedasEligibleChange = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ verifiedasEligible: 1 });
        }
        else {
            this.setState({ verifiedasEligible: 0 });
        }
    }
    onPaymentToIrvineCompanyChange = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ paymentToIrvineCompany: 1 });
        }
        else {
            this.setState({ paymentToIrvineCompany: 0 });
        }
    }
    onLandlordTitlePulledChange = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ landlordTitlePulled: 1 });
        }
        else {
            this.setState({ landlordTitlePulled: 0 });
        }
    }
    updateLandlordFullName = (evt) => {
        var name = evt.target.value;
        this.setState({ landlordFullName: name });
    }
    updateRentAmountCovered = (evt) => {
        var name = evt.target.value;
        // this.setState({ rentAmountCovered: name, total1099Amount: +this.state.otherPaymentAmount + +name, rentAmountCovered_Error: "" });

        this.setState({ rentAmountCovered: name, rentAmountCovered_Error: "" }, function () {
            this.calculateTotal1099();
        });
        if (this.state.amountBehindOnRent !== 0 && this.state.amountBehindOnRent !== "0.00" &&
            this.state.amountBehindOnRent !== '' && this.state.amountBehindOnRent !== null && this.state.amountBehindOnRent !== undefined &&
            name !== 0 && name !== "0.00" &&
            name !== '' && name !== null && name !== undefined) {
            this.setState({ percentageOfMonthlyRent: ((name * 100) / this.state.amountBehindOnRent).toFixed(2) });
        }
        else {
            this.setState({ percentageOfMonthlyRent: '' });
        }
    }
    updateLandlordBankName = (evt) => {
        var name = evt.target.value;
        this.setState({ landlordBankName: name });
    }
    updateLandlordAccountNumber = (evt) => {
        var name = evt.target.value;
        //name = name.replace(/\D/g, '');
        this.setState({ landlordAccountNumber: name, landlordAccountNumber_Error: "" });
    }
    updateLandlordRoutingNumber = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ landlordRoutingNumber: name, landlordRoutingNumber_Error: "" });
    }
    updateMemoPropertyAddress = (evt) => {
        var name = evt.target.value;
        this.setState({ memoPropertyAddress: name });
    }
    updateRentPaymentStatus = (evt) => {
        var name = evt.target.value;
        // this.setState({ rentPaymentStatus: name, reasonPaymentNotProcessedIn21Days_Error: "" });
        this.setState({ rentPaymentStatus: name, reasonPaymentNotProcessedIn21Days_Error: "" }, function () {
            this.calculateTotal1099();
        });
        if ((name === 'Approved for Payment' || name === 'Not Approved for Payment' || name === 'Issued') &&
            (this.state.electricPaymentStatus === 'Approved for Payment' || this.state.electricPaymentStatus === 'N/A' || this.state.electricPaymentStatus === 'Issued') &&
            (this.state.gasPaymentStatus === 'Approved for Payment' || this.state.gasPaymentStatus === 'N/A' || this.state.gasPaymentStatus === 'Issued') &&
            (this.state.waterPaymentStatus === 'Approved for Payment' || this.state.waterPaymentStatus === 'N/A' || this.state.waterPaymentStatus === 'Issued') &&
            (this.state.trashPaymentStatus === 'Approved for Payment' || this.state.trashPaymentStatus === 'N/A' || this.state.trashPaymentStatus === 'Issued') &&
            (this.state.energyPaymentStatus === 'Approved for Payment' || this.state.energyPaymentStatus === 'N/A' || this.state.energyPaymentStatus === 'Issued') &&
            (this.state.individualPaymentStatus === 'Approved for Payment' || this.state.individualPaymentStatus === 'N/A' || this.state.individualPaymentStatus === 'Issued') 
            // &&
            // (this.state.otherPaymentStatus === 'Approved for Payment' || this.state.otherPaymentStatus === 'N/A' || this.state.otherPaymentStatus === 'Issued')
            ) {
            document.getElementById("individualCaseId").disabled = false;
        }
        else {
            document.getElementById("individualCaseId").disabled = true;
        }
    }
    updateLandlordSSN = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ landlordSSN: name, landlordSSN_Error: "" });
    }
    updateLandlordTaxID = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ landlordTaxID: name, landlordTaxID_Error: "" });
    }
    updateLandlordDUNSNumber = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ landlordDUNSNumber: name, landlordDUNSNumber_Error: "" });
    }
    updateRentTypeOfAssistance = (evt) => {
        var name = evt.target.value;
        this.setState({ rentTypeOfAssistance: name });
    }
    updateMonthlyRentPayment = (evt) => {
        var name = evt.target.value;
        this.setState({ monthlyRentPayment: name, monthlyRentPayment_Error: "" });
    }
    updatePercentageOfMonthlyRent = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ percentageOfMonthlyRent: name, percentageOfMonthlyRent_Error: "" });
    }
    updateOutstandingRentalArrears = (evt) => {
        var name = evt.target.value;
        this.setState({ outstandingRentalArrearsAmount: name, outstandingRentalArrearsAmount_Error: "" });
    }
    updateMonthsBehindOnRent = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ monthsBehindOnRent: name, monthsBehindOnRent_Error: "" });
    }
    updateTimestampOfRentIssued = (evt) => {
        var name = evt.target.value;
        this.setState({ timestampOfRentIssued: name });
    }
    updateLandlordFirstAttempt = (evt) => {
        var name = evt.target.value;
        this.setState({ landlordFirstAttempt: name });
    }
    updateLandlordResponse = (evt) => {
        var name = evt.target.value;
        this.setState({ landlordResponse: name });
    }
    updateReasonPaymentNotProcessedIn21Days = (evt) => {
        var name = evt.target.value;
        this.setState({ reasonPaymentNotProcessedIn21Days: name, reasonPaymentNotProcessedIn21Days_Error: "" });
    }
    updateStartDateCoveredByPayment = (evt) => {
        var name = evt.target.value;
        this.setState({ startDateCoveredByPayment: name });
    }
    updateEndDateCoveredByPayment = (evt) => {
        var name = evt.target.value;
        this.setState({ endDateCoveredByPayment: name });
    }

    updateElectricProvider = (evt) => {
        var name = evt.target.value;
        this.setState({ electricProvider: name });
    }
    updateElectricUtilityAccountNumber = (evt) => {
        var name = evt.target.value;
        //name = name.replace(/\D/g, '');
        this.setState({ electricUtilityAccountNo: name, electricUtilityAccountNo_Error: "" });
    }
    updateDollarAmountDueForElectricity = (evt) => {
        var name = evt.target.value;
        this.setState({ amountDueforElectricity: name, amountDueforElectricity_Error: "" });

        if (name !== 0 && name !== "0.00" &&
            name !== '' && name !== null && name !== undefined &&
            this.state.electricAmount !== 0 && this.state.electricAmount !== "0.00" &&
            this.state.electricAmount !== '' && this.state.electricAmount !== null && this.state.electricAmount !== undefined) {
            this.setState({ percentageOfElectricBill: ((this.state.electricAmount * 100) / name).toFixed(2) });
        }
        else {
            this.setState({ percentageOfElectricBill: '' });
        }
    }
    updateElectricBillingAccountNumber = (evt) => {
        var name = evt.target.value;
        //name = name.replace(/\D/g, '');
        this.setState({ custElectricBillingAccountNo: name, custElectricBillingAccountNo_Error: "" });
    }
    updateElectricAmount = (evt) => {
        var name = evt.target.value;
        this.setState({ electricAmount: name, electricAmount_Error: "" });

        if (this.state.amountDueforElectricity !== 0 && this.state.amountDueforElectricity !== "0.00" &&
            this.state.amountDueforElectricity !== '' && this.state.amountDueforElectricity !== null && this.state.amountDueforElectricity !== undefined &&
            name !== 0 && name !== "0.00" &&
            name !== '' && name !== null && name !== undefined) {
            this.setState({ percentageOfElectricBill: ((name * 100) / this.state.amountDueforElectricity).toFixed(2) });
        }
        else {
            this.setState({ percentageOfElectricBill: '' });
        }
    }
    updateElectricBankName = (evt) => {
        var name = evt.target.value;
        this.setState({ electricBankName: name });
    }
    updateElectricityProvidersBankingAccountNumber = (evt) => {
        var name = evt.target.value;
        //name = name.replace(/\D/g, '');
        this.setState({ electricUtilityProviderAccountNo: name, electricUtilityProviderAccountNo_Error: "" });
    }
    updateElectricRoutingNumber = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ electricRoutingNumber: name, electricRoutingNumber_Error: "" });
    }
    updateElectricMemoAccountNumber = (evt) => {
        var name = evt.target.value;
        //name = name.replace(/\D/g, '');
        this.setState({ electricMemoAccountNo: name, electricMemoAccountNo_Error: "" });
    }
    updateElectricPaymentStatus = (evt) => {
        var name = evt.target.value;
        this.setState({ electricPaymentStatus: name });

        if ((this.state.rentPaymentStatus === 'Approved for Payment' || this.state.rentPaymentStatus === 'Not Approved for Payment' || this.state.rentPaymentStatus === 'Issued') &&
            (name === 'Approved for Payment' || name === 'N/A' || name === 'Issued') &&
            (this.state.gasPaymentStatus === 'Approved for Payment' || this.state.gasPaymentStatus === 'N/A' || this.state.gasPaymentStatus === 'Issued') &&
            (this.state.waterPaymentStatus === 'Approved for Payment' || this.state.waterPaymentStatus === 'N/A' || this.state.waterPaymentStatus === 'Issued') &&
            (this.state.trashPaymentStatus === 'Approved for Payment' || this.state.trashPaymentStatus === 'N/A' || this.state.trashPaymentStatus === 'Issued') &&
            (this.state.energyPaymentStatus === 'Approved for Payment' || this.state.energyPaymentStatus === 'N/A' || this.state.energyPaymentStatus === 'Issued') &&
            (this.state.individualPaymentStatus === 'Approved for Payment' || this.state.individualPaymentStatus === 'N/A' || this.state.individualPaymentStatus === 'Issued') 
            // &&
            // (this.state.otherPaymentStatus === 'Approved for Payment' || this.state.otherPaymentStatus === 'N/A' || this.state.otherPaymentStatus === 'Issued')
            ) {
            document.getElementById("individualCaseId").disabled = false;
        }
        else {
            document.getElementById("individualCaseId").disabled = true;
        }
    }
    updateElectricUtilityTaxID = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ electricUtilityTaxID: name, electricUtilityTaxID_Error: "" });
    }
    updateElectricUtilityDUNSNumber = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ electricUtilityDUNSNumber: name, electricUtilityDUNSNumber_Error: "" });
    }
    updateMonthlyElectricBillAmount = (evt) => {
        var name = evt.target.value;
        this.setState({ monthlyElectricBillAmount: name, monthlyElectricBillAmount_Error: "" });
    }
    updatePercentageOfElectricBill = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ percentageOfElectricBill: name, percentageOfElectricBill_Error: "" });
    }
    updateNoOfMonthsElectricUtilityPayments = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ noOfMonthsElectricUtilityPayments: name, noOfMonthsElectricUtilityPayments_Error: "" });
    }
    updateElectricTypeOfAssistance = (evt) => {
        var name = evt.target.value;
        this.setState({ electricTypeOfAssistance: name });
    }


    updateGasProvider = (evt) => {
        var name = evt.target.value;
        this.setState({ gasProvider: name });
    }
    updateGasUtilityAccountNumber = (evt) => {
        var name = evt.target.value;
        //name = name.replace(/\D/g, '');
        this.setState({ gasUtilityAccountNo: name, gasUtilityAccountNo_Error: "" });
    }
    updateDollarAmountDueForGas = (evt) => {
        var name = evt.target.value;
        this.setState({ amountDueforGas: name, amountDueforGas_Error: "" });

        if (name !== 0 && name !== "0.00" &&
            name !== '' && name !== null && name !== undefined &&
            this.state.gasAmount !== 0 && this.state.gasAmount !== "0.00" &&
            this.state.gasAmount !== '' && this.state.gasAmount !== null && this.state.gasAmount !== undefined) {
            this.setState({ percentageOfGasBill: ((this.state.gasAmount * 100) / name).toFixed(2) });
        }
        else {
            this.setState({ percentageOfGasBill: '' });
        }
    }
    updateGasBillingAccountNumber = (evt) => {
        var name = evt.target.value;
        //name = name.replace(/\D/g, '');
        this.setState({ custGasBillingAccountNo: name, custGasBillingAccountNo_Error: "" });
    }
    updateGasBankName = (evt) => {
        var name = evt.target.value;
        this.setState({ gasBankName: name });
    }
    updateGasAmount = (evt) => {
        var name = evt.target.value;
        this.setState({ gasAmount: name, gasAmount_Error: "" });

        if (this.state.amountDueforGas !== 0 && this.state.amountDueforGas !== "0.00" &&
            this.state.amountDueforGas !== '' && this.state.amountDueforGas !== null && this.state.amountDueforGas !== undefined &&
            name !== 0 && name !== "0.00" &&
            name !== '' && name !== null && name !== undefined) {
            this.setState({ percentageOfGasBill: ((name * 100) / this.state.amountDueforGas).toFixed(2) });
        }
        else {
            this.setState({ percentageOfGasBill: '' });
        }
    }
    updateGasProvidersBankingAccountNumber = (evt) => {
        var name = evt.target.value;
        //name = name.replace(/\D/g, '');
        this.setState({ gasUtilityProviderAccountNo: name, gasUtilityProviderAccountNo_Error: "" });
    }
    updateGasRoutingNumber = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ gasRoutingNumber: name, gasRoutingNumber_Error: "" });
    }
    updateGasMemoAccountNumber = (evt) => {
        var name = evt.target.value;
        //name = name.replace(/\D/g, '');
        this.setState({ gasMemoAccountNo: name, gasMemoAccountNo_Error: "" });
    }
    updateGasPaymentStatus = (evt) => {
        var name = evt.target.value;
        this.setState({ gasPaymentStatus: name });

        if ((this.state.rentPaymentStatus === 'Approved for Payment' || this.state.rentPaymentStatus === 'Not Approved for Payment' || this.state.rentPaymentStatus === 'Issued') &&
            (this.state.electricPaymentStatus === 'Approved for Payment' || this.state.electricPaymentStatus === 'N/A' || this.state.electricPaymentStatus === 'Issued') &&
            (name === 'Approved for Payment' || name === 'N/A' || name === 'Issued') &&
            (this.state.waterPaymentStatus === 'Approved for Payment' || this.state.waterPaymentStatus === 'N/A' || this.state.waterPaymentStatus === 'Issued') &&
            (this.state.trashPaymentStatus === 'Approved for Payment' || this.state.trashPaymentStatus === 'N/A' || this.state.trashPaymentStatus === 'Issued') &&
            (this.state.energyPaymentStatus === 'Approved for Payment' || this.state.energyPaymentStatus === 'N/A' || this.state.energyPaymentStatus === 'Issued') &&
            (this.state.individualPaymentStatus === 'Approved for Payment' || this.state.individualPaymentStatus === 'N/A' || this.state.individualPaymentStatus === 'Issued') 
            // &&
            // (this.state.otherPaymentStatus === 'Approved for Payment' || this.state.otherPaymentStatus === 'N/A' || this.state.otherPaymentStatus === 'Issued')
            ) {
            document.getElementById("individualCaseId").disabled = false;
        }
        else {
            document.getElementById("individualCaseId").disabled = true;
        }
    }
    updateGasUtilityTaxID = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ gasUtilityTaxID: name, gasUtilityTaxID_Error: "" });
    }
    updateGasUtilityDUNSNumber = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ gasUtilityDUNSNumber: name, gasUtilityDUNSNumber_Error: "" });
    }
    updateMonthlyGasBillAmount = (evt) => {
        var name = evt.target.value;
        this.setState({ monthlyGasBillAmount: name, monthlyGasBillAmount_Error: "" });
    }
    updatePercentageOfGasBill = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ percentageOfGasBill: name, percentageOfGasBill_Error: "" });
    }
    updateNoOfMonthsGasUtilityPayments = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ noOfMonthsGasUtilityPayments: name, noOfMonthsGasUtilityPayments_Error: "" });
    }
    updateGasTypeOfAssistance = (evt) => {
        var name = evt.target.value;
        this.setState({ gasTypeOfAssistance: name });
    }

    updateOtherPaymentAmount = (evt) => {
        var name = evt.target.value;
        this.setState({ otherPaymentAmount: name, otherPaymentAmount_Error: "" });
    }
    updateOtherPaymentStatus = (evt) => {
        var name = evt.target.value;
        this.setState({ otherPaymentStatus: name });

        if ((this.state.rentPaymentStatus === 'Approved for Payment' || this.state.rentPaymentStatus === 'Not Approved for Payment' || this.state.rentPaymentStatus === 'Issued') &&
            (this.state.electricPaymentStatus === 'Approved for Payment' || this.state.electricPaymentStatus === 'N/A' || this.state.electricPaymentStatus === 'Issued') &&
            (this.state.gasPaymentStatus === 'Approved for Payment' || this.state.gasPaymentStatus === 'N/A' || this.state.gasPaymentStatus === 'Issued') &&
            (this.state.waterPaymentStatus === 'Approved for Payment' || this.state.waterPaymentStatus === 'N/A' || this.state.waterPaymentStatus === 'Issued') &&
            (this.state.trashPaymentStatus === 'Approved for Payment' || this.state.trashPaymentStatus === 'N/A' || this.state.trashPaymentStatus === 'Issued') &&
            (this.state.energyPaymentStatus === 'Approved for Payment' || this.state.energyPaymentStatus === 'N/A' || this.state.energyPaymentStatus === 'Issued') &&
            (this.state.individualPaymentStatus === 'Approved for Payment' || this.state.individualPaymentStatus === 'N/A' || this.state.individualPaymentStatus === 'Issued') 
            // &&
            // (name === 'Approved for Payment' || name === 'N/A' || name === 'Issued')
            ) {
            document.getElementById("individualCaseId").disabled = false;
        }
        else {
            document.getElementById("individualCaseId").disabled = true;
        }
    }

    updateTenantDocumentStatus = (evt) => {
        var name = evt.target.value;
        this.setState({ applicantDocUploadStatus: name });
    }

    updateLandlordInfoRequestedStatus = (evt) => {
        var name = evt.target.value;
        this.setState({ landlordInfoRequestedStatus: name });
    }

    updateNotes = (evt) => {
        var name = evt.target.value;
        this.setState({ notes: name, notes_Error: "" });
    }

    updateDateApplied = (evt) => {
        var name = evt.target.value;
        this.setState({ dateApplied: name, dateApplied_Error: "" });
    }

    updateEmail = (evt) => {
        var name = evt.target.value;
        this.setState({ email: name, email_Error: "" });
    }

    updateIndividualBankName = (evt) => {
        var name = evt.target.value;
        this.setState({ individualBankName: name });
    }

    updateIndividualAccountNumber = (evt) => {
        var name = evt.target.value;
        //name = name.replace(/\D/g, '');
        this.setState({ individualAccountNumber: name, individualAccountNumber_Error: "" });
    }

    updateIndividualRoutingNumber = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ individualRoutingNumber: name, individualRoutingNumber_Error: "" });
    }

    updateIndividualPaymentAmount = (evt) => {
        var name = evt.target.value;
        this.setState({ individualPaymentAmount: name, individualPaymentAmount_Error: "" });
    }

    updateIndividualPaymentStatus = (evt) => {
        var name = evt.target.value;
        this.setState({ individualPaymentStatus: name });

        if ((this.state.rentPaymentStatus === 'Approved for Payment' || this.state.rentPaymentStatus === 'Not Approved for Payment' || this.state.rentPaymentStatus === 'Issued') &&
            (this.state.electricPaymentStatus === 'Approved for Payment' || this.state.electricPaymentStatus === 'N/A' || this.state.electricPaymentStatus === 'Issued') &&
            (this.state.gasPaymentStatus === 'Approved for Payment' || this.state.gasPaymentStatus === 'N/A' || this.state.gasPaymentStatus === 'Issued') &&
            (this.state.waterPaymentStatus === 'Approved for Payment' || this.state.waterPaymentStatus === 'N/A' || this.state.waterPaymentStatus === 'Issued') &&
            (this.state.trashPaymentStatus === 'Approved for Payment' || this.state.trashPaymentStatus === 'N/A' || this.state.trashPaymentStatus === 'Issued') &&
            (this.state.energyPaymentStatus === 'Approved for Payment' || this.state.energyPaymentStatus === 'N/A' || this.state.energyPaymentStatus === 'Issued') &&
            (name === 'Approved for Payment' || name === 'N/A' || name === 'Issued') 
            // &&
            // (this.state.otherPaymentStatus === 'Approved for Payment' || this.state.otherPaymentStatus === 'N/A' || this.state.otherPaymentStatus === 'Issued')
            ) {
            document.getElementById("individualCaseId").disabled = false;
        }
        else {
            document.getElementById("individualCaseId").disabled = true;
        }
    }

    updateIndividualMemoPropertyAddress = (evt) => {
        var name = evt.target.value;
        this.setState({ individualMemoPropertyAddress: name, individualMemoPropertyAddress_Error: "" });
    }

    onIndividualACHReceivedChange = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ individualACHReceived: 1 });
        }
        else {
            this.setState({ individualACHReceived: 0 });
        }
    }

    updateIndividualPaymentReason = (evt) => {
        var name = evt.target.value;
        this.setState({ individualPaymentReason: name });
    }

    updateOtherBankName = (evt) => {
        var name = evt.target.value;
        this.setState({ otherBankName: name });
    }

    updateOtherAccountNumber = (evt) => {
        var name = evt.target.value;
        //name = name.replace(/\D/g, '');
        this.setState({ otherAccountNumber: name, otherAccountNumber_Error: "" });
    }

    updateOtherRoutingNumber = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ otherRoutingNumber: name, otherRoutingNumber_Error: "" });
    }

    updateOtherMemoPropertyAddress = (evt) => {
        var name = evt.target.value;
        this.setState({ otherMemoPropertyAddress: name, otherMemoPropertyAddress_Error: "" });
    }

    updateOtherPayeeName = (evt) => {
        var name = evt.target.value;
        this.setState({ otherPayeeName: name, otherPayeeName_Error: "" });
    }

    updateOtherDesription = (evt) => {
        var name = evt.target.value;
        this.setState({ otherDesription: name, otherDesription_Error: "" });
    }



    updateWaterProvider = (evt) => {
        var name = evt.target.value;
        this.setState({ waterProvider: name });
    }
    updateWaterUtilityAccountNumber = (evt) => {
        var name = evt.target.value;
        //name = name.replace(/\D/g, '');
        this.setState({ waterUtilityAccountNo: name, waterUtilityAccountNo_Error: "" });
    }
    updateWaterBillingAccountNumber = (evt) => {
        var name = evt.target.value;
        //name = name.replace(/\D/g, '');
        this.setState({ custWaterBillingAccountNo: name, custWaterBillingAccountNo_Error: "" });
    }
    updateMonthlyWaterBillAmount = (evt) => {
        var name = evt.target.value;
        this.setState({ monthlyWaterBillAmount: name, monthlyWaterBillAmount_Error: "" });
    }
    updateNoOfMonthsWaterUtilityPayments = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ noOfMonthsWaterUtilityPayments: name, noOfMonthsWaterUtilityPayments_Error: "" });
    }
    updateWaterTypeOfAssistance = (evt) => {
        var name = evt.target.value;
        this.setState({ waterTypeOfAssistance: name });
    }
    updateDollarAmountDueForWater = (evt) => {
        var name = evt.target.value;
        this.setState({ amountDueforWater: name, amountDueforWater_Error: "" });

        if (name !== 0 && name !== "0.00" &&
            name !== '' && name !== null && name !== undefined &&
            this.state.waterAmount !== 0 && this.state.waterAmount !== "0.00" &&
            this.state.waterAmount !== '' && this.state.waterAmount !== null && this.state.waterAmount !== undefined) {
            this.setState({ percentageOfWaterBill: ((this.state.waterAmount * 100) / name).toFixed(2) });
        }
        else {
            this.setState({ percentageOfWaterBill: '' });
        }
    }
    updateWaterAmount = (evt) => {
        var name = evt.target.value;
        this.setState({ waterAmount: name, waterAmount_Error: "" });

        if (this.state.amountDueforWater !== 0 && this.state.amountDueforWater !== "0.00" &&
            this.state.amountDueforWater !== '' && this.state.amountDueforWater !== null && this.state.amountDueforWater !== undefined &&
            name !== 0 && name !== "0.00" &&
            name !== '' && name !== null && name !== undefined) {
            this.setState({ percentageOfWaterBill: ((name * 100) / this.state.amountDueforWater).toFixed(2) });
        }
        else {
            this.setState({ percentageOfWaterBill: '' });
        }
    }
    updatePercentageOfWaterBill = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ percentageOfWaterBill: name, percentageOfWaterBill_Error: "" });
    }
    updateWaterBankName = (evt) => {
        var name = evt.target.value;
        this.setState({ waterBankName: name });
    }
    updateWaterProvidersBankingAccountNumber = (evt) => {
        var name = evt.target.value;
        //name = name.replace(/\D/g, '');
        this.setState({ waterUtilityProviderAccountNo: name, waterUtilityProviderAccountNo_Error: "" });
    }
    updateWaterRoutingNumber = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ waterRoutingNumber: name, waterRoutingNumber_Error: "" });
    }
    updateWaterMemoAccountNumber = (evt) => {
        var name = evt.target.value;
        //name = name.replace(/\D/g, '');
        this.setState({ waterMemoAccountNo: name, waterMemoAccountNo_Error: "" });
    }
    updateWaterUtilityTaxID = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ waterUtilityTaxID: name, waterUtilityTaxID_Error: "" });
    }
    updateWaterUtilityDUNSNumber = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ waterUtilityDUNSNumber: name, waterUtilityDUNSNumber_Error: "" });
    }
    updateWaterPaymentStatus = (evt) => {
        var name = evt.target.value;
        this.setState({ waterPaymentStatus: name });

        if ((this.state.rentPaymentStatus === 'Approved for Payment' || this.state.rentPaymentStatus === 'Not Approved for Payment' || this.state.rentPaymentStatus === 'Issued') &&
            (this.state.electricPaymentStatus === 'Approved for Payment' || this.state.electricPaymentStatus === 'N/A' || this.state.electricPaymentStatus === 'Issued') &&
            (this.state.gasPaymentStatus === 'Approved for Payment' || this.state.gasPaymentStatus === 'N/A' || this.state.gasPaymentStatus === 'Issued') &&
            (name === 'Approved for Payment' || name === 'N/A' || name === 'Issued') &&
            (this.state.trashPaymentStatus === 'Approved for Payment' || this.state.trashPaymentStatus === 'N/A' || this.state.trashPaymentStatus === 'Issued') &&
            (this.state.energyPaymentStatus === 'Approved for Payment' || this.state.energyPaymentStatus === 'N/A' || this.state.energyPaymentStatus === 'Issued') &&
            (this.state.individualPaymentStatus === 'Approved for Payment' || this.state.individualPaymentStatus === 'N/A' || this.state.individualPaymentStatus === 'Issued') 
            // &&
            // (this.state.otherPaymentStatus === 'Approved for Payment' || this.state.otherPaymentStatus === 'N/A' || this.state.otherPaymentStatus === 'Issued')
            ) {
            document.getElementById("individualCaseId").disabled = false;
        }
        else {
            document.getElementById("individualCaseId").disabled = true;
        }
    }


    updateTrashProvider = (evt) => {
        var name = evt.target.value;
        this.setState({ trashProvider: name });
    }
    updateTrashUtilityAccountNumber = (evt) => {
        var name = evt.target.value;
        //name = name.replace(/\D/g, '');
        this.setState({ trashUtilityAccountNo: name, trashUtilityAccountNo_Error: "" });
    }
    updateTrashBillingAccountNumber = (evt) => {
        var name = evt.target.value;
        //name = name.replace(/\D/g, '');
        this.setState({ custTrashBillingAccountNo: name, custTrashBillingAccountNo_Error: "" });
    }
    updateMonthlyTrashBillAmount = (evt) => {
        var name = evt.target.value;
        this.setState({ monthlyTrashBillAmount: name, monthlyTrashBillAmount_Error: "" });
    }
    updateNoOfMonthsTrashUtilityPayments = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ noOfMonthsTrashUtilityPayments: name, noOfMonthsTrashUtilityPayments_Error: "" });
    }
    updateTrashTypeOfAssistance = (evt) => {
        var name = evt.target.value;
        this.setState({ trashTypeOfAssistance: name });
    }
    updateDollarAmountDueForTrash = (evt) => {
        var name = evt.target.value;
        this.setState({ amountDueforTrash: name, amountDueforTrash_Error: "" });

        if (name !== 0 && name !== "0.00" &&
            name !== '' && name !== null && name !== undefined &&
            this.state.trashAmount !== 0 && this.state.trashAmount !== "0.00" &&
            this.state.trashAmount !== '' && this.state.trashAmount !== null && this.state.trashAmount !== undefined) {
            this.setState({ percentageOfTrashBill: ((this.state.trashAmount * 100) / name).toFixed(2) });
        }
        else {
            this.setState({ percentageOfTrashBill: '' });
        }
    }
    updateTrashAmount = (evt) => {
        var name = evt.target.value;
        this.setState({ trashAmount: name, trashAmount_Error: "" });

        if (this.state.amountDueforTrash !== 0 && this.state.amountDueforTrash !== "0.00" &&
            this.state.amountDueforTrash !== '' && this.state.amountDueforTrash !== null && this.state.amountDueforTrash !== undefined &&
            name !== 0 && name !== "0.00" &&
            name !== '' && name !== null && name !== undefined) {
            this.setState({ percentageOfTrashBill: ((name * 100) / this.state.amountDueforTrash).toFixed(2) });
        }
        else {
            this.setState({ percentageOfTrashBill: '' });
        }
    }
    updatePercentageOfTrashBill = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ percentageOfTrashBill: name, percentageOfTrashBill_Error: "" });
    }
    updateTrashBankName = (evt) => {
        var name = evt.target.value;
        this.setState({ trashBankName: name });
    }
    updateTrashProvidersBankingAccountNumber = (evt) => {
        var name = evt.target.value;
        //name = name.replace(/\D/g, '');
        this.setState({ trashUtilityProviderAccountNo: name, trashUtilityProviderAccountNo_Error: "" });
    }
    updateTrashRoutingNumber = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ trashRoutingNumber: name, trashRoutingNumber_Error: "" });
    }
    updateTrashMemoAccountNumber = (evt) => {
        var name = evt.target.value;
        //name = name.replace(/\D/g, '');
        this.setState({ trashMemoAccountNo: name, trashMemoAccountNo_Error: "" });
    }
    updateTrashUtilityTaxID = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ trashUtilityTaxID: name, trashUtilityTaxID_Error: "" });
    }
    updateTrashUtilityDUNSNumber = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ trashUtilityDUNSNumber: name, trashUtilityDUNSNumber_Error: "" });
    }
    updateTrashPaymentStatus = (evt) => {
        var name = evt.target.value;
        this.setState({ trashPaymentStatus: name });

        if ((this.state.rentPaymentStatus === 'Approved for Payment' || this.state.rentPaymentStatus === 'Not Approved for Payment' || this.state.rentPaymentStatus === 'Issued') &&
            (this.state.electricPaymentStatus === 'Approved for Payment' || this.state.electricPaymentStatus === 'N/A' || this.state.electricPaymentStatus === 'Issued') &&
            (this.state.gasPaymentStatus === 'Approved for Payment' || this.state.gasPaymentStatus === 'N/A' || this.state.gasPaymentStatus === 'Issued') &&
            (this.state.waterPaymentStatus === 'Approved for Payment' || this.state.waterPaymentStatus === 'N/A' || this.state.waterPaymentStatus === 'Issued') &&
            (name === 'Approved for Payment' || name === 'N/A' || name === 'Issued') &&
            (this.state.energyPaymentStatus === 'Approved for Payment' || this.state.energyPaymentStatus === 'N/A' || this.state.energyPaymentStatus === 'Issued') &&
            (this.state.individualPaymentStatus === 'Approved for Payment' || this.state.individualPaymentStatus === 'N/A' || this.state.individualPaymentStatus === 'Issued') 
            // &&
            // (this.state.otherPaymentStatus === 'Approved for Payment' || this.state.otherPaymentStatus === 'N/A' || this.state.otherPaymentStatus === 'Issued')
            ) {
            document.getElementById("individualCaseId").disabled = false;
        }
        else {
            document.getElementById("individualCaseId").disabled = true;
        }
    }


    updateEnergyProvider = (evt) => {
        var name = evt.target.value;
        this.setState({ energyProvider: name });
    }
    updateEnergyUtilityAccountNumber = (evt) => {
        var name = evt.target.value;
        //name = name.replace(/\D/g, '');
        this.setState({ energyUtilityAccountNo: name, energyUtilityAccountNo_Error: "" });
    }
    updateEnergyBillingAccountNumber = (evt) => {
        var name = evt.target.value;
        //name = name.replace(/\D/g, '');
        this.setState({ custEnergyBillingAccountNo: name, custEnergyBillingAccountNo_Error: "" });
    }
    updateMonthlyEnergyBillAmount = (evt) => {
        var name = evt.target.value;
        this.setState({ monthlyEnergyBillAmount: name, monthlyEnergyBillAmount_Error: "" });
    }
    updateNoOfMonthsEnergyUtilityPayments = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ noOfMonthsEnergyUtilityPayments: name, noOfMonthsEnergyUtilityPayments_Error: "" });
    }
    updateEnergyTypeOfAssistance = (evt) => {
        var name = evt.target.value;
        this.setState({ energyTypeOfAssistance: name });
    }
    updateDollarAmountDueForEnergy = (evt) => {
        var name = evt.target.value;
        this.setState({ amountDueforEnergy: name, amountDueforEnergy_Error: "" });

        if (name !== 0 && name !== "0.00" &&
            name !== '' && name !== null && name !== undefined &&
            this.state.energyAmount !== 0 && this.state.energyAmount !== "0.00" &&
            this.state.energyAmount !== '' && this.state.energyAmount !== null && this.state.energyAmount !== undefined) {
            this.setState({ percentageOfEnergyBill: ((this.state.energyAmount * 100) / name).toFixed(2) });
        }
        else {
            this.setState({ percentageOfEnergyBill: '' });
        }
    }
    updateEnergyAmount = (evt) => {
        var name = evt.target.value;
        this.setState({ energyAmount: name, energyAmount_Error: "" });

        if (this.state.amountDueforEnergy !== 0 && this.state.amountDueforEnergy !== "0.00" &&
            this.state.amountDueforEnergy !== '' && this.state.amountDueforEnergy !== null && this.state.amountDueforEnergy !== undefined &&
            name !== 0 && name !== "0.00" &&
            name !== '' && name !== null && name !== undefined) {
            this.setState({ percentageOfEnergyBill: ((name * 100) / this.state.amountDueforEnergy).toFixed(2) });
        }
        else {
            this.setState({ percentageOfEnergyBill: '' });
        }
    }
    updatePercentageOfEnergyBill = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ percentageOfEnergyBill: name, percentageOfEnergyBill_Error: "" });
    }
    updateEnergyBankName = (evt) => {
        var name = evt.target.value;
        this.setState({ energyBankName: name });
    }
    updateEnergyProvidersBankingAccountNumber = (evt) => {
        var name = evt.target.value;
        //name = name.replace(/\D/g, '');
        this.setState({ energyUtilityProviderAccountNo: name, energyUtilityProviderAccountNo_Error: "" });
    }
    updateEnergyRoutingNumber = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ energyRoutingNumber: name, energyRoutingNumber_Error: "" });
    }
    updateEnergyMemoAccountNumber = (evt) => {
        var name = evt.target.value;
        //name = name.replace(/\D/g, '');
        this.setState({ energyMemoAccountNo: name, energyMemoAccountNo_Error: "" });
    }
    updateEnergyUtilityTaxID = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ energyUtilityTaxID: name, energyUtilityTaxID_Error: "" });
    }
    updateEnergyUtilityDUNSNumber = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ energyUtilityDUNSNumber: name, energyUtilityDUNSNumber_Error: "" });
    }
    updateEnergyPaymentStatus = (evt) => {
        var name = evt.target.value;
        this.setState({ energyPaymentStatus: name });

        if ((this.state.rentPaymentStatus === 'Approved for Payment' || this.state.rentPaymentStatus === 'Not Approved for Payment' || this.state.rentPaymentStatus === 'Issued') &&
            (this.state.electricPaymentStatus === 'Approved for Payment' || this.state.electricPaymentStatus === 'N/A' || this.state.electricPaymentStatus === 'Issued') &&
            (this.state.gasPaymentStatus === 'Approved for Payment' || this.state.gasPaymentStatus === 'N/A' || this.state.gasPaymentStatus === 'Issued') &&
            (this.state.waterPaymentStatus === 'Approved for Payment' || this.state.waterPaymentStatus === 'N/A' || this.state.waterPaymentStatus === 'Issued') &&
            (this.state.trashPaymentStatus === 'Approved for Payment' || this.state.trashPaymentStatus === 'N/A' || this.state.trashPaymentStatus === 'Issued') &&
            (name === 'Approved for Payment' || name === 'N/A' || name === 'Issued') &&
            (this.state.individualPaymentStatus === 'Approved for Payment' || this.state.individualPaymentStatus === 'N/A' || this.state.individualPaymentStatus === 'Issued') 
            // &&
            // (this.state.otherPaymentStatus === 'Approved for Payment' || this.state.otherPaymentStatus === 'N/A' || this.state.otherPaymentStatus === 'Issued')
            ) {
            document.getElementById("individualCaseId").disabled = false;
        }
        else {
            document.getElementById("individualCaseId").disabled = true;
        }
    }

    openAddPropertyPopup = async () => {
        document.getElementById("recform").reset();
        this.setState({ reconciliation: { "entityType": "", "initiallyPaidBy": "", "amountType": "", "amount": "", "memoPropertyAddress": "", "paymentMethod": "", "otherPaymentStatus": "", "amountCoveredAfterRec": "", "amountCoveredBeforeRec": "", "transactionNo": "", "notes": "" }, reconciliationMode: 'add', isChildRecord: false });
        setTimeout(() => {
            $('#exampleModal').modal('show');
        }, 100);
    }

    editReconciliation(id) {
        this.setState({ validationErrors: {} });
        let editRec = this.state.reconciliationListClone.filter(x => x.id == id)[0];
        let test = editRec;
        let entity = this.state.reconciliationList.find(x => x.id == id).entityType;
        let rec = this.state.reconciliationList.filter(x => x.entityType == entity)
        if (rec && rec.length > 0) {
            if (rec.pop().id != id) {
                this.setState({
                    isChildRecord: true
                });
            }
            else {
                this.setState({
                    isChildRecord: false
                });
            }
        }
        this.setState({ reconciliation: test, reconciliationMode: 'edit', reconciliationEditId: id });
        setTimeout(() => {
            $('#exampleModal').modal('show');
        }, 1000);
    }

    handleReconciliationChanges(field, e) {
        if (field == "entityType" || field == "initiallyPaidBy" || field == "amountType") {
            const { reconciliation } = { ...this.state };
            const currentState = reconciliation;
            currentState[field] = e.target.value;
            this.setState({ reconciliation: currentState });
            if (field == "amountType" || field == "entityType" && this.state.reconciliation.entityType && this.state.reconciliation.entityType !== "" && this.state.reconciliation.amountType && this.state.reconciliation.amountType !== "" && this.state.reconciliation.amount && this.state.reconciliation.amount !== "") {
                this.calculateAmountCoveredAfterRec(this.state.reconciliation.entityType)
            }
        }
        else if (field == "amount" || field == "amountCoveredAfterRec") {
            let { value } = e.target;
            //  if(!value.isNaN()) {
            let intValue = value.replace(/[^0-9.]/g, "");
            const { reconciliation } = { ...this.state };
            const currentState = reconciliation;
            currentState[field] = intValue;
            //   this.setState({ reconciliation: currentState });
            this.setState({ reconciliation: currentState });
            if (field == "amount" && this.state.reconciliation.entityType && this.state.reconciliation.entityType !== "" && this.state.reconciliation.amountType && this.state.reconciliation.amountType !== "" && this.state.reconciliation.amount && this.state.reconciliation.amount !== "") {
                this.calculateAmountCoveredAfterRec(this.state.reconciliation.entityType)
            }
            // }
        }
        else {
            const { reconciliation } = { ...this.state };
            const currentState = reconciliation;
            const { value } = e.target;
            currentState[field] = value;
            //  this.setState({ reconciliation: currentState });
            this.setState({ reconciliation: currentState });
        }

        // this.handlePropertyOnchangeValidation(field);
    }
    calculateAmountCoveredAfterRec(entityType) {
        let findRecord = this.state.reconciliationList.filter(x => x.entityType == entityType);
        let amountCoveredAfterRec = 0;
        let amountCoveredBeforeRec = 0;
        let rentAmountorReco = 0;
        // first check record exists
        if (findRecord && findRecord.length > 0) {
            let oldRecord = findRecord.pop();
            if (this.state.reconciliationMode == "add") {
                rentAmountorReco = oldRecord.amountCoveredAfterRec;
                amountCoveredBeforeRec = oldRecord.amountCoveredAfterRec;
            }
            else {
                let findRec = this.state.reconciliationList.find(x => x.id == this.state.reconciliationEditId);
                rentAmountorReco = findRec.amountCoveredBeforeRec;
            }
        }
        else {
            // <option value="">-- select --</option>
            // <option value="Landlord">Landlord</option>
            // <option value="Electric">Electric</option>
            // <option value="Gas">Gas</option>
            // <option value="Water/Sewer">Water/Sewer</option>
            // <option value="Trash">Trash</option>
            // <option value="Energy">Energy</option>
            // <option value="Individual">Individual</option>
            if (entityType == "Individual") {
                rentAmountorReco = this.state.individualPaymentAmount == null ? 0 : this.state.individualPaymentAmount;
                amountCoveredBeforeRec = this.state.individualPaymentAmount == null ? 0 : this.state.individualPaymentAmount;
            }
            else if (entityType == "Electric") {
                rentAmountorReco = this.state.electricAmount == null ? 0 : this.state.electricAmount;
                amountCoveredBeforeRec = this.state.electricAmount == null ? 0 : this.state.electricAmount;
            }
            else if (entityType == "Gas") {
                rentAmountorReco = this.state.gasAmount == null ? 0 : this.state.gasAmount;
                amountCoveredBeforeRec = this.state.gasAmount == null ? 0 : this.state.gasAmount;
            }
            else if (entityType == "Water/Sewer") {
                rentAmountorReco = this.state.waterAmount == null ? 0 : this.state.waterAmount;
                amountCoveredBeforeRec = this.state.waterAmount == null ? 0 : this.state.waterAmount;
            }
            else if (entityType == "Trash") {
                rentAmountorReco = this.state.trashAmount == null ? 0 : this.state.trashAmount;
                amountCoveredBeforeRec = this.state.trashAmount == null ? 0 : this.state.trashAmount;
            }
            else if (entityType == "Energy") {
                rentAmountorReco = this.state.energyAmount == null ? 0 : this.state.energyAmount;
                amountCoveredBeforeRec = this.state.energyAmount == null ? 0 : this.state.energyAmount;
            }
            else {
                rentAmountorReco = this.state.rentAmountCovered == null ? 0 : this.state.rentAmountCovered;
                amountCoveredBeforeRec = this.state.rentAmountCovered == null ? 0 : this.state.rentAmountCovered;
            }
        }

        if (this.state.reconciliation.amountType == "Paid" || this.state.reconciliation.amountType == "Adjustment(Positive)") {
            amountCoveredAfterRec = Number(rentAmountorReco) + Number(this.state.reconciliation.amount);
        }
        else {
            amountCoveredAfterRec = Number(rentAmountorReco) - Number(this.state.reconciliation.amount);
        }
        const { reconciliation } = { ...this.state };
        const currentState = reconciliation;
        currentState['amountCoveredAfterRec'] = amountCoveredAfterRec.toFixed(2);
        if (this.state.reconciliationMode == "add") {
            currentState['amountCoveredBeforeRec'] = amountCoveredBeforeRec;
        }
        this.setState({ reconciliation: currentState });
    }
    // calculateAmountCoveredAfterRec() {
    //     if (this.state.reconciliation.entityType == "Landlord" && this.state.reconciliation.amount) {
    //         let amountCoveredAfterRec = 0;
    //         if (this.state.reconciliation.amountType == "Paid") {
    //             amountCoveredAfterRec = Number(this.state.rentAmount) + Number(this.state.reconciliation.amount);
    //         }
    //         else {
    //             amountCoveredAfterRec = Number(this.state.rentAmount) - Number(this.state.reconciliation.amount);
    //         }
    //         const { reconciliation } = { ...this.state };
    //         const currentState = reconciliation;
    //         currentState['amountCoveredAfterRec'] = amountCoveredAfterRec;
    //         // this.setState({ reconciliation: currentState });
    //         this.setState({ reconciliation: currentState });
    //     }
    //     if (this.state.reconciliation.entityType == "Individual" && this.state.reconciliation.amount) {
    //         let amountCoveredAfterRec = 0;
    //         if (this.state.reconciliation.amountType == "Paid") {
    //             amountCoveredAfterRec = Number(this.state.individualPaymentAmount) + Number(this.state.reconciliation.amount);
    //         }
    //         else {
    //             amountCoveredAfterRec = Number(this.state.individualPaymentAmount) - Number(this.state.reconciliation.amount);
    //         }
    //         const { reconciliation } = { ...this.state };
    //         const currentState = reconciliation;
    //         currentState['amountCoveredAfterRec'] = amountCoveredAfterRec;
    //         // this.setState({ reconciliation: currentState });
    //         this.setState({ reconciliation: currentState });
    //     }
    // }
    addReconciliation = async () => {
        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayName;
        let path = '/addreconciliation';
        let myInit = { // OPTIONAL
            body: {
                "info": {
                    "individualID": this.state.reconciliation.individualID,
                    "amount": this.state.reconciliation.amount,
                    "amountCoveredAfterRec": this.state.reconciliation.amountCoveredAfterRec,
                    "amountCoveredBeforeRec": this.state.reconciliation.amountCoveredBeforeRec,
                    "amountType": this.state.reconciliation.amountType,
                    "entityType": this.state.reconciliation.entityType,
                    "initiallyPaidBy": this.state.reconciliation.initiallyPaidBy,
                    "notes": this.state.reconciliation.notes,
                    "transactionNo": this.state.reconciliation.transactionNo,
                    "mode": this.state.reconciliationMode,
                    "memoPropertyAddress": this.state.reconciliation.memoPropertyAddress,
                    "paymentMethod": this.state.reconciliation.paymentMethod,
                    "otherPaymentStatus": this.state.reconciliation.otherPaymentStatus,
                    "id": this.state.reconciliation.id,
                    "program": "irvine",
                    "total1099Amount": this.state.total1099Amount,
                    "createdBy": this.state.modifiedBy,
                    "modifiedBy": this.state.modifiedBy
                }
            }, // replace this with attributes you need
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
        }
        return await API.post(apiName, path, myInit);
    }
    calculateTotal1099() {
        // total 1099 logic
        let sum = 0;
        let substact = 0;
        // if (this.state.reconciliation.entityType == "Landlord" && this.state.reconciliation.initiallyPaidBy == "OCUW") {
        let rec = this.state.reconciliationList.filter(x => x.entityType == "Landlord" && x.initiallyPaidBy == "OCUW");
        if (rec.length > 0) {
            let arr = [];
            let returnArr = [];

            let paidRec = rec.filter(x => x.amountType == "Paid" || x.amountType == "Adjustment(Positive)");
            //  let paidRec = rec.filter(x => x.amountType == "Paid" || x.amountType == "Adjustment(Positive)");
            if (paidRec && paidRec.length > 0) {
                for (let i = 0; i < paidRec.length; i++) {
                    arr.push(Number(paidRec[i].amount))
                }
                for (var i = 0; i < arr.length; i++) {
                    sum += parseFloat(arr[i]) || 0;
                }
            }
            // let returnRec = rec.filter(x => x.amountType == "Return" || x.amountType == "Adjustment(Negative)");

            let returnRec = rec.filter(x => x.amountType == "Return" || x.amountType == "Adjustment(Negative)");

            if (returnRec && returnRec.length > 0) {
                for (let i = 0; i < returnRec.length; i++) {
                    returnArr.push(Number(returnRec[i].amount))
                }
                for (var i = 0; i < returnArr.length; i++) {
                    substact += parseFloat(returnArr[i]) || 0;
                }
            }
        }
        let total1099 = 0;
        // if (this.state.reconciliation.amountType == "Paid" || this.state.reconciliation.amountType == "Adjustment(Positive)") {
        //     total1099 = Number(this.state.rentAmount) + sum - substact;
        //     total1099 = total1099.toFixed(2);
        // }
        // else {
        //     total1099 = Number(this.state.rentAmount) + sum - substact;
        //     total1099 = total1099.toFixed(2);
        // }
        if (this.state.rentPaymentStatus == 'Not Approved for Payment') {
            total1099 = sum - substact;
        }
        else {
            total1099 = Number(this.state.rentAmountCovered) + sum - substact;
        }
        total1099 = total1099.toFixed(2);
        this.setState({ total1099Amount: total1099 });
        // }
    }

    calculateTotalFund() {
        // total 1099 logic
        let sum = 0;
        let substact = 0;
        // if (this.state.reconciliation.entityType == "Landlord" && this.state.reconciliation.initiallyPaidBy == "OCUW") {
        if (this.state.reconciliationList && this.state.reconciliationList.length > 0) {
            let arr = [];
            let returnArr = [];

            let paidRec = this.state.reconciliationList.filter(x => x.amountType == "Paid" || x.amountType == "Adjustment(Positive)");
            //  let paidRec = rec.filter(x => x.amountType == "Paid" || x.amountType == "Adjustment(Positive)");
            if (paidRec && paidRec.length > 0) {
                for (let i = 0; i < paidRec.length; i++) {
                    arr.push(Number(paidRec[i].amount))
                }
                for (var i = 0; i < arr.length; i++) {
                    sum += parseFloat(arr[i]) || 0;
                }
            }
            // let returnRec = rec.filter(x => x.amountType == "Return" || x.amountType == "Adjustment(Negative)");

            let returnRec = this.state.reconciliationList.filter(x => x.amountType == "Return" || x.amountType == "Adjustment(Negative)");

            if (returnRec && returnRec.length > 0) {
                for (let i = 0; i < returnRec.length; i++) {
                    returnArr.push(Number(returnRec[i].amount))
                }
                for (var i = 0; i < returnArr.length; i++) {
                    substact += parseFloat(returnArr[i]) || 0;
                }
            }
        }

        let totalFund = 0;
        totalFund = Number(this.state.totalCoveredClone) + sum - substact;
        totalFund = totalFund.toFixed(2);
        this.setState({ totalCovered: totalFund });
        // }
    }

    saveReconciliation = async (e) => {
        e.preventDefault();
        let isvalid = this.handleReconciliationValidation();
        if (isvalid) {
            this.setState({ isLoading: true });
            $('#exampleModal').modal('hide');
            const { reconciliation } = { ...this.state };
            const currentState = reconciliation;
            currentState["individualID"] = this.state.individualID;
            // total 1099 logic
            //  let sum = 0;
            //  let substact = 0;
            //  if (this.state.reconciliation.entityType == "Landlord" && this.state.reconciliation.initiallyPaidBy == "OCUW") {
            //      let rec = this.state.reconciliationList.filter(x => x.entityType == "Landlord" && x.initiallyPaidBy == "OCUW");
            //      if (rec.length > 0) {
            //          let arr = [];
            //          let returnArr = [];
            //          let paidRec = null;
            //          if(this.state.reconciliationMode == "edit") {
            //             // paidRec=  rec.filter(x => x.amountType == "Paid" || x.amountType == "Adjustment(Positive)" && x.id != this.state.reconciliationEditId);
            //              paidRec=  rec.filter( x => x.id != this.state.reconciliationEditId && (x.amountType == "Paid" || x.amountType == "Adjustment(Positive)"));
            //          }
            //          else {
            //              paidRec=   rec.filter(x => x.amountType == "Paid" || x.amountType == "Adjustment(Positive)");
            //          }
            //        //  let paidRec = rec.filter(x => x.amountType == "Paid" || x.amountType == "Adjustment(Positive)");
            //          if (paidRec && paidRec.length > 0) {
            //              for (let i = 0; i < paidRec.length; i++) {
            //                  arr.push(Number(paidRec[i].amount))
            //              }
            //              for (var i = 0; i < arr.length; i++) {
            //                  sum += parseFloat(arr[i]) || 0; 
            //              }
            //          }
            //         // let returnRec = rec.filter(x => x.amountType == "Return" || x.amountType == "Adjustment(Negative)");
            //         let returnRec =  null;
            //         if(this.state.reconciliationMode == "edit") { 
            //             returnRec = rec.filter(x => x.amountType == "Return" || x.amountType == "Adjustment(Negative)"  && x.id != this.state.reconciliationEditId);
            //         }
            //         else {
            //             returnRec = rec.filter(x => x.amountType == "Return" || x.amountType == "Adjustment(Negative)");
            //         }
            //          if (returnRec && returnRec.length > 0) {
            //              for (let i = 0; i < returnRec.length; i++) {
            //                  returnArr.push(Number(returnRec[i].amount))
            //              }
            //              for (var i = 0; i < returnArr.length; i++) {
            //                  substact += parseFloat(returnArr[i]) || 0; 
            //              }
            //          }
            //      }
            //      let total1099 = 0;
            //      if (this.state.reconciliation.amountType == "Paid" || this.state.reconciliation.amountType == "Adjustment(Positive)") {
            //          total1099 = Number(this.state.rentAmount) + Number(this.state.reconciliation.amount)  + sum - substact;
            //      }
            //      else {
            //          total1099 = Number(this.state.rentAmount) - Number(this.state.reconciliation.amount) + sum - substact;
            //      }
            //      this.setState({ total1099Amount: total1099 });
            //  }
            // if(this.state.reconciliation.entityType == "Landlord" && this.state.reconciliation.initiallyPaidBy == "OCUW") {
            //     let total1099 = 0;
            //     if (this.state.reconciliation.amountType == "Paid" || this.state.reconciliation.amountType == "Adjustment(Positive)") {
            //         total1099 = Number(this.state.rentAmount) + Number(this.state.reconciliation.amount);
            //     }
            //     else {
            //         total1099 = Number(this.state.rentAmount) - Number(this.state.reconciliation.amount);
            //     }
            //     this.setState({total1099Amount: total1099});
            // }
            let res = await this.addReconciliation();
            console.log(res);
            if (this.state.reconciliationMode && this.state.reconciliationMode == "add") {
                this.setState({ isLoading: false, snackbarMessageClass: 'Mui-filledSuccess', openSnackbar: true, snackbarSeverity: "success", snackbarMessage: "Record Inserted Successfully" });
            }
            else {
                this.setState({ isLoading: false, snackbarMessageClass: 'Mui-filledSuccess', openSnackbar: true, snackbarSeverity: "success", snackbarMessage: "Record Updated Successfully" });
            }
            let getList = await this.getReconciliationList();
            this.setState({ isLoading: false });
            console.log(getList);
            //  this.setState({ reconciliationList: getList, reconciliationListClone: getList });
            this.setState({ reconciliationList: getList, reconciliationListClone: JSON.parse(JSON.stringify(getList)) });
            this.calculateTotal1099();
            // this.calculateTotalFund();
        }
    }

    handleReconciliationValidation() {
        let errors = {};
        let formIsValid = true;

        if (!this.state.reconciliation.amount || this.state.reconciliation.amount == "") {
            formIsValid = false;
            errors["amount"] = "Amount (in $) is required";
        }
        if (!this.state.reconciliation.entityType || this.state.reconciliation.entityType == "") {
            formIsValid = false;
            errors["entityType"] = "Initial Receiver is required";
        }
        if (!this.state.reconciliation.initiallyPaidBy || this.state.reconciliation.initiallyPaidBy == "") {
            formIsValid = false;
            errors["initiallyPaidBy"] = "Initially Paid By is required";
        }
        if (!this.state.reconciliation.amountType || this.state.reconciliation.amountType == "") {
            formIsValid = false;
            errors["amountType"] = "Amount is required";
        }

        this.setState({ validationErrors: errors });
        return formIsValid;
    }

    closeMsg = () => {
        $('#deleteModal').modal('hide');
    }

    deleteRec = async (id) => {
        let entity = this.state.reconciliationList.find(x => x.id == id).entityType;
        let rec = this.state.reconciliationList.filter(x => x.entityType == entity)
        if (rec && rec.length > 0) {
            if (rec.pop().id == id) {
                $('#deleteModal').modal('show');
                this.setState({
                    deleteReconciliationId: id
                });
            }
            else {
                this.setState({ isLoading: false, openSnackbar: true, snackbarMessageClass: 'Mui-filledError', snackbarSeverity: "error", snackbarMessage: "You can not delete this record as there is another latest reconciliation record for same receiver." });
            }
        }
    }

    performDelete = async () => {
        this.setState({ isLoading: true });
        $('#deleteModal').modal('hide');
        let res = await this.deleteReconciliationList();
        this.setState({ isLoading: false, deleteReconciliationId: 0, snackbarMessageClass: 'Mui-filledSuccess', openSnackbar: true, snackbarSeverity: "success", snackbarMessage: "Record Deleted Successfully" });
        var recList = await this.getReconciliationList();
        // this.setState({ reconciliationList: recList, reconciliationListClone: recList });
        this.setState({ reconciliationList: recList, reconciliationListClone: JSON.parse(JSON.stringify(recList)) });
        this.calculateTotal1099();
        // this.calculateTotalFund();
    }

    deleteReconciliationList = async () => {
        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayName;
        let path = '/deletereconciliation';
        let myInit = { // OPTIONAL
            body: {
                "info": {
                    "id": this.state.deleteReconciliationId,
                    "program": "irvine",
                    "modifiedBy": this.state.modifiedBy
                }
            }, // replace this with attributes you need
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
        }
        return await API.post(apiName, path, myInit);
    }

    render() {
        if (true) { //this.state.authState === "signedIn" || this.state.authState === "loading") {
            return (

                <div className="d-flex flex-column sticky-footer-wrapper">

                    {/* main content starts */}
                    {/* <main className="main flex-fill mt-2 mt-md-3">
                        <div className="container-fluid"> */}

                    {/* content area starts */}
                    <div className="box-shadow details-page">

                        <div className="record-detail-main validation-text">
                            <h4 className="font-weight-bold text-uppercase text-primary py-2 d-block d-sm-none"><i class="fas fa-edit"></i> EDIT ERA-IRVINE APPLICATION</h4>
                            <div className="data-section">
                                <fieldset id="myset">
                                    <div className="border p-3">
                                        <React.Fragment>

                                            <div className="box-gray mb-4">
                                                {/* <h2 className="profile-title"><i class="fas fa-file-alt mr-2"></i>Other Details</h2> */}
                                                <h2 className="profile-title d-md-flex align-items-center justify-content-between">
                                                    <span></span>
                                                    <span class="property-grid-add d-flex">
                                                        <button type="button" className="btn btn-primary btn-sm my-2 my-md-0" onClick={this.resendApplicantCredentials} style={{ display: this.state.applicationAccepted_Original === "Yes" ? 'block' : 'none' }}>Resend Applicant Password</button>
                                                        <button type="button" className="btn btn-primary btn-sm ml-2 my-2 my-md-0" onClick={this.resendLandlordCredentials} style={{ display: (this.state.requestedLandlordInfo === true || this.state.requestedLandlordInfo === 1) ? 'block' : 'none' }} >Resend Landlord Password</button>
                                                    </span>
                                                </h2>
                                                <div className="box-details">
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Case </label>
                                                            <select className="form-control" id="individualCaseId" onChange={this.updateIndividualCase} value={this.state.individualCase}>
                                                                <option value="">-- select --</option>
                                                                <option value="Open" selected>Open</option>
                                                                <option value="Close">Close</option>
                                                                <option value="Paid">Paid</option>
                                                                <option value="Duplicate">Duplicate</option>
                                                                <option value="Ineligible">Ineligible</option>
                                                                <option value="Re-open">Re-open</option>
                                                            </select>
                                                            <div className="alert-small-text">{""}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Application Accepted?</label>
                                                            <select className="form-control" ref={this.applicationAcceptedRef} id="applicationAcceptedId" onChange={this.updateApplicationAccepted} value={this.state.applicationAccepted}>
                                                                <option value="">-- select --</option>
                                                                <option value="Yes">Yes</option>
                                                                <option value="No">No</option>
                                                            </select>
                                                            <div className="alert-small-text">{""}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-4">
                                                            <label>Has the household been approved for United Way? </label>
                                                            <select className="form-control" onChange={this.updateHouseholdApprovedForUnitedWay} value={this.state.householdApprovedForUnitedWay}>
                                                                <option value="">-- select --</option>
                                                                <option value="Yes">Yes</option>
                                                                <option value="No">No</option>
                                                            </select>
                                                            <div className="alert-small-text">{""}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label></label>
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" id="verifiedasEligibleId" class="custom-control-input" onChange={this.onVerifiedasEligibleChange} />
                                                                <label class="custom-control-label" for="verifiedasEligibleId">
                                                                    Verified as Eligible
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2" style={{ display: this.state.applicationAccepted_Original === "Yes" ? 'block' : 'none' }}>
                                                            <label>App. Accepted Timestamp</label>
                                                            <input className="form-control" readOnly value={this.state.dateApplicationAcceptedChanged_Formatted + ' ' + this.state.timeApplicationAcceptedChanged}></input>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <p className="wh-note"><span>Note:</span> After updating details, please click on 'Save' button to push changes to database.</p>

                                            <div className="box-gray mb-4">
                                                <h2 className="profile-title"><i class="fas fa-user-circle mr-2"></i>APPLICANT DETAILS</h2>
                                                <div className="box-details">
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Individual ID </label>
                                                            <input className="form-control" readOnly onChange={this.updateIndividualID} value={this.state.individualID}></input>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Program </label>
                                                            <select disabled className="form-control" ref={(input) => this.program = input} onChange={this.updateProgram} value={this.state.programTitle}>
                                                                <option value="">-- select --</option>
                                                                <option value="ERA-IRVINE">ERA-IRV</option>
                                                                <option value="ERA-OC">ERA-OC</option>
                                                                <option value="Pandemic HPP">Pandemic HPP</option>
                                                            </select>
                                                            <div className="alert-small-text">{""}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Date Applied </label>
                                                            <Tooltip title="Date on which applicant applied for program">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="date" readOnly ref={this.dateAppliedRef} onChange={this.updateDateApplied} value={this.state.createdDate}></input>
                                                            <div className="alert-small-text">{this.state.dateApplied_Error}</div>
                                                        </div>

                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>First Name </label>
                                                            <input className="form-control" type="text" ref={this.firstNameRef} onChange={this.updateFirstName} value={this.state.firstName} maxlength="100"></input>
                                                            <div className="alert-small-text">{this.state.firstName_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Middle Name </label>
                                                            <input className="form-control" type="text" ref={this.middleNameRef} onChange={this.updateMiddleName} value={this.state.middleName} maxlength="100"></input>
                                                            <div className="alert-small-text">{this.state.middleName_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Last Name </label>
                                                            <input className="form-control" type="text" ref={this.lastNameRef} onChange={this.updateLastName} value={this.state.lastName} maxlength="100"></input>
                                                            <div class="alert-small-text">{this.state.lastName_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-1">
                                                            <label>Suffix </label>
                                                            <input className="form-control" type="text" ref={this.suffixRef} onChange={this.updateSuffix} maxlength="10" value={this.state.suffix}></input>
                                                            <div className="alert-small-text">{this.state.suffix_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Address Line 1 </label>
                                                            <input className="form-control" type="text" ref={this.addressLine1Ref} onChange={this.updateAddress} maxlength="30" value={this.state.addressLine1}></input>
                                                            <div className="alert-small-text">{this.state.addressLine1_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Address Line 2 </label>
                                                            <input className="form-control" type="text" ref={this.addressLine2Ref} onChange={this.updateAddress2} maxlength="255" value={this.state.addressLine2}></input>
                                                            <div class="alert-small-text">{this.state.addressLine2_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-1">
                                                            <label>City </label>
                                                            <input className="form-control" type="text" ref={this.cityRef} onChange={this.updateCity} maxlength="30" value={this.state.city}></input>
                                                            <div class="alert-small-text">{this.state.city_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-1">
                                                            <label>State </label>
                                                            <select className="form-control" ref={this.stateRef} onChange={this.updateState} value={this.state.state}>
                                                                <option value="selectstate">-- select --</option>
                                                                <option value="AL">AL</option>
                                                                <option value="AK">AK</option>
                                                                <option value="AR">AR</option>
                                                                <option value="AZ">AZ</option>
                                                                <option value="CA">CA</option>
                                                                <option value="CO">CO</option>
                                                                <option value="CT">CT</option>
                                                                <option value="DC">DC</option>
                                                                <option value="DE">DE</option>
                                                                <option value="FL">FL</option>
                                                                <option value="GA">GA</option>
                                                                <option value="HI">HI</option>
                                                                <option value="IA">IA</option>
                                                                <option value="ID">ID</option>
                                                                <option value="IL">IL</option>
                                                                <option value="IN">IN</option>
                                                                <option value="KS">KS</option>
                                                                <option value="KY">KY</option>
                                                                <option value="LA">LA</option>
                                                                <option value="MA">MA</option>
                                                                <option value="MD">MD</option>
                                                                <option value="ME">ME</option>
                                                                <option value="MI">MI</option>
                                                                <option value="MN">MN</option>
                                                                <option value="MO">MO</option>
                                                                <option value="MS">MS</option>
                                                                <option value="MT">MT</option>
                                                                <option value="NC">NC</option>
                                                                <option value="NE">NE</option>
                                                                <option value="NH">NH</option>
                                                                <option value="NJ">NJ</option>
                                                                <option value="NM">NM</option>
                                                                <option value="NV">NV</option>
                                                                <option value="NY">NY</option>
                                                                <option value="ND">ND</option>
                                                                <option value="OH">OH</option>
                                                                <option value="OK">OK</option>
                                                                <option value="OR">OR</option>
                                                                <option value="PA">PA</option>
                                                                <option value="RI">RI</option>
                                                                <option value="SC">SC</option>
                                                                <option value="SD">SD</option>
                                                                <option value="TN">TN</option>
                                                                <option value="TX">TX</option>
                                                                <option value="UT">UT</option>
                                                                <option value="VT">VT</option>
                                                                <option value="VA">VA</option>
                                                                <option value="WA">WA</option>
                                                                <option value="WI">WI</option>
                                                                <option value="WV">WV</option>
                                                                <option value="WY">WY</option>
                                                            </select>
                                                            <div class="alert-small-text">{this.state.state_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-1">
                                                            <label>Zip </label>
                                                            <input className="form-control" type="text" ref={this.zipcodeRef} onChange={this.updateZip} maxlength="5" value={this.state.zipcode}></input>
                                                            <div class="alert-small-text">{this.state.zipcode_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Tenant Email </label>
                                                            <div className="input-group-append">
                                                                <input type="text" className="form-control" ref={this.emailRef} onChange={this.updateEmail} readOnly maxlength="100" value={this.state.email}></input>
                                                            </div>
                                                            <div class="alert-small-text">{this.state.email_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Phone Number</label>
                                                            <input className="form-control" type="text" ref={this.phoneRef} onChange={this.updatePhone} maxlength="20" value={this.state.phone}></input>
                                                            <div class="alert-small-text">{this.state.phone_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Phone Type</label>
                                                            <select className="form-control" ref={this.phoneTypeRef} onChange={this.updatePhoneType} value={this.state.phoneType}>
                                                                <option value="">-- select --</option>
                                                                <option value="Mobile">Mobile</option>
                                                                <option value="Home">Home</option>
                                                                <option value="Work">Work</option>
                                                                <option value="Other">Other</option>
                                                            </select>
                                                            <div class="alert-small-text">{this.state.phoneType_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Gender </label>
                                                            <select className="form-control" ref={this.genderRef} onChange={this.updateGender} value={this.state.gender}>
                                                                <option value="">-- select --</option>
                                                                <option value="Female">Female</option>
                                                                <option value="Male">Male</option>
                                                                <option value="Trans Female (MTF or Male to Female)">Trans Female (MTF or Male to Female)</option>
                                                                <option value="Trans Male (FTM or Female to Male)">Trans Male (FTM or Female to Male)</option>
                                                                <option value="Gender Non-Conforming">Gender Non-Conforming</option>
                                                                <option value="Do not know">Do not know</option>
                                                                <option value="Refuse to answer">Refuse to answer</option>
                                                            </select>
                                                            <div className="alert-small-text">{""}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Race</label>
                                                            <select className="form-control" ref={this.raceRef} onChange={this.updateRace} value={this.state.race}>
                                                                <option value="">-- select --</option>
                                                                <option value="White (Hispanic or Latino)">White (Hispanic or Latino)</option>
                                                                <option value="White (Non-Hispanic or Non-Latino)">White (Non-Hispanic or Non-Latino)</option>
                                                                <option value="Black or African American">Black or African American</option>
                                                                <option value="American Indian or Alaska Native">American Indian or Alaska Native</option>
                                                                <option value="Middle Eastern and North African">Middle Eastern and North African</option>
                                                                <option value="Native Hawaiian or Other Pacific Islander">Native Hawaiian or Other Pacific Islander</option>
                                                                <option value="Asian">Asian</option>
                                                                <option value="Other">Other</option>
                                                                <option value="Do not know">Do not know</option>
                                                                <option value="Refuse to answer">Refuse to answer</option>
                                                            </select>
                                                            <div class="alert-small-text">{this.state.race_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Ethnicity</label>
                                                            <select className="form-control" ref={this.ethnicityRef} onChange={this.updateEthnicity} value={this.state.ethnicity}>
                                                                <option value="">-- select --</option>
                                                                <option value="Non-Hispanic or Latino">Non-Hispanic or Latino</option>
                                                                <option value="Hispanic or Latino">Hispanic or Latino</option>
                                                                <option value="Do not know">Do not know</option>
                                                                <option value="Refuse to answer">Refuse to answer</option>
                                                            </select>
                                                            <div class="alert-small-text">{this.state.ethnicity_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Date of Birth </label>
                                                            <input className="form-control" type="date" ref={this.dateOfBirthRef} onChange={this.updateDOB} value={this.state.dateOfBirth}></input>
                                                            <div className="alert-small-text">{""}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Interpretation service </label>
                                                            <select className="form-control" onChange={this.updateInterpretationServicesRequiredForLanguage} value={this.state.interpretationServicesRequiredForLanguage}>
                                                                <option value="">-- select --</option>
                                                                <option value="Spanish">Spanish</option>
                                                                <option value="Farsi">Farsi</option>
                                                                <option value="Mandarin">Mandarin</option>
                                                                <option value="Vietnamese">Vietnamese</option>
                                                                <option value="Korean">Korean</option>
                                                                <option value="None">None</option>
                                                            </select>
                                                            <div className="alert-small-text">{""}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-3">
                                                            <label>Secondary First Name </label>
                                                            <input className="form-control" type="text" ref={this.secondaryFirstNameRef} onChange={this.updateSecondaryFirstName} value={this.state.secondaryFirstName} maxlength="100"></input>
                                                            <div className="alert-small-text">{this.state.secondaryFirstName_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-3">
                                                            <label>Secondary Last Name </label>
                                                            <input className="form-control" type="text" ref={this.secondaryLastNameRef} onChange={this.updateSecondaryLastName} value={this.state.secondaryLastName} maxlength="100"></input>
                                                            <div class="alert-small-text">{this.state.secondaryLastName_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-3">
                                                            <label>Secondary Email</label>
                                                            <input className="form-control" type="text" ref={this.secondaryEmailRef} onChange={this.updateSecondaryEmail} value={this.state.secondaryEmail} maxlength="50" ></input>
                                                            <div class="alert-small-text">{this.state.secondaryEmail_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-3">
                                                            <label>Secondary Phone </label>
                                                            <input className="form-control" type="text" ref={this.secondaryPhoneRef} onChange={this.updateSecondaryPhone} value={this.state.secondaryPhone} maxlength="20" ></input>
                                                            <div class="alert-small-text">{this.state.secondaryPhone_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Household Size? </label>
                                                            <input className="form-control" type="number" name="household" min="1" max="100" ref={this.householdNumberRef} onChange={this.updateHouseHold} value={this.state.householdNumber} />
                                                            <div class="alert-small-text">{this.state.householdNumber_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Count Adults </label>
                                                            <input className="form-control" type="number" name="household" min="0" max="100" ref={this.countAdultRef} onChange={this.updateCountAdult} value={this.state.countAdult} />
                                                            <div class="alert-small-text">{this.state.countAdult_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Count Children </label>
                                                            <input className="form-control" type="number" name="household" min="0" max="100" ref={this.countChildrenRef} onChange={this.updateCountChildren} value={this.state.countChildren} />
                                                            <div class="alert-small-text">{this.state.countChildren_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Are you the Head of Household? </label>
                                                            <select className="form-control" ref={this.headOfHouseholdRef} onChange={this.updateHeadOfHousehold} value={this.state.headOfHousehold}>
                                                                <option value="">-- select --</option>
                                                                <option value="Yes">Yes</option>
                                                                <option value="No">No</option>
                                                                <option value="Don't Know">Don't Know</option>
                                                            </select>
                                                            <div class="alert-small-text">{this.state.headOfHousehold_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-4">
                                                            <label>Are you or anyone in your household a veteran? </label>
                                                            <select className="form-control" ref={(input) => this.veteranSelector = input} onChange={this.updateVeteran} value={this.state.veteran}>
                                                                <option value="">-- select --</option>
                                                                <option value="1">Yes</option>
                                                                <option value="0">No</option>
                                                            </select>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>

                                                        <div className="form-group col-md-6 col-xl-3">
                                                            <label>Monthly Household Income</label>
                                                            <Tooltip title="Monthly Household Income before COVID-19. Maximum allowed value is 99999.99.">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="text" ref={this.currentMonthlyIncomeRef} onChange={this.updateCurrentMonthlyIncome} value={this.state.currentMonthlyIncome} max="99999.99"></input>
                                                            <div class="alert-small-text">{this.state.currentMonthlyIncome_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-3">
                                                            <label>Current Total Household Income</label>
                                                            <Tooltip title="Maximum allowed value is 9999999.99">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="text" ref={this.totalHouseholdAnnualIncomeRef} onChange={this.updateTotalHouseholdAnnualIncome} value={this.state.totalHouseholdAnnualIncome} max="9999999.99" ></input>
                                                            <div class="alert-small-text">{this.state.totalHouseholdAnnualIncome_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-3">
                                                            <label>Financial hardship due to COVID-19?</label>
                                                            <select className="form-control" ref={this.experiencedFinancialHardshipInCovidRef} onChange={this.updateExperiencedFinancialHardshipInCovid} value={this.state.experiencedFinancialHardshipInCovid}>
                                                                <option value="">-- select --</option>
                                                                <option value="1">Yes</option>
                                                                <option value="0">No</option>
                                                            </select>
                                                            <div class="alert-small-text">{this.state.experiencedFinancialHardshipInCovid_Error}</div>
                                                        </div>
                                                        {/* <div className="form-group col-md-6 col-xl-3">
                                                                <label>Financial effect </label>
                                                                <Tooltip title="How did COVID-19 financially affect you or your household?">
                                                                    <i className="fa fa-question-circle ml-1"></i>
                                                                </Tooltip>
                                                                <select className="form-control" onChange={this.checkaffect} ref={(input) => this.effected = input} onChange={this.updateFinancialEffect} value={this.state.effectOfCovid19Financially}>
                                                                    <option value="">-- select --</option>
                                                                    <option value="Furloughed due to pandemic">Furloughed due to pandemic</option>
                                                                    <option value="Laid off due to pandemic">Laid off due to pandemic</option>
                                                                    <option value="Underemployed or significant reduction in work hours">Underemployed or significant reduction in work hours</option>
                                                                    <option value="Not Affected">Not Affected</option>
                                                                    <option value="Other">Other</option>
                                                                </select>
                                                                <div className="alert-small-text">{""}</div>
                                                            </div>
                                                            <div className="form-group col-md-6 col-xl-2" style={{ display: (this.state.effectOfCovid19Financially === 'Other') ? 'block' : 'none' }}>
                                                                <label>Enter the other financial effect</label>
                                                                <input className="form-control" ref={this.covid_otherRef} onChange={this.updateCovidOther} value={this.state.covid_other}></input>
                                                                <div class="alert-small-text">{this.state.covid_other_Error}</div>
                                                            </div> */}
                                                        <div className="form-group col-md-6 col-xl-3">
                                                            <label>Is someone on rental/lease agreement?</label>
                                                            <Tooltip title="Is someone in your household on the rental or lease agreement?">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <select className="form-control" ref={this.isSomeoneOnRentalOrLeaseAgreementRef} onChange={this.updateIsSomeoneOnRentalOrLeaseAgreement} value={this.state.isSomeoneOnRentalOrLeaseAgreement}>
                                                                <option value="">-- select --</option>
                                                                <option value="1">Yes</option>
                                                                <option value="0">No</option>
                                                            </select>
                                                            <div class="alert-small-text">{this.state.isSomeoneOnRentalOrLeaseAgreement_Error}</div>
                                                        </div>

                                                        <div className="form-group col-md-12">
                                                            <div><label>Check all that apply to you and/or anyone in your household due to the impact of the COVID19 coronavirus pandemic</label></div>
                                                            <div className="form-row">
                                                                <div className="col-md-6">
                                                                    <div class="custom-control custom-checkbox">
                                                                        <input type="checkbox" class="custom-control-input" onChange={this.updateQualifiedForUnemploymentBenefits} id="qualifiedForUnemploymentBenefitsId" />
                                                                        <label class="custom-control-label" for="qualifiedForUnemploymentBenefitsId">Qualified for unemployment benefits</label>
                                                                    </div>
                                                                    <div class="custom-control custom-checkbox">
                                                                        <input type="checkbox" class="custom-control-input" onChange={this.updateExperiencedReductionInHouseholdIncome} id="experiencedReductionInHouseholdIncomeId" />
                                                                        <label class="custom-control-label" for="experiencedReductionInHouseholdIncomeId">Experienced a reduction in household income</label>
                                                                    </div>
                                                                    <div class="custom-control custom-checkbox">
                                                                        <input type="checkbox" class="custom-control-input" onChange={this.updateIncurredSignificantCosts} id="incurredSignificantCostsId" />
                                                                        <label class="custom-control-label" for="incurredSignificantCostsId">Incurred significant costs</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div class="custom-control custom-checkbox">
                                                                        <input type="checkbox" class="custom-control-input" onChange={this.updateReductionOfChildSupport} id="reductionOfChildSupportId" />
                                                                        <label class="custom-control-label" for="reductionOfChildSupportId">Reduction/loss of child support</label>
                                                                    </div>
                                                                    <div class="custom-control custom-checkbox">
                                                                        <input type="checkbox" class="custom-control-input" onChange={this.updateOtherFinancialHardship} id="otherFinancialHardshipId" />
                                                                        <label class="custom-control-label" for="otherFinancialHardshipId">Other financial hardship(s)</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-3">
                                                            <label>Do you receive any housing subsidy?</label>
                                                            <select className="form-control" ref={this.receivedHousingSubsidyRef} onChange={this.updateReceivedAnyHousingSubsidy} value={this.state.receivedHousingSubsidy}>
                                                                <option value="">-- select --</option>
                                                                <option value="1">Yes</option>
                                                                <option value="0">No</option>
                                                            </select>
                                                            <div class="alert-small-text">{this.state.receivedHousingSubsidy_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>If yes, please explain</label>
                                                            <input className="form-control" type="text" id="subsidyExplanationId" ref={this.subsidyExplanationRef} onChange={this.updateSubsidyExplanation} maxlength="255" value={this.state.subsidyExplanation}></input>
                                                            <div class="alert-small-text">{this.state.subsidyExplanation_Error}</div>
                                                        </div>

                                                        <div className="form-group col-md-6 col-xl-3">
                                                            <label>Filed a declaration of COVID-19?</label>
                                                            <Tooltip title="Have you filed a declaration of COVID-19 related financial stress?">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <select className="form-control" ref={this.filedCovid19FinancialStressDeclarationRef} onChange={this.updateFiledCovid19FinancialStressDeclaration} value={this.state.filedCovid19FinancialStressDeclaration}>
                                                                <option value="">-- select --</option>
                                                                <option value="1">Yes</option>
                                                                <option value="0">No</option>
                                                            </select>
                                                            <div class="alert-small-text">{this.state.filedCovid19FinancialStressDeclaration_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-3">
                                                            <label>Received an eviction notice?</label>
                                                            <select className="form-control" ref={this.receivedEvictionNoticeRef} onChange={this.updateReceivedEvictionNotice} value={this.state.receivedEvictionNotice}>
                                                                <option value="">-- select --</option>
                                                                <option value="1">Yes</option>
                                                                <option value="0">No</option>
                                                            </select>
                                                            <div class="alert-small-text">{this.state.receivedEvictionNotice_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-3">
                                                            <label>Eviction notice received date </label>
                                                            <input className="form-control" type="date" id="dateEvictionNoticeReceivedId" ref={this.dateEvictionNoticeReceivedRef} onChange={this.updateDateEvictionNoticeReceived} value={this.state.dateEvictionNoticeReceived}></input>
                                                            <div className="alert-small-text">{this.state.dateEvictionNoticeReceived_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-3">
                                                            <label>Live in Irvine?</label>
                                                            <select className="form-control" ref={this.doYouLiveInIrvineRef} onChange={this.updateDoYouLiveInIrvine} value={this.state.doYouLiveInIrvine}>
                                                                <option value="">-- select --</option>
                                                                <option value="1">Yes</option>
                                                                <option value="0">No</option>
                                                            </select>
                                                            <div class="alert-small-text">{this.state.doYouLiveInIrvine_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-3">
                                                            <label>Do you rent?</label>
                                                            <select className="form-control" ref={this.doYouRentRef} onChange={this.updateDoYouRent} value={this.state.doYouRent}>
                                                                <option value="">-- select --</option>
                                                                <option value="1">Yes</option>
                                                                <option value="0">No</option>
                                                            </select>
                                                            <div class="alert-small-text">{this.state.doYouRent_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-3">
                                                            <label>Behind on rent?</label>
                                                            <select className="form-control" ref={this.areYouBehindOnRentRef} onChange={this.updateAreYouBehindOnRent} value={this.state.areYouBehindOnRent}>
                                                                <option value="">-- select --</option>
                                                                <option value="1">Yes</option>
                                                                <option value="0">No</option>
                                                            </select>
                                                            <div class="alert-small-text">{this.state.areYouBehindOnRent_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-3">
                                                            <label>Hold Section 8?</label>
                                                            <select className="form-control" ref={this.doYouHoldSection8HousingVoucherRef} onChange={this.updateDoYouHoldSection8HousingVoucher} value={this.state.doYouHoldSection8HousingVoucher}>
                                                                <option value="">-- select --</option>
                                                                <option value="1">Yes</option>
                                                                <option value="0">No</option>
                                                            </select>
                                                            <div class="alert-small-text">{this.state.doYouHoldSection8HousingVoucher_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-3">
                                                            <label>Annual Income less?</label>
                                                            <select className="form-control" ref={this.isAnnualIncomeLessThanMaxLimitRef} onChange={this.updateIsAnnualIncomeLessThanMaxLimit} value={this.state.isAnnualIncomeLessThanMaxLimit}>
                                                                <option value="">-- select --</option>
                                                                <option value="1">Yes</option>
                                                                <option value="0">No</option>
                                                            </select>
                                                            <div class="alert-small-text">{this.state.isAnnualIncomeLessThanMaxLimit_Error}</div>
                                                        </div>

                                                        <div className="form-group col-md-6 col-xl-3">
                                                            <label>Below 30% AMI </label>
                                                            <select className="form-control" onChange={this.updateBelowThirtyPercAMI} value={this.state.belowThirtyPercAMI}>
                                                                <option value="">-- select --</option>
                                                                <option value="Yes">Yes</option>
                                                                <option value="No">No</option>
                                                            </select>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-3">
                                                            <label>30%-50% AMI </label>
                                                            <select className="form-control" onChange={this.updateThirtyToFiftyPercAMI} value={this.state.thirtyToFiftyPercAMI}>
                                                                <option value="">-- select --</option>
                                                                <option value="Yes">Yes</option>
                                                                <option value="No">No</option>
                                                            </select>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-3">
                                                            <label>50%-80% AMI </label>
                                                            <select className="form-control" onChange={this.updateFiftyToEightyPercAMI} value={this.state.fiftyToEightyPercAMI}>
                                                                <option value="">-- select --</option>
                                                                <option value="Yes">Yes</option>
                                                                <option value="No">No</option>
                                                            </select>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="box-gray mb-4">
                                                <h2 className="profile-title"><i class="fas fa-file-alt mr-2"></i>Other Details</h2>
                                                <div className="box-details">
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Unique ID </label>
                                                            <input className="form-control" readOnly ref={this.uniqueIDRef} onChange={this.updateUniqueID} value={this.state.uniqueID} maxlength="50"></input>
                                                            <div class="alert-small-text">{this.state.uniqueID_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Agent</label>
                                                            <input className="form-control" type="text" ref={this.agentRef} onChange={this.updateAgent} maxlength="100" value={this.state.agent}></input>
                                                            <div class="alert-small-text">{this.state.agent_Error}</div>
                                                        </div>

                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Social Security Number</label>
                                                            <input className="form-control" type="text" ref={this.SSNRef} onChange={this.updateSSN} maxlength="10" value={this.state.SSN}></input>
                                                            <div class="alert-small-text">{this.state.SSN_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Social Security First Name </label>
                                                            <input className="form-control" type="text" ref={this.socialSecurityFirstNameRef} onChange={this.updateSocialSecurityFirstName} value={this.state.socialSecurityFirstName} maxlength="100"></input>
                                                            <div className="alert-small-text">{this.state.socialSecurityFirstName_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Social Security Last Name </label>
                                                            <input className="form-control" type="text" ref={this.socialSecurityLastNameRef} onChange={this.updateSocialSecurityLastName} value={this.state.socialSecurityLastName} maxlength="100"></input>
                                                            <div class="alert-small-text">{this.state.socialSecurityLastName_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Length of time for Award </label>
                                                            <Tooltip title="Calculated based on Date Applied and Date on which the rent payment status updated to Issued">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="text" readOnly onChange={this.updateLengthOfTimeForAward} value={this.state.lengthOfTimeForAward} ></input>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-1">
                                                            <label>Household ID </label>
                                                            <input className="form-control" ref={this.householdIDRef} onChange={this.updateHouseholdID} value={this.state.householdID}></input>
                                                            <div class="alert-small-text">{this.state.householdID_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Transaction ID </label>
                                                            <input className="form-control" ref={this.transactionIDRef} onChange={this.updateTransactionID} value={this.state.transactionID}></input>
                                                            <div class="alert-small-text">{this.state.transactionID_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-1">
                                                            <label>Age </label>
                                                            <input className="form-control" type="number" name="household" min="0" max="100" ref={this.ageRef} onChange={this.updateAge} value={this.state.age} />
                                                            <div class="alert-small-text">{this.state.age_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Birthday Code </label>
                                                            <input className="form-control" type="text" ref={this.birthdayCodeRef} onChange={this.updateBirthdayCode} value={this.state.birthdayCode} maxlength="255"></input>
                                                            <div class="alert-small-text">{this.state.birthdayCode_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Other Phone </label>
                                                            <input className="form-control" type="text" ref={this.otherPhoneRef} onChange={this.updateOtherPhone} value={this.state.otherPhone} maxlength="20" ></input>
                                                            <div class="alert-small-text">{this.state.otherPhone_Error}</div>
                                                        </div>

                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>SPA Assignment </label>
                                                            <select className="form-control" ref={this.spaAssignmentNoRef} onChange={this.updateSPAAssignmentNo} value={this.state.spaAssignmentNo}>
                                                                <option value="">-- select --</option>
                                                                <option value="North SPA">North SPA</option>
                                                                <option value="Central SPA">Central SPA</option>
                                                                <option value="South SPA">South SPA</option>
                                                            </select>
                                                            <div class="alert-small-text">{this.state.spaAssignmentNo_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>SPA Service Provider Email </label>
                                                            <input className="form-control" maxlength="255" ref={this.spaServiceProviderEmailRef} onChange={this.updateSPAServiceProviderEmail} value={this.state.spaServiceProviderEmail}></input>
                                                            <div class="alert-small-text">{this.state.spaServiceProviderEmail_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Partner First Name </label>
                                                            <input className="form-control" type="text" ref={this.partnerFirstNameRef} onChange={this.updateFirstNameSpouse} maxlength="100" value={this.state.partnerFirstName}></input>
                                                            <div className="alert-small-text">{this.state.partnerFirstName_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Partner Last Name </label>
                                                            <input className="form-control" type="text" ref={this.partnerLastNameRef} onChange={this.updateLastNameSpouse} maxlength="100" value={this.state.partnerLastName}></input>
                                                            <div class="alert-small-text">{this.state.partnerLastName_Error}</div>
                                                        </div>

                                                        <div className="form-group col-md-6 col-xl-3">
                                                            <label>Is the Individual Open to Follow-up? </label>
                                                            <select className="form-control" ref={(input) => this.openToFollowUpSelector = input} onChange={this.updateOpenToFollowUp} value={this.state.openToFollowup}>
                                                                <option value="">-- select --</option>
                                                                <option value="Yes">Yes</option>
                                                                <option value="No">No</option>
                                                            </select>
                                                            <div className="alert-small-text">{""}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-3">
                                                            <label>Number of Times Participated</label>
                                                            <Tooltip title="Number of Times Applicant has Participated in Program">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="text" ref={this.noOfTimesParticipatedRef} onChange={this.updateNoOfTimesParticipated} maxlength="2" value={this.state.noOfTimesParticipated}></input>
                                                            <div class="alert-small-text">{this.state.noOfTimesParticipated_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Board District </label>
                                                            <select className="form-control" onChange={this.updateBoardDistrict} value={this.state.boardDistrict}>
                                                                <option value="">-- select --</option>
                                                                <option value="1">1</option>
                                                                <option value="2">2</option>
                                                                <option value="3">3</option>
                                                                <option value="4">4</option>
                                                                <option value="5">5</option>
                                                            </select>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>

                                                        <div className="form-group col-md-6 col-xl-3">
                                                            <label>Are you eligible to legally work in the US? </label>
                                                            <select className="form-control" ref={(input) => this.workSelector = input} onChange={this.updateLegallyAbleToWork} value={this.state.legallyAbleToWork}>
                                                                <option value="">-- select --</option>
                                                                <option value="Yes">Yes</option>
                                                                <option value="No">No</option>
                                                            </select>
                                                            <div className="alert-small-text">{""}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Work Industry? </label>
                                                            <select className="form-control" ref={(input) => this.industrySelector = input} onChange={this.updateWorkIndustry} value={this.state.workIndustry}>
                                                                <option value="">-- select --</option>
                                                                <option value="Food Service (traditional food service & food delivery)">Food Service (traditional food service & food delivery)</option>
                                                                <option value="Hospitality">Hospitality</option>
                                                                <option value="Amusement Parks">Amusement Parks</option>
                                                                <option value="Retail">Retail</option>
                                                                <option value="Self-employed (free lance, uber/lyft drivers, etc.)">Self-employed (free lance, uber/lyft drivers, etc.)</option>
                                                                <option value="Other">Other</option>
                                                            </select>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2" style={{ display: (this.state.workIndustry === 'Other') ? 'block' : 'none' }}>
                                                            <label>Enter the other industry</label>
                                                            <input className="form-control" ref={this.industry_otherRef} onChange={this.updateIndustryOther} value={this.state.industry_other} maxlength="100"></input>
                                                            <div className="alert-small-text">{this.state.industry_other_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-3">
                                                            <label>Are You eligible for stimulus funds? </label>
                                                            <select className="form-control" ref={(input) => this.stimulusSelector = input} onChange={this.updateStimulus} value={this.state.eligibleForStimulusFunds}>
                                                                <option value="">-- select --</option>
                                                                <option value="Yes">Yes</option>
                                                                <option value="No">No</option>
                                                            </select>
                                                            <div className="alert-small-text">{""}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-3">
                                                            <label>Employment Status? </label>
                                                            <select className="form-control" ref={(input) => this.employmentSelector = input} onChange={this.updateEmploymentStatus} value={this.state.employmentStatus}>
                                                                <option value="">-- select --</option>
                                                                <option value="Employed">Employed</option>
                                                                <option value="Unemployed">Unemployed</option>
                                                            </select>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="box-gray mb-4">
                                                <h2 class="profile-title d-md-flex align-items-center justify-content-between">
                                                    <span><i class="fas fa-house-user mr-2"></i>Landlord Details</span>
                                                    {this.state.landlordInfoRequestedStatus !== "Ready" ?
                                                        (
                                                            <span class="property-grid-add d-flex">
                                                                <button type="button" class="btn btn-primary btn-sm my-2 my-md-0" onClick={this.requestLandlordInfo} style={{ display: (this.state.requestedLandlordInfo === true || this.state.requestedLandlordInfo === 1) ? 'none' : 'block' }} id="requestLandlordInfoId">Request information</button>
                                                                <label class="my-2 pt-2" style={{ fontSize: '14px', color: '#000', textTransform: 'initial', fontWeight: '500' }} style={{ display: (this.state.requestedLandlordInfo === true || this.state.requestedLandlordInfo === 1) ? 'block' : 'none' }} id="requestDateId" >Date Requested: {this.state.dateLandlordInfoRequested_Formatted + ' ' + this.state.timeLandlordInfoRequested}</label>
                                                                <label class="my-2 ml-3 mr-2 pt-2" style={{ fontSize: '14px', color: '#000', textTransform: 'initial', fontWeight: '500' }} style={{ display: ((this.state.requestedLandlordInfo === true || this.state.requestedLandlordInfo === 1) && this.state.landlordInfoRequestedStatus === 'Reminder Sent') ? 'block' : 'none' }} id="remindedDateId" >Date Reminded: {this.state.dateLandlordInfoReminded_Formatted + ' ' + this.state.timeLandlordInfoReminded}</label>
                                                                <button type="button" class="btn btn-primary btn-sm ml-2 my-2 my-md-0" onClick={this.sendReminderToLandlord} style={{ display: (this.state.requestedLandlordInfo === true || this.state.requestedLandlordInfo === 1) ? 'block' : 'none' }} id="sendReminderToLandlordId">Send Reminder</button>

                                                                {/* <button type="button" disabled class="btn btn-primary btn-sm my-2 my-md-0" onClick={this.requestLandlordInfo} style={{display: 'block'}} id="requestLandlordInfoId">Request information</button>
                                                                <label class="my-2 pt-2" style={{ fontSize: '14px', color: '#000', textTransform: 'initial', fontWeight: '500' }} style={{display: 'none'}} id="requestDateId" ></label>
                                                                <button type="button" disabled class="btn btn-primary btn-sm ml-2 my-2 my-md-0" onClick={this.sendReminderToLandlord} style={{display: 'none'}} id="sendReminderToLandlordId">Send Reminder</button> */}
                                                            </span>
                                                        ) :
                                                        (
                                                            // <span class="property-grid-add d-flex">
                                                            //     <label class="my-2 ml-3 mr-2 pt-2" style={{ fontSize: '14px', color: '#000', textTransform: 'initial', fontWeight: '500' }} style={{display: 'block'}} id="landlordSubmittedDateId" >Information Received: {this.state.dateLandlordDocumentSubmitted_Formatted + ' ' + this.state.timeLandlordDocumentSubmitted}</label>
                                                            // </span>


                                                            // Nothing to show here
                                                            <span></span>
                                                        )
                                                    }
                                                </h2>
                                                <div className="box-details">
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Landlord Payee Full Name</label>
                                                            <Tooltip title="please fillout name on bank account (landlord or property manager)">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="text" ref={this.landlordFullNameRef} onChange={this.updateLandlordFullName} value={this.state.landlordFullName} maxlength="100" ></input>
                                                            <div class="alert-small-text">{this.state.landlordFullName_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Landlord Email</label>
                                                            <input className="form-control" type="text" ref={this.landlordEmailRef} onChange={this.updateLandlordEmail} value={this.state.landlordEmail} maxlength="50" ></input>
                                                            <div class="alert-small-text">{this.state.landlordEmail_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Landlord Phone Number </label>
                                                            <input className="form-control" type="text" ref={this.landlordPhoneRef} onChange={this.updateLandlordPhone} value={this.state.landlordPhone} maxlength="20" ></input>
                                                            <div class="alert-small-text">{this.state.landlordPhone_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Landlord Mobile Number </label>
                                                            <input className="form-control" type="text" ref={this.landlordMobileRef} onChange={this.updatelandlordMobile} value={this.state.landlordMobile} maxlength="20" ></input>
                                                            <div class="alert-small-text">{this.state.landlordMobile_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Amount Due for Rent</label>
                                                            <Tooltip title="Amount due must be within the time period of April 1, 2020 to March 31, 2021. Maximum allowed value is 99999.99.">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="text" ref={this.amountBehindOnRentRef} onChange={this.updateAmountBehindOnRent} value={this.state.amountBehindOnRent} maxlength="8" max="99999.99"></input>
                                                            <div class="alert-small-text">{this.state.amountBehindOnRent_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Rent Amount Covered</label>
                                                            <Tooltip title="$ amount approved for payout. Maximum allowed value is 99999.99.">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="text" ref={this.rentAmountCoveredRef} onChange={this.updateRentAmountCovered} value={this.state.rentAmountCovered} maxlength="8" max="99999.99" ></input>
                                                            <div class="alert-small-text">{this.state.rentAmountCovered_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Award % of total rent due</label>
                                                            <Tooltip title="Calculated based on Amount Due for Rent and Rent Amount Covered">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="text" ref={this.percentageOfMonthlyRentRef} onChange={this.updatePercentageOfMonthlyRent} value={this.state.percentageOfMonthlyRent} readOnly maxlength="2" ></input>
                                                            <div class="alert-small-text">{this.state.percentageOfMonthlyRent_Error}</div>
                                                        </div>

                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Landlord Bank Name</label>
                                                            <input className="form-control" type="text" onChange={this.updateLandlordBankName} value={this.state.landlordBankName} maxlength="100" ></input>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Landlord Account Number</label>
                                                            <Tooltip title="If applicable: Bank account number. Monies will be deposited into this account.">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="text" ref={this.landlordAccountNumberRef} onChange={this.updateLandlordAccountNumber} value={this.state.landlordAccountNumber} maxlength="100" ></input>
                                                            <div class="alert-small-text">{this.state.landlordAccountNumber_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Landlord Routing Number</label>
                                                            <Tooltip title="If applicable: Bank electronic routing number">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="text" ref={this.landlordRoutingNumberRef} onChange={this.updateLandlordRoutingNumber} value={this.state.landlordRoutingNumber} maxlength="100" ></input>
                                                            <div class="alert-small-text">{this.state.landlordRoutingNumber_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Landlord SSN</label>
                                                            <Tooltip title="If applicable: SSN is a nine-digit number issued to U.S. citizens, permanent residents, and temporary residents.">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="text" ref={this.landlordSSNRef} onChange={this.updateLandlordSSN} value={this.state.landlordSSN} maxlength="10" ></input>
                                                            <div class="alert-small-text">{this.state.landlordSSN_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Landlord Tax I.D.</label>
                                                            <Tooltip title="Must Include if Applicable. A Tax Identification Number is a nine-digit number, can be a social security number or employer identification number. Typically used if landlord is a sole proprietor.">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="text" ref={this.landlordTaxIDRef} onChange={this.updateLandlordTaxID} value={this.state.landlordTaxID} maxlength="10" ></input>
                                                            <div class="alert-small-text">{this.state.landlordTaxID_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Landlord DUNS Number</label>
                                                            <Tooltip title="Must Include if Applicable. The Dun & Bradstreet DUNS Number is a unique nine-digit identifier for businesses.">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="text" ref={this.landlordDUNSNumberRef} onChange={this.updateLandlordDUNSNumber} value={this.state.landlordDUNSNumber} maxlength="10" ></input>
                                                            <div class="alert-small-text">{this.state.landlordDUNSNumber_Error}</div>
                                                        </div>

                                                        <div className="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Monthly Rent</label>
                                                            <Tooltip title="Maximum allowed value is 99999.99">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="text" ref={this.monthlyRentPaymentRef} onChange={this.updateMonthlyRentPayment} value={this.state.monthlyRentPayment} maxlength="8" max="99999.99"></input>
                                                            <div class="alert-small-text">{this.state.monthlyRentPayment_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label># of Month of Rental Payment</label>
                                                            <input className="form-control" type="text" ref={this.monthsBehindOnRentRef} onChange={this.updateMonthsBehindOnRent} value={this.state.monthsBehindOnRent} maxlength="5" ></input>
                                                            <div class="alert-small-text">{this.state.monthsBehindOnRent_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Memo - Property Address</label>
                                                            <input className="form-control" type="text" onChange={this.updateMemoPropertyAddress} value={this.state.memoPropertyAddress} maxlength="100" ></input>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Rent Type of Assistance </label>
                                                            <select className="form-control" ref={(input) => this.rentTypeAssistanceSelector = input} onChange={this.updateRentTypeOfAssistance} value={this.state.rentTypeOfAssistance}>
                                                                <option value="">-- select --</option>
                                                                <option value="Rent">Rent</option>
                                                                <option value="Rental Arrears">Rental Arrears</option>
                                                            </select>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Arrear Amount</label>
                                                            <Tooltip title="Outstanding Rental Arrears Amount. Maximum allowed value is 99999.99.">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="text" ref={this.outstandingRentalArrearsAmountRef} onChange={this.updateOutstandingRentalArrears} value={this.state.outstandingRentalArrearsAmount} maxlength="8" max="99999.99" ></input>
                                                            <div class="alert-small-text">{this.state.outstandingRentalArrearsAmount_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Rent Payment Status </label>
                                                            <select className="form-control" id="rentPaymentStatusId" ref={this.rentPaymentStatusRef} onChange={this.updateRentPaymentStatus} value={this.state.rentPaymentStatus}>
                                                                <option value="">-- select --</option>
                                                                <option value="Pending">Pending</option>
                                                                <option value="In-Review">In-Review</option>
                                                                <option value="Approved for Payment">Approved for Payment</option>
                                                                <option value="Not Approved for Payment">Not Approved for Payment</option>
                                                                {/* <option value="Issued">Issued</option> */}
                                                            </select>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>

                                                        <div className="form-group col-md-6 col-xl-3" style={{ display: (this.state.rentPaymentStatus_Original === 'Issued') ? 'block' : 'none' }}>
                                                            <label>Timestamp of Issued Notification</label>
                                                            <input className="form-control" type="text" readOnly onChange={this.updateTimestampOfRentIssued} value={this.state.timestampOfRentIssued} ></input>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-3">
                                                            <label>Landlord First Attempt (21 Days)</label>
                                                            <input className="form-control" type="date" onChange={this.updateLandlordFirstAttempt} value={this.state.landlordFirstAttempt}></input>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                        {/* <div className="form-group col-md-6 col-xl-3">
                                                                <label>Landlord Response (21 Days)</label>
                                                                <input className="form-control" type="date" onChange={this.updateLandlordResponse} value={this.state.landlordResponse}></input>
                                                                <div class="alert-small-text">{""}</div>
                                                            </div> */}
                                                        <div className="form-group col-md-6 col-xl-3">
                                                            <label>Reason Rent Payment Status N/A </label>
                                                            <Tooltip title="Reason of payment not processed in 21 days">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <select className="form-control" onChange={this.updateReasonPaymentNotProcessedIn21Days} ref={this.reasonPaymentNotProcessedIn21DaysRef} value={this.state.reasonPaymentNotProcessedIn21Days}>
                                                                <option value="">-- select --</option>
                                                                <option value="Refused to accept">Refused to accept</option>
                                                                <option value="No payment requested">No payment requested</option>
                                                                <option value="No response in 21 days from landlord">No response in 21 days from landlord</option>
                                                                <option value="Tenant Request to Withdraw">Tenant Request to Withdraw</option>
                                                                <option value="Anonymous file transfer">Anonymous file transfer</option>
                                                                <option value="Pending service provider response">Pending service provider response</option>
                                                                <option value="Terminated. No applicant response.">Terminated. No applicant response.</option>
                                                            </select>
                                                            <div class="alert-small-text">{this.state.reasonPaymentNotProcessedIn21Days_Error}</div>
                                                        </div>

                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Start Date Covered by Payment</label>
                                                            <input className="form-control" type="date" name="startDateCovered" min="2020-04-01" onChange={this.updateStartDateCoveredByPayment} value={this.state.startDateCoveredByPayment}></input>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>End Date Covered by Payment</label>
                                                            <input className="form-control" type="date" onChange={this.updateEndDateCoveredByPayment} value={this.state.endDateCoveredByPayment}></input>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>1099 Total</label>
                                                            <Tooltip title="Sum of 'Rent Amount Covered' and 'reconciliation Amount'">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="text" readOnly value={this.state.total1099Amount} ></input>
                                                            {/* <div class="alert-small-text">{this.state.total1099Amount_Error}</div> */}
                                                        </div>

                                                        <div className="form-group col-auto">
                                                            <label></label>
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" id="landlordACHReceivedId" class="custom-control-input" onChange={this.onLandlordACHReceivedChange} />
                                                                <label class="custom-control-label" for="landlordACHReceivedId">
                                                                    ACH Received
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="form-group col-auto">
                                                            <label></label>
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" id="paymentToIrvineCompanyId" class="custom-control-input" onChange={this.onPaymentToIrvineCompanyChange} />
                                                                <label class="custom-control-label" for="paymentToIrvineCompanyId">
                                                                    Payment to Irvine Company
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="form-group col-auto">
                                                            <label></label>
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" id="landlordTitlePulledId" class="custom-control-input" onChange={this.onLandlordTitlePulledChange} />
                                                                <label class="custom-control-label" for="landlordTitlePulledId">
                                                                    Title Pulled
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="box-gray mb-4">
                                                <h2 className="profile-title"><i class="fas fa-bolt mr-2"></i>Electric Details</h2>
                                                <div className="box-details">
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Electric Provider </label>
                                                            <input className="form-control" onChange={this.updateElectricProvider} value={this.state.electricProvider} maxlength="100"></input>
                                                            <div className="alert-small-text">{""}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Electric Utility Account # </label>
                                                            <input className="form-control" ref={this.electricUtilityAccountNoRef} onChange={this.updateElectricUtilityAccountNumber} value={this.state.electricUtilityAccountNo} maxlength="100"></input>
                                                            <div class="alert-small-text">{this.state.electricUtilityAccountNo_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Billing Account Number </label>
                                                            <Tooltip title="Customer's Electric Utility Billing Account Number">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" ref={this.custElectricBillingAccountNoRef} onChange={this.updateElectricBillingAccountNumber} value={this.state.custElectricBillingAccountNo} maxlength="100"></input>
                                                            <div class="alert-small-text">{this.state.custElectricBillingAccountNo_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Monthly Electric Bill Amount</label>
                                                            <Tooltip title="Maximum allowed value is 99999.99">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="text" ref={this.monthlyElectricBillAmountRef} onChange={this.updateMonthlyElectricBillAmount} value={this.state.monthlyElectricBillAmount} maxlength="8" max="99999.99" ></input>
                                                            <div class="alert-small-text">{this.state.monthlyElectricBillAmount_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-4">
                                                            <label># of Month of Electric Utility Payments</label>
                                                            <input className="form-control" type="text" ref={this.noOfMonthsElectricUtilityPaymentsRef} onChange={this.updateNoOfMonthsElectricUtilityPayments} value={this.state.noOfMonthsElectricUtilityPayments} maxlength="2" ></input>
                                                            <div class="alert-small-text">{this.state.noOfMonthsElectricUtilityPayments_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Electric Type of Assistance </label>
                                                            <select className="form-control" ref={(input) => this.electricTypeOfAssistanceSelector = input} onChange={this.updateElectricTypeOfAssistance} value={this.state.electricTypeOfAssistance}>
                                                                <option value="">-- select --</option>
                                                                <option value="Payment">Payment</option>
                                                                <option value="Payment Arrears">Payment Arrears</option>
                                                            </select>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Amount Due for Electricity </label>
                                                            <Tooltip title="Maximum allowed value is 99999.99">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" ref={this.amountDueforElectricityRef} onChange={this.updateDollarAmountDueForElectricity} value={this.state.amountDueforElectricity} maxlength="8" max="99999.99"></input>
                                                            <div className="alert-small-text">{this.state.amountDueforElectricity_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Electric Amount Covered </label>
                                                            <Tooltip title="$ amount approved for payout. Maximum allowed value is 99999.99">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" ref={this.electricAmountRef} onChange={this.updateElectricAmount} value={this.state.electricAmount} maxlength="8" max="99999.99"></input>
                                                            <div class="alert-small-text">{this.state.electricAmount_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>% of Electric Bill</label>
                                                            <Tooltip title="Calculated based on Amount Due for Electricity and Electric Amount Covered">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="text" ref={this.percentageOfElectricBillRef} onChange={this.updatePercentageOfElectricBill} value={this.state.percentageOfElectricBill} readOnly maxlength="2" ></input>
                                                            <div class="alert-small-text">{this.state.percentageOfElectricBill_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Electric Bank Name </label>
                                                            <input className="form-control" onChange={this.updateElectricBankName} value={this.state.electricBankName} maxlength="50"></input>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Banking Account Number </label>
                                                            <Tooltip title="Electric Utility Provider's Banking Account Number">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" ref={this.electricUtilityProviderAccountNoRef} onChange={this.updateElectricityProvidersBankingAccountNumber} value={this.state.electricUtilityProviderAccountNo}></input>
                                                            <div class="alert-small-text">{this.state.electricUtilityProviderAccountNo_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Electric Routing Number </label>
                                                            <input className="form-control" type="text" ref={this.electricRoutingNumberRef} onChange={this.updateElectricRoutingNumber} value={this.state.electricRoutingNumber} maxlength="100" ></input>
                                                            <div class="alert-small-text">{this.state.electricRoutingNumber_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-3">
                                                            <label>Electric Memo - Account Number</label>
                                                            <input className="form-control" type="text" ref={this.electricMemoAccountNoRef} onChange={this.updateElectricMemoAccountNumber} value={this.state.electricMemoAccountNo} maxlength="100" ></input>
                                                            <div class="alert-small-text">{this.state.electricMemoAccountNo_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Electric Utility Tax I.D.</label>
                                                            <Tooltip title="Must Include if Applicable">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="text" ref={this.electricUtilityTaxIDRef} onChange={this.updateElectricUtilityTaxID} value={this.state.electricUtilityTaxID} maxlength="10" ></input>
                                                            <div class="alert-small-text">{this.state.electricUtilityTaxID_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-3">
                                                            <label>Electric Utility DUNS Number</label>
                                                            <Tooltip title="Must Include if Applicable">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="text" ref={this.electricUtilityDUNSNumberRef} onChange={this.updateElectricUtilityDUNSNumber} value={this.state.electricUtilityDUNSNumber} maxlength="10" ></input>
                                                            <div class="alert-small-text">{this.state.electricUtilityDUNSNumber_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Electric Payment Status </label>
                                                            <select className="form-control" id="electricPaymentStatusId" ref={(input) => this.electricPaymentStatusSelector = input} onChange={this.updateElectricPaymentStatus} value={this.state.electricPaymentStatus}>
                                                                <option value="">-- select --</option>
                                                                <option value="Pending">Pending</option>
                                                                <option value="In-Review">In-Review</option>
                                                                <option value="Approved for Payment">Approved for Payment</option>
                                                                <option value="N/A">N/A</option>
                                                                {/* <option value="Issued">Issued</option> */}
                                                            </select>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="box-gray mb-4">
                                                <h2 className="profile-title"><i class="fas fa-burn mr-2"></i>Gas Details</h2>
                                                <div className="box-details">
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Gas Provider </label>
                                                            <input className="form-control" onChange={this.updateGasProvider} value={this.state.gasProvider} maxlength="100"></input>
                                                            <div className="alert-small-text">{""}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Gas Utility Account # </label>
                                                            <input className="form-control" ref={this.gasUtilityAccountNoRef} onChange={this.updateGasUtilityAccountNumber} value={this.state.gasUtilityAccountNo} maxlength="100"></input>
                                                            <div class="alert-small-text">{this.state.gasUtilityAccountNo_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Billing Account Number </label>
                                                            <Tooltip title="Customer's Gas Utility Billing Account Number">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" ref={this.custGasBillingAccountNoRef} onChange={this.updateGasBillingAccountNumber} value={this.state.custGasBillingAccountNo} maxlength="100"></input>
                                                            <div class="alert-small-text">{this.state.custGasBillingAccountNo_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Monthly Gas Bill Amount </label>
                                                            <Tooltip title="CMaximum allowed value is 99999.99">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" ref={this.monthlyGasBillAmountRef} onChange={this.updateMonthlyGasBillAmount} value={this.state.monthlyGasBillAmount} maxlength="8" max="99999.99"></input>
                                                            <div class="alert-small-text">{this.state.monthlyGasBillAmount_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-4">
                                                            <label># of Month of Gas Utility Payments</label>
                                                            <input className="form-control" type="text" ref={this.noOfMonthsGasUtilityPaymentsRef} onChange={this.updateNoOfMonthsGasUtilityPayments} value={this.state.noOfMonthsGasUtilityPayments} maxlength="2" ></input>
                                                            <div class="alert-small-text">{this.state.noOfMonthsGasUtilityPayments_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Gas Type of Assistance </label>
                                                            <select className="form-control" ref={(input) => this.gasTypeOfAssistanceSelector = input} onChange={this.updateGasTypeOfAssistance} value={this.state.gasTypeOfAssistance}>
                                                                <option value="">-- select --</option>
                                                                <option value="Payment">Payment</option>
                                                                <option value="Payment Arrears">Payment Arrears</option>
                                                            </select>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Amount Due for Gas </label>
                                                            <Tooltip title="Maximum allowed value is 99999.99">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" ref={this.amountDueforGasRef} onChange={this.updateDollarAmountDueForGas} value={this.state.amountDueforGas} maxlength="8" max="99999.99"></input>
                                                            <div className="alert-small-text">{this.state.amountDueforGas_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Gas Amount Covered </label>
                                                            <Tooltip title="$ amount approved for payout. Maximum allowed value is 99999.99">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" ref={this.gasAmountRef} onChange={this.updateGasAmount} value={this.state.gasAmount} maxlength="8" max="99999.99"></input>
                                                            <div class="alert-small-text">{this.state.gasAmount_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>% of Gas Bill </label>
                                                            <Tooltip title="Calculated based on Amount Due for Gas and Gas Amount Covered">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" ref={this.percentageOfGasBillRef} readOnly maxlength="2" onChange={this.updatePercentageOfGasBill} value={this.state.percentageOfGasBill}></input>
                                                            <div class="alert-small-text">{this.state.percentageOfGasBill_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Gas Bank Name </label>
                                                            <input className="form-control" onChange={this.updateGasBankName} value={this.state.gasBankName} maxlength="50"></input>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Banking Account Number </label>
                                                            <Tooltip title="Gas Utility Provider's Banking Account Number">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" ref={this.gasUtilityProviderAccountNoRef} onChange={this.updateGasProvidersBankingAccountNumber} value={this.state.gasUtilityProviderAccountNo} maxlength="100"></input>
                                                            <div class="alert-small-text">{this.state.gasUtilityProviderAccountNo_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Gas Routing Number </label>
                                                            <input className="form-control" type="text" ref={this.gasRoutingNumberRef} onChange={this.updateGasRoutingNumber} value={this.state.gasRoutingNumber} maxlength="100" ></input>
                                                            <div class="alert-small-text">{this.state.gasRoutingNumber_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-3">
                                                            <label>Gas Memo - Account Number</label>
                                                            <input className="form-control" type="text" ref={this.gasMemoAccountNoRef} onChange={this.updateGasMemoAccountNumber} value={this.state.gasMemoAccountNo} maxlength="100" ></input>
                                                            <div class="alert-small-text">{this.state.gasMemoAccountNo_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Gas Utility Tax I.D. </label>
                                                            <Tooltip title="Must Include if Applicable">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="text" ref={this.gasUtilityTaxIDRef} onChange={this.updateGasUtilityTaxID} value={this.state.gasUtilityTaxID} maxlength="10" ></input>
                                                            <div class="alert-small-text">{this.state.gasUtilityTaxID_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-3">
                                                            <label>Gas Utility DUNS Number </label>
                                                            <Tooltip title="Must Include if Applicable">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="text" ref={this.gasUtilityDUNSNumberRef} onChange={this.updateGasUtilityDUNSNumber} value={this.state.gasUtilityDUNSNumber} maxlength="10" ></input>
                                                            <div class="alert-small-text">{this.state.gasUtilityDUNSNumber_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Gas Payment Status </label>
                                                            <select className="form-control" id="gasPaymentStatusId" ref={(input) => this.gasPaymentStatusSelector = input} onChange={this.updateGasPaymentStatus} value={this.state.gasPaymentStatus}>
                                                                <option value="">-- select --</option>
                                                                <option value="Pending">Pending</option>
                                                                <option value="In-Review">In-Review</option>
                                                                <option value="Approved for Payment">Approved for Payment</option>
                                                                <option value="N/A">N/A</option>
                                                                {/* <option value="Issued">Issued</option> */}
                                                            </select>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="box-gray mb-4">
                                                <h2 className="profile-title"><i class="fas fa-water mr-2"></i>Water/Sewer Details</h2>
                                                <div className="box-details">
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Water Provider </label>
                                                            <input className="form-control" onChange={this.updateWaterProvider} value={this.state.waterProvider} maxlength="100"></input>
                                                            <div className="alert-small-text">{""}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Water Utility Account # </label>
                                                            <input className="form-control" ref={this.waterUtilityAccountNoRef} onChange={this.updateWaterUtilityAccountNumber} value={this.state.waterUtilityAccountNo} maxlength="100"></input>
                                                            <div class="alert-small-text">{this.state.waterUtilityAccountNo_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Billing Account Number </label>
                                                            <Tooltip title="Customer's Water Utility Billing Account Number">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" ref={this.custWaterBillingAccountNoRef} onChange={this.updateWaterBillingAccountNumber} value={this.state.custWaterBillingAccountNo} maxlength="100"></input>
                                                            <div class="alert-small-text">{this.state.custWaterBillingAccountNo_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Monthly Water Bill Amount </label>
                                                            <Tooltip title="Maximum allowed value is 99999.99">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" ref={this.monthlyWaterBillAmountRef} onChange={this.updateMonthlyWaterBillAmount} value={this.state.monthlyWaterBillAmount} maxlength="8" max="99999.99"></input>
                                                            <div class="alert-small-text">{this.state.monthlyWaterBillAmount_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-4">
                                                            <label># of Month of Water Utility Payments</label>
                                                            <input className="form-control" type="text" ref={this.noOfMonthsWaterUtilityPaymentsRef} onChange={this.updateNoOfMonthsWaterUtilityPayments} value={this.state.noOfMonthsWaterUtilityPayments} maxlength="2" ></input>
                                                            <div class="alert-small-text">{this.state.noOfMonthsWaterUtilityPayments_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Water Type of Assistance </label>
                                                            <select className="form-control" ref={(input) => this.waterTypeOfAssistanceSelector = input} onChange={this.updateWaterTypeOfAssistance} value={this.state.waterTypeOfAssistance}>
                                                                <option value="">-- select --</option>
                                                                <option value="Payment">Payment</option>
                                                                <option value="Payment Arrears">Payment Arrears</option>
                                                            </select>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Amount Due for Water </label>
                                                            <Tooltip title="Maximum allowed value is 99999.99">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" ref={this.amountDueforWaterRef} onChange={this.updateDollarAmountDueForWater} value={this.state.amountDueforWater} maxlength="8" max="99999.99"></input>
                                                            <div className="alert-small-text">{this.state.amountDueforWater_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Water Amount Covered </label>
                                                            <Tooltip title="$ amount approved for payout. Maximum allowed value is 99999.99">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" ref={this.waterAmountRef} onChange={this.updateWaterAmount} value={this.state.waterAmount} maxlength="8" max="99999.99"></input>
                                                            <div class="alert-small-text">{this.state.waterAmount_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>% of Water Bill </label>
                                                            <Tooltip title="Calculated based on Amount Due for Water and Water Amount Covered">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" ref={this.percentageOfWaterBillRef} readOnly maxlength="2" onChange={this.updatePercentageOfWaterBill} value={this.state.percentageOfWaterBill}></input>
                                                            <div class="alert-small-text">{this.state.percentageOfWaterBill_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Water Bank Name </label>
                                                            <input className="form-control" onChange={this.updateWaterBankName} value={this.state.waterBankName} maxlength="50"></input>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Banking Account Number </label>
                                                            <Tooltip title="Water Utility Provider's Banking Account Number">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" ref={this.waterUtilityProviderAccountNoRef} onChange={this.updateWaterProvidersBankingAccountNumber} value={this.state.waterUtilityProviderAccountNo} maxlength="100"></input>
                                                            <div class="alert-small-text">{this.state.waterUtilityProviderAccountNo_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Water Routing Number </label>
                                                            <input className="form-control" type="text" ref={this.waterRoutingNumberRef} onChange={this.updateWaterRoutingNumber} value={this.state.waterRoutingNumber} maxlength="100" ></input>
                                                            <div class="alert-small-text">{this.state.waterRoutingNumber_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-3">
                                                            <label>Water Memo - Account Number</label>
                                                            <input className="form-control" type="text" ref={this.waterMemoAccountNoRef} onChange={this.updateWaterMemoAccountNumber} value={this.state.waterMemoAccountNo} maxlength="100" ></input>
                                                            <div class="alert-small-text">{this.state.waterMemoAccountNo_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Water Utility Tax I.D. </label>
                                                            <Tooltip title="Must Include if Applicable">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="text" ref={this.waterUtilityTaxIDRef} onChange={this.updateWaterUtilityTaxID} value={this.state.waterUtilityTaxID} maxlength="10" ></input>
                                                            <div class="alert-small-text">{this.state.waterUtilityTaxID_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-3">
                                                            <label>Water Utility DUNS Number </label>
                                                            <Tooltip title="Must Include if Applicable">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="text" ref={this.waterUtilityDUNSNumberRef} onChange={this.updateWaterUtilityDUNSNumber} value={this.state.waterUtilityDUNSNumber} maxlength="10" ></input>
                                                            <div class="alert-small-text">{this.state.waterUtilityDUNSNumber_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Water Payment Status </label>
                                                            <select className="form-control" id="waterPaymentStatusId" ref={(input) => this.waterPaymentStatusSelector = input} onChange={this.updateWaterPaymentStatus} value={this.state.waterPaymentStatus}>
                                                                <option value="">-- select --</option>
                                                                <option value="Pending">Pending</option>
                                                                <option value="In-Review">In-Review</option>
                                                                <option value="Approved for Payment">Approved for Payment</option>
                                                                <option value="N/A">N/A</option>
                                                                {/* <option value="Issued">Issued</option> */}
                                                            </select>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="box-gray mb-4">
                                                <h2 className="profile-title"><i class="fas fa-trash mr-2"></i>Trash Details</h2>
                                                <div className="box-details">
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Trash Provider </label>
                                                            <input className="form-control" onChange={this.updateTrashProvider} value={this.state.trashProvider} maxlength="100"></input>
                                                            <div className="alert-small-text">{""}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Trash Utility Account # </label>
                                                            <input className="form-control" ref={this.trashUtilityAccountNoRef} onChange={this.updateTrashUtilityAccountNumber} value={this.state.trashUtilityAccountNo} maxlength="100"></input>
                                                            <div class="alert-small-text">{this.state.trashUtilityAccountNo_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Billing Account Number </label>
                                                            <Tooltip title="Customer's Trash Utility Billing Account Number">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" ref={this.custTrashBillingAccountNoRef} onChange={this.updateTrashBillingAccountNumber} value={this.state.custTrashBillingAccountNo} maxlength="100"></input>
                                                            <div class="alert-small-text">{this.state.custTrashBillingAccountNo_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Monthly Trash Bill Amount </label>
                                                            <Tooltip title="Maximum allowed value is 99999.99">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" ref={this.monthlyTrashBillAmountRef} onChange={this.updateMonthlyTrashBillAmount} value={this.state.monthlyTrashBillAmount} maxlength="8" max="99999.99"></input>
                                                            <div class="alert-small-text">{this.state.monthlyTrashBillAmount_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-4">
                                                            <label># of Month of Trash Utility Payments</label>
                                                            <input className="form-control" type="text" ref={this.noOfMonthsTrashUtilityPaymentsRef} onChange={this.updateNoOfMonthsTrashUtilityPayments} value={this.state.noOfMonthsTrashUtilityPayments} maxlength="2" ></input>
                                                            <div class="alert-small-text">{this.state.noOfMonthsTrashUtilityPayments_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Trash Type of Assistance </label>
                                                            <select className="form-control" ref={(input) => this.trashTypeOfAssistanceSelector = input} onChange={this.updateTrashTypeOfAssistance} value={this.state.trashTypeOfAssistance}>
                                                                <option value="">-- select --</option>
                                                                <option value="Payment">Payment</option>
                                                                <option value="Payment Arrears">Payment Arrears</option>
                                                            </select>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Amount Due for Trash </label>
                                                            <Tooltip title="Maximum allowed value is 99999.99">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" ref={this.amountDueforTrashRef} onChange={this.updateDollarAmountDueForTrash} value={this.state.amountDueforTrash} maxlength="8" max="99999.99"></input>
                                                            <div className="alert-small-text">{this.state.amountDueforTrash_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Trash Amount Covered </label>
                                                            <Tooltip title="$ amount approved for payout. Maximum allowed value is 99999.99">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" ref={this.trashAmountRef} onChange={this.updateTrashAmount} value={this.state.trashAmount} maxlength="8" max="99999.99"></input>
                                                            <div class="alert-small-text">{this.state.trashAmount_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>% of Trash Bill </label>
                                                            <Tooltip title="Calculated based on Amount Due for Trash and Trash Amount Covered">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" ref={this.percentageOfTrashBillRef} readOnly maxlength="2" onChange={this.updatePercentageOfTrashBill} value={this.state.percentageOfTrashBill}></input>
                                                            <div class="alert-small-text">{this.state.percentageOfTrashBill_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Trash Bank Name </label>
                                                            <input className="form-control" onChange={this.updateTrashBankName} value={this.state.trashBankName} maxlength="100"></input>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Banking Account Number </label>
                                                            <Tooltip title="Trash Utility Provider's Banking Account Number">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" ref={this.trashUtilityProviderAccountNoRef} onChange={this.updateTrashProvidersBankingAccountNumber} value={this.state.trashUtilityProviderAccountNo} maxlength="100"></input>
                                                            <div class="alert-small-text">{this.state.trashUtilityProviderAccountNo_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Trash Routing Number </label>
                                                            <input className="form-control" type="text" ref={this.trashRoutingNumberRef} onChange={this.updateTrashRoutingNumber} value={this.state.trashRoutingNumber} maxlength="100" ></input>
                                                            <div class="alert-small-text">{this.state.trashRoutingNumber_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-3">
                                                            <label>Trash Memo - Account Number</label>
                                                            <input className="form-control" type="text" ref={this.trashMemoAccountNoRef} onChange={this.updateTrashMemoAccountNumber} value={this.state.trashMemoAccountNo} maxlength="100" ></input>
                                                            <div class="alert-small-text">{this.state.trashMemoAccountNo_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Trash Utility Tax I.D. </label>
                                                            <Tooltip title="Must Include if Applicable">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="text" ref={this.trashUtilityTaxIDRef} onChange={this.updateTrashUtilityTaxID} value={this.state.trashUtilityTaxID} maxlength="10" ></input>
                                                            <div class="alert-small-text">{this.state.trashUtilityTaxID_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-3">
                                                            <label>Trash Utility DUNS Number </label>
                                                            <Tooltip title="Must Include if Applicable">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="text" ref={this.trashUtilityDUNSNumberRef} onChange={this.updateTrashUtilityDUNSNumber} value={this.state.trashUtilityDUNSNumber} maxlength="10" ></input>
                                                            <div class="alert-small-text">{this.state.trashUtilityDUNSNumber_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Trash Payment Status </label>
                                                            <select className="form-control" id="trashPaymentStatusId" ref={(input) => this.trashPaymentStatusSelector = input} onChange={this.updateTrashPaymentStatus} value={this.state.trashPaymentStatus}>
                                                                <option value="">-- select --</option>
                                                                <option value="Pending">Pending</option>
                                                                <option value="In-Review">In-Review</option>
                                                                <option value="Approved for Payment">Approved for Payment</option>
                                                                <option value="N/A">N/A</option>
                                                                {/* <option value="Issued">Issued</option> */}
                                                            </select>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="box-gray mb-4">
                                                <h2 className="profile-title"><i class="fas fa-sun mr-2"></i>Energy Details</h2>
                                                <div className="box-details">
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Energy Provider </label>
                                                            <input className="form-control" onChange={this.updateEnergyProvider} value={this.state.energyProvider} maxlength="100"></input>
                                                            <div className="alert-small-text">{""}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Energy Utility Account # </label>
                                                            <input className="form-control" ref={this.energyUtilityAccountNoRef} onChange={this.updateEnergyUtilityAccountNumber} value={this.state.energyUtilityAccountNo} maxlength="100"></input>
                                                            <div class="alert-small-text">{this.state.energyUtilityAccountNo_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Billing Account Number </label>
                                                            <Tooltip title="Customer's Energy Utility Billing Account Number">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" ref={this.custEnergyBillingAccountNoRef} onChange={this.updateEnergyBillingAccountNumber} value={this.state.custEnergyBillingAccountNo} maxlength="100"></input>
                                                            <div class="alert-small-text">{this.state.custEnergyBillingAccountNo_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Monthly Energy Bill Amount </label>
                                                            <Tooltip title="Maximum allowed value is 99999.99">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" ref={this.monthlyEnergyBillAmountRef} onChange={this.updateMonthlyEnergyBillAmount} value={this.state.monthlyEnergyBillAmount} maxlength="8" max="99999.99"></input>
                                                            <div class="alert-small-text">{this.state.monthlyEnergyBillAmount_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-4">
                                                            <label># of Month of Energy Utility Payments</label>
                                                            <input className="form-control" type="text" ref={this.noOfMonthsEnergyUtilityPaymentsRef} onChange={this.updateNoOfMonthsEnergyUtilityPayments} value={this.state.noOfMonthsEnergyUtilityPayments} maxlength="2" ></input>
                                                            <div class="alert-small-text">{this.state.noOfMonthsEnergyUtilityPayments_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Energy Type of Assistance </label>
                                                            <select className="form-control" ref={(input) => this.energyTypeOfAssistanceSelector = input} onChange={this.updateEnergyTypeOfAssistance} value={this.state.energyTypeOfAssistance}>
                                                                <option value="">-- select --</option>
                                                                <option value="Payment">Payment</option>
                                                                <option value="Payment Arrears">Payment Arrears</option>
                                                            </select>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Amount Due for Energy </label>
                                                            <Tooltip title="Maximum allowed value is 99999.99">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" ref={this.amountDueforEnergyRef} onChange={this.updateDollarAmountDueForEnergy} value={this.state.amountDueforEnergy} maxlength="8" max="99999.99"></input>
                                                            <div className="alert-small-text">{this.state.amountDueforEnergy_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Energy Amount Covered </label>
                                                            <Tooltip title="$ amount approved for payout. Maximum allowed value is 99999.99">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" ref={this.energyAmountRef} onChange={this.updateEnergyAmount} value={this.state.energyAmount} maxlength="8" max="99999.99"></input>
                                                            <div class="alert-small-text">{this.state.energyAmount_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>% of Energy Bill </label>
                                                            <Tooltip title="Calculated based on Amount Due for Energy and Energy Amount Covered">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" ref={this.percentageOfEnergyBillRef} readOnly maxlength="2" onChange={this.updatePercentageOfEnergyBill} value={this.state.percentageOfEnergyBill}></input>
                                                            <div class="alert-small-text">{this.state.percentageOfEnergyBill_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Energy Bank Name </label>
                                                            <input className="form-control" onChange={this.updateEnergyBankName} value={this.state.energyBankName} maxlength="100"></input>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Banking Account Number </label>
                                                            <Tooltip title="Energy Utility Provider's Banking Account Number">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" ref={this.energyUtilityProviderAccountNoRef} onChange={this.updateEnergyProvidersBankingAccountNumber} value={this.state.energyUtilityProviderAccountNo} maxlength="100"></input>
                                                            <div class="alert-small-text">{this.state.energyUtilityProviderAccountNo_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Energy Routing Number </label>
                                                            <input className="form-control" type="text" ref={this.energyRoutingNumberRef} onChange={this.updateEnergyRoutingNumber} value={this.state.energyRoutingNumber} maxlength="100" ></input>
                                                            <div class="alert-small-text">{this.state.energyRoutingNumber_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-3">
                                                            <label>Energy Memo - Account Number</label>
                                                            <input className="form-control" type="text" ref={this.energyMemoAccountNoRef} onChange={this.updateEnergyMemoAccountNumber} value={this.state.energyMemoAccountNo} maxlength="100" ></input>
                                                            <div class="alert-small-text">{this.state.energyMemoAccountNo_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Energy Utility Tax I.D. </label>
                                                            <Tooltip title="Must Include if Applicable">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="text" ref={this.energyUtilityTaxIDRef} onChange={this.updateEnergyUtilityTaxID} value={this.state.energyUtilityTaxID} maxlength="10" ></input>
                                                            <div class="alert-small-text">{this.state.energyUtilityTaxID_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-3">
                                                            <label>Energy Utility DUNS Number </label>
                                                            <Tooltip title="Must Include if Applicable">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="text" ref={this.energyUtilityDUNSNumberRef} onChange={this.updateEnergyUtilityDUNSNumber} value={this.state.energyUtilityDUNSNumber} maxlength="10" ></input>
                                                            <div class="alert-small-text">{this.state.energyUtilityDUNSNumber_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Energy Payment Status </label>
                                                            <select className="form-control" id="energyPaymentStatusId" ref={(input) => this.energyPaymentStatusSelector = input} onChange={this.updateEnergyPaymentStatus} value={this.state.energyPaymentStatus}>
                                                                <option value="">-- select --</option>
                                                                <option value="Pending">Pending</option>
                                                                <option value="In-Review">In-Review</option>
                                                                <option value="Approved for Payment">Approved for Payment</option>
                                                                <option value="N/A">N/A</option>
                                                                {/* <option value="Issued">Issued</option> */}
                                                            </select>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="box-gray mb-4">
                                                <h2 className="profile-title"><i class="fas fa-money-check-alt mr-2"></i>Individual Payment Details</h2>
                                                <div className="box-details">
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Payment Reason</label>
                                                            <select className="form-control" ref={(input) => this.individualPaymentReasonSelector = input} onChange={this.updateIndividualPaymentReason} value={this.state.individualPaymentReason}>
                                                                <option value="">-- select --</option>
                                                                <option value="Rent">Rent</option>
                                                                <option value="Electric">Electric</option>
                                                                <option value="Gas">Gas</option>
                                                            </select>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-3">
                                                            <label>Individual Payment Amount</label>
                                                            <Tooltip title="Maximum allowed value is 99999.99">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="text" ref={this.individualPaymentAmountRef} onChange={this.updateIndividualPaymentAmount} value={this.state.individualPaymentAmount} maxlength="8" max="99999.99" ></input>
                                                            <div class="alert-small-text">{this.state.individualPaymentAmount_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-3">
                                                            <label>Memo - Property Address </label>
                                                            <input className="form-control" type="text" ref={this.individualMemoPropertyAddressRef} onChange={this.updateIndividualMemoPropertyAddress} maxlength="100" value={this.state.individualMemoPropertyAddress}></input>
                                                            <div className="alert-small-text">{this.state.individualMemoPropertyAddress_Error}</div>
                                                        </div>

                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Individual Bank Name </label>
                                                            <input className="form-control" onChange={this.updateIndividualBankName} value={this.state.individualBankName} maxlength="50"></input>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Individual Account Number </label>
                                                            <input className="form-control" ref={this.individualAccountNumberRef} onChange={this.updateIndividualAccountNumber} value={this.state.individualAccountNumber} maxlength="100" ></input>
                                                            <div class="alert-small-text">{this.state.individualAccountNumber_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Individual Routing Number </label>
                                                            <input className="form-control" type="text" ref={this.individualRoutingNumberRef} onChange={this.updateIndividualRoutingNumber} value={this.state.individualRoutingNumber} maxlength="100" ></input>
                                                            <div class="alert-small-text">{this.state.individualRoutingNumber_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-3">
                                                            <label>Individual Payment Status</label>
                                                            <select className="form-control" id="individualPaymentStatusId" ref={(input) => this.individualPaymentStatusSelector = input} onChange={this.updateIndividualPaymentStatus} value={this.state.individualPaymentStatus}>
                                                                <option value="">-- select --</option>
                                                                <option value="Pending">Pending</option>
                                                                <option value="In-Review">In-Review</option>
                                                                <option value="Approved for Payment">Approved for Payment</option>
                                                                <option value="N/A">N/A</option>
                                                                {/* <option value="Issued">Issued</option> */}
                                                            </select>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label></label>
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" id="individualACHReceivedId" class="custom-control-input" onChange={this.onIndividualACHReceivedChange} />
                                                                <label class="custom-control-label" for="individualACHReceivedId">
                                                                    ACH Received
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="box-gray mb-4" style={{ display: 'none' }}>
                                                <h2 className="profile-title"><i class="fas fa-money-check-alt mr-2"></i>AB832 Additional Payment</h2>
                                                <div className="box-details">
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Other Payee Name </label>
                                                            <select disabled className="form-control" ref={this.otherPayeeNameRef} onChange={this.updateOtherPayeeName} value={this.state.otherPayeeName}>
                                                                <option value="">-- select --</option>
                                                                <option value="Tenant">Tenant</option>
                                                                <option value="Landlord">Landlord</option>
                                                            </select>
                                                            <div class="alert-small-text">{this.state.otherPayeeName_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Payment Description </label>
                                                            <select disabled className="form-control" ref={this.otherDesriptionRef} onChange={this.updateOtherDesription} value={this.state.otherDesription}>
                                                                <option value="">-- select --</option>
                                                                <option value="AB832">AB832</option>
                                                            </select>
                                                            <div class="alert-small-text">{this.state.otherDesription_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-3">
                                                            <label>Additional Payment Amount</label>
                                                            <Tooltip title="Maximum allowed value is 99999.99">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input disabled className="form-control" type="text" ref={this.otherPaymentAmountRef} onChange={this.updateOtherPaymentAmount} value={this.state.otherPaymentAmount} maxlength="8" max="99999.99" ></input>
                                                            <div class="alert-small-text">{this.state.otherPaymentAmount_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-3">
                                                            <label>Memo - Property Address </label>
                                                            <input disabled className="form-control" type="text" ref={this.otherMemoPropertyAddressRef} onChange={this.updateOtherMemoPropertyAddress} maxlength="255" value={this.state.otherMemoPropertyAddress}></input>
                                                            <div className="alert-small-text">{this.state.otherMemoPropertyAddress_Error}</div>
                                                        </div>

                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Other Bank Name </label>
                                                            <input disabled className="form-control" onChange={this.updateOtherBankName} value={this.state.otherBankName} maxlength="100"></input>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Other Account Number </label>
                                                            <input disabled className="form-control" ref={this.otherAccountNumberRef} onChange={this.updateOtherAccountNumber} value={this.state.otherAccountNumber} maxlength="100" ></input>
                                                            <div class="alert-small-text">{this.state.otherAccountNumber_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Other Routing Number </label>
                                                            <input disabled className="form-control" type="text" ref={this.otherRoutingNumberRef} onChange={this.updateOtherRoutingNumber} value={this.state.otherRoutingNumber} maxlength="100" ></input>
                                                            <div class="alert-small-text">{this.state.otherRoutingNumber_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-3">
                                                            <label>Other Payment Status</label>
                                                            <select disabled className="form-control" id="otherPaymentStatusId" ref={(input) => this.otherPaymentStatusSelector = input} onChange={this.updateOtherPaymentStatus} value={this.state.otherPaymentStatus}>
                                                                <option value="">-- select --</option>
                                                                <option value="Pending">Pending</option>
                                                                <option value="In-Review">In-Review</option>
                                                                <option value="Approved for Payment">Approved for Payment</option>
                                                                <option value="N/A">N/A</option>
                                                                {/* <option value="Issued">Issued</option> */}
                                                            </select>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* reconci section start */}
                                            <div className="box-gray mb-4">
                                                <h2 class="profile-title d-flex align-items-center justify-content-between"><span><i class="fas fa-handshake mr-2"></i>Manage reconciliation</span><span className="property-grid-add">
                                                    <button type="button" class="btn btn-primary" onClick={this.openAddPropertyPopup.bind(this)} ><i class="fas fa-plus-circle text-white mr-1"></i>Add</button></span></h2>
                                                <div className="box-details">
                                                    <div className='property-grid table-responsive'>

                                                        <table className={"table " + (this.state.reconciliationList && this.state.reconciliationList.length > 3 ? "table-scroll" : "")}>
                                                            <thead>
                                                                <tr>
                                                                    <th width="15%" scope="col">Initial Receiver</th>
                                                                    <th width="15%" scope="col">Paid By</th>
                                                                    <th width="15%" scope="col">Transaction Type</th>
                                                                    <th width="10%" scope="col">Before reconciliation</th>
                                                                    <th width="15%" scope="col">Amount (in $)</th>
                                                                    <th width="10%" scope="col">After reconciliation</th>
                                                                    <th width="5%"></th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.reconciliationList.length == 0 ?
                                                                    <p class="text-center"> No record </p> : null
                                                                }
                                                                {this.state.reconciliationList && this.state.reconciliationList.map((member, index) => (
                                                                    <tr>

                                                                        <td width="15%">
                                                                            {member.entityType}
                                                                        </td>

                                                                        <td width="15%">
                                                                            {member.initiallyPaidBy}
                                                                        </td>

                                                                        <td width="15%">
                                                                            {member.amountType}
                                                                        </td>
                                                                        <td width="10%">
                                                                            {member.amountCoveredBeforeRec}
                                                                        </td>
                                                                        <td width="15%">
                                                                            {member.amount}
                                                                        </td>
                                                                        <td width="10%">
                                                                            {member.amountCoveredAfterRec}
                                                                        </td>

                                                                        <td width="5%">
                                                                            <a className="border-0" onClick={this.editReconciliation.bind(this, member.id)}><i class="fas fa-edit mr-2"></i></a>
                                                                            <a className="border-0" onClick={this.deleteRec.bind(this, member.id)}><i class="fas fa-trash-alt red"></i></a>
                                                                        </td>
                                                                    </tr>

                                                                ))}

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* rec section end */}
                                            <div className="box-gray mb-4">
                                                <h2 class="profile-title d-md-flex align-items-center justify-content-between">
                                                    <span><i class="fas fa-file mr-2"></i>Tenant Documents</span>
                                                    <div class="property-grid-add">
                                                        <div class="row">
                                                            <label class="col-auto ml-3 pt-2" style={{ fontSize: '14px', color: '#000', textTransform: 'initial', fontWeight: '500' }} style={{ display: (this.state.applicantDocUploadStatus_Original === 'Ready') ? 'block' : 'none' }} >Information Received: {this.state.dateApplicantDocumentSubmitted_Formatted + ' ' + this.state.timeApplicantDocumentSubmitted}</label>
                                                            <label class="col-auto col-form-label px-0">Document Status </label>
                                                            <div class="col-auto">
                                                                <select class="form-control w-100" ref={(input) => this.documentStatusSelector = input} onChange={this.updateTenantDocumentStatus} value={this.state.applicantDocUploadStatus}>
                                                                    <option value="">-- select --</option>
                                                                    <option value="Pending">Pending</option>
                                                                    <option value="Ready">Ready</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* <span class="property-grid-add d-flex">
                                                            {this.state.applicantDocUploadStatus === "Ready" ? 
                                                            (
                                                                <Tooltip title="Documents are ready for review">
                                                                    <lable className="fas fa-check-circle text-success mr-2 mb-2 ml-2 position-static">  READY FOR REVIEW</lable>
                                                                </Tooltip>
                                                            ) : 
                                                            (
                                                                <Tooltip title="Documents are pending to be submitted by applicant">
                                                                    <lable className="fas fa-exclamation-triangle mr-2 mb-2 ml-2 position-static" style={{color:'#FFA833 !important'}} >  PENDING</lable>
                                                                </Tooltip>
                                                            )}
                                                        </span> */}
                                                </h2>

                                                <div className="box-details">
                                                    <div className="form-row">
                                                        <div className="table-responsive">
                                                            <table class="table table-bordered table-cgreen mt-3">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col" width="35%">Document</th>
                                                                        <th scope="col" width="25%">File Name</th>
                                                                        <th scope="col" width="25%">Uploaded Date Time</th>
                                                                        <th scope="col">Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr id="photo_identification_id">
                                                                        <td>Photo Identification<div id="photo_identification_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td><input class="form-control-sm form-control-file pl-0" type="file" id="photo_identification_upload_id" /></td>
                                                                    </tr>
                                                                    <tr id="secondary_identification_id">
                                                                        <td>Secondary Identification, if applicable<div id="secondary_identification_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td><input class="form-control-sm form-control-file pl-0" type="file" id="secondary_identification_upload_id" /></td>
                                                                    </tr>
                                                                    <tr id="rental_agreement_id">
                                                                        <td>Rental Lease Agreement<div id="rental_agreement_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td><input class="form-control-sm form-control-file pl-0" type="file" id="rental_agreement_upload_id" /></td>
                                                                    </tr>
                                                                    <tr id="secondary_proof_of_residential_tenancy_id">
                                                                        <td>Secondary Proof of Residential Tenancy, if applicable<div id="secondary_proof_of_residential_tenancy_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td><input class="form-control-sm form-control-file pl-0" type="file" id="secondary_proof_of_residential_tenancy_upload_id" /></td>
                                                                    </tr>
                                                                    <tr id="proof_of_past_due_rent_amount_id">
                                                                        <td>Proof of Past Due Rent Amount<div id="proof_of_past_due_rent_amount_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td><input class="form-control-sm form-control-file pl-0" type="file" id="proof_of_past_due_rent_amount_upload_id" /></td>
                                                                    </tr>
                                                                    <tr id="proof_of_unemployment_id">
                                                                        <td>Proof of Unemployment and/or Financial Hardship Due to COVID-19<div id="proof_of_unemployment_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td><input class="form-control-sm form-control-file pl-0" type="file" id="proof_of_unemployment_upload_id" /></td>
                                                                    </tr>
                                                                    <tr id="supplemental_proofof_unemployment_id">
                                                                        <td>Supplemental Proof of Unemployment and/or Financial Hardship Due to COVID-19<div id="supplemental_proofof_unemployment_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td><input class="form-control-sm form-control-file pl-0" type="file" id="supplemental_proofof_unemployment_upload_id" /></td>
                                                                    </tr>
                                                                    <tr id="proof_of_Annual_2020_household_income_id">
                                                                        <td>Proof of Annual 2020 Household Income for All Household Members<div id="proof_of_Annual_2020_household_income_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td><input class="form-control-sm form-control-file pl-0" type="file" id="proof_of_Annual_2020_household_income_upload_id" /></td>
                                                                    </tr>
                                                                    <tr id="supplemental_proofof_Annual_2020_household_income_id">
                                                                        <td>Supplemental Proof of Annual 2020 Household Income for All Household Members<div id="supplemental_proofof_Annual_2020_household_income_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td><input class="form-control-sm form-control-file pl-0" type="file" id="supplemental_proofof_Annual_2020_household_income_upload_id" /></td>
                                                                    </tr>
                                                                    <tr id="participation_agreement_id">
                                                                        <td>City of Irvine, Tenant Applicant, Participation Agreement<div id="participation_agreement_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td><input class="form-control-sm form-control-file pl-0" type="file" id="participation_agreement_upload_id" /></td>
                                                                    </tr>
                                                                    <tr id="authorization_to_release_information_to_SSA_id">
                                                                        <td>Authorization to Release Information to Social Services Agency (for all other Housing Authorities)<div id="authorization_to_release_information_to_SSA_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td><input class="form-control-sm form-control-file pl-0" type="file" id="authorization_to_release_information_to_SSA_upload_id" /></td>
                                                                    </tr>
                                                                    <tr id="authorization_to_release_information_to_HUD_id">
                                                                        <td>Authorization to Release Information to Housing and Urban Development (for OC Housing Authority)<div id="authorization_to_release_information_to_HUD_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td><input class="form-control-sm form-control-file pl-0" type="file" id="authorization_to_release_information_to_HUD_upload_id" /></td>
                                                                    </tr>
                                                                    <tr id="consent_to_exchange_information_id">
                                                                        <td>Consent to Exchange Information with Homeless Management Information System and other parties such as Landlord<div id="consent_to_exchange_information_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td><input class="form-control-sm form-control-file pl-0" type="file" id="consent_to_exchange_information_upload_id" /></td>
                                                                    </tr>
                                                                    <tr id="ami_household_income_worksheet_id">
                                                                        <td>AMI-Household Income Worksheet<div id="ami_household_income_worksheet_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td><input class="form-control-sm form-control-file pl-0" type="file" id="ami_household_income_worksheet_upload_id" /></td>
                                                                    </tr>
                                                                    <tr id="tenant_payment_agreement_id">
                                                                        <td>Tenant Payment Agreement<div id="tenant_payment_agreement_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td><input class="form-control-sm form-control-file pl-0" type="file" id="tenant_payment_agreement_upload_id" /></td>
                                                                    </tr>

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="box-gray mb-4">
                                                <h2 class="profile-title d-md-flex align-items-center justify-content-between">
                                                    <span><i class="fas fa-file mr-2"></i>Landlord Documents</span>
                                                    <div class="property-grid-add">
                                                        <div class="row">
                                                            <label class="col-auto ml-3 pt-2" style={{ fontSize: '14px', color: '#000', textTransform: 'initial', fontWeight: '500' }} style={{ display: this.state.landlordInfoRequestedStatus_Original === "Ready" ? 'block' : 'none' }} id="landlordSubmittedDateId" >Information Received: {this.state.dateLandlordDocumentSubmitted_Formatted + ' ' + this.state.timeLandlordDocumentSubmitted}</label>
                                                            <label class="col-auto col-form-label px-0">Document Status </label>
                                                            <div class="col-auto">
                                                                <select class="form-control w-100" ref={(input) => this.landlordDocumentStatusSelector = input} onChange={this.updateLandlordInfoRequestedStatus} value={this.state.landlordInfoRequestedStatus}>
                                                                    <option value="">-- select --</option>
                                                                    <option value="Pending">Pending</option>
                                                                    <option value="Info Requested">Info Requested</option>
                                                                    <option value="Reminder Sent">Reminder Sent</option>
                                                                    <option value="Ready">Ready</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* <span class="property-grid-add d-flex">
                                                            {this.state.applicantDocUploadStatus === "Ready" ? 
                                                            (
                                                                <Tooltip title="Documents are ready for review">
                                                                    <lable className="fas fa-check-circle text-success mr-2 mb-2 ml-2 position-static">  READY FOR REVIEW</lable>
                                                                </Tooltip>
                                                            ) : 
                                                            (
                                                                <Tooltip title="Documents are pending to be submitted by applicant">
                                                                    <lable className="fas fa-exclamation-triangle mr-2 mb-2 ml-2 position-static" style={{color:'#FFA833 !important'}} >  PENDING</lable>
                                                                </Tooltip>
                                                            )}
                                                        </span> */}
                                                </h2>


                                                <div className="box-details">
                                                    <div className="form-row">
                                                        <div className="table-responsive">
                                                            <table class="table table-bordered table-cgreen mt-3">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col" width="35%">Document</th>
                                                                        <th scope="col" width="25%">File Name</th>
                                                                        <th scope="col" width="25%">Uploaded Date Time</th>
                                                                        <th scope="col">Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr id="third_party_payer_agreement_id">
                                                                        <td>Third Party Approval Document<div id="third_party_payer_agreement_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td><input class="form-control-sm form-control-file pl-0" type="file" id="third_party_payer_agreement_upload_id" /></td>
                                                                    </tr>
                                                                    <tr id="landlord_w9_id">
                                                                        <td>Landlord W9<div id="landlord_w9_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td><input class="form-control-sm form-control-file pl-0" type="file" id="landlord_w9_upload_id" /></td>
                                                                    </tr>
                                                                    <tr id="landlord_participationagreement_id">
                                                                        <td>Landlord Participation Agreement <div id="landlord_participationagreement_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td><input class="form-control-sm form-control-file pl-0" type="file" id="landlord_participationagreement_upload_id" /></td>
                                                                    </tr>
                                                                    <tr id="verified_checkpoint_id">
                                                                        <td>CheckPoint ID Verification Page <div id="verified_checkpoint_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td><input class="form-control-sm form-control-file pl-0" type="file" id="verified_checkpoint_upload_id" /></td>
                                                                    </tr>
                                                                    <tr id="landlord_driver_license_id">
                                                                        <td>Driver’s License or other government issued photo ID<div id="landlord_driver_license_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td><input class="form-control-sm form-control-file pl-0" type="file" id="landlord_driver_license_upload_id" /></td>
                                                                    </tr>
                                                                    <tr id="management_agreement_id">
                                                                        <td>Management Agreement<div id="management_agreement_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td><input class="form-control-sm form-control-file pl-0" type="file" id="management_agreement_upload_id" /></td>
                                                                    </tr>
                                                                    <tr id="landlord_payment_agreement_id">
                                                                        <td>Landlord Payment Agreement<div id="landlord_payment_agreement_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td><input class="form-control-sm form-control-file pl-0" type="file" id="landlord_payment_agreement_upload_id" /></td>
                                                                    </tr>

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="box-gray mb-4">
                                                <h2 className="profile-title"><i class="fas fa-sticky-note mr-2"></i>Notes</h2>
                                                <div className="box-details">
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6 col-xl-12">
                                                            <textarea rows="20" id="notesTextArea" className="form-control h-auto" defaultValue={this.state.notes} onChange={this.updateNotes} value={this.state.notes} maxlength="15000"></textarea>
                                                            <div class="alert-small-text">{this.state.notes_Error}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="assistoc-speeddial">
                                                {this.state.mode && this.state.mode == "Admin" ?
                                                    <Tooltip title="Cancel" arrow>
                                                        <Button type="button" onClick={this.handleBack} className="btn btn-primary whoc-float-btn">
                                                            <i className="fas fa-times"></i>
                                                        </Button>
                                                    </Tooltip>
                                                    : null
                                                }
                                                {this.state.mode && this.state.mode == "Admin" ?
                                                    <Tooltip title="Save" arrow>
                                                        <Button type="button" onClick={this.handleUpdate} className="btn btn-secondary ml-2 whoc-float-btn">
                                                            <i className="fas fa-save" ></i>
                                                        </Button>
                                                    </Tooltip>
                                                    : null
                                                }
                                            </div>

                                            <Backdrop className="MuiBackdrop-root" open={this.state.isLoading} >
                                                <CircularProgress />
                                            </Backdrop>
                                            <Snackbar anchorOrigin={{ vertical: this.state.snackbarVertical, horizontal: this.state.snackbarHorizontal }}
                                                open={this.state.openSnackbar}
                                                autoHideDuration={3000}
                                                severity={this.state.snackbarSeverity}
                                                onClose={() => this.setState({ openSnackbar: false })}
                                                message={this.state.snackbarMessage}
                                                className={this.state.snackbarMessageClass}
                                                key={this.state.snackbarVertical + this.state.snackbarHorizontal}
                                            />
                                            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                <div class="modal-dialog modal-lg">
                                                    <div class="modal-content">
                                                        <div class="modal-header bg-primary">
                                                            <h5 class="modal-title text-white" id="exampleModalLabel">Manage reconciliation</h5>
                                                            <button type="button" class="close text-white op-1" data-dismiss="modal" aria-label="Close">
                                                                <span aria-hidden="true">&times;</span>
                                                            </button>
                                                        </div>
                                                        <div class="modal-body">
                                                            <form id="recform">
                                                                <div class="form-row">
                                                                    <div class="form-group col-sm-6 col-lg-4">
                                                                        <label>Initial Receiver </label>
                                                                        <select className="form-control" disabled={this.state.reconciliationMode == "edit" ? true : false} onChange={this.handleReconciliationChanges.bind(this, "entityType")} value={this.state.reconciliation.entityType} >
                                                                            <option value="">-- select --</option>
                                                                            <option value="Landlord">Landlord</option>
                                                                            <option value="Electric">Electric</option>
                                                                            <option value="Gas">Gas</option>
                                                                            <option value="Water/Sewer">Water/Sewer</option>
                                                                            <option value="Trash">Trash</option>
                                                                            <option value="Energy">Energy</option>
                                                                            <option value="Individual">Individual</option>
                                                                        </select>
                                                                        <div class="alert-small-text" id="valEntityType">{this.state.validationErrors["entityType"]}</div>
                                                                    </div>

                                                                    <div class="form-group col-sm-6 col-lg-4">
                                                                        <label>Paid By </label>
                                                                        <select className="form-control" disabled={this.state.isChildRecord ? true : false} onChange={this.handleReconciliationChanges.bind(this, "initiallyPaidBy")} value={this.state.reconciliation.initiallyPaidBy} >
                                                                            <option value="">-- select --</option>
                                                                            <option value="OCUW">OCUW</option>
                                                                            <option value="3rd Party">3rd Party</option>
                                                                        </select>
                                                                        <div class="alert-small-text" id="valInitiallyPaidBy">{this.state.validationErrors["initiallyPaidBy"]}</div>
                                                                    </div>

                                                                    <div class="form-group col-md-6 col-lg-4">
                                                                        <label>Transaction Type </label>
                                                                        <select className="form-control" disabled={this.state.isChildRecord ? true : false} onChange={this.handleReconciliationChanges.bind(this, "amountType")} value={this.state.reconciliation.amountType} >
                                                                            <option value="">-- select --</option>
                                                                            <option value="Paid">Paid</option>
                                                                            <option value="Adjustment(Positive)">Adjustment (Positive)</option>
                                                                            <option value="Return">Return</option>
                                                                            <option value="Adjustment(Negative)">Adjustment (Negative)</option>
                                                                        </select>
                                                                        <div class="alert-small-text">{this.state.validationErrors["amountType"]}</div>
                                                                    </div>
                                                                    <div class="form-group col-sm-6 col-lg-4">
                                                                        <label>Amount paid before reconciliation</label>
                                                                        <input disabled className="form-control" type="text" maxlength="100" onChange={this.handleReconciliationChanges.bind(this, "amountCoveredBeforeRec")} value={this.state.reconciliation.amountCoveredBeforeRec}></input>
                                                                        <span class="alert-small-text">{this.state.validationErrors["amountCoveredBeforeRec"]}</span>
                                                                    </div>
                                                                    <div class="form-group col-sm-6 col-lg-4">
                                                                        <label>Amount (in $)</label>
                                                                        <input className="form-control" disabled={this.state.isChildRecord ? true : false} type="text" maxlength="100" onChange={this.handleReconciliationChanges.bind(this, "amount")} value={this.state.reconciliation.amount}></input>
                                                                        <span class="alert-small-text">{this.state.validationErrors["amount"]}</span>
                                                                    </div>

                                                                    <div class="form-group col-sm-6 col-lg-4">
                                                                        <label>Amount paid after reconciliation</label>
                                                                        <input disabled className="form-control" type="text" maxlength="100" onChange={this.handleReconciliationChanges.bind(this, "amountCoveredAfterRec")} value={this.state.reconciliation.amountCoveredAfterRec}></input>
                                                                        <span class="alert-small-text">{this.state.validationErrors["amountCoveredAfterRec"]}</span>
                                                                    </div>
                                                                    <div class="form-group col-md-6 col-lg-4">
                                                                        <label>Payment Method </label>
                                                                        <input className="form-control" onChange={this.handleReconciliationChanges.bind(this, "paymentMethod")} value={this.state.reconciliation.paymentMethod} maxlength="100"></input>
                                                                        <span class="alert-small-text">{this.state.validationErrors["paymentMethod"]}</span>
                                                                    </div>
                                                                    <div className="form-group col-md-6 col-lg-4">
                                                                        <label>Memo - Property Address </label>
                                                                        <input className="form-control" type="text" onChange={this.handleReconciliationChanges.bind(this, "memoPropertyAddress")} maxlength="100" value={this.state.reconciliation.memoPropertyAddress}></input>
                                                                        <span class="alert-small-text">{this.state.validationErrors["memoPropertyAddress"]}</span>
                                                                    </div>
                                                                    <div class="form-group col-sm-6 col-lg-4">
                                                                        <label>Other Payment Status</label>
                                                                        <select className="form-control" id="otherPaymentStatusId" onChange={this.handleReconciliationChanges.bind(this, "otherPaymentStatus")} value={this.state.reconciliation.otherPaymentStatus}>
                                                                            <option value="">-- select --</option>
                                                                            <option value="Pending">Pending</option>
                                                                            <option value="In-Review">In-Review</option>
                                                                            <option value="Approved for Payment">Approved for Payment</option>
                                                                            <option value="N/A">N/A</option>
                                                                            <option value="Issued">Issued</option>
                                                                        </select>
                                                                        <span class="alert-small-text">{this.state.validationErrors["otherPaymentStatus"]}</span>
                                                                    </div>
                                                                    <div class="form-group col-sm-6 col-lg-4">
                                                                        <label>Transaction ID</label>
                                                                        <input className="form-control" type="text" maxlength="100" onChange={this.handleReconciliationChanges.bind(this, "transactionNo")} value={this.state.reconciliation.transactionNo}></input>
                                                                        <span class="alert-small-text">{this.state.validationErrors["transactionNo"]}</span>
                                                                    </div>

                                                                    <div class="form-group col-md-6 col-xl-12">
                                                                        <label>Notes</label>
                                                                        <textarea rows="10" id="notesTextArea" className="form-control h-auto" onChange={this.handleReconciliationChanges.bind(this, "notes")} value={this.state.reconciliation.notes}></textarea>
                                                                        <div class="alert-small-text">{this.state.validationErrors["notes"]}</div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                        <div class="modal-footer">
                                                            <button type="button" class="btn btn-primary" onClick={this.saveReconciliation.bind(this)}>Save</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    </div>
                                </fieldset>
                            </div>
                        </div>

                    </div>
                    {/* content area ends */}

                    {/* </div>
                    </main> */}
                    {/* main content ends */}
                    <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-lg">
                            <div class="modal-content">
                                <div class="modal-header bg-primary">
                                    <h5 class="modal-title text-white" id="exampleModalLabel">Delete reconciliation</h5>
                                    <button type="button" class="close text-white op-1" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    Are you sure you want to delete this reconciliation record?
                                </div>
                                <div class="modal-footer">
                                    {/* <button type="button" class="btn btn-secondary btn-light" data-dismiss="modal">Close</button> */}
                                    <button type="button" class="btn btn-primary" onClick={this.performDelete}>Yes</button>
                                    <button type="button" class="btn btn-primary" onClick={this.closeMsg}>No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        else {
            return null;
        }
    }
};

export default IrvineClaimDetails;
