import { Button, CircularProgress } from '@material-ui/core';
import React from "react";
import MUIDataTable from "mui-datatables";
import { API, Auth, Storage } from 'aws-amplify';
import { isMobile } from 'react-device-detect';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import CustomToolbar from "../customToolbar";
import Tooltip from '@material-ui/core/Tooltip';
import * as cloneDeep from 'lodash/cloneDeep';
import * as find from 'lodash/find';
import {
    FormGroup,
    FormLabel,
    FormControl,
    ListItemText,
    TextField,
    Checkbox,
    FormControlLabel,
    Grid,
    Select,
    InputLabel,
    MenuItem
} from '@material-ui/core';
import ReactDOM from 'react-dom';
import Backdrop from '@material-ui/core/Backdrop';

import imgLogo from '../imgs/logo-assist-oc.png';
import TableViewCol from "../TableViewCol";
import TableSearch from "../TableSearch";
import imgSearchclaim from '../imgs/search-claims.svg';
import SearchClaims from './searchClaims'
import {
    Route,
    NavLink,
} from "react-router-dom";
//import RecordDetails from './recordDetails';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Box from '@material-ui/core/Box';
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";

const headers = [
    "sc.uniqueID",
    "sr.roundNumber",
    "sc.firstName",
    "sc.lastName",
    "sr.entityType",
    "sr.amountType",
    "sr.amountCoveredBeforeRec",
    "sr.amount",
    "sr.amountCoveredAfterRec",
    "sr.initiallyPaidBy",
    "sr.dateCreated",
    "sr.timeCreated",
    "sr.dateModified",
    "sr.timeModified",
    "sr.createdBy",
    "sr.modifiedBy",
    "sr.paymentMethod",
    "sr.memoPropertyAddress",
    "sc.total1099Amount",
    "sr.otherPaymentStatus",
    "sr.transactionNo",
    "sr.notes"
];

class CeppClaimsReconciliation extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            expandRows: [],
           // permissions: [],
            // mode: "Admin",
            showCeppClaims: "none",
            showIrvineClaims: "none",
            showAddUser: "none",
            showSubmitClaim: "none",
            showLogin: "none",
            columns: [
                {
                    name: "uniqueID",
                    label: "Unique ID",
                    options: {
                        download: true,
                        display: true,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 12) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "100px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "100px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "roundNumber",
                    label: "Round Number",
                    options: {
                        download: true,
                        display: true,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                                <div style={{
                                    width: "50px",
                                    overflow: "hidden",
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    position: 'relative',
                                }}>
                                    {value}
                                </div>
                            );
                        },
                    },
                },
                {
                    name: "firstName",
                    label: "First",
                    options: {
                        download: true,
                        display: true,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 12) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "100px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "100px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "lastName",
                    label: "Last",
                    options: {
                        download: true,
                        display: true,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 12) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "100px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "100px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "entityType",
                    label: "Initial Receiver",
                    options: {
                        download: true,
                        display: true,
                        filterType: 'custom',
                        filterOptions: {
                            display: (filterList, onChange, index, column) => {
                                const optionValues = [['Landlord'],['Electric'],['Gas'],['Water/Sewer'],['Trash'],['Energy'],['Individual']];
                                return (
                                    <FormControl>
                                        <InputLabel>
                                        Initial Receiver
                                        </InputLabel>
                                        <Select
                                            value={filterList[index]}
                                            renderValue={selected => selected}
                                            onChange={event => {
                                                filterList[index] = event.target.value;
                                                onChange(filterList[index], index, column);
                                            }}
                                        >
                                            {optionValues.map(item => (
                                                <MenuItem key={item} value={item}>
                                                    <ListItemText primary={item} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                );
                            }
                        },
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 12) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "100px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "100px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "amountType",
                    label: "Transaction Type",
                    options: {
                        download: true,
                        display: true,
                        filterType: 'custom',
                        filterOptions: {
                            display: (filterList, onChange, index, column) => {
                                const optionValues = [['Paid'], ['Adjustment(Positive)'], ['Return'], ['Adjustment(Negative)']];
                                return (
                                    <FormControl>
                                        <InputLabel>
                                            Transaction Type
                                        </InputLabel>
                                        <Select
                                            value={filterList[index]}
                                            renderValue={selected => selected}
                                            onChange={event => {
                                                filterList[index] = event.target.value;
                                                onChange(filterList[index], index, column);
                                            }}
                                        >
                                            {optionValues.map(item => (
                                                <MenuItem key={item} value={item}>
                                                    <ListItemText primary={item} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                );
                            }
                        },
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 12) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "150px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "150px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "amountCoveredBeforeRec",
                    label: "Amount Covered Before Rec",
                    options: {
                        download: true,
                        display: true,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 12) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "70px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "70px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "amount",
                    label: "Amount",
                    options: {
                        download: true,
                        display: true,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 12) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "70px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "70px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "amountCoveredAfterRec",
                    label: "Amount Covered After Rec",
                    options: {
                        download: true,
                        display: true,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 12) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "70px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "70px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "initiallyPaidBy",
                    label: "Paid By",
                    options: {
                        download: true,
                        display: false,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 12) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "70px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "70px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "dateCreated",
                    label: "Date Created",
                    options: {
                        download: true,
                        display: false,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 12) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "100px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "100px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "timeCreated",
                    label: "Time Created",
                    options: {
                        download: true,
                        display: false,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 12) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "70px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "70px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "dateModified",
                    label: "Date Modified",
                    options: {
                        download: true,
                        display: false,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 12) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "100px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "100px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "timeModified",
                    label: "Time Modified",
                    options: {
                        download: true,
                        display: false,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 12) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "70px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "70px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "createdBy",
                    label: "Created By",
                    options: {
                        download: true,
                        display: false,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 12) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "170px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "170px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "modifiedBy",
                    label: "Modified By",
                    options: {
                        download: true,
                        display: false,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 12) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "170px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "170px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "paymentMethod",
                    label: "Payment Method",
                    options: {
                        download: true,
                        display: false,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 12) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "70px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "70px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "memoPropertyAddress",
                    label: "Memo Property Address",
                    options: {
                        download: true,
                        display: false,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 12) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "150px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "150px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "total1099Amount",
                    label: "Total 1099 Amount",
                    options: {
                        download: true,
                        display: false,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 12) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "70px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "70px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "otherPaymentStatus",
                    label: "Other Payment Status",
                    options: {
                        download: true,
                        display: false,
                        filterType: 'custom',
                        filterOptions: {
                            display: (filterList, onChange, index, column) => {
                                const optionValues = [['Pending'], ['In-Review'], ['Approved for Payment'], ['N/A'],['Issued']];
                                return (
                                    <FormControl>
                                        <InputLabel>
                                            Other Payment Status
                                        </InputLabel>
                                        <Select
                                            value={filterList[index]}
                                            renderValue={selected => selected}
                                            onChange={event => {
                                                filterList[index] = event.target.value;
                                                onChange(filterList[index], index, column);
                                            }}
                                        >
                                            {optionValues.map(item => (
                                                <MenuItem key={item} value={item}>
                                                    <ListItemText primary={item} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                );
                            }
                        },
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 12) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "140px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "140px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "transactionNo",
                    label: "Transaction No",
                    options: {
                        download: true,
                        display: false,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 12) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "70px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "70px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "notes",
                    label: "notes",
                    options: {
                        download: true,
                        display: false,
                        filter: false,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 12) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
            ],
            hideCompleted: 1,
            msg: "",
            email: null,
            showDetails: "none",
            dataheight: null,
            notes: "",
            files: null,
            file1: null,
            file2: null,
            cardValue: "500.00",
            file31: null,
            file32: null,
            file41: null,
            file42: null,
            rowSelected: [],
            data: [["Loading Data..."]],

            isLoading: false,
            page: 0,
            count: 1,
            rowsPerPage: 50,
            sortOrder: {
                name: 'uniqueID',                  // default sort by column dateUserCreated in desc order
                direction: 'asc'
            },
            filters: {},
            searchText: '',
            searchTimerID: 0,
            handleSearch: 0,
            downloadFile: 0,
            selectedRowFirstName: '',
            selectedRowLastName: '',
            options: {
                download: false,
                downloadOptions: {
                    filename: 'tableDownload.csv',
                    separator: ',',
                    filterOptions: {
                        useDisplayedColumnsOnly: true,          // downloads only columns that are visible (e.g. after filtering)
                        useDisplayedRowsOnly: false              // downloads only rows that are visible (e.g. after filtering)
                    }                                         // default false - downloads all data
                },
                selectableRows: false,
                selectableRowsOnClick: false,
                filter: true,
                sort: true,
                responsive: 'standard', //'scroll', //'scrollMaxHeight',
                serverSide: true,
                viewColumns: false,
                expandableRows: true,
                // setRowProps: row => {
                //     if (row[18].props && row[18].props.class === 'pstatus') {
                //         return {
                //             style: { background: '#ffe1b9' }
                //         };
                //     }
                // },
                renderExpandableRow: (rowData, rowMeta) => {
                    return (
                        //   <TableRow>
                        //     <TableCell colSpan={rowData.length}>
                        //      {rowData[8].props.children}
                        //     {/* Custom expandable row option. Data: {JSON.stringify(rowData)} */}
                        //     </TableCell>
                        //   </TableRow>
                        <tr>
                        <td colSpan={10}>
                            <TableContainer className="asoc-nested-table" component={Paper}>
                                <Table  aria-label="purchases">
                                    <TableBody>
                                    <TableRow>
                                            <TableCell><b>Paid By</b></TableCell>
                                            <TableCell><b>Payment Method</b></TableCell>
                                            <TableCell><b>Memo - Property Address</b></TableCell>
                                            <TableCell><b>Total 1099 Amount</b></TableCell>
                                            <TableCell><b>Transaction ID</b></TableCell>
                                            <TableCell><b>Other Payment Status</b></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell scope="row">
                                                {rowData[9]}
                                            </TableCell>
                                            <TableCell>  {rowData[16]}</TableCell>
                                            <TableCell> {rowData[17]}</TableCell>
                                            <TableCell>
                                                {rowData[18]}
                                            </TableCell>
                                            <TableCell>
                                                {rowData[20]}
                                            </TableCell>
                                            <TableCell>
                                                {rowData[19]}
                                            </TableCell>
                                           
                                        </TableRow>
                                        <TableRow>
                                            <TableCell><b>Date Created</b></TableCell>
                                            <TableCell><b>Time Created</b></TableCell>
                                            <TableCell><b>Date Modified</b></TableCell>
                                            <TableCell><b>Time Modified</b></TableCell>
                                            <TableCell><b>Created By</b></TableCell>
                                            <TableCell><b>Modified By</b></TableCell>
                                        </TableRow>
                                        <TableRow>
                                        <TableCell>
                                                {rowData[10]}
                                            </TableCell>
                                            <TableCell>
                                                {rowData[11]}
                                            </TableCell>
                                            <TableCell>
                                                {rowData[12]}
                                            </TableCell>
                                            <TableCell>
                                                {rowData[13]}
                                            </TableCell>
                                            <TableCell>
                                                {rowData[14]}
                                            </TableCell>
                                            <TableCell>
                                                {rowData[15]}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colspan="6"><b>Notes</b></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colspan="6" component="th" scope="row">
                                                {rowData[21].props.children === '' || rowData[21].props.children === null ? '-' : rowData[21]}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </td>
                    </tr>
                    );
                },
                // makes it so filters have to be "confirmed" before being applied to the 
                // table's internal filterList
                confirmFilters: true,

                // Calling the applyNewFilters parameter applies the selected filters to the table 
                customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
                    //console.log('customFilterDialogFooter');
                    return (
                        <div style={{ marginTop: '40px' }}>
                            <Button variant="contained" onClick={() => this.handleFilterSubmit(applyNewFilters)}>Apply Filters</Button>
                        </div>
                    );
                },

                // callback that gets executed when filters are confirmed
                onFilterConfirm: (filterList) => {
                    //console.log('onFilterConfirm');
                    console.dir("filterList: " + filterList);
                },

                onFilterDialogOpen: () => {
                    //console.log('filter dialog opened');
                },
                onFilterDialogClose: () => {
                    //console.log('filter dialog closed');
                },
                onFilterChange: (column, filterList, type) => {
                    //console.log('onFilterChange');
                    //console.log("column: " + column);
                    //console.log("filterList: " + filterList);
                    //console.log("type: " + type);
                    this.setState({ filters: filterList, page: 0 });
                    if (type === 'chip') {
                        var newFilters = () => (filterList);
                        //console.log('updating filters via chip');
                        this.handleFilterSubmit(newFilters);
                    }
                },

                pagination: true,
                count: 1,
                rowsPerPage: 50,
                rowsPerPageOptions: [10, 20, 50],
                sortOrder: {
                    name: 'dateImported',                  // default sort by column dateUserCreated in desc order
                    direction: 'desc'
                },

                onTableChange: (action, tableState) => {

                    //console.log(action, tableState);

                    // a developer could react to change on an action basis or
                    // examine the state as a whole and do whatever they want

                    switch (action) {
                        case 'changePage':
                            this.changePage(tableState.page, tableState.sortOrder);
                            break;
                        case 'sort':
                            this.sort(tableState.page, tableState.sortOrder);
                            break;
                        case 'changeRowsPerPage':
                            this.changeRowsPerPage(tableState.page, tableState.sortOrder, tableState.rowsPerPage);
                            break;
                        case 'search':
                            this.OnSearchTextChange(tableState.searchText);
                            break;
                        default:
                        //console.log('action not handled - ' + action);
                    }
                },
                onViewColumnsChange: (column, action) => {
                    //console.log(column, action);
                    if (column !== null) {
                        this.state.columns.filter(c => c.name === column)[0].options.display = (action === 'add') ? true : false;
                    }
                },
                onRowClick: (rowData, rowState) => {
                    this.setState({ rowClickProcessed: false });
                    console.log(rowData, rowState);

                    if (rowData[10] !== null && rowData[10].props !== undefined) {
                        if (rowData[10].props["title"] !== undefined) {
                            this.setState({
                                individualID: rowData[10].props["title"]
                            });
                        }
                        else {
                            this.setState({
                                individualID: rowData[10].props["children"]
                            });
                        }
                    }
                    else {
                        this.setState({
                            individualID: rowData[10]
                        });
                    }

                    console.log(rowData[10].props["children"]);
                    console.log(this.state.individualID);
                    this.setState({ rowClickProcessed: true });
                },
                fixedHeader: true,
                fixedSelectColumn: true,
                tableBodyHeight: 'calc(100vh - 242px)',
                print: false,
                customToolbar: () => {
                    return (
                        <CustomToolbar refreshCallback={this.OnRefreshClick.bind(this)} downloadCallback={this.OnDownloadClick.bind(this)}  isERAClaims={false} isRec={true} />
                    );
                },
                searchText: '',
                // searchProps: {
                //   onBlur: (e) => {
                //     //console.log('onBlur!');
                //     this.OnSearchIdleTimeout(this.state.searchText);
                //   },
                //   onKeyUp: (e) => {
                //     //console.log('onKeyUp!');
                //     if (e.keyCode === 13) { // Enter pressed
                //       this.OnSearchIdleTimeout(this.state.searchText);
                //     }
                //   },
                // },
                searchPlaceholder: 'Search Text',
                onSearchClose: () => {
                    //console.log('onSearchClose - ' + this.state.searchTimerID);
                    clearTimeout(this.state.searchTimerID);

                    this.setState({
                        isLoading: false,
                        handleSearch: 0,
                        searchText: '',
                    });

                    this.OnRefreshClick();
                },
                customSearchRender: (searchText, handleSearch, hideSearch, options) => {
                    return (
                        <TableSearch
                            searchText={searchText}
                            onSearch={handleSearch}
                            onHide={hideSearch}
                            options={options}
                            onSearchClick={this.OnSearchClick}
                        />
                    );
                },

                individualID: 0,
                rowClickProcessed: false,
            },

        }

    }

    getMuiTheme = () => createMuiTheme({
        overrides: {
            MUIDataTableToolbar: {
                actions: {
                    display: "flex",
                    flex: "initial",
                    alignItems: "center",
                    // move all icons to the right
                    "& > span, & > button": {
                        order: 99
                    },
                    // target the custom toolbar icon
                    "& > span:last-child, & > button:last-child": {
                        order: 1
                    },
                    "& > .MuiPaper-elevation1": {
                        order: 999
                    }
                    // // target any icon
                    // "& > span:nth-child(4), & > button:nth-child(4)": {
                    //   order: 2
                    // }
                }
            },
            MuiIconButton: {
                root: {
                    zIndex: "10",
                }
            },
            MuiTooltip: {
                tooltip: {
                    fontSize: "0.85em",
                }
            },
            MuiTableCell: {
                head: {
                    //width:'190px',
                }
            },
            MUIDataTableHeadCell: {
                root: {
                    // overflow: "hidden",
                    // whiteSpace: 'nowrap',
                    // textOverflow: 'ellipsis',
                    // position: 'relative',
                    fontWeight: "bold",
                }
            },
            MUIDataTableBodyCell: {
                root: {
                    // overflow: "hidden",
                    // whiteSpace: 'nowrap',
                    // textOverflow: 'ellipsis',
                    // position: 'relative',
                    //width:'200px'
                }
            },
            MUIDataTableBody: {
                root: {
                }
            },
            MUIDataTableColumn: {
                row: {
                }
            },
            MUIDataTablePagination: {
                root: {
                },
                caption: {
                }
            }
        }
    })

    getData = async () => {
        this.setState({ isLoading: true });
        // var retrievedObject = localStorage.getItem('retainObject');

        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayName;
        console.log(apiName);
        let path = '/getceppreconciliation';
        let myInit = {};

        var filterdict = {}; // create an object

        for (var ii = 0; ii < headers.length; ii++) {
            if (this.state.filters[ii] && this.state.filters[ii].length > 0) {
                filterdict[`${headers[ii]}`] = `${this.state.filters[ii]}`;
            }
        }

        //console.log("JSON filterdict: " + JSON.stringify(filterdict));

        myInit = { // OPTIONAL
            body: {
                "info": {
                    "page": this.state.page,
                    "sortField": this.state.sortOrder.name,
                    "sortDir": this.state.sortOrder.direction,
                    "rowsPerPage": this.state.rowsPerPage,
                    "downloadFile": this.state.downloadFile,
                    "filters": JSON.stringify(filterdict),
                    "handleSearch": this.state.handleSearch,
                    "searchText": this.state.searchText,
                }
            }, // replace this with attributes you need
            headers: {
                Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`
            }
        }
        return await API.post(apiName, path, myInit);
    }
    postData = async () => {
        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayName;
        let path = '/getsantaanaclaim';
        let myInit = { // OPTIONAL
            body: {
                "info": {
                    "email": this.state.email,
                    "adminUsername": user.attributes.email
                }
            }, // replace this with attributes you need
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
        }
        return await API.post(apiName, path, myInit);
    };

    componentDidMount = async () => {
        this.props.parentCallback("ERA-OC Reconciliation", "", imgSearchclaim);
        var height;
        if (isMobile) {
            height = "60vh";
        }
        else {
            height = "70vh"
        }
        var res = await this.getData();
        this.setState({ data: res.data, isLoading: false, page: res.page, count: res.total });
    }

    sort = (page, sortOrder) => {
        this.setState({ isLoading: true, sortOrder: sortOrder });
        this.getData().then(res => {
            this.setState({
                data: res.data,
                page: res.page,
                count: res.total,
                sortOrder: sortOrder,
                isLoading: false,
            });
        });
    }

    changePage = (page, sortOrder) => {
        this.setState({
            isLoading: true,
            page: page,
            sortOrder: sortOrder,
        });
        this.getData().then(res => {
            this.setState({
                isLoading: false,
                data: res.data,
                page: res.page,
                count: res.total,
                sortOrder: sortOrder,
            });
        });
    };

    changeRowsPerPage = (page, sortOrder, rowsPerPage) => {
        this.setState({
            isLoading: true,
            page: 0,
            rowsPerPage: rowsPerPage,
            sortOrder: sortOrder
        });
        this.getData().then(res => {
            this.setState({
                isLoading: false,
                sortOrder: sortOrder,
                data: res.data,
                page: res.page,
                count: res.total,
            });
        });
    };

    delay = () => {
        return new Promise((resolve, reject) => {
            window.setTimeout(
                () => {
                    resolve();
                },
                2000
            );
        });
    }

    testLoad = async () => {
        this.setState({ isLoading: true });

        while (true) {
            var res = await this.delay();
            if (this.state.rowClickProcessed === true) {
                break;
            }
        }

        console.log(this.state.individualID);
        this.setState({ isLoading: false, rowClickProcessed: false });

        // // Render the record details page
        // ReactDOM.render(<RecordDetails individualID={this.state.individualID} />, document.getElementById("root"));

        this.props.showRecordDetails(this.state.individualID);
    }

    OnSearchClick = async () => {
        this.OnSearchIdleTimeout(this.state.searchText)
    }

    refresh = async () => {
        //console.log("Refresh!!!");
        var res = await this.getData();
        this.setState({
            isLoading: false,
            data: res.data,
            page: res.page,
            count: res.total,
        })
    }

    OnRefreshClick = async () => {
        //console.log("OnRefreshClick!!!");
        await this.refresh();
    }

    componentDidUpdate() {
    }

    handleFilterSubmit = applyFilters => {
        let filterList = applyFilters();
        //console.log('handleFilterSubmit');
        //console.log('filterList: ' + filterList);
        this.setState({ isLoading: true, filters: filterList, page: 0 });
        this.getData().then(res => {
            this.setState({
                isLoading: false,
                data: res.data,
                page: res.page,
                count: res.total,
            });
        });
    };

    OnSearchTextChange(searchQuery) {
        this.setState({ searchText: searchQuery });
    }

    OnSearchIdleTimeout = async (searchQuery) => {
        if (searchQuery !== null && searchQuery !== 'undefined') {
            //console.log('OnSearchIdleTimeout - ' + searchQuery);

            this.setState({ searchText: searchQuery });

            this.setState({
                isLoading: true,
                handleSearch: 1,
                searchText: searchQuery
            });
            this.state.options.searchText = searchQuery;

            var res = await this.getData();

            //console.log('SearchResult');
            //console.log(res.data);

            this.setState({
                isLoading: false,
                //handleSearch: 0,
                //searchText: '',
                data: res.data,
                page: res.page,
                count: res.total,
            });
        }
    }

    escapeDangerousCSVCharacters(data) {
        if (typeof data === 'string') {
            // Places single quote before the appearance of dangerous characters if they
            // are the first in the data string.
            return data.replace(/^\+|^\-|^\=|^\@/g, "'$&");
        }

        return data;
    }

    buildCSV(columns, data, options) {
        const replaceDoubleQuoteInString = columnData =>
            typeof columnData === 'string' ? columnData.replace(/\"/g, '""') : columnData;

        const buildHead = bColumns => {
            return (
                bColumns
                    .reduce(
                        (soFar, column) =>
                            column.options.download
                                ? soFar +
                                '"' +
                                this.escapeDangerousCSVCharacters(replaceDoubleQuoteInString(column.label || column.name)) +
                                '"' +
                                options.downloadOptions.separator
                                : soFar,
                        '',
                    )
                    .slice(0, -1) + '\r\n'
            );
        };
        const CSVHead = buildHead(columns);

        //console.log('buildCSV');
        //console.log(columns);

        const buildBody = bData => {
            if (!bData.length) return '';
            return bData
                .reduce(
                    (soFar, row) =>
                        soFar +
                        '"' +
                        row.data
                            .filter((_, index) => columns[index].options.download)
                            .map(columnData => this.escapeDangerousCSVCharacters(replaceDoubleQuoteInString(columnData)))
                            .join('"' + options.downloadOptions.separator + '"') +
                        '"\r\n',
                    '',
                )
                .trim();
        };
        const CSVBody = buildBody(data);

        // const csv = options.onDownload
        //   ? options.onDownload(buildHead, buildBody, columns, data)
        //   : `${CSVHead}${CSVBody}`.trim();

        const csv = `${CSVHead}${CSVBody}`.trim();

        //console.log('csv');
        //console.log(csv);
        return csv;
    }

    downloadCSV(csv, filename) {
        const blob = new Blob([csv], { type: 'text/csv' });

        /* taken from react-csv */
        if (navigator && navigator.msSaveOrOpenBlob) {
            navigator.msSaveOrOpenBlob(blob, filename);
        } else {
            const dataURI = `data:text/csv;charset=utf-8,${csv}`;

            const URL = window.URL || window.webkitURL;
            const downloadURI = typeof URL.createObjectURL === 'undefined' ? dataURI : URL.createObjectURL(blob);

            let link = document.createElement('a');
            link.setAttribute('href', downloadURI);
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    createCSVDownload(columns, data, options) {
        const csv = this.buildCSV(columns, data, options);

        // if (options.onDownload && csv === false) {
        //   return;
        // }

        this.downloadCSV(csv, options.downloadOptions.filename);
    }

    OnHideCompletedClick = async () => {

        let newState = 1;
        if (this.state.hideCompleted === 1) {
            newState = 0;
        }
        else {
            newState = 1;
        }

        this.setState({ hideCompleted: newState });

        this.setState({
            isLoading: true,
        });

        var res = await this.getData();

        this.setState({
            isLoading: false,
            data: res.data,
            page: res.page,
            count: res.total,
        });
    }

    OnDownloadClick = async () => {
        //console.log("OnDownloadClick!!! - " + this.state.page);

        const { data, columns, options } = this.state;
        let displayData = this.state.data;


        this.setState({
            isLoading: true,
            downloadFile: 1,
        });

        var res = await this.getData();
        this.setState({
            isLoading: false,
            downloadFile: 0,
        });

        Storage.configure({
            bucket: process.env.REACT_APP_S3FrontendBucket,
            region: process.env.REACT_APP_Region,
            level: 'public',
        });

        var file1 = await Storage.get(`ERA-OC_Reconciliation/CSVExport/${res.filename}`);
        console.log(file1);

        let link = document.createElement('a');
        link.href = file1;
        link.download = file1;
        link.click();
    }

    signOut = async () => {
        // localStorage.removeItem("retainObject");
        try {
            await Auth.signOut();
            window.location.assign("/");
            // signout success
        }
        catch (e) {
            console.log(e)
        }
    }

    getCurrentUser = async () => {
        try {
          var user = await Auth.currentAuthenticatedUser();
          return user;
        }
        catch(e){
         // this.setState({ isLoading: false });
          if(e === "not authenticated") {
            await this.signOut();
          }
        }
    }

    getFileIconType(filename) {
        let extension = /(?:\.([^.]+))?$/.exec(filename)[1];

        switch (extension) {
            case 'pdf':
                return 'fas fa-file-pdf';
            case 'jpeg':
            case 'jpg':
            case 'png':
            case 'gif':
            case 'bmp':
                return 'fas fa-file-image';
            case 'txt':
                return 'fas fa-file-alt';
            case 'doc':
            case 'docx':
                return 'fas fa-file-word';
            case 'xls':
            case 'xlsx':
                return 'fas fa-file-excel';
            case 'ppt':
            case 'pptx':
                return 'fas fa-file-powerpoint';
            default: // any other file
                return 'fas fa-file';
        }
    }

    updatePanels = (n) => {
        if (n === 1) {
            this.setState({ showAddUser: "block" });
            this.setState({ showSubmitClaim: "none" });
            this.setState({ showLogin: "none" });
            this.setState({ showIrvineClaims: "none" });
        }
        else if (n === 2) {
            this.setState({ showAddUser: "none" });
            this.setState({ showLogin: "block" });
            this.setState({ showIrvineClaims: "none" });
        }
        else if (n === 3) {
            this.setState({ showSubmitClaim: "block" });
            this.setState({ showAddUser: "none" });
            this.setState({ showLogin: "none" });
            this.setState({ showIrvineClaims: "none" });
        }
        else if (n === 4) {
            this.setState({ showCeppClaims: "none" });
            this.setState({ showSubmitClaim: "none" });
            this.setState({ showLogin: "block" });
            this.setState({ showIrvineClaims: "none" });
        }
        else if (n === 5) {
            this.setState({ showIrvineClaims: "block" });
            this.setState({ showCeppClaims: "none" });
            this.setState({ showSubmitClaim: "none" });
            this.setState({ showLogin: "none" });
        }
    }

    dummyRequest = () => {
        return new Promise((resolve, reject) => {
            window.setTimeout(
                () => {
                    resolve();
                },
                5000
            );
        });
    }

    close = () => {
        this.props.updateFunction(4);
        this.setState({ email: "" });
        this.closeDetails();
    }


    render() {

        this.state.options.page = this.state.page;
        this.state.options.sortOrder = this.state.sortOrder;
        this.state.options.count = this.state.count;
        this.state.options.rowsPerPage = this.state.rowsPerPage;
        this.state.options.searchText = this.state.searchText;

        return (

            <div className="d-flex flex-column sticky-footer-wrapper">

                {/* main content starts */}
                {/* <main className="main flex-fill mt-2 mt-md-3">
                <div className="container-fluid"> */}

                {/* content area starts */}
                <div className="search-claims-form w-100 h-100 p-0 cepp-grid">
                    <h4 className="font-weight-bold text-uppercase text-primary d-block d-sm-none py-2" style={{ whiteSpace: 'nowrap' }}>
                        <svg className="icon-title" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x={0} y={0} viewBox="0 0 45.6 48" xmlSpace="preserve"><style dangerouslySetInnerHTML={{ __html: ".st0{fill:#005191}" }} /><path className="st0" d="M6.1 11.6h3.8v3.8H6.1zM31.3 6.5h-5.9V.6z" /><path className="st0" d="M24.4 8.5c-.6 0-1-.5-1-1V0H1C.4 0 0 .5 0 1v39.1c0 .5.4 1 1 1h19.3l-.3-.3c-2.5-2.5-3.8-5.7-3.8-9.2 0-3.5 1.3-6.7 3.8-9.2 2.5-2.5 5.7-3.8 9.2-3.8.9 0 1.7.1 2.6.3V8.5h-7.4zM4.1 10.6c0-.6.4-1 1-1h5.8c.6 0 1 .4 1 1v5.8c0 .5-.4 1-1 1H5.1c-.6 0-1-.5-1-1v-5.8zm8.8 21.6H5.1c-.6 0-1-.4-1-1 0-.5.4-1 1-1h7.8c.6 0 1 .5 1 1s-.4 1-1 1zm0-3.7H5.1c-.6 0-1-.5-1-1 0-.6.4-1 1-1h7.8c.6 0 1 .4 1 1 0 .5-.4 1-1 1zm0-3.8H5.1c-.6 0-1-.5-1-1s.4-1 1-1h7.8c.6 0 1 .5 1 1s-.4 1-1 1zm0-3.7H5.1c-.6 0-1-.5-1-1 0-.6.4-1 1-1h7.8c.6 0 1 .4 1 1s-.4 1-1 1zm8.6-4.3h-7.6c-.6 0-1-.5-1-1 0-.6.4-1 1-1h7.6c.6 0 1 .4 1 1s-.5 1-1 1zm0-4.4h-7.6c-.6 0-1-.4-1-1 0-.5.4-1 1-1h7.6c.6 0 1 .5 1 1 0 .6-.5 1-1 1z" /><path className="st0" d="M44.7 42.5L39 36.8c.8-1.6 1.3-3.3 1.3-5.2 0-2.9-1.2-5.7-3.2-7.8-2.1-2.1-4.8-3.2-7.8-3.2-2.9 0-5.7 1.2-7.8 3.2-2.1 2.1-3.2 4.8-3.2 7.8 0 2.9 1.1 5.7 3.2 7.8 2.2 2.1 5 3.2 7.8 3.2 1.8 0 3.5-.4 5.1-1.3l5.7 5.7c.6.6 1.5.9 2.3.9.8 0 1.7-.3 2.3-1 1.3-1.1 1.3-3.1 0-4.4zM22.9 38c-1.7-1.7-2.6-4-2.6-6.4 0-2.4.9-4.7 2.6-6.4 1.7-1.7 4-2.6 6.4-2.6 2.4 0 4.7.9 6.4 2.6s2.6 4 2.6 6.4c0 2.4-.9 4.7-2.6 6.4-3.6 3.5-9.3 3.5-12.8 0z" /></svg>
                        Search Claim ERA-Santa Ana
                    </h4>

                    <div className="data-section">
                        <div className="data-table" style={{ height: this.state.dataheight }}>
                            <React.Fragment>
                                <div className="mui-table-border">
                                    <MuiThemeProvider theme={this.getMuiTheme()}>
                                        <MUIDataTable data={this.state.data} columns={this.state.columns} options={this.state.options} components={{ TableViewCol: TableViewCol }} />
                                    </MuiThemeProvider>
                                </div>
                            </React.Fragment>
                        </div>
                    </div>
                </div>
                {/* content area ends */}
                <Backdrop className="MuiBackdrop-root" open={this.state.isLoading} >
                    <CircularProgress />
                </Backdrop>
                {/* </div>
                    </main> */}
                {/* main content ends */}

            </div>

        );
    }
};

export default CeppClaimsReconciliation;