import React from "react";
import { Auth, API, Storage } from 'aws-amplify';

let UserName = '';
let showBkBtn = 'block';

class AddUser extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            msg: "",
            firstname: null,
            lastname: null,
            firstnameSpouse: null,
            lastnameSpouse: null,
            email: null,
            address: null,
            address2: "",
            state: null,
            city: null,
            DOB: null,
            covid_other: null,
            industry_other: null,
            zip: null,
            showAlert: "none",
            showAlert2: "none",
            phone: null,
            incomebefore: null,
            household: "1",
            household1: "0",
            household2: "0",
            household3: "0",
            household4: "0",
            showOther1: "none",
            showOther2: "none"
        }
    }

    async componentDidMount() {
        this.props.parentCallback("Register User", "fas fa-user mr-2", "");
        const { accessToken } = await Auth.currentSession();
        const cognitogroups = accessToken.payload['cognito:groups'];
        if (cognitogroups.length === 1) {
            showBkBtn = "none";
        }
        var user = await Auth.currentAuthenticatedUser();
        UserName = user.attributes.name;
    }

    close = () => {
        //this.props.updateFunction(2);
        this.clearValues();
        window.location.assign("/");
    }

    closeMsg = () => {
        this.setState({ showAlert: "none", msg: "" });
    }

    closeMsg2 = () => {
        this.setState({ showAlert2: "none", msg: "" });
    }

    postDataRDS = async () => {
        var phone = `+1${this.state.phone}`;
        var covideffect;
        var industry;
        if (this.state.covid_other === null || this.state.covid_other === "") {
            covideffect = this.effected.value;
        }
        else {
            covideffect = this.state.covid_other;
        }
        if (this.state.industry_other === null || this.state.industry_other === "") {
            industry = this.industrySelector.value;
        }
        else {
            industry = this.state.industry_other;
        }

        var user = await Auth.currentAuthenticatedUser();
        let apiName = process.env.REACT_APP_APIGatewayName;
        let path = '/adminadduser';
        let myInit = { // OPTIONAL
            body: {
                "info": {
                    "effectOfCovid19Financially": covideffect,
                    "firstName": this.state.firstname,
                    "lastName": this.state.lastname,
                    "partnerFirstName": this.state.firstnameSpouse,
                    "partnerLastName": this.state.lastnameSpouse,
                    "clientDOB": this.state.DOB,
                    "email": this.state.email,
                    "addressLine1": this.state.address,
                    "addressLine2": this.state.address2,
                    "city": this.state.city,
                    "state": this.stateSelector.value,
                    "zipcode": this.state.zip,
                    "country": "USA",
                    "phone": phone,
                    "monthlyIncomeBeforeCovid19": this.state.incomebefore,
                    "legallyAbleToWork": this.workSelector.value,
                    "workIndustry": industry,
                    "veteran": this.veteranSelector.value,
                    "eligibleForStimulusFunds": this.stimulusSelector.value,
                    "employmentStatus": this.employmentSelector.value,
                    "householdNumber": this.state.household,
                    "age0to5": this.state.household1,
                    "age6to17": this.state.household2,
                    "age18to59": this.state.household3,
                    "age60andUp": this.state.household4,
                    "originator": user.attributes.email
                }
            }, // replace this with attributes you need
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
        }
        return await API.post(apiName, path, myInit);
    }

    add = async () => {
        //https://u3662pbt8j.execute-api.us-east-1.amazonaws.com/test-v1/adminadduser  POST
        if (this.effected.value === "Not Affected") {
            this.setState({
                msg: "ERROR: Not Eligible for AID.",
                showAlert: "block"
            });
            return;
        }
        if (this.effected.value === "Make a selection") {
            this.setState({
                msg: "ERROR: Make a selection for financial impact.",
                showAlert: "block"
            });
            return;
        }
        if (this.industrySelector.value === "Make a selection") {
            this.setState({
                msg: "ERROR: Make a selection for work industry.",
                showAlert: "block"
            });
            return;
        }
        if (this.state.email === null || this.state.email === "") {
            this.setState({
                msg: "ERROR: No Email Provided",
                showAlert: "block"
            });
            return;
        }
        if (this.state.phone === null || this.state.phone === "") {
            this.setState({
                msg: "ERROR: No Phone Number Provided",
                showAlert: "block"
            });
            return;
        }
        if (this.state.firstname === null || this.state.firstname === "") {
            this.setState({
                msg: "ERROR: No FirstName Provided",
                showAlert: "block"
            });
            return;
        }
        if (this.state.lastname === null || this.state.lastname === "") {
            this.setState({
                msg: "ERROR: No LastName Provided",
                showAlert: "block"
            });
            return;
        }
        if (this.state.address === null || this.state.address === "" || this.state.city === null || this.state.city === "" || this.state.zip === null || this.state.zip === "") {
            this.setState({
                msg: "ERROR: Incomplete Address",
                showAlert: "block"
            });
            return;
        }
        if (this.state.zip.length < 5) {
            this.setState({
                msg: "ERROR: Zip code must be a 5 digit number",
                showAlert: "block"
            });
            return;
        }
        if (this.state.DOB === null || this.state.address === "0000-00-00" || this.state.DOB === "") {
            this.setState({
                msg: "ERROR: Missing Date of Birth",
                showAlert: "block"
            });
            return;
        }
        if (this.state.incomebefore === null || this.state.incomebefore === "") {
            this.setState({
                msg: "ERROR: Missing Income before COVID-19",
                showAlert: "block"
            });
            return;
        }

        var res = await this.postDataRDS();
        if (res.message === 0) {
            //console.log(res.message);
            this.setState({
                msg: "User has been created. Thank you.",
                firstname: null,
                lastname: null,
                email: null,
                address: null,
                phone: null,
                household: "1",
                showAlert: "block"
            });
            this.clearValues();

        }
        else {
            //console.log(res.message.message);
            this.setState({
                msg: res.message.message,
                showAlert: "block"
            });

        }
    }

    updateFirstName = (evt) => {
        var name = evt.target.value;
        this.setState({ firstname: name });
    }
    updateLastName = (evt) => {
        var name = evt.target.value;
        this.setState({ lastname: name });
    }
    updateFirstNameSpouse = (evt) => {
        var name = evt.target.value;
        this.setState({ firstnameSpouse: name });
    }
    updateLastNameSpouse = (evt) => {
        var name = evt.target.value;
        this.setState({ lastnameSpouse: name });
    }

    updateEmail = (evt) => {
        var name = evt.target.value;
        this.setState({ email: name });
    }

    updateAddress = (evt) => {
        var name = evt.target.value;
        this.setState({ address: name });
    }

    updateAddress2 = (evt) => {
        var name = evt.target.value;
        this.setState({ address2: name });
    }
    updateZip = (evt) => {
        var name = evt.target.value;
        var myString = name.replace(/\D/g, '');
        this.setState({ zip: myString });
    }
    updateCity = (evt) => {
        var name = evt.target.value;
        this.setState({ city: name });
    }

    updatePhone = (evt) => {
        var name = evt.target.value;
        var myString = name.replace(/\D/g, '');
        this.setState({ phone: myString });
    }

    updateHouseHold = (evt) => {
        var name = evt.target.value;
        this.setState({ household: name });
    }
    updateHouseHold1 = (evt) => {
        var name = evt.target.value;
        this.setState({ household1: name });
    }
    updateHouseHold2 = (evt) => {
        var name = evt.target.value;
        this.setState({ household2: name });
    }
    updateHouseHold3 = (evt) => {
        var name = evt.target.value;
        this.setState({ household3: name });
    }
    updateHouseHold4 = (evt) => {
        var name = evt.target.value;
        this.setState({ household4: name });
    }

    updateDOB = (evt) => {
        var name = evt.target.value;
        this.setState({ DOB: name });
    }

    updateCovidOther = (evt) => {
        var name = evt.target.value;
        this.setState({ covid_other: name });
    }

    updateIndustryOther = (evt) => {
        var name = evt.target.value;
        this.setState({ industry_other: name });
    }

    updateIncome = (evt) => {
        var name = evt.target.value;
        this.setState({ incomebefore: name });
    }

    adminGetUser = async () => {
        var user = await Auth.currentAuthenticatedUser();
        let apiName = process.env.REACT_APP_APIGatewayName;
        let path = '/admingetuser';
        let myInit = { // OPTIONAL
            body: {
                "info": {
                    "email": this.state.email,
                }
            }, // replace this with attributes you need
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
        }
        return await API.post(apiName, path, myInit);
    }

    loadData = async () => {
        if (this.state.email === null) {
            this.setState({
                msg: "ERROR: Please provide an email.",
                showAlert: "block"
            });
            return;
        }
        try {
            var res = await this.adminGetUser();
            if (res.length === 0) {
                this.setState({
                    msg: "ERROR: Email not in System.",
                    showAlert: "block"
                });
                return;
            }
            //console.log(res[0]);
            //set covid other or industry other TODO
            if (res[0][19] === "Furloughed due to pandemic" || res[0][19] === "Laid off due to pandemic" || res[0][19] === "Underemployed or significant reduction in work hours") {
                this.effected.value = res[0][19];
                this.setState({ showOther1: "none", covid_other: "" });
            }
            else {
                this.effected.value = "other";
                this.setState({ covid_other: res[0][19], showOther1: "block" });
            }
            if (res[0][17] === "Food Service (traditional food service & food delivery)" || res[0][17] === "Hospitality" || res[0][17] === "Amusement Parks" || res[0][17] === "Retail" || res[0][17] === "Self-employed (free lance, uber/lyft drivers, etc.)") {
                this.industrySelector.value = res[0][17];
                this.setState({ showOther2: "none", industry_other: "" });
            }
            else {
                this.industrySelector.value = "other";
                this.setState({ industry_other: res[0][17], showOther2: "block" });
            }

            var phone = res[0][12];
            phone = phone.substring(2);
            this.setState({
                firstname: res[0][0],
                lastname: res[0][1],
                firstnameSpouse: res[0][2],
                lastnameSpouse: res[0][3],
                email: res[0][11],
                address: res[0][4],
                address2: res[0][5],
                city: res[0][6],
                DOB: res[0][10],
                zip: res[0][9],
                phone: phone,
                incomebefore: res[0][16],
                household: res[0][20],
                household1: res[0][21],
                household2: res[0][22],
                household3: res[0][23],
                household4: res[0][24],
            });
            this.stateSelector.value = res[0][7];
            this.workSelector.value = res[0][13];
            this.veteranSelector.value = res[0][15];
            this.stimulusSelector.value = res[0][14];
            this.employmentSelector.value = res[0][18];
        }
        catch (err) {
            console.log(err);
            this.setState({
                msg: "ERROR: Failed to load info.",
                showAlert: "block"
            });
            return;
        }
    }

    clearValues = () => {
        this.setState({
            firstname: "",
            lastname: "",
            firstnameSpouse: "",
            lastnameSpouse: "",
            email: "",
            address: "",
            address2: "",
            city: "",
            DOB: "",
            zip: "",
            phone: "",
            incomebefore: "",
            household: "1",
            household1: "0",
            household2: "0",
            household3: "0",
            household4: "0",
            covid_other: "",
            industry_other: "",
            showOther1: "none",
            showOther2: "none",

        });
        this.stateSelector.value = "CA";
        this.workSelector.value = "yes";
        this.veteranSelector.value = "yes";
        this.stimulusSelector.value = "yes";
        this.employmentSelector.value = "employed";
        this.industrySelector.value = "Make a selection";
        this.effected.value = "Make a selection";
    }

    adminUpdateUser = async () => {
        var phone = `+1${this.state.phone}`;
        var covideffect;
        var industry;
        if (this.state.covid_other === null || this.state.covid_other === "") {
            covideffect = this.effected.value;
        }
        else {
            covideffect = this.state.covid_other;
        }
        if (this.state.industry_other === null || this.state.industry_other === "") {
            industry = this.industrySelector.value;
        }
        else {
            industry = this.state.industry_other;
        }
        var user = await Auth.currentAuthenticatedUser();
        let apiName = process.env.REACT_APP_APIGatewayName;
        let path = '/adminmodifyuser';
        let myInit = { // OPTIONAL
            body: {
                "info": {
                    "effectOfCovid19Financially": covideffect,
                    "firstName": this.state.firstname,
                    "lastName": this.state.lastname,
                    "clientDOB": this.state.DOB,
                    "email": this.state.email,
                    "addressLine1": this.state.address,
                    "addressLine2": this.state.address2,
                    "city": this.state.city,
                    "state": this.stateSelector.value,
                    "zipcode": this.state.zip,
                    "country": "USA",
                    "phone": phone,
                    "monthlyIncomeBeforeCovid19": this.state.incomebefore,
                    "legallyAbleToWork": this.workSelector.value,
                    "workIndustry": industry,
                    "veteran": this.veteranSelector.value,
                    "eligibleForStimulusFunds": this.stimulusSelector.value,
                    "employmentStatus": this.employmentSelector.value,
                    "householdNumber": this.state.household,
                    "age0to5": this.state.household1,
                    "age6to17": this.state.household2,
                    "age18to59": this.state.household3,
                    "age60andUp": this.state.household4,
                    "partnerFirstName": this.state.firstnameSpouse,
                    "partnerLastName": this.state.lastnameSpouse
                }
            }, // replace this with attributes you need
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
        }
        return await API.post(apiName, path, myInit);
    }

    updateData = async () => {
        if (this.effected.value === "Not Affected") {
            this.setState({
                msg: "ERROR: Not Eligible for AID.",
                showAlert: "block"
            });
            return;
        }
        if (this.effected.value === "Make a selection") {
            this.setState({
                msg: "ERROR: Make a selection for financial impact.",
                showAlert: "block"
            });
            return;
        }
        if (this.industrySelector.value === "Make a selection") {
            this.setState({
                msg: "ERROR: Make a selection for work industry.",
                showAlert: "block"
            });
            return;
        }
        if (this.state.email === null || this.state.email === "") {
            this.setState({
                msg: "ERROR: No Email Provided",
                showAlert: "block"
            });
            return;
        }
        if (this.state.phone === null || this.state.phone === "") {
            this.setState({
                msg: "ERROR: No Phone Number Provided",
                showAlert: "block"
            });
            return;
        }
        if (this.state.firstname === null || this.state.firstname === "") {
            this.setState({
                msg: "ERROR: No FirstName Provided",
                showAlert: "block"
            });
            return;
        }
        if (this.state.lastname === null || this.state.lastname === "") {
            this.setState({
                msg: "ERROR: No LastName Provided",
                showAlert: "block"
            });
            return;
        }
        if (this.state.address === null || this.state.address === "" || this.state.city === null || this.state.city === "" || this.state.zip === null || this.state.zip === "") {
            this.setState({
                msg: "ERROR: Incomplete Address",
                showAlert: "block"
            });
            return;
        }
        if (this.state.zip.length < 5) {
            this.setState({
                msg: "ERROR: Zip code must be a 5 digit number",
                showAlert: "block"
            });
            return;
        }
        if (this.state.DOB === null || this.state.address === "0000-00-00" || this.state.DOB === "") {
            this.setState({
                msg: "ERROR: Missing Date of Birth",
                showAlert: "block"
            });
            return;
        }
        //console.log(this.state.DOB);
        if (this.state.incomebefore === null || this.state.incomebefore === "") {
            this.setState({
                msg: "ERROR: Missing Income before COVID-19",
                showAlert: "block"
            });
            return;
        }
        try {
            var res = await this.adminUpdateUser();
            //console.log(res);
            if (res.message === 0) {
                this.setState({
                    msg: "User Update Successful.",
                    showAlert: "block"
                });
                this.clearValues();
            }
            else {
                //console.log(res.message.message);
                this.setState({
                    msg: res.message.message,
                    showAlert: "block"
                });

            }
        }
        catch (err) {
            console.log(err);
            this.setState({
                msg: "ERROR: Failed to Update.",
                showAlert: "block"
            });
        }
    }

    checkaffect = (evt) => {
        if (this.effected.value === "Not Affected") {
            this.setState({
                msg: "ERROR: Not Eligible for aid.",
                showAlert: "block"
            });

        }
        if (this.effected.value === "other") {
            this.setState({
                showOther1: "block"
            });
        }
        else {
            this.setState({
                showOther1: "none",
                covid_other: "",
            });
        }
    }

    checkaffect2 = (evt) => {
        if (this.industrySelector.value === "other") {
            this.setState({
                showOther2: "block"
            });
        }
        else {
            this.setState({
                industry_other: "",
                showOther2: "none"
            });
        }
    }

    deleteUser = async () => {
        if (this.state.email === "" || this.state.email === null) {
            this.setState({
                msg: "Error: must provide an email.",
                showAlert: "block"
            });
            return;
        }
        this.setState({
            msg: "Are you sure you would like to Delete user with ID: " + this.state.email,
            showAlert2: "block"
        });
    }

    performDelete = async () => {
        this.setState({
            msg: "",
            showAlert2: "none"
        });

        try {
            Storage.configure({
                bucket: process.env.REACT_APP_S3FrontendBucket,
                region: process.env.REACT_APP_Region,
                level: 'public',
            });

            var key = `HPP/${this.state.email}/`;
            var files = await Storage.list(key);
            console.log(files);
            for (var i = 0; i < files.length; i++) {
                var key1 = files[i].key;
                var Delres = await Storage.remove(key1);
                console.log(Delres);
            }
        }
        catch (err) {
            console.log(err);
            this.setState({ msg: "Failed to clear files from S3" });
        }
        var res = await this.adminDeleteUser();
        console.log(res);
        this.setState({
            msg: "Delete Completed",
            showAlert: "block"
        });
    }

    adminDeleteUser = async () => {
        var user = await Auth.currentAuthenticatedUser();
        let apiName = process.env.REACT_APP_APIGatewayName;
        let path = '/admindeleteuser';
        let myInit = { // OPTIONAL
            body: {
                "info": {
                    "email": this.state.email,
                }
            }, // replace this with attributes you need
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
        }
        return await API.post(apiName, path, myInit);
    }

    signOut = async () => {
        try {
            await Auth.signOut();
            window.location.assign("/");
            // signout success
        }
        catch (e) {
            console.log(e)
        }
    }

    render() {
        document.body.classList.remove('dashboard');
        return (

            <div className="d-flex flex-column sticky-footer-wrapper">
                {/* <main className="main flex-fill mt-2 mt-md-3">
          <div className="container-fluid"> */}

                {/* content area starts */}
                <div className="box-shadow">
                    <div className="add-user-form p-3">
                        <div className="row">
                            <div className="col-lg-7 border-right">

                                <div className="form-group form-group-md-select w-100">
                                    <label className="text-muted small">How did COVID-19 financially affect you or your household?</label>
                                    <select className="form-control" onChange={this.checkaffect} ref={(input) => this.effected = input}>
                                        <option value="Make a selection">Make a selection</option>
                                        <option value="Furloughed due to pandemic">Furloughed due to pandemic</option>
                                        <option value="Laid off due to pandemic">Laid off due to pandemic</option>
                                        <option value="Underemployed or significant reduction in work hours">Underemployed or significant reduction in work hours</option>
                                        <option value="Not Affected">Not Affected</option>
                                        <option value="other">Other</option>
                                    </select>
                                </div>

                                <div className="form-group" style={{ display: this.state.showOther1 }}>
                                    <label>If other</label>
                                    <input className="form-control" placeholder="If Other" onChange={this.updateCovidOther} value={this.state.covid_other}></input>
                                </div>

                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group form-group-md-input">
                                            <label className="text-muted">First Name</label>
                                            <input className="form-control" type="text" onChange={this.updateFirstName} placeholder="First Name" value={this.state.firstname}></input>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group form-group-md-input">
                                            <label>Last Name</label>
                                            <input className="form-control" type="text" onChange={this.updateLastName} placeholder="Last Name" value={this.state.lastname}></input>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group form-group-md-input">
                                            <label>Partner First Name</label>
                                            <input className="form-control" type="text" onChange={this.updateFirstNameSpouse} placeholder="Partner First Name" value={this.state.firstnameSpouse}></input>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group form-group-md-input">
                                            <label>Partner Last Name</label>
                                            <input className="form-control" type="text" onChange={this.updateLastNameSpouse} placeholder="Partner Last Name" value={this.state.lastnameSpouse}></input>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group form-group-md-input">
                                            <label>Date of Birth</label>
                                            <input className="form-control" type="date" onChange={this.updateDOB} value={this.state.DOB}></input>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <label className="text-muted small">Email</label>
                                        <div className="input-group mb-3 form-group-md-input">
                                            <input type="text" className="form-control" onChange={this.updateEmail} placeholder="Email" value={this.state.email}></input>
                                            <div className="input-group-append">
                                                <button className="btn btn-secondary" type="button" id="button-addon2" onClick={this.loadData}>Load</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group form-group-md-input">
                                            <label>Address Line 1</label>
                                            <input className="form-control" type="text" onChange={this.updateAddress} maxlength="30" placeholder="Address Line 1" value={this.state.address}></input>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group form-group-md-input">
                                            <label>Address Line 2</label>
                                            <input className="form-control" type="text" onChange={this.updateAddress2} placeholder="Address Line 2" value={this.state.address2}></input>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-6 col-6">
                                        <div className="form-group form-group-md-input">
                                            <label>City</label>
                                            <input className="form-control" type="text" onChange={this.updateCity} placeholder="City" value={this.state.city}></input>
                                        </div>
                                    </div>
                                    <div className="col-sm-3 col-6">
                                        <div className="form-group form-group-md-select w-100">
                                            <label className="text-muted small">State</label>
                                            <select className="form-control" ref={(input) => this.stateSelector = input}>
                                                <option value="AL">AL</option>
                                                <option value="AK">AK</option>
                                                <option value="AR">AR</option>
                                                <option value="AZ">AZ</option>
                                                <option value="CA" selected>CA</option>
                                                <option value="CO">CO</option>
                                                <option value="CT">CT</option>
                                                <option value="DC">DC</option>
                                                <option value="DE">DE</option>
                                                <option value="FL">FL</option>
                                                <option value="GA">GA</option>
                                                <option value="HI">HI</option>
                                                <option value="IA">IA</option>
                                                <option value="ID">ID</option>
                                                <option value="IL">IL</option>
                                                <option value="IN">IN</option>
                                                <option value="KS">KS</option>
                                                <option value="KY">KY</option>
                                                <option value="LA">LA</option>
                                                <option value="MA">MA</option>
                                                <option value="MD">MD</option>
                                                <option value="ME">ME</option>
                                                <option value="MI">MI</option>
                                                <option value="MN">MN</option>
                                                <option value="MO">MO</option>
                                                <option value="MS">MS</option>
                                                <option value="MT">MT</option>
                                                <option value="NC">NC</option>
                                                <option value="NE">NE</option>
                                                <option value="NH">NH</option>
                                                <option value="NJ">NJ</option>
                                                <option value="NM">NM</option>
                                                <option value="NV">NV</option>
                                                <option value="NY">NY</option>
                                                <option value="ND">ND</option>
                                                <option value="OH">OH</option>
                                                <option value="OK">OK</option>
                                                <option value="OR">OR</option>
                                                <option value="PA">PA</option>
                                                <option value="RI">RI</option>
                                                <option value="SC">SC</option>
                                                <option value="SD">SD</option>
                                                <option value="TN">TN</option>
                                                <option value="TX">TX</option>
                                                <option value="UT">UT</option>
                                                <option value="VT">VT</option>
                                                <option value="VA">VA</option>
                                                <option value="WA">WA</option>
                                                <option value="WI">WI</option>
                                                <option value="WV">WV</option>
                                                <option value="WY">WY</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-3 col-6">
                                        <div className="form-group form-group-md-input">
                                            <label>Zip</label>
                                            <input className="form-control" type="text" onChange={this.updateZip} maxlength="5" placeholder="Zip" value={this.state.zip}></input>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-6">
                                        <div className="form-group form-group-md-input">
                                            <label>Phone Number</label>
                                            <input className="form-control" type="text" onChange={this.updatePhone} maxlength="10" placeholder="Phone Number: 2223334455" value={this.state.phone}></input>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group form-group-md-input">
                                            <label>Monthly Household Income before COVID-19:</label>
                                            <input className="form-control" type="text" placeholder="$$$" onChange={this.updateIncome} maxlength="10" value={this.state.incomebefore}></input>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="col-lg-5">

                                <div className="form-group form-group-md-select w-100">
                                    <label className="text-muted small">Are you eligible to legally work in the US?</label>
                                    <select className="form-control" ref={(input) => this.workSelector = input}>
                                        <option value="yes">Yes</option>
                                        <option value="no">No</option>
                                    </select>
                                </div>

                                <div className="form-group form-group-md-select w-100">
                                    <label className="text-muted small">Work Industry?</label>
                                    <select className="form-control" onChange={this.checkaffect2} ref={(input) => this.industrySelector = input}>
                                        <option value="Make a selection">Make a selection</option>
                                        <option value="Food Service (traditional food service & food delivery)">Food Service (traditional food service & food delivery)</option>
                                        <option value="Hospitality">Hospitality</option>
                                        <option value="Amusement Parks">Amusement Parks</option>
                                        <option value="Retail">Retail</option>
                                        <option value="Self-employed (free lance, uber/lyft drivers, etc.)">Self-employed (free lance, uber/lyft drivers, etc.)</option>
                                        <option value="other">Other</option>
                                    </select>
                                </div>

                                <div className="form-group form-group-md-input" style={{ display: this.state.showOther2 }}>
                                    <label>If Other</label>
                                    <input className="form-control" placeholder="If other" onChange={this.updateIndustryOther} value={this.state.industry_other}></input>
                                </div>

                                <div className="form-group form-group-md-select w-100">
                                    <label className="text-muted small">Are you or anyone in your household a veteran?</label>
                                    <select className="form-control" ref={(input) => this.veteranSelector = input}>
                                        <option value="yes">Yes</option>
                                        <option value="no">No</option>
                                    </select>
                                </div>

                                <div className="form-group form-group-md-select w-100">
                                    <label className="text-muted small">Are You eligible for stimulus funds?</label>
                                    <select className="form-control" ref={(input) => this.stimulusSelector = input}>
                                        <option value="yes">Yes</option>
                                        <option value="no">No</option>
                                    </select>
                                </div>


                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group form-group-md-select w-100">
                                            <label className="text-muted small">Employment Status?</label>
                                            <select className="form-control" ref={(input) => this.employmentSelector = input}>
                                                <option value="employed">Employed</option>
                                                <option value="unemployed">Unemployed</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group form-group-md-input">
                                            <label>Household Size?</label>
                                            <input className="form-control" type="number" name="household" min="1" max="100" onChange={this.updateHouseHold} value={this.state.household} />
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-3 col-sm-6 col-6">
                                        <div className="form-group form-group-md-input">
                                            <label>Age 0-5</label>
                                            <input className="form-control" type="number" name="household" min="0" max="100" onChange={this.updateHouseHold1} value={this.state.household1} />
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-6 col-6">
                                        <div className="form-group form-group-md-input">
                                            <label>Age 6-17</label>
                                            <input className="form-control" type="number" name="household" min="0" max="100" onChange={this.updateHouseHold2} value={this.state.household2} />
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-6 col-6">
                                        <div className="form-group form-group-md-input">
                                            <label>Age 18-59</label>
                                            <input className="form-control" type="number" name="household" min="0" max="100" onChange={this.updateHouseHold3} value={this.state.household3} />
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-6 col-6">
                                        <div className="form-group form-group-md-input">
                                            <label>Age 60+</label>
                                            <input className="form-control" type="number" name="household" min="0" max="100" onChange={this.updateHouseHold4} value={this.state.household4} />
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                        <hr />

                        <div className="text-left text-sm-right">
                            <button className="btn btn-primary mb-3 mb-sm-0 mr-3" onClick={this.updateData}><i className="fas fa-user-edit mr-2"></i> UPDATE</button>
                            <button className="btn btn-secondary mb-3 mb-sm-0 mr-3" onClick={this.add}><i className="fas fa-user-check mr-2"></i> REGISTER</button>
                            <button className="btn btn-danger mb-3 mb-sm-0" onClick={this.deleteUser}> <i className="fas fa-user-times mr-2"></i> DELETE</button>
                        </div>

                        <div className="alert-msg-2 alert-fixed" style={{ display: this.state.showAlert }}>
                            <button className="close-panel2" onClick={this.closeMsg}>X</button>
                            <div className="alert-text">
                                {
                                    this.state.msg
                                }
                            </div>
                        </div>

                        <div className="alert-msg-2 alert-fixed" style={{ display: this.state.showAlert2 }}>
                            <button className="close-panel2" onClick={this.closeMsg2}>X</button>
                            <div className="alert-text">
                                {
                                    this.state.msg
                                }
                            </div>
                            <button onClick={this.performDelete}>Yes</button>
                            <button onClick={this.closeMsg2}>No</button>
                        </div>
                    </div>

                </div>
                {/* content area ends */}
            </div>
        );
    }
};

export default AddUser;
