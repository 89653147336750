import { Button, CircularProgress } from '@material-ui/core';
import React from "react";
import MUIDataTable from "mui-datatables";
import { API, Auth, Storage } from 'aws-amplify';
import { isMobile } from 'react-device-detect';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import CustomToolbar from "../customToolbar";
import Tooltip from '@material-ui/core/Tooltip';
import * as cloneDeep from 'lodash/cloneDeep';
import * as find from 'lodash/find';
import {
    FormGroup,
    FormLabel,
    FormControl,
    ListItemText,
    TextField,
    Checkbox,
    FormControlLabel,
    Grid,
    Select,
    InputLabel,
    MenuItem
} from '@material-ui/core';
import ReactDOM from 'react-dom';
import Backdrop from '@material-ui/core/Backdrop';

import imgLogo from '../imgs/logo-assist-oc.png';
import TableViewCol from "../TableViewCol";
import TableSearch from "../TableSearch";
import imgSearchclaim from '../imgs/search-claims.svg';
import SearchClaims from './searchClaims'
import {
    Route,
    NavLink,
} from "react-router-dom";
//import RecordDetails from './recordDetails';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Box from '@material-ui/core/Box';
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";

import {
    KmsKeyringBrowser,
    KMS,
    getClient,
    buildClient,
    CommitmentPolicy
} from "@aws-crypto/client-browser";
import * as AWS from "aws-sdk";
import * as util_utf8_node from "@aws-sdk/util-utf8-node";
import { toBase64,fromBase64 } from "@aws-sdk/util-base64-browser";
import { decryptValue } from '../utils/AESUtil';

const { encrypt, decrypt } = buildClient(
    CommitmentPolicy.REQUIRE_ENCRYPT_ALLOW_DECRYPT
);

const headers = [
    "ST.individualID",
    "ST.fundingSource",
    "ST.entityType",
    "ST.payeeName",
    "ST.transactionType",
    "ST.entityAccountNumber",
    "ST.entityRoutingNumber",
    "SPL.entityAmount",
    "ST.paymentType",
    "SPL.entityMemoPropertyAddress",
    "SA.spaAssignmentNo",
    "ST.createdDate",
    "ST.createdTime",
    "SA.programTitle",
    "paymentDate",
    "bulkUpdateId",
    "SA.receiverCompany"
    

     // "sr.amount",
    // "sr.amountCoveredAfterRec",
    // "sr.initiallyPaidBy",
    // "sr.dateCreated",
    // "sr.timeCreated",
    // "sr.dateModified",
    // "sr.timeModified",
    // "sr.createdBy",
    // "sr.modifiedBy",
    // "sr.paymentMethod",
    // "sr.memoPropertyAddress",
    // "sc.total1099Amount",
    // "sr.otherPaymentStatus",
    // "sr.transactionNo",
    // "sr.notes"
];

class SantaAnaApplicantTransaction extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            nextFriday:"",
            expandRows: [],
            // permissions: [],
            // mode: "Admin",
            showCeppClaims: "none",
            showIrvineClaims: "none",
            showAddUser: "none",
            showSubmitClaim: "none",
            showLogin: "none",
            columns: [
                {
                    name: "individualID",
                    label: "Individual ID",
                    options: {
                        download: true,
                        display: true,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 12) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "50px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "50px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "fundingSource",
                    label: "Funding Source",
                    options: {
                        download: true,
                        display: true,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {

                            if (value && value.length > 12) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "128px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "128px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "entityType",
                    label: "Recipient",
                    options: {
                        download: true,
                        display: true,
                        filter: true,
                        filterType: 'custom',
                        filterOptions: {
                            display: (filterList, onChange, index, column) => {
                                const optionValues = [['Landlord'], ['Individual']];
                                return (
                                    <FormControl>
                                        <InputLabel>
                                            Recipient
                                        </InputLabel>
                                        <Select
                                            value={filterList[index]}
                                            renderValue={selected => selected}
                                            onChange={event => {
                                                filterList[index] = event.target.value;
                                                onChange(filterList[index], index, column);
                                            }}
                                        >
                                            {optionValues.map(item => (
                                                <MenuItem key={item} value={item}>
                                                    <ListItemText primary={item} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                );
                            }
                        },
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 12) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "128px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "128px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "payeeName",
                    label: "Payee Name",
                    options: {
                        download: true,
                        display: true,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 12) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "128px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "128px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "transactionType",
                    label: "Payment Mode",
                    options: {
                        download: true,
                        display: true,
                        filterType: 'custom',
                        filterOptions: {
                            display: (filterList, onChange, index, column) => {
                                const optionValues = [['ACH'], ['Check']];
                                return (
                                    <FormControl>
                                        <InputLabel>
                                        Payment Mode
                                        </InputLabel>
                                        <Select
                                            value={filterList[index]}
                                            renderValue={selected => selected}
                                            onChange={event => {
                                                filterList[index] = event.target.value;
                                                onChange(filterList[index], index, column);
                                            }}
                                        >
                                            {optionValues.map(item => (
                                                <MenuItem key={item} value={item}>
                                                    <ListItemText primary={item} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                );
                            }
                        },
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 12) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "115px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "115px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "entityAccountNumber",
                    label: "Account Number",
                    options: {
                        download: true,
                        display: true,
                        filter: false,
                        //filterType: 'textField',
                        sort: false,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 12) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "90px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {/* {value} */}
                                            {value == "" || value == null ? "-" : value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "90px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value == "" || value == null ? "-" : value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "entityRoutingNumber",
                    label: "Routing Number",
                    options: {
                        download: true,
                        display: true,
                        filter: false,
                        //filterType: 'textField',
                        sort: false,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 12) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "90px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                          {value == "" || value == null ? "-" : value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "90px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                         {value == "" || value == null ? "-" : value}
                                    </div>
                                );
                        },
                    },
                },
               
                {
                    name: "entityAmount",
                    label: "Amount",
                    options: {
                        download: true,
                        display: true,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 12) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "70px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "70px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                
                {
                    name: "paymentType",
                    label: "Payment Type",
                    options: {
                        download: true,
                        display: true,
                        filterType: 'custom',
                        filterOptions: {
                            display: (filterList, onChange, index, column) => {
                                const optionValues = [['Rent Payment'], ['Reconciliation']];
                                return (
                                    <FormControl>
                                        <InputLabel>
                                        Payment Type
                                        </InputLabel>
                                        <Select
                                            value={filterList[index]}
                                            renderValue={selected => selected}
                                            onChange={event => {
                                                filterList[index] = event.target.value;
                                                onChange(filterList[index], index, column);
                                            }}
                                        >
                                            {optionValues.map(item => (
                                                <MenuItem key={item} value={item}>
                                                    <ListItemText primary={item} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                );
                            }
                        },
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 12) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "140px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "140px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "memoPropertyAddress",
                    label: "Memo - Property Address",
                    options: {
                        download: true,
                        display: false,
                        filter: true,
                        filterType: 'custom',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 12) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "70px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "70px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "spaAssignmentNo",
                    label: "SPA Assignment",
                    options: {
                        download: true,
                        display: false,
                        filter: true,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 12) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "createdDate",
                    label: "Date Created",
                    options: {
                        download: true,
                        display: false,
                        filter: true,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 12) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "createdTime",
                    label: "Time Created",
                    options: {
                        download: true,
                        display: false,
                        filter: true,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 12) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "programTitle",
                    label: "Program",
                    options: {
                        download: true,
                        display: false,
                        filter: true,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 12) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },                
                {
                    name: "paymentDate",
                    label: "Payment Date",
                    options: {
                        download: true,
                        display: false,
                        filter: false,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                             // 7-number of days in a week,  X- index of friday in a week calculation +/- you need to add/subtract index of current day of a week to get friday.                       
                            var day = new Date();
                            console.log(day.toLocaleString('en-us', {weekday: 'long'}));
                            var todaysDay = day.toLocaleString('en-us', {weekday: 'long'});
                            if(todaysDay === 'Friday'){
                            var nextDay = new Date(); 
                            this.setState({nextFriday:nextDay && nextDay.toISOString().split('T')[0]})
                            }else{
                                var day_in_week = 5;
                                var testDay = new Date();
                                var nextDay = testDay.setDate(testDay.getDate() + (day_in_week - 1 - testDay.getDay() + 7) % 7 + 1);
                                var event = new Date(nextDay);
                                let date1 = JSON.stringify(event);
                                var FidayDate = date1.slice(1,11);
                                this.setState({nextFriday:FidayDate})
                            }                           

                            //   console.log(nextWeekdayDate(date, 5)); // Outputs the date next Friday after today.

                            // else if(todaysDay === 'Saturday'){
                            // var days = 7 - day.getDay() + 4;
                            // var nextDay = new Date(day.setDate(day.getDate() + days)); 
                            // }
                            // else if(todaysDay === 'Sunday'){
                            // var days = 7 - day.getDay() + 3;
                            // var nextDay = new Date(day.setDate(day.getDate() + days)); 
                            // }
                            // else if(todaysDay === 'Monday'){
                            // var days = 7 - day.getDay() + 2;
                            // var nextDay = new Date(day.setDate(day.getDate() + days)); 
                            // }
                            // else if(todaysDay === 'Tuesday'){
                            // var days = 7 - day.getDay() + 1;
                            // var nextDay = new Date(day.setDate(day.getDate() + days)); 
                            // }
                            // else if(todaysDay === 'Wednesday'){
                            // var days = 7 - day.getDay() + 0;
                            // var nextDay = new Date(day.setDate(day.getDate() + days)); 
                            // }
                            // else if(todaysDay === 'Thursday'){
                            // var days = 7 - day.getDay() - 1 ;
                            // var nextDay = new Date(day.setDate(day.getDate() + days)); 
                            // }
                            
                            if (this.state.nextFriday) {
                                return (
                                        <div>
                                            {this.state.nextFriday}
                                        </div>
                                );
                            }
                            
                        },
                    },
                },
                {
                    name: "bulkUpdateId",
                    label: "Bulk Update Id",
                    options: {
                        download: true,
                        display: false,
                        filter: false,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 12) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "receiverCompany",
                    label: "Receiver Company",
                    options: {
                        download: true,
                        display: false,
                        filterType: 'custom',
                        filterOptions: {
                            display: (filterList, onChange, index, column) => {
                                const optionValues = [['No Company'],['Rose: AMC'], ['Ronnie Sandoval']];
                                return (
                                    <FormControl>
                                        <InputLabel>
                                        Receiver Company
                                        </InputLabel>
                                        <Select
                                            value={filterList[index]}
                                            renderValue={selected => selected}
                                            onChange={event => {
                                                filterList[index] = event.target.value;
                                                onChange(filterList[index], index, column);
                                            }}
                                        >
                                            {optionValues.map(item => (
                                                <MenuItem key={item} value={item}>
                                                    <ListItemText primary={item} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                );
                            }
                        },
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 12) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "140px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "140px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
            ],
            hideCompleted: 1,
            msg: "",
            email: null,
            showDetails: "none",
            dataheight: null,
            notes: "",
            files: null,
            file1: null,
            file2: null,
            cardValue: "500.00",
            file31: null,
            file32: null,
            file41: null,
            file42: null,
            rowSelected: [],
            data: [["Loading Data..."]],

            isLoading: false,
            page: 0,
            count: 1,
            rowsPerPage: 50,
            sortOrder: {
                name: 'individualID',                  // default sort by column dateUserCreated in desc order
                direction: 'asc'
            },
            filters: {},
            searchText: '',
            searchTimerID: 0,
            handleSearch: 0,
            downloadFile: 0,
            downloadFileAll: 0,
            selectedRowFirstName: '',
            selectedRowLastName: '',
            options: {
                download: false,
                downloadOptions: {
                    filename: 'tableDownload.csv',
                    separator: ',',
                    filterOptions: {
                        useDisplayedColumnsOnly: true,          // downloads only columns that are visible (e.g. after filtering)
                        useDisplayedRowsOnly: false              // downloads only rows that are visible (e.g. after filtering)
                    }                                         // default false - downloads all data
                },
                selectableRows: false,
                selectableRowsOnClick: false,
                filter: true,
                sort: true,
                responsive: 'standard', //'scroll', //'scrollMaxHeight',
                serverSide: true,
                viewColumns: false,
                expandableRows: true,
                // setRowProps: row => {
                //     if (row[18].props && row[18].props.class === 'pstatus') {
                //         return {
                //             style: { background: '#ffe1b9' }
                //         };
                //     }
                // },
                renderExpandableRow: (rowData, rowMeta) => {
                    console.log("rowData",rowData);
                    return (
                        <tr>
                            <td colSpan={10}>
                                <TableContainer className="asoc-nested-table" component={Paper}>
                                    <Table aria-label="purchases">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell><b>Memo - Property Address</b></TableCell>
                                                <TableCell><b>SPA Assignment</b></TableCell>
                                                <TableCell><b>Date Created</b></TableCell>
                                                <TableCell><b>Time Created</b></TableCell>
                                                <TableCell><b>Program</b></TableCell>
                                                {/* <TableCell><b>Recipient</b></TableCell> */}
                                                <TableCell><b>Payment Date</b></TableCell>
                                                <TableCell><b>Bulk Update Id</b></TableCell>
                                                <TableCell><b>Receiver Company</b></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell scope="row">
                                                    {rowData[9]}
                                                </TableCell>
                                                <TableCell>  {rowData[10]}</TableCell>
                                                <TableCell> {rowData[11]}</TableCell>
                                                <TableCell>
                                                    {rowData[12]}
                                                </TableCell>
                                                
                                                <TableCell>
                                                    {rowData[13]}
                                                </TableCell>
                                                {/* <TableCell>
                                                    {rowData[12]}
                                                </TableCell> */}
                                                <TableCell>
                                                    {this.state.nextFriday}
                                                </TableCell>
                                                <TableCell>
                                                    {rowData[15]}
                                                </TableCell>
                                                <TableCell>
                                                    {rowData[16]}
                                                </TableCell>

                                            </TableRow>
                                            {/* <TableRow>
                                                <TableCell><b>Date Created</b></TableCell>
                                                <TableCell><b>Time Created</b></TableCell>
                                                <TableCell><b>Date Modified</b></TableCell>
                                                <TableCell><b>Time Modified</b></TableCell>
                                                <TableCell><b>Created By</b></TableCell>
                                                <TableCell><b>Modified By</b></TableCell>
                                            </TableRow> */}
                                            {/* <TableRow>
                                                {/* <TableCell>
                                                    {rowData[10]}
                                                </TableCell>
                                                <TableCell>
                                                    {rowData[11]}
                                                </TableCell>
                                                <TableCell>
                                                    {rowData[12]}
                                                </TableCell>
                                                <TableCell>
                                                    {rowData[13]}
                                                </TableCell>
                                                <TableCell>
                                                    {rowData[14]}
                                                </TableCell>
                                                <TableCell>
                                                    {rowData[15]}
                                                </TableCell> */}
                                            {/* </TableRow>
                                            <TableRow>
                                                <TableCell colspan="6"><b>Notes</b></TableCell>
                                            </TableRow>
                                            <TableRow> */}
                                                {/* <TableCell colspan="6" component="th" scope="row">
                                                    {rowData[21].props.children === '' || rowData[21].props.children === null ? '-' : rowData[21]}
                                                </TableCell> */}
                                            {/* </TableRow> */} 
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </td>
                        </tr>
                    );
                },
                // makes it so filters have to be "confirmed" before being applied to the 
                // table's internal filterList
                confirmFilters: true,

                // Calling the applyNewFilters parameter applies the selected filters to the table 
                customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
                    //console.log('customFilterDialogFooter');
                    return (
                        <div style={{ marginTop: '40px' }}>
                            <Button variant="contained" onClick={() => this.handleFilterSubmit(applyNewFilters)}>Apply Filters</Button>
                        </div>
                    );
                },

                // callback that gets executed when filters are confirmed
                onFilterConfirm: (filterList) => {
                    //console.log('onFilterConfirm');
                    console.dir("filterList: " + filterList);
                },

                onFilterDialogOpen: () => {
                    //console.log('filter dialog opened');
                },
                onFilterDialogClose: () => {
                    //console.log('filter dialog closed');
                },
                onFilterChange: (column, filterList, type) => {
                    //console.log('onFilterChange');
                    //console.log("column: " + column);
                    //console.log("filterList: " + filterList);
                    //console.log("type: " + type);
                    this.setState({ filters: filterList, page: 0 });
                    if (type === 'chip') {
                        var newFilters = () => (filterList);
                        //console.log('updating filters via chip');
                        this.handleFilterSubmit(newFilters);
                    }
                },

                pagination: true,
                count: 1,
                rowsPerPage: 50,
                rowsPerPageOptions: [10, 20, 50],
                sortOrder: {
                    name: 'dateImported',                  // default sort by column dateUserCreated in desc order
                    direction: 'desc'
                },

                onTableChange: (action, tableState) => {

                    //console.log(action, tableState);

                    // a developer could react to change on an action basis or
                    // examine the state as a whole and do whatever they want

                    switch (action) {
                        case 'changePage':
                            this.changePage(tableState.page, tableState.sortOrder);
                            break;
                        case 'sort':
                            this.sort(tableState.page, tableState.sortOrder);
                            break;
                        case 'changeRowsPerPage':
                            this.changeRowsPerPage(tableState.page, tableState.sortOrder, tableState.rowsPerPage);
                            break;
                        case 'search':
                            this.OnSearchTextChange(tableState.searchText);
                            break;
                        default:
                        //console.log('action not handled - ' + action);
                    }
                },
                onViewColumnsChange: (column, action) => {
                    //console.log(column, action);
                    if (column !== null) {
                        this.state.columns.filter(c => c.name === column)[0].options.display = (action === 'add') ? true : false;
                    }
                },
                onRowClick: (rowData, rowState) => {
                    this.setState({ rowClickProcessed: false });
                    console.log(rowData, rowState);

                    if (rowData[10] !== null && rowData[10].props !== undefined) {
                        if (rowData[10].props["title"] !== undefined) {
                            this.setState({
                                individualID: rowData[10].props["title"]
                            });
                        }
                        else {
                            this.setState({
                                individualID: rowData[10].props["children"]
                            });
                        }
                    }
                    else {
                        this.setState({
                            individualID: rowData[10]
                        });
                    }

                    console.log(rowData[10].props["children"]);
                    console.log(this.state.individualID);
                    this.setState({ rowClickProcessed: true });
                },
                fixedHeader: true,
                fixedSelectColumn: true,
                tableBodyHeight: 'calc(100vh - 242px)',
                print: false,
                customToolbar: () => {
                    return (
                        <CustomToolbar refreshCallback={this.OnRefreshClick.bind(this)} downloadCallback={this.OnDownloadClick.bind(this)} downloadAllCallback={this.OnDownloadClickAll.bind(this)} isERAClaims={false} isTran={true} />
                    );
                },
                searchText: '',
                // searchProps: {
                //   onBlur: (e) => {
                //     //console.log('onBlur!');
                //     this.OnSearchIdleTimeout(this.state.searchText);
                //   },
                //   onKeyUp: (e) => {
                //     //console.log('onKeyUp!');
                //     if (e.keyCode === 13) { // Enter pressed
                //       this.OnSearchIdleTimeout(this.state.searchText);
                //     }
                //   },
                // },
                searchPlaceholder: 'Search Text',
                onSearchClose: () => {
                    //console.log('onSearchClose - ' + this.state.searchTimerID);
                    clearTimeout(this.state.searchTimerID);

                    this.setState({
                        isLoading: false,
                        handleSearch: 0,
                        searchText: '',
                    });

                    this.OnRefreshClick();
                },
                customSearchRender: (searchText, handleSearch, hideSearch, options) => {
                    return (
                        <TableSearch
                            searchText={searchText}
                            onSearch={handleSearch}
                            onHide={hideSearch}
                            options={options}
                            onSearchClick={this.OnSearchClick}
                        />
                    );
                },

                individualID: 0,
                rowClickProcessed: false,
            },
            keyring: {},

        }

    }

    

    setKmsKeyring = async () => {
        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayName;
        let path = '/getuisettings';
        let myInit = {
            headers: {
                Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`
            }
        }
        let res1 = await API.get(apiName, path, myInit);
        let accessKeyId = res1 ? await decryptValue(res1.a) : '';
        let secretAccessKey = res1 ? await decryptValue(res1.b) : '';
        AWS.config.credentials = new AWS.Credentials(
            accessKeyId,
            secretAccessKey
        );
        //AWS.config.region = process.env.REACT_APP_Region;
        let AWSCreds = await AWS.config.credentials.get();
        /* The KMS keyring must be configured with the desired CMKs */
        let clientProvider = getClient(KMS, {
            AWSCreds,
            region: process.env.REACT_APP_Region
        });

        let keyring = new KmsKeyringBrowser({
            clientProvider,
            generatorKeyId: process.env.REACT_APP_AWS_KMS_KeyId
        });
        keyring.Credentials = {
            AccessKeyId: accessKeyId,
            SecretAccessKey: secretAccessKey
        };
        return keyring;
    }

    getMuiTheme = () => createMuiTheme({
        overrides: {
            MUIDataTableToolbar: {
                actions: {
                    display: "flex",
                    flex: "initial",
                    alignItems: "center",
                    // move all icons to the right
                    "& > span, & > button": {
                        order: 99
                    },
                    // target the custom toolbar icon
                    "& > span:last-child, & > button:last-child": {
                        order: 1
                    },
                    "& > .MuiPaper-elevation1": {
                        order: 999
                    }
                    // // target any icon
                    // "& > span:nth-child(4), & > button:nth-child(4)": {
                    //   order: 2
                    // }
                }
            },
            MuiIconButton: {
                root: {
                    zIndex: "10",
                }
            },
            MuiTooltip: {
                tooltip: {
                    fontSize: "0.85em",
                }
            },
            MuiTableCell: {
                head: {
                    //width:'190px',
                }
            },
            MUIDataTableHeadCell: {
                root: {
                    // overflow: "hidden",
                    // whiteSpace: 'nowrap',
                    // textOverflow: 'ellipsis',
                    // position: 'relative',
                    fontWeight: "bold",
                }
            },
            MUIDataTableBodyCell: {
                root: {
                    // overflow: "hidden",
                    // whiteSpace: 'nowrap',
                    // textOverflow: 'ellipsis',
                    // position: 'relative',
                    //width:'200px'
                }
            },
            MUIDataTableBody: {
                root: {
                }
            },
            MUIDataTableColumn: {
                row: {
                }
            },
            MUIDataTablePagination: {
                root: {
                },
                caption: {
                }
            }
        }
    })

     nextWeekdayDate = (date, day_in_week) => {
        var nextDay = new Date(date||new Date());
        nextDay.setDate(nextDay.getDate() + (day_in_week - 1 - nextDay.getDay() + 7) % 7 + 1);
        return nextDay;
      }     

    getData = async () => {
        this.setState({ isLoading: true });
        // var retrievedObject = localStorage.getItem('retainObject');

        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayName;
        console.log(apiName);
        let path = '/getsantaanaapplicantreconciliation';
        let myInit = {};

        var filterdict = {}; // create an object

        for (var ii = 0; ii < headers.length; ii++) {
            if (this.state.filters[ii] && this.state.filters[ii].length > 0) {
                filterdict[`${headers[ii]}`] = `${this.state.filters[ii]}`;
            }
        }

        //console.log("JSON filterdict: " + JSON.stringify(filterdict));

        myInit = { // OPTIONAL
            body: {
                "info": {
                    "page": this.state.page,
                    "sortField": this.state.sortOrder.name,
                    "sortDir": this.state.sortOrder.direction,
                    "rowsPerPage": this.state.rowsPerPage,
                    "downloadFile": this.state.downloadFile,
                    "downloadFileAll": this.state.downloadFileAll,
                    "filters": JSON.stringify(filterdict),
                    "handleSearch": this.state.handleSearch,
                    "searchText": this.state.searchText,
                }
            }, // replace this with attributes you need
            headers: {
                Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`
            }
        }
        return await API.post(apiName, path, myInit);
    }

    getTransactionReportData = async () => {
      this.setState({ isLoading: true });
      // var retrievedObject = localStorage.getItem('retainObject');

      var user = await  this.getCurrentUser();
      let apiName = process.env.REACT_APP_APIGatewayName;
      console.log(apiName);
      let path = '/getsantaanatransactionreport';
      let myInit = {};

      var filterdict = {}; // create an object

      for (var ii = 0; ii < headers.length; ii++) {
          if (this.state.filters[ii] && this.state.filters[ii].length > 0) {
              filterdict[`${headers[ii]}`] = `${this.state.filters[ii]}`;
          }
      }

      //console.log("JSON filterdict: " + JSON.stringify(filterdict));

      myInit = { // OPTIONAL
          body: {
              "info": {
                  "page": this.state.page,
                  "sortField": this.state.sortOrder.name,
                  "sortDir": this.state.sortOrder.direction,
                  "rowsPerPage": this.state.rowsPerPage,
                  "downloadFile": this.state.downloadFile,
                  "downloadFileAll": this.state.downloadFileAll,
                  "filters": JSON.stringify(filterdict),
                  "handleSearch": this.state.handleSearch,
                  "searchText": this.state.searchText,
              }
          }, // replace this with attributes you need
          headers: {
              Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`
          }
      }
      return await API.post(apiName, path, myInit);
  }

    postData = async () => {
        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayName;
        let path = '/getsantaanaclaim';
        let myInit = { // OPTIONAL
            body: {
                "info": {
                    "email": this.state.email,
                    "adminUsername": user.attributes.email
                }
            }, // replace this with attributes you need
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
        }
        return await API.post(apiName, path, myInit);
    };

    encryptData = async (data) => {
        const { result } = await encrypt(this.state.keyring, util_utf8_node.fromUtf8(data));
        return toBase64(result);
    }

    decryptData = async (data) => {
        const { plaintext, messageHeader } = await decrypt(this.state.keyring, fromBase64(data));
        return util_utf8_node.toUtf8(plaintext);
    }

    //updateAccountNoRoutingNoData
    updateAccountNoRoutingNoData = async (res) => {
        return new Promise(async (resolve, reject) => {
            let len = res['data'].length;
            console.log("res['data'].length");
            console.log(res['data'].length);
            let isAccNoComplete = false;
            let isRoutingNoComplete = false;
            for (let index = 0; index < len; index++) {
                if (res['data'][index][5] && res['data'][index][5] !== null && res['data'][index][5] !== undefined && res['data'][index][5] !== "") {
                    await this.decryptData(res['data'][index][5]).then((isGetResult) => {
                        res['data'][index][5] = isGetResult;
                        isAccNoComplete = (len - 1) == index ? true : false;
                    });
                } else {
                    isAccNoComplete = (len - 1) == index ? true : false;
                }
                if (res['data'][index][6] && res['data'][index][6] !== null && res['data'][index][6] !== undefined && res['data'][index][6] !== "") {
                    await this.decryptData(res['data'][index][6]).then((isGetResult1) => {
                        res['data'][index][6] = isGetResult1;
                        isRoutingNoComplete = (len - 1) == index ? true : false;
                    });
                } else {
                    isRoutingNoComplete = (len - 1) == index ? true : false;
                }
                // console.log('isAccNoComplete');
                // console.log(isAccNoComplete)
                // console.log('isRoutingNoComplete');
                // console.log(isRoutingNoComplete)
                // console.log("res['data'][index][3]");
                // console.log(res['data'][index][3])
                // console.log("res['data'][index][4]");
                // console.log(res['data'][index][4])
                if (isAccNoComplete && isRoutingNoComplete)
                    resolve(true);
            }

        });
    }

    componentDidMount = async () => {
        this.props.parentCallback("Santa Ana Transaction", "", imgSearchclaim);
        var height;
        if (isMobile) {
            height = "60vh";
        }
        else {
            height = "70vh"
        }
        this.setState({ keyring: await this.setKmsKeyring() });
        var res = await this.getTransactionReportData();
        if (res && res['total'] > 0) {
            this.updateAccountNoRoutingNoData(res).then((isGetResult) => {
                if (isGetResult) {
                    console.log('res2');
                    console.log(res);
                    this.setState({ data: res.data, isLoading: false, page: res.page, count: res.total });
                }
            });
        } else {
            this.setState({ data: res.data, isLoading: false, page: res.page, count: res.total });
        }
        
        console.log('res1');
        console.log(res);
    }  

    
    
    sort = (page, sortOrder) => {
        this.setState({ isLoading: true, sortOrder: sortOrder });
        this.getTransactionReportData().then(res => {
            if (res && res['total'] > 0) {
                this.updateAccountNoRoutingNoData(res).then((isGetResult) => {
                    if (isGetResult) {
                        console.log('resSort');
                        console.log(res);
                        this.setState({
                            data: res.data,
                            page: res.page,
                            count: res.total,
                            sortOrder: sortOrder,
                            isLoading: false,
                        });
                    }
                });
            } else {
                this.setState({ data: res.data, isLoading: false, page: res.page, count: res.total });
            }
        });
    }

    changePage = (page, sortOrder) => {
        this.setState({
            isLoading: true,
            page: page,
            sortOrder: sortOrder,
        });
        this.getTransactionReportData().then(res => {
            if (res && res['total'] > 0) {
                this.updateAccountNoRoutingNoData(res).then((isGetResult) => {
                    if (isGetResult) {
                        console.log('reschangePage');
                        console.log(res);
                        this.setState({
                            isLoading: false,
                            data: res.data,
                            page: res.page,
                            count: res.total,
                            sortOrder: sortOrder,
                        });
                    }
                });
            } else {
                this.setState({ data: res.data, isLoading: false, page: res.page, count: res.total });
            }
        });
    };

    changeRowsPerPage = (page, sortOrder, rowsPerPage) => {
        this.setState({
            isLoading: true,
            page: 0,
            rowsPerPage: rowsPerPage,
            sortOrder: sortOrder
        });
        this.getTransactionReportData().then(res => {
            if (res && res['total'] > 0) {
                this.updateAccountNoRoutingNoData(res).then((isGetResult) => {
                    if (isGetResult) {
                        console.log('reschangeRowsPerPage');
                        console.log(res);
                        this.setState({
                            isLoading: false,
                            sortOrder: sortOrder,
                            data: res.data,
                            page: res.page,
                            count: res.total,
                        });
                    }
                });
            } else {
                this.setState({ data: res.data, isLoading: false, page: res.page, count: res.total });
            }
        });
    };

    delay = () => {
        return new Promise((resolve, reject) => {
            window.setTimeout(
                () => {
                    resolve();
                },
                2000
            );
        });
    }

    testLoad = async () => {
        this.setState({ isLoading: true });

        while (true) {
            var res = await this.delay();
            if (this.state.rowClickProcessed === true) {
                break;
            }
        }

        console.log(this.state.individualID);
        this.setState({ isLoading: false, rowClickProcessed: false });

        // // Render the record details page
        // ReactDOM.render(<RecordDetails individualID={this.state.individualID} />, document.getElementById("root"));

        this.props.showRecordDetails(this.state.individualID);
    }

    OnSearchClick = async () => {
        this.OnSearchIdleTimeout(this.state.searchText)
    }

    refresh = async () => {
        //console.log("Refresh!!!");
        var res = await this.getTransactionReportData();
        if (res && res['total'] > 0) {
            this.updateAccountNoRoutingNoData(res).then((isGetResult) => {
                if (isGetResult) {
                    console.log('resrefresh');
                    console.log(res);
                    this.setState({
                        isLoading: false,
                        data: res.data,
                        page: res.page,
                        count: res.total,
                    })
                }
            });
        } else {
            this.setState({ data: res.data, isLoading: false, page: res.page, count: res.total });
        }
    }

    OnRefreshClick = async () => {
        //console.log("OnRefreshClick!!!");
        await this.refresh();
    }

    componentDidUpdate() {
    }

    handleFilterSubmit = applyFilters => {
        let filterList = applyFilters();
        //console.log('handleFilterSubmit');
        //console.log('filterList: ' + filterList);
        this.setState({ isLoading: true, filters: filterList, page: 0 });
        this.getTransactionReportData().then(res => {
            if (res && res['total'] > 0) {
                this.updateAccountNoRoutingNoData(res).then((isGetResult) => {
                    if (isGetResult) {
                        console.log('reshandleFilterSubmit');
                        console.log(res);
                        this.setState({
                            isLoading: false,
                            data: res.data,
                            page: res.page,
                            count: res.total,
                        });
                        
                    }
                });
            } else {
                this.setState({ data: res.data, isLoading: false, page: res.page, count: res.total });
            }
        });
    };

    OnSearchTextChange(searchQuery) {
        this.setState({ searchText: searchQuery });
    }

    OnSearchIdleTimeout = async (searchQuery) => {
        if (searchQuery !== null && searchQuery !== 'undefined') {
            // console.log('OnSearchIdleTimeout - ' + searchQuery);

            this.setState({ searchText: searchQuery });

            this.setState({
                isLoading: true,
                handleSearch: 1,
                searchText: searchQuery
            });
            this.state.options.searchText = searchQuery;

            var res = await this.getTransactionReportData();

            //console.log('SearchResult');
            // console.log(res.data);
            if (res && res['total'] > 0) {
                this.updateAccountNoRoutingNoData(res).then((isGetResult) => {
                    if (isGetResult) {
                        console.log('resOnSearchIdleTimeout');
                        console.log(res);
                        this.setState({
                            isLoading: false,
                            //handleSearch: 0,
                            //searchText: '',
                            data: res.data,
                            page: res.page,
                            count: res.total,
                        });
                    }
                });
            } else {
                this.setState({ data: res.data, isLoading: false, page: res.page, count: res.total });
            }
        }
    }

    escapeDangerousCSVCharacters(data) {
        if (typeof data === 'string') {
            // Places single quote before the appearance of dangerous characters if they
            // are the first in the data string.
            return data.replace(/^\+|^\-|^\=|^\@/g, "'$&");
        }

        return data;
    }

    buildCSV(columns, data, options) {
        const replaceDoubleQuoteInString = columnData =>
            typeof columnData === 'string' ? columnData.replace(/\"/g, '""') : columnData;

        const buildHead = bColumns => {
            return (
                bColumns
                    .reduce(
                        (soFar, column) =>
                            column.options.download
                                ? soFar +
                                '"' +
                                this.escapeDangerousCSVCharacters(replaceDoubleQuoteInString(column.label || column.name)) +
                                '"' +
                                options.downloadOptions.separator
                                : soFar,
                        '',
                    )
                    .slice(0, -1) + '\r\n'
            );
        };
        const CSVHead = buildHead(columns);

        //console.log('buildCSV');
        //console.log(columns);

        const buildBody = bData => {
            if (!bData.length) return '';
            return bData
                .reduce(
                    (soFar, row) =>
                        soFar +
                        '"' +
                        row.data
                            .filter((_, index) => columns[index].options.download)
                            .map(columnData => this.escapeDangerousCSVCharacters(replaceDoubleQuoteInString(columnData)))
                            .join('"' + options.downloadOptions.separator + '"') +
                        '"\r\n',
                    '',
                )
                .trim();
        };
        const CSVBody = buildBody(data);

        // const csv = options.onDownload
        //   ? options.onDownload(buildHead, buildBody, columns, data)
        //   : `${CSVHead}${CSVBody}`.trim();

        const csv = `${CSVHead}${CSVBody}`.trim();

        //console.log('csv');
        //console.log(csv);
        return csv;
    }

    downloadCSV(csv, filename) {
        const blob = new Blob([csv], { type: 'text/csv' });

        /* taken from react-csv */
        if (navigator && navigator.msSaveOrOpenBlob) {
            navigator.msSaveOrOpenBlob(blob, filename);
        } else {
            const dataURI = `data:text/csv;charset=utf-8,${csv}`;

            const URL = window.URL || window.webkitURL;
            const downloadURI = typeof URL.createObjectURL === 'undefined' ? dataURI : URL.createObjectURL(blob);

            let link = document.createElement('a');
            link.setAttribute('href', downloadURI);
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    createCSVDownload(columns, data, options) {
        const csv = this.buildCSV(columns, data, options);

        // if (options.onDownload && csv === false) {
        //   return;
        // }

        this.downloadCSV(csv, options.downloadOptions.filename);
    }

    OnHideCompletedClick = async () => {

        let newState = 1;
        if (this.state.hideCompleted === 1) {
            newState = 0;
        }
        else {
            newState = 1;
        }

        this.setState({ hideCompleted: newState });

        this.setState({
            isLoading: true,
        });

        var res = await this.getTransactionReportData();
        if (res && res['total'] > 0) {
            this.updateAccountNoRoutingNoData(res).then((isGetResult) => {
                if (isGetResult) {
                    console.log('resOnHideCompletedClick');
                    console.log(res);
                    this.setState({
                        isLoading: false,
                        data: res.data,
                        page: res.page,
                        count: res.total,
                    });
                    
                }
            });
        } else {
            this.setState({ data: res.data, isLoading: false, page: res.page, count: res.total });
        }
    }

    OnDownloadClick = async () => {
        //console.log("OnDownloadClick!!! - " + this.state.page);

        const { data, columns, options } = this.state;
        let displayData = this.state.data;


        this.setState({
            isLoading: true,
            downloadFile: 1,
        });

        var res = await this.getTransactionReportData();
        // if (res && res['total'] > 0) {
        //     this.updateAccountNoRoutingNoData(res).then(async (isGetResult) => {
        //         if (isGetResult) {
        //             console.log('resOnDownloadClick');
        //             console.log(res);
        //             this.setState({
        //                 isLoading: false,
        //                 downloadFile: 0,
        //             });
            
        //             Storage.configure({
        //                 bucket: process.env.REACT_APP_S3FrontendBucket,
        //                 region: process.env.REACT_APP_Region,
        //                 level: 'public',
        //             });
            
        //             var file1 = await Storage.get(`SantaAnaTransaction/CSVExport/${res.filename}`);
        //             console.log(file1);
            
        //             let link = document.createElement('a');
        //             link.href = file1;
        //             link.download = file1;
        //             link.click();
        //         }
        //     });
        // } else {
        //     this.setState({ data: res.data, isLoading: false, page: res.page, count: res.total });
        // }
        console.log('resOnDownloadClick');
        console.log(res);
        this.setState({
            isLoading: false,
            downloadFile: 0,
        });

        Storage.configure({
            bucket: process.env.REACT_APP_S3FrontendBucket,
            region: process.env.REACT_APP_Region,
            level: 'public',
        });

        var file1 = await Storage.get(`SantaAnaTransaction/CSVExport/${res.filename}`);
        console.log(file1);

        let link = document.createElement('a');
        link.href = file1;
        link.download = file1;
        link.click();
        var respon = await Storage.remove(`SantaAnaTransaction/CSVExport/${res.filename}`);
        console.log('respon');
        console.log(respon);
    }

    OnDownloadClickAll = async () => {
        //console.log("OnDownloadClick!!! - " + this.state.page);

        const { data, columns, options } = this.state;
        let displayData = this.state.data;


        this.setState({
            isLoading: true,
            downloadFile: 1,
            downloadFileAll: 1,
        });

        var res = await this.getTransactionReportData();
        // if (res && res['total'] > 0) {
        //     this.updateAccountNoRoutingNoData(res).then(async (isGetResult) => {
        //         if (isGetResult) {
        //             console.log('resOnDownloadClick');
        //             console.log(res);
        //             this.setState({
        //                 isLoading: false,
        //                 downloadFile: 0,
        //             });
            
        //             Storage.configure({
        //                 bucket: process.env.REACT_APP_S3FrontendBucket,
        //                 region: process.env.REACT_APP_Region,
        //                 level: 'public',
        //             });
            
        //             var file1 = await Storage.get(`SantaAnaTransaction/CSVExport/${res.filename}`);
        //             console.log(file1);
            
        //             let link = document.createElement('a');
        //             link.href = file1;
        //             link.download = file1;
        //             link.click();
        //         }
        //     });
        // } else {
        //     this.setState({ data: res.data, isLoading: false, page: res.page, count: res.total });
        // }
        console.log('resOnDownloadClick');
        console.log(res);
        this.setState({
            isLoading: false,
            downloadFile: 0,
            downloadFileAll: 0
        });

        Storage.configure({
            bucket: process.env.REACT_APP_S3FrontendBucket,
            region: process.env.REACT_APP_Region,
            level: 'public',
        });

        var file1 = await Storage.get(`SantaAnaTransaction/CSVExport/${res.filename}`);
        console.log(file1);

        let link = document.createElement('a');
        link.href = file1;
        link.download = file1;
       // link.target="_blank";
        link.click();
        var respon = await Storage.remove(`SantaAnaTransaction/CSVExport/${res.filename}`);
        console.log('respon');
        console.log(respon);
    }

    signOut = async () => {
        try {
            await Auth.signOut()
            // signout success
        }
        catch (e) {
            console.log(e)
        }
    }

    getCurrentUser = async () => {
        try {
          var user = await Auth.currentAuthenticatedUser();
          return user;
        }
        catch(e){
          this.setState({ isLoading: false });
          if(e === "not authenticated") {
            await this.signOut();
          }
        }
      }

    getFileIconType(filename) {
        let extension = /(?:\.([^.]+))?$/.exec(filename)[1];

        switch (extension) {
            case 'pdf':
                return 'fas fa-file-pdf';
            case 'jpeg':
            case 'jpg':
            case 'png':
            case 'gif':
            case 'bmp':
                return 'fas fa-file-image';
            case 'txt':
                return 'fas fa-file-alt';
            case 'doc':
            case 'docx':
                return 'fas fa-file-word';
            case 'xls':
            case 'xlsx':
                return 'fas fa-file-excel';
            case 'ppt':
            case 'pptx':
                return 'fas fa-file-powerpoint';
            default: // any other file
                return 'fas fa-file';
        }
    }

    updatePanels = (n) => {
        if (n === 1) {
            this.setState({ showAddUser: "block" });
            this.setState({ showSubmitClaim: "none" });
            this.setState({ showLogin: "none" });
            this.setState({ showIrvineClaims: "none" });
        }
        else if (n === 2) {
            this.setState({ showAddUser: "none" });
            this.setState({ showLogin: "block" });
            this.setState({ showIrvineClaims: "none" });
        }
        else if (n === 3) {
            this.setState({ showSubmitClaim: "block" });
            this.setState({ showAddUser: "none" });
            this.setState({ showLogin: "none" });
            this.setState({ showIrvineClaims: "none" });
        }
        else if (n === 4) {
            this.setState({ showCeppClaims: "none" });
            this.setState({ showSubmitClaim: "none" });
            this.setState({ showLogin: "block" });
            this.setState({ showIrvineClaims: "none" });
        }
        else if (n === 5) {
            this.setState({ showIrvineClaims: "block" });
            this.setState({ showCeppClaims: "none" });
            this.setState({ showSubmitClaim: "none" });
            this.setState({ showLogin: "none" });
        }
    }

    dummyRequest = () => {
        return new Promise((resolve, reject) => {
            window.setTimeout(
                () => {
                    resolve();
                },
                5000
            );
        });
    }

    close = () => {
        this.props.updateFunction(4);
        this.setState({ email: "" });
        this.closeDetails();
    }


    render() {

        this.state.options.page = this.state.page;
        this.state.options.sortOrder = this.state.sortOrder;
        this.state.options.count = this.state.count;
        this.state.options.rowsPerPage = this.state.rowsPerPage;
        this.state.options.searchText = this.state.searchText;
        document.body.classList.remove('dashboard');

        return (

            <div className="d-flex flex-column sticky-footer-wrapper">

                {/* main content starts */}
                {/* <main className="main flex-fill mt-2 mt-md-3">
                <div className="container-fluid"> */}

                {/* content area starts */}
                <div className="search-claims-form w-100 h-100 p-0 cepp-grid">
                    <h4 className="font-weight-bold text-uppercase text-primary d-block d-sm-none py-2" style={{ whiteSpace: 'nowrap' }}>
                        <svg className="icon-title" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x={0} y={0} viewBox="0 0 45.6 48" xmlSpace="preserve"><style dangerouslySetInnerHTML={{ __html: ".st0{fill:#005191}" }} /><path className="st0" d="M6.1 11.6h3.8v3.8H6.1zM31.3 6.5h-5.9V.6z" /><path className="st0" d="M24.4 8.5c-.6 0-1-.5-1-1V0H1C.4 0 0 .5 0 1v39.1c0 .5.4 1 1 1h19.3l-.3-.3c-2.5-2.5-3.8-5.7-3.8-9.2 0-3.5 1.3-6.7 3.8-9.2 2.5-2.5 5.7-3.8 9.2-3.8.9 0 1.7.1 2.6.3V8.5h-7.4zM4.1 10.6c0-.6.4-1 1-1h5.8c.6 0 1 .4 1 1v5.8c0 .5-.4 1-1 1H5.1c-.6 0-1-.5-1-1v-5.8zm8.8 21.6H5.1c-.6 0-1-.4-1-1 0-.5.4-1 1-1h7.8c.6 0 1 .5 1 1s-.4 1-1 1zm0-3.7H5.1c-.6 0-1-.5-1-1 0-.6.4-1 1-1h7.8c.6 0 1 .4 1 1 0 .5-.4 1-1 1zm0-3.8H5.1c-.6 0-1-.5-1-1s.4-1 1-1h7.8c.6 0 1 .5 1 1s-.4 1-1 1zm0-3.7H5.1c-.6 0-1-.5-1-1 0-.6.4-1 1-1h7.8c.6 0 1 .4 1 1s-.4 1-1 1zm8.6-4.3h-7.6c-.6 0-1-.5-1-1 0-.6.4-1 1-1h7.6c.6 0 1 .4 1 1s-.5 1-1 1zm0-4.4h-7.6c-.6 0-1-.4-1-1 0-.5.4-1 1-1h7.6c.6 0 1 .5 1 1 0 .6-.5 1-1 1z" /><path className="st0" d="M44.7 42.5L39 36.8c.8-1.6 1.3-3.3 1.3-5.2 0-2.9-1.2-5.7-3.2-7.8-2.1-2.1-4.8-3.2-7.8-3.2-2.9 0-5.7 1.2-7.8 3.2-2.1 2.1-3.2 4.8-3.2 7.8 0 2.9 1.1 5.7 3.2 7.8 2.2 2.1 5 3.2 7.8 3.2 1.8 0 3.5-.4 5.1-1.3l5.7 5.7c.6.6 1.5.9 2.3.9.8 0 1.7-.3 2.3-1 1.3-1.1 1.3-3.1 0-4.4zM22.9 38c-1.7-1.7-2.6-4-2.6-6.4 0-2.4.9-4.7 2.6-6.4 1.7-1.7 4-2.6 6.4-2.6 2.4 0 4.7.9 6.4 2.6s2.6 4 2.6 6.4c0 2.4-.9 4.7-2.6 6.4-3.6 3.5-9.3 3.5-12.8 0z" /></svg>
                        Search Claim ERA-Santa Ana
                    </h4>

                    <div className="data-section">
                        <div className="data-table" style={{ height: this.state.dataheight }}>
                            <React.Fragment>
                                <div className="mui-table-border">
                                    <MuiThemeProvider theme={this.getMuiTheme()}>
                                        <MUIDataTable data={this.state.data} columns={this.state.columns} options={this.state.options} components={{ TableViewCol: TableViewCol }} />
                                    </MuiThemeProvider>
                                </div>
                            </React.Fragment>
                        </div>
                    </div>
                </div>
                {/* content area ends */}
                <Backdrop className="MuiBackdrop-root" open={this.state.isLoading} >
                    <CircularProgress />
                </Backdrop>
                {/* </div>
                    </main> */}
                {/* main content ends */}

            </div>

        );
    }
};

export default SantaAnaApplicantTransaction;