import React from "react";
import { Auth, API, Storage } from 'aws-amplify';
import imgLogo from '../imgs/logo-assist-oc.png';
import Tooltip from '@material-ui/core/Tooltip';
import { CircularProgress, Button } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import ReactDOM from 'react-dom';
import App from "../App";

import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import imgSearchclaim from '../imgs/search-claims.svg';
import Snackbar from '@material-ui/core/Snackbar';
import $ from 'jquery';

class CeppClaimDetails extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            isLoading: false,
            permissions: [],
            authState: "loading",
            mode: "Admin",
            ///////////
            individualID: "0",

            firstName: null,
            lastName: null,
            partnerFirstName: null,
            partnerLastName: null,
            addressLine1: null,
            addressLine2: null,
            city: null,
            state: null,
            country: null,
            zipcode: null,
            clientDOB: null,
            phone: null,
            legallyAbleToWork: null,
            eligibleForStimulusFunds: null,
            veteran: null,
            monthlyIncomeBeforeCovid19: null,
            workIndustry: null,
            employmentStatus: null,
            effectOfCovid19Financially: null,
            householdNumber: "0",
            age0to5: "0",
            age6to17: "0",
            age18to59: "0",
            age60andUp: "0",
            cardValue: null,
            participateInSurvey: null,
            claimStatus: null,
            dateImported: null,
            dateImported_Formatted: null,
            timeImported: null,
            dateFromHIMS: null,
            timeFromHIMS: null,
            dateClaimModified: null,
            timeClaimModified: null,
            notes: null,
            adminUsername: null,
            originator: null,
            programTitle: null,
            spaAssignmentNo: null,
            hmisApproval: null,
            householdID: "0",
            transactionID: "0",
            landlordPhoneNumber: null,
            landlordEmail: null,
            landlordEmail_Original: null,
            amountDueforRent: null,
            payee: null,
            rentAmount: null,
            landlordBankName: null,
            landlordAccountNumber: null,
            landlordRoutingNumber: null,
            memoPropertyAddress: null,
            rentPaymentStatus: null,
            rentPaymentStatus_Original: null,
            electricProvider: null,
            electricUtilityAccountNo: null,
            amountDueforElectricity: null,
            custElectricBillingAccountNo: null,
            electricAmount: null,
            electricBankName: null,
            electricUtilityProviderAccountNo: null,
            electricRoutingNumber: null,
            electricMemoAccountNo: null,
            electricPaymentStatus: null,
            electricPaymentStatus_Original: null,
            gasProvider: null,
            gasUtilityAccountNo: null,
            amountDueforGas: null,
            custGasBillingAccountNo: null,
            gasBankName: null,
            gasAmount: null,
            gasUtilityProviderAccountNo: null,
            gasRoutingNumber: null,
            gasMemoAccountNo: null,
            gasPaymentStatus: null,
            gasPaymentStatus_Original: null,
            otherPaymentAmount: null,
            otherPaymentStatus: null,
            otherPaymentStatus_Original: null,

            dateEmailTriggered: null,
            timeEmailTriggered: null,
            dateRentPaymentStatusUpdated: null,
            timeRentPaymentStatusUpdated: null,
            dateElectricPaymentStatusUpdated: null,
            timeElectricPaymentStatusUpdated: null,
            dateGasPaymentStatusUpdated: null,
            timeGasPaymentStatusUpdated: null,
            dateOtherPaymentStatusUpdated: null,
            timeOtherPaymentStatusUpdated: null,

            currentTotalHouseholdIncome: null,
            openToFollowup: null,
            gender: null,
            race: null,
            ethnicity: null,
            spaServiceProviderEmail: null,
            noOfTimesParticipated: null,

            landlordSSN: null,
            landlordTaxID: null,
            landlordDUNSNumber: null,
            rentTypeOfAssistance: null,
            monthlyRent: null,
            percentageOfMonthlyRent: null,
            outstandingRentalArrearsAmount: null,
            noOfMonthsRentalPayments: null,

            electricUtilityTaxID: null,
            electricUtilityDUNSNumber: null,
            monthlyElectricBillAmount: null,
            percentageOfElectricBill: null,
            noOfMonthsElectricUtilityPayments: null,
            electricTypeOfAssistance: null,

            gasUtilityTaxID: null,
            gasUtilityDUNSNumber: null,
            monthlyGasBillAmount: null,
            percentageOfGasBill: null,
            noOfMonthsGasUtilityPayments: null,
            gasTypeOfAssistance: null,

            covid_other: null,
            industry_other: null,

            tenantEmail: null,
            dateApplied: null,
            individualBankName: null,
            individualAccountNumber: null,
            individualRoutingNumber: null,
            individualMemoPropertyAddress: null,
            individualPaymentReason: null,
            individualPaymentStatus: null,
            individualPaymentStatus_Original: null,
            dateIndividualPaymentStatusUpdated: null,
            timeIndividualPaymentStatusUpdated: null,
            otherDesription: null,
            otherBankName: null,
            otherAccountNumber: null,
            otherRoutingNumber: null,
            otherMemoPropertyAddress: null,
            otherPayeeName: null,
            individualPaymentAmount: null,

            waterProvider: null,
            waterUtilityAccountNo: null,
            custWaterBillingAccountNo: null,
            monthlyWaterBillAmount: null,
            noOfMonthsWaterUtilityPayments: null,
            waterTypeOfAssistance: null,
            amountDueforWater: null,
            waterAmount: null,
            percentageOfWaterBill: null,
            waterBankName: null,
            waterUtilityProviderAccountNo: null,
            waterRoutingNumber: null,
            waterMemoAccountNo: null,
            waterUtilityTaxID: null,
            waterUtilityDUNSNumber: null,
            waterPaymentStatus: null,
            waterPaymentStatus_Original: null,
            dateWaterPaymentStatusUpdated: null,
            timeWaterPaymentStatusUpdated: null,

            trashProvider: null,
            trashUtilityAccountNo: null,
            custTrashBillingAccountNo: null,
            monthlyTrashBillAmount: null,
            noOfMonthsTrashUtilityPayments: null,
            trashTypeOfAssistance: null,
            amountDueforTrash: null,
            trashAmount: null,
            percentageOfTrashBill: null,
            trashBankName: null,
            trashUtilityProviderAccountNo: null,
            trashRoutingNumber: null,
            trashMemoAccountNo: null,
            trashUtilityTaxID: null,
            trashUtilityDUNSNumber: null,
            trashPaymentStatus: null,
            trashPaymentStatus_Original: null,
            dateTrashPaymentStatusUpdated: null,
            timeTrashPaymentStatusUpdated: null,

            energyProvider: null,
            energyUtilityAccountNo: null,
            custEnergyBillingAccountNo: null,
            monthlyEnergyBillAmount: null,
            noOfMonthsEnergyUtilityPayments: null,
            energyTypeOfAssistance: null,
            amountDueforEnergy: null,
            energyAmount: null,
            percentageOfEnergyBill: null,
            energyBankName: null,
            energyUtilityProviderAccountNo: null,
            energyRoutingNumber: null,
            energyMemoAccountNo: null,
            energyUtilityTaxID: null,
            energyUtilityDUNSNumber: null,
            energyPaymentStatus: null,
            energyPaymentStatus_Original: null,
            dateEnergyPaymentStatusUpdated: null,
            timeEnergyPaymentStatusUpdated: null,

            suffix: null,
            middleName: null,
            agent: null,
            doYouRent: null,
            receiveAnyHousingSubsidy: null,
            isAnnualIncomeLess: null,
            isSomeoneOnRentalOrLeaseAgreement: null,
            filedCovid19FinancialStressDeclaration: null,
            receivedEvictionNotice: null,
            dateEvictionNoticeReceived: null,
            SSN: null,
            socialSecurityFirstName: null,
            socialSecurityLastName: null,
            birthdayCode: null,
            age: null,
            otherPhone: null,
            secondaryFirstName: null,
            secondaryLastName: null,
            secondaryEmail: null,
            secondaryPhone: null,

            belowThirtyPercAMI: null,
            thirtyToFiftyPercAMI: null,
            fiftyToEightyPercAMI: null,
            boardDistrict: null,
            landlordFirstAttempt: null,
            landlordResponse: null,

            individualCase: null,
            individualCase_Original: null,
            reasonPaymentNotProcessedIn21Days: null,

            uniqueID: null,

            countAdult: null,
            countChildren: null,
            householdApprovedForUnitedWay: null,
            landlordACHReceived: null,
            individualACHReceived: null,

            roundNumber: 1,

            requestedLandlordInfo: false,
            dateLandlordInfoRequested: '0000-00-00',
            dateLandlordInfoRequested_Formatted: '0000-00-00',
            timeLandlordInfoRequested: null,
            countLandlordInfoRequested: 0,
            dateLandlordInfoReminded: '0000-00-00',
            dateLandlordInfoReminded_Formatted: '0000-00-00',
            timeLandlordInfoReminded: null,

            total1099Amount: 0,

            totalCovered: 0,
            totalCoveredClone: 0,
            totalRecords: 1,

            ///////////

            lengthOfTimeForAward: null,
            timestampOfRentIssued: null,

            triggerEmail: "0",


            reconciliationEditId: 0,
            deleteReconciliationId: 0,
            reconciliationMode: '',
            isChildRecord: false,

            reconciliation: { "entityType": "", "initiallyPaidBy": "", "amountType": "", "amount": "", "memoPropertyAddress": "", "paymentMethod": "", "otherPaymentStatus": "", "amountCoveredAfterRec": "", "amountCoveredBeforeRec": "", "transactionNo": "", "notes": "" },
            reconciliationList: [],
            reconciliationListClone: [],
            reconciliationListByIndividualId: [],

            validationErrors: {},

            openSnackbar: false,
            snackbarSeverity: "",
            snackbarVertical: 'top',
            snackbarHorizontal: 'right',
            snackbarMessage: "",
            snackbarMessageClass: '',


            photo_identification_uploaded: false,
            proof_of_address_uploaded: false,
            proof_of_income_uploaded: false,
            proof_of_income_loss_uploaded: false,
            ach_authorization_form_uploaded: false,
            lease_agreement_uploaded: false,
            utility_gas_bill_uploaded: false,
            utility_electricity_bill_uploaded: false,
            landlord_w9_uploaded: false,
            landlord_driver_license_uploaded: false,
            verified_checkpoint_uploaded: false,
            round2_landlord_updated_w9_uploaded: false,
            round2_updated_program_participation_Form_uploaded: false,
            round2_landlord_identity_uploaded: false,
            round2_landlord_checkpoint_uploaded: false,
            tenant_payment_agreement_uploaded: false,
            landlord_payment_agreement_uploaded: false,


            speedDialOpen: false,
            speedDialActions: [
                { icon: <SaveIcon onClick={() => this.handleSpeedDialClick('save')} />, name: 'Save' },
                { icon: <CancelIcon onClick={() => this.handleSpeedDialClick('cancel')} />, name: 'Cancel' },
            ],


            /////////// Error State Variables - Starts ///////////
            firstName_Error: "",
            lastName_Error: "",
            partnerFirstName_Error: "",
            partnerLastName_Error: "",
            addressLine1_Error: "",
            addressLine2_Error: "",
            city_Error: "",
            state_Error: "",
            country_Error: "",
            zipcode_Error: "",
            clientDOB_Error: "",
            phone_Error: "",
            monthlyIncomeBeforeCovid19_Error: "",
            householdNumber_Error: "",
            age0to5_Error: "",
            age6to17_Error: "",
            age18to59_Error: "",
            age60andUp_Error: "",
            cardValue_Error: "",

            notes_Error: "",

            spaAssignmentNo_Error: "",
            householdID_Error: "",
            transactionID_Error: "",
            landlordPhoneNumber_Error: "",
            landlordEmail_Error: "",
            amountDueforRent_Error: "",
            rentAmount_Error: "",
            landlordAccountNumber_Error: "",
            landlordRoutingNumber_Error: "",
            electricUtilityAccountNo_Error: "",
            amountDueforElectricity_Error: "",
            custElectricBillingAccountNo_Error: "",
            electricAmount_Error: "",
            electricUtilityProviderAccountNo_Error: "",
            electricRoutingNumber_Error: "",
            electricMemoAccountNo_Error: "",
            gasUtilityAccountNo_Error: "",
            amountDueforGas_Error: "",
            custGasBillingAccountNo_Error: "",
            gasAmount_Error: "",
            gasUtilityProviderAccountNo_Error: "",
            gasRoutingNumber_Error: "",
            gasMemoAccountNo_Error: "",
            otherPaymentAmount_Error: "",

            currentTotalHouseholdIncome_Error: "",
            spaServiceProviderEmail_Error: "",
            noOfTimesParticipated_Error: "",

            landlordSSN_Error: "",
            landlordTaxID_Error: "",
            landlordDUNSNumber_Error: "",
            monthlyRent_Error: "",
            percentageOfMonthlyRent_Error: "",
            outstandingRentalArrearsAmount_Error: "",
            noOfMonthsRentalPayments_Error: "",

            electricUtilityTaxID_Error: "",
            electricUtilityDUNSNumber_Error: "",
            monthlyElectricBillAmount_Error: "",
            percentageOfElectricBill_Error: "",
            noOfMonthsElectricUtilityPayments_Error: "",

            gasUtilityTaxID_Error: "",
            gasUtilityDUNSNumber_Error: "",
            monthlyGasBillAmount_Error: "",
            percentageOfGasBill_Error: "",
            noOfMonthsGasUtilityPayments_Error: "",

            covid_other_Error: "",
            industry_other_Error: "",

            tenantEmail_Error: "",
            dateApplied_Error: "",

            individualAccountNumber_Error: "",
            individualRoutingNumber_Error: "",
            individualPaymentAmount_Error: "",
            individualMemoPropertyAddress_Error: "",

            otherAccountNumber_Error: "",
            otherRoutingNumber_Error: "",
            otherPaymentAmount_Error: "",
            otherMemoPropertyAddress_Error: "",
            otherPayeeName_Error: "",
            otherDesription_Error: "",

            waterUtilityAccountNo_Error: "",
            custWaterBillingAccountNo_Error: "",
            monthlyWaterBillAmount_Error: "",
            noOfMonthsWaterUtilityPayments_Error: "",
            amountDueforWater_Error: "",
            waterAmount_Error: "",
            waterUtilityProviderAccountNo_Error: "",
            waterRoutingNumber_Error: "",
            waterMemoAccountNo_Error: "",
            waterUtilityTaxID_Error: "",
            waterUtilityDUNSNumber_Error: "",

            trashUtilityAccountNo_Error: "",
            custTrashBillingAccountNo_Error: "",
            monthlyTrashBillAmount_Error: "",
            noOfMonthsTrashUtilityPayments_Error: "",
            amountDueforTrash_Error: "",
            trashAmount_Error: "",
            trashUtilityProviderAccountNo_Error: "",
            trashRoutingNumber_Error: "",
            trashMemoAccountNo_Error: "",
            trashUtilityTaxID_Error: "",
            trashUtilityDUNSNumber_Error: "",

            energyUtilityAccountNo_Error: "",
            custEnergyBillingAccountNo_Error: "",
            monthlyEnergyBillAmount_Error: "",
            noOfMonthsEnergyUtilityPayments_Error: "",
            amountDueforEnergy_Error: "",
            energyAmount_Error: "",
            energyUtilityProviderAccountNo_Error: "",
            energyRoutingNumber_Error: "",
            energyMemoAccountNo_Error: "",
            energyUtilityTaxID_Error: "",
            energyUtilityDUNSNumber_Error: "",

            suffix_Error: "",
            middleName_Error: "",
            agent_Error: "",
            doYouRent_Error: "",
            receiveAnyHousingSubsidy_Error: "",
            isAnnualIncomeLess_Error: "",
            isSomeoneOnRentalOrLeaseAgreement_Error: "",
            filedCovid19FinancialStressDeclaration_Error: "",
            receivedEvictionNotice_Error: "",
            SSN_Error: "",
            socialSecurityFirstName_Error: "",
            socialSecurityLastName_Error: "",
            birthdayCode_Error: "",
            age_Error: "",
            otherPhone_Error: "",
            secondaryFirstName_Error: "",
            secondaryLastName_Error: "",
            secondaryEmail_Error: "",
            secondaryPhone_Error: "",

            reasonPaymentNotProcessedIn21Days_Error: "",

            uniqueID_Error: "",

            countAdult_Error: "",
            countChildren_Error: "",

            /////////// Error State Variables - Ends ///////////

        }

        // preserve the initial state in a new object
        this.baseState = { ...this.state };

        this.rentPaymentStatusRef = React.createRef();

        /////////// Validation Control References - Ends ///////////
        this.firstNameRef = React.createRef();
        this.lastNameRef = React.createRef();
        this.partnerFirstNameRef = React.createRef();
        this.partnerLastNameRef = React.createRef();
        this.addressLine1Ref = React.createRef();
        this.addressLine2Ref = React.createRef();
        this.cityRef = React.createRef();
        this.stateRef = React.createRef();
        this.countryRef = React.createRef();
        this.zipcodeRef = React.createRef();
        this.clientDOBRef = React.createRef();
        this.phoneRef = React.createRef();
        this.monthlyIncomeBeforeCovid19Ref = React.createRef();
        this.householdNumberRef = React.createRef();
        this.age0to5Ref = React.createRef();
        this.age6to17Ref = React.createRef();
        this.age18to59Ref = React.createRef();
        this.age60andUpRef = React.createRef();
        this.cardValueRef = React.createRef();

        this.notesRef = React.createRef();

        this.spaAssignmentNoRef = React.createRef();
        this.householdIDRef = React.createRef();
        this.transactionIDRef = React.createRef();
        this.landlordPhoneNumberRef = React.createRef();
        this.landlordEmailRef = React.createRef();
        this.amountDueforRentRef = React.createRef();
        this.rentAmountRef = React.createRef();
        this.landlordAccountNumberRef = React.createRef();
        this.landlordRoutingNumberRef = React.createRef();
        this.electricUtilityAccountNoRef = React.createRef();
        this.amountDueforElectricityRef = React.createRef();
        this.custElectricBillingAccountNoRef = React.createRef();
        this.electricAmountRef = React.createRef();
        this.electricUtilityProviderAccountNoRef = React.createRef();
        this.electricRoutingNumberRef = React.createRef();
        this.electricMemoAccountNoRef = React.createRef();
        this.gasUtilityAccountNoRef = React.createRef();
        this.amountDueforGasRef = React.createRef();
        this.custGasBillingAccountNoRef = React.createRef();
        this.gasAmountRef = React.createRef();
        this.gasUtilityProviderAccountNoRef = React.createRef();
        this.gasRoutingNumberRef = React.createRef();
        this.gasMemoAccountNoRef = React.createRef();
        this.otherPaymentAmountRef = React.createRef();

        this.currentTotalHouseholdIncomeRef = React.createRef();
        this.spaServiceProviderEmailRef = React.createRef();
        this.noOfTimesParticipatedRef = React.createRef();

        this.landlordSSNRef = React.createRef();
        this.landlordTaxIDRef = React.createRef();
        this.landlordDUNSNumberRef = React.createRef();
        this.monthlyRentRef = React.createRef();
        this.percentageOfMonthlyRentRef = React.createRef();
        this.outstandingRentalArrearsAmountRef = React.createRef();
        this.noOfMonthsRentalPaymentsRef = React.createRef();

        this.electricUtilityTaxIDRef = React.createRef();
        this.electricUtilityDUNSNumberRef = React.createRef();
        this.monthlyElectricBillAmountRef = React.createRef();
        this.percentageOfElectricBillRef = React.createRef();
        this.noOfMonthsElectricUtilityPaymentsRef = React.createRef();

        this.gasUtilityTaxIDRef = React.createRef();
        this.gasUtilityDUNSNumberRef = React.createRef();
        this.monthlyGasBillAmountRef = React.createRef();
        this.percentageOfGasBillRef = React.createRef();
        this.noOfMonthsGasUtilityPaymentsRef = React.createRef();

        this.covid_otherRef = React.createRef();
        this.industry_otherRef = React.createRef();

        this.tenantEmailRef = React.createRef();
        this.dateAppliedRef = React.createRef();

        this.individualAccountNumberRef = React.createRef();
        this.individualRoutingNumberRef = React.createRef();
        this.individualPaymentAmountRef = React.createRef();
        this.individualMemoPropertyAddressRef = React.createRef();

        this.otherAccountNumberRef = React.createRef();
        this.otherRoutingNumberRef = React.createRef();
        this.otherPaymentAmountRef = React.createRef();
        this.otherMemoPropertyAddressRef = React.createRef();
        this.otherPayeeNameRef = React.createRef();
        this.otherDesriptionRef = React.createRef();

        this.waterUtilityAccountNoRef = React.createRef();
        this.custWaterBillingAccountNoRef = React.createRef();
        this.monthlyWaterBillAmountRef = React.createRef();
        this.noOfMonthsWaterUtilityPaymentsRef = React.createRef();
        this.amountDueforWaterRef = React.createRef();
        this.waterAmountRef = React.createRef();
        this.waterUtilityProviderAccountNoRef = React.createRef();
        this.waterRoutingNumberRef = React.createRef();
        this.waterMemoAccountNoRef = React.createRef();
        this.waterUtilityTaxIDRef = React.createRef();
        this.waterUtilityDUNSNumberRef = React.createRef();

        this.trashUtilityAccountNoRef = React.createRef();
        this.custTrashBillingAccountNoRef = React.createRef();
        this.monthlyTrashBillAmountRef = React.createRef();
        this.noOfMonthsTrashUtilityPaymentsRef = React.createRef();
        this.amountDueforTrashRef = React.createRef();
        this.trashAmountRef = React.createRef();
        this.trashUtilityProviderAccountNoRef = React.createRef();
        this.trashRoutingNumberRef = React.createRef();
        this.trashMemoAccountNoRef = React.createRef();
        this.trashUtilityTaxIDRef = React.createRef();
        this.trashUtilityDUNSNumberRef = React.createRef();

        this.energyUtilityAccountNoRef = React.createRef();
        this.custEnergyBillingAccountNoRef = React.createRef();
        this.monthlyEnergyBillAmountRef = React.createRef();
        this.noOfMonthsEnergyUtilityPaymentsRef = React.createRef();
        this.amountDueforEnergyRef = React.createRef();
        this.energyAmountRef = React.createRef();
        this.energyUtilityProviderAccountNoRef = React.createRef();
        this.energyRoutingNumberRef = React.createRef();
        this.energyMemoAccountNoRef = React.createRef();
        this.energyUtilityTaxIDRef = React.createRef();
        this.energyUtilityDUNSNumberRef = React.createRef();

        this.suffixRef = React.createRef();
        this.middleNameRef = React.createRef();
        this.agentRef = React.createRef();
        this.doYouRentRef = React.createRef();
        this.receiveAnyHousingSubsidyRef = React.createRef();
        this.isAnnualIncomeLessRef = React.createRef();
        this.isSomeoneOnRentalOrLeaseAgreementRef = React.createRef();
        this.filedCovid19FinancialStressDeclarationRef = React.createRef();
        this.receivedEvictionNoticeRef = React.createRef();
        this.SSNRef = React.createRef();
        this.socialSecurityFirstNameRef = React.createRef();
        this.socialSecurityLastNameRef = React.createRef();
        this.birthdayCodeRef = React.createRef();
        this.ageRef = React.createRef();
        this.otherPhoneRef = React.createRef();
        this.secondaryFirstNameRef = React.createRef();
        this.secondaryLastNameRef = React.createRef();
        this.secondaryEmailRef = React.createRef();
        this.secondaryPhoneRef = React.createRef();

        this.reasonPaymentNotProcessedIn21DaysRef = React.createRef();
        this.uniqueIDRef = React.createRef();

        this.countAdultRef = React.createRef();
        this.countChildrenRef = React.createRef();

        /////////// Validation Control References - Ends ///////////

        this.photo_identification_upload = null;
        this.proof_of_address_upload = null;
        this.proof_of_income_upload = null;
        this.proof_of_income_loss_upload = null;
        this.ach_authorization_form_upload = null;
        this.lease_agreement_upload = null;
        this.utility_gas_bill_upload = null;
        this.utility_electricity_bill_upload = null;
        this.landlord_w9_upload = null;
        this.landlord_driver_license_upload = null;
        this.verified_checkpoint_upload = null;
        this.round2_landlord_updated_w9_upload = null;
        this.round2_updated_program_participation_Form_upload = null;
        this.round2_landlord_identity_upload = null;
        this.round2_landlord_checkpoint_upload = null;
        this.tenant_payment_agreement_upload = null;
        this.landlord_payment_agreement_upload = null;

        // this.setState({ individualID: this.props.individualID });
    }


    validateControls = () => {
        var validationError = false;
        this.setState({ isLoading: true });

        ///////////////////////////   VALIDATION STARTS   ///////////////////////////

        // if (this.state.firstName !== "" && this.state.firstName !== null && this.state.firstName !== undefined) {
        //     var namePattern = new RegExp(/^[A-Za-z\/\']+[A-Za-z]$/);
        //     if (!namePattern.test(this.state.firstName)) {
        //         this.setState({ firstName_Error: "Invalid First Name" });
        //         this.firstNameRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ firstName_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ firstName_Error: "" });
        // }

        // if (this.state.lastName !== "" && this.state.lastName !== null && this.state.lastName !== undefined) {
        //     var namePattern = new RegExp(/^[A-Za-z\/\']+[A-Za-z]$/);
        //     if (!namePattern.test(this.state.lastName)) {
        //         this.setState({ lastName_Error: "Invalid Last Name" });
        //         this.lastNameRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ lastName_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ lastName_Error: "" });
        // }

        // if (this.state.partnerFirstName !== "" && this.state.partnerFirstName !== null && this.state.partnerFirstName !== undefined) {
        //     var namePattern = new RegExp(/^[A-Za-z\/\']+[A-Za-z]$/);
        //     if (!namePattern.test(this.state.partnerFirstName)) {
        //         this.setState({ partnerFirstName_Error: "Invalid Partner First Name" });
        //         this.partnerFirstNameRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ partnerFirstName_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ partnerFirstName_Error: "" });
        // }

        // if (this.state.partnerLastName !== "" && this.state.partnerLastName !== null && this.state.partnerLastName !== undefined) {
        //     var namePattern = new RegExp(/^[A-Za-z\/\']+[A-Za-z]$/);
        //     if (!namePattern.test(this.state.partnerLastName)) {
        //         this.setState({ partnerLastName_Error: "Invalid Partner Last Name" });
        //         this.partnerLastNameRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ partnerLastName_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ partnerLastName_Error: "" });
        // }

        // addressLine1
        // addressLine2

        if (this.state.city !== "" && this.state.city !== null && this.state.city !== undefined) {
            var namePattern = new RegExp(/^[a-zA-Z- ]*$/);
            if (!namePattern.test(this.state.city)) {
                this.setState({ city_Error: "Invalid City" });
                this.cityRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ city_Error: "" });
            }
        }
        else {
            this.setState({ city_Error: "" });
        }

        // state
        // country

        if (this.state.zipcode !== "" && this.state.zipcode !== null && this.state.zipcode !== undefined) {
            if (this.state.zipcode.length < 5 || /^\d+$/.test(this.state.zipcode) !== true) {
                this.setState({ zipcode_Error: "Invalid Zip" });
                this.zipcodeRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ zipcode_Error: "" });
            }
        }
        else {
            this.setState({ zipcode_Error: "" });
        }

        // clientDOB

        if (this.state.phone !== "" && this.state.phone !== null && this.state.phone !== undefined) {
            if (this.state.phone.length < 10) {
                this.setState({ phone_Error: "Invalid Phone" });
                this.phoneRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ phone_Error: "" });
            }
        }
        else {
            this.setState({ phone_Error: "" });
        }

        if (this.state.monthlyIncomeBeforeCovid19 !== "" && this.state.monthlyIncomeBeforeCovid19 !== null && this.state.monthlyIncomeBeforeCovid19 !== undefined) {
            if (/^\d+(\.\d{1,2})?$/.test(this.state.monthlyIncomeBeforeCovid19) !== true) {
                this.setState({ monthlyIncomeBeforeCovid19_Error: "Invalid Amount" });
                this.monthlyIncomeBeforeCovid19Ref.current.focus();

                validationError = true;
            }
            else if (this.state.monthlyIncomeBeforeCovid19 > 99999.99) {
                this.setState({ monthlyIncomeBeforeCovid19_Error: "Exceeds limit" });
                this.monthlyIncomeBeforeCovid19Ref.current.focus();

                validationError = true;
            }
            else {
                this.setState({ monthlyIncomeBeforeCovid19_Error: "" });
            }
        }
        else {
            this.setState({ monthlyIncomeBeforeCovid19_Error: "" });
        }

        if (this.state.householdNumber !== "" && this.state.householdNumber !== null && this.state.householdNumber !== undefined) {
            if (/^\d+$/.test(this.state.householdNumber) !== true || parseInt(this.state.householdNumber) > 100) {
                this.setState({ householdNumber_Error: "Invalid Number" });
                this.householdNumberRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ householdNumber_Error: "" });
            }
        }
        else {
            this.setState({ householdNumber_Error: "" });
        }

        // if (this.state.age0to5 !== "" && this.state.age0to5 !== null && this.state.age0to5 !== undefined) {
        //     if (/^\d+$/.test(this.state.age0to5) !== true || parseInt(this.state.age0to5) > 100) {
        //         this.setState({ age0to5_Error: "Invalid Age 0-5" });
        //         this.age0to5Ref.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ age0to5_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ age0to5_Error: "" });
        // }

        // if (this.state.age6to17 !== "" && this.state.age6to17 !== null && this.state.age6to17 !== undefined) {
        //     if (/^\d+$/.test(this.state.age6to17) !== true || parseInt(this.state.age6to17) > 100) {
        //         this.setState({ age6to17_Error: "Invalid Number" });
        //         this.age6to17Ref.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ age6to17_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ age6to17_Error: "" });
        // }

        // if (this.state.age18to59 !== "" && this.state.age18to59 !== null && this.state.age18to59 !== undefined) {
        //     if (/^\d+$/.test(this.state.age18to59) !== true || parseInt(this.state.age18to59) > 100) {
        //         this.setState({ age18to59_Error: "Invalid Number" });
        //         this.age18to59Ref.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ age18to59_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ age18to59_Error: "" });
        // }

        // if (this.state.age60andUp !== "" && this.state.age60andUp !== null && this.state.age60andUp !== undefined) {
        //     if (/^\d+$/.test(this.state.age60andUp) !== true || parseInt(this.state.age60andUp) > 100) {
        //         this.setState({ age60andUp_Error: "Invalid Age 60+" });
        //         this.age60andUpRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ age60andUp_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ age60andUp_Error: "" });
        // }



        if (this.state.countAdult !== "" && this.state.countAdult !== null && this.state.countAdult !== undefined) {
            if (/^\d+$/.test(this.state.countAdult) !== true || parseInt(this.state.countAdult) > 100) {
                this.setState({ countAdult_Error: "Invalid Number" });
                this.countAdultRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ countAdult_Error: "" });
            }
        }
        else {
            this.setState({ countAdult_Error: "" });
        }

        if (this.state.countChildren !== "" && this.state.countChildren !== null && this.state.countChildren !== undefined) {
            if (/^\d+$/.test(this.state.countChildren) !== true || parseInt(this.state.countChildren) > 100) {
                this.setState({ countChildren_Error: "Invalid Number" });
                this.countChildrenRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ countChildren_Error: "" });
            }
        }
        else {
            this.setState({ countChildren_Error: "" });
        }


        // if (this.state.cardValue !== "" && this.state.cardValue !== null && this.state.cardValue !== undefined) {
        //     if (/^\d+$/.test(this.state.cardValue) !== true) {
        //         this.setState({ cardValue_Error: "Invalid Card Value" });
        //         this.cardValueRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ cardValue_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ cardValue_Error: "" });
        // }

        // if (this.state.spaAssignmentNo !== "" && this.state.spaAssignmentNo !== null && this.state.spaAssignmentNo !== undefined) {
        //     if (/^\d+$/.test(this.state.spaAssignmentNo) !== true) {
        //         this.setState({ spaAssignmentNo_Error: "Invalid SPA Assignment Number" });
        //         this.spaAssignmentNoRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ spaAssignmentNo_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ spaAssignmentNo_Error: "" });
        // }

        if (this.state.transactionID !== "" && this.state.transactionID !== null && this.state.transactionID !== undefined) {
            if (/^\d+$/.test(this.state.transactionID) !== true) {
                this.setState({ transactionID_Error: "Invalid Transaction ID" });
                this.transactionIDRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ transactionID_Error: "" });
            }
        }
        else {
            this.setState({ transactionID_Error: "" });
        }

        if (this.state.suffix !== "" && this.state.suffix !== null && this.state.suffix !== undefined) {
            if (this.state.suffix.length > 10) {
                this.setState({ suffix_Error: "Suffix too long" });
                this.suffixRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ suffix_Error: "" });
            }
        }
        else {
            this.setState({ suffix_Error: "" });
        }

        if (this.state.middleName !== "" && this.state.middleName !== null && this.state.middleName !== undefined) {
            if (this.state.middleName.length > 100) {
                this.setState({ middleName_Error: "Name too long" });
                this.middleNameRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ middleName_Error: "" });
            }
        }
        else {
            this.setState({ middleName_Error: "" });
        }

        if (this.state.agent !== "" && this.state.agent !== null && this.state.agent !== undefined) {
            if (this.state.agent.length > 100) {
                this.setState({ agent_Error: "Name too long" });
                this.agentRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ agent_Error: "" });
            }
        }
        else {
            this.setState({ agent_Error: "" });
        }

        if (this.state.doYouRent !== "" && this.state.doYouRent !== null && this.state.doYouRent !== undefined) {
            if (this.state.doYouRent.length > 100) {
                this.setState({ doYouRent_Error: "String too long" });
                this.doYouRentRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ doYouRent_Error: "" });
            }
        }
        else {
            this.setState({ doYouRent_Error: "" });
        }

        if (this.state.receiveAnyHousingSubsidy !== "" && this.state.receiveAnyHousingSubsidy !== null && this.state.receiveAnyHousingSubsidy !== undefined) {
            if (this.state.receiveAnyHousingSubsidy.length > 100) {
                this.setState({ receiveAnyHousingSubsidy_Error: "String too long" });
                this.receiveAnyHousingSubsidyRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ receiveAnyHousingSubsidy_Error: "" });
            }
        }
        else {
            this.setState({ receiveAnyHousingSubsidy_Error: "" });
        }

        // if (this.state.isAnnualIncomeLess !== "" && this.state.isAnnualIncomeLess !== null && this.state.isAnnualIncomeLess !== undefined) {
        //     if (this.state.isAnnualIncomeLess.length > 100) {
        //         this.setState({ isAnnualIncomeLess_Error: "String too long" });
        //         this.isAnnualIncomeLessRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ isAnnualIncomeLess_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ isAnnualIncomeLess_Error: "" });
        // }

        if (this.state.isSomeoneOnRentalOrLeaseAgreement !== "" && this.state.isSomeoneOnRentalOrLeaseAgreement !== null && this.state.isSomeoneOnRentalOrLeaseAgreement !== undefined) {
            if (this.state.isSomeoneOnRentalOrLeaseAgreement.length > 100) {
                this.setState({ isSomeoneOnRentalOrLeaseAgreement_Error: "String too long" });
                this.isSomeoneOnRentalOrLeaseAgreementRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ isSomeoneOnRentalOrLeaseAgreement_Error: "" });
            }
        }
        else {
            this.setState({ isSomeoneOnRentalOrLeaseAgreement_Error: "" });
        }

        if (this.state.filedCovid19FinancialStressDeclaration !== "" && this.state.filedCovid19FinancialStressDeclaration !== null && this.state.filedCovid19FinancialStressDeclaration !== undefined) {
            if (this.state.filedCovid19FinancialStressDeclaration.length > 100) {
                this.setState({ filedCovid19FinancialStressDeclaration_Error: "String too long" });
                this.filedCovid19FinancialStressDeclarationRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ filedCovid19FinancialStressDeclaration_Error: "" });
            }
        }
        else {
            this.setState({ filedCovid19FinancialStressDeclaration_Error: "" });
        }

        if (this.state.receivedEvictionNotice !== "" && this.state.receivedEvictionNotice !== null && this.state.receivedEvictionNotice !== undefined) {
            if (this.state.receivedEvictionNotice.length > 100) {
                this.setState({ receivedEvictionNotice_Error: "String too long" });
                this.receivedEvictionNoticeRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ receivedEvictionNotice_Error: "" });
            }
        }
        else {
            this.setState({ receivedEvictionNotice_Error: "" });
        }

        if (this.state.dateEvictionNoticeReceived !== "" && this.state.dateEvictionNoticeReceived !== null && this.state.dateEvictionNoticeReceived !== undefined) {
            if (this.state.dateEvictionNoticeReceived.length > 100) {
                this.setState({ dateEvictionNoticeReceived_Error: "String too long" });
                this.dateEvictionNoticeReceivedRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ dateEvictionNoticeReceived_Error: "" });
            }
        }
        else {
            this.setState({ dateEvictionNoticeReceived_Error: "" });
        }

        if (this.state.SSN !== "" && this.state.SSN !== null && this.state.SSN !== undefined) {
            if (this.state.SSN.length < 9 || /^\d+$/.test(this.state.SSN) !== true) {
                this.setState({ SSN_Error: "Invalid SSN Number" });
                this.SSNRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ SSN_Error: "" });
            }
        }
        else {
            this.setState({ SSN_Error: "" });
        }

        if (this.state.socialSecurityFirstName !== "" && this.state.socialSecurityFirstName !== null && this.state.socialSecurityFirstName !== undefined) {
            if (this.state.socialSecurityFirstName.length > 100) {
                this.setState({ socialSecurityFirstName_Error: "String too long" });
                this.socialSecurityFirstNameRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ socialSecurityFirstName_Error: "" });
            }
        }
        else {
            this.setState({ socialSecurityFirstName_Error: "" });
        }

        if (this.state.socialSecurityLastName !== "" && this.state.socialSecurityLastName !== null && this.state.socialSecurityLastName !== undefined) {
            if (this.state.socialSecurityLastName.length > 100) {
                this.setState({ socialSecurityLastName_Error: "String too long" });
                this.socialSecurityLastNameRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ socialSecurityLastName_Error: "" });
            }
        }
        else {
            this.setState({ socialSecurityLastName_Error: "" });
        }

        if (this.state.birthdayCode !== "" && this.state.birthdayCode !== null && this.state.birthdayCode !== undefined) {
            if (this.state.birthdayCode.length > 255) {
                this.setState({ birthdayCode_Error: "String too long" });
                this.birthdayCodeRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ birthdayCode_Error: "" });
            }
        }
        else {
            this.setState({ birthdayCode_Error: "" });
        }

        if (this.state.age !== "" && this.state.age !== null && this.state.age !== undefined) {
            if (/^\d+$/.test(this.state.age) !== true) {
                this.setState({ age_Error: "Invalid Number" });
                this.ageRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ age_Error: "" });
            }
        }
        else {
            this.setState({ age_Error: "" });
        }

        if (this.state.otherPhone !== "" && this.state.otherPhone !== null && this.state.otherPhone !== undefined) {
            if (this.state.otherPhone.length < 10) {
                this.setState({ otherPhone_Error: "Invalid Phone Number" });
                this.otherPhoneRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ otherPhone_Error: "" });
            }
        }
        else {
            this.setState({ otherPhone_Error: "" });
        }

        if (this.state.secondaryFirstName !== "" && this.state.secondaryFirstName !== null && this.state.secondaryFirstName !== undefined) {
            if (this.state.secondaryFirstName.length > 100) {
                this.setState({ secondaryFirstName_Error: "String too long" });
                this.secondaryFirstNameRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ secondaryFirstName_Error: "" });
            }
        }
        else {
            this.setState({ secondaryFirstName_Error: "" });
        }

        if (this.state.secondaryLastName !== "" && this.state.secondaryLastName !== null && this.state.secondaryLastName !== undefined) {
            if (this.state.secondaryLastName.length > 100) {
                this.setState({ secondaryLastName_Error: "String too long" });
                this.secondaryLastNameRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ secondaryLastName_Error: "" });
            }
        }
        else {
            this.setState({ secondaryLastName_Error: "" });
        }

        if (this.state.secondaryEmail !== "" && this.state.secondaryEmail !== null && this.state.secondaryEmail !== undefined) {
            var secondaryEmailPattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!secondaryEmailPattern.test(this.state.secondaryEmail)) {
                this.setState({ secondaryEmail_Error: "Invalid Email" });
                this.secondaryEmailRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ secondaryEmail_Error: "" });
            }
        }
        else {
            this.setState({ secondaryEmail_Error: "" });
        }

        if (this.state.secondaryPhone !== "" && this.state.secondaryPhone !== null && this.state.secondaryPhone !== undefined) {
            if (this.state.secondaryPhone.length < 10) {
                this.setState({ secondaryPhone_Error: "Invalid Phone Number" });
                this.secondaryPhoneRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ secondaryPhone_Error: "" });
            }
        }
        else {
            this.setState({ secondaryPhone_Error: "" });
        }

        if (this.state.householdID !== "" && this.state.householdID !== null && this.state.householdID !== undefined) {
            if (/^\d+$/.test(this.state.householdID) !== true) {
                this.setState({ householdID_Error: "Invalid Household ID" });
                this.householdIDRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ householdID_Error: "" });
            }
        }
        else {
            this.setState({ householdID_Error: "" });
        }

        if (this.state.landlordPhoneNumber !== "" && this.state.landlordPhoneNumber !== null && this.state.landlordPhoneNumber !== undefined) {
            if (this.state.landlordPhoneNumber.length < 10) {
                this.setState({ landlordPhoneNumber_Error: "Invalid Phone Number" });
                this.landlordPhoneNumberRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ landlordPhoneNumber_Error: "" });
            }
        }
        else {
            this.setState({ landlordPhoneNumber_Error: "" });
        }


        if (this.state.landlordEmail !== "" && this.state.landlordEmail !== null && this.state.landlordEmail !== undefined) {
            var landlordEmailPattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!landlordEmailPattern.test(this.state.landlordEmail)) {
                this.setState({ landlordEmail_Error: "Invalid Email" });
                this.landlordEmailRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ landlordEmail_Error: "" });
            }
        }
        else {
            this.setState({ landlordEmail_Error: "" });
        }


        if (this.state.amountDueforRent !== "" && this.state.amountDueforRent !== null && this.state.amountDueforRent !== undefined) {
            if (/^\d+(\.\d{1,2})?$/.test(this.state.amountDueforRent) !== true) {
                this.setState({ amountDueforRent_Error: "Invalid Amount" });
                this.amountDueforRentRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ amountDueforRent_Error: "" });
            }
        }
        else {
            this.setState({ amountDueforRent_Error: "" });
        }

        if (this.state.rentAmount !== "" && this.state.rentAmount !== null && this.state.rentAmount !== undefined) {
            if (/^\d+(\.\d{1,2})?$/.test(this.state.rentAmount) !== true) {
                this.setState({ rentAmount_Error: "Invalid Amount" });
                this.rentAmountRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ rentAmount_Error: "" });
            }
        }
        else {
            this.setState({ rentAmount_Error: "" });
        }

        if (this.state.rentPaymentStatus === 'N/A') {
            if (this.state.reasonPaymentNotProcessedIn21Days === "" || this.state.reasonPaymentNotProcessedIn21Days === null || this.state.reasonPaymentNotProcessedIn21Days === undefined) {
                this.setState({ reasonPaymentNotProcessedIn21Days_Error: "Reason required for Rent Payment Status as N/A" });
                this.reasonPaymentNotProcessedIn21DaysRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ reasonPaymentNotProcessedIn21Days_Error: "" });
            }
        }
        else {
            this.setState({ reasonPaymentNotProcessedIn21Days_Error: "" });
        }

        // if (this.state.landlordAccountNumber !== "" && this.state.landlordAccountNumber !== null && this.state.landlordAccountNumber !== undefined) {
        //     if (/^\d+$/.test(this.state.landlordAccountNumber) !== true) {
        //         this.setState({ landlordAccountNumber_Error: "Invalid Account Number" });
        //         this.landlordAccountNumberRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ landlordAccountNumber_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ landlordAccountNumber_Error: "" });
        // }

        if (this.state.landlordRoutingNumber !== "" && this.state.landlordRoutingNumber !== null && this.state.landlordRoutingNumber !== undefined) {
            if (this.state.landlordRoutingNumber.length < 9 || /^\d+$/.test(this.state.landlordRoutingNumber) !== true) {
                this.setState({ landlordRoutingNumber_Error: "Invalid Routing Number" });
                this.landlordRoutingNumberRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ landlordRoutingNumber_Error: "" });
            }
        }
        else {
            this.setState({ landlordRoutingNumber_Error: "" });
        }

        // if (this.state.electricUtilityAccountNo !== "" && this.state.electricUtilityAccountNo !== null && this.state.electricUtilityAccountNo !== undefined) {
        //     if (/^\d+$/.test(this.state.electricUtilityAccountNo) !== true) {
        //         this.setState({ electricUtilityAccountNo_Error: "Invalid Account Number" });
        //         this.electricUtilityAccountNoRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ electricUtilityAccountNo_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ electricUtilityAccountNo_Error: "" });
        // }

        if (this.state.amountDueforElectricity !== "" && this.state.amountDueforElectricity !== null && this.state.amountDueforElectricity !== undefined) {
            if (/^\d+(\.\d{1,2})?$/.test(this.state.amountDueforElectricity) !== true) {
                this.setState({ amountDueforElectricity_Error: "Invalid Amount" });
                this.amountDueforElectricityRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ amountDueforElectricity_Error: "" });
            }
        }
        else {
            this.setState({ amountDueforElectricity_Error: "" });
        }

        // if (this.state.custElectricBillingAccountNo !== "" && this.state.custElectricBillingAccountNo !== null && this.state.custElectricBillingAccountNo !== undefined) {
        //     if (/^\d+$/.test(this.state.custElectricBillingAccountNo) !== true) {
        //         this.setState({ custElectricBillingAccountNo_Error: "Invalid Account Number" });
        //         this.custElectricBillingAccountNoRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ custElectricBillingAccountNo_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ custElectricBillingAccountNo_Error: "" });
        // }

        if (this.state.electricAmount !== "" && this.state.electricAmount !== null && this.state.electricAmount !== undefined) {
            if (/^\d+(\.\d{1,2})?$/.test(this.state.electricAmount) !== true) {
                this.setState({ electricAmount_Error: "Invalid Amount" });
                this.electricAmountRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ electricAmount_Error: "" });
            }
        }
        else {
            this.setState({ electricAmount_Error: "" });
        }

        // if (this.state.electricUtilityProviderAccountNo !== "" && this.state.electricUtilityProviderAccountNo !== null && this.state.electricUtilityProviderAccountNo !== undefined) {
        //     if (/^\d+$/.test(this.state.electricUtilityProviderAccountNo) !== true) {
        //         this.setState({ electricUtilityProviderAccountNo_Error: "Invalid Account Number" });
        //         this.electricUtilityProviderAccountNoRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ electricUtilityProviderAccountNo_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ electricUtilityProviderAccountNo_Error: "" });
        // }

        if (this.state.electricRoutingNumber !== "" && this.state.electricRoutingNumber !== null && this.state.electricRoutingNumber !== undefined) {
            if (this.state.electricRoutingNumber.length < 9 || /^\d+$/.test(this.state.electricRoutingNumber) !== true) {
                this.setState({ electricRoutingNumber_Error: "Invalid Routing Number" });
                this.electricRoutingNumberRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ electricRoutingNumber_Error: "" });
            }
        }
        else {
            this.setState({ electricRoutingNumber_Error: "" });
        }

        // if (this.state.electricMemoAccountNo !== "" && this.state.electricMemoAccountNo !== null && this.state.electricMemoAccountNo !== undefined) {
        //     if (/^\d+$/.test(this.state.electricMemoAccountNo) !== true) {
        //         this.setState({ electricMemoAccountNo_Error: "Invalid Account Number" });
        //         this.electricMemoAccountNoRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ electricMemoAccountNo_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ electricMemoAccountNo_Error: "" });
        // }

        // if (this.state.gasUtilityAccountNo !== "" && this.state.gasUtilityAccountNo !== null && this.state.gasUtilityAccountNo !== undefined) {
        //     if (/^\d+$/.test(this.state.gasUtilityAccountNo) !== true) {
        //         this.setState({ gasUtilityAccountNo_Error: "Invalid Account Number" });
        //         this.gasUtilityAccountNoRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ gasUtilityAccountNo_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ gasUtilityAccountNo_Error: "" });
        // }

        if (this.state.amountDueforGas !== "" && this.state.amountDueforGas !== null && this.state.amountDueforGas !== undefined) {
            if (/^\d+(\.\d{1,2})?$/.test(this.state.amountDueforGas) !== true) {
                this.setState({ amountDueforGas_Error: "Invalid Amount" });
                this.amountDueforGasRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ amountDueforGas_Error: "" });
            }
        }
        else {
            this.setState({ amountDueforGas_Error: "" });
        }

        // if (this.state.custGasBillingAccountNo !== "" && this.state.custGasBillingAccountNo !== null && this.state.custGasBillingAccountNo !== undefined) {
        //     if (/^\d+$/.test(this.state.custGasBillingAccountNo) !== true) {
        //         this.setState({ custGasBillingAccountNo_Error: "Invalid Account Number" });
        //         this.custGasBillingAccountNoRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ custGasBillingAccountNo_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ custGasBillingAccountNo_Error: "" });
        // }

        if (this.state.gasAmount !== "" && this.state.gasAmount !== null && this.state.gasAmount !== undefined) {
            if (/^\d+(\.\d{1,2})?$/.test(this.state.gasAmount) !== true) {
                this.setState({ gasAmount_Error: "Invalid Amount" });
                this.gasAmountRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ gasAmount_Error: "" });
            }
        }
        else {
            this.setState({ gasAmount_Error: "" });
        }

        // if (this.state.gasUtilityProviderAccountNo !== "" && this.state.gasUtilityProviderAccountNo !== null && this.state.gasUtilityProviderAccountNo !== undefined) {
        //     if (/^\d+$/.test(this.state.gasUtilityProviderAccountNo) !== true) {
        //         this.setState({ gasUtilityProviderAccountNo_Error: "Invalid Account Number" });
        //         this.gasUtilityProviderAccountNoRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ gasUtilityProviderAccountNo_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ gasUtilityProviderAccountNo_Error: "" });
        // }

        if (this.state.gasRoutingNumber !== "" && this.state.gasRoutingNumber !== null && this.state.gasRoutingNumber !== undefined) {
            if (this.state.gasRoutingNumber.length < 9 || /^\d+$/.test(this.state.gasRoutingNumber) !== true) {
                this.setState({ gasRoutingNumber_Error: "Invalid Routing Number" });
                this.gasRoutingNumberRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ gasRoutingNumber_Error: "" });
            }
        }
        else {
            this.setState({ gasRoutingNumber_Error: "" });
        }

        // if (this.state.gasMemoAccountNo !== "" && this.state.gasMemoAccountNo !== null && this.state.gasMemoAccountNo !== undefined) {
        //     if (/^\d+$/.test(this.state.gasMemoAccountNo) !== true) {
        //         this.setState({ gasMemoAccountNo_Error: "Invalid Account Number" });
        //         this.gasMemoAccountNoRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ gasMemoAccountNo_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ gasMemoAccountNo_Error: "" });
        // }

        if (this.state.gasUtilityTaxID !== "" && this.state.gasUtilityTaxID !== null && this.state.gasUtilityTaxID !== undefined) {
            if (this.state.gasUtilityTaxID.length < 9 || /^\d+$/.test(this.state.gasUtilityTaxID) !== true) {
                this.setState({ gasUtilityTaxID_Error: "Invalid Tax ID" });
                this.gasUtilityTaxIDRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ gasUtilityTaxID_Error: "" });
            }
        }
        else {
            this.setState({ gasUtilityTaxID_Error: "" });
        }

        if (this.state.gasUtilityDUNSNumber !== "" && this.state.gasUtilityDUNSNumber !== null && this.state.gasUtilityDUNSNumber !== undefined) {
            if (this.state.gasUtilityDUNSNumber.length < 9 || /^\d+$/.test(this.state.gasUtilityDUNSNumber) !== true) {
                this.setState({ gasUtilityDUNSNumber_Error: "Invalid DUNS Number" });
                this.gasUtilityDUNSNumberRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ gasUtilityDUNSNumber_Error: "" });
            }
        }
        else {
            this.setState({ gasUtilityDUNSNumber_Error: "" });
        }

        if (this.state.monthlyGasBillAmount !== "" && this.state.monthlyGasBillAmount !== null && this.state.monthlyGasBillAmount !== undefined) {
            if (/^\d+(\.\d{1,2})?$/.test(this.state.monthlyGasBillAmount) !== true) {
                this.setState({ monthlyGasBillAmount_Error: "Invalid Amount" });
                this.monthlyGasBillAmountRef.current.focus();

                validationError = true;
            }
            else if (this.state.monthlyGasBillAmount > 99999.99) {
                this.setState({ monthlyGasBillAmount_Error: "Exceeds limit" });
                this.monthlyGasBillAmountRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ monthlyGasBillAmount_Error: "" });
            }
        }
        else {
            this.setState({ monthlyGasBillAmount_Error: "" });
        }

        // if (this.state.percentageOfGasBill !== "" && this.state.percentageOfGasBill !== null && this.state.percentageOfGasBill !== undefined) {
        //     if (/^\d+$/.test(this.state.percentageOfGasBill) !== true) {
        //         this.setState({ percentageOfGasBill_Error: "Invalid Number" });
        //         this.percentageOfGasBillRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ percentageOfGasBill_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ percentageOfGasBill_Error: "" });
        // }

        if (this.state.noOfMonthsGasUtilityPayments !== "" && this.state.noOfMonthsGasUtilityPayments !== null && this.state.noOfMonthsGasUtilityPayments !== undefined) {
            if (/^\d+$/.test(this.state.noOfMonthsGasUtilityPayments) !== true) {
                this.setState({ noOfMonthsGasUtilityPayments_Error: "Invalid Number" });
                this.noOfMonthsGasUtilityPaymentsRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ noOfMonthsGasUtilityPayments_Error: "" });
            }
        }
        else {
            this.setState({ noOfMonthsGasUtilityPayments_Error: "" });
        }

        // if (this.state.waterUtilityAccountNo !== "" && this.state.waterUtilityAccountNo !== null && this.state.waterUtilityAccountNo !== undefined) {
        //     if (/^\d+$/.test(this.state.waterUtilityAccountNo) !== true) {
        //         this.setState({ waterUtilityAccountNo_Error: "Invalid Account Number" });
        //         this.waterUtilityAccountNoRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ waterUtilityAccountNo_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ waterUtilityAccountNo_Error: "" });
        // }

        if (this.state.amountDueforWater !== "" && this.state.amountDueforWater !== null && this.state.amountDueforWater !== undefined) {
            if (/^\d+(\.\d{1,2})?$/.test(this.state.amountDueforWater) !== true) {
                this.setState({ amountDueforWater_Error: "Invalid Amount" });
                this.amountDueforWaterRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ amountDueforWater_Error: "" });
            }
        }
        else {
            this.setState({ amountDueforWater_Error: "" });
        }

        // if (this.state.custWaterBillingAccountNo !== "" && this.state.custWaterBillingAccountNo !== null && this.state.custWaterBillingAccountNo !== undefined) {
        //     if (/^\d+$/.test(this.state.custWaterBillingAccountNo) !== true) {
        //         this.setState({ custWaterBillingAccountNo_Error: "Invalid Account Number" });
        //         this.custWaterBillingAccountNoRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ custWaterBillingAccountNo_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ custWaterBillingAccountNo_Error: "" });
        // }

        if (this.state.waterAmount !== "" && this.state.waterAmount !== null && this.state.waterAmount !== undefined) {
            if (/^\d+(\.\d{1,2})?$/.test(this.state.waterAmount) !== true) {
                this.setState({ waterAmount_Error: "Invalid Amount" });
                this.waterAmountRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ waterAmount_Error: "" });
            }
        }
        else {
            this.setState({ waterAmount_Error: "" });
        }

        // if (this.state.waterUtilityProviderAccountNo !== "" && this.state.waterUtilityProviderAccountNo !== null && this.state.waterUtilityProviderAccountNo !== undefined) {
        //     if (/^\d+$/.test(this.state.waterUtilityProviderAccountNo) !== true) {
        //         this.setState({ waterUtilityProviderAccountNo_Error: "Invalid Account Number" });
        //         this.waterUtilityProviderAccountNoRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ waterUtilityProviderAccountNo_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ waterUtilityProviderAccountNo_Error: "" });
        // }

        if (this.state.waterRoutingNumber !== "" && this.state.waterRoutingNumber !== null && this.state.waterRoutingNumber !== undefined) {
            if (this.state.waterRoutingNumber.length < 9 || /^\d+$/.test(this.state.waterRoutingNumber) !== true) {
                this.setState({ waterRoutingNumber_Error: "Invalid Routing Number" });
                this.waterRoutingNumberRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ waterRoutingNumber_Error: "" });
            }
        }
        else {
            this.setState({ waterRoutingNumber_Error: "" });
        }

        // if (this.state.waterMemoAccountNo !== "" && this.state.waterMemoAccountNo !== null && this.state.waterMemoAccountNo !== undefined) {
        //     if (/^\d+$/.test(this.state.waterMemoAccountNo) !== true) {
        //         this.setState({ waterMemoAccountNo_Error: "Invalid Account Number" });
        //         this.waterMemoAccountNoRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ waterMemoAccountNo_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ waterMemoAccountNo_Error: "" });
        // }


        if (this.state.waterUtilityTaxID !== "" && this.state.waterUtilityTaxID !== null && this.state.waterUtilityTaxID !== undefined) {
            if (this.state.waterUtilityTaxID.length < 9 || /^\d+$/.test(this.state.waterUtilityTaxID) !== true) {
                this.setState({ waterUtilityTaxID_Error: "Invalid Tax ID" });
                this.waterUtilityTaxIDRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ waterUtilityTaxID_Error: "" });
            }
        }
        else {
            this.setState({ waterUtilityTaxID_Error: "" });
        }

        if (this.state.waterUtilityDUNSNumber !== "" && this.state.waterUtilityDUNSNumber !== null && this.state.waterUtilityDUNSNumber !== undefined) {
            if (this.state.waterUtilityDUNSNumber.length < 9 || /^\d+$/.test(this.state.waterUtilityDUNSNumber) !== true) {
                this.setState({ waterUtilityDUNSNumber_Error: "Invalid DUNS Number" });
                this.waterUtilityDUNSNumberRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ waterUtilityDUNSNumber_Error: "" });
            }
        }
        else {
            this.setState({ waterUtilityDUNSNumber_Error: "" });
        }

        if (this.state.monthlyWaterBillAmount !== "" && this.state.monthlyWaterBillAmount !== null && this.state.monthlyWaterBillAmount !== undefined) {
            if (/^\d+(\.\d{1,2})?$/.test(this.state.monthlyWaterBillAmount) !== true) {
                this.setState({ monthlyWaterBillAmount_Error: "Invalid Amount" });
                this.monthlyWaterBillAmountRef.current.focus();

                validationError = true;
            }
            else if (this.state.monthlyWaterBillAmount > 99999.99) {
                this.setState({ monthlyWaterBillAmount_Error: "Exceeds limit" });
                this.monthlyWaterBillAmountRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ monthlyWaterBillAmount_Error: "" });
            }
        }
        else {
            this.setState({ monthlyWaterBillAmount_Error: "" });
        }

        // if (this.state.percentageOfWaterBill !== "" && this.state.percentageOfWaterBill !== null && this.state.percentageOfWaterBill !== undefined) {
        //     if (/^\d+$/.test(this.state.percentageOfWaterBill) !== true) {
        //         this.setState({ percentageOfWaterBill_Error: "Invalid Number" });
        //         this.percentageOfWaterBillRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ percentageOfWaterBill_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ percentageOfWaterBill_Error: "" });
        // }

        if (this.state.noOfMonthsWaterUtilityPayments !== "" && this.state.noOfMonthsWaterUtilityPayments !== null && this.state.noOfMonthsWaterUtilityPayments !== undefined) {
            if (/^\d+$/.test(this.state.noOfMonthsWaterUtilityPayments) !== true) {
                this.setState({ noOfMonthsWaterUtilityPayments_Error: "Invalid Number" });
                this.noOfMonthsWaterUtilityPaymentsRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ noOfMonthsWaterUtilityPayments_Error: "" });
            }
        }
        else {
            this.setState({ noOfMonthsWaterUtilityPayments_Error: "" });
        }


        // if (this.state.trashUtilityAccountNo !== "" && this.state.trashUtilityAccountNo !== null && this.state.trashUtilityAccountNo !== undefined) {
        //     if (/^\d+$/.test(this.state.trashUtilityAccountNo) !== true) {
        //         this.setState({ trashUtilityAccountNo_Error: "Invalid Account Number" });
        //         this.trashUtilityAccountNoRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ trashUtilityAccountNo_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ trashUtilityAccountNo_Error: "" });
        // }

        if (this.state.amountDueforTrash !== "" && this.state.amountDueforTrash !== null && this.state.amountDueforTrash !== undefined) {
            if (/^\d+(\.\d{1,2})?$/.test(this.state.amountDueforTrash) !== true) {
                this.setState({ amountDueforTrash_Error: "Invalid Amount" });
                this.amountDueforTrashRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ amountDueforTrash_Error: "" });
            }
        }
        else {
            this.setState({ amountDueforTrash_Error: "" });
        }

        // if (this.state.custTrashBillingAccountNo !== "" && this.state.custTrashBillingAccountNo !== null && this.state.custTrashBillingAccountNo !== undefined) {
        //     if (/^\d+$/.test(this.state.custTrashBillingAccountNo) !== true) {
        //         this.setState({ custTrashBillingAccountNo_Error: "Invalid Account Number" });
        //         this.custTrashBillingAccountNoRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ custTrashBillingAccountNo_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ custTrashBillingAccountNo_Error: "" });
        // }

        if (this.state.trashAmount !== "" && this.state.trashAmount !== null && this.state.trashAmount !== undefined) {
            if (/^\d+(\.\d{1,2})?$/.test(this.state.trashAmount) !== true) {
                this.setState({ trashAmount_Error: "Invalid Amount" });
                this.trashAmountRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ trashAmount_Error: "" });
            }
        }
        else {
            this.setState({ trashAmount_Error: "" });
        }

        // if (this.state.trashUtilityProviderAccountNo !== "" && this.state.trashUtilityProviderAccountNo !== null && this.state.trashUtilityProviderAccountNo !== undefined) {
        //     if (/^\d+$/.test(this.state.trashUtilityProviderAccountNo) !== true) {
        //         this.setState({ trashUtilityProviderAccountNo_Error: "Invalid Account Number" });
        //         this.trashUtilityProviderAccountNoRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ trashUtilityProviderAccountNo_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ trashUtilityProviderAccountNo_Error: "" });
        // }

        if (this.state.trashRoutingNumber !== "" && this.state.trashRoutingNumber !== null && this.state.trashRoutingNumber !== undefined) {
            if (this.state.trashRoutingNumber.length < 9 || /^\d+$/.test(this.state.trashRoutingNumber) !== true) {
                this.setState({ trashRoutingNumber_Error: "Invalid Routing Number" });
                this.trashRoutingNumberRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ trashRoutingNumber_Error: "" });
            }
        }
        else {
            this.setState({ trashRoutingNumber_Error: "" });
        }

        // if (this.state.trashMemoAccountNo !== "" && this.state.trashMemoAccountNo !== null && this.state.trashMemoAccountNo !== undefined) {
        //     if (/^\d+$/.test(this.state.trashMemoAccountNo) !== true) {
        //         this.setState({ trashMemoAccountNo_Error: "Invalid Account Number" });
        //         this.trashMemoAccountNoRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ trashMemoAccountNo_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ trashMemoAccountNo_Error: "" });
        // }


        if (this.state.trashUtilityTaxID !== "" && this.state.trashUtilityTaxID !== null && this.state.trashUtilityTaxID !== undefined) {
            if (this.state.trashUtilityTaxID.length < 9 || /^\d+$/.test(this.state.trashUtilityTaxID) !== true) {
                this.setState({ trashUtilityTaxID_Error: "Invalid Tax ID" });
                this.trashUtilityTaxIDRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ trashUtilityTaxID_Error: "" });
            }
        }
        else {
            this.setState({ trashUtilityTaxID_Error: "" });
        }

        if (this.state.trashUtilityDUNSNumber !== "" && this.state.trashUtilityDUNSNumber !== null && this.state.trashUtilityDUNSNumber !== undefined) {
            if (this.state.trashUtilityDUNSNumber.length < 9 || /^\d+$/.test(this.state.trashUtilityDUNSNumber) !== true) {
                this.setState({ trashUtilityDUNSNumber_Error: "Invalid DUNS Number" });
                this.trashUtilityDUNSNumberRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ trashUtilityDUNSNumber_Error: "" });
            }
        }
        else {
            this.setState({ trashUtilityDUNSNumber_Error: "" });
        }

        if (this.state.monthlyTrashBillAmount !== "" && this.state.monthlyTrashBillAmount !== null && this.state.monthlyTrashBillAmount !== undefined) {
            if (/^\d+(\.\d{1,2})?$/.test(this.state.monthlyTrashBillAmount) !== true) {
                this.setState({ monthlyTrashBillAmount_Error: "Invalid Amount" });
                this.monthlyTrashBillAmountRef.current.focus();

                validationError = true;
            }
            else if (this.state.monthlyTrashBillAmount > 99999.99) {
                this.setState({ monthlyTrashBillAmount_Error: "Exceeds limit" });
                this.monthlyTrashBillAmountRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ monthlyTrashBillAmount_Error: "" });
            }
        }
        else {
            this.setState({ monthlyTrashBillAmount_Error: "" });
        }

        // if (this.state.percentageOfTrashBill !== "" && this.state.percentageOfTrashBill !== null && this.state.percentageOfTrashBill !== undefined) {
        //     if (/^\d+$/.test(this.state.percentageOfTrashBill) !== true) {
        //         this.setState({ percentageOfTrashBill_Error: "Invalid Number" });
        //         this.percentageOfTrashBillRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ percentageOfTrashBill_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ percentageOfTrashBill_Error: "" });
        // }

        if (this.state.noOfMonthsTrashUtilityPayments !== "" && this.state.noOfMonthsTrashUtilityPayments !== null && this.state.noOfMonthsTrashUtilityPayments !== undefined) {
            if (/^\d+$/.test(this.state.noOfMonthsTrashUtilityPayments) !== true) {
                this.setState({ noOfMonthsTrashUtilityPayments_Error: "Invalid Number" });
                this.noOfMonthsTrashUtilityPaymentsRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ noOfMonthsTrashUtilityPayments_Error: "" });
            }
        }
        else {
            this.setState({ noOfMonthsTrashUtilityPayments_Error: "" });
        }


        // if (this.state.energyUtilityAccountNo !== "" && this.state.energyUtilityAccountNo !== null && this.state.energyUtilityAccountNo !== undefined) {
        //     if (/^\d+$/.test(this.state.energyUtilityAccountNo) !== true) {
        //         this.setState({ energyUtilityAccountNo_Error: "Invalid Account Number" });
        //         this.energyUtilityAccountNoRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ energyUtilityAccountNo_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ energyUtilityAccountNo_Error: "" });
        // }

        if (this.state.amountDueforEnergy !== "" && this.state.amountDueforEnergy !== null && this.state.amountDueforEnergy !== undefined) {
            if (/^\d+(\.\d{1,2})?$/.test(this.state.amountDueforEnergy) !== true) {
                this.setState({ amountDueforEnergy_Error: "Invalid Amount" });
                this.amountDueforEnergyRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ amountDueforEnergy_Error: "" });
            }
        }
        else {
            this.setState({ amountDueforEnergy_Error: "" });
        }

        // if (this.state.custEnergyBillingAccountNo !== "" && this.state.custEnergyBillingAccountNo !== null && this.state.custEnergyBillingAccountNo !== undefined) {
        //     if (/^\d+$/.test(this.state.custEnergyBillingAccountNo) !== true) {
        //         this.setState({ custEnergyBillingAccountNo_Error: "Invalid Account Number" });
        //         this.custEnergyBillingAccountNoRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ custEnergyBillingAccountNo_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ custEnergyBillingAccountNo_Error: "" });
        // }

        if (this.state.energyAmount !== "" && this.state.energyAmount !== null && this.state.energyAmount !== undefined) {
            if (/^\d+(\.\d{1,2})?$/.test(this.state.energyAmount) !== true) {
                this.setState({ energyAmount_Error: "Invalid Amount" });
                this.energyAmountRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ energyAmount_Error: "" });
            }
        }
        else {
            this.setState({ energyAmount_Error: "" });
        }

        // if (this.state.energyUtilityProviderAccountNo !== "" && this.state.energyUtilityProviderAccountNo !== null && this.state.energyUtilityProviderAccountNo !== undefined) {
        //     if (/^\d+$/.test(this.state.energyUtilityProviderAccountNo) !== true) {
        //         this.setState({ energyUtilityProviderAccountNo_Error: "Invalid Account Number" });
        //         this.energyUtilityProviderAccountNoRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ energyUtilityProviderAccountNo_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ energyUtilityProviderAccountNo_Error: "" });
        // }

        if (this.state.energyRoutingNumber !== "" && this.state.energyRoutingNumber !== null && this.state.energyRoutingNumber !== undefined) {
            if (this.state.energyRoutingNumber.length < 9 || /^\d+$/.test(this.state.energyRoutingNumber) !== true) {
                this.setState({ energyRoutingNumber_Error: "Invalid Routing Number" });
                this.energyRoutingNumberRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ energyRoutingNumber_Error: "" });
            }
        }
        else {
            this.setState({ energyRoutingNumber_Error: "" });
        }

        // if (this.state.energyMemoAccountNo !== "" && this.state.energyMemoAccountNo !== null && this.state.energyMemoAccountNo !== undefined) {
        //     if (/^\d+$/.test(this.state.energyMemoAccountNo) !== true) {
        //         this.setState({ energyMemoAccountNo_Error: "Invalid Account Number" });
        //         this.energyMemoAccountNoRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ energyMemoAccountNo_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ energyMemoAccountNo_Error: "" });
        // }


        if (this.state.energyUtilityTaxID !== "" && this.state.energyUtilityTaxID !== null && this.state.energyUtilityTaxID !== undefined) {
            if (this.state.energyUtilityTaxID.length < 9 || /^\d+$/.test(this.state.energyUtilityTaxID) !== true) {
                this.setState({ energyUtilityTaxID_Error: "Invalid Tax ID" });
                this.energyUtilityTaxIDRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ energyUtilityTaxID_Error: "" });
            }
        }
        else {
            this.setState({ energyUtilityTaxID_Error: "" });
        }

        if (this.state.energyUtilityDUNSNumber !== "" && this.state.energyUtilityDUNSNumber !== null && this.state.energyUtilityDUNSNumber !== undefined) {
            if (this.state.energyUtilityDUNSNumber.length < 9 || /^\d+$/.test(this.state.energyUtilityDUNSNumber) !== true) {
                this.setState({ energyUtilityDUNSNumber_Error: "Invalid DUNS Number" });
                this.energyUtilityDUNSNumberRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ energyUtilityDUNSNumber_Error: "" });
            }
        }
        else {
            this.setState({ energyUtilityDUNSNumber_Error: "" });
        }

        if (this.state.monthlyEnergyBillAmount !== "" && this.state.monthlyEnergyBillAmount !== null && this.state.monthlyEnergyBillAmount !== undefined) {
            if (/^\d+(\.\d{1,2})?$/.test(this.state.monthlyEnergyBillAmount) !== true) {
                this.setState({ monthlyEnergyBillAmount_Error: "Invalid Amount" });
                this.monthlyEnergyBillAmountRef.current.focus();

                validationError = true;
            }
            else if (this.state.monthlyEnergyBillAmount > 99999.99) {
                this.setState({ monthlyEnergyBillAmount_Error: "Exceeds limit" });
                this.monthlyEnergyBillAmountRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ monthlyEnergyBillAmount_Error: "" });
            }
        }
        else {
            this.setState({ monthlyEnergyBillAmount_Error: "" });
        }

        // if (this.state.percentageOfEnergyBill !== "" && this.state.percentageOfEnergyBill !== null && this.state.percentageOfEnergyBill !== undefined) {
        //     if (/^\d+$/.test(this.state.percentageOfEnergyBill) !== true) {
        //         this.setState({ percentageOfEnergyBill_Error: "Invalid Number" });
        //         this.percentageOfEnergyBillRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ percentageOfEnergyBill_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ percentageOfEnergyBill_Error: "" });
        // }

        if (this.state.noOfMonthsEnergyUtilityPayments !== "" && this.state.noOfMonthsEnergyUtilityPayments !== null && this.state.noOfMonthsEnergyUtilityPayments !== undefined) {
            if (/^\d+$/.test(this.state.noOfMonthsEnergyUtilityPayments) !== true) {
                this.setState({ noOfMonthsEnergyUtilityPayments_Error: "Invalid Number" });
                this.noOfMonthsEnergyUtilityPaymentsRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ noOfMonthsEnergyUtilityPayments_Error: "" });
            }
        }
        else {
            this.setState({ noOfMonthsEnergyUtilityPayments_Error: "" });
        }

        if (this.state.otherPaymentAmount !== "" && this.state.otherPaymentAmount !== null && this.state.otherPaymentAmount !== undefined) {
            if (/^\d+(\.\d{1,2})?$/.test(this.state.otherPaymentAmount) !== true) {
                this.setState({ otherPaymentAmount_Error: "Invalid Amount" });
                this.otherPaymentAmountRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ otherPaymentAmount_Error: "" });
            }
        }
        else {
            this.setState({ otherPaymentAmount_Error: "" });
        }

        if (this.state.currentTotalHouseholdIncome !== "" && this.state.currentTotalHouseholdIncome !== null && this.state.currentTotalHouseholdIncome !== undefined) {
            if (/^\d+(\.\d{1,2})?$/.test(this.state.currentTotalHouseholdIncome) !== true) {
                this.setState({ currentTotalHouseholdIncome_Error: "Invalid Amount" });
                this.currentTotalHouseholdIncomeRef.current.focus();

                validationError = true;
            }
            else if (this.state.currentTotalHouseholdIncome > 9999999.99) {
                this.setState({ currentTotalHouseholdIncome_Error: "Exceeds limit" });
                this.currentTotalHouseholdIncomeRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ currentTotalHouseholdIncome_Error: "" });
            }
        }
        else {
            this.setState({ currentTotalHouseholdIncome_Error: "" });
        }

        if (this.state.spaServiceProviderEmail !== "" && this.state.spaServiceProviderEmail !== null && this.state.spaServiceProviderEmail !== undefined) {
            var spaServiceProviderEmailPattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!spaServiceProviderEmailPattern.test(this.state.spaServiceProviderEmail)) {
                this.setState({ spaServiceProviderEmail_Error: "Invalid Email" });
                this.spaServiceProviderEmailRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ spaServiceProviderEmail_Error: "" });
            }
        }
        else {
            this.setState({ spaServiceProviderEmail_Error: "" });
        }

        if (this.state.rentPaymentStatus_Original !== this.state.rentPaymentStatus && this.state.rentPaymentStatus === 'Issued') {
            // if (this.state.landlordEmail === null || this.state.landlordEmail === "" || this.state.landlordEmail === undefined) {
            //     this.setState({ landlordEmail_Error: "Email required" });
            //     this.landlordEmailRef.current.focus();

            //     validationError = true;
            // }
            // else {
            //     this.setState({ landlordEmail_Error: "" });
            // }

            if (this.state.spaServiceProviderEmail === null || this.state.spaServiceProviderEmail === "" || this.state.spaServiceProviderEmail === undefined) {
                this.setState({ spaServiceProviderEmail_Error: "Email required" });
                this.spaServiceProviderEmailRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ spaServiceProviderEmail_Error: "" });
            }

            if (this.state.firstName === "" || this.state.firstName === null || this.state.firstName === undefined) {
                this.setState({ firstName_Error: "First Name required" });
                this.firstNameRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ firstName_Error: "" });
            }

            if (this.state.addressLine1 === "" || this.state.addressLine1 === null || this.state.addressLine1 === undefined) {
                this.setState({ addressLine1_Error: "Address required" });
                this.addressLine1Ref.current.focus();

                validationError = true;
            }
            else {
                this.setState({ addressLine1_Error: "" });
            }

            if (this.state.city === "" || this.state.city === null || this.state.city === undefined) {
                this.setState({ city_Error: "City required" });
                this.cityRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ city_Error: "" });
            }

            if (this.state.state === "" || this.state.state === null || this.state.state === undefined) {
                this.setState({ state_Error: "State required" });
                this.stateRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ state_Error: "" });
            }

            if (this.state.zipcode === "" || this.state.zipcode === null || this.state.zipcode === undefined) {
                this.setState({ zipcode_Error: "Zip required" });
                this.zipcodeRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ zipcode_Error: "" });
            }

            if (this.state.uniqueID === "" || this.state.uniqueID === null || this.state.uniqueID === undefined) {
                this.setState({ uniqueID_Error: "Unique ID required" });
                this.uniqueIDRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ uniqueID_Error: "" });
            }
        }

        if (this.state.noOfTimesParticipated !== "" && this.state.noOfTimesParticipated !== null && this.state.noOfTimesParticipated !== undefined) {
            if (/^\d+$/.test(this.state.noOfTimesParticipated) !== true) {
                this.setState({ noOfTimesParticipated_Error: "Invalid Number" });
                this.noOfTimesParticipatedRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ noOfTimesParticipated_Error: "" });
            }
        }
        else {
            this.setState({ noOfTimesParticipated_Error: "" });
        }

        if (this.state.landlordSSN !== "" && this.state.landlordSSN !== null && this.state.landlordSSN !== undefined) {
            if (this.state.landlordSSN.length < 9 || /^\d+$/.test(this.state.landlordSSN) !== true) {
                this.setState({ landlordSSN_Error: "Invalid SSN Number" });
                this.landlordSSNRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ landlordSSN_Error: "" });
            }
        }
        else {
            this.setState({ landlordSSN_Error: "" });
        }

        if (this.state.landlordTaxID !== "" && this.state.landlordTaxID !== null && this.state.landlordTaxID !== undefined) {
            if (this.state.landlordTaxID.length < 9 || /^\d+$/.test(this.state.landlordTaxID) !== true) {
                this.setState({ landlordTaxID_Error: "Invalid Tax ID" });
                this.landlordTaxIDRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ landlordTaxID_Error: "" });
            }
        }
        else {
            this.setState({ landlordTaxID_Error: "" });
        }

        if (this.state.landlordDUNSNumber !== "" && this.state.landlordDUNSNumber !== null && this.state.landlordDUNSNumber !== undefined) {
            if (this.state.landlordDUNSNumber.length < 9 || /^\d+$/.test(this.state.landlordDUNSNumber) !== true) {
                this.setState({ landlordDUNSNumber_Error: "Invalid DUNS Number" });
                this.landlordDUNSNumberRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ landlordDUNSNumber_Error: "" });
            }
        }
        else {
            this.setState({ landlordDUNSNumber_Error: "" });
        }

        if (this.state.monthlyRent !== "" && this.state.monthlyRent !== null && this.state.monthlyRent !== undefined) {
            if (/^\d+(\.\d{1,2})?$/.test(this.state.monthlyRent) !== true) {
                this.setState({ monthlyRent_Error: "Invalid Amount" });
                this.monthlyRentRef.current.focus();

                validationError = true;
            }
            else if (this.state.monthlyRent > 99999.99) {
                this.setState({ monthlyRent_Error: "Exceeds limit" });
                this.monthlyRentRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ monthlyRent_Error: "" });
            }
        }
        else {
            this.setState({ monthlyRent_Error: "" });
        }

        // if (this.state.percentageOfMonthlyRent !== "" && this.state.percentageOfMonthlyRent !== null && this.state.percentageOfMonthlyRent !== undefined) {
        //     if (/^\d+$/.test(this.state.percentageOfMonthlyRent) !== true) {
        //         this.setState({ percentageOfMonthlyRent_Error: "Invalid Number" });
        //         this.percentageOfMonthlyRentRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ percentageOfMonthlyRent_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ percentageOfMonthlyRent_Error: "" });
        // }

        if (this.state.noOfMonthsRentalPayments !== "" && this.state.noOfMonthsRentalPayments !== null && this.state.noOfMonthsRentalPayments !== undefined) {
            if (/^\d+$/.test(this.state.noOfMonthsRentalPayments) !== true) {
                this.setState({ noOfMonthsRentalPayments_Error: "Invalid Number" });
                this.noOfMonthsRentalPaymentsRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ noOfMonthsRentalPayments_Error: "" });
            }
        }
        else {
            this.setState({ noOfMonthsRentalPayments_Error: "" });
        }

        if (this.state.outstandingRentalArrearsAmount !== "" && this.state.outstandingRentalArrearsAmount !== null && this.state.outstandingRentalArrearsAmount !== undefined) {
            if (/^\d+(\.\d{1,2})?$/.test(this.state.outstandingRentalArrearsAmount) !== true) {
                this.setState({ outstandingRentalArrearsAmount_Error: "Invalid Amount" });
                this.outstandingRentalArrearsAmountRef.current.focus();

                validationError = true;
            }
            else if (this.state.outstandingRentalArrearsAmount > 99999.99) {
                this.setState({ outstandingRentalArrearsAmount_Error: "Exceeds limit" });
                this.outstandingRentalArrearsAmountRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ outstandingRentalArrearsAmount_Error: "" });
            }
        }
        else {
            this.setState({ outstandingRentalArrearsAmount_Error: "" });
        }

        if (this.state.electricUtilityTaxID !== "" && this.state.electricUtilityTaxID !== null && this.state.electricUtilityTaxID !== undefined) {
            if (this.state.electricUtilityTaxID.length < 9 || /^\d+$/.test(this.state.electricUtilityTaxID) !== true) {
                this.setState({ electricUtilityTaxID_Error: "Invalid Tax ID" });
                this.electricUtilityTaxIDRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ electricUtilityTaxID_Error: "" });
            }
        }
        else {
            this.setState({ electricUtilityTaxID_Error: "" });
        }

        if (this.state.electricUtilityDUNSNumber !== "" && this.state.electricUtilityDUNSNumber !== null && this.state.electricUtilityDUNSNumber !== undefined) {
            if (this.state.electricUtilityDUNSNumber.length < 9 || /^\d+$/.test(this.state.electricUtilityDUNSNumber) !== true) {
                this.setState({ electricUtilityDUNSNumber_Error: "Invalid DUNS Number" });
                this.electricUtilityDUNSNumberRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ electricUtilityDUNSNumber_Error: "" });
            }
        }
        else {
            this.setState({ electricUtilityDUNSNumber_Error: "" });
        }

        if (this.state.monthlyElectricBillAmount !== "" && this.state.monthlyElectricBillAmount !== null && this.state.monthlyElectricBillAmount !== undefined) {
            if (/^\d+(\.\d{1,2})?$/.test(this.state.monthlyElectricBillAmount) !== true) {
                this.setState({ monthlyElectricBillAmount_Error: "Invalid Amount" });
                this.monthlyElectricBillAmountRef.current.focus();

                validationError = true;
            }
            else if (this.state.monthlyElectricBillAmount > 99999.99) {
                this.setState({ monthlyElectricBillAmount_Error: "Exceeds limit" });
                this.monthlyElectricBillAmountRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ monthlyElectricBillAmount_Error: "" });
            }
        }
        else {
            this.setState({ monthlyElectricBillAmount_Error: "" });
        }

        // if (this.state.percentageOfElectricBill !== "" && this.state.percentageOfElectricBill !== null && this.state.percentageOfElectricBill !== undefined) {
        //     if (/^\d+$/.test(this.state.percentageOfElectricBill) !== true) {
        //         this.setState({ percentageOfElectricBill_Error: "Invalid Number" });
        //         this.percentageOfElectricBillRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ percentageOfElectricBill_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ percentageOfElectricBill_Error: "" });
        // }

        if (this.state.noOfMonthsElectricUtilityPayments !== "" && this.state.noOfMonthsElectricUtilityPayments !== null && this.state.noOfMonthsElectricUtilityPayments !== undefined) {
            if (/^\d+$/.test(this.state.noOfMonthsElectricUtilityPayments) !== true) {
                this.setState({ noOfMonthsElectricUtilityPayments_Error: "Invalid Number" });
                this.noOfMonthsElectricUtilityPaymentsRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ noOfMonthsElectricUtilityPayments_Error: "" });
            }
        }
        else {
            this.setState({ noOfMonthsElectricUtilityPayments_Error: "" });
        }

        if (this.state.effectOfCovid19Financially === 'Other' &&
            this.state.covid_other !== "" && this.state.covid_other !== null && this.state.covid_other !== undefined) {
            if (this.state.covid_other.length > 100) {
                this.setState({ covid_other_Error: "Text too long" });
                this.covid_otherRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ covid_other_Error: "" });
            }
        }
        else {
            this.setState({ covid_other_Error: "" });
        }

        if (this.state.workIndustry === 'Other' &&
            this.state.industry_other !== "" && this.state.industry_other !== null && this.state.industry_other !== undefined) {
            if (this.state.industry_other.length > 100) {
                this.setState({ industry_other_Error: "Text too long" });
                this.industry_otherRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ industry_other_Error: "" });
            }
        }
        else {
            this.setState({ industry_other_Error: "" });
        }

        if (this.state.tenantEmail !== "" && this.state.tenantEmail !== null && this.state.tenantEmail !== undefined) {
            var tenantEmailPattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!tenantEmailPattern.test(this.state.tenantEmail)) {
                this.setState({ tenantEmail_Error: "Invalid Email" });
                this.tenantEmailRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ tenantEmail_Error: "" });
            }
        }
        else {
            this.setState({ tenantEmail_Error: "" });
        }


        // if (this.state.individualAccountNumber !== "" && this.state.individualAccountNumber !== null && this.state.individualAccountNumber !== undefined) {
        //     if (/^\d+$/.test(this.state.individualAccountNumber) !== true) {
        //         this.setState({ individualAccountNumber_Error: "Invalid Account Number" });
        //         this.individualAccountNumberRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ individualAccountNumber_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ individualAccountNumber_Error: "" });
        // }


        if (this.state.individualRoutingNumber !== "" && this.state.individualRoutingNumber !== null && this.state.individualRoutingNumber !== undefined) {
            if (this.state.individualRoutingNumber.length < 9 || /^\d+$/.test(this.state.individualRoutingNumber) !== true) {
                this.setState({ individualRoutingNumber_Error: "Invalid Routing Number" });
                this.individualRoutingNumberRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ individualRoutingNumber_Error: "" });
            }
        }
        else {
            this.setState({ individualRoutingNumber_Error: "" });
        }


        if (this.state.individualPaymentAmount !== "" && this.state.individualPaymentAmount !== null && this.state.individualPaymentAmount !== undefined) {
            if (/^\d+(\.\d{1,2})?$/.test(this.state.individualPaymentAmount) !== true) {
                this.setState({ individualPaymentAmount_Error: "Invalid Amount" });
                this.individualPaymentAmountRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ individualPaymentAmount_Error: "" });
            }
        }
        else {
            this.setState({ individualPaymentAmount_Error: "" });
        }

        if (this.state.individualMemoPropertyAddress !== "" && this.state.individualMemoPropertyAddress !== null && this.state.individualMemoPropertyAddress !== undefined) {
            if (this.state.individualMemoPropertyAddress.length > 100) {
                this.setState({ individualMemoPropertyAddress_Error: "Address too long" });
                this.individualMemoPropertyAddressRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ individualMemoPropertyAddress_Error: "" });
            }
        }
        else {
            this.setState({ individualMemoPropertyAddress_Error: "" });
        }

        // if (this.state.otherAccountNumber !== "" && this.state.otherAccountNumber !== null && this.state.otherAccountNumber !== undefined) {
        //     if (/^\d+$/.test(this.state.otherAccountNumber) !== true) {
        //         this.setState({ otherAccountNumber_Error: "Invalid Account Number" });
        //         this.otherAccountNumberRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ otherAccountNumber_Error: "" });
        //     }
        // }
        // else {
        //     this.setState({ otherAccountNumber_Error: "" });
        // }


        if (this.state.otherRoutingNumber !== "" && this.state.otherRoutingNumber !== null && this.state.otherRoutingNumber !== undefined) {
            if (this.state.otherRoutingNumber.length < 9 || /^\d+$/.test(this.state.otherRoutingNumber) !== true) {
                this.setState({ otherRoutingNumber_Error: "Invalid Routing Number" });
                this.otherRoutingNumberRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ otherRoutingNumber_Error: "" });
            }
        }
        else {
            this.setState({ otherRoutingNumber_Error: "" });
        }


        if (this.state.otherPaymentAmount !== "" && this.state.otherPaymentAmount !== null && this.state.otherPaymentAmount !== undefined) {
            if (/^\d+(\.\d{1,2})?$/.test(this.state.otherPaymentAmount) !== true) {
                this.setState({ otherPaymentAmount_Error: "Invalid Amount" });
                this.otherPaymentAmountRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ otherPaymentAmount_Error: "" });
            }
        }
        else {
            this.setState({ otherPaymentAmount_Error: "" });
        }

        if (this.state.otherMemoPropertyAddress !== "" && this.state.otherMemoPropertyAddress !== null && this.state.otherMemoPropertyAddress !== undefined) {
            if (this.state.otherMemoPropertyAddress.length > 100) {
                this.setState({ otherMemoPropertyAddress_Error: "Address too long" });
                this.otherMemoPropertyAddressRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ otherMemoPropertyAddress_Error: "" });
            }
        }
        else {
            this.setState({ otherMemoPropertyAddress_Error: "" });
        }

        if (this.state.otherPayeeName !== "" && this.state.otherPayeeName !== null && this.state.otherPayeeName !== undefined) {
            if (this.state.otherPayeeName.length > 100) {
                this.setState({ otherPayeeName_Error: "Name too long" });
                this.otherPayeeNameRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ otherPayeeName_Error: "" });
            }
        }
        else {
            this.setState({ otherPayeeName_Error: "" });
        }

        if (this.state.otherDesription !== "" && this.state.otherDesription !== null && this.state.otherDesription !== undefined) {
            if (this.state.otherDesription.length > 100) {
                this.setState({ otherDesription_Error: "Description too long" });
                this.otherDesriptionRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ otherDesription_Error: "" });
            }
        }
        else {
            this.setState({ otherDesription_Error: "" });
        }

        if (this.state.notes !== "" && this.state.notes !== null && this.state.notes !== undefined) {
            if (this.state.notes.length > 15000) {
                this.setState({ notes_Error: "Notes too long" });
                this.notesRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ notes_Error: "" });
            }
        }
        else {
            this.setState({ notes_Error: "" });
        }

        ///////////////////////////   VALIDATION ENDS   ///////////////////////////

        if (validationError === true) {
            this.setState({ isLoading: false });
            return false;
        }

        return true;
    }

    loadIndividualDetails = async (searchParam) => {
        this.resetValues();
        this.forceUpdate();

        console.log(searchParam);
        this.setState({ isLoading: true, individualID: searchParam.split('+')[0], roundNumber: searchParam.split('+')[1] });

        // check the current user when the App component is loaded
        var adminUser = '';
        Auth.currentAuthenticatedUser().then(user => {
            //console.log(user);
            adminUser = user.attributes.email;
            this.setState({ authState: 'signedIn' });
        }).catch(e => {
            console.log(e);
            if(e === "not authenticated") {
                this.signOut();
            }
            this.setState({ authState: 'signIn' });
        });

        await this.loadData();

        // Set the logged-in admin user
        this.setState({ adminUsername: adminUser });

        // Restore the Issued Payment Status if the Case is not open.
        if (this.state.individualCase !== 'Open' && this.state.individualCase !== 'Ineligible' &&
            this.state.individualCase !== '' && this.state.individualCase !== null && this.state.individualCase !== undefined) {

            var rentPaymentStatusSelect = document.getElementById("rentPaymentStatusId");
            if (!(rentPaymentStatusSelect.options.length > 5)) {
                var rentPaymentStatusIssuedOption = document.createElement("option");
                rentPaymentStatusIssuedOption.text = "Issued";
                rentPaymentStatusIssuedOption.value = "Issued";
                rentPaymentStatusSelect.appendChild(rentPaymentStatusIssuedOption);
            }

            var electricPaymentStatusSelect = document.getElementById("electricPaymentStatusId");
            if (!(electricPaymentStatusSelect.options.length > 5)) {
                var electricPaymentStatusIssuedOption = document.createElement("option");
                electricPaymentStatusIssuedOption.text = "Issued";
                electricPaymentStatusIssuedOption.value = "Issued";
                electricPaymentStatusSelect.appendChild(electricPaymentStatusIssuedOption);
            }

            var gasPaymentStatusSelect = document.getElementById("gasPaymentStatusId");
            if (!(gasPaymentStatusSelect.options.length > 5)) {
                var gasPaymentStatusIssuedOption = document.createElement("option");
                gasPaymentStatusIssuedOption.text = "Issued";
                gasPaymentStatusIssuedOption.value = "Issued";
                gasPaymentStatusSelect.appendChild(gasPaymentStatusIssuedOption);
            }

            var waterPaymentStatusSelect = document.getElementById("waterPaymentStatusId");
            if (!(waterPaymentStatusSelect.options.length > 5)) {
                var waterPaymentStatusIssuedOption = document.createElement("option");
                waterPaymentStatusIssuedOption.text = "Issued";
                waterPaymentStatusIssuedOption.value = "Issued";
                waterPaymentStatusSelect.appendChild(waterPaymentStatusIssuedOption);
            }

            var trashPaymentStatusSelect = document.getElementById("trashPaymentStatusId");
            if (!(trashPaymentStatusSelect.options.length > 5)) {
                var trashPaymentStatusIssuedOption = document.createElement("option");
                trashPaymentStatusIssuedOption.text = "Issued";
                trashPaymentStatusIssuedOption.value = "Issued";
                trashPaymentStatusSelect.appendChild(trashPaymentStatusIssuedOption);
            }

            var energyPaymentStatusSelect = document.getElementById("energyPaymentStatusId");
            if (!(energyPaymentStatusSelect.options.length > 5)) {
                var energyPaymentStatusIssuedOption = document.createElement("option");
                energyPaymentStatusIssuedOption.text = "Issued";
                energyPaymentStatusIssuedOption.value = "Issued";
                energyPaymentStatusSelect.appendChild(energyPaymentStatusIssuedOption);
            }

            var individualPaymentStatusSelect = document.getElementById("individualPaymentStatusId");
            if (!(individualPaymentStatusSelect.options.length > 5)) {
                var individualPaymentStatusIssuedOption = document.createElement("option");
                individualPaymentStatusIssuedOption.text = "Issued";
                individualPaymentStatusIssuedOption.value = "Issued";
                individualPaymentStatusSelect.appendChild(individualPaymentStatusIssuedOption);
            }

            var otherPaymentStatusSelect = document.getElementById("otherPaymentStatusId");
            if (!(otherPaymentStatusSelect.options.length > 5)) {
                var otherPaymentStatusIssuedOption = document.createElement("option");
                otherPaymentStatusIssuedOption.text = "Issued";
                otherPaymentStatusIssuedOption.value = "Issued";
                otherPaymentStatusSelect.appendChild(otherPaymentStatusIssuedOption);
            }
        }
        else if (this.state.individualCase === 'Open') {
            var rentPaymentStatusSelect = document.getElementById("rentPaymentStatusId");
            if (rentPaymentStatusSelect.options.length > 5) {
                rentPaymentStatusSelect.remove(5);
            }

            var electricPaymentStatusSelect = document.getElementById("electricPaymentStatusId");
            if (electricPaymentStatusSelect.options.length > 5) {
                electricPaymentStatusSelect.remove(5);
            }

            var gasPaymentStatusSelect = document.getElementById("gasPaymentStatusId");
            if (gasPaymentStatusSelect.options.length > 5) {
                gasPaymentStatusSelect.remove(5);
            }

            var waterPaymentStatusSelect = document.getElementById("waterPaymentStatusId");
            if (waterPaymentStatusSelect.options.length > 5) {
                waterPaymentStatusSelect.remove(5);
            }

            var trashPaymentStatusSelect = document.getElementById("trashPaymentStatusId");
            if (trashPaymentStatusSelect.options.length > 5) {
                trashPaymentStatusSelect.remove(5);
            }

            var energyPaymentStatusSelect = document.getElementById("energyPaymentStatusId");
            if (energyPaymentStatusSelect.options.length > 5) {
                energyPaymentStatusSelect.remove(5);
            }

            var individualPaymentStatusSelect = document.getElementById("individualPaymentStatusId");
            if (individualPaymentStatusSelect.options.length > 5) {
                individualPaymentStatusSelect.remove(5);
            }

            var otherPaymentStatusSelect = document.getElementById("otherPaymentStatusId");
            if (otherPaymentStatusSelect.options.length > 5) {
                otherPaymentStatusSelect.remove(5);
            }
        }
        else if (this.state.individualCase === 'Ineligible') {
            document.getElementById("individualCaseId").disabled = false;
        }

        this.setState({ isLoading: false });
    }

    componentDidMount = async () => {
        this.setState({isLoading: true});
        this.props.parentCallback("EDIT ERA-OC APPLICATION", "", imgSearchclaim);
        try{
            this.getCurrentUserPermissions();
        }
        catch(error){
            console.log(error);      
            await this.signOut();    
            this.setState({isLoading: false});  
        };
        this.loadIndividualDetails(this.props.match.params.id);
        // this.setState({isLoading: false});
    }

    getCurrentUserPermissions= async ()=>{
        const{ permissions } = this.props;
        if (permissions != null && permissions.length > 0) {
            if (permissions.includes('CEPPClaimUser') && (permissions.indexOf("CEPPClaim") == -1)) {
                document.getElementById("myset").disabled = true;
                this.setState({ mode: "User" });
            }
        }
    }

    loadData = async () => {
        try {
            var res = await this.adminGetIndividual();
            if (res.length === 0) {
                console.log("ERROR: IndividualID is not available in System." + this.state.individualID);
                return;
            }

            //console.log(res[0]);

            var recList = await this.getReconciliationList();
            let dispList = recList.filter(x => x.roundNumber == this.state.roundNumber);
            this.setState({ reconciliationList: dispList, reconciliationListByIndividualId: recList, reconciliationListClone: JSON.parse(JSON.stringify(dispList)) });

            this.setState({
                firstName: res[0][0],
                lastName: res[0][1],
                partnerFirstName: res[0][2],
                partnerLastName: res[0][3],
                addressLine1: res[0][4],
                addressLine2: res[0][5],
                city: res[0][6],
                state: res[0][7],
                country: res[0][8],
                zipcode: res[0][9],
                clientDOB: res[0][10],

                phone: res[0][11],
                legallyAbleToWork: res[0][12],
                eligibleForStimulusFunds: res[0][13],
                veteran: res[0][14],
                monthlyIncomeBeforeCovid19: res[0][15],
                workIndustry: res[0][16],
                employmentStatus: res[0][17],
                effectOfCovid19Financially: res[0][18],
                householdNumber: res[0][19],
                age0to5: res[0][20],
                age6to17: res[0][21],
                age18to59: res[0][22],
                age60andUp: res[0][23],
                cardValue: res[0][24],
                participateInSurvey: res[0][25],
                claimStatus: res[0][26],
                dateImported: res[0][27],
                timeImported: res[0][28],
                dateFromHIMS: res[0][29],
                timeFromHIMS: res[0][30],
                dateClaimModified: res[0][31],
                timeClaimModified: res[0][32],
                notes: res[0][33],
                adminUsername: res[0][34],
                originator: res[0][35],
                programTitle: res[0][36],
                spaAssignmentNo: res[0][37],
                hmisApproval: res[0][38],
                householdID: res[0][39],
                transactionID: res[0][40],

                individualID: res[0][41],

                landlordPhoneNumber: res[0][42],
                landlordEmail: res[0][43],
                landlordEmail_Original: res[0][43],
                amountDueforRent: res[0][44],
                payee: res[0][45],
                rentAmount: res[0][46],
                landlordBankName: res[0][47],
                landlordAccountNumber: res[0][48],
                landlordRoutingNumber: res[0][49],
                memoPropertyAddress: res[0][50],
                rentPaymentStatus_Original: res[0][51],
                rentPaymentStatus: res[0][51],
                electricProvider: res[0][52],
                electricUtilityAccountNo: res[0][53],
                amountDueforElectricity: res[0][54],
                custElectricBillingAccountNo: res[0][55],
                electricAmount: res[0][56],
                electricBankName: res[0][57],
                electricUtilityProviderAccountNo: res[0][58],
                electricRoutingNumber: res[0][59],
                electricMemoAccountNo: res[0][60],
                electricPaymentStatus: res[0][61],
                electricPaymentStatus_Original: res[0][61],
                gasProvider: res[0][62],
                gasUtilityAccountNo: res[0][63],
                amountDueforGas: res[0][64],
                custGasBillingAccountNo: res[0][65],
                gasBankName: res[0][66],
                gasAmount: res[0][67],
                gasUtilityProviderAccountNo: res[0][68],
                gasRoutingNumber: res[0][69],
                gasMemoAccountNo: res[0][70],
                gasPaymentStatus: res[0][71],
                gasPaymentStatus_Original: res[0][71],
                otherPaymentAmount: res[0][72],
                otherPaymentStatus: res[0][73],
                otherPaymentStatus_Original: res[0][73],

                dateRentPaymentStatusUpdated: res[0][74],
                timeRentPaymentStatusUpdated: res[0][75],
                dateElectricPaymentStatusUpdated: res[0][76],
                timeElectricPaymentStatusUpdated: res[0][77],
                dateGasPaymentStatusUpdated: res[0][78],
                timeGasPaymentStatusUpdated: res[0][79],
                dateOtherPaymentStatusUpdated: res[0][80],
                timeOtherPaymentStatusUpdated: res[0][81],
                dateEmailTriggered: res[0][82],
                timeEmailTriggered: res[0][83],


                gender: res[0][84],
                race: res[0][85],
                ethnicity: res[0][86],
                currentTotalHouseholdIncome: res[0][87],
                openToFollowup: res[0][88],
                spaServiceProviderEmail: res[0][89],
                noOfTimesParticipated: res[0][90],

                landlordSSN: res[0][91],
                landlordTaxID: res[0][92],
                landlordDUNSNumber: res[0][93],
                rentTypeOfAssistance: res[0][94],
                monthlyRent: res[0][95],
                percentageOfMonthlyRent: res[0][96],
                outstandingRentalArrearsAmount: res[0][97],
                noOfMonthsRentalPayments: res[0][98],

                electricUtilityTaxID: res[0][99],
                electricUtilityDUNSNumber: res[0][100],
                monthlyElectricBillAmount: res[0][101],
                percentageOfElectricBill: res[0][102],
                noOfMonthsElectricUtilityPayments: res[0][103],
                electricTypeOfAssistance: res[0][104],

                gasUtilityTaxID: res[0][105],
                gasUtilityDUNSNumber: res[0][106],
                monthlyGasBillAmount: res[0][107],
                percentageOfGasBill: res[0][108],
                noOfMonthsGasUtilityPayments: res[0][109],
                gasTypeOfAssistance: res[0][110],

                covid_other: res[0][111],
                industry_other: res[0][112],

                tenantEmail: res[0][113],
                dateApplied: res[0][114],
                individualBankName: res[0][115],
                individualAccountNumber: res[0][116],
                individualRoutingNumber: res[0][117],
                individualMemoPropertyAddress: res[0][118],
                individualPaymentReason: res[0][119],
                individualPaymentStatus: res[0][120],
                individualPaymentStatus_Original: res[0][120],
                dateIndividualPaymentStatusUpdated: res[0][121],
                timeIndividualPaymentStatusUpdated: res[0][122],
                otherDesription: res[0][123],
                otherBankName: res[0][124],
                otherAccountNumber: res[0][125],
                otherRoutingNumber: res[0][126],
                otherMemoPropertyAddress: res[0][127],
                otherPayeeName: res[0][128],
                individualPaymentAmount: res[0][129],

                waterProvider: res[0][130],
                waterUtilityAccountNo: res[0][131],
                custWaterBillingAccountNo: res[0][132],
                monthlyWaterBillAmount: res[0][133],
                noOfMonthsWaterUtilityPayments: res[0][134],
                waterTypeOfAssistance: res[0][135],
                amountDueforWater: res[0][136],
                waterAmount: res[0][137],
                percentageOfWaterBill: res[0][138],
                waterBankName: res[0][139],
                waterUtilityProviderAccountNo: res[0][140],
                waterRoutingNumber: res[0][141],
                waterMemoAccountNo: res[0][142],
                waterUtilityTaxID: res[0][143],
                waterUtilityDUNSNumber: res[0][144],
                waterPaymentStatus: res[0][145],
                waterPaymentStatus_Original: res[0][145],
                dateWaterPaymentStatusUpdated: res[0][146],
                timeWaterPaymentStatusUpdated: res[0][147],

                trashProvider: res[0][148],
                trashUtilityAccountNo: res[0][149],
                custTrashBillingAccountNo: res[0][150],
                monthlyTrashBillAmount: res[0][151],
                noOfMonthsTrashUtilityPayments: res[0][152],
                trashTypeOfAssistance: res[0][153],
                amountDueforTrash: res[0][154],
                trashAmount: res[0][155],
                percentageOfTrashBill: res[0][156],
                trashBankName: res[0][157],
                trashUtilityProviderAccountNo: res[0][158],
                trashRoutingNumber: res[0][159],
                trashMemoAccountNo: res[0][160],
                trashUtilityTaxID: res[0][161],
                trashUtilityDUNSNumber: res[0][162],
                trashPaymentStatus: res[0][163],
                trashPaymentStatus_Original: res[0][163],
                dateTrashPaymentStatusUpdated: res[0][164],
                timeTrashPaymentStatusUpdated: res[0][165],

                energyProvider: res[0][166],
                energyUtilityAccountNo: res[0][167],
                custEnergyBillingAccountNo: res[0][168],
                monthlyEnergyBillAmount: res[0][169],
                noOfMonthsEnergyUtilityPayments: res[0][170],
                energyTypeOfAssistance: res[0][171],
                amountDueforEnergy: res[0][172],
                energyAmount: res[0][173],
                percentageOfEnergyBill: res[0][174],
                energyBankName: res[0][175],
                energyUtilityProviderAccountNo: res[0][176],
                energyRoutingNumber: res[0][177],
                energyMemoAccountNo: res[0][178],
                energyUtilityTaxID: res[0][179],
                energyUtilityDUNSNumber: res[0][180],
                energyPaymentStatus: res[0][181],
                energyPaymentStatus_Original: res[0][181],
                dateEnergyPaymentStatusUpdated: res[0][182],
                timeEnergyPaymentStatusUpdated: res[0][183],

                suffix: res[0][184],
                middleName: res[0][185],
                agent: res[0][186],
                doYouRent: res[0][187],
                receiveAnyHousingSubsidy: res[0][188],
                isAnnualIncomeLess: res[0][189],
                isSomeoneOnRentalOrLeaseAgreement: res[0][190],
                filedCovid19FinancialStressDeclaration: res[0][191],
                receivedEvictionNotice: res[0][192],
                dateEvictionNoticeReceived: res[0][193],
                SSN: res[0][194],
                socialSecurityFirstName: res[0][195],
                socialSecurityLastName: res[0][196],
                birthdayCode: res[0][197],
                age: res[0][198],
                otherPhone: res[0][199],
                secondaryFirstName: res[0][200],
                secondaryLastName: res[0][201],
                secondaryEmail: res[0][202],
                secondaryPhone: res[0][203],

                belowThirtyPercAMI: res[0][204],
                thirtyToFiftyPercAMI: res[0][205],
                fiftyToEightyPercAMI: res[0][206],
                boardDistrict: res[0][207],
                landlordFirstAttempt: res[0][208],
                landlordResponse: res[0][209],

                individualCase: res[0][210],
                individualCase_Original: res[0][210],
                reasonPaymentNotProcessedIn21Days: res[0][211],

                uniqueID: res[0][212],

                countAdult: res[0][213],
                countChildren: res[0][214],
                householdApprovedForUnitedWay: res[0][215],
                landlordACHReceived: res[0][216],
                individualACHReceived: res[0][217],

                roundNumber: res[0][218],

                requestedLandlordInfo: res[0][219],
                dateLandlordInfoRequested: (res[0][220] === '1000-01-01') ? '0000-00-00' : res[0][220],
                timeLandlordInfoRequested: res[0][221],
                countLandlordInfoRequested: res[0][222],
                dateLandlordInfoReminded: (res[0][223] === '1000-01-01') ? '0000-00-00' : res[0][223],
                timeLandlordInfoReminded: res[0][224],
                total1099Amount: res[0][225],

                // CAUTION: Add newly added db field(s) just before this statement and adjust the index of totalCovered and totalRecords fields starting the last index + 1.
                totalCovered: res[0][226],
                totalCoveredClone: res[0][226],
                totalRecords: res[0][227],
            });

            this.calculateTotal1099();
            this.calculateTotalFund();
            // Update the case dropdown after loading the data
            this.onPaymentStatusChange();

            // Update following fields based on the existing fields
            this.setState({
                timestampOfRentIssued: "",
                lengthOfTimeForAward: "0",
            });

            if (res[0][219] === true || res[0][219] === 1 || res[0][219] === "1") {   // requestedLandlordInfo
                if (res[0][223] !== null && res[0][224] !== null &&
                    res[0][223] !== '1000-01-01' && res[0][223] !== '0000-00-00') {     // dateLandlordInfoReminded, timeLandlordInfoReminded

                    var d = new Date(res[0][223]);
                    var infoRemindedDate = [
                        ('0' + (d.getMonth() + 1)).slice(-2),
                        ('0' + d.getDate()).slice(-2),
                        d.getFullYear()
                    ].join('/');
                    this.setState({ dateLandlordInfoReminded_Formatted: infoRemindedDate });

                    // var regExTimeArr = /([0-9]?[0-9]):([0-9][0-9]):([0-9][0-9])/.exec(res[0][224]);
                    // var hhmmss = '00:00:00';
                    // if (regExTimeArr != null && regExTimeArr.length === 4) {
                    //     hhmmss = ('0' + regExTimeArr[1]).slice(-2) + ':' + ('0' + regExTimeArr[2]).slice(-2) + ':' + ('0' + regExTimeArr[3]).slice(-2);
                    // }

                    // var infoRemindedTimestamp = new Date(res[0][223] + 'T' + hhmmss + '.000Z');   //"2021-06-01T10:54:54.000"
                    // this.setState({ dateLandlordInfoReminded_Formatted: new Date(infoRemindedTimestamp.toUTCString()).toLocaleString('en-US', 
                    //     {
                    //         //timeZone: 'Canada/Pacific', 
                    //         month: '2-digit', 
                    //         day: '2-digit', 
                    //         year: 'numeric', 
                    //         hour: '2-digit', 
                    //         minute:'2-digit', 
                    //         second:'2-digit', 
                    //         hour12: false 
                    //     }).replace(",", "") });

                    // //console.log(theDate.toLocaleString('en-US', { timeZone: 'America/Tijuana' }));
                    // //America/Tijuana
                }

                if (res[0][220] !== null && res[0][221] !== null &&
                    res[0][220] !== '1000-01-01' && res[0][220] !== '0000-00-00') {    // dateLandlordInfoRequested, timeLandlordInfoRequested
                    var d = new Date(res[0][220]);
                    var infoRequestedDate = [
                        ('0' + (d.getMonth() + 1)).slice(-2),
                        ('0' + d.getDate()).slice(-2),
                        d.getFullYear()
                    ].join('/');
                    this.setState({ dateLandlordInfoRequested_Formatted: infoRequestedDate });

                    // var regExTimeArr = /([0-9]?[0-9]):([0-9][0-9]):([0-9][0-9])/.exec(res[0][221]);
                    // var hhmmss = '00:00:00';
                    // if (regExTimeArr != null && regExTimeArr.length === 4) {
                    //     hhmmss = ('0' + regExTimeArr[1]).slice(-2) + ':' + ('0' + regExTimeArr[2]).slice(-2) + ':' + ('0' + regExTimeArr[3]).slice(-2);
                    // }

                    // var infoRequestedTimestamp = new Date(res[0][220] + 'T' + hhmmss + '.000Z');   //"2021-06-01T10:54:54.000"
                    // this.setState({ dateLandlordInfoRequested_Formatted: new Date(infoRequestedTimestamp.toUTCString()).toLocaleString('en-US', 
                    //     {
                    //         //timeZone: 'Canada/Pacific', 
                    //         month: '2-digit', 
                    //         day: '2-digit', 
                    //         year: 'numeric', 
                    //         hour: '2-digit', 
                    //         minute:'2-digit', 
                    //         second:'2-digit', 
                    //         hour12: false 
                    //     }).replace(",", "") });

                    // //console.log(theDate.toLocaleString('en-US', { timeZone: 'America/Tijuana' }));
                    // //America/Tijuana
                }
            }

            if (this.state.dateImported !== "" && this.state.dateImported !== null) {
                var d = new Date(this.state.dateImported);
                var formattedDate = [
                    ('0' + (d.getMonth() + 1)).slice(-2),
                    ('0' + d.getDate()).slice(-2),
                    d.getFullYear()
                ].join('/');

                this.setState({
                    dateImported_Formatted: formattedDate,
                });
            }

            if (this.state.rentPaymentStatus_Original === 'Issued') {

                if (this.state.dateRentPaymentStatusUpdated !== '' && this.state.dateRentPaymentStatusUpdated !== null && this.state.dateRentPaymentStatusUpdated !== undefined &&
                    this.state.timeRentPaymentStatusUpdated !== '' && this.state.timeRentPaymentStatusUpdated !== null && this.state.timeRentPaymentStatusUpdated !== undefined) {

                    var d = new Date(this.state.dateRentPaymentStatusUpdated);
                    var statusUpdatedDate = [
                        ('0' + (d.getMonth() + 1)).slice(-2),
                        ('0' + d.getDate()).slice(-2),
                        d.getFullYear()
                    ].join('/');

                    this.setState({
                        timestampOfRentIssued: statusUpdatedDate + ' ' + this.state.timeRentPaymentStatusUpdated,
                    });

                    if (this.state.dateApplied !== '' && this.state.dateApplied !== null && this.state.dateApplied !== undefined) {
                        this.setState({
                            lengthOfTimeForAward: this.date_diff_in_days(this.state.dateApplied, this.state.dateRentPaymentStatusUpdated),
                        });
                    }
                }
            }

            if (this.state.programTitle === '' || this.state.programTitle === null || this.state.programTitle === undefined) {
                this.setState({ programTitle: "ERA-OC" });
            }

            if (this.state.landlordACHReceived === '1' || this.state.landlordACHReceived === 1) {
                document.getElementById("landlordACHReceivedId").checked = true;
            }
            else {
                document.getElementById("landlordACHReceivedId").checked = false;
            }
            if (this.state.individualACHReceived === '1' || this.state.individualACHReceived === 1) {
                document.getElementById("individualACHReceivedId").checked = true;
            }
            else {
                document.getElementById("individualACHReceivedId").checked = false;
            }

            if (this.state.amountDueforRent !== 0 && this.state.amountDueforRent !== "0.00" &&
                this.state.amountDueforRent !== '' && this.state.amountDueforRent !== null && this.state.amountDueforRent !== undefined &&
                this.state.rentAmount !== 0 && this.state.rentAmount !== "0.00" &&
                this.state.rentAmount !== '' && this.state.rentAmount !== null && this.state.rentAmount !== undefined) {
                this.setState({ percentageOfMonthlyRent: ((this.state.rentAmount * 100) / this.state.amountDueforRent).toFixed(2) });
            }
            else {
                this.setState({ percentageOfMonthlyRent: '' });
            }

            if (this.state.amountDueforElectricity !== 0 && this.state.amountDueforElectricity !== "0.00" &&
                this.state.amountDueforElectricity !== '' && this.state.amountDueforElectricity !== null && this.state.amountDueforElectricity !== undefined &&
                this.state.electricAmount !== 0 && this.state.electricAmount !== "0.00" &&
                this.state.electricAmount !== '' && this.state.electricAmount !== null && this.state.electricAmount !== undefined) {
                this.setState({ percentageOfElectricBill: ((this.state.electricAmount * 100) / this.state.amountDueforElectricity).toFixed(2) });
            }
            else {
                this.setState({ percentageOfElectricBill: '' });
            }

            if (this.state.amountDueforGas !== 0 && this.state.amountDueforGas !== "0.00" &&
                this.state.amountDueforGas !== '' && this.state.amountDueforGas !== null && this.state.amountDueforGas !== undefined &&
                this.state.gasAmount !== 0 && this.state.gasAmount !== "0.00" &&
                this.state.gasAmount !== '' && this.state.gasAmount !== null && this.state.gasAmount !== undefined) {
                this.setState({ percentageOfGasBill: ((this.state.gasAmount * 100) / this.state.amountDueforGas).toFixed(2) });
            }
            else {
                this.setState({ percentageOfGasBill: '' });
            }

            if (this.state.amountDueforWater !== 0 && this.state.amountDueforWater !== "0.00" &&
                this.state.amountDueforWater !== '' && this.state.amountDueforWater !== null && this.state.amountDueforWater !== undefined &&
                this.state.waterAmount !== 0 && this.state.waterAmount !== "0.00" &&
                this.state.waterAmount !== '' && this.state.waterAmount !== null && this.state.waterAmount !== undefined) {
                this.setState({ percentageOfWaterBill: ((this.state.waterAmount * 100) / this.state.amountDueforWater).toFixed(2) });
            }
            else {
                this.setState({ percentageOfWaterBill: '' });
            }

            if (this.state.amountDueforTrash !== 0 && this.state.amountDueforTrash !== "0.00" &&
                this.state.amountDueforTrash !== '' && this.state.amountDueforTrash !== null && this.state.amountDueforTrash !== undefined &&
                this.state.trashAmount !== 0 && this.state.trashAmount !== "0.00" &&
                this.state.trashAmount !== '' && this.state.trashAmount !== null && this.state.trashAmount !== undefined) {
                this.setState({ percentageOfTrashBill: ((this.state.trashAmount * 100) / this.state.amountDueforTrash).toFixed(2) });
            }
            else {
                this.setState({ percentageOfTrashBill: '' });
            }

            if (this.state.amountDueforEnergy !== 0 && this.state.amountDueforEnergy !== "0.00" &&
                this.state.amountDueforEnergy !== '' && this.state.amountDueforEnergy !== null && this.state.amountDueforEnergy !== undefined &&
                this.state.energyAmount !== 0 && this.state.energyAmount !== "0.00" &&
                this.state.energyAmount !== '' && this.state.energyAmount !== null && this.state.energyAmount !== undefined) {
                this.setState({ percentageOfEnergyBill: ((this.state.energyAmount * 100) / this.state.amountDueforEnergy).toFixed(2) });
            }
            else {
                this.setState({ percentageOfEnergyBill: '' });
            }

            // comment due to reconsiliation changes
            // if ((this.state.otherPaymentAmount !== "" && this.state.otherPaymentAmount !== null && this.state.otherPaymentAmount !== undefined) ||
            //     (this.state.rentAmount !== '' && this.state.rentAmount !== null && this.state.rentAmount !== undefined)) {
            //     this.setState({ total1099Amount: +this.state.rentAmount + +this.state.otherPaymentAmount });
            // }

            res = await this.loadUploadedDocuments();
            //console.log(res);
        }
        catch (err) {
            console.log(err);
            console.log("ERROR: Failed to load the individual info.");
            return;
        }
    }

    adminGetIndividual = async () => {
        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayName;
        let path = '/getceppindividualbyindividualid';
        let myInit = { // OPTIONAL
            body: {
                "info": {
                    "individualID": this.state.individualID,
                    "roundNumber": this.state.roundNumber,
                }
            }, // replace this with attributes you need
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
        }
        return await API.post(apiName, path, myInit);
    }

    getReconciliationList = async () => {
        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayName;
        let path = '/reconciliationbyindividualid';
        let myInit = { // OPTIONAL
            body: {
                "info": {
                    "individualID": this.state.individualID,
                    "roundNumber": this.state.roundNumber,
                    "program": "cepp",
                }
            }, // replace this with attributes you need
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
        }
        return await API.post(apiName, path, myInit);
    }

    textExtractW9Delete = async (key,roundNumber) => {
        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayName;
        let path = '/textextracteraceppclaim';
        let myInit = { // OPTIONAL
            body: {
                "info": {
                    "individualID": this.state.individualID,
                    "roundNumber": roundNumber,
                    "modifiedBy": user.attributes.email,
                    "key": key,
                }
            }, // replace this with attributes you need
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
        }
        return await API.post(apiName, path, myInit);
    }

    resetValues = () => {
        this.setState({
            individualID: "0",

            firstName: "",
            lastName: "",
            partnerFirstName: "",
            partnerLastName: "",
            addressLine1: "",
            addressLine2: "",
            city: "",
            state: "",
            country: "",
            zipcode: "",
            clientDOB: "",
            phone: "",
            legallyAbleToWork: "",
            eligibleForStimulusFunds: "",
            veteran: "",
            monthlyIncomeBeforeCovid19: "",
            workIndustry: "",
            employmentStatus: "",
            effectOfCovid19Financially: "",
            householdNumber: "0",
            age0to5: "0",
            age6to17: "0",
            age18to59: "0",
            age60andUp: "0",
            cardValue: "",
            participateInSurvey: "",
            claimStatus: "",
            dateImported: "",
            timeImported: "",
            dateFromHIMS: "",
            timeFromHIMS: "",
            dateClaimModified: "",
            timeClaimModified: "",
            notes: "",
            adminUsername: "",
            originator: "",
            programTitle: "",
            spaAssignmentNo: "",
            hmisApproval: "",
            householdID: "0",
            transactionID: "0",
            landlordPhoneNumber: "",
            landlordEmail: "",
            amountDueforRent: "",
            payee: "",
            rentAmount: "",
            landlordBankName: "",
            landlordAccountNumber: "",
            landlordRoutingNumber: "",
            memoPropertyAddress: "",
            rentPaymentStatus: "",
            rentPaymentStatus_Original: "",
            electricProvider: "",
            electricUtilityAccountNo: "",
            amountDueforElectricity: "",
            custElectricBillingAccountNo: "",
            electricAmount: "",
            electricBankName: "",
            electricUtilityProviderAccountNo: "",
            electricRoutingNumber: "",
            electricMemoAccountNo: "",
            electricPaymentStatus: "",
            electricPaymentStatus_Original: "",
            gasProvider: "",
            gasUtilityAccountNo: "",
            amountDueforGas: "",
            custGasBillingAccountNo: "",
            gasBankName: "",
            gasAmount: "",
            gasUtilityProviderAccountNo: "",
            gasRoutingNumber: "",
            gasMemoAccountNo: "",
            gasPaymentStatus: "",
            gasPaymentStatus_Original: "",
            otherPaymentAmount: "",
            otherPaymentStatus: "",
            otherPaymentStatus_Original: "",

            dateEmailTriggered: "",
            timeEmailTriggered: "",
            dateRentPaymentStatusUpdated: "",
            timeRentPaymentStatusUpdated: "",
            dateElectricPaymentStatusUpdated: "",
            timeElectricPaymentStatusUpdated: "",
            dateGasPaymentStatusUpdated: "",
            timeGasPaymentStatusUpdated: "",
            dateOtherPaymentStatusUpdated: "",
            timeOtherPaymentStatusUpdated: "",

            currentTotalHouseholdIncome: "",
            openToFollowup: "",
            gender: "",
            race: "",
            ethnicity: "",
            spaServiceProviderEmail: "",
            noOfTimesParticipated: "",

            landlordSSN: "",
            landlordTaxID: "",
            landlordDUNSNumber: "",
            rentTypeOfAssistance: "",
            monthlyRent: "",
            percentageOfMonthlyRent: "",
            outstandingRentalArrearsAmount: "",
            noOfMonthsRentalPayments: "",

            electricUtilityTaxID: "",
            electricUtilityDUNSNumber: "",
            monthlyElectricBillAmount: "",
            percentageOfElectricBill: "",
            noOfMonthsElectricUtilityPayments: "",
            electricTypeOfAssistance: "",

            gasUtilityTaxID: "",
            gasUtilityDUNSNumber: "",
            monthlyGasBillAmount: "",
            percentageOfGasBill: "",
            noOfMonthsGasUtilityPayments: "",
            gasTypeOfAssistance: "",

            covid_other: "",
            industry_other: "",

            tenantEmail: "",
            dateApplied: "",
            individualBankName: "",
            individualAccountNumber: "",
            individualRoutingNumber: "",
            individualMemoPropertyAddress: "",
            individualPaymentReason: "",
            individualPaymentStatus: "",
            individualPaymentStatus_Original: "",
            dateIndividualPaymentStatusUpdated: "",
            timeIndividualPaymentStatusUpdated: "",
            otherDesription: "",
            otherBankName: "",
            otherAccountNumber: "",
            otherRoutingNumber: "",
            otherMemoPropertyAddress: "",
            otherPayeeName: "",
            individualPaymentAmount: "",

            waterProvider: "",
            waterUtilityAccountNo: "",
            custWaterBillingAccountNo: "",
            monthlyWaterBillAmount: "",
            noOfMonthsWaterUtilityPayments: "",
            waterTypeOfAssistance: "",
            amountDueforWater: "",
            waterAmount: "",
            percentageOfWaterBill: "",
            waterBankName: "",
            waterUtilityProviderAccountNo: "",
            waterRoutingNumber: "",
            waterMemoAccountNo: "",
            waterUtilityTaxID: "",
            waterUtilityDUNSNumber: "",
            waterPaymentStatus: "",
            waterPaymentStatus_Original: "",
            dateWaterPaymentStatusUpdated: "",
            timeWaterPaymentStatusUpdated: "",

            trashProvider: "",
            trashUtilityAccountNo: "",
            custTrashBillingAccountNo: "",
            monthlyTrashBillAmount: "",
            noOfMonthsTrashUtilityPayments: "",
            trashTypeOfAssistance: "",
            amountDueforTrash: "",
            trashAmount: "",
            percentageOfTrashBill: "",
            trashBankName: "",
            trashUtilityProviderAccountNo: "",
            trashRoutingNumber: "",
            trashMemoAccountNo: "",
            trashUtilityTaxID: "",
            trashUtilityDUNSNumber: "",
            trashPaymentStatus: "",
            trashPaymentStatus_Original: "",
            dateTrashPaymentStatusUpdated: "",
            timeTrashPaymentStatusUpdated: "",

            energyProvider: "",
            energyUtilityAccountNo: "",
            custEnergyBillingAccountNo: "",
            monthlyEnergyBillAmount: "",
            noOfMonthsEnergyUtilityPayments: "",
            energyTypeOfAssistance: "",
            amountDueforEnergy: "",
            energyAmount: "",
            percentageOfEnergyBill: "",
            energyBankName: "",
            energyUtilityProviderAccountNo: "",
            energyRoutingNumber: "",
            energyMemoAccountNo: "",
            energyUtilityTaxID: "",
            energyUtilityDUNSNumber: "",
            energyPaymentStatus: "",
            energyPaymentStatus_Original: "",
            dateEnergyPaymentStatusUpdated: "",
            timeEnergyPaymentStatusUpdated: "",

            suffix: "",
            middleName: "",
            agent: "",
            doYouRent: "",
            receiveAnyHousingSubsidy: "",
            isAnnualIncomeLess: "",
            isSomeoneOnRentalOrLeaseAgreement: "",
            filedCovid19FinancialStressDeclaration: "",
            receivedEvictionNotice: "",
            dateEvictionNoticeReceived: "",
            SSN: "",
            socialSecurityFirstName: "",
            socialSecurityLastName: "",
            birthdayCode: "",
            age: "",
            otherPhone: "",
            secondaryFirstName: "",
            secondaryLastName: "",
            secondaryEmail: "",
            secondaryPhone: "",

            belowThirtyPercAMI: "",
            thirtyToFiftyPercAMI: "",
            fiftyToEightyPercAMI: "",
            boardDistrict: "",
            landlordFirstAttempt: "",
            landlordResponse: "",

            individualCase: "",
            individualCase_Original: "",
            reasonPaymentNotProcessedIn21Days: "",

            uniqueID: "",

            countAdult: "",
            countChildren: "",
            householdApprovedForUnitedWay: "",
            landlordACHReceived: "",
            individualACHReceived: "",

            roundNumber: 1,

            requestedLandlordInfo: false,
            dateLandlordInfoRequested: '0000-00-00',
            dateLandlordInfoRequested_Formatted: '0000-00-00',
            timeLandlordInfoRequested: null,
            countLandlordInfoRequested: 0,
            dateLandlordInfoReminded: '0000-00-00',
            dateLandlordInfoReminded_Formatted: '0000-00-00',
            timeLandlordInfoReminded: null,

            totalCovered: 0,
            totalRecords: 1,

            lengthOfTimeForAward: "",
            timestampOfRentIssued: "",

            triggerEmail: "0",
        });
    }

    updateIndividualDetails = async () => {
        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayName;
        let path = '/modifyceppindividual';
        let myInit = { // OPTIONAL
            body: {
                "info": {
                    "individualID": this.state.individualID,

                    "firstName": this.state.firstName,
                    "lastName": this.state.lastName,
                    "partnerFirstName": this.state.partnerFirstName,
                    "partnerLastName": this.state.partnerLastName,
                    "addressLine1": this.state.addressLine1,
                    "addressLine2": this.state.addressLine2,
                    "city": this.state.city,
                    "state": this.state.state,
                    "country": this.state.country,
                    "zipcode": this.state.zipcode,
                    "clientDOB": this.state.clientDOB,
                    "phone": this.state.phone,
                    "legallyAbleToWork": this.state.legallyAbleToWork,
                    "eligibleForStimulusFunds": this.state.eligibleForStimulusFunds,
                    "veteran": this.state.veteran,
                    "monthlyIncomeBeforeCovid19": this.state.monthlyIncomeBeforeCovid19,
                    "workIndustry": this.state.workIndustry,
                    "employmentStatus": this.state.employmentStatus,
                    "effectOfCovid19Financially": this.state.effectOfCovid19Financially,
                    "householdNumber": this.state.householdNumber,
                    "age0to5": this.state.age0to5,
                    "age6to17": this.state.age6to17,
                    "age18to59": this.state.age18to59,
                    "age60andUp": this.state.age60andUp,
                    "cardValue": this.state.cardValue,
                    "participateInSurvey": this.state.participateInSurvey,
                    "claimStatus": this.state.claimStatus,
                    "dateImported": this.state.dateImported,
                    "timeImported": this.state.timeImported,
                    "dateFromHIMS": this.state.dateFromHIMS,
                    "timeFromHIMS": this.state.timeFromHIMS,
                    "dateClaimModified": this.state.dateClaimModified,
                    "timeClaimModified": this.state.timeClaimModified,
                    "notes": this.state.notes,
                    "adminUsername": this.state.adminUsername,
                    "originator": this.state.originator,
                    "programTitle": this.state.programTitle,
                    "spaAssignmentNo": this.state.spaAssignmentNo,
                    "hmisApproval": this.state.hmisApproval,
                    "householdID": this.state.householdID,
                    "transactionID": this.state.transactionID,
                    "landlordPhoneNumber": this.state.landlordPhoneNumber,
                    "landlordEmail": this.state.landlordEmail,
                    "amountDueforRent": this.state.amountDueforRent,
                    "payee": this.state.payee,
                    "rentAmount": this.state.rentAmount,
                    "landlordBankName": this.state.landlordBankName,
                    "landlordAccountNumber": this.state.landlordAccountNumber,
                    "landlordRoutingNumber": this.state.landlordRoutingNumber,
                    "memoPropertyAddress": this.state.memoPropertyAddress,
                    "rentPaymentStatus": this.state.rentPaymentStatus,
                    "electricProvider": this.state.electricProvider,
                    "electricUtilityAccountNo": this.state.electricUtilityAccountNo,
                    "amountDueforElectricity": this.state.amountDueforElectricity,
                    "custElectricBillingAccountNo": this.state.custElectricBillingAccountNo,
                    "electricAmount": this.state.electricAmount,
                    "electricBankName": this.state.electricBankName,
                    "electricUtilityProviderAccountNo": this.state.electricUtilityProviderAccountNo,
                    "electricRoutingNumber": this.state.electricRoutingNumber,
                    "electricMemoAccountNo": this.state.electricMemoAccountNo,
                    "electricPaymentStatus": this.state.electricPaymentStatus,
                    "gasProvider": this.state.gasProvider,
                    "gasUtilityAccountNo": this.state.gasUtilityAccountNo,
                    "amountDueforGas": this.state.amountDueforGas,
                    "custGasBillingAccountNo": this.state.custGasBillingAccountNo,
                    "gasBankName": this.state.gasBankName,
                    "gasAmount": this.state.gasAmount,
                    "gasUtilityProviderAccountNo": this.state.gasUtilityProviderAccountNo,
                    "gasRoutingNumber": this.state.gasRoutingNumber,
                    "gasMemoAccountNo": this.state.gasMemoAccountNo,
                    "gasPaymentStatus": this.state.gasPaymentStatus,
                    "otherPaymentAmount": this.state.otherPaymentAmount,
                    "otherPaymentStatus": this.state.otherPaymentStatus,

                    "dateEmailTriggered": this.state.dateEmailTriggered,
                    "timeEmailTriggered": this.state.timeEmailTriggered,
                    "dateRentPaymentStatusUpdated": this.state.dateRentPaymentStatusUpdated,
                    "timeRentPaymentStatusUpdated": this.state.timeRentPaymentStatusUpdated,
                    "dateElectricPaymentStatusUpdated": this.state.dateElectricPaymentStatusUpdated,
                    "timeElectricPaymentStatusUpdated": this.state.timeElectricPaymentStatusUpdated,
                    "dateGasPaymentStatusUpdated": this.state.dateGasPaymentStatusUpdated,
                    "timeGasPaymentStatusUpdated": this.state.timeGasPaymentStatusUpdated,
                    "dateOtherPaymentStatusUpdated": this.state.dateOtherPaymentStatusUpdated,
                    "timeOtherPaymentStatusUpdated": this.state.timeOtherPaymentStatusUpdated,

                    "currentTotalHouseholdIncome": this.state.currentTotalHouseholdIncome,
                    "openToFollowup": this.state.openToFollowup,
                    "gender": this.state.gender,
                    "race": this.state.race,
                    "ethnicity": this.state.ethnicity,
                    "spaServiceProviderEmail": this.state.spaServiceProviderEmail,
                    "noOfTimesParticipated": this.state.noOfTimesParticipated,

                    "landlordSSN": this.state.landlordSSN,
                    "landlordTaxID": this.state.landlordTaxID,
                    "landlordDUNSNumber": this.state.landlordDUNSNumber,
                    "rentTypeOfAssistance": this.state.rentTypeOfAssistance,
                    "monthlyRent": this.state.monthlyRent,
                    "percentageOfMonthlyRent": this.state.percentageOfMonthlyRent,
                    "outstandingRentalArrearsAmount": this.state.outstandingRentalArrearsAmount,
                    "noOfMonthsRentalPayments": this.state.noOfMonthsRentalPayments,

                    "electricUtilityTaxID": this.state.electricUtilityTaxID,
                    "electricUtilityDUNSNumber": this.state.electricUtilityDUNSNumber,
                    "monthlyElectricBillAmount": this.state.monthlyElectricBillAmount,
                    "percentageOfElectricBill": this.state.percentageOfElectricBill,
                    "noOfMonthsElectricUtilityPayments": this.state.noOfMonthsElectricUtilityPayments,
                    "electricTypeOfAssistance": this.state.electricTypeOfAssistance,

                    "gasUtilityTaxID": this.state.gasUtilityTaxID,
                    "gasUtilityDUNSNumber": this.state.gasUtilityDUNSNumber,
                    "monthlyGasBillAmount": this.state.monthlyGasBillAmount,
                    "percentageOfGasBill": this.state.percentageOfGasBill,
                    "noOfMonthsGasUtilityPayments": this.state.noOfMonthsGasUtilityPayments,
                    "gasTypeOfAssistance": this.state.gasTypeOfAssistance,

                    "covid_other": this.state.covid_other,
                    "industry_other": this.state.industry_other,

                    "tenantEmail": this.state.tenantEmail,
                    "dateApplied": this.state.dateApplied,
                    "individualBankName": this.state.individualBankName,
                    "individualAccountNumber": this.state.individualAccountNumber,
                    "individualRoutingNumber": this.state.individualRoutingNumber,
                    "individualMemoPropertyAddress": this.state.individualMemoPropertyAddress,
                    "individualPaymentReason": this.state.individualPaymentReason,
                    "individualPaymentStatus": this.state.individualPaymentStatus,
                    "dateIndividualPaymentStatusUpdated": this.state.dateIndividualPaymentStatusUpdated,
                    "timeIndividualPaymentStatusUpdated": this.state.timeIndividualPaymentStatusUpdated,
                    "otherDesription": this.state.otherDesription,
                    "otherBankName": this.state.otherBankName,
                    "otherAccountNumber": this.state.otherAccountNumber,
                    "otherRoutingNumber": this.state.otherRoutingNumber,
                    "otherMemoPropertyAddress": this.state.otherMemoPropertyAddress,
                    "otherPayeeName": this.state.otherPayeeName,
                    "individualPaymentAmount": this.state.individualPaymentAmount,

                    "waterProvider": this.state.waterProvider,
                    "waterUtilityAccountNo": this.state.waterUtilityAccountNo,
                    "custWaterBillingAccountNo": this.state.custWaterBillingAccountNo,
                    "monthlyWaterBillAmount": this.state.monthlyWaterBillAmount,
                    "noOfMonthsWaterUtilityPayments": this.state.noOfMonthsWaterUtilityPayments,
                    "waterTypeOfAssistance": this.state.waterTypeOfAssistance,
                    "amountDueforWater": this.state.amountDueforWater,
                    "waterAmount": this.state.waterAmount,
                    "percentageOfWaterBill": this.state.percentageOfWaterBill,
                    "waterBankName": this.state.waterBankName,
                    "waterUtilityProviderAccountNo": this.state.waterUtilityProviderAccountNo,
                    "waterRoutingNumber": this.state.waterRoutingNumber,
                    "waterMemoAccountNo": this.state.waterMemoAccountNo,
                    "waterUtilityTaxID": this.state.waterUtilityTaxID,
                    "waterUtilityDUNSNumber": this.state.waterUtilityDUNSNumber,
                    "waterPaymentStatus": this.state.waterPaymentStatus,
                    "dateWaterPaymentStatusUpdated": this.state.dateWaterPaymentStatusUpdated,
                    "timeWaterPaymentStatusUpdated": this.state.timeWaterPaymentStatusUpdated,

                    "trashProvider": this.state.trashProvider,
                    "trashUtilityAccountNo": this.state.trashUtilityAccountNo,
                    "custTrashBillingAccountNo": this.state.custTrashBillingAccountNo,
                    "monthlyTrashBillAmount": this.state.monthlyTrashBillAmount,
                    "noOfMonthsTrashUtilityPayments": this.state.noOfMonthsTrashUtilityPayments,
                    "trashTypeOfAssistance": this.state.trashTypeOfAssistance,
                    "amountDueforTrash": this.state.amountDueforTrash,
                    "trashAmount": this.state.trashAmount,
                    "percentageOfTrashBill": this.state.percentageOfTrashBill,
                    "trashBankName": this.state.trashBankName,
                    "trashUtilityProviderAccountNo": this.state.trashUtilityProviderAccountNo,
                    "trashRoutingNumber": this.state.trashRoutingNumber,
                    "trashMemoAccountNo": this.state.trashMemoAccountNo,
                    "trashUtilityTaxID": this.state.trashUtilityTaxID,
                    "trashUtilityDUNSNumber": this.state.trashUtilityDUNSNumber,
                    "trashPaymentStatus": this.state.trashPaymentStatus,
                    "dateTrashPaymentStatusUpdated": this.state.dateTrashPaymentStatusUpdated,
                    "timeTrashPaymentStatusUpdated": this.state.timeTrashPaymentStatusUpdated,

                    "energyProvider": this.state.energyProvider,
                    "energyUtilityAccountNo": this.state.energyUtilityAccountNo,
                    "custEnergyBillingAccountNo": this.state.custEnergyBillingAccountNo,
                    "monthlyEnergyBillAmount": this.state.monthlyEnergyBillAmount,
                    "noOfMonthsEnergyUtilityPayments": this.state.noOfMonthsEnergyUtilityPayments,
                    "energyTypeOfAssistance": this.state.energyTypeOfAssistance,
                    "amountDueforEnergy": this.state.amountDueforEnergy,
                    "energyAmount": this.state.energyAmount,
                    "percentageOfEnergyBill": this.state.percentageOfEnergyBill,
                    "energyBankName": this.state.energyBankName,
                    "energyUtilityProviderAccountNo": this.state.energyUtilityProviderAccountNo,
                    "energyRoutingNumber": this.state.energyRoutingNumber,
                    "energyMemoAccountNo": this.state.energyMemoAccountNo,
                    "energyUtilityTaxID": this.state.energyUtilityTaxID,
                    "energyUtilityDUNSNumber": this.state.energyUtilityDUNSNumber,
                    "energyPaymentStatus": this.state.energyPaymentStatus,
                    "dateEnergyPaymentStatusUpdated": this.state.dateEnergyPaymentStatusUpdated,
                    "timeEnergyPaymentStatusUpdated": this.state.timeEnergyPaymentStatusUpdated,

                    "suffix": this.state.suffix,
                    "middleName": this.state.middleName,
                    "agent": this.state.agent,
                    "doYouRent": this.state.doYouRent,
                    "receiveAnyHousingSubsidy": this.state.receiveAnyHousingSubsidy,
                    "isAnnualIncomeLess": this.state.isAnnualIncomeLess,
                    "isSomeoneOnRentalOrLeaseAgreement": this.state.isSomeoneOnRentalOrLeaseAgreement,
                    "filedCovid19FinancialStressDeclaration": this.state.filedCovid19FinancialStressDeclaration,
                    "receivedEvictionNotice": this.state.receivedEvictionNotice,
                    "dateEvictionNoticeReceived": this.state.dateEvictionNoticeReceived,
                    "SSN": this.state.SSN,
                    "socialSecurityFirstName": this.state.socialSecurityFirstName,
                    "socialSecurityLastName": this.state.socialSecurityLastName,
                    "birthdayCode": this.state.birthdayCode,
                    "age": this.state.age,
                    "otherPhone": this.state.otherPhone,
                    "secondaryFirstName": this.state.secondaryFirstName,
                    "secondaryLastName": this.state.secondaryLastName,
                    "secondaryEmail": this.state.secondaryEmail,
                    "secondaryPhone": this.state.secondaryPhone,

                    "belowThirtyPercAMI": this.state.belowThirtyPercAMI,
                    "thirtyToFiftyPercAMI": this.state.thirtyToFiftyPercAMI,
                    "fiftyToEightyPercAMI": this.state.fiftyToEightyPercAMI,
                    "boardDistrict": this.state.boardDistrict,
                    "landlordFirstAttempt": this.state.landlordFirstAttempt,
                    "landlordResponse": this.state.landlordResponse,

                    "individualCase": this.state.individualCase,
                    "reasonPaymentNotProcessedIn21Days": this.state.reasonPaymentNotProcessedIn21Days,

                    "uniqueID": this.state.uniqueID,

                    "countAdult": this.state.countAdult,
                    "countChildren": this.state.countChildren,
                    "householdApprovedForUnitedWay": this.state.householdApprovedForUnitedWay,
                    "landlordACHReceived": this.state.landlordACHReceived,
                    "individualACHReceived": this.state.individualACHReceived,

                    "roundNumber": this.state.roundNumber,

                    "requestedLandlordInfo": this.state.requestedLandlordInfo,
                    "dateLandlordInfoRequested": (this.state.dateLandlordInfoRequested === '0000-00-00') ? '1000-01-01' : this.state.dateLandlordInfoRequested,
                    "timeLandlordInfoRequested": this.state.timeLandlordInfoRequested,

                    "countLandlordInfoRequested": this.state.countLandlordInfoRequested,
                    "dateLandlordInfoReminded": (this.state.dateLandlordInfoReminded === '0000-00-00') ? '1000-01-01' : this.state.dateLandlordInfoReminded,
                    "timeLandlordInfoReminded": this.state.timeLandlordInfoReminded,

                    "lengthOfTimeForAward": this.state.lengthOfTimeForAward,
                    "timestampOfRentIssued": this.state.timestampOfRentIssued,
                    "total1099Amount": this.state.total1099Amount,

                    //"triggerEmail": this.state.triggerEmail,
                }
            }, // replace this with attributes you need
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
        }
        return await API.post(apiName, path, myInit);
    };

    UploadIndividualDocuments = async () => {

        if (this.state.individualID === -1 || this.state.individualID === 0) {
            // early return
            return;
        }

        Storage.configure({
            bucket: process.env.REACT_APP_S3FrontendBucket,
            region: process.env.REACT_APP_Region,
            level: 'public',
        });

        this.tenant_payment_agreement_upload = document.getElementById("tenant_payment_agreement_upload_id");
        if (this.tenant_payment_agreement_upload !== null && this.tenant_payment_agreement_upload.files != null && this.tenant_payment_agreement_upload.files.length > 0) {
            try {
                var result = await Storage.put(`Individuals/Individual_${this.state.individualID}/tenant_payment_agreement/${this.tenant_payment_agreement_upload.files[0].name}`,
                    this.tenant_payment_agreement_upload.files[0],
                    { contentType: this.tenant_payment_agreement_upload.files[0].type });
                //console.log(result);
                this.tenant_payment_agreement_upload = null;

                this.setState({ tenant_payment_agreement_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }

        this.landlord_payment_agreement_upload = document.getElementById("landlord_payment_agreement_upload_id");
        if (this.landlord_payment_agreement_upload !== null && this.landlord_payment_agreement_upload.files != null && this.landlord_payment_agreement_upload.files.length > 0) {
            try {
                var result = await Storage.put(`Individuals/Individual_${this.state.individualID}/landlord_payment_agreement/${this.landlord_payment_agreement_upload.files[0].name}`,
                    this.landlord_payment_agreement_upload.files[0],
                    { contentType: this.landlord_payment_agreement_upload.files[0].type });
                //console.log(result);
                this.landlord_payment_agreement_upload = null;

                this.setState({ landlord_payment_agreement_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }

        if (this.state.individualCase_Original !== 'Open') {
            // early return. don't allow rest of the documents to upload if individual case is set to other than open.
            return;
        }

        this.photo_identification_upload = document.getElementById("photo_identification_upload_id");
        this.proof_of_address_upload = document.getElementById("proof_of_address_upload_id");
        this.proof_of_income_upload = document.getElementById("proof_of_income_upload_id");
        this.proof_of_income_loss_upload = document.getElementById("proof_of_income_loss_upload_id");
        this.ach_authorization_form_upload = document.getElementById("ach_authorization_form_upload_id");
        this.lease_agreement_upload = document.getElementById("lease_agreement_upload_id");
        this.utility_gas_bill_upload = document.getElementById("utility_gas_bill_upload_id");
        this.utility_electricity_bill_upload = document.getElementById("utility_electricity_bill_upload_id");
        this.landlord_w9_upload = document.getElementById("landlord_w9_upload_id");
        this.landlord_driver_license_upload = document.getElementById("landlord_driver_license_upload_id");
        this.verified_checkpoint_upload = document.getElementById("verified_checkpoint_upload_id");
        this.round2_landlord_updated_w9_upload = document.getElementById("round2_landlord_updated_w9_upload_id");
        this.round2_updated_program_participation_Form_upload = document.getElementById("round2_updated_program_participation_Form_upload_id");
        this.round2_landlord_identity_upload = document.getElementById("round2_landlord_identity_upload_id");
        this.round2_landlord_checkpoint_upload = document.getElementById("round2_landlord_checkpoint_upload_id");

        // Upload the new documents to be uploaded `Individuals/Individual_${this.state.individualID}/`
        if (this.photo_identification_upload !== null && this.photo_identification_upload.files != null && this.photo_identification_upload.files.length > 0) {
            try {
                var result = await Storage.put(`Individuals/Individual_${this.state.individualID}/photo_identification/${this.photo_identification_upload.files[0].name}`,
                    this.photo_identification_upload.files[0],
                    { contentType: this.photo_identification_upload.files[0].type });
                //console.log(result);
                this.photo_identification_upload = null;

                this.setState({ photo_identification_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }

        if (this.proof_of_address_upload !== null && this.proof_of_address_upload.files != null && this.proof_of_address_upload.files.length > 0) {
            try {
                var result = await Storage.put(`Individuals/Individual_${this.state.individualID}/proof_of_address/${this.proof_of_address_upload.files[0].name}`,
                    this.proof_of_address_upload.files[0],
                    { contentType: this.proof_of_address_upload.files[0].type });
                //console.log(result);
                this.proof_of_address_upload = null;

                this.setState({ proof_of_address_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }

        if (this.proof_of_income_upload !== null && this.proof_of_income_upload.files != null && this.proof_of_income_upload.files.length > 0) {
            try {
                var result = await Storage.put(`Individuals/Individual_${this.state.individualID}/proof_of_income/${this.proof_of_income_upload.files[0].name}`,
                    this.proof_of_income_upload.files[0],
                    { contentType: this.proof_of_income_upload.files[0].type });
                //console.log(result);
                this.proof_of_income_upload = null;

                this.setState({ proof_of_income_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }

        if (this.proof_of_income_loss_upload !== null && this.proof_of_income_loss_upload.files != null && this.proof_of_income_loss_upload.files.length > 0) {
            try {
                var result = await Storage.put(`Individuals/Individual_${this.state.individualID}/proof_of_income_loss/${this.proof_of_income_loss_upload.files[0].name}`,
                    this.proof_of_income_loss_upload.files[0],
                    { contentType: this.proof_of_income_loss_upload.files[0].type });
                //console.log(result);
                this.proof_of_income_loss_upload = null;

                this.setState({ proof_of_income_loss_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }

        if (this.ach_authorization_form_upload !== null && this.ach_authorization_form_upload.files != null && this.ach_authorization_form_upload.files.length > 0) {
            try {
                var result = await Storage.put(`Individuals/Individual_${this.state.individualID}/ach_authorization_form/${this.ach_authorization_form_upload.files[0].name}`,
                    this.ach_authorization_form_upload.files[0],
                    { contentType: this.ach_authorization_form_upload.files[0].type });
                //console.log(result);
                this.ach_authorization_form_upload = null;

                this.setState({ ach_authorization_form_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }

        if (this.lease_agreement_upload !== null && this.lease_agreement_upload.files != null && this.lease_agreement_upload.files.length > 0) {
            try {
                var result = await Storage.put(`Individuals/Individual_${this.state.individualID}/lease_agreement/${this.lease_agreement_upload.files[0].name}`,
                    this.lease_agreement_upload.files[0],
                    { contentType: this.lease_agreement_upload.files[0].type });
                //console.log(result);
                this.lease_agreement_upload = null;

                this.setState({ lease_agreement_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }

        if (this.utility_gas_bill_upload !== null && this.utility_gas_bill_upload.files != null && this.utility_gas_bill_upload.files.length > 0) {
            try {
                var result = await Storage.put(`Individuals/Individual_${this.state.individualID}/utility_gas_bill/${this.utility_gas_bill_upload.files[0].name}`,
                    this.utility_gas_bill_upload.files[0],
                    { contentType: this.utility_gas_bill_upload.files[0].type });
                //console.log(result);
                this.utility_gas_bill_upload = null;

                this.setState({ utility_gas_bill_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }

        if (this.utility_electricity_bill_upload !== null && this.utility_electricity_bill_upload.files != null && this.utility_electricity_bill_upload.files.length > 0) {
            try {
                var result = await Storage.put(`Individuals/Individual_${this.state.individualID}/utility_electricity_bill/${this.utility_electricity_bill_upload.files[0].name}`,
                    this.utility_electricity_bill_upload.files[0],
                    { contentType: this.utility_electricity_bill_upload.files[0].type });
                //console.log(result);
                this.utility_electricity_bill_upload = null;

                this.setState({ utility_electricity_bill_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }

        if (this.landlord_w9_upload !== null && this.landlord_w9_upload.files != null && this.landlord_w9_upload.files.length > 0) {
            try {
                // var result = await Storage.put(`Individuals/Individual_${this.state.individualID}/landlord_w9/${this.landlord_w9_upload.files[0].name}`,
                //     this.landlord_w9_upload.files[0],
                //     { contentType: this.landlord_w9_upload.files[0].type });

                // var resultW9 = await Storage.put(`ERA-OC/W9_TextExtract/${this.state.individualID}_1_W9.` + (this.landlord_w9_upload.files[0].name).split('.').pop(),
                //     this.landlord_w9_upload.files[0],
                //     { contentType: this.landlord_w9_upload.files[0].type });

                // Note : As per discussion, new W9 will be uploaded under round 2 always
                var result = await Storage.put(`Individuals/Individual_${this.state.individualID}/round2_landlord_updated_w9/${this.landlord_w9_upload.files[0].name}`,
                    this.landlord_w9_upload.files[0],
                    { contentType: this.landlord_w9_upload.files[0].type });

                var resultW9 = await Storage.put(`ERA-OC/W9_TextExtract/${this.state.individualID}_2_W9.` + (this.landlord_w9_upload.files[0].name).split('.').pop(),
                    this.landlord_w9_upload.files[0],
                    { contentType: this.landlord_w9_upload.files[0].type });

                //console.log(result);
                this.landlord_w9_upload = null;

                this.setState({ landlord_w9_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }

        if (this.landlord_driver_license_upload !== null && this.landlord_driver_license_upload.files != null && this.landlord_driver_license_upload.files.length > 0) {
            try {
                var result = await Storage.put(`Individuals/Individual_${this.state.individualID}/landlord_driver_license/${this.landlord_driver_license_upload.files[0].name}`,
                    this.landlord_driver_license_upload.files[0],
                    { contentType: this.landlord_driver_license_upload.files[0].type });
                //console.log(result);
                this.landlord_driver_license_upload = null;

                this.setState({ landlord_driver_license_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }

        if (this.verified_checkpoint_upload !== null && this.verified_checkpoint_upload.files != null && this.verified_checkpoint_upload.files.length > 0) {
            try {
                var result = await Storage.put(`Individuals/Individual_${this.state.individualID}/verified_checkpoint/${this.verified_checkpoint_upload.files[0].name}`,
                    this.verified_checkpoint_upload.files[0],
                    { contentType: this.verified_checkpoint_upload.files[0].type });
                //console.log(result);
                this.verified_checkpoint_upload = null;

                this.setState({ verified_checkpoint_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }

        if (this.round2_landlord_updated_w9_upload !== null && this.round2_landlord_updated_w9_upload.files != null && this.round2_landlord_updated_w9_upload.files.length > 0) {
            try {
                var result = await Storage.put(`Individuals/Individual_${this.state.individualID}/round2_landlord_updated_w9/${this.round2_landlord_updated_w9_upload.files[0].name}`,
                    this.round2_landlord_updated_w9_upload.files[0],
                    { contentType: this.round2_landlord_updated_w9_upload.files[0].type });

                var resultW9 = await Storage.put(`ERA-OC/W9_TextExtract/${this.state.individualID}_2_W9.` + (this.round2_landlord_updated_w9_upload.files[0].name).split('.').pop(),
                    this.round2_landlord_updated_w9_upload.files[0],
                    { contentType: this.round2_landlord_updated_w9_upload.files[0].type });
                
                //console.log(result);
                this.round2_landlord_updated_w9_upload = null;

                this.setState({ round2_landlord_updated_w9_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }

        if (this.round2_updated_program_participation_Form_upload !== null && this.round2_updated_program_participation_Form_upload.files != null && this.round2_updated_program_participation_Form_upload.files.length > 0) {
            try {
                var result = await Storage.put(`Individuals/Individual_${this.state.individualID}/round2_updated_program_participation_Form/${this.round2_updated_program_participation_Form_upload.files[0].name}`,
                    this.round2_updated_program_participation_Form_upload.files[0],
                    { contentType: this.round2_updated_program_participation_Form_upload.files[0].type });
                //console.log(result);
                this.round2_updated_program_participation_Form_upload = null;

                this.setState({ round2_updated_program_participation_Form_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }

        if (this.round2_landlord_identity_upload !== null && this.round2_landlord_identity_upload.files != null && this.round2_landlord_identity_upload.files.length > 0) {
            try {
                var result = await Storage.put(`Individuals/Individual_${this.state.individualID}/round2_landlord_identity/${this.round2_landlord_identity_upload.files[0].name}`,
                    this.round2_landlord_identity_upload.files[0],
                    { contentType: this.round2_landlord_identity_upload.files[0].type });
                //console.log(result);
                this.round2_landlord_identity_upload = null;

                this.setState({ round2_landlord_identity_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }

        if (this.round2_landlord_checkpoint_upload !== null && this.round2_landlord_checkpoint_upload.files != null && this.round2_landlord_checkpoint_upload.files.length > 0) {
            try {
                var result = await Storage.put(`Individuals/Individual_${this.state.individualID}/round2_landlord_checkpoint/${this.round2_landlord_checkpoint_upload.files[0].name}`,
                    this.round2_landlord_checkpoint_upload.files[0],
                    { contentType: this.round2_landlord_checkpoint_upload.files[0].type });
                //console.log(result);
                this.round2_landlord_checkpoint_upload = null;

                this.setState({ round2_landlord_checkpoint_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }

    }

    lockDocuments = async () => {
        if (this.state.individualCase_Original !== 'Open') {
            this.photo_identification_upload = document.getElementById("photo_identification_upload_id");
            this.proof_of_address_upload = document.getElementById("proof_of_address_upload_id");
            this.proof_of_income_upload = document.getElementById("proof_of_income_upload_id");
            this.proof_of_income_loss_upload = document.getElementById("proof_of_income_loss_upload_id");
            this.ach_authorization_form_upload = document.getElementById("ach_authorization_form_upload_id");
            this.lease_agreement_upload = document.getElementById("lease_agreement_upload_id");
            this.utility_gas_bill_upload = document.getElementById("utility_gas_bill_upload_id");
            this.utility_electricity_bill_upload = document.getElementById("utility_electricity_bill_upload_id");
            this.landlord_w9_upload = document.getElementById("landlord_w9_upload_id");
            this.landlord_driver_license_upload = document.getElementById("landlord_driver_license_upload_id");
            this.verified_checkpoint_upload = document.getElementById("verified_checkpoint_upload_id");
            this.round2_landlord_updated_w9_upload = document.getElementById("round2_landlord_updated_w9_upload_id");
            this.round2_updated_program_participation_Form_upload = document.getElementById("round2_updated_program_participation_Form_upload_id");
            this.round2_landlord_identity_upload = document.getElementById("round2_landlord_identity_upload_id");
            this.round2_landlord_checkpoint_upload = document.getElementById("round2_landlord_checkpoint_upload_id");
            // this.tenant_payment_agreement_upload = document.getElementById("tenant_payment_agreement_upload_id");
            // this.landlord_payment_agreement_upload = document.getElementById("landlord_payment_agreement_upload_id");

            if (this.photo_identification_upload !== null) {
                this.photo_identification_upload.disabled = true;
            }
            if (this.proof_of_address_upload !== null) {
                this.proof_of_address_upload.disabled = true;
            }
            if (this.proof_of_income_upload !== null) {
                this.proof_of_income_upload.disabled = true;
            }
            if (this.proof_of_income_loss_upload !== null) {
                this.proof_of_income_loss_upload.disabled = true;
            }
            if (this.ach_authorization_form_upload !== null) {
                this.ach_authorization_form_upload.disabled = true;
            }
            if (this.lease_agreement_upload !== null) {
                this.lease_agreement_upload.disabled = true;
            }
            if (this.utility_gas_bill_upload !== null) {
                this.utility_gas_bill_upload.disabled = true;
            }
            if (this.utility_electricity_bill_upload !== null) {
                this.utility_electricity_bill_upload.disabled = true;
            }
            if (this.landlord_w9_upload !== null) {
                this.landlord_w9_upload.disabled = true;
            }
            if (this.landlord_driver_license_upload !== null) {
                this.landlord_driver_license_upload.disabled = true;
            }
            if (this.verified_checkpoint_upload !== null) {
                this.verified_checkpoint_upload.disabled = true;
            }
            if (this.round2_landlord_updated_w9_upload !== null) {
                this.round2_landlord_updated_w9_upload.disabled = true;
            }
            if (this.round2_updated_program_participation_Form_upload !== null) {
                this.round2_updated_program_participation_Form_upload.disabled = true;
            }
            if (this.round2_landlord_identity_upload !== null) {
                this.round2_landlord_identity_upload.disabled = true;
            }
            if (this.round2_landlord_checkpoint_upload !== null) {
                this.round2_landlord_checkpoint_upload.disabled = true;
            }
            // if (this.tenant_payment_agreement_upload !== null) {
            //     this.tenant_payment_agreement_upload.disabled = true;
            // }
            // if (this.landlord_payment_agreement_upload !== null) {
            //     this.landlord_payment_agreement_upload.disabled = true;
            // }
        }
    }

    loadUploadedDocuments = async () => {
        this.setState({ isLoading: true });

        document.getElementById('photo_identification_id').innerHTML = `<td>Photo Identification<div id="photo_identification_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="photo_identification_upload_id" /></td>`;
        document.getElementById('proof_of_address_id').innerHTML = `<td>Proof of Address<div id="proof_of_address_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="proof_of_address_upload_id" /></td>`;
        document.getElementById('proof_of_income_id').innerHTML = `<td>Proof of Income<div id="proof_of_income_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="proof_of_income_upload_id" /></td>`;
        document.getElementById('proof_of_income_loss_id').innerHTML = `<td>Proof of Income loss<div id="proof_of_income_loss_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="proof_of_income_loss_upload_id" /></td>`;
        document.getElementById('ach_authorization_form_id').innerHTML = `<td>Program Participation Form<div id="ach_authorization_form_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="ach_authorization_form_upload_id" /></td>`;
        document.getElementById('lease_agreement_id').innerHTML = `<td>Lease Agreement<div id="lease_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="lease_agreement_upload_id" /></td>`;
        document.getElementById('utility_gas_bill_id').innerHTML = `<td>Utility Gas Bill<div id="utility_gas_bill_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="utility_gas_bill_upload_id" /></td>`;
        document.getElementById('utility_electricity_bill_id').innerHTML = `<td>Utility Electricity Bill<div id="utility_electricity_bill_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="utility_electricity_bill_upload_id" /></td>`;
        document.getElementById('landlord_w9_id').innerHTML = `<td>Landlord W9<div id="landlord_w9_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="landlord_w9_upload_id" /></td>`;
        document.getElementById('landlord_driver_license_id').innerHTML = `<td>Landlord Identification<div id="landlord_driver_license_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="landlord_driver_license_upload_id" /></td>`;
        document.getElementById('verified_checkpoint_id').innerHTML = `<td>CheckPoint ID Verification Page<div id="verified_checkpoint_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="verified_checkpoint_upload_id" /></td>`;
        document.getElementById('round2_landlord_updated_w9_id').innerHTML = `<td>Round 2 updated Landlord W9<div id="round2_landlord_updated_w9_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="round2_landlord_updated_w9_upload_id" /></td>`;
        document.getElementById('round2_updated_program_participation_Form_id').innerHTML = `<td>Round 2 updated Program Participation Form<div id="round2_updated_program_participation_Form_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="round2_updated_program_participation_Form_upload_id" /></td>`;
        document.getElementById('round2_landlord_identity_id').innerHTML = `<td>Round 2 Landlord Identity<div id="round2_landlord_identity_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="round2_landlord_identity_upload_id" /></td>`;
        document.getElementById('round2_landlord_checkpoint_id').innerHTML = `<td>Round 2 Landlord checkpoint ID Verification<div id="round2_landlord_checkpoint_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="round2_landlord_checkpoint_upload_id" /></td>`;
        document.getElementById('tenant_payment_agreement_id').innerHTML = `<td>Tenant Payment Agreement<div id="tenant_payment_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="tenant_payment_agreement_upload_id" /></td>`;
        document.getElementById('landlord_payment_agreement_id').innerHTML = `<td>Landlord Payment Agreement<div id="landlord_payment_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="landlord_payment_agreement_upload_id" /></td>`;

        // Lock documents if individual case is set to other than open
        this.lockDocuments();

        // Reset all the flags
        this.setState({
            photo_identification_uploaded: false,
            proof_of_address_uploaded: false,
            proof_of_income_uploaded: false,
            proof_of_income_loss_uploaded: false,
            ach_authorization_form_uploaded: false,
            lease_agreement_uploaded: false,
            utility_gas_bill_uploaded: false,
            utility_electricity_bill_uploaded: false,
            landlord_w9_uploaded: false,
            landlord_driver_license_uploaded: false,
            verified_checkpoint_uploaded: false,
            round2_landlord_updated_w9_uploaded: false,
            round2_updated_program_participation_Form_uploaded: false,
            round2_landlord_identity_uploaded: false,
            round2_landlord_checkpoint_uploaded: false,
            tenant_payment_agreement_uploaded: false,
            landlord_payment_agreement_uploaded: false,
        });

        Storage.configure({
            bucket: process.env.REACT_APP_S3FrontendBucket,
            region: process.env.REACT_APP_Region,
            level: 'public',
        });

        //get file names for s3 keys
        try {
            var key = `Individuals/Individual_${this.state.individualID}/`;
            var files = await Storage.list(key);
            //console.log(files);
        }
        catch (err) {
            console.log("Error Files - " + err);
            this.setState({ isLoading: false });
            return 1;
        }

        for (var i = 0; i < files.length; i++) {
            if (files[i].key.includes("photo_identification/")) {
                try {
                    var file1 = await Storage.get(files[i].key);
                    this.setState({ photo_identification: file1 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    if (this.state.mode && this.state.mode == "User") {
                        document.getElementById('photo_identification_id').innerHTML = `<td>Photo Identification<div id="photo_identification_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.photo_identification}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File1"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
                    }
                    else {
                        document.getElementById('photo_identification_id').innerHTML = `<td>Photo Identification<div id="photo_identification_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.photo_identification}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File1"><i class="fas fa-trash-alt red"></i></a></td>`;
                        var aTag1 = document.getElementById('deleteS3File1');
                        if (aTag1 !== null && aTag1 !== undefined) {
                            if (this.state.individualCase_Original !== 'Open') {
                                aTag1.style.visibility = "hidden";
                            }
                            else {
                                if (aTag1.addEventListener) {
                                    aTag1.addEventListener("click", () => { this.deleteS3File(1) });
                                }
                                else if (aTag1.attachEvent) {
                                    aTag1.attachEvent("click", () => { this.deleteS3File(1) });
                                }
                            }
                        }
                    }

                    this.setState({ photo_identification_uploaded: true });
                }
                catch (err) {
                    console.log("Error photo_identification - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('photo_identification_id').innerHTML = `<td>Photo Identification<div id="photo_identification_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="photo_identification_upload_id" /></td>`;
                    return 1;
                }
            }
            else if (files[i].key.includes("proof_of_address/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ proof_of_address: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    if (this.state.mode && this.state.mode == "User") {
                        document.getElementById('proof_of_address_id').innerHTML = `<td>Proof of Address<div id="proof_of_address_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.proof_of_address}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File2"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
                    }
                    else {
                        document.getElementById('proof_of_address_id').innerHTML = `<td>Proof of Address<div id="proof_of_address_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.proof_of_address}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File2"><i class="fas fa-trash-alt red"></i></a></td>`;
                        var aTag2 = document.getElementById('deleteS3File2');
                        if (aTag2 !== null && aTag2 !== undefined) {
                            if (this.state.individualCase_Original !== 'Open') {
                                aTag2.style.visibility = "hidden";
                            }
                            else {
                                if (aTag2.addEventListener) {
                                    aTag2.addEventListener("click", () => { this.deleteS3File(2) });
                                }
                                else if (aTag2.attachEvent) {
                                    aTag2.attachEvent("click", () => { this.deleteS3File(2) });
                                }
                            }
                        }
                    }
                    this.setState({ proof_of_address_uploaded: true });
                }
                catch (err) {
                    console.log("Error proof_of_address - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('proof_of_address_id').innerHTML = `<td>Proof of Address<div id="proof_of_address_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="proof_of_address_upload_id" /></td>`;
                    return 2;
                }
            }
            else if (files[i].key.includes("proof_of_income/")) {
                try {
                    var file31 = await Storage.get(files[i].key);
                    this.setState({ proof_of_income: file31 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    if (this.state.mode && this.state.mode == "User") {
                        document.getElementById('proof_of_income_id').innerHTML = `<td>Proof of Income<div id="proof_of_income_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.proof_of_income}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File3"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
                    }
                    else {
                        document.getElementById('proof_of_income_id').innerHTML = `<td>Proof of Income<div id="proof_of_income_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.proof_of_income}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File3"><i class="fas fa-trash-alt red"></i></a></td>`;
                        var aTag31 = document.getElementById('deleteS3File3');
                        if (aTag31 !== null && aTag31 !== undefined) {
                            if (this.state.individualCase_Original !== 'Open') {
                                aTag31.style.visibility = "hidden";
                            }
                            else {
                                if (aTag31.addEventListener) {
                                    aTag31.addEventListener("click", () => { this.deleteS3File(3) });
                                }
                                else if (aTag31.attachEvent) {
                                    aTag31.attachEvent("click", () => { this.deleteS3File(3) });
                                }
                            }
                        }
                    }
                    this.setState({ proof_of_income_uploaded: true });
                }
                catch (err) {
                    console.log("Error proof_of_income - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('proof_of_income_id').innerHTML = `<td>Proof of Income<div id="proof_of_income_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="proof_of_income_upload_id" /></td>`;
                    return 3;
                }
            }
            else if (files[i].key.includes("proof_of_income_loss/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ proof_of_income_loss: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    if (this.state.mode && this.state.mode == "User") {
                        document.getElementById('proof_of_income_loss_id').innerHTML = `<td>Proof of Income loss<div id="proof_of_income_loss_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.proof_of_income_loss}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File4"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
                    }
                    else {
                        document.getElementById('proof_of_income_loss_id').innerHTML = `<td>Proof of Income loss<div id="proof_of_income_loss_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.proof_of_income_loss}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File4"><i class="fas fa-trash-alt red"></i></a></td>`;
                        var aTag2 = document.getElementById('deleteS3File4');
                        if (aTag2 !== null && aTag2 !== undefined) {
                            if (this.state.individualCase_Original !== 'Open') {
                                aTag2.style.visibility = "hidden";
                            }
                            else {
                                if (aTag2.addEventListener) {
                                    aTag2.addEventListener("click", () => { this.deleteS3File(4) });
                                }
                                else if (aTag2.attachEvent) {
                                    aTag2.attachEvent("click", () => { this.deleteS3File(4) });
                                }
                            }
                        }
                    }
                    this.setState({ proof_of_income_loss_uploaded: true });
                }
                catch (err) {
                    console.log("Error proof_of_income_loss - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('proof_of_income_loss_id').innerHTML = `<td>Proof of Income loss<div id="proof_of_income_loss_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="proof_of_income_loss_upload_id" /></td>`;
                    return 4;
                }
            }
            else if (files[i].key.includes("ach_authorization_form/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ ach_authorization_form: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    if (this.state.mode && this.state.mode == "User") {
                        document.getElementById('ach_authorization_form_id').innerHTML = `<td>Program Participation Form<div id="ach_authorization_form_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.ach_authorization_form}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File5"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
                    }
                    else {
                        document.getElementById('ach_authorization_form_id').innerHTML = `<td>Program Participation Form<div id="ach_authorization_form_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.ach_authorization_form}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File5"><i class="fas fa-trash-alt red"></i></a></td>`;
                        var aTag2 = document.getElementById('deleteS3File5');
                        if (aTag2 !== null && aTag2 !== undefined) {
                            if (this.state.individualCase_Original !== 'Open') {
                                aTag2.style.visibility = "hidden";
                            }
                            else {
                                if (aTag2.addEventListener) {
                                    aTag2.addEventListener("click", () => { this.deleteS3File(5) });
                                }
                                else if (aTag2.attachEvent) {
                                    aTag2.attachEvent("click", () => { this.deleteS3File(5) });
                                }
                            }
                        }
                    }
                    this.setState({ ach_authorization_form_uploaded: true });
                }
                catch (err) {
                    console.log("Error ach_authorization_form - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('ach_authorization_form_id').innerHTML = `<td>Program Participation Form<div id="ach_authorization_form_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="ach_authorization_form_upload_id" /></td>`;
                    return 5;
                }
            }
            else if (files[i].key.includes("lease_agreement/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ lease_agreement: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    if (this.state.mode && this.state.mode == "User") {
                        document.getElementById('lease_agreement_id').innerHTML = `<td>Lease Agreement<div id="lease_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.lease_agreement}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File6"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
                    }
                    else {
                        document.getElementById('lease_agreement_id').innerHTML = `<td>Lease Agreement<div id="lease_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.lease_agreement}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File6"><i class="fas fa-trash-alt red"></i></a></td>`;
                        var aTag2 = document.getElementById('deleteS3File6');
                        if (aTag2 !== null && aTag2 !== undefined) {
                            if (this.state.individualCase_Original !== 'Open') {
                                aTag2.style.visibility = "hidden";
                            }
                            else {
                                if (aTag2.addEventListener) {
                                    aTag2.addEventListener("click", () => { this.deleteS3File(6) });
                                }
                                else if (aTag2.attachEvent) {
                                    aTag2.attachEvent("click", () => { this.deleteS3File(6) });
                                }
                            }
                        }
                    }
                    this.setState({ lease_agreement_uploaded: true });
                }
                catch (err) {
                    console.log("Error lease_agreement - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('lease_agreement_id').innerHTML = `<td>Lease Agreement<div id="lease_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="lease_agreement_upload_id" /></td>`;
                    return 6;
                }
            }
            else if (files[i].key.includes("utility_gas_bill/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ utility_gas_bill: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    if (this.state.mode && this.state.mode == "User") {
                        document.getElementById('utility_gas_bill_id').innerHTML = `<td>Utility Gas Bill<div id="utility_gas_bill_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.utility_gas_bill}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File7"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
                    }
                    else {
                        document.getElementById('utility_gas_bill_id').innerHTML = `<td>Utility Gas Bill<div id="utility_gas_bill_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.utility_gas_bill}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File7"><i class="fas fa-trash-alt red"></i></a></td>`;
                        var aTag2 = document.getElementById('deleteS3File7');
                        if (aTag2 !== null && aTag2 !== undefined) {
                            if (this.state.individualCase_Original !== 'Open') {
                                aTag2.style.visibility = "hidden";
                            }
                            else {
                                if (aTag2.addEventListener) {
                                    aTag2.addEventListener("click", () => { this.deleteS3File(7) });
                                }
                                else if (aTag2.attachEvent) {
                                    aTag2.attachEvent("click", () => { this.deleteS3File(7) });
                                }
                            }
                        }
                    }
                    this.setState({ utility_gas_bill_uploaded: true });
                }
                catch (err) {
                    console.log("Error utility_gas_bill - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('utility_gas_bill_id').innerHTML = `<td>Utility Gas Bill<div id="utility_gas_bill_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="utility_gas_bill_upload_id" /></td>`;
                    return 7;
                }
            }
            else if (files[i].key.includes("utility_electricity_bill/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ utility_electricity_bill: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    if (this.state.mode && this.state.mode == "User") {
                        document.getElementById('utility_electricity_bill_id').innerHTML = `<td>Utility Electricity Bill<div id="utility_electricity_bill_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.utility_electricity_bill}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File8"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
                    }
                    else {
                        document.getElementById('utility_electricity_bill_id').innerHTML = `<td>Utility Electricity Bill<div id="utility_electricity_bill_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.utility_electricity_bill}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File8"><i class="fas fa-trash-alt red"></i></a></td>`;
                        var aTag2 = document.getElementById('deleteS3File8');
                        if (aTag2 !== null && aTag2 !== undefined) {
                            if (this.state.individualCase_Original !== 'Open') {
                                aTag2.style.visibility = "hidden";
                            }
                            else {
                                if (aTag2.addEventListener) {
                                    aTag2.addEventListener("click", () => { this.deleteS3File(8) });
                                }
                                else if (aTag2.attachEvent) {
                                    aTag2.attachEvent("click", () => { this.deleteS3File(8) });
                                }
                            }
                        }
                    }
                    this.setState({ utility_electricity_bill_uploaded: true });
                }
                catch (err) {
                    console.log("Error utility_electricity_bill - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('utility_electricity_bill_id').innerHTML = `<td>Utility Electricity Bill<div id="utility_electricity_bill_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="utility_electricity_bill_upload_id" /></td>`;
                    return 8;
                }
            }
            else if (files[i].key.includes("landlord_w9/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ landlord_w9: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    if (this.state.mode && this.state.mode == "User") {
                        document.getElementById('landlord_w9_id').innerHTML = `<td>Landlord W9<div id="landlord_w9_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.landlord_w9}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File9"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
                    }
                    else {
                        document.getElementById('landlord_w9_id').innerHTML = `<td>Landlord W9<div id="landlord_w9_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.landlord_w9}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File9"><i class="fas fa-trash-alt red"></i></a></td>`;
                        var aTag2 = document.getElementById('deleteS3File9');
                        if (aTag2 !== null && aTag2 !== undefined) {
                            if (this.state.individualCase_Original !== 'Open') {
                                aTag2.style.visibility = "hidden";
                            }
                            else {
                                if (aTag2.addEventListener) {
                                    aTag2.addEventListener("click", () => { this.deleteS3File(9) });
                                }
                                else if (aTag2.attachEvent) {
                                    aTag2.attachEvent("click", () => { this.deleteS3File(9) });
                                }
                            }
                        }
                    }


                    this.setState({ landlord_w9_uploaded: true });
                }
                catch (err) {
                    console.log("Error landlord_w9 - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('landlord_w9_id').innerHTML = `<td>Landlord W9<div id="landlord_w9_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="landlord_w9_upload_id" /></td>`;
                    return 9;
                }
            }
            else if (files[i].key.includes("landlord_driver_license/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ landlord_driver_license: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    if (this.state.mode && this.state.mode == "User") {
                        document.getElementById('landlord_driver_license_id').innerHTML = `<td>Landlord Identification<div id="landlord_driver_license_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.landlord_driver_license}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File10"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
                    }
                    else {
                        document.getElementById('landlord_driver_license_id').innerHTML = `<td>Landlord Identification<div id="landlord_driver_license_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.landlord_driver_license}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File10"><i class="fas fa-trash-alt red"></i></a></td>`;
                        var aTag2 = document.getElementById('deleteS3File10');
                        if (aTag2 !== null && aTag2 !== undefined) {
                            if (this.state.individualCase_Original !== 'Open') {
                                aTag2.style.visibility = "hidden";
                            }
                            else {
                                if (aTag2.addEventListener) {
                                    aTag2.addEventListener("click", () => { this.deleteS3File(10) });
                                }
                                else if (aTag2.attachEvent) {
                                    aTag2.attachEvent("click", () => { this.deleteS3File(10) });
                                }
                            }
                        }
                    }


                    this.setState({ landlord_driver_license_uploaded: true });
                }
                catch (err) {
                    console.log("Error landlord_driver_license - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('landlord_driver_license_id').innerHTML = `<td>Landlord Identification<div id="landlord_driver_license_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="landlord_driver_license_upload_id" /></td>`;
                    return 10;
                }
            }
            else if (files[i].key.includes("verified_checkpoint/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ verified_checkpoint: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    if (this.state.mode && this.state.mode == "User") {
                        document.getElementById('verified_checkpoint_id').innerHTML = `<td>CheckPoint ID Verification Page<div id="verified_checkpoint_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.verified_checkpoint}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File11"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
                    }
                    else {
                        document.getElementById('verified_checkpoint_id').innerHTML = `<td>CheckPoint ID Verification Page<div id="verified_checkpoint_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.verified_checkpoint}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File11"><i class="fas fa-trash-alt red"></i></a></td>`;
                        var aTag2 = document.getElementById('deleteS3File11');
                        if (aTag2 !== null && aTag2 !== undefined) {
                            if (this.state.individualCase_Original !== 'Open') {
                                aTag2.style.visibility = "hidden";
                            }
                            else {
                                if (aTag2.addEventListener) {
                                    aTag2.addEventListener("click", () => { this.deleteS3File(11) });
                                }
                                else if (aTag2.attachEvent) {
                                    aTag2.attachEvent("click", () => { this.deleteS3File(11) });
                                }
                            }
                        }
                    }
                    this.setState({ verified_checkpoint_uploaded: true });
                }
                catch (err) {
                    console.log("Error verified_checkpoint - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('verified_checkpoint_id').innerHTML = `<td>CheckPoint ID Verification Page<div id="verified_checkpoint_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="verified_checkpoint_upload_id" /></td>`;
                    return 11;
                }
            }

            else if (files[i].key.includes("round2_landlord_updated_w9/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ round2_landlord_updated_w9: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    if (this.state.mode && this.state.mode == "User") {
                        document.getElementById('round2_landlord_updated_w9_id').innerHTML = `<td>Round 2 updated Landlord W9<div id="round2_landlord_updated_w9_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.round2_landlord_updated_w9}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File12"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
                    }
                    else {
                        document.getElementById('round2_landlord_updated_w9_id').innerHTML = `<td>Round 2 updated Landlord W9<div id="round2_landlord_updated_w9_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.round2_landlord_updated_w9}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File12"><i class="fas fa-trash-alt red"></i></a></td>`;
                        var aTag2 = document.getElementById('deleteS3File12');
                        if (aTag2 !== null && aTag2 !== undefined) {
                            if (this.state.individualCase_Original !== 'Open') {
                                aTag2.style.visibility = "hidden";
                            }
                            else {
                                if (aTag2.addEventListener) {
                                    aTag2.addEventListener("click", () => { this.deleteS3File(12) });
                                }
                                else if (aTag2.attachEvent) {
                                    aTag2.attachEvent("click", () => { this.deleteS3File(12) });
                                }
                            }
                        }
                    }
                    this.setState({ round2_landlord_updated_w9_uploaded: true });
                }
                catch (err) {
                    console.log("Error round2_landlord_updated_w9 - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('round2_landlord_updated_w9_id').innerHTML = `<td>Round 2 updated Landlord W9<div id="round2_landlord_updated_w9_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="round2_landlord_updated_w9_upload_id" /></td>`;
                    return 12;
                }
            }
            else if (files[i].key.includes("round2_updated_program_participation_Form/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ round2_updated_program_participation_Form: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    if (this.state.mode && this.state.mode == "User") {
                        document.getElementById('round2_updated_program_participation_Form_id').innerHTML = `<td>Round 2 updated Program Participation Form<div id="round2_updated_program_participation_Form_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.round2_updated_program_participation_Form}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File13"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
                    }
                    else {
                        document.getElementById('round2_updated_program_participation_Form_id').innerHTML = `<td>Round 2 updated Program Participation Form<div id="round2_updated_program_participation_Form_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.round2_updated_program_participation_Form}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File13"><i class="fas fa-trash-alt red"></i></a></td>`;
                        var aTag2 = document.getElementById('deleteS3File13');
                        if (aTag2 !== null && aTag2 !== undefined) {
                            if (this.state.individualCase_Original !== 'Open') {
                                aTag2.style.visibility = "hidden";
                            }
                            else {
                                if (aTag2.addEventListener) {
                                    aTag2.addEventListener("click", () => { this.deleteS3File(13) });
                                }
                                else if (aTag2.attachEvent) {
                                    aTag2.attachEvent("click", () => { this.deleteS3File(13) });
                                }
                            }
                        }
                    }
                    this.setState({ round2_updated_program_participation_Form_uploaded: true });
                }
                catch (err) {
                    console.log("Error round2_updated_program_participation_Form - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('round2_updated_program_participation_Form_id').innerHTML = `<td>Round 2 updated Program Participation Form<div id="round2_updated_program_participation_Form_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="round2_updated_program_participation_Form_upload_id" /></td>`;
                    return 13;
                }
            }
            else if (files[i].key.includes("round2_landlord_identity/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ round2_landlord_identity: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    if (this.state.mode && this.state.mode == "User") {
                        document.getElementById('round2_landlord_identity_id').innerHTML = `<td>Round 2 Landlord Identity<div id="round2_landlord_identity_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.round2_landlord_identity}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File14"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
                    }
                    else {
                        document.getElementById('round2_landlord_identity_id').innerHTML = `<td>Round 2 Landlord Identity<div id="round2_landlord_identity_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.round2_landlord_identity}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File14"><i class="fas fa-trash-alt red"></i></a></td>`;
                        var aTag2 = document.getElementById('deleteS3File14');
                        if (aTag2 !== null && aTag2 !== undefined) {
                            if (this.state.individualCase_Original !== 'Open') {
                                aTag2.style.visibility = "hidden";
                            }
                            else {
                                if (aTag2.addEventListener) {
                                    aTag2.addEventListener("click", () => { this.deleteS3File(14) });
                                }
                                else if (aTag2.attachEvent) {
                                    aTag2.attachEvent("click", () => { this.deleteS3File(14) });
                                }
                            }
                        }
                    }
                    this.setState({ round2_landlord_identity_uploaded: true });
                }
                catch (err) {
                    console.log("Error round2_landlord_identity - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('round2_landlord_identity_id').innerHTML = `<td>Round 2 Landlord Identity<div id="round2_landlord_identity_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="round2_landlord_identity_upload_id" /></td>`;
                    return 14;
                }
            }
            else if (files[i].key.includes("round2_landlord_checkpoint/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ round2_landlord_checkpoint: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    if (this.state.mode && this.state.mode == "User") {
                        document.getElementById('round2_landlord_checkpoint_id').innerHTML = `<td>Round 2 Landlord checkpoint ID Verification<div id="round2_landlord_checkpoint_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.round2_landlord_checkpoint}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File15"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
                    }
                    else {
                        document.getElementById('round2_landlord_checkpoint_id').innerHTML = `<td>Round 2 Landlord checkpoint ID Verification<div id="round2_landlord_checkpoint_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.round2_landlord_checkpoint}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File15"><i class="fas fa-trash-alt red"></i></a></td>`;
                        var aTag2 = document.getElementById('deleteS3File15');
                        if (aTag2 !== null && aTag2 !== undefined) {
                            if (this.state.individualCase_Original !== 'Open') {
                                aTag2.style.visibility = "hidden";
                            }
                            else {
                                if (aTag2.addEventListener) {
                                    aTag2.addEventListener("click", () => { this.deleteS3File(15) });
                                }
                                else if (aTag2.attachEvent) {
                                    aTag2.attachEvent("click", () => { this.deleteS3File(15) });
                                }
                            }
                        }
                    }
                    this.setState({ round2_landlord_checkpoint_uploaded: true });
                }
                catch (err) {
                    console.log("Error round2_landlord_checkpoint - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('round2_landlord_checkpoint_id').innerHTML = `<td>Round 2 Landlord checkpoint ID Verification<div id="round2_landlord_checkpoint_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="round2_landlord_checkpoint_upload_id" /></td>`;
                    return 15;
                }
            }
            else if (files[i].key.includes("tenant_payment_agreement/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ tenant_payment_agreement: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    if (this.state.mode && this.state.mode == "User") {
                        document.getElementById('tenant_payment_agreement_id').innerHTML = `<td>Tenant Payment Agreement<div id="tenant_payment_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.tenant_payment_agreement}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File16"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
                    }
                    else {
                        document.getElementById('tenant_payment_agreement_id').innerHTML = `<td>Tenant Payment Agreement<div id="tenant_payment_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.tenant_payment_agreement}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File16"><i class="fas fa-trash-alt red"></i></a></td>`;
                        var aTag2 = document.getElementById('deleteS3File16');
                        if (aTag2 !== null && aTag2 !== undefined) {
                            if (this.state.individualCase_Original !== 'Open') {
                                aTag2.style.visibility = "hidden";
                            }
                            else {
                                if (aTag2.addEventListener) {
                                    aTag2.addEventListener("click", () => { this.deleteS3File(16) });
                                }
                                else if (aTag2.attachEvent) {
                                    aTag2.attachEvent("click", () => { this.deleteS3File(16) });
                                }
                            }
                        }
                    }
                    this.setState({ tenant_payment_agreement_uploaded: true });
                }
                catch (err) {
                    console.log("Error tenant_payment_agreement - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('tenant_payment_agreement_id').innerHTML = `<td>Tenant Payment Agreement<div id="tenant_payment_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="tenant_payment_agreement_upload_id" /></td>`;
                    return 16;
                }
            }
            else if (files[i].key.includes("landlord_payment_agreement/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ landlord_payment_agreement: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    if (this.state.mode && this.state.mode == "User") {
                        document.getElementById('landlord_payment_agreement_id').innerHTML = `<td>Landlord Payment Agreement<div id="landlord_payment_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.landlord_payment_agreement}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File17"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
                    }
                    else {
                        document.getElementById('landlord_payment_agreement_id').innerHTML = `<td>Landlord Payment Agreement<div id="landlord_payment_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.landlord_payment_agreement}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File17"><i class="fas fa-trash-alt red"></i></a></td>`;
                        var aTag2 = document.getElementById('deleteS3File17');
                        if (aTag2 !== null && aTag2 !== undefined) {
                            if (this.state.individualCase_Original !== 'Open') {
                                aTag2.style.visibility = "hidden";
                            }
                            else {
                                if (aTag2.addEventListener) {
                                    aTag2.addEventListener("click", () => { this.deleteS3File(17) });
                                }
                                else if (aTag2.attachEvent) {
                                    aTag2.attachEvent("click", () => { this.deleteS3File(17) });
                                }
                            }
                        }
                    }
                    this.setState({ landlord_payment_agreement_uploaded: true });
                }
                catch (err) {
                    console.log("Error landlord_payment_agreement - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('landlord_payment_agreement_id').innerHTML = `<td>Landlord Payment Agreement<div id="landlord_payment_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="landlord_payment_agreement_upload_id" /></td>`;
                    return 17;
                }
            }

        }

        this.setState({ isLoading: false });

        return 0;
    }

    deleteS3File = async (fileToDelete) => {
        var result = window.confirm("Are you sure you want to delete selected document?");
        if (result === false) {
            return;
        }

        Storage.configure({
            bucket: process.env.REACT_APP_S3FrontendBucket,
            region: process.env.REACT_APP_Region,
            level: 'public',
        });

        this.setState({ isLoading: true });
        try {

            var key = `Individuals/Individual_${this.state.individualID}/`;
            var files = await Storage.list(key);
            //console.log(files);

            // Remove the earlier files for the section for which the new files to be uploaded

            switch (fileToDelete) {
                case 1:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes("photo_identification/")) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                //console.log(res);
                            }
                        }
                        document.getElementById('photo_identification_id').innerHTML = `<td>Photo Identification<div id="photo_identification_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="photo_identification_upload_id" /></td>`;
                        this.setState({ photo_identification: "" });
                    }
                    break;
                case 2:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes("proof_of_address/")) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                //console.log(res);
                            }
                        }
                        document.getElementById('proof_of_address_id').innerHTML = `<td>Proof of Address<div id="proof_of_address_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="proof_of_address_upload_id" /></td>`;
                        this.setState({ proof_of_address: "" });
                    }
                    break;
                case 3:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes("proof_of_income/")) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                //console.log(res);
                            }
                        }
                        document.getElementById('proof_of_income_id').innerHTML = `<td>Proof of Income<div id="proof_of_income_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="proof_of_income_upload_id" /></td>`;
                        this.setState({ proof_of_income: "" });
                    }
                    break;
                case 4:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes("proof_of_income_loss/")) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                //console.log(res);
                            }
                        }
                        document.getElementById('proof_of_income_loss_id').innerHTML = `<td>Proof of Income loss<div id="proof_of_income_loss_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="proof_of_income_loss_upload_id" /></td>`;
                        this.setState({ proof_of_income_loss: "" });
                    }
                    break;
                case 5:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes("ach_authorization_form/")) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                //console.log(res);
                            }
                        }
                        document.getElementById('ach_authorization_form_id').innerHTML = `<td>Program Participation Form<div id="ach_authorization_form_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="ach_authorization_form_upload_id" /></td>`;
                        this.setState({ ach_authorization_form: "" });
                    }
                    break;
                case 6:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes("lease_agreement/")) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                //console.log(res);
                            }
                        }
                        document.getElementById('lease_agreement_id').innerHTML = `<td>Lease Agreement<div id="lease_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="lease_agreement_upload_id" /></td>`;
                        this.setState({ lease_agreement: "" });
                    }
                    break;
                case 7:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes("utility_gas_bill/")) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                //console.log(res);
                            }
                        }
                        document.getElementById('utility_gas_bill_id').innerHTML = `<td>Utility Gas Bill<div id="utility_gas_bill_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="utility_gas_bill_upload_id" /></td>`;
                        this.setState({ utility_gas_bill: "" });
                    }
                    break;
                case 8:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes("utility_electricity_bill/")) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                //console.log(res);
                            }
                        }
                        document.getElementById('utility_electricity_bill_id').innerHTML = `<td>Utility Electricity Bill<div id="utility_electricity_bill_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="utility_electricity_bill_upload_id" /></td>`;
                        this.setState({ utility_electricity_bill: "" });
                    }
                    break;
                case 9:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes("landlord_w9/")) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                //console.log(res);
                                await this.textExtractW9Delete(files[i].key, '1');
                            }
                        }
                        document.getElementById('landlord_w9_id').innerHTML = `<td>Landlord W9<div id="landlord_w9_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="landlord_w9_upload_id" /></td>`;
                        this.setState({ landlord_w9: "" });
                    }
                    break;
                case 10:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes("landlord_driver_license/")) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                //console.log(res);
                            }
                        }
                        document.getElementById('landlord_driver_license_id').innerHTML = `<td>Landlord Identification<div id="landlord_driver_license_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="landlord_driver_license_upload_id" /></td>`;
                        this.setState({ landlord_driver_license: "" });
                    }
                    break;
                case 11:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes("verified_checkpoint/")) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                //console.log(res);
                            }
                        }
                        document.getElementById('verified_checkpoint_id').innerHTML = `<td>CheckPoint ID Verification Page<div id="verified_checkpoint_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="verified_checkpoint_upload_id" /></td>`;
                        this.setState({ verified_checkpoint: "" });
                    }
                    break;
                case 12:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes("round2_landlord_updated_w9/")) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                //console.log(res);
                                await this.textExtractW9Delete(files[i].key, '2');
                            }
                        }
                        document.getElementById('round2_landlord_updated_w9_id').innerHTML = `<td>Round 2 updated Landlord W9<div id="round2_landlord_updated_w9_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="round2_landlord_updated_w9_upload_id" /></td>`;
                        this.setState({ round2_landlord_updated_w9: "" });
                    }
                    break;
                case 13:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes("round2_updated_program_participation_Form/")) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                //console.log(res);
                            }
                        }
                        document.getElementById('round2_updated_program_participation_Form_id').innerHTML = `<td>Round 2 updated Program Participation Form<div id="round2_updated_program_participation_Form_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="round2_updated_program_participation_Form_upload_id" /></td>`;
                        this.setState({ round2_updated_program_participation_Form: "" });
                    }
                    break;
                case 14:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes("round2_landlord_identity/")) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                //console.log(res);
                            }
                        }
                        document.getElementById('round2_landlord_identity_id').innerHTML = `<td>Round 2 Landlord Identity<div id="round2_landlord_identity_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="round2_landlord_identity_upload_id" /></td>`;
                        this.setState({ round2_landlord_identity: "" });
                    }
                    break;
                case 15:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes("round2_landlord_checkpoint/")) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                //console.log(res);
                            }
                        }
                        document.getElementById('round2_landlord_checkpoint_id').innerHTML = `<td>Round 2 Landlord checkpoint ID Verification<div id="round2_landlord_checkpoint_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="round2_landlord_checkpoint_upload_id" /></td>`;
                        this.setState({ round2_landlord_checkpoint: "" });
                    }
                    break;
                case 16:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes("tenant_payment_agreement/")) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                //console.log(res);
                            }
                        }
                        document.getElementById('tenant_payment_agreement_id').innerHTML = `<td>Tenant Payment Agreement<div id="tenant_payment_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="tenant_payment_agreement_upload_id" /></td>`;
                        this.setState({ tenant_payment_agreement: "" });
                    }
                    break;
                case 17:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes("landlord_payment_agreement/")) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                //console.log(res);
                            }
                        }
                        document.getElementById('landlord_payment_agreement_id').innerHTML = `<td>Landlord Payment Agreement<div id="landlord_payment_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="landlord_payment_agreement_upload_id" /></td>`;
                        this.setState({ landlord_payment_agreement: "" });
                    }
                    break;

                default:
                    break;
            }
        }
        catch (err) {
            console.log(err);
            this.setState({ msg: "Failed to delete file" });
            this.setState({ isLoading: false });
            return;
        }

        this.setState({ isLoading: false });

        var safeload = await this.loadUploadedDocuments();
    }

    getFileIconType(filename) {
        let extension = /(?:\.([^.]+))?$/.exec(filename)[1];

        switch (extension) {
            case 'pdf':
                return 'fas fa-file-pdf';
            case 'jpeg':
            case 'jpg':
            case 'png':
            case 'gif':
            case 'bmp':
                return 'fas fa-file-image';
            case 'txt':
                return 'fas fa-file-alt';
            case 'doc':
            case 'docx':
                return 'fas fa-file-word';
            case 'xls':
            case 'xlsx':
                return 'fas fa-file-excel';
            case 'ppt':
            case 'pptx':
                return 'fas fa-file-powerpoint';
            default: // any other file
                return 'fas fa-file';
        }
    }

    handleSpeedDialClick = (action) => {
        switch (action) {
            case 'save':
                this.handleUpdate();
                break;
            case 'cancel':
                this.handleBack();
                break;
            default:
                break;
        }
    }

    handleSpeedDialClose = () => {
        this.setState({ speedDialOpen: false });
    }

    handleSpeedDialOpen = () => {
        this.setState({ speedDialOpen: true });
    }

    handleUpdate = async () => {
        this.setState({ isLoading: true });

        // Check input field validations if any
        if (this.validateControls() === false) {
            // Validation Failed - early return.
            this.setState({ isLoading: false });
            return;
        }

        try {
            var user = await Auth.currentAuthenticatedUser();
           // return user;
          }
          catch(e){
            this.setState({ isLoading: false });
            if(e === "not authenticated") {
              await this.signOut();
              return;
            }
        }
        
        var d = new Date(new Date().toLocaleString('en-US', { timeZone: 'US/Pacific' }));
        var todaysDate = [
            d.getFullYear(),
            ('0' + (d.getMonth() + 1)).slice(-2),
            ('0' + d.getDate()).slice(-2)
        ].join('-');

        var todaysTime = [
            ('0' + (d.getHours())).slice(-2),
            ('0' + d.getMinutes()).slice(-2),
            ('0' + d.getSeconds()).slice(-2),
        ].join(':');

        this.setState({
            dateClaimModified: todaysDate,
            timeClaimModified: todaysTime
        });

        if (this.state.rentPaymentStatus_Original !== this.state.rentPaymentStatus) {
            if (this.state.rentPaymentStatus === 'Issued' &&
                this.state.spaServiceProviderEmail !== null && this.state.spaServiceProviderEmail !== "") {

                let tempEmail = this.state.landlordEmail;
                if (this.state.landlordEmail === null || this.state.landlordEmail === "" || this.state.landlordEmail === undefined) {
                    tempEmail = 'JennO@UnitedWayOC.org';
                }

                var result = window.confirm(`You have updated rent status to Issued. This will trigger an email to landlord on ${tempEmail} and service provider on ${this.state.spaServiceProviderEmail}. Please click Ok to confirm.`);
                if (result !== false) {

                    this.setState({
                        triggerEmail: "1",
                        dateEmailTriggered: todaysDate,
                        timeEmailTriggered: todaysTime,
                        dateRentPaymentStatusUpdated: todaysDate,
                        timeRentPaymentStatusUpdated: todaysTime
                    });
                }
                else {
                    this.rentPaymentStatusRef.current.focus();

                    this.setState({ isLoading: false, triggerEmail: "0" });
                    return;
                }
            }
            else {
                this.setState({
                    triggerEmail: "0",
                    dateRentPaymentStatusUpdated: todaysDate,
                    timeRentPaymentStatusUpdated: todaysTime
                });
            }
        }

        if (this.state.electricPaymentStatus_Original !== this.state.electricPaymentStatus) {
            this.setState({
                dateElectricPaymentStatusUpdated: todaysDate,
                timeElectricPaymentStatusUpdated: todaysTime
            });
        }
        if (this.state.gasPaymentStatus_Original !== this.state.gasPaymentStatus) {
            this.setState({
                dateGasPaymentStatusUpdated: todaysDate,
                timeGasPaymentStatusUpdated: todaysTime
            });
        }
        if (this.state.otherPaymentStatus_Original !== this.state.otherPaymentStatus) {
            this.setState({
                dateOtherPaymentStatusUpdated: todaysDate,
                timeOtherPaymentStatusUpdated: todaysTime
            });
        }
        if (this.state.individualPaymentStatus_Original !== this.state.individualPaymentStatus) {
            this.setState({
                dateIndividualPaymentStatusUpdated: todaysDate,
                timeIndividualPaymentStatusUpdated: todaysTime
            });
        }

        if (this.state.waterPaymentStatus_Original !== this.state.waterPaymentStatus) {
            this.setState({
                dateWaterPaymentStatusUpdated: todaysDate,
                timeWaterPaymentStatusUpdated: todaysTime
            });
        }
        if (this.state.trashPaymentStatus_Original !== this.state.trashPaymentStatus) {
            this.setState({
                dateTrashPaymentStatusUpdated: todaysDate,
                timeTrashPaymentStatusUpdated: todaysTime
            });
        }
        if (this.state.energyPaymentStatus_Original !== this.state.energyPaymentStatus) {
            this.setState({
                dateEnergyPaymentStatusUpdated: todaysDate,
                timeEnergyPaymentStatusUpdated: todaysTime
            });
        }

        if (this.state.landlordEmail_Original !== this.state.landlordEmail) {
            this.setState({
                requestedLandlordInfo: false,
                countLandlordInfoRequested: 0,
                dateLandlordInfoRequested: '0000-00-00',
                timeLandlordInfoRequested: '00-00-00',
                dateLandlordInfoReminded: '0000-00-00',
                timeLandlordInfoReminded: '00-00-00',
            });
        }

        this.setState({ isLoading: true });
        try {
            var res = await this.updateIndividualDetails();
            //console.log(res);
            //console.log("Individual Updated Successful.");

            if (this.state.triggerEmail === "1") {
                // Trigger rent payment issued email
                await this.triggerRentPaymentIssuedEmail();
            }

            res = await this.UploadIndividualDocuments();
            //console.log(res);

            alert('The changes have been saved successfully.');

            //await this.loadData(); // load the updated data again.
            await this.handleBack();
        }
        catch (err) {
            console.log("ERROR: Failed to Update the individual.");
            console.log(err);
            alert('ERROR: Failed to Update the individual.');
        }

        this.setState({ isLoading: false });
    }

    triggerRentPaymentIssuedEmail = async () => {
        this.setState({ isLoading: true });

        try {
            var user = this.getCurrentUser();
            let apiName = process.env.REACT_APP_APIGatewayName;
            let path = '/eraocsendrentpaymentemail';
            let myInit = { // OPTIONAL
                body: {
                    "info": {
                        "firstName": this.state.firstName,
                        "lastName": this.state.lastName,
                        "tenantEmail": this.state.tenantEmail,
                        "spaServiceProviderEmail": this.state.spaServiceProviderEmail,
                        "landlordEmail": this.state.landlordEmail,
                        "payee": this.state.payee,
                        "rentAmount": this.state.rentAmount,
                        "addressLine1": this.state.addressLine1,
                        "addressLine2": this.state.addressLine2,
                        "city": this.state.city,
                        "state": this.state.state,
                        "zipcode": this.state.zipcode,
                        "uniqueID": this.state.uniqueID,
                    }
                }, // replace this with attributes you need
                headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
            }

            await API.post(apiName, path, myInit);
            // if (res.message === 0)
        }
        catch (err) {
            console.log(err)
        }

        this.setState({ isLoading: false });
    }

    requestLandlordInfo = async () => {
        await this.sendEmailToLandlord(0);
    }

    sendReminderToLandlord = async () => {
        await this.sendEmailToLandlord(1);
    }

    sendEmailToLandlord = async (remind) => {
        this.setState({ isLoading: true });

        try {
            var user = await this.getCurrentUser();
            let apiName = process.env.REACT_APP_APIGatewayName;
            let path = '/octriggerdocumentrequestemailtolandlordmanually';
            let myInit = { // OPTIONAL
                body: {
                    "info": {
                        "individualID": this.state.individualID,
                        "uniqueID": this.state.uniqueID,
                        "payee": this.state.payee,
                        "firstName": this.state.firstName,
                        "lastName": this.state.lastName,
                        "addressLine1": this.state.addressLine1,
                        "addressLine2": this.state.addressLine2,
                        "landlordEmail": this.state.landlordEmail,
                        "rentAmount": this.state.rentAmount,
                        "roundNumber": this.state.roundNumber,
                        "countLandlordInfoRequested": this.state.countLandlordInfoRequested,
                        "reminder": remind,
                        "totalRecords": this.state.totalRecords,
                    }
                }
                , // replace this with attributes you need
                headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
            }

            var res = await API.post(apiName, path, myInit);

            // reload the individual data
            await this.loadData();
        }
        catch (err) {
            console.log(err)
        }

        this.setState({ isLoading: false });
    }

    handleBack = async () => {
        // // check the current user when the App component is loaded
        // Auth.currentAuthenticatedUser().then(user => {
        //     this.setState({ authState: 'signedIn' });
        // }).catch(e => {
        //     console.log(e);
        //     this.setState({ authState: 'signIn' });
        // });

        // ReactDOM.render(<App showCeppClaims={'block'} authState={this.state.authState} />, document.getElementById("root"));

        // reset the state
        this.resetValues();
        this.setState(
            {
                individualID: "0",

                firstName: "",
                lastName: "",
                partnerFirstName: "",
                partnerLastName: "",
                addressLine1: "",
                addressLine2: "",
                city: "",
                state: "",
                country: "",
                zipcode: "",
                clientDOB: "",
                phone: "",
                legallyAbleToWork: "",
                eligibleForStimulusFunds: "",
                veteran: "",
                monthlyIncomeBeforeCovid19: "",
                workIndustry: "",
                employmentStatus: "",
                effectOfCovid19Financially: "",
                householdNumber: "0",
                age0to5: "0",
                age6to17: "0",
                age18to59: "0",
                age60andUp: "0",
                cardValue: "",
                participateInSurvey: "",
                claimStatus: "",
                dateImported: "",
                timeImported: "",
                dateFromHIMS: "",
                timeFromHIMS: "",
                dateClaimModified: "",
                timeClaimModified: "",
                notes: "",
                adminUsername: "",
                originator: "",
                programTitle: "",
                spaAssignmentNo: "",
                hmisApproval: "",
                householdID: "0",
                transactionID: "0",
                landlordPhoneNumber: "",
                landlordEmail: "",
                amountDueforRent: "",
                payee: "",
                rentAmount: "",
                landlordBankName: "",
                landlordAccountNumber: "",
                landlordRoutingNumber: "",
                memoPropertyAddress: "",
                rentPaymentStatus: "",
                rentPaymentStatus_Original: "",
                electricProvider: "",
                electricUtilityAccountNo: "",
                amountDueforElectricity: "",
                custElectricBillingAccountNo: "",
                electricAmount: "",
                electricBankName: "",
                electricUtilityProviderAccountNo: "",
                electricRoutingNumber: "",
                electricMemoAccountNo: "",
                electricPaymentStatus: "",
                electricPaymentStatus_Original: "",
                gasProvider: "",
                gasUtilityAccountNo: "",
                amountDueforGas: "",
                custGasBillingAccountNo: "",
                gasBankName: "",
                gasAmount: "",
                gasUtilityProviderAccountNo: "",
                gasRoutingNumber: "",
                gasMemoAccountNo: "",
                gasPaymentStatus: "",
                gasPaymentStatus_Original: "",
                otherPaymentAmount: "",
                otherPaymentStatus: "",
                otherPaymentStatus_Original: "",

                dateEmailTriggered: "",
                timeEmailTriggered: "",
                dateRentPaymentStatusUpdated: "",
                timeRentPaymentStatusUpdated: "",
                dateElectricPaymentStatusUpdated: "",
                timeElectricPaymentStatusUpdated: "",
                dateGasPaymentStatusUpdated: "",
                timeGasPaymentStatusUpdated: "",
                dateOtherPaymentStatusUpdated: "",
                timeOtherPaymentStatusUpdated: "",

                currentTotalHouseholdIncome: "",
                openToFollowup: "",
                gender: "",
                race: "",
                ethnicity: "",
                spaServiceProviderEmail: "",
                noOfTimesParticipated: "",

                landlordSSN: "",
                landlordTaxID: "",
                landlordDUNSNumber: "",
                rentTypeOfAssistance: "",
                monthlyRent: "",
                percentageOfMonthlyRent: "",
                outstandingRentalArrearsAmount: "",
                noOfMonthsRentalPayments: "",

                electricUtilityTaxID: "",
                electricUtilityDUNSNumber: "",
                monthlyElectricBillAmount: "",
                percentageOfElectricBill: "",
                noOfMonthsElectricUtilityPayments: "",
                electricTypeOfAssistance: "",

                gasUtilityTaxID: "",
                gasUtilityDUNSNumber: "",
                monthlyGasBillAmount: "",
                percentageOfGasBill: "",
                noOfMonthsGasUtilityPayments: "",
                gasTypeOfAssistance: "",

                covid_other: "",
                industry_other: "",

                tenantEmail: "",
                dateApplied: "",
                individualBankName: "",
                individualAccountNumber: "",
                individualRoutingNumber: "",
                individualMemoPropertyAddress: "",
                individualPaymentReason: "",
                individualPaymentStatus: "",
                individualPaymentStatus_Original: "",
                dateIndividualPaymentStatusUpdated: "",
                timeIndividualPaymentStatusUpdated: "",
                otherDesription: "",
                otherBankName: "",
                otherAccountNumber: "",
                otherRoutingNumber: "",
                otherMemoPropertyAddress: "",
                otherPayeeName: "",
                individualPaymentAmount: "",

                waterProvider: "",
                waterUtilityAccountNo: "",
                custWaterBillingAccountNo: "",
                monthlyWaterBillAmount: "",
                noOfMonthsWaterUtilityPayments: "",
                waterTypeOfAssistance: "",
                amountDueforWater: "",
                waterAmount: "",
                percentageOfWaterBill: "",
                waterBankName: "",
                waterUtilityProviderAccountNo: "",
                waterRoutingNumber: "",
                waterMemoAccountNo: "",
                waterUtilityTaxID: "",
                waterUtilityDUNSNumber: "",
                waterPaymentStatus: "",
                waterPaymentStatus_Original: "",
                dateWaterPaymentStatusUpdated: "",
                timeWaterPaymentStatusUpdated: "",

                trashProvider: "",
                trashUtilityAccountNo: "",
                custTrashBillingAccountNo: "",
                monthlyTrashBillAmount: "",
                noOfMonthsTrashUtilityPayments: "",
                trashTypeOfAssistance: "",
                amountDueforTrash: "",
                trashAmount: "",
                percentageOfTrashBill: "",
                trashBankName: "",
                trashUtilityProviderAccountNo: "",
                trashRoutingNumber: "",
                trashMemoAccountNo: "",
                trashUtilityTaxID: "",
                trashUtilityDUNSNumber: "",
                trashPaymentStatus: "",
                trashPaymentStatus_Original: "",
                dateTrashPaymentStatusUpdated: "",
                timeTrashPaymentStatusUpdated: "",

                energyProvider: "",
                energyUtilityAccountNo: "",
                custEnergyBillingAccountNo: "",
                monthlyEnergyBillAmount: "",
                noOfMonthsEnergyUtilityPayments: "",
                energyTypeOfAssistance: "",
                amountDueforEnergy: "",
                energyAmount: "",
                percentageOfEnergyBill: "",
                energyBankName: "",
                energyUtilityProviderAccountNo: "",
                energyRoutingNumber: "",
                energyMemoAccountNo: "",
                energyUtilityTaxID: "",
                energyUtilityDUNSNumber: "",
                energyPaymentStatus: "",
                energyPaymentStatus_Original: "",
                dateEnergyPaymentStatusUpdated: "",
                timeEnergyPaymentStatusUpdated: "",

                suffix: "",
                middleName: "",
                agent: "",
                doYouRent: "",
                receiveAnyHousingSubsidy: "",
                isAnnualIncomeLess: "",
                isSomeoneOnRentalOrLeaseAgreement: "",
                filedCovid19FinancialStressDeclaration: "",
                receivedEvictionNotice: "",
                dateEvictionNoticeReceived: "",
                SSN: "",
                socialSecurityFirstName: "",
                socialSecurityLastName: "",
                birthdayCode: "",
                age: "",
                otherPhone: "",
                secondaryFirstName: "",
                secondaryLastName: "",
                secondaryEmail: "",
                secondaryPhone: "",

                belowThirtyPercAMI: "",
                thirtyToFiftyPercAMI: "",
                fiftyToEightyPercAMI: "",
                boardDistrict: "",
                landlordFirstAttempt: "",
                landlordResponse: "",

                individualCase: "",
                individualCase_Original: "",
                reasonPaymentNotProcessedIn21Days: "",

                uniqueID: "",

                countAdult: "",
                countChildren: "",
                householdApprovedForUnitedWay: "",
                landlordACHReceived: "",
                individualACHReceived: "",

                roundNumber: 1,

                totalCovered: 0,
                totalRecords: 1,

                lengthOfTimeForAward: "",
                timestampOfRentIssued: "",

                triggerEmail: "0",
            }, () => {
                this.forceUpdate();
                this.showCeppGrid();
            });
    }

    showCeppGrid = async () => {
        window.location.assign("/assistoc/ceppclaim");
        //this.props.updateFunction(5);
        //await this.props.refreshCeppGrid();
    }

    signOut = async () => {
        try {
            await Auth.signOut()
            // signout success
            this.setState({ authState: 'signIn' });
            window.location.assign("/");
        }
        catch (e) {
            console.log(e)
        }
    }

    getCurrentUser = async () => {
        try {
          var user = await Auth.currentAuthenticatedUser();
          return user;
        }
        catch(e){
         // this.setState({ isLoading: false });
          if(e === "not authenticated") {
            await this.signOut();
          }
        }
    }

    renderPage = (page) => {
        // check the current user when the App component is loaded
        Auth.currentAuthenticatedUser().then(user => {
            this.setState({ authState: 'signedIn' });
        }).catch(e => {
            console.log(e);
            if(e === "not authenticated") {
                this.signOut();
            }
            this.setState({ authState: 'signIn' });
        });

        switch (page) {
            case 1:
                ReactDOM.render(<App showAddUser={'block'} authState={this.state.authState} />, document.getElementById("root"));
                break;
            case 3:
                ReactDOM.render(<App showSubmitClaim={'block'} authState={this.state.authState} />, document.getElementById("root"));
                break;
            case 5:
                ReactDOM.render(<App showCeppClaims={'block'} authState={this.state.authState} />, document.getElementById("root"));
                break;
            default:
                break;
        }
    }

    date_diff_in_days = (date1, date2) => {
        var dt1 = new Date(date1);
        var dt2 = new Date(date2);
        // Math.floor
        return Math.abs((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
    }

    onPaymentStatusChange = () => {
        if ((this.state.rentPaymentStatus === 'Approved for Payment' || this.state.rentPaymentStatus === 'N/A' || this.state.rentPaymentStatus === 'Issued') &&
            (this.state.electricPaymentStatus === 'Approved for Payment' || this.state.electricPaymentStatus === 'N/A' || this.state.electricPaymentStatus === 'Issued') &&
            (this.state.gasPaymentStatus === 'Approved for Payment' || this.state.gasPaymentStatus === 'N/A' || this.state.gasPaymentStatus === 'Issued') &&
            (this.state.waterPaymentStatus === 'Approved for Payment' || this.state.waterPaymentStatus === 'N/A' || this.state.waterPaymentStatus === 'Issued') &&
            (this.state.trashPaymentStatus === 'Approved for Payment' || this.state.trashPaymentStatus === 'N/A' || this.state.trashPaymentStatus === 'Issued') &&
            (this.state.energyPaymentStatus === 'Approved for Payment' || this.state.energyPaymentStatus === 'N/A' || this.state.energyPaymentStatus === 'Issued') &&
            (this.state.individualPaymentStatus === 'Approved for Payment' || this.state.individualPaymentStatus === 'N/A' || this.state.individualPaymentStatus === 'Issued') 
            // &&
            // (this.state.otherPaymentStatus === 'Approved for Payment' || this.state.otherPaymentStatus === 'N/A' || this.state.otherPaymentStatus === 'Issued')
            ) {
            document.getElementById("individualCaseId").disabled = false;
        }
        else {
            document.getElementById("individualCaseId").disabled = true;
        }
    }

    updateIndividualCase = (evt) => {
        var name = evt.target.value;
        this.setState({ individualCase: name });
    }

    updateHouseholdApprovedForUnitedWay = (evt) => {
        var name = evt.target.value;
        this.setState({ householdApprovedForUnitedWay: name });
    }

    updateFirstName = (evt) => {
        var name = evt.target.value;
        this.setState({ firstName: name, firstName_Error: "" });
    }
    updateLastName = (evt) => {
        var name = evt.target.value;
        this.setState({ lastName: name, lastName_Error: "" });
    }
    updateFirstNameSpouse = (evt) => {
        var name = evt.target.value;
        this.setState({ partnerFirstName: name, partnerFirstName_Error: "" });
    }
    updateLastNameSpouse = (evt) => {
        var name = evt.target.value;
        this.setState({ partnerLastName: name, partnerLastName_Error: "" });
    }

    updateAddress = (evt) => {
        var name = evt.target.value;
        this.setState({ addressLine1: name, addressLine1_Error: "" });
    }

    updateAddress2 = (evt) => {
        var name = evt.target.value;
        this.setState({ addressLine2: name, addressLine2_Error: "" });
    }
    updateZip = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ zipcode: name, zipcode_Error: "" });
    }
    updateCity = (evt) => {
        var name = evt.target.value;
        this.setState({ city: name, city_Error: "" });
    }
    updateState = (evt) => {
        var name = evt.target.value;
        this.setState({ state: name, state_Error: "" });
    }

    updatePhone = (evt) => {
        var name = evt.target.value;
        //name = name.replace(/\D/g, '');
        this.setState({ phone: name, phone_Error: "" });
    }

    updateHouseHold = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ householdNumber: name, householdNumber_Error: "" });
    }
    // updateHouseHold1 = (evt) => {
    //     var name = evt.target.value;
    //     name = name.replace(/\D/g, '');
    //     this.setState({ age0to5: name, age0to5_Error: "" });
    // }
    // updateHouseHold2 = (evt) => {
    //     var name = evt.target.value;
    //     name = name.replace(/\D/g, '');
    //     this.setState({ age6to17: name, age6to17_Error: "" });
    // }
    // updateHouseHold3 = (evt) => {
    //     var name = evt.target.value;
    //     name = name.replace(/\D/g, '');
    //     this.setState({ age18to59: name, age18to59_Error: "" });
    // }
    // updateHouseHold4 = (evt) => {
    //     var name = evt.target.value;
    //     name = name.replace(/\D/g, '');
    //     this.setState({ age60andUp: name, age60andUp_Error: "" });
    // }
    updateCountAdult = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ countAdult: name, countAdult_Error: "" });
    }
    updateCountChildren = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ countChildren: name, countChildren_Error: "" });
    }

    updateDOB = (evt) => {
        var name = evt.target.value;
        this.setState({ clientDOB: name });
    }

    updateCovidOther = (evt) => {
        var name = evt.target.value;
        this.setState({ covid_other: name, covid_other_Error: "" });
    }

    updateLegallyAbleToWork = (evt) => {
        var name = evt.target.value;
        this.setState({ legallyAbleToWork: name });
    }

    updateWorkIndustry = (evt) => {
        var name = evt.target.value;
        this.setState({ workIndustry: name });
    }

    updateIndustryOther = (evt) => {
        var name = evt.target.value;
        this.setState({ industry_other: name, industry_other_Error: "" });
    }

    updateVeteran = (evt) => {
        var name = evt.target.value;
        this.setState({ veteran: name });
    }

    updateStimulus = (evt) => {
        var name = evt.target.value;
        this.setState({ eligibleForStimulusFunds: name });
    }

    updateEmploymentStatus = (evt) => {
        var name = evt.target.value;
        this.setState({ employmentStatus: name });
    }

    updateIncome = (evt) => {
        var name = evt.target.value;
        this.setState({ monthlyIncomeBeforeCovid19: name, monthlyIncomeBeforeCovid19_Error: "" });
    }

    updateFinancialEffect = (evt) => {
        var name = evt.target.value;
        this.setState({ effectOfCovid19Financially: name });
    }


    updateProgram = (evt) => {
        var name = evt.target.value;
        this.setState({ programTitle: name });
    }
    updateSPAAssignmentNo = (evt) => {
        var name = evt.target.value;
        //name = name.replace(/\D/g, '');
        this.setState({ spaAssignmentNo: name, spaAssignmentNo_Error: "" });
    }
    updateHIMSAproval = (evt) => {
        var name = evt.target.value;
        this.setState({ hmisApproval: name });
    }
    updateHouseholdID = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ householdID: name, householdID_Error: "" });
    }
    updateTransactionID = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ transactionID: name, transactionID_Error: "" });
    }
    updateIndividualID = (evt) => {
        var name = evt.target.value;
        this.setState({ individualID: name });
    }
    updateUniqueID = (evt) => {
        var name = evt.target.value;
        this.setState({ uniqueID: name, uniqueID_Error: "" });
    }
    updateCurrentTotalHouseholdIncome = (evt) => {
        var name = evt.target.value;
        this.setState({ currentTotalHouseholdIncome: name, currentTotalHouseholdIncome_Error: "" });
    }
    updateOpenToFollowUp = (evt) => {
        var name = evt.target.value;
        this.setState({ openToFollowup: name });
    }
    updateGender = (evt) => {
        var name = evt.target.value;
        this.setState({ gender: name, gender_Error: "" });
    }
    updateRace = (evt) => {
        var name = evt.target.value;
        this.setState({ race: name, race_Error: "" });
    }
    updateEthnicity = (evt) => {
        var name = evt.target.value;
        this.setState({ ethnicity: name, ethnicity_Error: "" });
    }
    updateSPAServiceProviderEmail = (evt) => {
        var name = evt.target.value;
        this.setState({ spaServiceProviderEmail: name, spaServiceProviderEmail_Error: "" });
    }
    updateNoOfTimesParticipated = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ noOfTimesParticipated: name, noOfTimesParticipated_Error: "" });
    }



    updateSuffix = (evt) => {
        var name = evt.target.value;
        this.setState({ suffix: name, suffix_Error: "" });
    }
    updateMiddleName = (evt) => {
        var name = evt.target.value;
        this.setState({ middleName: name, middleName_Error: "" });
    }
    updateAgent = (evt) => {
        var name = evt.target.value;
        this.setState({ agent: name, agent_Error: "" });
    }
    updateDoYouRent = (evt) => {
        var name = evt.target.value;
        this.setState({ doYouRent: name, doYouRent_Error: "" });
    }
    updateReceiveAnyHousingSubsidy = (evt) => {
        var name = evt.target.value;
        this.setState({ receiveAnyHousingSubsidy: name, receiveAnyHousingSubsidy_Error: "" });
    }
    updateIsAnnualIncomeLess = (evt) => {
        var name = evt.target.value;
        this.setState({ isAnnualIncomeLess: name, isAnnualIncomeLess_Error: "" });
    }
    updateIsSomeoneOnRentalOrLeaseAgreement = (evt) => {
        var name = evt.target.value;
        this.setState({ isSomeoneOnRentalOrLeaseAgreement: name, isSomeoneOnRentalOrLeaseAgreement_Error: "" });
    }
    updateFiledCovid19FinancialStressDeclaration = (evt) => {
        var name = evt.target.value;
        this.setState({ filedCovid19FinancialStressDeclaration: name, filedCovid19FinancialStressDeclaration_Error: "" });
    }
    updateReceivedEvictionNotice = (evt) => {
        var name = evt.target.value;
        this.setState({ receivedEvictionNotice: name, receivedEvictionNotice_Error: "" });
    }
    updateDateEvictionNoticeReceived = (evt) => {
        var name = evt.target.value;
        this.setState({ dateEvictionNoticeReceived: name, dateEvictionNoticeReceived_Error: "" });
    }
    updateSSN = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ SSN: name, SSN_Error: "" });
    }
    updateSocialSecurityFirstName = (evt) => {
        var name = evt.target.value;
        this.setState({ socialSecurityFirstName: name, socialSecurityFirstName_Error: "" });
    }
    updateSocialSecurityLastName = (evt) => {
        var name = evt.target.value;
        this.setState({ socialSecurityLastName: name, socialSecurityLastName_Error: "" });
    }
    updateBirthdayCode = (evt) => {
        var name = evt.target.value;
        this.setState({ birthdayCode: name, birthdayCode_Error: "" });
    }
    updateAge = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ age: name, age_Error: "" });
    }
    updateOtherPhone = (evt) => {
        var name = evt.target.value;
        //name = name.replace(/\D/g, '');
        this.setState({ otherPhone: name, otherPhone_Error: "" });
    }
    updateSecondaryFirstName = (evt) => {
        var name = evt.target.value;
        this.setState({ secondaryFirstName: name, secondaryFirstName_Error: "" });
    }
    updateSecondaryLastName = (evt) => {
        var name = evt.target.value;
        this.setState({ secondaryLastName: name, secondaryLastName_Error: "" });
    }
    updateSecondaryEmail = (evt) => {
        var name = evt.target.value;
        this.setState({ secondaryEmail: name, secondaryEmail_Error: "" });
    }
    updateSecondaryPhone = (evt) => {
        var name = evt.target.value;
        //name = name.replace(/\D/g, '');
        this.setState({ secondaryPhone: name, secondaryPhone_Error: "" });
    }

    updateBelowThirtyPercAMI = (evt) => {
        var name = evt.target.value;
        this.setState({ belowThirtyPercAMI: name });
    }
    updateThirtyToFiftyPercAMI = (evt) => {
        var name = evt.target.value;
        this.setState({ thirtyToFiftyPercAMI: name });
    }
    updateFiftyToEightyPercAMI = (evt) => {
        var name = evt.target.value;
        this.setState({ fiftyToEightyPercAMI: name });
    }
    updateBoardDistrict = (evt) => {
        var name = evt.target.value;
        this.setState({ boardDistrict: name });
    }
    updateLengthOfTimeForAward = (evt) => {
        var name = evt.target.value;
        this.setState({ lengthOfTimeForAward: name });
    }


    updateLandlordPhone = (evt) => {
        var name = evt.target.value;
        //name = name.replace(/\D/g, '');
        this.setState({ landlordPhoneNumber: name, landlordPhoneNumber_Error: "" });
    }
    updateLandlordEmail = (evt) => {
        var name = evt.target.value;
        this.setState({ landlordEmail: name, landlordEmail_Error: "" });
    }
    updateDollarAmountDueForRent = (evt) => {
        var name = evt.target.value;
        this.setState({ amountDueforRent: name, amountDueforRent_Error: "" });

        if (name !== 0 && name !== "0.00" &&
            name !== '' && name !== null && name !== undefined &&
            this.state.rentAmount !== 0 && this.state.rentAmount !== "0.00" &&
            this.state.rentAmount !== '' && this.state.rentAmount !== null && this.state.rentAmount !== undefined) {
            this.setState({ percentageOfMonthlyRent: ((this.state.rentAmount * 100) / name).toFixed(2) });
        }
        else {
            this.setState({ percentageOfMonthlyRent: '' });
        }
    }
    onLandlordACHReceivedChange = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ landlordACHReceived: 1 });
        }
        else {
            this.setState({ landlordACHReceived: 0 });
        }
    }

    updatePayee = (evt) => {
        var name = evt.target.value;
        this.setState({ payee: name });
    }
    updateAmount = (evt) => {
        var name = evt.target.value;
        // this.setState({ rentAmount: name, total1099Amount: +this.state.otherPaymentAmount + +name, rentAmount_Error: "" });
        this.setState({ rentAmount: name, rentAmount_Error: "" }, function () {
            this.calculateTotal1099();
        });

        if (this.state.amountDueforRent !== 0 && this.state.amountDueforRent !== "0.00" &&
            this.state.amountDueforRent !== '' && this.state.amountDueforRent !== null && this.state.amountDueforRent !== undefined &&
            name !== 0 && name !== "0.00" &&
            name !== '' && name !== null && name !== undefined) {
            this.setState({ percentageOfMonthlyRent: ((name * 100) / this.state.amountDueforRent).toFixed(2) });
        }
        else {
            this.setState({ percentageOfMonthlyRent: '' });
        }
    }
    updateLandlordBankName = (evt) => {
        var name = evt.target.value;
        this.setState({ landlordBankName: name });
    }
    updateLandlordAccountNumber = (evt) => {
        var name = evt.target.value;
        //name = name.replace(/\D/g, '');
        this.setState({ landlordAccountNumber: name, landlordAccountNumber_Error: "" });
    }
    updateLandlordRoutingNumber = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ landlordRoutingNumber: name, landlordRoutingNumber_Error: "" });
    }
    updatePropertyAddress = (evt) => {
        var name = evt.target.value;
        this.setState({ memoPropertyAddress: name });
    }
    updateRentPaymentStatus = (evt) => {
        var name = evt.target.value;
        //this.setState({ rentPaymentStatus: name, reasonPaymentNotProcessedIn21Days_Error: "" });
        this.setState({ rentPaymentStatus: name, reasonPaymentNotProcessedIn21Days_Error: "" }, function () {
            this.calculateTotal1099();
        });

        if ((name === 'Approved for Payment' || name === 'N/A' || name === 'Issued') &&
            (this.state.electricPaymentStatus === 'Approved for Payment' || this.state.electricPaymentStatus === 'N/A' || this.state.electricPaymentStatus === 'Issued') &&
            (this.state.gasPaymentStatus === 'Approved for Payment' || this.state.gasPaymentStatus === 'N/A' || this.state.gasPaymentStatus === 'Issued') &&
            (this.state.waterPaymentStatus === 'Approved for Payment' || this.state.waterPaymentStatus === 'N/A' || this.state.waterPaymentStatus === 'Issued') &&
            (this.state.trashPaymentStatus === 'Approved for Payment' || this.state.trashPaymentStatus === 'N/A' || this.state.trashPaymentStatus === 'Issued') &&
            (this.state.energyPaymentStatus === 'Approved for Payment' || this.state.energyPaymentStatus === 'N/A' || this.state.energyPaymentStatus === 'Issued') &&
            (this.state.individualPaymentStatus === 'Approved for Payment' || this.state.individualPaymentStatus === 'N/A' || this.state.individualPaymentStatus === 'Issued') 
            // &&
            // (this.state.otherPaymentStatus === 'Approved for Payment' || this.state.otherPaymentStatus === 'N/A' || this.state.otherPaymentStatus === 'Issued')
            ) {
            document.getElementById("individualCaseId").disabled = false;
        }
        else {
            document.getElementById("individualCaseId").disabled = true;
        }
    }
    updateLandlordSSN = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ landlordSSN: name, landlordSSN_Error: "" });
    }
    updateLandlordTaxID = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ landlordTaxID: name, landlordTaxID_Error: "" });
    }
    updateLandlordDUNSNumber = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ landlordDUNSNumber: name, landlordDUNSNumber_Error: "" });
    }
    updateRentTypeOfAssistance = (evt) => {
        var name = evt.target.value;
        this.setState({ rentTypeOfAssistance: name });
    }
    updateMonthlyRent = (evt) => {
        var name = evt.target.value;
        this.setState({ monthlyRent: name, monthlyRent_Error: "" });
    }
    updatePercentageOfMonthlyRent = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ percentageOfMonthlyRent: name, percentageOfMonthlyRent_Error: "" });
    }
    updateOutstandingRentalArrears = (evt) => {
        var name = evt.target.value;
        this.setState({ outstandingRentalArrearsAmount: name, outstandingRentalArrearsAmount_Error: "" });
    }
    updateNoOfMonthsRentalPayments = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ noOfMonthsRentalPayments: name, noOfMonthsRentalPayments_Error: "" });
    }
    updateTimestampOfRentIssued = (evt) => {
        var name = evt.target.value;
        this.setState({ timestampOfRentIssued: name });
    }
    updateLandlordFirstAttempt = (evt) => {
        var name = evt.target.value;
        this.setState({ landlordFirstAttempt: name });
    }
    updateLandlordResponse = (evt) => {
        var name = evt.target.value;
        this.setState({ landlordResponse: name });
    }
    updateReasonPaymentNotProcessedIn21Days = (evt) => {
        var name = evt.target.value;
        this.setState({ reasonPaymentNotProcessedIn21Days: name, reasonPaymentNotProcessedIn21Days_Error: "" });
    }

    updateElectricProvider = (evt) => {
        var name = evt.target.value;
        this.setState({ electricProvider: name });
    }
    updateElectricUtilityAccountNumber = (evt) => {
        var name = evt.target.value;
        //name = name.replace(/\D/g, '');
        this.setState({ electricUtilityAccountNo: name, electricUtilityAccountNo_Error: "" });
    }
    updateDollarAmountDueForElectricity = (evt) => {
        var name = evt.target.value;
        this.setState({ amountDueforElectricity: name, amountDueforElectricity_Error: "" });

        if (name !== 0 && name !== "0.00" &&
            name !== '' && name !== null && name !== undefined &&
            this.state.electricAmount !== 0 && this.state.electricAmount !== "0.00" &&
            this.state.electricAmount !== '' && this.state.electricAmount !== null && this.state.electricAmount !== undefined) {
            this.setState({ percentageOfElectricBill: ((this.state.electricAmount * 100) / name).toFixed(2) });
        }
        else {
            this.setState({ percentageOfElectricBill: '' });
        }
    }
    updateElectricBillingAccountNumber = (evt) => {
        var name = evt.target.value;
        //name = name.replace(/\D/g, '');
        this.setState({ custElectricBillingAccountNo: name, custElectricBillingAccountNo_Error: "" });
    }
    updateElectricAmount = (evt) => {
        var name = evt.target.value;
        this.setState({ electricAmount: name, electricAmount_Error: "" });

        if (this.state.amountDueforElectricity !== 0 && this.state.amountDueforElectricity !== "0.00" &&
            this.state.amountDueforElectricity !== '' && this.state.amountDueforElectricity !== null && this.state.amountDueforElectricity !== undefined &&
            name !== 0 && name !== "0.00" &&
            name !== '' && name !== null && name !== undefined) {
            this.setState({ percentageOfElectricBill: ((name * 100) / this.state.amountDueforElectricity).toFixed(2) });
        }
        else {
            this.setState({ percentageOfElectricBill: '' });
        }
    }
    updateElectricBankName = (evt) => {
        var name = evt.target.value;
        this.setState({ electricBankName: name });
    }
    updateElectricityProvidersBankingAccountNumber = (evt) => {
        var name = evt.target.value;
        //name = name.replace(/\D/g, '');
        this.setState({ electricUtilityProviderAccountNo: name, electricUtilityProviderAccountNo_Error: "" });
    }
    updateElectricRoutingNumber = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ electricRoutingNumber: name, electricRoutingNumber_Error: "" });
    }
    updateElectricMemoAccountNumber = (evt) => {
        var name = evt.target.value;
        //name = name.replace(/\D/g, '');
        this.setState({ electricMemoAccountNo: name, electricMemoAccountNo_Error: "" });
    }
    updateElectricPaymentStatus = (evt) => {
        var name = evt.target.value;
        this.setState({ electricPaymentStatus: name });

        if ((this.state.rentPaymentStatus === 'Approved for Payment' || this.state.rentPaymentStatus === 'N/A' || this.state.rentPaymentStatus === 'Issued') &&
            (name === 'Approved for Payment' || name === 'N/A' || name === 'Issued') &&
            (this.state.gasPaymentStatus === 'Approved for Payment' || this.state.gasPaymentStatus === 'N/A' || this.state.gasPaymentStatus === 'Issued') &&
            (this.state.waterPaymentStatus === 'Approved for Payment' || this.state.waterPaymentStatus === 'N/A' || this.state.waterPaymentStatus === 'Issued') &&
            (this.state.trashPaymentStatus === 'Approved for Payment' || this.state.trashPaymentStatus === 'N/A' || this.state.trashPaymentStatus === 'Issued') &&
            (this.state.energyPaymentStatus === 'Approved for Payment' || this.state.energyPaymentStatus === 'N/A' || this.state.energyPaymentStatus === 'Issued') &&
            (this.state.individualPaymentStatus === 'Approved for Payment' || this.state.individualPaymentStatus === 'N/A' || this.state.individualPaymentStatus === 'Issued') 
            // &&
            // (this.state.otherPaymentStatus === 'Approved for Payment' || this.state.otherPaymentStatus === 'N/A' || this.state.otherPaymentStatus === 'Issued')
            ) {
            document.getElementById("individualCaseId").disabled = false;
        }
        else {
            document.getElementById("individualCaseId").disabled = true;
        }
    }
    updateElectricUtilityTaxID = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ electricUtilityTaxID: name, electricUtilityTaxID_Error: "" });
    }
    updateElectricUtilityDUNSNumber = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ electricUtilityDUNSNumber: name, electricUtilityDUNSNumber_Error: "" });
    }
    updateMonthlyElectricBillAmount = (evt) => {
        var name = evt.target.value;
        this.setState({ monthlyElectricBillAmount: name, monthlyElectricBillAmount_Error: "" });
    }
    updatePercentageOfElectricBill = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ percentageOfElectricBill: name, percentageOfElectricBill_Error: "" });
    }
    updateNoOfMonthsElectricUtilityPayments = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ noOfMonthsElectricUtilityPayments: name, noOfMonthsElectricUtilityPayments_Error: "" });
    }
    updateElectricTypeOfAssistance = (evt) => {
        var name = evt.target.value;
        this.setState({ electricTypeOfAssistance: name });
    }


    updateGasProvider = (evt) => {
        var name = evt.target.value;
        this.setState({ gasProvider: name });
    }
    updateGasUtilityAccountNumber = (evt) => {
        var name = evt.target.value;
        //name = name.replace(/\D/g, '');
        this.setState({ gasUtilityAccountNo: name, gasUtilityAccountNo_Error: "" });
    }
    updateDollarAmountDueForGas = (evt) => {
        var name = evt.target.value;
        this.setState({ amountDueforGas: name, amountDueforGas_Error: "" });

        if (name !== 0 && name !== "0.00" &&
            name !== '' && name !== null && name !== undefined &&
            this.state.gasAmount !== 0 && this.state.gasAmount !== "0.00" &&
            this.state.gasAmount !== '' && this.state.gasAmount !== null && this.state.gasAmount !== undefined) {
            this.setState({ percentageOfGasBill: ((this.state.gasAmount * 100) / name).toFixed(2) });
        }
        else {
            this.setState({ percentageOfGasBill: '' });
        }
    }
    updateGasBillingAccountNumber = (evt) => {
        var name = evt.target.value;
        //name = name.replace(/\D/g, '');
        this.setState({ custGasBillingAccountNo: name, custGasBillingAccountNo_Error: "" });
    }
    updateGasBankName = (evt) => {
        var name = evt.target.value;
        this.setState({ gasBankName: name });
    }
    updateGasAmount = (evt) => {
        var name = evt.target.value;
        this.setState({ gasAmount: name, gasAmount_Error: "" });

        if (this.state.amountDueforGas !== 0 && this.state.amountDueforGas !== "0.00" &&
            this.state.amountDueforGas !== '' && this.state.amountDueforGas !== null && this.state.amountDueforGas !== undefined &&
            name !== 0 && name !== "0.00" &&
            name !== '' && name !== null && name !== undefined) {
            this.setState({ percentageOfGasBill: ((name * 100) / this.state.amountDueforGas).toFixed(2) });
        }
        else {
            this.setState({ percentageOfGasBill: '' });
        }
    }
    updateGasProvidersBankingAccountNumber = (evt) => {
        var name = evt.target.value;
        //name = name.replace(/\D/g, '');
        this.setState({ gasUtilityProviderAccountNo: name, gasUtilityProviderAccountNo_Error: "" });
    }
    updateGasRoutingNumber = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ gasRoutingNumber: name, gasRoutingNumber_Error: "" });
    }
    updateGasMemoAccountNumber = (evt) => {
        var name = evt.target.value;
        //name = name.replace(/\D/g, '');
        this.setState({ gasMemoAccountNo: name, gasMemoAccountNo_Error: "" });
    }
    updateGasPaymentStatus = (evt) => {
        var name = evt.target.value;
        this.setState({ gasPaymentStatus: name });

        if ((this.state.rentPaymentStatus === 'Approved for Payment' || this.state.rentPaymentStatus === 'N/A' || this.state.rentPaymentStatus === 'Issued') &&
            (this.state.electricPaymentStatus === 'Approved for Payment' || this.state.electricPaymentStatus === 'N/A' || this.state.electricPaymentStatus === 'Issued') &&
            (name === 'Approved for Payment' || name === 'N/A' || name === 'Issued') &&
            (this.state.waterPaymentStatus === 'Approved for Payment' || this.state.waterPaymentStatus === 'N/A' || this.state.waterPaymentStatus === 'Issued') &&
            (this.state.trashPaymentStatus === 'Approved for Payment' || this.state.trashPaymentStatus === 'N/A' || this.state.trashPaymentStatus === 'Issued') &&
            (this.state.energyPaymentStatus === 'Approved for Payment' || this.state.energyPaymentStatus === 'N/A' || this.state.energyPaymentStatus === 'Issued') &&
            (this.state.individualPaymentStatus === 'Approved for Payment' || this.state.individualPaymentStatus === 'N/A' || this.state.individualPaymentStatus === 'Issued') 
            // &&
            // (this.state.otherPaymentStatus === 'Approved for Payment' || this.state.otherPaymentStatus === 'N/A' || this.state.otherPaymentStatus === 'Issued')
            ) {
            document.getElementById("individualCaseId").disabled = false;
        }
        else {
            document.getElementById("individualCaseId").disabled = true;
        }
    }
    updateGasUtilityTaxID = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ gasUtilityTaxID: name, gasUtilityTaxID_Error: "" });
    }
    updateGasUtilityDUNSNumber = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ gasUtilityDUNSNumber: name, gasUtilityDUNSNumber_Error: "" });
    }
    updateMonthlyGasBillAmount = (evt) => {
        var name = evt.target.value;
        this.setState({ monthlyGasBillAmount: name, monthlyGasBillAmount_Error: "" });
    }
    updatePercentageOfGasBill = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ percentageOfGasBill: name, percentageOfGasBill_Error: "" });
    }
    updateNoOfMonthsGasUtilityPayments = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ noOfMonthsGasUtilityPayments: name, noOfMonthsGasUtilityPayments_Error: "" });
    }
    updateGasTypeOfAssistance = (evt) => {
        var name = evt.target.value;
        this.setState({ gasTypeOfAssistance: name });
    }

    updateOtherPaymentAmount = (evt) => {
        var name = evt.target.value;
        this.setState({ otherPaymentAmount: name, otherPaymentAmount_Error: "" });
    }
    updateOtherPaymentStatus = (evt) => {
        var name = evt.target.value;
        this.setState({ otherPaymentStatus: name });

        if ((this.state.rentPaymentStatus === 'Approved for Payment' || this.state.rentPaymentStatus === 'N/A' || this.state.rentPaymentStatus === 'Issued') &&
            (this.state.electricPaymentStatus === 'Approved for Payment' || this.state.electricPaymentStatus === 'N/A' || this.state.electricPaymentStatus === 'Issued') &&
            (this.state.gasPaymentStatus === 'Approved for Payment' || this.state.gasPaymentStatus === 'N/A' || this.state.gasPaymentStatus === 'Issued') &&
            (this.state.waterPaymentStatus === 'Approved for Payment' || this.state.waterPaymentStatus === 'N/A' || this.state.waterPaymentStatus === 'Issued') &&
            (this.state.trashPaymentStatus === 'Approved for Payment' || this.state.trashPaymentStatus === 'N/A' || this.state.trashPaymentStatus === 'Issued') &&
            (this.state.energyPaymentStatus === 'Approved for Payment' || this.state.energyPaymentStatus === 'N/A' || this.state.energyPaymentStatus === 'Issued') &&
            (this.state.individualPaymentStatus === 'Approved for Payment' || this.state.individualPaymentStatus === 'N/A' || this.state.individualPaymentStatus === 'Issued') 
            // &&
            // (name === 'Approved for Payment' || name === 'N/A' || name === 'Issued')
            ) {
            document.getElementById("individualCaseId").disabled = false;
        }
        else {
            document.getElementById("individualCaseId").disabled = true;
        }
    }

    updateNotes = (evt) => {
        var name = evt.target.value;
        this.setState({ notes: name, notes_Error: "" });
    }

    updateDateApplied = (evt) => {
        var name = evt.target.value;
        this.setState({ dateApplied: name, dateApplied_Error: "" });
    }

    updateTenantEmail = (evt) => {
        var name = evt.target.value;
        this.setState({ tenantEmail: name, tenantEmail_Error: "" });
    }

    updateIndividualBankName = (evt) => {
        var name = evt.target.value;
        this.setState({ individualBankName: name });
    }

    updateIndividualAccountNumber = (evt) => {
        var name = evt.target.value;
        //name = name.replace(/\D/g, '');
        this.setState({ individualAccountNumber: name, individualAccountNumber_Error: "" });
    }

    updateIndividualRoutingNumber = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ individualRoutingNumber: name, individualRoutingNumber_Error: "" });
    }

    updateIndividualPaymentAmount = (evt) => {
        var name = evt.target.value;
        this.setState({ individualPaymentAmount: name, individualPaymentAmount_Error: "" });
    }

    updateIndividualPaymentStatus = (evt) => {
        var name = evt.target.value;
        this.setState({ individualPaymentStatus: name });

        if ((this.state.rentPaymentStatus === 'Approved for Payment' || this.state.rentPaymentStatus === 'N/A' || this.state.rentPaymentStatus === 'Issued') &&
            (this.state.electricPaymentStatus === 'Approved for Payment' || this.state.electricPaymentStatus === 'N/A' || this.state.electricPaymentStatus === 'Issued') &&
            (this.state.gasPaymentStatus === 'Approved for Payment' || this.state.gasPaymentStatus === 'N/A' || this.state.gasPaymentStatus === 'Issued') &&
            (this.state.waterPaymentStatus === 'Approved for Payment' || this.state.waterPaymentStatus === 'N/A' || this.state.waterPaymentStatus === 'Issued') &&
            (this.state.trashPaymentStatus === 'Approved for Payment' || this.state.trashPaymentStatus === 'N/A' || this.state.trashPaymentStatus === 'Issued') &&
            (this.state.energyPaymentStatus === 'Approved for Payment' || this.state.energyPaymentStatus === 'N/A' || this.state.energyPaymentStatus === 'Issued') &&
            (name === 'Approved for Payment' || name === 'N/A' || name === 'Issued') 
            // &&
            // (this.state.otherPaymentStatus === 'Approved for Payment' || this.state.otherPaymentStatus === 'N/A' || this.state.otherPaymentStatus === 'Issued')
            ) {
            document.getElementById("individualCaseId").disabled = false;
        }
        else {
            document.getElementById("individualCaseId").disabled = true;
        }
    }

    updateIndividualMemoPropertyAddress = (evt) => {
        var name = evt.target.value;
        this.setState({ individualMemoPropertyAddress: name, individualMemoPropertyAddress_Error: "" });
    }

    onIndividualACHReceivedChange = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ individualACHReceived: 1 });
        }
        else {
            this.setState({ individualACHReceived: 0 });
        }
    }

    updateIndividualPaymentReason = (evt) => {
        var name = evt.target.value;
        this.setState({ individualPaymentReason: name });
    }

    updateOtherBankName = (evt) => {
        var name = evt.target.value;
        this.setState({ otherBankName: name });
    }

    updateOtherAccountNumber = (evt) => {
        var name = evt.target.value;
        //name = name.replace(/\D/g, '');
        this.setState({ otherAccountNumber: name, otherAccountNumber_Error: "" });
    }

    updateOtherRoutingNumber = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ otherRoutingNumber: name, otherRoutingNumber_Error: "" });
    }

    updateOtherMemoPropertyAddress = (evt) => {
        var name = evt.target.value;
        this.setState({ otherMemoPropertyAddress: name, otherMemoPropertyAddress_Error: "" });
    }

    updateOtherPayeeName = (evt) => {
        var name = evt.target.value;
        this.setState({ otherPayeeName: name, otherPayeeName_Error: "" });
    }

    updateOtherDesription = (evt) => {
        var name = evt.target.value;
        this.setState({ otherDesription: name, otherDesription_Error: "" });
    }



    updateWaterProvider = (evt) => {
        var name = evt.target.value;
        this.setState({ waterProvider: name });
    }
    updateWaterUtilityAccountNumber = (evt) => {
        var name = evt.target.value;
        //name = name.replace(/\D/g, '');
        this.setState({ waterUtilityAccountNo: name, waterUtilityAccountNo_Error: "" });
    }
    updateWaterBillingAccountNumber = (evt) => {
        var name = evt.target.value;
        //name = name.replace(/\D/g, '');
        this.setState({ custWaterBillingAccountNo: name, custWaterBillingAccountNo_Error: "" });
    }
    updateMonthlyWaterBillAmount = (evt) => {
        var name = evt.target.value;
        this.setState({ monthlyWaterBillAmount: name, monthlyWaterBillAmount_Error: "" });
    }
    updateNoOfMonthsWaterUtilityPayments = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ noOfMonthsWaterUtilityPayments: name, noOfMonthsWaterUtilityPayments_Error: "" });
    }
    updateWaterTypeOfAssistance = (evt) => {
        var name = evt.target.value;
        this.setState({ waterTypeOfAssistance: name });
    }
    updateDollarAmountDueForWater = (evt) => {
        var name = evt.target.value;
        this.setState({ amountDueforWater: name, amountDueforWater_Error: "" });

        if (name !== 0 && name !== "0.00" &&
            name !== '' && name !== null && name !== undefined &&
            this.state.waterAmount !== 0 && this.state.waterAmount !== "0.00" &&
            this.state.waterAmount !== '' && this.state.waterAmount !== null && this.state.waterAmount !== undefined) {
            this.setState({ percentageOfWaterBill: ((this.state.waterAmount * 100) / name).toFixed(2) });
        }
        else {
            this.setState({ percentageOfWaterBill: '' });
        }
    }
    updateWaterAmount = (evt) => {
        var name = evt.target.value;
        this.setState({ waterAmount: name, waterAmount_Error: "" });

        if (this.state.amountDueforWater !== 0 && this.state.amountDueforWater !== "0.00" &&
            this.state.amountDueforWater !== '' && this.state.amountDueforWater !== null && this.state.amountDueforWater !== undefined &&
            name !== 0 && name !== "0.00" &&
            name !== '' && name !== null && name !== undefined) {
            this.setState({ percentageOfWaterBill: ((name * 100) / this.state.amountDueforWater).toFixed(2) });
        }
        else {
            this.setState({ percentageOfWaterBill: '' });
        }
    }
    updatePercentageOfWaterBill = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ percentageOfWaterBill: name, percentageOfWaterBill_Error: "" });
    }
    updateWaterBankName = (evt) => {
        var name = evt.target.value;
        this.setState({ waterBankName: name });
    }
    updateWaterProvidersBankingAccountNumber = (evt) => {
        var name = evt.target.value;
        //name = name.replace(/\D/g, '');
        this.setState({ waterUtilityProviderAccountNo: name, waterUtilityProviderAccountNo_Error: "" });
    }
    updateWaterRoutingNumber = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ waterRoutingNumber: name, waterRoutingNumber_Error: "" });
    }
    updateWaterMemoAccountNumber = (evt) => {
        var name = evt.target.value;
        //name = name.replace(/\D/g, '');
        this.setState({ waterMemoAccountNo: name, waterMemoAccountNo_Error: "" });
    }
    updateWaterUtilityTaxID = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ waterUtilityTaxID: name, waterUtilityTaxID_Error: "" });
    }
    updateWaterUtilityDUNSNumber = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ waterUtilityDUNSNumber: name, waterUtilityDUNSNumber_Error: "" });
    }
    updateWaterPaymentStatus = (evt) => {
        var name = evt.target.value;
        this.setState({ waterPaymentStatus: name });

        if ((this.state.rentPaymentStatus === 'Approved for Payment' || this.state.rentPaymentStatus === 'N/A' || this.state.rentPaymentStatus === 'Issued') &&
            (this.state.electricPaymentStatus === 'Approved for Payment' || this.state.electricPaymentStatus === 'N/A' || this.state.electricPaymentStatus === 'Issued') &&
            (this.state.gasPaymentStatus === 'Approved for Payment' || this.state.gasPaymentStatus === 'N/A' || this.state.gasPaymentStatus === 'Issued') &&
            (name === 'Approved for Payment' || name === 'N/A' || name === 'Issued') &&
            (this.state.trashPaymentStatus === 'Approved for Payment' || this.state.trashPaymentStatus === 'N/A' || this.state.trashPaymentStatus === 'Issued') &&
            (this.state.energyPaymentStatus === 'Approved for Payment' || this.state.energyPaymentStatus === 'N/A' || this.state.energyPaymentStatus === 'Issued') &&
            (this.state.individualPaymentStatus === 'Approved for Payment' || this.state.individualPaymentStatus === 'N/A' || this.state.individualPaymentStatus === 'Issued') 
            // &&
            // (this.state.otherPaymentStatus === 'Approved for Payment' || this.state.otherPaymentStatus === 'N/A' || this.state.otherPaymentStatus === 'Issued')
            ) {
            document.getElementById("individualCaseId").disabled = false;
        }
        else {
            document.getElementById("individualCaseId").disabled = true;
        }
    }


    updateTrashProvider = (evt) => {
        var name = evt.target.value;
        this.setState({ trashProvider: name });
    }
    updateTrashUtilityAccountNumber = (evt) => {
        var name = evt.target.value;
        //name = name.replace(/\D/g, '');
        this.setState({ trashUtilityAccountNo: name, trashUtilityAccountNo_Error: "" });
    }
    updateTrashBillingAccountNumber = (evt) => {
        var name = evt.target.value;
        //name = name.replace(/\D/g, '');
        this.setState({ custTrashBillingAccountNo: name, custTrashBillingAccountNo_Error: "" });
    }
    updateMonthlyTrashBillAmount = (evt) => {
        var name = evt.target.value;
        this.setState({ monthlyTrashBillAmount: name, monthlyTrashBillAmount_Error: "" });
    }
    updateNoOfMonthsTrashUtilityPayments = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ noOfMonthsTrashUtilityPayments: name, noOfMonthsTrashUtilityPayments_Error: "" });
    }
    updateTrashTypeOfAssistance = (evt) => {
        var name = evt.target.value;
        this.setState({ trashTypeOfAssistance: name });
    }
    updateDollarAmountDueForTrash = (evt) => {
        var name = evt.target.value;
        this.setState({ amountDueforTrash: name, amountDueforTrash_Error: "" });

        if (name !== 0 && name !== "0.00" &&
            name !== '' && name !== null && name !== undefined &&
            this.state.trashAmount !== 0 && this.state.trashAmount !== "0.00" &&
            this.state.trashAmount !== '' && this.state.trashAmount !== null && this.state.trashAmount !== undefined) {
            this.setState({ percentageOfTrashBill: ((this.state.trashAmount * 100) / name).toFixed(2) });
        }
        else {
            this.setState({ percentageOfTrashBill: '' });
        }
    }
    updateTrashAmount = (evt) => {
        var name = evt.target.value;
        this.setState({ trashAmount: name, trashAmount_Error: "" });

        if (this.state.amountDueforTrash !== 0 && this.state.amountDueforTrash !== "0.00" &&
            this.state.amountDueforTrash !== '' && this.state.amountDueforTrash !== null && this.state.amountDueforTrash !== undefined &&
            name !== 0 && name !== "0.00" &&
            name !== '' && name !== null && name !== undefined) {
            this.setState({ percentageOfTrashBill: ((name * 100) / this.state.amountDueforTrash).toFixed(2) });
        }
        else {
            this.setState({ percentageOfTrashBill: '' });
        }
    }
    updatePercentageOfTrashBill = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ percentageOfTrashBill: name, percentageOfTrashBill_Error: "" });
    }
    updateTrashBankName = (evt) => {
        var name = evt.target.value;
        this.setState({ trashBankName: name });
    }
    updateTrashProvidersBankingAccountNumber = (evt) => {
        var name = evt.target.value;
        //name = name.replace(/\D/g, '');
        this.setState({ trashUtilityProviderAccountNo: name, trashUtilityProviderAccountNo_Error: "" });
    }
    updateTrashRoutingNumber = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ trashRoutingNumber: name, trashRoutingNumber_Error: "" });
    }
    updateTrashMemoAccountNumber = (evt) => {
        var name = evt.target.value;
        //name = name.replace(/\D/g, '');
        this.setState({ trashMemoAccountNo: name, trashMemoAccountNo_Error: "" });
    }
    updateTrashUtilityTaxID = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ trashUtilityTaxID: name, trashUtilityTaxID_Error: "" });
    }
    updateTrashUtilityDUNSNumber = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ trashUtilityDUNSNumber: name, trashUtilityDUNSNumber_Error: "" });
    }
    updateTrashPaymentStatus = (evt) => {
        var name = evt.target.value;
        this.setState({ trashPaymentStatus: name });

        if ((this.state.rentPaymentStatus === 'Approved for Payment' || this.state.rentPaymentStatus === 'N/A' || this.state.rentPaymentStatus === 'Issued') &&
            (this.state.electricPaymentStatus === 'Approved for Payment' || this.state.electricPaymentStatus === 'N/A' || this.state.electricPaymentStatus === 'Issued') &&
            (this.state.gasPaymentStatus === 'Approved for Payment' || this.state.gasPaymentStatus === 'N/A' || this.state.gasPaymentStatus === 'Issued') &&
            (this.state.waterPaymentStatus === 'Approved for Payment' || this.state.waterPaymentStatus === 'N/A' || this.state.waterPaymentStatus === 'Issued') &&
            (name === 'Approved for Payment' || name === 'N/A' || name === 'Issued') &&
            (this.state.energyPaymentStatus === 'Approved for Payment' || this.state.energyPaymentStatus === 'N/A' || this.state.energyPaymentStatus === 'Issued') &&
            (this.state.individualPaymentStatus === 'Approved for Payment' || this.state.individualPaymentStatus === 'N/A' || this.state.individualPaymentStatus === 'Issued') 
            // &&
            // (this.state.otherPaymentStatus === 'Approved for Payment' || this.state.otherPaymentStatus === 'N/A' || this.state.otherPaymentStatus === 'Issued')
            ) {
            document.getElementById("individualCaseId").disabled = false;
        }
        else {
            document.getElementById("individualCaseId").disabled = true;
        }
    }


    updateEnergyProvider = (evt) => {
        var name = evt.target.value;
        this.setState({ energyProvider: name });
    }
    updateEnergyUtilityAccountNumber = (evt) => {
        var name = evt.target.value;
        //name = name.replace(/\D/g, '');
        this.setState({ energyUtilityAccountNo: name, energyUtilityAccountNo_Error: "" });
    }
    updateEnergyBillingAccountNumber = (evt) => {
        var name = evt.target.value;
        //name = name.replace(/\D/g, '');
        this.setState({ custEnergyBillingAccountNo: name, custEnergyBillingAccountNo_Error: "" });
    }
    updateMonthlyEnergyBillAmount = (evt) => {
        var name = evt.target.value;
        this.setState({ monthlyEnergyBillAmount: name, monthlyEnergyBillAmount_Error: "" });
    }
    updateNoOfMonthsEnergyUtilityPayments = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ noOfMonthsEnergyUtilityPayments: name, noOfMonthsEnergyUtilityPayments_Error: "" });
    }
    updateEnergyTypeOfAssistance = (evt) => {
        var name = evt.target.value;
        this.setState({ energyTypeOfAssistance: name });
    }
    updateDollarAmountDueForEnergy = (evt) => {
        var name = evt.target.value;
        this.setState({ amountDueforEnergy: name, amountDueforEnergy_Error: "" });

        if (name !== 0 && name !== "0.00" &&
            name !== '' && name !== null && name !== undefined &&
            this.state.energyAmount !== 0 && this.state.energyAmount !== "0.00" &&
            this.state.energyAmount !== '' && this.state.energyAmount !== null && this.state.energyAmount !== undefined) {
            this.setState({ percentageOfEnergyBill: ((this.state.energyAmount * 100) / name).toFixed(2) });
        }
        else {
            this.setState({ percentageOfEnergyBill: '' });
        }
    }
    updateEnergyAmount = (evt) => {
        var name = evt.target.value;
        this.setState({ energyAmount: name, energyAmount_Error: "" });

        if (this.state.amountDueforEnergy !== 0 && this.state.amountDueforEnergy !== "0.00" &&
            this.state.amountDueforEnergy !== '' && this.state.amountDueforEnergy !== null && this.state.amountDueforEnergy !== undefined &&
            name !== 0 && name !== "0.00" &&
            name !== '' && name !== null && name !== undefined) {
            this.setState({ percentageOfEnergyBill: ((name * 100) / this.state.amountDueforEnergy).toFixed(2) });
        }
        else {
            this.setState({ percentageOfEnergyBill: '' });
        }
    }
    updatePercentageOfEnergyBill = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ percentageOfEnergyBill: name, percentageOfEnergyBill_Error: "" });
    }
    updateEnergyBankName = (evt) => {
        var name = evt.target.value;
        this.setState({ energyBankName: name });
    }
    updateEnergyProvidersBankingAccountNumber = (evt) => {
        var name = evt.target.value;
        //name = name.replace(/\D/g, '');
        this.setState({ energyUtilityProviderAccountNo: name, energyUtilityProviderAccountNo_Error: "" });
    }
    updateEnergyRoutingNumber = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ energyRoutingNumber: name, energyRoutingNumber_Error: "" });
    }
    updateEnergyMemoAccountNumber = (evt) => {
        var name = evt.target.value;
        //name = name.replace(/\D/g, '');
        this.setState({ energyMemoAccountNo: name, energyMemoAccountNo_Error: "" });
    }
    updateEnergyUtilityTaxID = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ energyUtilityTaxID: name, energyUtilityTaxID_Error: "" });
    }
    updateEnergyUtilityDUNSNumber = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ energyUtilityDUNSNumber: name, energyUtilityDUNSNumber_Error: "" });
    }
    updateEnergyPaymentStatus = (evt) => {
        var name = evt.target.value;
        this.setState({ energyPaymentStatus: name });

        if ((this.state.rentPaymentStatus === 'Approved for Payment' || this.state.rentPaymentStatus === 'N/A' || this.state.rentPaymentStatus === 'Issued') &&
            (this.state.electricPaymentStatus === 'Approved for Payment' || this.state.electricPaymentStatus === 'N/A' || this.state.electricPaymentStatus === 'Issued') &&
            (this.state.gasPaymentStatus === 'Approved for Payment' || this.state.gasPaymentStatus === 'N/A' || this.state.gasPaymentStatus === 'Issued') &&
            (this.state.waterPaymentStatus === 'Approved for Payment' || this.state.waterPaymentStatus === 'N/A' || this.state.waterPaymentStatus === 'Issued') &&
            (this.state.trashPaymentStatus === 'Approved for Payment' || this.state.trashPaymentStatus === 'N/A' || this.state.trashPaymentStatus === 'Issued') &&
            (name === 'Approved for Payment' || name === 'N/A' || name === 'Issued') &&
            (this.state.individualPaymentStatus === 'Approved for Payment' || this.state.individualPaymentStatus === 'N/A' || this.state.individualPaymentStatus === 'Issued') 
            // &&
            // (this.state.otherPaymentStatus === 'Approved for Payment' || this.state.otherPaymentStatus === 'N/A' || this.state.otherPaymentStatus === 'Issued')
            ) {
            document.getElementById("individualCaseId").disabled = false;
        }
        else {
            document.getElementById("individualCaseId").disabled = true;
        }
    }

    openAddPropertyPopup = async () => {
        document.getElementById("recform").reset();
        this.setState({ reconciliation: { "entityType": "", "initiallyPaidBy": "", "amountType": "", "amount": "", "memoPropertyAddress": "", "paymentMethod": "", "otherPaymentStatus": "", "amountCoveredAfterRec": "", "amountCoveredBeforeRec": "", "transactionNo": "", "notes": "" }, reconciliationMode: 'add', isChildRecord: false });
        setTimeout(() => {
            $('#exampleModal').modal('show');
        }, 100);
    }

    editReconciliation(id) {
        this.setState({ validationErrors: {} });
        let editRec = this.state.reconciliationListClone.filter(x => x.id == id)[0];
        let test = editRec;
        let entity = this.state.reconciliationList.find(x => x.id == id).entityType;
        let rec = this.state.reconciliationList.filter(x => x.entityType == entity)
        if (rec && rec.length > 0) {
            if (rec.pop().id != id) {
                this.setState({
                    isChildRecord: true
                });
            }
            else {
                this.setState({
                    isChildRecord: false
                });
            }
        }
        this.setState({ reconciliation: test, reconciliationMode: 'edit', reconciliationEditId: id });
        setTimeout(() => {
            $('#exampleModal').modal('show');
        }, 1000);
    }

    handleReconciliationChanges(field, e) {
        if (field == "entityType" || field == "initiallyPaidBy" || field == "amountType") {
            const { reconciliation } = { ...this.state };
            const currentState = reconciliation;
            currentState[field] = e.target.value;
            this.setState({ reconciliation: currentState });
            if (field == "amountType" || field == "entityType" && this.state.reconciliation.entityType && this.state.reconciliation.entityType !== "" && this.state.reconciliation.amountType && this.state.reconciliation.amountType !== "" && this.state.reconciliation.amount && this.state.reconciliation.amount !== "") {
                this.calculateAmountCoveredAfterRec(this.state.reconciliation.entityType)
            }
        }
        else if (field == "amount" || field == "amountCoveredAfterRec") {
            let { value } = e.target;
            //  if(!value.isNaN()) {
            let intValue = value.replace(/[^0-9.]/g, "");
            const { reconciliation } = { ...this.state };
            const currentState = reconciliation;
            currentState[field] = intValue;
            //   this.setState({ reconciliation: currentState });
            this.setState({ reconciliation: currentState });
            if (field == "amount" && this.state.reconciliation.entityType && this.state.reconciliation.entityType !== "" && this.state.reconciliation.amountType && this.state.reconciliation.amountType !== "" && this.state.reconciliation.amount && this.state.reconciliation.amount !== "") {
                this.calculateAmountCoveredAfterRec(this.state.reconciliation.entityType)
            }
            // }
        }
        else {
            const { reconciliation } = { ...this.state };
            const currentState = reconciliation;
            const { value } = e.target;
            currentState[field] = value;
            //  this.setState({ reconciliation: currentState });
            this.setState({ reconciliation: currentState });
        }

        // this.handlePropertyOnchangeValidation(field);
    }
    calculateAmountCoveredAfterRec(entityType) {
        let findRecord = this.state.reconciliationList.filter(x => x.entityType == entityType);
        let amountCoveredAfterRec = 0;
        let amountCoveredBeforeRec = 0;
        let rentAmountorReco = 0;
        // first check record exists
        if (findRecord && findRecord.length > 0) {
            let oldRecord = findRecord.pop();
            if (this.state.reconciliationMode == "add") {
                rentAmountorReco = oldRecord.amountCoveredAfterRec;
                amountCoveredBeforeRec = oldRecord.amountCoveredAfterRec;
            }
            else {
                let findRec = this.state.reconciliationList.find(x => x.id == this.state.reconciliationEditId);
                rentAmountorReco = findRec.amountCoveredBeforeRec;
            }
        }
        else {
            if (entityType == "Individual") {
                rentAmountorReco = this.state.individualPaymentAmount == null ? 0 : this.state.individualPaymentAmount;
                amountCoveredBeforeRec = this.state.individualPaymentAmount == null ? 0 : this.state.individualPaymentAmount;
            }
            else if (entityType == "Electric") {
                rentAmountorReco = this.state.electricAmount == null ? 0 : this.state.electricAmount;
                amountCoveredBeforeRec = this.state.electricAmount == null ? 0 : this.state.electricAmount;
            }
            else if (entityType == "Gas") {
                rentAmountorReco = this.state.gasAmount == null ? 0 : this.state.gasAmount;
                amountCoveredBeforeRec = this.state.gasAmount == null ? 0 : this.state.gasAmount;
            }
            else if (entityType == "Water/Sewer") {
                rentAmountorReco = this.state.waterAmount == null ? 0 : this.state.waterAmount;
                amountCoveredBeforeRec = this.state.waterAmount == null ? 0 : this.state.waterAmount;
            }
            else if (entityType == "Trash") {
                rentAmountorReco = this.state.trashAmount == null ? 0 : this.state.trashAmount;
                amountCoveredBeforeRec = this.state.trashAmount == null ? 0 : this.state.trashAmount;
            }
            else if (entityType == "Energy") {
                rentAmountorReco = this.state.energyAmount == null ? 0 : this.state.energyAmount;
                amountCoveredBeforeRec = this.state.energyAmount == null ? 0 : this.state.energyAmount;
            }
            else {
                rentAmountorReco = this.state.rentAmount == null ? 0 : this.state.rentAmount;
                amountCoveredBeforeRec = this.state.rentAmount == null ? 0 : this.state.rentAmount;
            }
        }

        if (this.state.reconciliation.amountType == "Paid" || this.state.reconciliation.amountType == "Adjustment(Positive)") {
            amountCoveredAfterRec = Number(rentAmountorReco) + Number(this.state.reconciliation.amount);
        }
        else {
            amountCoveredAfterRec = Number(rentAmountorReco) - Number(this.state.reconciliation.amount);
        }
        const { reconciliation } = { ...this.state };
        const currentState = reconciliation;
        currentState['amountCoveredAfterRec'] = amountCoveredAfterRec.toFixed(2);
        if (this.state.reconciliationMode == "add") {
            currentState['amountCoveredBeforeRec'] = amountCoveredBeforeRec;
        }
        this.setState({ reconciliation: currentState });
    }

    addReconciliation = async () => {
        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayName;
        let path = '/addreconciliation';
        let myInit = { // OPTIONAL
            body: {
                "info": {
                    "individualID": this.state.reconciliation.individualID,
                    "roundNumber": this.state.reconciliation.roundNumber,
                    "amount": this.state.reconciliation.amount,
                    "amountCoveredAfterRec": this.state.reconciliation.amountCoveredAfterRec,
                    "amountCoveredBeforeRec": this.state.reconciliation.amountCoveredBeforeRec,
                    "amountType": this.state.reconciliation.amountType,
                    "entityType": this.state.reconciliation.entityType,
                    "initiallyPaidBy": this.state.reconciliation.initiallyPaidBy,
                    "notes": this.state.reconciliation.notes,
                    "transactionNo": this.state.reconciliation.transactionNo,
                    "mode": this.state.reconciliationMode,
                    "memoPropertyAddress": this.state.reconciliation.memoPropertyAddress,
                    "paymentMethod": this.state.reconciliation.paymentMethod,
                    "otherPaymentStatus": this.state.reconciliation.otherPaymentStatus,
                    "id": this.state.reconciliation.id,
                    "program": "cepp",

                    "total1099Amount": this.state.total1099Amount,
                    "createdBy": this.state.adminUsername,
                    "modifiedBy": this.state.adminUsername,
                }
            }, // replace this with attributes you need
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
        }
        return await API.post(apiName, path, myInit);
    }
    calculateTotal1099() {
        // total 1099 logic
        let sum = 0;
        let substact = 0;
        // if (this.state.reconciliation.entityType == "Landlord" && this.state.reconciliation.initiallyPaidBy == "OCUW") {
        let rec = this.state.reconciliationList.filter(x => x.entityType == "Landlord" && x.initiallyPaidBy == "OCUW");
        if (rec.length > 0) {
            let arr = [];
            let returnArr = [];

            let paidRec = rec.filter(x => x.amountType == "Paid" || x.amountType == "Adjustment(Positive)");
            //  let paidRec = rec.filter(x => x.amountType == "Paid" || x.amountType == "Adjustment(Positive)");
            if (paidRec && paidRec.length > 0) {
                for (let i = 0; i < paidRec.length; i++) {
                    arr.push(Number(paidRec[i].amount))
                }
                for (var i = 0; i < arr.length; i++) {
                    sum += parseFloat(arr[i]) || 0;
                }
            }
            // let returnRec = rec.filter(x => x.amountType == "Return" || x.amountType == "Adjustment(Negative)");

            let returnRec = rec.filter(x => x.amountType == "Return" || x.amountType == "Adjustment(Negative)");

            if (returnRec && returnRec.length > 0) {
                for (let i = 0; i < returnRec.length; i++) {
                    returnArr.push(Number(returnRec[i].amount))
                }
                for (var i = 0; i < returnArr.length; i++) {
                    substact += parseFloat(returnArr[i]) || 0;
                }
            }
        }
        let total1099 = 0;
        if (this.state.rentPaymentStatus == 'N/A') {
            total1099 = sum - substact;
        }
        else {
            total1099 = Number(this.state.rentAmount) + sum - substact;
        }
        // total1099 = Number(this.state.rentAmount) + sum - substact;
        total1099 = total1099.toFixed(2);
        this.setState({ total1099Amount: total1099 });
        // }
    }

    calculateTotalFund() {
        // total 1099 logic
        let sum = 0;
        let substact = 0;
        // if (this.state.reconciliation.entityType == "Landlord" && this.state.reconciliation.initiallyPaidBy == "OCUW") {
        if (this.state.reconciliationListByIndividualId && this.state.reconciliationListByIndividualId.length > 0) {
            let arr = [];
            let returnArr = [];

            let paidRec = this.state.reconciliationListByIndividualId.filter(x => x.amountType == "Paid" || x.amountType == "Adjustment(Positive)");
            //  let paidRec = rec.filter(x => x.amountType == "Paid" || x.amountType == "Adjustment(Positive)");
            if (paidRec && paidRec.length > 0) {
                for (let i = 0; i < paidRec.length; i++) {
                    arr.push(Number(paidRec[i].amount))
                }
                for (var i = 0; i < arr.length; i++) {
                    sum += parseFloat(arr[i]) || 0;
                }
            }
            // let returnRec = rec.filter(x => x.amountType == "Return" || x.amountType == "Adjustment(Negative)");

            let returnRec = this.state.reconciliationListByIndividualId.filter(x => x.amountType == "Return" || x.amountType == "Adjustment(Negative)");

            if (returnRec && returnRec.length > 0) {
                for (let i = 0; i < returnRec.length; i++) {
                    returnArr.push(Number(returnRec[i].amount))
                }
                for (var i = 0; i < returnArr.length; i++) {
                    substact += parseFloat(returnArr[i]) || 0;
                }
            }
        }

        let totalFund = 0;
        totalFund = Number(this.state.totalCoveredClone) + sum - substact;
        totalFund = totalFund.toFixed(2);
        this.setState({ totalCovered: totalFund });
        // }
    }

    saveReconciliation = async (e) => {
        e.preventDefault();
        let isvalid = this.handleReconciliationValidation();
        if (isvalid) {
            this.setState({ isLoading: true });
            $('#exampleModal').modal('hide');
            const { reconciliation } = { ...this.state };
            const currentState = reconciliation;
            currentState["individualID"] = this.state.individualID;
            currentState["roundNumber"] = this.state.roundNumber;
            let res = await this.addReconciliation();
            console.log(res);
            if (this.state.reconciliationMode && this.state.reconciliationMode == "add") {
                this.setState({ isLoading: false, snackbarMessageClass: 'Mui-filledSuccess', openSnackbar: true, snackbarSeverity: "success", snackbarMessage: "Record Inserted Successfully" });
            }
            else {
                this.setState({ isLoading: false, snackbarMessageClass: 'Mui-filledSuccess', openSnackbar: true, snackbarSeverity: "success", snackbarMessage: "Record Updated Successfully" });
            }
            let getList = await this.getReconciliationList();
            this.setState({ isLoading: false });
            console.log(getList);
            // this.setState({ reconciliationList: getList, reconciliationListClone: JSON.parse(JSON.stringify(getList)) });
            let dispList = getList.filter(x => x.roundNumber == this.state.roundNumber);
            this.setState({ reconciliationList: dispList, reconciliationListByIndividualId: getList, reconciliationListClone: JSON.parse(JSON.stringify(dispList)) });
            this.calculateTotal1099();
            this.calculateTotalFund();
        }
    }

    handleReconciliationValidation() {
        let errors = {};
        let formIsValid = true;

        if (!this.state.reconciliation.amount || this.state.reconciliation.amount == "") {
            formIsValid = false;
            errors["amount"] = "Amount (in $) is required";
        }
        if (!this.state.reconciliation.entityType || this.state.reconciliation.entityType == "") {
            formIsValid = false;
            errors["entityType"] = "Initial Receiver is required";
        }
        if (!this.state.reconciliation.initiallyPaidBy || this.state.reconciliation.initiallyPaidBy == "") {
            formIsValid = false;
            errors["initiallyPaidBy"] = "Initially Paid By is required";
        }
        if (!this.state.reconciliation.amountType || this.state.reconciliation.amountType == "") {
            formIsValid = false;
            errors["amountType"] = "Amount is required";
        }

        this.setState({ validationErrors: errors });
        return formIsValid;
    }

    closeMsg = () => {
        $('#deleteModal').modal('hide');
    }

    deleteRec = async (id) => {
        let entity = this.state.reconciliationList.find(x => x.id == id).entityType;
        let rec = this.state.reconciliationList.filter(x => x.entityType == entity)
        if (rec && rec.length > 0) {
            if (rec.pop().id == id) {
                $('#deleteModal').modal('show');
                this.setState({
                    deleteReconciliationId: id
                });
            }
            else {
                this.setState({ isLoading: false, openSnackbar: true, snackbarMessageClass: 'Mui-filledError', snackbarSeverity: "error", snackbarMessage: "You can not delete this record as there is another latest reconciliation record for same receiver." });
            }
        }
    }

    performDelete = async () => {
        this.setState({ isLoading: true });
        $('#deleteModal').modal('hide');
        let res = await this.deleteReconciliationList();
        this.setState({ isLoading: false, deleteReconciliationId: 0, snackbarMessageClass: 'Mui-filledSuccess', openSnackbar: true, snackbarSeverity: "success", snackbarMessage: "Record Deleted Successfully" });
        var recList = await this.getReconciliationList();

        //        this.setState({ reconciliationList: recList, reconciliationListClone: JSON.parse(JSON.stringify(recList)) });
        let dispList = recList.filter(x => x.roundNumber == this.state.roundNumber);
        this.setState({ reconciliationList: dispList, reconciliationListByIndividualId: recList, reconciliationListClone: JSON.parse(JSON.stringify(dispList)) });
        this.calculateTotal1099();
        this.calculateTotalFund();
    }

    deleteReconciliationList = async () => {
        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayName;
        let path = '/deletereconciliation';
        let myInit = { // OPTIONAL
            body: {
                "info": {
                    "id": this.state.deleteReconciliationId,
                    "program": "cepp",
                    "modifiedBy": this.state.adminUsername
                }
            }, // replace this with attributes you need
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
        }
        return await API.post(apiName, path, myInit);
    }

    render() {
        if (this.state.authState === "signedIn" || this.state.authState === "loading") {
            return (

                <div className="d-flex flex-column sticky-footer-wrapper">

                    {/* main content starts */}
                    {/* <main className="main flex-fill mt-2 mt-md-3">
                        <div className="container-fluid"> */}

                    {/* content area starts */}
                    <div className="box-shadow details-page">

                        <div className="record-detail-main validation-text">
                            <h4 className="font-weight-bold text-uppercase text-primary py-2 d-block d-sm-none"><i class="fas fa-edit"></i> EDIT ERA-OC APPLICATION</h4>
                            <div className="data-section">
                                <fieldset id="myset">
                                    <div className="border p-3">
                                        <React.Fragment>

                                            <p className="top-note" style={{ display: this.state.totalRecords > 1 ? 'block' : 'none' }}> There is another record for same Tenant under ERA-OC program.</p>

                                            <div className="box-gray mb-4">
                                                {/* <h2 className="profile-title"><i class="fas fa-file-alt mr-2"></i>Other Details</h2> */}
                                                <div className="box-details">
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Case </label>
                                                            <select className="form-control" id="individualCaseId" onChange={this.updateIndividualCase} value={this.state.individualCase}>
                                                                <option value="">-- select --</option>
                                                                <option value="Open" selected>Open</option>
                                                                <option value="Close">Close</option>
                                                                <option value="Paid">Paid</option>
                                                                <option value="Ineligible">Ineligible</option>
                                                                <option value="Re-open">Re-open</option>
                                                            </select>
                                                            <div className="alert-small-text">{""}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Online Application Accepted </label>
                                                            <select className="form-control" ref={(input) => this.hmisApprovalSelector = input} onChange={this.updateHIMSAproval} value={this.state.hmisApproval}>
                                                                <option value="">-- select --</option>
                                                                <option value="Yes">Yes</option>
                                                                <option value="No">No</option>
                                                            </select>
                                                            <div className="alert-small-text">{""}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-4 field-hightlight">
                                                            <label>Has the household been approved for United Way? </label>
                                                            <select className="form-control" onChange={this.updateHouseholdApprovedForUnitedWay} value={this.state.householdApprovedForUnitedWay}>
                                                                <option value="">-- select --</option>
                                                                <option value="Yes">Yes</option>
                                                                <option value="No">No</option>
                                                            </select>
                                                            <div className="alert-small-text">{""}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-1">
                                                            <label>Round #</label>
                                                            <input className="form-control" readOnly value={this.state.roundNumber}></input>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-1">
                                                            <label>Total Fund</label>
                                                            <input className="form-control" readOnly value={this.state.totalCovered}></input>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2" style={{ display: (this.state.dateImported_Formatted !== "" && this.state.dateImported_Formatted !== null) ? 'block' : 'none' }}>
                                                            <label>Date Imported</label>
                                                            <input className="form-control" readOnly value={this.state.dateImported_Formatted}></input>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                            <p className="wh-note"><span>Note:</span> After updating details, please click on 'Save' button to push changes to database.</p>

                                            <div className="box-gray mb-4">
                                                <h2 className="profile-title"><i class="fas fa-user-circle mr-2"></i>APPLICANT DETAILS</h2>
                                                <div className="box-details">
                                                    <div className="form-row">
                                                        <div class="form-group col-md-6 col-xl-1 field-hightlight">
                                                            <label>Individual ID </label>
                                                            <input className="form-control" readOnly onChange={this.updateIndividualID} value={this.state.individualID}></input>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Unique ID </label>
                                                            <input className="form-control" ref={this.uniqueIDRef} onChange={this.updateUniqueID} value={this.state.uniqueID} maxlength="50"></input>
                                                            <div class="alert-small-text">{this.state.uniqueID_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Program </label>
                                                            <select disabled className="form-control" ref={(input) => this.program = input} onChange={this.updateProgram} value={this.state.programTitle}>
                                                                <option value="">-- select --</option>
                                                                <option value="ERA-OC">ERA-OC</option>
                                                                <option value="Pandemic HPP">Pandemic HPP</option>
                                                            </select>
                                                            <div className="alert-small-text">{""}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Date Applied </label>
                                                            <Tooltip title="Date on which applicant applied for program">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="date" ref={this.dateAppliedRef} onChange={this.updateDateApplied} value={this.state.dateApplied}></input>
                                                            <div className="alert-small-text">{this.state.dateApplied_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>Agent</label>
                                                            <input className="form-control" type="text" ref={this.agentRef} onChange={this.updateAgent} maxlength="100" value={this.state.agent}></input>
                                                            <div class="alert-small-text">{this.state.agent_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-1">
                                                            <label>Household ID </label>
                                                            <input className="form-control" ref={this.householdIDRef} onChange={this.updateHouseholdID} value={this.state.householdID}></input>
                                                            <div class="alert-small-text">{this.state.householdID_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>Transaction ID </label>
                                                            <input className="form-control" ref={this.transactionIDRef} onChange={this.updateTransactionID} value={this.state.transactionID}></input>
                                                            <div class="alert-small-text">{this.state.transactionID_Error}</div>
                                                        </div>

                                                        <div className="form-group col-md-6 col-xl-1 field-hightlight">
                                                            <label>Suffix </label>
                                                            <input className="form-control" type="text" ref={this.suffixRef} onChange={this.updateSuffix} maxlength="10" value={this.state.suffix}></input>
                                                            <div className="alert-small-text">{this.state.suffix_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>First Name </label>
                                                            <input className="form-control" type="text" ref={this.firstNameRef} onChange={this.updateFirstName} value={this.state.firstName} maxlength="100"></input>
                                                            <div className="alert-small-text">{this.state.firstName_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Middle Name </label>
                                                            <input className="form-control" type="text" ref={this.middleNameRef} onChange={this.updateMiddleName} value={this.state.middleName} maxlength="100"></input>
                                                            <div className="alert-small-text">{this.state.middleName_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Last Name </label>
                                                            <input className="form-control" type="text" ref={this.lastNameRef} onChange={this.updateLastName} value={this.state.lastName} maxlength="100"></input>
                                                            <div class="alert-small-text">{this.state.lastName_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Tenant Email </label>
                                                            <div className="input-group-append">
                                                                <input type="text" className="form-control" ref={this.tenantEmailRef} onChange={this.updateTenantEmail} maxlength="100" value={this.state.tenantEmail}></input>
                                                            </div>
                                                            <div class="alert-small-text">{this.state.tenantEmail_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Phone Number</label>
                                                            <input className="form-control" type="text" ref={this.phoneRef} onChange={this.updatePhone} maxlength="20" value={this.state.phone}></input>
                                                            <div class="alert-small-text">{this.state.phone_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-1 field-hightlight">
                                                            <label>Age </label>
                                                            <input className="form-control" type="number" name="household" min="0" max="100" ref={this.ageRef} onChange={this.updateAge} value={this.state.age} />
                                                            <div class="alert-small-text">{this.state.age_Error}</div>
                                                        </div>

                                                        <div className="form-group col-md-6 col-xl-3 field-hightlight">
                                                            <label>Address Line 1 </label>
                                                            <input className="form-control" type="text" ref={this.addressLine1Ref} onChange={this.updateAddress} maxlength="30" value={this.state.addressLine1}></input>
                                                            <div className="alert-small-text">{this.state.addressLine1_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Address Line 2 </label>
                                                            <input className="form-control" type="text" ref={this.addressLine2Ref} onChange={this.updateAddress2} maxlength="255" value={this.state.addressLine2}></input>
                                                            <div class="alert-small-text">{this.state.addressLine2_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-1 field-hightlight">
                                                            <label>City </label>
                                                            <input className="form-control" type="text" ref={this.cityRef} onChange={this.updateCity} maxlength="30" value={this.state.city}></input>
                                                            <div class="alert-small-text">{this.state.city_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-1 field-hightlight">
                                                            <label>State </label>
                                                            <input className="form-control" ref={this.stateRef} onChange={this.updateState} value={this.state.state} maxlength="50"></input>
                                                            <div class="alert-small-text">{this.state.state_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-1 field-hightlight">
                                                            <label>Zip </label>
                                                            <input className="form-control" type="text" ref={this.zipcodeRef} onChange={this.updateZip} maxlength="5" value={this.state.zipcode}></input>
                                                            <div class="alert-small-text">{this.state.zipcode_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Birthday Code </label>
                                                            <input className="form-control" type="text" ref={this.birthdayCodeRef} onChange={this.updateBirthdayCode} value={this.state.birthdayCode} maxlength="255"></input>
                                                            <div class="alert-small-text">{this.state.birthdayCode_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Date of Birth </label>
                                                            <input className="form-control" type="date" ref={this.clientDOBRef} onChange={this.updateDOB} value={this.state.clientDOB}></input>
                                                            <div className="alert-small-text">{""}</div>
                                                        </div>

                                                        <div className="form-group col-md-6 col-xl-1 field-hightlight">
                                                            <label>Gender </label>
                                                            <input className="form-control" type="text" onChange={this.updateGender} maxlength="10" value={this.state.gender}></input>
                                                            <div className="alert-small-text">{""}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-1 field-hightlight">
                                                            <label>Race</label>
                                                            <input className="form-control" type="text" onChange={this.updateRace} maxlength="100" value={this.state.race}></input>
                                                            <div class="alert-small-text">{this.state.race_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Ethnicity</label>
                                                            <input className="form-control" type="text" onChange={this.updateEthnicity} maxlength="100" value={this.state.ethnicity}></input>
                                                            <div class="alert-small-text">{this.state.ethnicity_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>Social Security Number</label>
                                                            <input className="form-control" type="text" ref={this.SSNRef} onChange={this.updateSSN} maxlength="10" value={this.state.SSN}></input>
                                                            <div class="alert-small-text">{this.state.SSN_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Social Security First Name </label>
                                                            <input className="form-control" type="text" ref={this.socialSecurityFirstNameRef} onChange={this.updateSocialSecurityFirstName} value={this.state.socialSecurityFirstName} maxlength="100"></input>
                                                            <div className="alert-small-text">{this.state.socialSecurityFirstName_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>Social Security Last Name </label>
                                                            <input className="form-control" type="text" ref={this.socialSecurityLastNameRef} onChange={this.updateSocialSecurityLastName} value={this.state.socialSecurityLastName} maxlength="100"></input>
                                                            <div class="alert-small-text">{this.state.socialSecurityLastName_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>Other Phone </label>
                                                            <input className="form-control" type="text" ref={this.otherPhoneRef} onChange={this.updateOtherPhone} value={this.state.otherPhone} maxlength="20" ></input>
                                                            <div class="alert-small-text">{this.state.otherPhone_Error}</div>
                                                        </div>

                                                        <div class="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>SPA Assignment </label>
                                                            <select className="form-control" ref={this.spaAssignmentNoRef} onChange={this.updateSPAAssignmentNo} value={this.state.spaAssignmentNo}>
                                                                <option value="">-- select --</option>
                                                                <option value="North SPA">North SPA</option>
                                                                <option value="Central SPA">Central SPA</option>
                                                                <option value="South SPA">South SPA</option>
                                                            </select>
                                                            <div class="alert-small-text">{this.state.spaAssignmentNo_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>SPA Service Provider Email </label>
                                                            <input className="form-control" maxlength="255" ref={this.spaServiceProviderEmailRef} onChange={this.updateSPAServiceProviderEmail} value={this.state.spaServiceProviderEmail}></input>
                                                            <div class="alert-small-text">{this.state.spaServiceProviderEmail_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Partner First Name </label>
                                                            <input className="form-control" type="text" ref={this.partnerFirstNameRef} onChange={this.updateFirstNameSpouse} maxlength="100" value={this.state.partnerFirstName}></input>
                                                            <div className="alert-small-text">{this.state.partnerFirstName_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>Partner Last Name </label>
                                                            <input className="form-control" type="text" ref={this.partnerLastNameRef} onChange={this.updateLastNameSpouse} maxlength="100" value={this.state.partnerLastName}></input>
                                                            <div class="alert-small-text">{this.state.partnerLastName_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-1">
                                                            <label>Do you rent?</label>
                                                            <input className="form-control" type="text" ref={this.doYouRentRef} onChange={this.updateDoYouRent} maxlength="100" value={this.state.doYouRent}></input>
                                                            <div class="alert-small-text">{this.state.doYouRent_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-3">
                                                            <label>Monthly Household Income</label>
                                                            <Tooltip title="Monthly Household Income before COVID-19. Maximum allowed value is 99999.99.">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="text" ref={this.monthlyIncomeBeforeCovid19Ref} onChange={this.updateIncome} maxlength="8" max="99999.99" value={this.state.monthlyIncomeBeforeCovid19}></input>
                                                            <div class="alert-small-text">{this.state.monthlyIncomeBeforeCovid19_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-3 field-hightlight">
                                                            <label>Current Total Household Income</label>
                                                            <Tooltip title="Maximum allowed value is 9999999.99">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="text" ref={this.currentTotalHouseholdIncomeRef} onChange={this.updateCurrentTotalHouseholdIncome} maxlength="10" value={this.state.currentTotalHouseholdIncome} max="9999999.99"></input>
                                                            <div class="alert-small-text">{this.state.currentTotalHouseholdIncome_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-3">
                                                            <label>Is the Individual Open to Follow-up? </label>
                                                            <select className="form-control" ref={(input) => this.openToFollowUpSelector = input} onChange={this.updateOpenToFollowUp} value={this.state.openToFollowup}>
                                                                <option value="">-- select --</option>
                                                                <option value="Yes">Yes</option>
                                                                <option value="No">No</option>
                                                            </select>
                                                            <div className="alert-small-text">{""}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-3">
                                                            <label>Number of Times Participated</label>
                                                            <Tooltip title="Number of Times Applicant has Participated in Program">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="text" ref={this.noOfTimesParticipatedRef} onChange={this.updateNoOfTimesParticipated} maxlength="2" value={this.state.noOfTimesParticipated}></input>
                                                            <div class="alert-small-text">{this.state.noOfTimesParticipated_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-3 field-hightlight">
                                                            <label>Do you receive any housing subsidy?</label>
                                                            <input className="form-control" type="text" ref={this.receiveAnyHousingSubsidyRef} onChange={this.updateReceiveAnyHousingSubsidy} maxlength="100" value={this.state.receiveAnyHousingSubsidy}></input>
                                                            <div class="alert-small-text">{this.state.receiveAnyHousingSubsidy_Error}</div>
                                                        </div>
                                                        {/* <div class="form-group col-md-6 col-xl-3">
                                                                <label>Is annual income less?</label>
                                                                <Tooltip title="Is annual income less than the maximum according to the size of your household?">
                                                                    <i className="fa fa-question-circle ml-1"></i>
                                                                </Tooltip>
                                                                <input className="form-control" type="text" ref={this.isAnnualIncomeLessRef} onChange={this.updateIsAnnualIncomeLess} maxlength="100" value={this.state.isAnnualIncomeLess}></input>
                                                                <div class="alert-small-text">{this.state.isAnnualIncomeLess_Error}</div>
                                                            </div> */}
                                                        <div class="form-group col-md-6 col-xl-3">
                                                            <label>Is someone on rental/lease agreement?</label>
                                                            <Tooltip title="Is someone in your household on the rental or lease agreement?">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="text" ref={this.isSomeoneOnRentalOrLeaseAgreementRef} onChange={this.updateIsSomeoneOnRentalOrLeaseAgreement} maxlength="100" value={this.state.isSomeoneOnRentalOrLeaseAgreement}></input>
                                                            <div class="alert-small-text">{this.state.isSomeoneOnRentalOrLeaseAgreement_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-3">
                                                            <label>Filed a declaration of COVID-19?</label>
                                                            <Tooltip title="Have you filed a declaration of COVID-19 related financial stress?">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="text" ref={this.filedCovid19FinancialStressDeclarationRef} onChange={this.updateFiledCovid19FinancialStressDeclaration} maxlength="100" value={this.state.filedCovid19FinancialStressDeclaration}></input>
                                                            <div class="alert-small-text">{this.state.filedCovid19FinancialStressDeclaration_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-3 field-hightlight">
                                                            <label>Received an eviction notice?</label>
                                                            <input className="form-control" type="text" ref={this.receivedEvictionNoticeRef} onChange={this.updateReceivedEvictionNotice} maxlength="100" value={this.state.receivedEvictionNotice}></input>
                                                            <div class="alert-small-text">{this.state.receivedEvictionNotice_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-3 field-hightlight">
                                                            <label>Eviction notice received date </label>
                                                            <input className="form-control" type="date" ref={this.dateEvictionNoticeReceivedRef} onChange={this.updateDateEvictionNoticeReceived} value={this.state.dateEvictionNoticeReceived}></input>
                                                            <div className="alert-small-text">{this.state.dateEvictionNoticeReceived_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>Secondary First Name </label>
                                                            <input className="form-control" type="text" ref={this.secondaryFirstNameRef} onChange={this.updateSecondaryFirstName} value={this.state.secondaryFirstName} maxlength="100"></input>
                                                            <div className="alert-small-text">{this.state.secondaryFirstName_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>Secondary Last Name </label>
                                                            <input className="form-control" type="text" ref={this.secondaryLastNameRef} onChange={this.updateSecondaryLastName} value={this.state.secondaryLastName} maxlength="100"></input>
                                                            <div class="alert-small-text">{this.state.secondaryLastName_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-3">
                                                            <label>Secondary Email</label>
                                                            <input className="form-control" type="text" ref={this.secondaryEmailRef} onChange={this.updateSecondaryEmail} value={this.state.secondaryEmail} maxlength="50" ></input>
                                                            <div class="alert-small-text">{this.state.secondaryEmail_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-3">
                                                            <label>Secondary Phone </label>
                                                            <input className="form-control" type="text" ref={this.secondaryPhoneRef} onChange={this.updateSecondaryPhone} value={this.state.secondaryPhone} maxlength="20" ></input>
                                                            <div class="alert-small-text">{this.state.secondaryPhone_Error}</div>
                                                        </div>

                                                        <div class="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Below 30% AMI </label>
                                                            <select className="form-control" onChange={this.updateBelowThirtyPercAMI} value={this.state.belowThirtyPercAMI}>
                                                                <option value="">-- select --</option>
                                                                <option value="Yes">Yes</option>
                                                                <option value="No">No</option>
                                                            </select>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>30%-50% AMI </label>
                                                            <select className="form-control" onChange={this.updateThirtyToFiftyPercAMI} value={this.state.thirtyToFiftyPercAMI}>
                                                                <option value="">-- select --</option>
                                                                <option value="Yes">Yes</option>
                                                                <option value="No">No</option>
                                                            </select>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>50%-80% AMI </label>
                                                            <select className="form-control" onChange={this.updateFiftyToEightyPercAMI} value={this.state.fiftyToEightyPercAMI}>
                                                                <option value="">-- select --</option>
                                                                <option value="Yes">Yes</option>
                                                                <option value="No">No</option>
                                                            </select>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>Board District </label>
                                                            <select className="form-control" onChange={this.updateBoardDistrict} value={this.state.boardDistrict}>
                                                                <option value="">-- select --</option>
                                                                <option value="1">1</option>
                                                                <option value="2">2</option>
                                                                <option value="3">3</option>
                                                                <option value="4">4</option>
                                                                <option value="5">5</option>
                                                            </select>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>Length of time for Award </label>
                                                            <Tooltip title="Calculated based on Date Applied and Date on which the rent payment status updated to Issued">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="text" readOnly onChange={this.updateLengthOfTimeForAward} value={this.state.lengthOfTimeForAward} ></input>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="box-gray mb-4">
                                                <h2 className="profile-title"><i class="fas fa-file-alt mr-2"></i>Other Details</h2>
                                                <div className="box-details">
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6 col-xl-3">
                                                            <label>Financial effect </label>
                                                            <Tooltip title="How did COVID-19 financially affect you or your household?">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <select className="form-control" onChange={this.checkaffect} ref={(input) => this.effected = input} onChange={this.updateFinancialEffect} value={this.state.effectOfCovid19Financially}>
                                                                <option value="">-- select --</option>
                                                                <option value="Furloughed due to pandemic">Furloughed due to pandemic</option>
                                                                <option value="Laid off due to pandemic">Laid off due to pandemic</option>
                                                                <option value="Underemployed or significant reduction in work hours">Underemployed or significant reduction in work hours</option>
                                                                <option value="Not Affected">Not Affected</option>
                                                                <option value="Other">Other</option>
                                                            </select>
                                                            <div className="alert-small-text">{""}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2" style={{ display: (this.state.effectOfCovid19Financially === 'Other') ? 'block' : 'none' }}>
                                                            <label>Enter the other financial effect</label>
                                                            <input className="form-control" ref={this.covid_otherRef} onChange={this.updateCovidOther} value={this.state.covid_other}></input>
                                                            <div class="alert-small-text">{this.state.covid_other_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-3">
                                                            <label>Are you eligible to legally work in the US? </label>
                                                            <select className="form-control" ref={(input) => this.workSelector = input} onChange={this.updateLegallyAbleToWork} value={this.state.legallyAbleToWork}>
                                                                <option value="">-- select --</option>
                                                                <option value="Yes">Yes</option>
                                                                <option value="No">No</option>
                                                            </select>
                                                            <div className="alert-small-text">{""}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>Work Industry? </label>
                                                            <select className="form-control" ref={(input) => this.industrySelector = input} onChange={this.updateWorkIndustry} value={this.state.workIndustry}>
                                                                <option value="">-- select --</option>
                                                                <option value="Food Service (traditional food service & food delivery)">Food Service (traditional food service & food delivery)</option>
                                                                <option value="Hospitality">Hospitality</option>
                                                                <option value="Amusement Parks">Amusement Parks</option>
                                                                <option value="Retail">Retail</option>
                                                                <option value="Self-employed (free lance, uber/lyft drivers, etc.)">Self-employed (free lance, uber/lyft drivers, etc.)</option>
                                                                <option value="Other">Other</option>
                                                            </select>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2" style={{ display: (this.state.workIndustry === 'Other') ? 'block' : 'none' }}>
                                                            <label>Enter the other industry</label>
                                                            <input className="form-control" ref={this.industry_otherRef} onChange={this.updateIndustryOther} value={this.state.industry_other}></input>
                                                            <div className="alert-small-text">{this.state.industry_other_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-4 field-hightlight">
                                                            <label>Are you or anyone in your household a veteran? </label>
                                                            <select className="form-control" ref={(input) => this.veteranSelector = input} onChange={this.updateVeteran} value={this.state.veteran}>
                                                                <option value="">-- select --</option>
                                                                <option value="Yes">Yes</option>
                                                                <option value="No">No</option>
                                                                <option value="Client refused">Client refused</option>
                                                                <option value="Client doesn’t know">Client doesn’t know</option>
                                                            </select>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-3">
                                                            <label>Are You eligible for stimulus funds? </label>
                                                            <select className="form-control" ref={(input) => this.stimulusSelector = input} onChange={this.updateStimulus} value={this.state.eligibleForStimulusFunds}>
                                                                <option value="">-- select --</option>
                                                                <option value="Yes">Yes</option>
                                                                <option value="No">No</option>
                                                            </select>
                                                            <div className="alert-small-text">{""}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-3">
                                                            <label>Employment Status? </label>
                                                            <select className="form-control" ref={(input) => this.employmentSelector = input} onChange={this.updateEmploymentStatus} value={this.state.employmentStatus}>
                                                                <option value="">-- select --</option>
                                                                <option value="Employed">Employed</option>
                                                                <option value="Unemployed">Unemployed</option>
                                                            </select>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Household Size? </label>
                                                            <input className="form-control" type="number" name="household" min="1" max="100" ref={this.householdNumberRef} onChange={this.updateHouseHold} value={this.state.householdNumber} />
                                                            <div class="alert-small-text">{this.state.householdNumber_Error}</div>
                                                        </div>
                                                        {/* <div class="form-group col-md-6 col-xl-1">
                                                                <label>Age 0-5 </label>
                                                                <input className="form-control" type="number" name="household" min="0" max="100" ref={this.age0to5Ref} onChange={this.updateHouseHold1} value={this.state.age0to5} />
                                                                <div class="alert-small-text">{this.state.age0to5_Error}</div>
                                                            </div>
                                                            <div class="form-group col-md-6 col-xl-1">
                                                                <label>Age 6-17 </label>
                                                                <input className="form-control" type="number" name="household" min="0" max="100" ref={this.age6to17Ref} onChange={this.updateHouseHold2} value={this.state.age6to17} />
                                                                <div class="alert-small-text">{this.state.age6to17_Error}</div>
                                                            </div>
                                                            <div class="form-group col-md-6 col-xl-1">
                                                                <label>Age 18-59 </label>
                                                                <input className="form-control" type="number" name="household" min="0" max="100" ref={this.age18to59Ref} onChange={this.updateHouseHold3} value={this.state.age18to59} />
                                                                <div class="alert-small-text">{this.state.age18to59_Error}</div>
                                                            </div>
                                                            <div class="form-group col-md-6 col-xl-1">
                                                                <label>Age 60+ </label>
                                                                <input className="form-control" type="number" name="household" min="0" max="100" ref={this.age60andUpRef} onChange={this.updateHouseHold4} value={this.state.age60andUp} />
                                                                <div class="alert-small-text">{this.state.age60andUp_Error}</div>
                                                            </div> */}
                                                        <div class="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Count Adults </label>
                                                            <input className="form-control" type="number" name="household" min="0" max="100" ref={this.countAdultRef} onChange={this.updateCountAdult} value={this.state.countAdult} />
                                                            <div class="alert-small-text">{this.state.countAdult_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Count Children </label>
                                                            <input className="form-control" type="number" name="household" min="0" max="100" ref={this.countChildrenRef} onChange={this.updateCountChildren} value={this.state.countChildren} />
                                                            <div class="alert-small-text">{this.state.countChildren_Error}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="box-gray mb-4">
                                                {/* <h2 className="profile-title"><i class="fas fa-house-user mr-2"></i>Landlord Details</h2> */}

                                                <h2 class="profile-title d-md-flex align-items-center justify-content-between">
                                                    <span><i class="fas fa-house-user mr-2"></i>Landlord Details</span>
                                                    {this.state.landlordEmail !== "" && this.state.landlordEmail !== null && this.state.landlordEmail !== undefined ?
                                                        (
                                                            <span class="property-grid-add d-flex">
                                                                <button type="button" class="btn btn-primary btn-sm my-2 my-md-0" onClick={this.requestLandlordInfo} style={{ display: (this.state.requestedLandlordInfo === true || this.state.requestedLandlordInfo === 1) ? 'none' : 'block' }} id="requestLandlordInfoId">Request information</button>
                                                                <label class="my-2 pt-2" style={{ fontSize: '14px', color: '#000', textTransform: 'initial', fontWeight: '500' }} style={{ display: (this.state.requestedLandlordInfo === true || this.state.requestedLandlordInfo === 1) ? 'block' : 'none' }} id="requestDateId" >Date Requested: {this.state.dateLandlordInfoRequested_Formatted + ' ' + this.state.timeLandlordInfoRequested}</label>
                                                                <label class="my-2 ml-3 mr-2 pt-2" style={{ fontSize: '14px', color: '#000', textTransform: 'initial', fontWeight: '500' }} style={{ display: ((this.state.requestedLandlordInfo === true || this.state.requestedLandlordInfo === 1) && (this.state.dateLandlordInfoReminded != '0000-00-00' && this.state.dateLandlordInfoReminded != '1000-01-01' && this.state.timeLandlordInfoReminded != null)) ? 'block' : 'none' }} id="remindedDateId" >Date Reminded: {this.state.dateLandlordInfoReminded_Formatted + ' ' + this.state.timeLandlordInfoReminded}</label>
                                                                <button type="button" class="btn btn-primary btn-sm ml-2 my-2 my-md-0" onClick={this.sendReminderToLandlord} style={{ display: (this.state.requestedLandlordInfo === true || this.state.requestedLandlordInfo === 1) ? 'block' : 'none' }} id="sendReminderToLandlordId">Send Reminder</button>
                                                            </span>
                                                        ) :
                                                        (
                                                            // Nothing to show here
                                                            <span></span>
                                                        )
                                                    }
                                                </h2>

                                                <div className="box-details">
                                                    <div className="form-row">
                                                        <div class="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Landlord Payee Full Name</label>
                                                            <Tooltip title="please fillout name on bank account (landlord or property manager)">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="text" ref={this.payeeRef} onChange={this.updatePayee} value={this.state.payee} maxlength="100" ></input>
                                                            <div class="alert-small-text">{this.state.payee_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Landlord Email</label>
                                                            <input className="form-control" type="text" ref={this.landlordEmailRef} onChange={this.updateLandlordEmail} value={this.state.landlordEmail} maxlength="50" ></input>
                                                            <div class="alert-small-text">{this.state.landlordEmail_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Landlord Phone Number </label>
                                                            <input className="form-control" type="text" ref={this.landlordPhoneNumberRef} onChange={this.updateLandlordPhone} value={this.state.landlordPhoneNumber} maxlength="20" ></input>
                                                            <div class="alert-small-text">{this.state.landlordPhoneNumber_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Amount Due for Rent</label>
                                                            <input className="form-control" type="text" ref={this.amountDueforRentRef} onChange={this.updateDollarAmountDueForRent} value={this.state.amountDueforRent} maxlength="10" ></input>
                                                            <div class="alert-small-text">{this.state.amountDueforRent_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Rent Amount Covered</label>
                                                            <Tooltip title="$ amount approved for payout.">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="text" ref={this.rentAmountRef} onChange={this.updateAmount} value={this.state.rentAmount} maxlength="100" ></input>
                                                            <div class="alert-small-text">{this.state.rentAmount_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>Award % of total rent due</label>
                                                            <Tooltip title="Calculated based on Amount Due for Rent and Rent Amount Covered">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="text" ref={this.percentageOfMonthlyRentRef} onChange={this.updatePercentageOfMonthlyRent} value={this.state.percentageOfMonthlyRent} readOnly maxlength="2" ></input>
                                                            <div class="alert-small-text">{this.state.percentageOfMonthlyRent_Error}</div>
                                                        </div>

                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>Landlord Bank Name</label>
                                                            <input className="form-control" type="text" onChange={this.updateLandlordBankName} value={this.state.landlordBankName} maxlength="100" ></input>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>Landlord Account Number</label>
                                                            <Tooltip title="If applicable: Bank account number. Monies will be deposited into this account.">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="text" ref={this.landlordAccountNumberRef} onChange={this.updateLandlordAccountNumber} value={this.state.landlordAccountNumber} maxlength="20" ></input>
                                                            <div class="alert-small-text">{this.state.landlordAccountNumber_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>Landlord Routing Number</label>
                                                            <Tooltip title="If applicable: Bank electronic routing number">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="text" ref={this.landlordRoutingNumberRef} onChange={this.updateLandlordRoutingNumber} value={this.state.landlordRoutingNumber} maxlength="10" ></input>
                                                            <div class="alert-small-text">{this.state.landlordRoutingNumber_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>Landlord SSN</label>
                                                            <Tooltip title="If applicable: SSN is a nine-digit number issued to U.S. citizens, permanent residents, and temporary residents.">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="text" ref={this.landlordSSNRef} onChange={this.updateLandlordSSN} value={this.state.landlordSSN} maxlength="10" ></input>
                                                            <div class="alert-small-text">{this.state.landlordSSN_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>Landlord Tax I.D.</label>
                                                            <Tooltip title="Must Include if Applicable. A Tax Identification Number is a nine-digit number, can be a social security number or employer identification number. Typically used if landlord is a sole proprietor.">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="text" ref={this.landlordTaxIDRef} onChange={this.updateLandlordTaxID} value={this.state.landlordTaxID} maxlength="10" ></input>
                                                            <div class="alert-small-text">{this.state.landlordTaxID_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>Landlord DUNS Number</label>
                                                            <Tooltip title="Must Include if Applicable. The Dun & Bradstreet DUNS Number is a unique nine-digit identifier for businesses.">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="text" ref={this.landlordDUNSNumberRef} onChange={this.updateLandlordDUNSNumber} value={this.state.landlordDUNSNumber} maxlength="10" ></input>
                                                            <div class="alert-small-text">{this.state.landlordDUNSNumber_Error}</div>
                                                        </div>

                                                        <div class="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Monthly Rent</label>
                                                            <Tooltip title="Maximum allowed value is 99999.99">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="text" ref={this.monthlyRentRef} onChange={this.updateMonthlyRent} value={this.state.monthlyRent} maxlength="8" max="99999.99"></input>
                                                            <div class="alert-small-text">{this.state.monthlyRent_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label># of Month of Rental Payment</label>
                                                            <input className="form-control" type="text" ref={this.noOfMonthsRentalPaymentsRef} onChange={this.updateNoOfMonthsRentalPayments} value={this.state.noOfMonthsRentalPayments} maxlength="5" ></input>
                                                            <div class="alert-small-text">{this.state.noOfMonthsRentalPayments_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>Memo - Property Address</label>
                                                            <input className="form-control" type="text" onChange={this.updatePropertyAddress} value={this.state.memoPropertyAddress} maxlength="255" ></input>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Rent Type of Assistance </label>
                                                            <select className="form-control" ref={(input) => this.rentTypeAssistanceSelector = input} onChange={this.updateRentTypeOfAssistance} value={this.state.rentTypeOfAssistance}>
                                                                <option value="">-- select --</option>
                                                                <option value="Rent">Rent</option>
                                                                <option value="Rental Arrears">Rental Arrears</option>
                                                            </select>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Arrear Amount</label>
                                                            <Tooltip title="Outstanding Rental Arrears Amount. Maximum allowed value is 99999.99.">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="text" ref={this.outstandingRentalArrearsAmountRef} onChange={this.updateOutstandingRentalArrears} value={this.state.outstandingRentalArrearsAmount} maxlength="8" max="99999.99" ></input>
                                                            <div class="alert-small-text">{this.state.outstandingRentalArrearsAmount_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Rent Payment Status </label>
                                                            <select className="form-control" id="rentPaymentStatusId" ref={this.rentPaymentStatusRef} onChange={this.updateRentPaymentStatus} value={this.state.rentPaymentStatus}>
                                                                <option value="">-- select --</option>
                                                                <option value="Pending">Pending</option>
                                                                <option value="In-Review">In-Review</option>
                                                                <option value="Approved for Payment">Approved for Payment</option>
                                                                <option value="N/A">N/A</option>
                                                                {/* <option value="Issued">Issued</option> */}
                                                            </select>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>

                                                        <div class="form-group col-md-6 col-xl-3" style={{ display: (this.state.rentPaymentStatus_Original === 'Issued') ? 'block' : 'none' }}>
                                                            <label>Timestamp of Issued Notification</label>
                                                            <input className="form-control" type="text" readOnly onChange={this.updateTimestampOfRentIssued} value={this.state.timestampOfRentIssued} ></input>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-3">
                                                            <label>Landlord First Attempt (21 Days)</label>
                                                            <input className="form-control" type="date" onChange={this.updateLandlordFirstAttempt} value={this.state.landlordFirstAttempt}></input>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                        {/* <div class="form-group col-md-6 col-xl-3">
                                                                <label>Landlord Response (21 Days)</label>
                                                                <input className="form-control" type="date" onChange={this.updateLandlordResponse} value={this.state.landlordResponse}></input>
                                                                <div class="alert-small-text">{""}</div>
                                                            </div> */}
                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>Reason Rent Payment Status N/A </label>
                                                            <Tooltip title="Reason of payment not processed in 21 days">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <select className="form-control" onChange={this.updateReasonPaymentNotProcessedIn21Days} ref={this.reasonPaymentNotProcessedIn21DaysRef} value={this.state.reasonPaymentNotProcessedIn21Days}>
                                                                <option value="">-- select --</option>
                                                                <option value="Refused to accept">Refused to accept</option>
                                                                <option value="No payment requested">No payment requested</option>
                                                                <option value="No response in 21 days from landlord">No response in 21 days from landlord</option>
                                                                <option value="Tenant Request to Withdraw">Tenant Request to Withdraw</option>
                                                                <option value="Anonymous file transfer">Anonymous file transfer</option>
                                                                <option value="Pending service provider response">Pending service provider response</option>
                                                                <option value="Application cancelled by Service Provider">Application cancelled by Service Provider</option>
                                                            </select>
                                                            <div class="alert-small-text">{this.state.reasonPaymentNotProcessedIn21Days_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2">
                                                            <label>1099 Total</label>
                                                            <Tooltip title="Sum of 'Rent Amount Covered' and 'reconciliation Amount'">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="text" readOnly value={this.state.total1099Amount} ></input>
                                                            {/* <div class="alert-small-text">{this.state.total1099Amount_Error}</div> */}
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label></label>
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" id="landlordACHReceivedId" class="custom-control-input" onChange={this.onLandlordACHReceivedChange} />
                                                                <label class="custom-control-label" for="landlordACHReceivedId">
                                                                    ACH Received
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="box-gray mb-4">
                                                <h2 className="profile-title"><i class="fas fa-bolt mr-2"></i>Electric Details</h2>
                                                <div className="box-details">
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Electric Provider </label>
                                                            <input className="form-control" onChange={this.updateElectricProvider} value={this.state.electricProvider}></input>
                                                            <div className="alert-small-text">{""}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Electric Utility Account # </label>
                                                            <input className="form-control" ref={this.electricUtilityAccountNoRef} onChange={this.updateElectricUtilityAccountNumber} value={this.state.electricUtilityAccountNo}></input>
                                                            <div class="alert-small-text">{this.state.electricUtilityAccountNo_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>Billing Account Number </label>
                                                            <Tooltip title="Customer's Electric Utility Billing Account Number">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" ref={this.custElectricBillingAccountNoRef} onChange={this.updateElectricBillingAccountNumber} value={this.state.custElectricBillingAccountNo}></input>
                                                            <div class="alert-small-text">{this.state.custElectricBillingAccountNo_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Monthly Electric Bill Amount</label>
                                                            <Tooltip title="Maximum allowed value is 99999.99">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="text" ref={this.monthlyElectricBillAmountRef} onChange={this.updateMonthlyElectricBillAmount} value={this.state.monthlyElectricBillAmount} maxlength="8" max="99999.99" ></input>
                                                            <div class="alert-small-text">{this.state.monthlyElectricBillAmount_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-4 field-hightlight">
                                                            <label># of Month of Electric Utility Payments</label>
                                                            <input className="form-control" type="text" ref={this.noOfMonthsElectricUtilityPaymentsRef} onChange={this.updateNoOfMonthsElectricUtilityPayments} value={this.state.noOfMonthsElectricUtilityPayments} maxlength="2" ></input>
                                                            <div class="alert-small-text">{this.state.noOfMonthsElectricUtilityPayments_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Electric Type of Assistance </label>
                                                            <select className="form-control" ref={(input) => this.electricTypeOfAssistanceSelector = input} onChange={this.updateElectricTypeOfAssistance} value={this.state.electricTypeOfAssistance}>
                                                                <option value="">-- select --</option>
                                                                <option value="Payment">Payment</option>
                                                                <option value="Payment Arrears">Payment Arrears</option>
                                                            </select>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Amount Due for Electricity </label>
                                                            <input className="form-control" ref={this.amountDueforElectricityRef} onChange={this.updateDollarAmountDueForElectricity} value={this.state.amountDueforElectricity}></input>
                                                            <div className="alert-small-text">{this.state.amountDueforElectricity_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Electric Amount Covered </label>
                                                            <Tooltip title="$ amount approved for payout.">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" ref={this.electricAmountRef} onChange={this.updateElectricAmount} value={this.state.electricAmount}></input>
                                                            <div class="alert-small-text">{this.state.electricAmount_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>% of Electric Bill</label>
                                                            <Tooltip title="Calculated based on Amount Due for Electricity and Electric Amount Covered">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="text" ref={this.percentageOfElectricBillRef} onChange={this.updatePercentageOfElectricBill} value={this.state.percentageOfElectricBill} readOnly maxlength="2" ></input>
                                                            <div class="alert-small-text">{this.state.percentageOfElectricBill_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>Electric Bank Name </label>
                                                            <input className="form-control" onChange={this.updateElectricBankName} value={this.state.electricBankName}></input>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>Banking Account Number </label>
                                                            <Tooltip title="Electric Utility Provider's Banking Account Number">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" ref={this.electricUtilityProviderAccountNoRef} onChange={this.updateElectricityProvidersBankingAccountNumber} value={this.state.electricUtilityProviderAccountNo}></input>
                                                            <div class="alert-small-text">{this.state.electricUtilityProviderAccountNo_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>Electric Routing Number </label>
                                                            <input className="form-control" type="text" ref={this.electricRoutingNumberRef} onChange={this.updateElectricRoutingNumber} value={this.state.electricRoutingNumber} maxlength="10" ></input>
                                                            <div class="alert-small-text">{this.state.electricRoutingNumber_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-3">
                                                            <label>Electric Memo - Account Number</label>
                                                            <input className="form-control" type="text" ref={this.electricMemoAccountNoRef} onChange={this.updateElectricMemoAccountNumber} value={this.state.electricMemoAccountNo} maxlength="100" ></input>
                                                            <div class="alert-small-text">{this.state.electricMemoAccountNo_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>Electric Utility Tax I.D.</label>
                                                            <Tooltip title="Must Include if Applicable">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="text" ref={this.electricUtilityTaxIDRef} onChange={this.updateElectricUtilityTaxID} value={this.state.electricUtilityTaxID} maxlength="10" ></input>
                                                            <div class="alert-small-text">{this.state.electricUtilityTaxID_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-3">
                                                            <label>Electric Utility DUNS Number</label>
                                                            <Tooltip title="Must Include if Applicable">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="text" ref={this.electricUtilityDUNSNumberRef} onChange={this.updateElectricUtilityDUNSNumber} value={this.state.electricUtilityDUNSNumber} maxlength="10" ></input>
                                                            <div class="alert-small-text">{this.state.electricUtilityDUNSNumber_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Electric Payment Status </label>
                                                            <select className="form-control" id="electricPaymentStatusId" ref={(input) => this.electricPaymentStatusSelector = input} onChange={this.updateElectricPaymentStatus} value={this.state.electricPaymentStatus}>
                                                                <option value="">-- select --</option>
                                                                <option value="Pending">Pending</option>
                                                                <option value="In-Review">In-Review</option>
                                                                <option value="Approved for Payment">Approved for Payment</option>
                                                                <option value="N/A">N/A</option>
                                                                {/* <option value="Issued">Issued</option> */}
                                                            </select>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="box-gray mb-4">
                                                <h2 className="profile-title"><i class="fas fa-burn mr-2"></i>Gas Details</h2>
                                                <div className="box-details">
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Gas Provider </label>
                                                            <input className="form-control" onChange={this.updateGasProvider} value={this.state.gasProvider}></input>
                                                            <div className="alert-small-text">{""}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Gas Utility Account # </label>
                                                            <input className="form-control" ref={this.gasUtilityAccountNoRef} onChange={this.updateGasUtilityAccountNumber} value={this.state.gasUtilityAccountNo}></input>
                                                            <div class="alert-small-text">{this.state.gasUtilityAccountNo_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>Billing Account Number </label>
                                                            <Tooltip title="Customer's Gas Utility Billing Account Number">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" ref={this.custGasBillingAccountNoRef} onChange={this.updateGasBillingAccountNumber} value={this.state.custGasBillingAccountNo}></input>
                                                            <div class="alert-small-text">{this.state.custGasBillingAccountNo_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Monthly Gas Bill Amount </label>
                                                            <Tooltip title="Maximum allowed value is 99999.99">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" ref={this.monthlyGasBillAmountRef} maxlength="8" max="99999.99" onChange={this.updateMonthlyGasBillAmount} value={this.state.monthlyGasBillAmount}></input>
                                                            <div class="alert-small-text">{this.state.monthlyGasBillAmount_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-4 field-hightlight">
                                                            <label># of Month of Gas Utility Payments</label>
                                                            <input className="form-control" type="text" ref={this.noOfMonthsGasUtilityPaymentsRef} onChange={this.updateNoOfMonthsGasUtilityPayments} value={this.state.noOfMonthsGasUtilityPayments} maxlength="2" ></input>
                                                            <div class="alert-small-text">{this.state.noOfMonthsGasUtilityPayments_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Gas Type of Assistance </label>
                                                            <select className="form-control" ref={(input) => this.gasTypeOfAssistanceSelector = input} onChange={this.updateGasTypeOfAssistance} value={this.state.gasTypeOfAssistance}>
                                                                <option value="">-- select --</option>
                                                                <option value="Payment">Payment</option>
                                                                <option value="Payment Arrears">Payment Arrears</option>
                                                            </select>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Amount Due for Gas </label>
                                                            <input className="form-control" ref={this.amountDueforGasRef} onChange={this.updateDollarAmountDueForGas} value={this.state.amountDueforGas}></input>
                                                            <div className="alert-small-text">{this.state.amountDueforGas_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Gas Amount Covered </label>
                                                            <Tooltip title="$ amount approved for payout.">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" ref={this.gasAmountRef} onChange={this.updateGasAmount} value={this.state.gasAmount}></input>
                                                            <div class="alert-small-text">{this.state.gasAmount_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>% of Gas Bill </label>
                                                            <Tooltip title="Calculated based on Amount Due for Gas and Gas Amount Covered">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" ref={this.percentageOfGasBillRef} readOnly maxlength="2" onChange={this.updatePercentageOfGasBill} value={this.state.percentageOfGasBill}></input>
                                                            <div class="alert-small-text">{this.state.percentageOfGasBill_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>Gas Bank Name </label>
                                                            <input className="form-control" onChange={this.updateGasBankName} value={this.state.gasBankName}></input>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>Banking Account Number </label>
                                                            <Tooltip title="Gas Utility Provider's Banking Account Number">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" ref={this.gasUtilityProviderAccountNoRef} onChange={this.updateGasProvidersBankingAccountNumber} value={this.state.gasUtilityProviderAccountNo}></input>
                                                            <div class="alert-small-text">{this.state.gasUtilityProviderAccountNo_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>Gas Routing Number </label>
                                                            <input className="form-control" type="text" ref={this.gasRoutingNumberRef} onChange={this.updateGasRoutingNumber} value={this.state.gasRoutingNumber} maxlength="10" ></input>
                                                            <div class="alert-small-text">{this.state.gasRoutingNumber_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-3">
                                                            <label>Gas Memo - Account Number</label>
                                                            <input className="form-control" type="text" ref={this.gasMemoAccountNoRef} onChange={this.updateGasMemoAccountNumber} value={this.state.gasMemoAccountNo} maxlength="100" ></input>
                                                            <div class="alert-small-text">{this.state.gasMemoAccountNo_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>Gas Utility Tax I.D. </label>
                                                            <Tooltip title="Must Include if Applicable">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="text" ref={this.gasUtilityTaxIDRef} onChange={this.updateGasUtilityTaxID} value={this.state.gasUtilityTaxID} maxlength="10" ></input>
                                                            <div class="alert-small-text">{this.state.gasUtilityTaxID_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-3">
                                                            <label>Gas Utility DUNS Number </label>
                                                            <Tooltip title="Must Include if Applicable">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="text" ref={this.gasUtilityDUNSNumberRef} onChange={this.updateGasUtilityDUNSNumber} value={this.state.gasUtilityDUNSNumber} maxlength="10" ></input>
                                                            <div class="alert-small-text">{this.state.gasUtilityDUNSNumber_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Gas Payment Status </label>
                                                            <select className="form-control" id="gasPaymentStatusId" ref={(input) => this.gasPaymentStatusSelector = input} onChange={this.updateGasPaymentStatus} value={this.state.gasPaymentStatus}>
                                                                <option value="">-- select --</option>
                                                                <option value="Pending">Pending</option>
                                                                <option value="In-Review">In-Review</option>
                                                                <option value="Approved for Payment">Approved for Payment</option>
                                                                <option value="N/A">N/A</option>
                                                                {/* <option value="Issued">Issued</option> */}
                                                            </select>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="box-gray mb-4">
                                                <h2 className="profile-title"><i class="fas fa-water mr-2"></i>Water/Sewer Details</h2>
                                                <div className="box-details">
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Water Provider </label>
                                                            <input className="form-control" onChange={this.updateWaterProvider} value={this.state.waterProvider}></input>
                                                            <div className="alert-small-text">{""}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Water Utility Account # </label>
                                                            <input className="form-control" ref={this.waterUtilityAccountNoRef} onChange={this.updateWaterUtilityAccountNumber} value={this.state.waterUtilityAccountNo}></input>
                                                            <div class="alert-small-text">{this.state.waterUtilityAccountNo_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>Billing Account Number </label>
                                                            <Tooltip title="Customer's Water Utility Billing Account Number">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" ref={this.custWaterBillingAccountNoRef} onChange={this.updateWaterBillingAccountNumber} value={this.state.custWaterBillingAccountNo}></input>
                                                            <div class="alert-small-text">{this.state.custWaterBillingAccountNo_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Monthly Water Bill Amount </label>
                                                            <Tooltip title="Maximum allowed value is 99999.99">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" ref={this.monthlyWaterBillAmountRef} maxlength="8" max="99999.99" onChange={this.updateMonthlyWaterBillAmount} value={this.state.monthlyWaterBillAmount}></input>
                                                            <div class="alert-small-text">{this.state.monthlyWaterBillAmount_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-4 field-hightlight">
                                                            <label># of Month of Water Utility Payments</label>
                                                            <input className="form-control" type="text" ref={this.noOfMonthsWaterUtilityPaymentsRef} onChange={this.updateNoOfMonthsWaterUtilityPayments} value={this.state.noOfMonthsWaterUtilityPayments} maxlength="2" ></input>
                                                            <div class="alert-small-text">{this.state.noOfMonthsWaterUtilityPayments_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Water Type of Assistance </label>
                                                            <select className="form-control" ref={(input) => this.waterTypeOfAssistanceSelector = input} onChange={this.updateWaterTypeOfAssistance} value={this.state.waterTypeOfAssistance}>
                                                                <option value="">-- select --</option>
                                                                <option value="Payment">Payment</option>
                                                                <option value="Payment Arrears">Payment Arrears</option>
                                                            </select>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Amount Due for Water </label>
                                                            <input className="form-control" ref={this.amountDueforWaterRef} onChange={this.updateDollarAmountDueForWater} value={this.state.amountDueforWater}></input>
                                                            <div className="alert-small-text">{this.state.amountDueforWater_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Water Amount Covered </label>
                                                            <Tooltip title="$ amount approved for payout.">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" ref={this.waterAmountRef} onChange={this.updateWaterAmount} value={this.state.waterAmount}></input>
                                                            <div class="alert-small-text">{this.state.waterAmount_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>% of Water Bill </label>
                                                            <Tooltip title="Calculated based on Amount Due for Water and Water Amount Covered">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" ref={this.percentageOfWaterBillRef} readOnly maxlength="2" onChange={this.updatePercentageOfWaterBill} value={this.state.percentageOfWaterBill}></input>
                                                            <div class="alert-small-text">{this.state.percentageOfWaterBill_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>Water Bank Name </label>
                                                            <input className="form-control" onChange={this.updateWaterBankName} value={this.state.waterBankName}></input>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>Banking Account Number </label>
                                                            <Tooltip title="Water Utility Provider's Banking Account Number">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" ref={this.waterUtilityProviderAccountNoRef} onChange={this.updateWaterProvidersBankingAccountNumber} value={this.state.waterUtilityProviderAccountNo}></input>
                                                            <div class="alert-small-text">{this.state.waterUtilityProviderAccountNo_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>Water Routing Number </label>
                                                            <input className="form-control" type="text" ref={this.waterRoutingNumberRef} onChange={this.updateWaterRoutingNumber} value={this.state.waterRoutingNumber} maxlength="10" ></input>
                                                            <div class="alert-small-text">{this.state.waterRoutingNumber_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-3">
                                                            <label>Water Memo - Account Number</label>
                                                            <input className="form-control" type="text" ref={this.waterMemoAccountNoRef} onChange={this.updateWaterMemoAccountNumber} value={this.state.waterMemoAccountNo} maxlength="100" ></input>
                                                            <div class="alert-small-text">{this.state.waterMemoAccountNo_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>Water Utility Tax I.D. </label>
                                                            <Tooltip title="Must Include if Applicable">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="text" ref={this.waterUtilityTaxIDRef} onChange={this.updateWaterUtilityTaxID} value={this.state.waterUtilityTaxID} maxlength="10" ></input>
                                                            <div class="alert-small-text">{this.state.waterUtilityTaxID_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-3">
                                                            <label>Water Utility DUNS Number </label>
                                                            <Tooltip title="Must Include if Applicable">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="text" ref={this.waterUtilityDUNSNumberRef} onChange={this.updateWaterUtilityDUNSNumber} value={this.state.waterUtilityDUNSNumber} maxlength="10" ></input>
                                                            <div class="alert-small-text">{this.state.waterUtilityDUNSNumber_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Water Payment Status </label>
                                                            <select className="form-control" id="waterPaymentStatusId" ref={(input) => this.waterPaymentStatusSelector = input} onChange={this.updateWaterPaymentStatus} value={this.state.waterPaymentStatus}>
                                                                <option value="">-- select --</option>
                                                                <option value="Pending">Pending</option>
                                                                <option value="In-Review">In-Review</option>
                                                                <option value="Approved for Payment">Approved for Payment</option>
                                                                <option value="N/A">N/A</option>
                                                                {/* <option value="Issued">Issued</option> */}
                                                            </select>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="box-gray mb-4">
                                                <h2 className="profile-title"><i class="fas fa-trash mr-2"></i>Trash Details</h2>
                                                <div className="box-details">
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Trash Provider </label>
                                                            <input className="form-control" onChange={this.updateTrashProvider} value={this.state.trashProvider}></input>
                                                            <div className="alert-small-text">{""}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Trash Utility Account # </label>
                                                            <input className="form-control" ref={this.trashUtilityAccountNoRef} onChange={this.updateTrashUtilityAccountNumber} value={this.state.trashUtilityAccountNo}></input>
                                                            <div class="alert-small-text">{this.state.trashUtilityAccountNo_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>Billing Account Number </label>
                                                            <Tooltip title="Customer's Trash Utility Billing Account Number">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" ref={this.custTrashBillingAccountNoRef} onChange={this.updateTrashBillingAccountNumber} value={this.state.custTrashBillingAccountNo}></input>
                                                            <div class="alert-small-text">{this.state.custTrashBillingAccountNo_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Monthly Trash Bill Amount </label>
                                                            <Tooltip title="Maximum allowed value is 99999.99">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" ref={this.monthlyTrashBillAmountRef} maxlength="8" max="99999.99" onChange={this.updateMonthlyTrashBillAmount} value={this.state.monthlyTrashBillAmount}></input>
                                                            <div class="alert-small-text">{this.state.monthlyTrashBillAmount_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-4 field-hightlight">
                                                            <label># of Month of Trash Utility Payments</label>
                                                            <input className="form-control" type="text" ref={this.noOfMonthsTrashUtilityPaymentsRef} onChange={this.updateNoOfMonthsTrashUtilityPayments} value={this.state.noOfMonthsTrashUtilityPayments} maxlength="2" ></input>
                                                            <div class="alert-small-text">{this.state.noOfMonthsTrashUtilityPayments_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Trash Type of Assistance </label>
                                                            <select className="form-control" ref={(input) => this.trashTypeOfAssistanceSelector = input} onChange={this.updateTrashTypeOfAssistance} value={this.state.trashTypeOfAssistance}>
                                                                <option value="">-- select --</option>
                                                                <option value="Payment">Payment</option>
                                                                <option value="Payment Arrears">Payment Arrears</option>
                                                            </select>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Amount Due for Trash </label>
                                                            <input className="form-control" ref={this.amountDueforTrashRef} onChange={this.updateDollarAmountDueForTrash} value={this.state.amountDueforTrash}></input>
                                                            <div className="alert-small-text">{this.state.amountDueforTrash_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Trash Amount Covered </label>
                                                            <Tooltip title="$ amount approved for payout.">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" ref={this.trashAmountRef} onChange={this.updateTrashAmount} value={this.state.trashAmount}></input>
                                                            <div class="alert-small-text">{this.state.trashAmount_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>% of Trash Bill </label>
                                                            <Tooltip title="Calculated based on Amount Due for Trash and Trash Amount Covered">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" ref={this.percentageOfTrashBillRef} readOnly maxlength="2" onChange={this.updatePercentageOfTrashBill} value={this.state.percentageOfTrashBill}></input>
                                                            <div class="alert-small-text">{this.state.percentageOfTrashBill_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>Trash Bank Name </label>
                                                            <input className="form-control" onChange={this.updateTrashBankName} value={this.state.trashBankName}></input>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>Banking Account Number </label>
                                                            <Tooltip title="Trash Utility Provider's Banking Account Number">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" ref={this.trashUtilityProviderAccountNoRef} onChange={this.updateTrashProvidersBankingAccountNumber} value={this.state.trashUtilityProviderAccountNo}></input>
                                                            <div class="alert-small-text">{this.state.trashUtilityProviderAccountNo_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>Trash Routing Number </label>
                                                            <input className="form-control" type="text" ref={this.trashRoutingNumberRef} onChange={this.updateTrashRoutingNumber} value={this.state.trashRoutingNumber} maxlength="10" ></input>
                                                            <div class="alert-small-text">{this.state.trashRoutingNumber_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-3">
                                                            <label>Trash Memo - Account Number</label>
                                                            <input className="form-control" type="text" ref={this.trashMemoAccountNoRef} onChange={this.updateTrashMemoAccountNumber} value={this.state.trashMemoAccountNo} maxlength="100" ></input>
                                                            <div class="alert-small-text">{this.state.trashMemoAccountNo_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>Trash Utility Tax I.D. </label>
                                                            <Tooltip title="Must Include if Applicable">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="text" ref={this.trashUtilityTaxIDRef} onChange={this.updateTrashUtilityTaxID} value={this.state.trashUtilityTaxID} maxlength="10" ></input>
                                                            <div class="alert-small-text">{this.state.trashUtilityTaxID_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-3">
                                                            <label>Trash Utility DUNS Number </label>
                                                            <Tooltip title="Must Include if Applicable">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="text" ref={this.trashUtilityDUNSNumberRef} onChange={this.updateTrashUtilityDUNSNumber} value={this.state.trashUtilityDUNSNumber} maxlength="10" ></input>
                                                            <div class="alert-small-text">{this.state.trashUtilityDUNSNumber_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Trash Payment Status </label>
                                                            <select className="form-control" id="trashPaymentStatusId" ref={(input) => this.trashPaymentStatusSelector = input} onChange={this.updateTrashPaymentStatus} value={this.state.trashPaymentStatus}>
                                                                <option value="">-- select --</option>
                                                                <option value="Pending">Pending</option>
                                                                <option value="In-Review">In-Review</option>
                                                                <option value="Approved for Payment">Approved for Payment</option>
                                                                <option value="N/A">N/A</option>
                                                                {/* <option value="Issued">Issued</option> */}
                                                            </select>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="box-gray mb-4">
                                                <h2 className="profile-title"><i class="fas fa-sun mr-2"></i>Energy Details</h2>
                                                <div className="box-details">
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Energy Provider </label>
                                                            <input className="form-control" onChange={this.updateEnergyProvider} value={this.state.energyProvider}></input>
                                                            <div className="alert-small-text">{""}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Energy Utility Account # </label>
                                                            <input className="form-control" ref={this.energyUtilityAccountNoRef} onChange={this.updateEnergyUtilityAccountNumber} value={this.state.energyUtilityAccountNo}></input>
                                                            <div class="alert-small-text">{this.state.energyUtilityAccountNo_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>Billing Account Number </label>
                                                            <Tooltip title="Customer's Energy Utility Billing Account Number">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" ref={this.custEnergyBillingAccountNoRef} onChange={this.updateEnergyBillingAccountNumber} value={this.state.custEnergyBillingAccountNo}></input>
                                                            <div class="alert-small-text">{this.state.custEnergyBillingAccountNo_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Monthly Energy Bill Amount </label>
                                                            <Tooltip title="Maximum allowed value is 99999.99">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" ref={this.monthlyEnergyBillAmountRef} maxlength="8" max="99999.99" onChange={this.updateMonthlyEnergyBillAmount} value={this.state.monthlyEnergyBillAmount}></input>
                                                            <div class="alert-small-text">{this.state.monthlyEnergyBillAmount_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-4 field-hightlight">
                                                            <label># of Month of Energy Utility Payments</label>
                                                            <input className="form-control" type="text" ref={this.noOfMonthsEnergyUtilityPaymentsRef} onChange={this.updateNoOfMonthsEnergyUtilityPayments} value={this.state.noOfMonthsEnergyUtilityPayments} maxlength="2" ></input>
                                                            <div class="alert-small-text">{this.state.noOfMonthsEnergyUtilityPayments_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Energy Type of Assistance </label>
                                                            <select className="form-control" ref={(input) => this.energyTypeOfAssistanceSelector = input} onChange={this.updateEnergyTypeOfAssistance} value={this.state.energyTypeOfAssistance}>
                                                                <option value="">-- select --</option>
                                                                <option value="Payment">Payment</option>
                                                                <option value="Payment Arrears">Payment Arrears</option>
                                                            </select>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Amount Due for Energy </label>
                                                            <input className="form-control" ref={this.amountDueforEnergyRef} onChange={this.updateDollarAmountDueForEnergy} value={this.state.amountDueforEnergy}></input>
                                                            <div className="alert-small-text">{this.state.amountDueforEnergy_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Energy Amount Covered </label>
                                                            <Tooltip title="$ amount approved for payout.">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" ref={this.energyAmountRef} onChange={this.updateEnergyAmount} value={this.state.energyAmount}></input>
                                                            <div class="alert-small-text">{this.state.energyAmount_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>% of Energy Bill </label>
                                                            <Tooltip title="Calculated based on Amount Due for Energy and Energy Amount Covered">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" ref={this.percentageOfEnergyBillRef} readOnly maxlength="2" onChange={this.updatePercentageOfEnergyBill} value={this.state.percentageOfEnergyBill}></input>
                                                            <div class="alert-small-text">{this.state.percentageOfEnergyBill_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>Energy Bank Name </label>
                                                            <input className="form-control" onChange={this.updateEnergyBankName} value={this.state.energyBankName}></input>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>Banking Account Number </label>
                                                            <Tooltip title="Energy Utility Provider's Banking Account Number">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" ref={this.energyUtilityProviderAccountNoRef} onChange={this.updateEnergyProvidersBankingAccountNumber} value={this.state.energyUtilityProviderAccountNo}></input>
                                                            <div class="alert-small-text">{this.state.energyUtilityProviderAccountNo_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>Energy Routing Number </label>
                                                            <input className="form-control" type="text" ref={this.energyRoutingNumberRef} onChange={this.updateEnergyRoutingNumber} value={this.state.energyRoutingNumber} maxlength="10" ></input>
                                                            <div class="alert-small-text">{this.state.energyRoutingNumber_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-3">
                                                            <label>Energy Memo - Account Number</label>
                                                            <input className="form-control" type="text" ref={this.energyMemoAccountNoRef} onChange={this.updateEnergyMemoAccountNumber} value={this.state.energyMemoAccountNo} maxlength="100" ></input>
                                                            <div class="alert-small-text">{this.state.energyMemoAccountNo_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>Energy Utility Tax I.D. </label>
                                                            <Tooltip title="Must Include if Applicable">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="text" ref={this.energyUtilityTaxIDRef} onChange={this.updateEnergyUtilityTaxID} value={this.state.energyUtilityTaxID} maxlength="10" ></input>
                                                            <div class="alert-small-text">{this.state.energyUtilityTaxID_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-3">
                                                            <label>Energy Utility DUNS Number </label>
                                                            <Tooltip title="Must Include if Applicable">
                                                                <i className="fa fa-question-circle ml-1"></i>
                                                            </Tooltip>
                                                            <input className="form-control" type="text" ref={this.energyUtilityDUNSNumberRef} onChange={this.updateEnergyUtilityDUNSNumber} value={this.state.energyUtilityDUNSNumber} maxlength="10" ></input>
                                                            <div class="alert-small-text">{this.state.energyUtilityDUNSNumber_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2 field-hightlight">
                                                            <label>Energy Payment Status </label>
                                                            <select className="form-control" id="energyPaymentStatusId" ref={(input) => this.energyPaymentStatusSelector = input} onChange={this.updateEnergyPaymentStatus} value={this.state.energyPaymentStatus}>
                                                                <option value="">-- select --</option>
                                                                <option value="Pending">Pending</option>
                                                                <option value="In-Review">In-Review</option>
                                                                <option value="Approved for Payment">Approved for Payment</option>
                                                                <option value="N/A">N/A</option>
                                                                {/* <option value="Issued">Issued</option> */}
                                                            </select>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="box-gray mb-4">
                                                <h2 className="profile-title"><i class="fas fa-money-check-alt mr-2"></i>Individual Payment Details</h2>
                                                <div className="box-details">
                                                    <div className="form-row">
                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>Payment Reason</label>
                                                            <select className="form-control" ref={(input) => this.individualPaymentReasonSelector = input} onChange={this.updateIndividualPaymentReason} value={this.state.individualPaymentReason}>
                                                                <option value="">-- select --</option>
                                                                <option value="Rent">Rent</option>
                                                                <option value="Electric">Electric</option>
                                                                <option value="Gas">Gas</option>
                                                            </select>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-3">
                                                            <label>Individual Payment Amount</label>
                                                            <input className="form-control" type="text" ref={this.individualPaymentAmountRef} onChange={this.updateIndividualPaymentAmount} value={this.state.individualPaymentAmount} maxlength="10" ></input>
                                                            <div class="alert-small-text">{this.state.individualPaymentAmount_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-3">
                                                            <label>Memo - Property Address </label>
                                                            <input className="form-control" type="text" ref={this.individualMemoPropertyAddressRef} onChange={this.updateIndividualMemoPropertyAddress} maxlength="100" value={this.state.individualMemoPropertyAddress}></input>
                                                            <div className="alert-small-text">{this.state.individualMemoPropertyAddress_Error}</div>
                                                        </div>

                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>Individual Bank Name </label>
                                                            <input className="form-control" onChange={this.updateIndividualBankName} value={this.state.individualBankName} maxlength="100"></input>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>Individual Account Number </label>
                                                            <input className="form-control" ref={this.individualAccountNumberRef} onChange={this.updateIndividualAccountNumber} value={this.state.individualAccountNumber} maxlength="10" ></input>
                                                            <div class="alert-small-text">{this.state.individualAccountNumber_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>Individual Routing Number </label>
                                                            <input className="form-control" type="text" ref={this.individualRoutingNumberRef} onChange={this.updateIndividualRoutingNumber} value={this.state.individualRoutingNumber} maxlength="10" ></input>
                                                            <div class="alert-small-text">{this.state.individualRoutingNumber_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-3 field-hightlight">
                                                            <label>Individual Payment Status</label>
                                                            <select className="form-control" id="individualPaymentStatusId" ref={(input) => this.individualPaymentStatusSelector = input} onChange={this.updateIndividualPaymentStatus} value={this.state.individualPaymentStatus}>
                                                                <option value="">-- select --</option>
                                                                <option value="Pending">Pending</option>
                                                                <option value="In-Review">In-Review</option>
                                                                <option value="Approved for Payment">Approved for Payment</option>
                                                                <option value="N/A">N/A</option>
                                                                {/* <option value="Issued">Issued</option> */}
                                                            </select>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label></label>
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" id="individualACHReceivedId" class="custom-control-input" onChange={this.onIndividualACHReceivedChange} />
                                                                <label class="custom-control-label" for="individualACHReceivedId">
                                                                    ACH Received
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="box-gray mb-4" style={{ display: 'none' }}>
                                                <h2 className="profile-title"><i class="fas fa-money-check-alt mr-2"></i>AB832 Additional Payment</h2>
                                                <div className="box-details">
                                                    <div className="form-row">
                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>Other Payee Name </label>
                                                            <select disabled className="form-control" ref={this.otherPayeeNameRef} onChange={this.updateOtherPayeeName} value={this.state.otherPayeeName}>
                                                                <option value="">-- select --</option>
                                                                <option value="Tenant">Tenant</option>
                                                                <option value="Landlord">Landlord</option>
                                                            </select>
                                                            <div class="alert-small-text">{this.state.otherPayeeName_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>Payment Description </label>
                                                            <select disabled className="form-control" ref={this.otherDesriptionRef} onChange={this.updateOtherDesription} value={this.state.otherDesription}>
                                                                <option value="">-- select --</option>
                                                                <option value="AB832">AB832</option>
                                                            </select>
                                                            <div class="alert-small-text">{this.state.otherDesription_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-3">
                                                            <label>Additional Payment Amount</label>
                                                            <input disabled className="form-control" type="text" ref={this.otherPaymentAmountRef} onChange={this.updateOtherPaymentAmount} value={this.state.otherPaymentAmount} maxlength="10" ></input>
                                                            <div class="alert-small-text">{this.state.otherPaymentAmount_Error}</div>
                                                        </div>
                                                        <div className="form-group col-md-6 col-xl-3">
                                                            <label>Memo - Property Address </label>
                                                            <input disabled className="form-control" type="text" ref={this.otherMemoPropertyAddressRef} onChange={this.updateOtherMemoPropertyAddress} maxlength="100" value={this.state.otherMemoPropertyAddress}></input>
                                                            <div className="alert-small-text">{this.state.otherMemoPropertyAddress_Error}</div>
                                                        </div>

                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>Other Bank Name </label>
                                                            <input disabled className="form-control" onChange={this.updateOtherBankName} value={this.state.otherBankName} maxlength="100"></input>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>Other Account Number </label>
                                                            <input disabled className="form-control" ref={this.otherAccountNumberRef} onChange={this.updateOtherAccountNumber} value={this.state.otherAccountNumber} maxlength="10" ></input>
                                                            <div class="alert-small-text">{this.state.otherAccountNumber_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-2">
                                                            <label>Other Routing Number </label>
                                                            <input disabled className="form-control" type="text" ref={this.otherRoutingNumberRef} onChange={this.updateOtherRoutingNumber} value={this.state.otherRoutingNumber} maxlength="10" ></input>
                                                            <div class="alert-small-text">{this.state.otherRoutingNumber_Error}</div>
                                                        </div>
                                                        <div class="form-group col-md-6 col-xl-3 field-hightlight">
                                                            <label>Other Payment Status</label>
                                                            <select disabled className="form-control" id="otherPaymentStatusId" ref={(input) => this.otherPaymentStatusSelector = input} onChange={this.updateOtherPaymentStatus} value={this.state.otherPaymentStatus}>
                                                                <option value="">-- select --</option>
                                                                <option value="Pending">Pending</option>
                                                                <option value="In-Review">In-Review</option>
                                                                <option value="Approved for Payment">Approved for Payment</option>
                                                                <option value="N/A">N/A</option>
                                                                {/* <option value="Issued">Issued</option> */}
                                                            </select>
                                                            <div class="alert-small-text">{""}</div>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                            {/* reconci section start */}
                                            <div className="box-gray mb-4">
                                                <h2 class="profile-title d-flex align-items-center justify-content-between"><span><i class="fas fa-handshake mr-2"></i>Manage reconciliation</span><span className="property-grid-add">
                                                    <button type="button" class="btn btn-primary" onClick={this.openAddPropertyPopup.bind(this)} ><i class="fas fa-plus-circle text-white mr-1"></i>Add</button></span></h2>
                                                <div className="box-details">
                                                    <div className='property-grid table-responsive'>

                                                        <table className={"table " + (this.state.reconciliationList && this.state.reconciliationList.length > 3 ? "table-scroll" : "")}>
                                                            <thead>
                                                                <tr>
                                                                    <th width="15%" scope="col">Initial Receiver</th>
                                                                    <th width="15%" scope="col">Paid By</th>
                                                                    <th width="15%" scope="col">Transaction Type</th>
                                                                    <th width="10%" scope="col">Before reconciliation</th>
                                                                    <th width="15%" scope="col">Amount (in $)</th>
                                                                    <th width="10%" scope="col">After reconciliation</th>
                                                                    <th width="5%"></th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.reconciliationList.length == 0 ?
                                                                    <p class="text-center"> No record </p> : null
                                                                }
                                                                {this.state.reconciliationList && this.state.reconciliationList.map((member, index) => (
                                                                    <tr>

                                                                        <td width="15%">
                                                                            {member.entityType}
                                                                        </td>

                                                                        <td width="15%">
                                                                            {member.initiallyPaidBy}
                                                                        </td>

                                                                        <td width="15%">
                                                                            {member.amountType}
                                                                        </td>
                                                                        <td width="10%">
                                                                            {member.amountCoveredBeforeRec}
                                                                        </td>
                                                                        <td width="15%">
                                                                            {member.amount}
                                                                        </td>
                                                                        <td width="10%">
                                                                            {member.amountCoveredAfterRec}
                                                                        </td>

                                                                        <td width="5%">
                                                                            <a className="border-0" onClick={this.editReconciliation.bind(this, member.id)}><i class="fas fa-edit mr-2"></i></a>
                                                                            <a className="border-0" onClick={this.deleteRec.bind(this, member.id)}><i class="fas fa-trash-alt red"></i></a>
                                                                        </td>
                                                                    </tr>

                                                                ))}

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* rec section end */}
                                            <div className="box-gray mb-4">
                                                <h2 className="profile-title"><i class="fas fa-file mr-2"></i>Documents</h2>
                                                <div className="box-details">
                                                    <div className="form-row">
                                                        <div className="table-responsive">
                                                            <table class="table table-bordered table-cgreen mt-3">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">Document</th>
                                                                        <th scope="col">File Name</th>
                                                                        <th scope="col">Uploaded Date Time</th>
                                                                        <th scope="col">Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr id="photo_identification_id">
                                                                        <td>Photo Identification<div id="photo_identification_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td><input class="form-control-sm form-control-file pl-0" type="file" id="photo_identification_upload_id" /></td>
                                                                    </tr>
                                                                    <tr id="proof_of_address_id">
                                                                        <td>Proof of Address<div id="proof_of_address_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td><input class="form-control-sm form-control-file pl-0" type="file" id="proof_of_address_upload_id" /></td>
                                                                    </tr>
                                                                    <tr id="proof_of_income_id">
                                                                        <td>Proof of Income<div id="proof_of_income_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td><input class="form-control-sm form-control-file pl-0" type="file" id="proof_of_income_upload_id" /></td>
                                                                    </tr>
                                                                    <tr id="proof_of_income_loss_id">
                                                                        <td>Proof of Income loss<div id="proof_of_income_loss_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td><input class="form-control-sm form-control-file pl-0" type="file" id="proof_of_income_loss_upload_id" /></td>
                                                                    </tr>
                                                                    <tr id="ach_authorization_form_id">
                                                                        <td>Program Participation Form<div id="ach_authorization_form_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td><input class="form-control-sm form-control-file pl-0" type="file" id="ach_authorization_form_upload_id" /></td>
                                                                    </tr>
                                                                    <tr id="lease_agreement_id">
                                                                        <td>Lease Agreement<div id="lease_agreement_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td><input class="form-control-sm form-control-file pl-0" type="file" id="lease_agreement_upload_id" /></td>
                                                                    </tr>
                                                                    <tr id="utility_gas_bill_id">
                                                                        <td>Utility Gas Bill<div id="utility_gas_bill_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td><input class="form-control-sm form-control-file pl-0" type="file" id="utility_gas_bill_upload_id" /></td>
                                                                    </tr>
                                                                    <tr id="utility_electricity_bill_id">
                                                                        <td>Utility Electricity Bill<div id="utility_electricity_bill_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td><input class="form-control-sm form-control-file pl-0" type="file" id="utility_electricity_bill_upload_id" /></td>
                                                                    </tr>
                                                                    <tr id="landlord_w9_id">
                                                                        <td>Landlord W9<div id="landlord_w9_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td><input class="form-control-sm form-control-file pl-0" type="file" id="landlord_w9_upload_id" /></td>
                                                                    </tr>
                                                                    <tr id="landlord_driver_license_id">
                                                                        <td>Landlord Identification<div id="landlord_driver_license_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td><input class="form-control-sm form-control-file pl-0" type="file" id="landlord_driver_license_upload_id" /></td>
                                                                    </tr>
                                                                    <tr id="verified_checkpoint_id">
                                                                        <td>CheckPoint ID Verification Page <div id="verified_checkpoint_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td><input class="form-control-sm form-control-file pl-0" type="file" id="verified_checkpoint_upload_id" /></td>
                                                                    </tr>

                                                                    <tr id="round2_landlord_updated_w9_id">
                                                                        <td>Round 2 updated Landlord W9 <div id="round2_landlord_updated_w9_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td><input class="form-control-sm form-control-file pl-0" type="file" id="round2_landlord_updated_w9_upload_id" /></td>
                                                                    </tr>
                                                                    <tr id="round2_updated_program_participation_Form_id">
                                                                        <td>Round 2 updated Program Participation Form <div id="round2_updated_program_participation_Form_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td><input class="form-control-sm form-control-file pl-0" type="file" id="round2_updated_program_participation_Form_upload_id" /></td>
                                                                    </tr>
                                                                    <tr id="round2_landlord_identity_id">
                                                                        <td>Round 2 Landlord Identity <div id="round2_landlord_identity_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td><input class="form-control-sm form-control-file pl-0" type="file" id="round2_landlord_identity_upload_id" /></td>
                                                                    </tr>
                                                                    <tr id="round2_landlord_checkpoint_id">
                                                                        <td>Round 2 Landlord checkpoint ID Verification <div id="round2_landlord_checkpoint_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td><input class="form-control-sm form-control-file pl-0" type="file" id="round2_landlord_checkpoint_upload_id" /></td>
                                                                    </tr>
                                                                    <tr id="tenant_payment_agreement_id">
                                                                        <td>Tenant Payment Agreement <div id="tenant_payment_agreement_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td><input class="form-control-sm form-control-file pl-0" type="file" id="tenant_payment_agreement_upload_id" /></td>
                                                                    </tr>
                                                                    <tr id="landlord_payment_agreement_id">
                                                                        <td>Landlord Payment Agreement <div id="landlord_payment_agreement_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td><input class="form-control-sm form-control-file pl-0" type="file" id="landlord_payment_agreement_upload_id" /></td>
                                                                    </tr>

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="box-gray mb-4">
                                                <h2 className="profile-title"><i class="fas fa-sticky-note mr-2"></i>Notes</h2>
                                                <div className="box-details">
                                                    <div className="form-row">
                                                        <div class="form-group col-md-6 col-xl-12">
                                                            <textarea rows="20" id="notesTextArea" className="form-control h-auto" defaultValue={this.state.notes} onChange={this.updateNotes} value={this.state.notes}></textarea>
                                                            <div class="alert-small-text">{this.state.notes_Error}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="assistoc-speeddial">
                                                {this.state.mode && this.state.mode == "Admin" ?
                                                    <Tooltip title="Cancel" arrow>
                                                        <Button type="button" onClick={this.handleBack} className="btn btn-primary whoc-float-btn">
                                                            <i className="fas fa-times"></i>
                                                        </Button>
                                                    </Tooltip>
                                                    : null
                                                }
                                                {this.state.mode && this.state.mode == "Admin" ?
                                                    <Tooltip title="Save" arrow>
                                                        <Button type="button" onClick={this.handleUpdate} className="btn btn-secondary ml-2 whoc-float-btn">
                                                            <i className="fas fa-save" ></i>
                                                        </Button>
                                                    </Tooltip>
                                                    : null
                                                }
                                            </div>

                                            <Backdrop className="MuiBackdrop-root" open={this.state.isLoading} >
                                                <CircularProgress />
                                            </Backdrop>
                                            <Snackbar anchorOrigin={{ vertical: this.state.snackbarVertical, horizontal: this.state.snackbarHorizontal }}
                                                open={this.state.openSnackbar}
                                                autoHideDuration={3000}
                                                severity={this.state.snackbarSeverity}
                                                onClose={() => this.setState({ openSnackbar: false })}
                                                message={this.state.snackbarMessage}
                                                className={this.state.snackbarMessageClass}
                                                key={this.state.snackbarVertical + this.state.snackbarHorizontal}
                                            />
                                            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                <div class="modal-dialog modal-lg">
                                                    <div class="modal-content">
                                                        <div class="modal-header bg-primary">
                                                            <h5 class="modal-title text-white" id="exampleModalLabel">Manage reconciliation</h5>
                                                            <button type="button" class="close text-white op-1" data-dismiss="modal" aria-label="Close">
                                                                <span aria-hidden="true">&times;</span>
                                                            </button>
                                                        </div>
                                                        <div class="modal-body">
                                                            <form id="recform">
                                                                <div class="form-row">
                                                                    <div class="form-group col-sm-6 col-lg-4">
                                                                        <label>Initial Receiver </label>
                                                                        <select className="form-control" disabled={this.state.reconciliationMode == "edit" ? true : false} onChange={this.handleReconciliationChanges.bind(this, "entityType")} value={this.state.reconciliation.entityType} >
                                                                            <option value="">-- select --</option>
                                                                            <option value="Landlord">Landlord</option>
                                                                            <option value="Electric">Electric</option>
                                                                            <option value="Gas">Gas</option>
                                                                            <option value="Water/Sewer">Water/Sewer</option>
                                                                            <option value="Trash">Trash</option>
                                                                            <option value="Energy">Energy</option>
                                                                            <option value="Individual">Individual</option>
                                                                        </select>
                                                                        <div class="alert-small-text" id="valEntityType">{this.state.validationErrors["entityType"]}</div>
                                                                    </div>

                                                                    <div class="form-group col-sm-6 col-lg-4">
                                                                        <label>Paid By </label>
                                                                        <select className="form-control" disabled={this.state.isChildRecord ? true : false} onChange={this.handleReconciliationChanges.bind(this, "initiallyPaidBy")} value={this.state.reconciliation.initiallyPaidBy} >
                                                                            <option value="">-- select --</option>
                                                                            <option value="OCUW">OCUW</option>
                                                                            <option value="3rd Party">3rd Party</option>
                                                                        </select>
                                                                        <div class="alert-small-text" id="valInitiallyPaidBy">{this.state.validationErrors["initiallyPaidBy"]}</div>
                                                                    </div>

                                                                    <div class="form-group col-md-6 col-lg-4">
                                                                        <label>Transaction Type </label>
                                                                        <select className="form-control" disabled={this.state.isChildRecord ? true : false} onChange={this.handleReconciliationChanges.bind(this, "amountType")} value={this.state.reconciliation.amountType} >
                                                                            <option value="">-- select --</option>
                                                                            <option value="Paid">Paid</option>
                                                                            <option value="Adjustment(Positive)">Adjustment (Positive)</option>
                                                                            <option value="Return">Return</option>
                                                                            <option value="Adjustment(Negative)">Adjustment (Negative)</option>
                                                                        </select>
                                                                        <div class="alert-small-text">{this.state.validationErrors["amountType"]}</div>
                                                                    </div>
                                                                    <div class="form-group col-sm-6 col-lg-4">
                                                                        <label>Amount paid before reconciliation</label>
                                                                        <input disabled className="form-control" type="text" maxlength="100" onChange={this.handleReconciliationChanges.bind(this, "amountCoveredBeforeRec")} value={this.state.reconciliation.amountCoveredBeforeRec}></input>
                                                                        <span class="alert-small-text">{this.state.validationErrors["amountCoveredBeforeRec"]}</span>
                                                                    </div>
                                                                    <div class="form-group col-sm-6 col-lg-4">
                                                                        <label>Amount (in $)</label>
                                                                        <input className="form-control" disabled={this.state.isChildRecord ? true : false} type="text" maxlength="100" onChange={this.handleReconciliationChanges.bind(this, "amount")} value={this.state.reconciliation.amount}></input>
                                                                        <span class="alert-small-text">{this.state.validationErrors["amount"]}</span>
                                                                    </div>

                                                                    <div class="form-group col-sm-6 col-lg-4">
                                                                        <label>Amount paid after reconciliation</label>
                                                                        <input disabled className="form-control" type="text" maxlength="100" onChange={this.handleReconciliationChanges.bind(this, "amountCoveredAfterRec")} value={this.state.reconciliation.amountCoveredAfterRec}></input>
                                                                        <span class="alert-small-text">{this.state.validationErrors["amountCoveredAfterRec"]}</span>
                                                                    </div>
                                                                    <div class="form-group col-md-6 col-lg-4">
                                                                        <label>Payment Method </label>
                                                                        <input className="form-control" onChange={this.handleReconciliationChanges.bind(this, "paymentMethod")} value={this.state.reconciliation.paymentMethod} maxlength="100"></input>
                                                                        <span class="alert-small-text">{this.state.validationErrors["paymentMethod"]}</span>
                                                                    </div>
                                                                    <div className="form-group col-md-6 col-lg-4">
                                                                        <label>Memo - Property Address </label>
                                                                        <input className="form-control" type="text" onChange={this.handleReconciliationChanges.bind(this, "memoPropertyAddress")} maxlength="100" value={this.state.reconciliation.memoPropertyAddress}></input>
                                                                        <span class="alert-small-text">{this.state.validationErrors["memoPropertyAddress"]}</span>
                                                                    </div>
                                                                    <div class="form-group col-sm-6 col-lg-4">
                                                                        <label>Other Payment Status</label>
                                                                        <select className="form-control" id="otherPaymentStatusId" onChange={this.handleReconciliationChanges.bind(this, "otherPaymentStatus")} value={this.state.reconciliation.otherPaymentStatus}>
                                                                            <option value="">-- select --</option>
                                                                            <option value="Pending">Pending</option>
                                                                            <option value="In-Review">In-Review</option>
                                                                            <option value="Approved for Payment">Approved for Payment</option>
                                                                            <option value="N/A">N/A</option>
                                                                            <option value="Issued">Issued</option>
                                                                        </select>
                                                                        <span class="alert-small-text">{this.state.validationErrors["otherPaymentStatus"]}</span>
                                                                    </div>
                                                                    <div class="form-group col-sm-6 col-lg-4">
                                                                        <label>Transaction ID</label>
                                                                        <input className="form-control" type="text" maxlength="100" onChange={this.handleReconciliationChanges.bind(this, "transactionNo")} value={this.state.reconciliation.transactionNo}></input>
                                                                        <span class="alert-small-text">{this.state.validationErrors["transactionNo"]}</span>
                                                                    </div>

                                                                    <div class="form-group col-md-6 col-xl-12">
                                                                        <label>Notes</label>
                                                                        <textarea rows="10" id="notesTextArea" className="form-control h-auto" onChange={this.handleReconciliationChanges.bind(this, "notes")} value={this.state.reconciliation.notes}></textarea>
                                                                        <div class="alert-small-text">{this.state.validationErrors["notes"]}</div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                        <div class="modal-footer">
                                                            <button type="button" class="btn btn-primary" onClick={this.saveReconciliation.bind(this)}>Save</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    </div>
                                </fieldset>
                            </div>
                        </div>

                    </div>
                    {/* content area ends */}

                    {/* </div>
                    </main> */}
                    {/* main content ends */}
                    <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-lg">
                            <div class="modal-content">
                                <div class="modal-header bg-primary">
                                    <h5 class="modal-title text-white" id="exampleModalLabel">Delete reconciliation</h5>
                                    <button type="button" class="close text-white op-1" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    Are you sure you would like to Delete reconciliation ?
                                </div>
                                <div class="modal-footer">
                                    {/* <button type="button" class="btn btn-secondary btn-light" data-dismiss="modal">Close</button> */}
                                    <button type="button" class="btn btn-primary" onClick={this.performDelete}>Yes</button>
                                    <button type="button" class="btn btn-primary" onClick={this.closeMsg}>No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        else {
            return null;
        }
    }
};

export default CeppClaimDetails;
