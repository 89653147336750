import React from "react";
import { CircularProgress } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import { API, Storage, Auth } from 'aws-amplify';
import Snackbar from '@material-ui/core/Snackbar';

class PHARegistrationStep3 extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            isLoading: false,

            OwnershipOfAssistedUnit: false,
            OwnersTenantsRelationship: false,
            ApprovedResidents: false,
            HousingQualityStandards: false,
            SecurityDeposit: false,
            NoticeToTenants: false,
            ReportingVacancies: false,
            ComputerMatchingConsent: false,
            AdministrativeCrimialActions: false,

            openSnackbar: false,
            snackbarSeverity: "",
            snackbarVertical: 'top',
            snackbarHorizontal: 'right',
            snackbarMessage: "",
            snackbarMessageClass: '',
        }

        this.propertyInfo = [];

        this.SelectAllCheckBoxes = React.createRef();
    }

    async componentDidMount() {
        // console.log(+this.props.match.params.id);
        console.log(+this.props.location.state?.ID )

        this.setState({ isLoading: true });

        var info = await this.getPropertyDetails();
        if (info.length > 0) {
            this.propertyInfo = info;
        }

        console.log('property Regulation details');
        console.log(info);

        if (info.length > 0) {
            this.setState({
                OwnershipOfAssistedUnit: info[0]["pr_OwnershipOfAssistedUnit"],
                OwnersTenantsRelationship: info[0]["pr_OwnersTenantsRelationship"],
                ApprovedResidents: info[0]["pr_ApprovedResidents"],
                HousingQualityStandards: info[0]["pr_HousingQualityStandards"],
                SecurityDeposit: info[0]["pr_SecurityDeposit"],
                NoticeToTenants: info[0]["pr_NoticeToTenants"],
                ReportingVacancies: info[0]["pr_ReportingVacancies"],
                ComputerMatchingConsent: info[0]["pr_ComputerMatchingConsent"],
                AdministrativeCrimialActions: info[0]["pr_AdministrativeCrimialActions"],
                // OwnershipOfAssistedUnit: (info[0]["OwnershipOfAssistedUnit"] === true)?1:0,
                // OwnersTenantsRelationship: (info[0]["OwnersTenantsRelationship"] === true)?1:0,
                // ApprovedResidents: (info[0]["ApprovedResidents"] === true)?1:0,
                // HousingQualityStandards: (info[0]["HousingQualityStandards"] === true)?1:0,
                // SecurityDeposit: (info[0]["SecurityDeposit"] === true)?1:0,
                // NoticeToTenants: (info[0]["NoticeToTenants"] === true)?1:0,
                // ReportingVacancies: (info[0]["ReportingVacancies"] === true)?1:0,
                // ComputerMatchingConsent: (info[0]["ComputerMatchingConsent"] === true)?1:0,
                // AdministrativeCrimialActions: (info[0]["AdministrativeCrimialActions"] === true)?1:0,
            });
        }

        if (this.state.OwnershipOfAssistedUnit === true &&
            this.state.OwnersTenantsRelationship === true &&
            this.state.ApprovedResidents === true &&
            this.state.HousingQualityStandards === true &&
            this.state.SecurityDeposit === true &&
            this.state.NoticeToTenants === true &&
            this.state.ReportingVacancies === true &&
            this.state.ComputerMatchingConsent === true &&
            this.state.AdministrativeCrimialActions === true) {
            document.getElementById("selectAll").checked = 1;
        }

        document.getElementById("ownershipOfAssistedUnit").checked = this.state.OwnershipOfAssistedUnit;
        document.getElementById("ownersTenantsRelationship").checked = this.state.OwnersTenantsRelationship;
        document.getElementById("approvedResidents").checked = this.state.ApprovedResidents;
        document.getElementById("housingQualityStandards").checked = this.state.HousingQualityStandards;
        document.getElementById("securityDeposit").checked = this.state.SecurityDeposit;
        document.getElementById("noticeToTenants").checked = this.state.NoticeToTenants;
        document.getElementById("reportingVacancies").checked = this.state.ReportingVacancies;
        document.getElementById("computerMatchingConsent").checked = this.state.ComputerMatchingConsent;
        document.getElementById("administrativeCrimialActions").checked = this.state.AdministrativeCrimialActions;

        // this.SelectAllCheckBoxes.current.focus();
        window.scrollTo(0, 0); // scroll to the top of the window

        this.setState({ isLoading: false });
    }

    getPropertyDetails = async () => {
        var user = await Auth.currentAuthenticatedUser();
        let apiName = process.env.REACT_APP_APIGatewayNameWHOC;
        let path = '/getpropertydetailsbypropertyid';
        let testId = this.props.location.state?.ID ;
        let formattedId = testId.toString();
        let myInit = {
            body: { "PropertyID": +formattedId },
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
        }
        return await API.post(apiName, path, myInit);
    }

    updatePropertyRegistrationDetails = async () => {
        let testId = this.props.location.state?.ID ;
        let formattedId = testId.toString();
        this.propertyInfo[0]["WhatToUpdate"] = "Step3";

        this.propertyInfo[0]["PropertyID"] = +formattedId;
        this.propertyInfo[0]["pr_OwnershipOfAssistedUnit"] = this.state.OwnershipOfAssistedUnit;
        this.propertyInfo[0]["pr_OwnersTenantsRelationship"] = this.state.OwnersTenantsRelationship;
        this.propertyInfo[0]["pr_ApprovedResidents"] = this.state.ApprovedResidents;
        this.propertyInfo[0]["pr_HousingQualityStandards"] = this.state.HousingQualityStandards;
        this.propertyInfo[0]["pr_SecurityDeposit"] = this.state.SecurityDeposit;
        this.propertyInfo[0]["pr_NoticeToTenants"] = this.state.NoticeToTenants;
        this.propertyInfo[0]["pr_ReportingVacancies"] = this.state.ReportingVacancies;
        this.propertyInfo[0]["pr_ComputerMatchingConsent"] = this.state.ComputerMatchingConsent;
        this.propertyInfo[0]["pr_AdministrativeCrimialActions"] = this.state.AdministrativeCrimialActions;

        var user = await Auth.currentAuthenticatedUser();
        let apiName = process.env.REACT_APP_APIGatewayNameWHOC;
        let path = '/updatepropertyregistrationdetails';
        let myInit = {
            body: this.propertyInfo[0],
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
        }
        return await API.post(apiName, path, myInit);
    }

    dummyRequest = (url) => {
        return new Promise((resolve, reject) => {
            window.setTimeout(
                () => {
                    console.log('dummy work completed');
                    resolve();
                },
                2000
            );
        });
    }

    saveForm = async () => {
        this.setState({ isLoading: true });

        await this.updatePropertyRegistrationDetails();
        console.log('after work done');

        this.setState({ isLoading: false });
        this.setState({ openSnackbar: true, snackbarMessage: "The updates are been saved",snackbarMessageClass: 'Mui-filledSuccess', hideDuration: 3000 });
    }

    validateForm = async () => {
        if (
            document.getElementById("ownershipOfAssistedUnit").checked &&
            document.getElementById("ownersTenantsRelationship").checked &&
            document.getElementById("approvedResidents").checked &&
            document.getElementById("housingQualityStandards").checked &&
            document.getElementById("securityDeposit").checked &&
            document.getElementById("noticeToTenants").checked &&
            document.getElementById("reportingVacancies").checked &&
            document.getElementById("computerMatchingConsent").checked &&
            document.getElementById("administrativeCrimialActions").checked
        ) {
            this.setState({
                isLoading: true,

                OwnershipOfAssistedUnit: true,
                OwnersTenantsRelationship: true,
                ApprovedResidents: true,
                HousingQualityStandards: true,
                SecurityDeposit: true,
                NoticeToTenants: true,
                ReportingVacancies: true,
                ComputerMatchingConsent: true,
                AdministrativeCrimialActions: true,
            });

            //var res = await this.dummyRequest('');
            await this.updatePropertyRegistrationDetails();
            console.log('after work done');

            this.setState({ isLoading: false });

            return true;
        }
        else {
            document.getElementById("regulationValidator").style.display = "block";
            this.SelectAllCheckBoxes.current.focus();
            return false;
        }
    }

    onSelectionChanged = (evt) => {
        document.getElementById("regulationValidator").style.display = "none";

        if (
            document.getElementById("ownershipOfAssistedUnit").checked &&
            document.getElementById("ownersTenantsRelationship").checked &&
            document.getElementById("approvedResidents").checked &&
            document.getElementById("housingQualityStandards").checked &&
            document.getElementById("securityDeposit").checked &&
            document.getElementById("noticeToTenants").checked &&
            document.getElementById("reportingVacancies").checked &&
            document.getElementById("computerMatchingConsent").checked &&
            document.getElementById("administrativeCrimialActions").checked
        ) {
            document.getElementById("selectAll").checked = true;
        }
        else {
            document.getElementById("selectAll").checked = false;
        }

        if (document.getElementById("ownershipOfAssistedUnit").checked === true) {
            this.setState({ OwnershipOfAssistedUnit: true });
        }
        else {
            this.setState({ OwnershipOfAssistedUnit: false });
        }

        if (document.getElementById("ownersTenantsRelationship").checked === true) {
            this.setState({ OwnersTenantsRelationship: true });
        }
        else {
            this.setState({ OwnersTenantsRelationship: false });
        }

        if (document.getElementById("approvedResidents").checked === true) {
            this.setState({ ApprovedResidents: true });
        }
        else {
            this.setState({ ApprovedResidents: false });
        }

        if (document.getElementById("housingQualityStandards").checked === true) {
            this.setState({ HousingQualityStandards: true });
        }
        else {
            this.setState({ HousingQualityStandards: false });
        }

        if (document.getElementById("securityDeposit").checked === true) {
            this.setState({ SecurityDeposit: true });
        }
        else {
            this.setState({ SecurityDeposit: false });
        }

        if (document.getElementById("noticeToTenants").checked === true) {
            this.setState({ NoticeToTenants: true });
        }
        else {
            this.setState({ NoticeToTenants: false });
        }

        if (document.getElementById("reportingVacancies").checked === true) {
            this.setState({ ReportingVacancies: true });
        }
        else {
            this.setState({ ReportingVacancies: false });
        }

        if (document.getElementById("computerMatchingConsent").checked === true) {
            this.setState({ ComputerMatchingConsent: true });
        }
        else {
            this.setState({ ComputerMatchingConsent: false });
        }

        if (document.getElementById("administrativeCrimialActions").checked === true) {
            this.setState({ AdministrativeCrimialActions: true });
        }
        else {
            this.setState({ AdministrativeCrimialActions: false });
        }

    }

    updateSelectAll = (evt) => {
        // if (evt.target.checked === false) {
        //     document.getElementById("regulationValidator").style.display = "block";
        // }
        // else {
        document.getElementById("regulationValidator").style.display = "none";
        //}

        document.getElementById("ownershipOfAssistedUnit").checked = evt.target.checked;
        document.getElementById("ownersTenantsRelationship").checked = evt.target.checked;
        document.getElementById("approvedResidents").checked = evt.target.checked;
        document.getElementById("housingQualityStandards").checked = evt.target.checked;
        document.getElementById("securityDeposit").checked = evt.target.checked;
        document.getElementById("noticeToTenants").checked = evt.target.checked;
        document.getElementById("reportingVacancies").checked = evt.target.checked;
        document.getElementById("computerMatchingConsent").checked = evt.target.checked;
        document.getElementById("administrativeCrimialActions").checked = evt.target.checked;


        this.setState({
            OwnershipOfAssistedUnit: evt.target.checked,
            OwnersTenantsRelationship: evt.target.checked,
            ApprovedResidents: evt.target.checked,
            HousingQualityStandards: evt.target.checked,
            SecurityDeposit: evt.target.checked,
            NoticeToTenants: evt.target.checked,
            ReportingVacancies: evt.target.checked,
            ComputerMatchingConsent: evt.target.checked,
            AdministrativeCrimialActions: evt.target.checked,
        });

    }

    render() {
        return (
            <div className="border p-3 regulations">
                <input className="form-control" type="hidden" ></input>
                <h2 className="profile-title"><i class="fas fa-list-alt mr-2"></i>Regulations</h2>
                <hr />
                <p>The answers provided on this certification are utilized to determine your eligibility to participate as a landlord in the Housing Authority Section 8 program, which is funded by the U.S. Department of Housing and Urban Development (“HUD”).
                    <br /><br />
                    <b>WARNING: </b>Making false statements on this form is considered FRAUD and may result in TERMINATION from the program and CRIMINAL PROSECUTION.
                    <br /><br />
                    <b>Initial each box below, affirming the statement is correct and you agree to follow the regulations listed below.</b>
                    <br /><br />

                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" ref={this.SelectAllCheckBoxes} id="selectAll" onChange={this.updateSelectAll} />
                        <label class="custom-control-label" for="selectAll">
                            <b>Select All</b>
                        </label>
                        <div id="regulationValidator" class="alert-small-text" style={{ display: 'none' }} >
                            Please select all the checkboxes
                        </div>
                    </div>
                    <hr />
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="ownershipOfAssistedUnit" onChange={this.onSelectionChanged} />
                        <label class="custom-control-label" for="ownershipOfAssistedUnit">
                            <b>Ownership of Assisted Unit</b>
                            <p>
                                I certify under the penalty of perjury that I am the legal owner, or the legally designated agent, for hte above referenced HUD subsidized unit, and that the prospective tenant(s) has no owenrship interest in the dwelling unit listed above whatsoever.
                            </p>
                        </label>
                    </div>

                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="ownersTenantsRelationship" onChange={this.onSelectionChanged} />
                        <label class="custom-control-label" for="ownersTenantsRelationship">
                            <b>Owner's/Tenant's Relationship</b>
                            <p>
                                I certify under the penalty of perjury the owner (including a principal or other interested party) of the HUD subsidized unit listed above is not the parent, child, grandparent, grandchild, sister or brother of <b>any</b> member of the household.
                            </p>
                        </label>
                    </div>

                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="approvedResidents" onChange={this.onSelectionChanged} />
                        <label class="custom-control-label" for="approvedResidents">
                            <b>Approved Residents of Assisted Unit and Requirement to Notify of Unauthorized Tenants</b>
                            <p>
                                I understand and acknowledge that the individuals listed on the dwelling lease agreement, as approved by the Housing Authorities, are the <b>only</b> individuals permitted to reside in the subsidized unit.
                            </p>
                        </label>
                    </div>

                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="housingQualityStandards" onChange={this.onSelectionChanged} />
                        <label class="custom-control-label" for="housingQualityStandards">
                            <b>Housing Quality Standards</b>
                            <p>
                                I understand my obligations in compliance with the Housing Assistance Payments Contract to perform necessary maintenance so the unit continues to comply with Housing Quality Standards.
                            </p>
                        </label>
                    </div>

                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="securityDeposit" onChange={this.onSelectionChanged} />
                        <label class="custom-control-label" for="securityDeposit">
                            <b>Security Deposit and Tenant Rent Payments</b>
                            <p>
                                I understand that I determine the amount of the security deposit in according with California State law; and the tenant's portion of the contract tent is determined by the Housing Authorities. <b>WARNING:</b> It is <b>illegal</b> to charge any additional amounts for rent, or any other item not specified in the lease.
                            </p>
                        </label>
                    </div>

                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="noticeToTenants" onChange={this.onSelectionChanged} />
                        <label class="custom-control-label" for="noticeToTenants">
                            <b>Notice to Tenants</b>
                            <p>
                                I understand that I must provide the Housing Authorities copies of any notices that I issue to the tenant. All proposed rent increase notices must be issued sixty days in advance. If a tenant is in violation of the lease, I amy issue a three day notice to perform or a thirty day notice to terminate the tenancy and I will forward a copy of such notices to Housing Authorities.
                            </p>
                        </label>
                    </div>

                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="reportingVacancies" onChange={this.onSelectionChanged} />
                        <label class="custom-control-label" for="reportingVacancies">
                            <b>Reporting Vacencies to the Housing Authorities</b>
                            <p>
                                I understand that should the assisted unit become vacant, I am responsible to notify the Housing Authorities immediately in writing.
                            </p>
                        </label>
                    </div>

                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="computerMatchingConsent" onChange={this.onSelectionChanged} />
                        <label class="custom-control-label" for="computerMatchingConsent">
                            <b>Computer Matching Consent</b>
                            <p>
                                I understand the Housing Assistance Payments Contract permits the Housing Authorities or HUD to verify my compliance with the Contract. I give consent for the Housing Authorities, HUD, or HUD Office of Inspector General ("HUD-OIG") to conduct computer matches to verify my compliance, as they deem necessary. The Housing Authorities and HUD may release and exchange information regarding my participation in the Section 8 Program with other Federal, State and County agencies.
                            </p>
                        </label>
                    </div>

                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="administrativeCrimialActions" onChange={this.onSelectionChanged} />
                        <label class="custom-control-label" for="administrativeCrimialActions">
                            <b>Administrative and Crimial Actions for Intentional Violations</b>
                            <p>
                                I understand that failure to comply with the terms and responsibilities of the Housing Assistance Payments Contract is grounds for termination of participation in the Section 8 Program. I understand that knowingly supplying false, incomplete or inaccurate information is punishable under Federal or State criminal law.
                            </p>
                        </label>
                    </div>
                </p>

                <React.Fragment>
                    {/* className={this.state.classes.backdrop} */}
                    <Backdrop open={this.state.isLoading} onClick={this.handleClose}>
                        <CircularProgress color="inherit" />
                    </Backdrop>

                    <Snackbar anchorOrigin={{ vertical: this.state.snackbarVertical, horizontal: this.state.snackbarHorizontal }}
                                    open={this.state.openSnackbar}
                                    autoHideDuration={3000}
                                    severity={this.state.snackbarSeverity}
                                    onClose={() => this.setState({ openSnackbar: false })}
                                    message={this.state.snackbarMessage}
                                    className={this.state.snackbarMessageClass}
                                    key={this.state.snackbarVertical + this.state.snackbarHorizontal}
                                />

                    {/* {this.state.isLoading && (
                                <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
                                    <CircularProgress />
                                </div>
                    )} */}
                </React.Fragment>

            </div>
        );
    }
}

export default PHARegistrationStep3;
